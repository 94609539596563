<ng-template #itemTemplate let-item="item">
  <div class="truncate">{{ item.name }}</div>
</ng-template>

<ng-template #selectionTemplate let-item="item">
  <div class="truncate">{{ item.name }}</div>
</ng-template>

<ng-container [formGroup]="editorForm">
  <ca-selector
    #assignmentSelector
    formControlName="editor"
    [url]="assignmentSelectorOptions.url"
    [itemTemplate]="itemTemplate"
    [selectionTemplate]="selectionTemplate"
    [queryOperator]="assignmentSelectorOptions.queryOperator"
    [queryField]="assignmentSelectorOptions.queryField"
    [multiple]="actualConfig.multiple"
    [emptyText]="assignmentSelectorOptions.emptyText"
    [showSelections]="assignmentSelectorOptions.showSelections"
    [showClearSelections]="assignmentSelectorOptions.showClearSelections"
    [showClearSelectionLink]="assignmentSelectorOptions.showClearSelectionLink"
    [selectorPosition]="assignmentSelectorOptions.selectorPosition"
    [styles]="{ width: '100%', 'border-width': '0px' }"
    [filters]="assignmentSelectorOptions.filters"
    [sorters]="assignmentSelectorOptions.sorters"
    [pageSize]="assignmentSelectorOptions.pageSize"
    [currentPage]="assignmentSelectorOptions.currentPage">
  </ca-selector>
</ng-container>
