export function GenericLookup(param: any) {
    const actualDecorator = (target: any, property: any): void => {
        const prototype = target.prototype;

        if (!prototype.mappings) {
            prototype.mappings = {};
        }

        prototype.mappings[property] = param.code;
    };

    return actualDecorator;
}
