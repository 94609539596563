import { FormBuilder } from '@angular/forms';
import { TextEditorConfigurationModel } from './../models/text-editor-configuration.model';
import { Component, OnInit } from '@angular/core';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';

@Component({
  selector: 'ca-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent extends FilterPanelEditorComponent implements OnInit {
  private _value: string;

  actualConfig: TextEditorConfigurationModel;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as TextEditorConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  get hasIcon(): boolean {
    return this.actualConfig.iconCls != null;
  }

  get labelClass() {
    let cls = '';
    if (this.actualConfig.callInline === true) {
      cls = 'col-5 custom-padding';
    }
    else if (this.actualConfig.chatInline === true) {
      cls = 'col-4 custom-padding';
    }
    else if (this.actualConfig.otherInline === true) {
      cls = 'col-5 label-padding';
    }
    return cls;
  }

  get customClass() {
    let cls = '';
    if (this.actualConfig.clearBottomMargin === true) {
      cls += 'mb-0';
    }
    if (this.actualConfig.callInline === true || this.actualConfig.chatInline === true) {
      cls += ' display-inline';
    }
    if (this.actualConfig.otherInline === true) {
      cls += ' row display-inline';
    }
    return cls;
  }

  get textClass() {
    let cls = '';
    if (this.actualConfig.otherInline === true) {
      cls += ' col no-padding-left';
    }
    else {
      cls += ' col no-padding';
    }
    return cls;
  }


  init(): void {
    super.init();
  }

  reload(): void { }

  reset(): void { }

  onValueChange(eventArgs) {
    this.onChange();
  }
    
  autoSelectFirstItem(): void {}

  set value(value: any) {
    this._value = value;

    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this.editorForm.controls.editor.value;
  }

  constructor(protected fb: FormBuilder) {
    super(fb);
  }

  ngOnInit(): void { }
}
