import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { delay, map, shareReplay } from 'rxjs/operators';
import { ConfigurationSettingsService } from '../services/configuration-settings/configuration-settings.service';
import { InitializeUserState } from '../actions/generic/user-state.actions';
import { Store } from '@ngxs/store';
import { StateVersionService } from '../services/state/state-version.service';

@Injectable()
export class UserStateResolver implements Resolve<boolean> {
  private cache$: Observable<any>;

  constructor(
    private configurationSettingsService: ConfigurationSettingsService,
    private stateVersionService: StateVersionService,
    private store: Store
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    let req = this.configurationSettingsService.initializeUserStateSettings().pipe(
      map((result: any) => {
        const action = new InitializeUserState(result.items);
        this.store.dispatch(action);
        this.stateVersionService.check();

        return true;
      }),
      delay(250)
    );

    if (!this.cache$) {
      this.cache$ = req.pipe(shareReplay(1));
    }

    return this.cache$;
  }
}
