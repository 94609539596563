import { Component, OnInit } from '@angular/core';
import { BooleanQueryItemDto } from 'src/core/models/query/boolean-query-item.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { Operators } from 'src/core/models/request/operator.enum';

@Component({
  selector: 'ca-query-tree-node-screen-record',
  templateUrl: './query-tree-node-screen-record.component.html',
  styleUrls: ['./query-tree-node-screen-record.component.scss'],
})
export class QueryTreeNodeScreenRecordComponent extends QueryEditorBase implements OnInit {
  payload: BooleanQueryItemDto;

  operatorList = [
    {
      notation: this.operators.Equals,
      label: 'Conversation::Exists',
      icon: null,
    },
    {
      notation: this.operators.NotEquals,
      label: 'Conversation::DoesNotExist',
      icon: null,
    },
  ];

  constructor(private operators: Operators) {
    super();
  }

  getOperatorLabel(operator) {
    return this.operatorList.find(x => x.notation == operator).label;
  }

  getOperatorIcon(operator) {
    return this.operatorList.find(x => x.notation == operator).icon;
  }

  ngOnInit(): void {
    this.payload = this.node.categoryItem.payload as BooleanQueryItemDto;
    this.node.validationStatus = true;
  }
}
