import { DetectedAudioContent } from 'src/core/models/speech-recognition/detected-audio-content.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecorderService } from 'src/core/services/conversation/recorder.service';
import { PlayerComponent, PlayerControllerComponent } from 'src/ca-shared/player/player.module';
import { TranscriptRow } from 'src/core/models/conversation/transcript/transcript-row.model';
import { PermissionService, LocalizationService } from '@abp/ng.core';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { CallAnalysisDto } from 'src/core/models/call/call-analysis.dto';
import { CallTranscriptDto } from 'src/core/models/call/call-transcript.dto';
import { CallService } from 'src/core/services/conversation/call.service';
import { CallAnalysisStatus } from 'src/core/models/generic-lookup-type/call/call-analysis-status.glt';
import { TranscriptWord } from 'src/core/models/conversation/transcript/transcript-word.model';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { ConfigStateService } from '@abp/ng.core';
import { SpeechRecognitionStatus } from 'src/core/models/speech-recognition/speech-recognition-status.enum';
import { SpeechRecognitionResult } from 'src/core/models/speech-recognition/speech-recognition-result.model';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';
import { take, takeUntil } from 'rxjs';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';

export interface DataTableTranscripts {
  chat: string;
  startTime: string;
  channel: number;
}

@Component({
  selector: 'ca-call-transcript-panel',
  templateUrl: './call-transcript-panel.component.html',
  styleUrls: ['./call-transcript-panel.component.scss'],
})
@AutoUnsubscribe()
export class CallTranscriptPanelComponent extends HasSubscription implements OnInit, AfterViewInit {
  transcriptDisplayedColumns: string[] = ['startTime', 'channel', 'action', 'chat'];
  @ViewChild('transcriptContainer', {
    read: ViewContainerRef,
  })
  transcriptContainer: ViewContainerRef;

  @ViewChild('speechRecognitionResultModal') speechRecognitionResultModal: any;
  dirty = false;
  chatViewThreshold = 0;
  processing: boolean;
  editMode: boolean = false;
  canEditTranscript: boolean = false;
  canExportTranscript: boolean = false;
  dynamicDataMaskingEnabled: boolean = false;
  recording = false;
  recordingRowIndex: number | null;
  recognitionStatus: SpeechRecognitionStatus;
  recognizingRowData: TranscriptRow;
  private _transcripts: CallTranscriptDto[];
  private _modifiedTranscripts: CallTranscriptDto[];
  private _analysisData: CallAnalysisDto;
  dataTableTranscripts: TranscriptRow[] = [];
  speechRecognitionResults: SpeechRecognitionResult[] = [];
  callAnalysisType = CallAnalysisStatus;
  speechRecognitionStatus = SpeechRecognitionStatus;
  speechRecognitionResult: SpeechRecognitionResult;
  distanceResult: number | null;
  recordingMessage: string;
  hideAgentTranscript = false;
  private lastHighlighteds = [];
  private lastHighlightedTime = null;
  private lastScrollIndex = null;
  private clsHighlighted = 'highlighted';
  private transcriptScrollOptions = { behavior: 'smooth', block: 'center', inline: 'nearest' };
  private _practiceMode = false;
  private readonly speechRecognitionFailureLimit: number;

  indexedWordContainers: any;
  currentPlayingRow = 0;
  continuePlayingTimeout: any;
  recordingInProgress = false;
  speechRecognitionFailed = false;
  speechRecognitionFailureCount = 0;
  pointsToSucceed: number;
  silenceTimeout: number;

  @ViewChildren('wordContainer') wordContainers: QueryList<ElementRef>;

  @Input()
  enableDictation = false;

  @Input()
  conversationId: number;

  @Input()
  recordingFileName: string;

  @Input()
  player: PlayerComponent;

  @Input()
  playerController: PlayerControllerComponent;

  @Input()
  set practiceMode(val: boolean) {
    this._practiceMode = val;
    this.hideAgentTranscript = !val;

    this.speechRecognitionResults = [];
    this.currentPlayingRow = 0;
    this.speechRecognitionFailureCount = 0;
    this.speechRecognitionFailed = false;
    this.recordingInProgress = false;

    if (this.player) {
      this.player.stop();
    }
  }

  get practiceMode() {
    return this._practiceMode;
  }

  @Input()
  showEditor: boolean = false;

  @Input()
  set analysisData(analysisData: CallAnalysisDto) {
    this._analysisData = analysisData;
  }

  @Input()
  set transcripts(transcripts: CallTranscriptDto[]) {
    this._transcripts = transcripts;
    this._modifiedTranscripts = transcripts;
    if (this._transcripts.length > 0) {
      this.fillDataTableTranscript(this._transcripts);
    }
  }

  @Input()
  enableExport = true;

  @Output()
  excelExportRequested: EventEmitter<{
    data: any;
  }> = new EventEmitter();

  get analysisData(): CallAnalysisDto {
    return this._analysisData;
  }
  get transcripts(): CallTranscriptDto[] {
    return this._transcripts;
  }
  get isTranscriptEditorVisible() {
    if (this.showEditor && this.canEditTranscript && !this.dynamicDataMaskingEnabled && this.transcripts.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  get isTranscriptExportVisible() {
    if (this.canExportTranscript && this.transcripts.length > 0 && this.enableExport) {
      return true;
    } else {
      return false;
    }
  }

  get recognitionStatusMessage(): string {
    const messages = {};

    messages[SpeechRecognitionStatus.Exporting] = 'Exporting';
    messages[SpeechRecognitionStatus.Recognizing] = 'Recognizing';
    messages[SpeechRecognitionStatus.Finished] = 'Finished';

    return messages[this.recognitionStatus];
  }

  @Output()
  rowClick: EventEmitter<{
    transcriptRow: TranscriptRow;
  }> = new EventEmitter();

  @Output()
  transcriptSaved: EventEmitter<{ transcripts: CallTranscriptDto[] }> = new EventEmitter();

  @Output()
  transcriptSaveFailed = new EventEmitter();

  @Output()
  recognitionScoreUpdated: EventEmitter<{
    score: number;
  }> = new EventEmitter();

  constructor(
    private permissionService: PermissionService,
    private callService: CallService,
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private confirmationService: ConfirmationService,
    private config: ConfigStateService,
    private modalService: NgbModal,
    private recorderService: RecorderService,
    private caTimePipe: CATimePipe,
    private globalSettingsService: GlobalSettingsService,
  ) {
    super();

    this.canEditTranscript = this.permissionService.getGrantedPolicy(
      'Conversation.Transcript._Edit'
    );

    this.canExportTranscript = this.permissionService.getGrantedPolicy(
      'Conversation.Transcript.Export'
    );

    this.dynamicDataMaskingEnabled = this.globalSettingsService.dynamicDataMaskingEnabled;

    this.chatViewThreshold = parseInt(this.config.getSetting('Conversation.ChatViewThreshold'), 10);
    this.pointsToSucceed = parseInt(
      this.config.getSetting('SpeechRecognition.PointsToSucceed'),
      10
    );
    this.silenceTimeout = parseInt(this.config.getSetting('SpeechRecognition.SilenceTimeout'), 10);
    this.speechRecognitionFailureLimit = parseInt(
      this.config.getSetting('SpeechRecognition.MaxNumberOfTriesToSkipRow'),
      10
    );

    this.recorderService.finishRecognition
      .pipe(takeUntil(this.autoUnsubscribeNotifier))
      .subscribe(this.onFinishRecognition.bind(this));
    this.recorderService.exceedSilenceThreshold
      .pipe(takeUntil(this.autoUnsubscribeNotifier))
      .subscribe(this.onExceedSilenceThreshold.bind(this));
  }
  ngOnInit(): void {
    if (this.player) {
      this.player.positionChange
        .pipe(takeUntil(this.autoUnsubscribeNotifier))
        .subscribe(this.highlightWord.bind(this));
      this.player.startPlaying
        .pipe(takeUntil(this.autoUnsubscribeNotifier))
        .subscribe(this.onPlayerStart.bind(this));
      this.player.pausePlaying
        .pipe(takeUntil(this.autoUnsubscribeNotifier))
        .subscribe(this.onPlayerPause.bind(this));
      this.player.finishPlaying
        .pipe(takeUntil(this.autoUnsubscribeNotifier))
        .subscribe(this.onPlayerFinish.bind(this));
    }
  }

  // 1 -> Agent
  // 2 -> Customer
  onPlayerStart(eventArgs: { byUserInteraction: boolean }): void {
    if (eventArgs.byUserInteraction && this.enableDictation) {
      if (
        this.speechRecognitionFailed &&
        this.speechRecognitionFailureCount < this.speechRecognitionFailureLimit
      ) {
        this.toastr.warn(
          this.localizationService.instant(
            'SpeechRecognition::SpeechRecognitionFailureOnRow',
            (this.currentPlayingRow + 1).toString()
          )
        );
        this.player.stop();

        return;
      }

      if (this.currentPlayingRow === 0) {
        this.player.stop();
      }

      if (!this.recordingInProgress) {
        this.processRow();
      }
    }
  }

  onPlayerPause(eventArgs: { byUserInteraction: boolean }): void {
    // stopped by user
    if (this.player.currentTime === 0 && eventArgs.byUserInteraction) {
      clearTimeout(this.continuePlayingTimeout);
      this.currentPlayingRow = 0;
      this.speechRecognitionResults = [];
      this.speechRecognitionFailureCount = 0;
      this.calculateRecognitionScore();
    } else if (eventArgs.byUserInteraction) {
      // paused by user
      clearTimeout(this.continuePlayingTimeout);
    }
  }

  onPlayerFinish(): void {
    clearTimeout(this.continuePlayingTimeout);
    this.currentPlayingRow = 0;
    this.speechRecognitionFailureCount = 0;
    this.speechRecognitionFailed = false;
  }

  onExceedSilenceThreshold(): void {
    this.recording = false;
  }

  processRow(): void {
    if (this.currentPlayingRow >= this.dataTableTranscripts.length) {
      clearTimeout(this.continuePlayingTimeout);
      this.currentPlayingRow = 0;
      this.speechRecognitionFailureCount = 0;
      this.speechRecognitionFailed = false;

      return;
    }

    const activeRow = this.dataTableTranscripts[this.currentPlayingRow];

    const fn = activeRow.channel === 1 ? this.processAgentRow : this.processCustomerRow;

    fn.call(this);
  }

  processAgentRow(): void {
    this.playerController.disableButtons = true;

    const rowData = this.dataTableTranscripts[this.currentPlayingRow];

    this.recordingInProgress = true;

    this.startRecording(this.currentPlayingRow, rowData);
  }

  processCustomerRow(): void {
    const activeRow = this.dataTableTranscripts[this.currentPlayingRow];

    const startPosition = this.currentPlayingRow === 0 ? 0 : activeRow.startMillisecond;
    const timeout = activeRow.endMillisecond - startPosition;

    this.player.play(startPosition / 1000, activeRow.endMillisecond / 1000);

    this.continuePlayingTimeout = setTimeout(() => {
      this.currentPlayingRow++;
      this.processRow();
    }, timeout);
  }

  fillDataTableTranscript(data: any[]) {
    this.dataTableTranscripts = [];

    let row: TranscriptRow;

    for (let i = 0; i < data.length; i++) {
      const curr = data[i];

      if (i === 0 || curr.channel !== row.channel) {
        let currentChannelRows = this.dataTableTranscripts.filter(x => x.channel == curr.channel);
        let channelLastRow = currentChannelRows[currentChannelRows.length - 1];
        if (
          channelLastRow &&
          channelLastRow.endMillisecond + this.chatViewThreshold >= curr.startTime
        ) {
          channelLastRow.words.push({
            startTime: curr.startTime,
            word: curr.word,
            endTime: curr.endTime,
          });
          continue;
        } else {
          row = {
            time: this.calculateTime(curr.startTime),
            startMillisecond: curr.startTime,
            endMillisecond: curr.endTime,
            channel: curr.channel,
            words: [],
          };
          this.dataTableTranscripts.push(row);
        }
      }
      row.endMillisecond = curr.endTime;
      row.words.push({
        startTime: curr.startTime,
        word: curr.word,
        endTime: curr.endTime,
      });
    }

    this.currentPlayingRow = 0;
  }

  // index span elements related to channel and time info to access them easily later (while highlighting)
  public setupWordContainers() {
    this.indexedWordContainers = {
      1: [],
      2: [],
    };
    this.wordContainers.forEach(el => {
      const nativeEl = el.nativeElement;
      let channel = nativeEl.getAttribute('data-channel');
      let time = nativeEl.getAttribute('data-time');
      let endTime = nativeEl.getAttribute('data-end-time');
      let index = nativeEl.getAttribute('data-index');
      channel = parseInt(channel, 10);
      time = parseFloat(time).toFixed(2);

      this.indexedWordContainers[channel].push({
        time: time,
        nativeEl: nativeEl,
        endTime: endTime,
        index: index,
      });
    });
  }

  calculateTime(currentTime: number) {
    const seconds = Math.floor(currentTime / 1000);
    return this.caTimePipe.transform(seconds);
  }

  highlightWord(playerArgs: { currentTime: number; currentTimeString: string }) {
    if (this.indexedWordContainers) {
      const t = playerArgs.currentTime.toFixed(2); // ms to sec

      if (
        playerArgs.currentTime === 0 ||
        this.lastHighlightedTime > playerArgs.currentTime ||
        playerArgs.currentTime - this.lastHighlightedTime > 1
      ) {
        this.clearAllHighlights(this.lastHighlighteds);
      }

      const sorted = Array.from(this.dataTableTranscripts);

      if (!this.lastHighlightedTime) {
        this.lastHighlightedTime = sorted
          .sort((a, b) => b.startMillisecond - a.startMillisecond)
          .find(x => x.startMillisecond < playerArgs.currentTime * 1000);
      }

      var wordArray = [];
      for (let container in this.indexedWordContainers) {
        wordArray = wordArray.concat(
          this.indexedWordContainers[container].filter(
            x => x.time <= playerArgs.currentTime && x.time > this.lastHighlightedTime
          )
        );
      }

      if (wordArray.length > 0) {
        if (this.practiceMode) {
          wordArray.forEach(element => {
            let elementIndex = parseInt(element.index);
            if (this.lastScrollIndex == null || elementIndex > this.lastScrollIndex) {
              element.nativeEl.scrollIntoView(this.transcriptScrollOptions);
              this.lastScrollIndex = elementIndex;
            }
          });
        } else {
          this.clearHighlight(this.lastHighlighteds, playerArgs.currentTime);
          wordArray.forEach(element => {
            element.nativeEl.classList.add(this.clsHighlighted);
            let elementIndex = parseInt(element.index);
            if (this.lastScrollIndex == null || elementIndex > this.lastScrollIndex) {
              element.nativeEl.scrollIntoView(this.transcriptScrollOptions);
              this.lastScrollIndex = elementIndex;
            }
            this.lastHighlighteds.push(element);
          });
        }
      }
      this.lastHighlightedTime = playerArgs.currentTime;
    }
  }
  onEdit() {
    this.player.pause();
    this.editMode = true;
  }

  clearAllHighlights(lastHighlighteds: any[]) {
    if (!this.practiceMode && lastHighlighteds.length > 0) {
      lastHighlighteds.forEach(lastHighlighted => {
        lastHighlighted.nativeEl.classList.remove(this.clsHighlighted);
      });
      this.lastHighlighteds = [];
    }
    this.lastHighlightedTime = null;
    this.lastScrollIndex = null;
  }
  clearHighlight(lastHighlighteds: any[], time: number) {
    if (lastHighlighteds.length > 0) {
      lastHighlighteds
        .filter(x => x.endTime < time)
        .forEach(lastHighlighted => {
          lastHighlighted.nativeEl.classList.remove(this.clsHighlighted);
        });
    }
  }
  onTranscriptEdited(eventArgs: { transcripts: CallTranscriptDto[] }) {
    this._transcripts = eventArgs.transcripts;
    this.fillDataTableTranscript(this._transcripts);
  }
  ngAfterViewInit() {
    this.wordContainers.changes.pipe(takeUntil(this.autoUnsubscribeNotifier)).subscribe(r => {
      this.setupWordContainers();
    });
  }
  onRowDoubleClick(eventArgs, transcriptRow: TranscriptRow) {
    if (!(this.hideAgentTranscript && transcriptRow.channel == 1)) {
      this.rowClick.emit({ transcriptRow: transcriptRow });
    }
  }
  onTranscriptRowChanged(
    eventArgs: { oldWords: TranscriptWord[]; newWords: TranscriptWord[] },
    channel: number
  ) {
    this.dirty = true;

    eventArgs.oldWords.forEach(element => {
      this._modifiedTranscripts = this._modifiedTranscripts.filter(
        x =>
          !(
            x.channel === channel &&
            x.startTime == element.startTime &&
            x.endTime == element.endTime &&
            x.word === element.word
          )
      );
    });

    eventArgs.newWords.forEach(element => {
      this._modifiedTranscripts.push({
        channel: channel,
        startTime: element.startTime,
        endTime: element.endTime,
        word: element.word,
        rangePercentage: 0,
      });
    });

    this._modifiedTranscripts.sort((a, b) => a.startTime - b.startTime);
  }

  saveTranscript() {
    this.processing = true;
    this.callService
      .updateTranscript(this.conversationId, this._modifiedTranscripts)
      .pipe(take(1))
      .subscribe({
        next: result => {
          this.processing = false;
          this.toastr.success(this.localizationService.instant('::SuccessfullySaved'));
          this.editMode = false;
          this.transcripts = this._modifiedTranscripts;
          this.transcriptSaved.emit({ transcripts: this.transcripts });
        },
        error: () => {
          this.processing = false;
          this.transcriptSaveFailed.emit();
        },
      });
  }

  saveCategoryTranscript() {
    this.processing = true;
    this.callService
      .updateCategoryTranscript(this.conversationId, this._modifiedTranscripts)
      .pipe(take(1))
      .subscribe({
        next: result => {
          this.processing = false;
          this.toastr.success(this.localizationService.instant('::SuccessfullySaved'));
          this.editMode = false;
          this.transcripts = this._modifiedTranscripts;
          this.transcriptSaved.emit({ transcripts: this.transcripts });
        },
        error: () => {
          this.processing = false;
          this.transcriptSaveFailed.emit();
        },
      });
  }

  deleteCategoryTranscript() {
    this.processing = true;
    this.callService
      .deleteCategoryTranscript(this.conversationId, this._modifiedTranscripts)
      .pipe(take(1))
      .subscribe({
        next: result => {
          this.processing = false;
          this.toastr.success(this.localizationService.instant('::SuccessfullyDeleted'));
          this.editMode = false;
          this.transcripts = this._modifiedTranscripts;
          this.transcriptSaved.emit({ transcripts: this.transcripts });
        },
        error: () => {
          this.processing = false;
          this.transcriptSaveFailed.emit();
        },
      });
  }

  cancelEdit() {
    if (this.dirty) {
      this.confirmationService
        .warn('::SaveWithoutChangeConfirmationMessage', '', {
          messageLocalizationParams: [
            this.localizationService.instant('Conversation::EditTranscript'),
          ],
          yesText: '::ContinueWithoutSave',
        })
        .pipe(take(1))
        .subscribe((status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.editMode = false;
            this._modifiedTranscripts = this.transcripts;
            this.dirty = false;
          }
        });
    } else {
      this.editMode = false;
    }
  }

  onFinishRecognition(result: SpeechRecognitionResult): void {
    this.playerController.disableButtons = false;
    this.recording = false;

    this.speechRecognitionResult = result;
    this.speechRecognitionResult.referenceText = this.joinWordsOfRecognizingRowData();
    this.recognitionStatus = SpeechRecognitionStatus.Finished;

    if (
      this.speechRecognitionResult.detectedAudioContent ===
      DetectedAudioContent.unrecognizableSpeech
    ) {
      this.speechRecognitionResult.success = false;
      this.speechRecognitionResult.resultText =
        '[' + this.localizationService.instant('SpeechRecognition::UnrecognizableSpeech') + ']';
    }

    this.speechRecognitionResults[this.currentPlayingRow] = ObjectHelper.deepCopy(
      this.speechRecognitionResult
    );

    if (!this.practiceMode) {
      this.calculateRecognitionScore();
    }

    if (
      this.speechRecognitionResult.success &&
      this.speechRecognitionResult.distanceScore >= this.pointsToSucceed
    ) {
      this.speechRecognitionFailed = false;
      this.speechRecognitionFailureCount = 0;
      this.currentPlayingRow++;
      this.recordingInProgress = false;
      this.processRow();
    } else {
      this.speechRecognitionFailed = true;
      this.speechRecognitionFailureCount++;

      // Skip failures if test mode is active.
      if (!this.practiceMode) {
        this.speechRecognitionFailureCount = 0;
        this.speechRecognitionFailed = false;
        this.currentPlayingRow++;
        this.recordingInProgress = false;
        this.processRow();
      }
    }

    if (this.speechRecognitionFailureCount >= this.speechRecognitionFailureLimit) {
      this.speechRecognitionFailed = false;
      this.speechRecognitionFailureCount = 0;
      this.currentPlayingRow++;
      this.processRow();
    }
  }

  openResultModal(): void {
    this.modalService.open(this.speechRecognitionResultModal, {
      size: 'lg',
      scrollable: false,
      backdrop: 'static',
      keyboard: false,
    });
  }

  joinWordsOfRecognizingRowData(): string {
    const words = [];

    for (let i = 0; i < this.recognizingRowData.words.length; i++) {
      words.push(this.recognizingRowData.words[i].word);
    }

    return words.join(' ');
  }

  onClickRecordButton(eventArgs: MouseEvent, element: TranscriptRow, rowIndex: number): void {
    this.speechRecognitionResults[rowIndex] = null;

    if (!this.recording) {
      this.startRecording(rowIndex, element);
    } else {
      this.stopRecording();
    }
  }

  startRecording(rowIndex: number, rowData: TranscriptRow): void {
    this.recording = true;
    this.recognizingRowData = rowData;
    this.recordingRowIndex = rowIndex;

    const rowText = this.joinWordsOfRecognizingRowData();

    this.recorderService.startRecording(
      this.conversationId,
      this.recordingRowIndex,
      rowText,
      this.silenceTimeout
    );
  }

  stopRecording(): void {
    this.recording = false;
    this.recognitionStatus = SpeechRecognitionStatus.Exporting;
    this.speechRecognitionResult = {
      detectedAudioContent: '',
      errorCode: '',
      errorMessage: '',
      resultText: '-',
      success: false,
      moreInfo: '',
      distanceScore: null,
      referenceText: '',
    };
    this.distanceResult = null;
    this.recorderService.stopRecording();
  }

  closeSpeechRecognitionResultModal(): void {
    this.modalService.dismissAll();
  }

  exportTranscript() {
    var headers = [
      this.localizationService.instant('::Time'),
      this.localizationService.instant('::Channel'),
      this.localizationService.instant('::Chat'),
    ];

    let excelData = this.populateExcelData();

    let reportData = {
      data: excelData,
      headers: headers,
    };
    this.excelExportRequested.emit({ data: reportData });
  }

  private calculateRecognitionScore(): void {
    let score = 0;
    let count = 0;

    if (this.speechRecognitionResults.length <= 0) {
      score = 0;
    } else {
      for (let i = 0; i < this.speechRecognitionResults.length; i++) {
        score += this.speechRecognitionResults[i]
          ? this.speechRecognitionResults[i].distanceScore
          : 0;

        count += this.speechRecognitionResults[i] ? 1 : 0;
      }

      score = Math.round(score / count);
    }

    this.recognitionScoreUpdated.emit({ score: score });
  }

  private populateExcelData(): any[] {
    let excelData = [];
    this.dataTableTranscripts.forEach(transcript => {
      let sentence = '';
      transcript.words.forEach(wordObj => {
        sentence += wordObj.word + ' ';
      });
      let channel =
        transcript.channel === 1
          ? this.localizationService.instant('Conversation::Agent')
          : this.localizationService.instant('Conversation::Customer');
      let row = [transcript.time, channel, sentence];
      excelData.push(row);
    });
    return excelData;
  }
}
