import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';

@Component({
  selector: 'ca-topic-training-failed-notification',
  templateUrl: './topic-training-failed-notification.component.html',
  styleUrls: ['./topic-training-failed-notification.component.scss'],
})
@NotificationType(
  'TopicTrainingFailedNotification',
  TopicTrainingFailedNotificationComponent
)
export class TopicTrainingFailedNotificationComponent implements OnInit {
  data: NotificationDto;

  get message(): string {
    return this.data ? JSON.parse(this.data.payload).message : '';
  }

  constructor() { }

  ngOnInit(): void { }

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
  ) {
    const parsedPayload = JSON.parse(payload);
    let message = localizationService.instant(
      'AITopic::TopicTrainingFailedMessage',
      parsedPayload.message
    );
    toastr.error(message);
  }
}
