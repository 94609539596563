import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TagDto } from 'src/core/models/conversation/tag/tag.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { TagQueryItemDto } from 'src/core/models/query/tag-query-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'ca-query-tree-node-tag',
  templateUrl: './query-tree-node-tag.component.html',
  styleUrls: ['./query-tree-node-tag.component.scss'],
})
export class QueryTreeNodeTagComponent extends QueryEditorBase implements OnInit {
  payload: TagQueryItemDto;
  tagForm: FormGroup;
  currentTagName: string;
  currentTagId: number;
  isInitialLoad = true;

  constructor(
    private operators: Operators,
    private localizationService: LocalizationService,
    private fb: FormBuilder,
    private service: CrudService
  ) {
    super();

    this.tagForm = fb.group(
      {
        tag: [null, { validators: [Validators.required], updateOn: 'change' }],
      },
      { updateOn: 'blur' }
    );

    this.tagForm.get('tag').valueChanges.subscribe((selectedTag: TagDto) => {
      this.onChange(selectedTag);
    });

    this.tagForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as TagQueryItemDto;
    this.payload.operator = this.operators.Equals;
  }

  ngAfterViewInit() {
    if (this.payload.tagId) {
      this.service.getById<TagDto>(TagDto, this.payload.tagId).subscribe(response => {
        this.currentTagName = response.name;
        this.tagForm.controls['tag'].setValue(response);
      });
    }
  }

  onChange(selectedTag: TagDto) {
    if (this.isInitialLoad) {
      this.isInitialLoad = false;
    } else {
      this.node.isDirty = true;
    }

    if (selectedTag) {
      this.payload.tagId = selectedTag.id;
    }
  }
}
