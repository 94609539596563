import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { QueryBuilderCategoryDto } from 'src/core/models/query/query-builder-category.dto';
import { QueryGroupQueryItemDto } from 'src/core/models/query/query-group-query-item.dto';
import { IQueryBuilderCategoryItem } from 'src/core/models/query/query-builder-category-item.interface';
import { ConversationFilterableFields } from 'src/core/models/conversation/conversation-filterable-fields.enum';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { AdvancedOrderedGroupQueryItemDto } from 'src/core/models/query/advanced-ordered-group-query-item.dto';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { map, Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';

@Component({
  selector: 'ca-query-tree-node-advanced-ordered-group',
  templateUrl: './query-tree-node-advanced-ordered-group.component.html',
  styleUrls: ['./query-tree-node-advanced-ordered-group.component.scss'],
})
export class QueryTreeNodeAdvancedOrderedGroupComponent extends QueryEditorBase implements OnInit {
  payload: AdvancedOrderedGroupQueryItemDto;
  conversationSides$: Observable<GenericLookupDto[]>;
  queryForm: FormGroup = null;
  currentConversationSide: string;
  categoryItems: IQueryBuilderCategoryItem[] = [];

  private categories: QueryBuilderCategoryDto[];

  onMenuOpened() {
    const nodeButtons = (
      this.viewContainerRef.element.nativeElement as HTMLElement
    ).querySelectorAll('.show-on-hover');

    nodeButtons.forEach(btn => {
      btn.classList.add('show-on-hover-disabled');
    });
  }

  onMenuClosed() {
    const nodeButtons = (
      this.viewContainerRef.element.nativeElement as HTMLElement
    ).querySelectorAll('.show-on-hover');

    nodeButtons.forEach((btn: HTMLElement) => {
      btn.classList.remove('show-on-hover-disabled');
      btn.style.display = 'none';
    });
  }

  onAddNodeClick(category: QueryBuilderCategoryDto, categoryItem: IQueryBuilderCategoryItem) {
    this.queryBuilder.addNodeFromAnotherNode(this.node, this.node.category, categoryItem);
  }

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private genericLookupService: GenericLookupTypeService,
    private viewContainerRef: ViewContainerRef,
    public queryField: ConversationFilterableFields,
    public queryFieldDataType: QueryFieldDataType
  ) {
    super();

    this.conversationSides$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    this.queryForm = fb.group(
      {
        conversationSide: [null],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.valueChanges.subscribe(() => {
      this.node.isDirty = this.queryForm.dirty;
    });

    this.queryForm.get('conversationSide').valueChanges.subscribe(value => {
      this.payload.sideCode = this.genericLookupService.findGenericLookupWithId(
        this.payload.sideId
      )?.code;
    });
  }

  ngOnInit() {
    this.categories = this.queryBuilder.visibleCategories;
    this.categories
      ?.map(c => c.items)
      .reduce(
        (acc, cur) => (
          (this.categoryItems = this.categoryItems.concat(
            acc
              .concat(cur)
              .filter(
                ci =>
                  ci.dataType === this.queryFieldDataType.AdvancedOrderedNearTerm ||
                  ci.dataType === this.queryFieldDataType.AdvancedOrderedSimpleTerm
              )
          )),
          []
        )
      );

    this.payload = this.node.categoryItem.payload as AdvancedOrderedGroupQueryItemDto;
    this.node.validationStatus = true;

    this.conversationSides$.subscribe((sides: GenericLookupDto[]) => {
      const conversationSide = sides.find(x => x.id == this.payload.sideId);
      this.currentConversationSide = conversationSide.name;
      this.payload.sideCode = conversationSide.code;
    });
  }
}
