import { Api } from 'src/core/decorators/dto/api.decorator';
import { DepartmentDto } from '../administration/department/department.dto';
import { DtoHasApi } from '../shared/dto-has-api.model';

@Api('api/app/configuration-settings/category-exception')
export class CategoryExceptionDto extends DtoHasApi {
  id: number;
  agentBeginningWordCount: number;
  agentEndingWordCount: number;
  customerBeginningWordCount: number;
  customerEndingWordCount: number;
  seperateValueForChannelsEnabled: boolean;
  departments: DepartmentDto[];
}
