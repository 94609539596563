import { PermissionDto } from './permission.dto';
import { DtoHasApi } from '../../shared/dto-has-api.model';
import { Api } from 'src/core/decorators/dto/api.decorator';

@Api('api/permission-management/permissions')
export class PermissionGroupDto extends DtoHasApi {
  name: string;
  displayName: string;
  permissions: PermissionDto[];
}
