import { HttpHeaders, HttpParams } from '@angular/common/http';
import {
  OAuthService,
  TokenResponse,
  OAuthSuccessEvent,
  OAuthErrorEvent,
} from 'angular-oauth2-oidc';

// Compose the operator:

function extractRecognizedCustomParametersNew(tokenResponse: TokenResponse): Map<string, string> {
  let foundParameters: Map<string, string> = new Map<string, string>();
  if (!this.config.customTokenParameters) {
    return foundParameters;
  }
  this.config.customTokenParameters.forEach((recognizedParameter: string) => {
    if (tokenResponse[recognizedParameter]) {
      foundParameters.set(recognizedParameter, JSON.stringify(tokenResponse[recognizedParameter]));
    }
  });
  return foundParameters;
}

function fetchTokenUsingGrant(
  this: OAuthService,
  grantType: string,
  parameters: object,
  headers: HttpHeaders = new HttpHeaders()
): Promise<TokenResponse> {
  this.assertUrlNotNullAndCorrectProtocol(this.tokenEndpoint, 'tokenEndpoint');

  /**
   * A `HttpParameterCodec` that uses `encodeURIComponent` and `decodeURIComponent` to
   * serialize and parse URL parameter keys and values.
   *
   * @stable
   */
  let params = new HttpParams().set('grant_type', grantType).set('scope', this.scope);

  if (this.useHttpBasicAuth) {
    const header = btoa(`${this.clientId}:${this.dummyClientSecret}`);
    headers = headers.set('Authorization', 'Basic ' + header);
  }

  if (!this.useHttpBasicAuth) {
    params = params.set('client_id', this.clientId);
  }

  if (!this.useHttpBasicAuth && this.dummyClientSecret) {
    params = params.set('client_secret', this.dummyClientSecret);
  }

  if (this.customQueryParams) {
    for (const key of Object.getOwnPropertyNames(this.customQueryParams)) {
      params = params.set(key, this.customQueryParams[key]);
    }
  }

  // set explicit parameters last, to allow overwriting
  for (const key of Object.keys(parameters)) {
    params = params.set(key, parameters[key]);
  }

  headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

  return new Promise((resolve, reject) => {
    this.http.post<TokenResponse>(this.tokenEndpoint, params, { headers }).subscribe({
      next: tokenResponse => {
        this.debug('tokenResponse', tokenResponse);
        this.storeAccessTokenResponse(
          tokenResponse.access_token,
          tokenResponse.refresh_token,
          tokenResponse.expires_in || this.fallbackAccessTokenExpirationTimeInSec,
          tokenResponse.scope,
          this.extractRecognizedCustomParametersNew(tokenResponse)
        );
        if (this.oidc && tokenResponse.id_token) {
          this.processIdToken(tokenResponse.id_token, tokenResponse.access_token).then(result => {
            this.storeIdToken(result);
            resolve(tokenResponse);
          });
        }
        this.eventsSubject.next(new OAuthSuccessEvent('token_received'));
        resolve(tokenResponse);
      },
      error: err => {
        this.logger.error('Error performing ${grantType} flow', err);
        this.eventsSubject.next(new OAuthErrorEvent('token_error', err));
        reject(err);
      },
    });
  });
}

// Add the operator to the Observable prototype:

OAuthService.prototype.fetchTokenUsingGrant = fetchTokenUsingGrant;
OAuthService.prototype.extractRecognizedCustomParametersNew = extractRecognizedCustomParametersNew;

// Extend the TypeScript interface for Observable to include the operator:

declare module 'angular-oauth2-oidc/oauth-service' {
  interface OAuthService {
    fetchTokenUsingGrant: typeof fetchTokenUsingGrant;
    extractRecognizedCustomParametersNew: typeof extractRecognizedCustomParametersNew;
  }
}
