import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReasonPoolService {
  constructor() {}

  @Output()
  changed = new EventEmitter();

  notify(): void {
    this.changed.emit();
  }
}
