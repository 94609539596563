import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../shared/dto-has-api.model';

@Api('api/app/reason')
export class ReasonDto extends DtoHasApi {
  id: number;
  text: string;
  reasonTypeId: number;
  reasonTypeCode?: string;
  lastModificationDate: Date;
}
