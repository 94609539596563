import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ValidationHelper } from 'src/core/helpers/validation-helper';
import { TranscriptRow } from 'src/core/models/conversation/transcript/transcript-row.model';
import { TranscriptWord } from 'src/core/models/conversation/transcript/transcript-word.model';

@Component({
  selector: 'ca-conversation-transcript-editor-input',
  templateUrl: './conversation-transcript-editor-input.component.html',
  styleUrls: ['./conversation-transcript-editor-input.component.scss'],
})
export class ConversationTranscriptEditorInputComponent implements OnInit {
  @ViewChild('textBox', { static: true, read: ElementRef })
  textBox: ElementRef;

  @Input()
  rowInfo: TranscriptRow;

  @Input()
  autoFocus: boolean;

  @Output()
  changed: EventEmitter<{
    oldWords: TranscriptWord[];
    newWords: TranscriptWord[];
  }> = new EventEmitter();

  charRegex = ValidationHelper.getSpecialCharacterRegex();

  get words(): TranscriptWord[] {
    return this.rowInfo.words;
  }

  get mergedValue(): string {
    let result = '';
    this.words.forEach(word => {
      if (result != '') {
        result += ' ';
      }
      result += word.word;
    });
    return result;
  }
  wordSpace = 50;

  constructor() {}

  onChange(event: any) {
    let newWords = [];
    let value = event.target.value.replace(/\s\s+/g, ' ').trim();
    if (value !== '') {
      newWords = this.getChanges(value.split(' '));
    }

    this.changed.emit({ oldWords: this.words, newWords: newWords });
  }

  getChanges(modifiedTextArray: string[]): TranscriptWord[] {
    let newWords: TranscriptWord[] = [];

    if (modifiedTextArray.length !== 0) {
      if (modifiedTextArray.length != this.words.length) {
        let wordTime = parseInt(
          (
            (this.rowInfo.endMillisecond - this.rowInfo.startMillisecond) /
            modifiedTextArray.length
          ).toFixed()
        );
        let wordStartTime = this.rowInfo.startMillisecond;

        for (let i = 0; i < modifiedTextArray.length; i++) {
          const endTime = wordStartTime + wordTime;
          let transcriptWord = {
            startTime: wordStartTime,
            endTime: endTime - this.wordSpace,
            word: modifiedTextArray[i],
          };
          newWords.push(transcriptWord);
          wordStartTime += wordTime;
        }
      } else {
        for (let i = 0; i < modifiedTextArray.length; i++) {
          let transcriptWord = {
            startTime: this.words[i].startTime,
            endTime: this.words[i].endTime,
            word: modifiedTextArray[i],
          };

          newWords.push(transcriptWord);
        }
      }
    }

    return newWords;
  }

  controlValue(eventArgs: any) {
    eventArgs.target.value = eventArgs.target.value.replace(this.charRegex, '');
    eventArgs.preventDefault();
    eventArgs.stopPropagation();

    return false;
  }
  ngOnInit(): void {
    if (this.autoFocus) {
      this.textBox.nativeElement.focus();
    }
  }
}
