import { State, Action, StateContext, Selector } from '@ngxs/store';
import { AdministrationModuleStateModel } from 'src/core/models/administration/administration-module.state-model';
import {
  SectionChange,
  TitleChange,
} from 'src/core/actions/administration/administration-module.actions';
import { Injectable } from '@angular/core';

@State<AdministrationModuleStateModel>({
  name: 'AdministrationModuleState',
  defaults: {
    section: '',
    title: '',
  } as AdministrationModuleStateModel,
})
@Injectable()
export class AdministrationModuleState {
  @Selector()
  static section(state: AdministrationModuleStateModel): string {
    return state.section;
  }

  @Selector()
  static title(state: AdministrationModuleStateModel): string {
    return state.title;
  }

  @Action(SectionChange)
  changeSection(ctx: StateContext<AdministrationModuleStateModel>, action: SectionChange) {
    const currentState = ctx.getState();
    currentState.section = action.section;

    ctx.patchState(currentState);
  }

  @Action(TitleChange)
  changeTitle(ctx: StateContext<AdministrationModuleStateModel>, action: TitleChange) {
    const currentState = ctx.getState();
    currentState.title = action.title;

    ctx.patchState(currentState);
  }

  constructor() {}
}
