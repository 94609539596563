import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'ca-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent implements OnInit, AfterViewInit {
  private _value: string | number;
  private _maximumValue: number;

  @ViewChild('rating', { static: false, read: ViewContainerRef })
  rating: ViewContainerRef;

  @Input()
  alwaysFill = false;

  @Input()
  set maximumValue(value: number) {
    this._maximumValue = value;

    this.calculteStyle();

    this.cdr.detectChanges();
  }

  get maximumValue(): number {
    return this._maximumValue;
  }

  @Input()
  colorCalculatorFn: (v: number | string) => string = (v: number | string) => {
    return '#27ae60';
  };

  @Input()
  set value(val: string | number) {
    this._value = val;

    this.calculteStyle();

    this.cdr.detectChanges();
  }

  get value(): string | number {
    return this._value;
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.calculteStyle();
  }

  calculteStyle(): void {
    if (this.rating) {
      const color = this.colorCalculatorFn(this._value);

      this.rating.element.nativeElement.style.color = color;
      this.rating.element.nativeElement.style.backgroundColor = color;

      const ratingColor = window.getComputedStyle(
        this.rating.element.nativeElement
      ).backgroundColor;

      let gradientValue = 0;

      if (this.alwaysFill) {
        gradientValue = 100;
      } else if (typeof this._value === 'number' && this.maximumValue > 0) {
        gradientValue = Math.round((100 * this._value) / this.maximumValue);
      } else {
        gradientValue = 0;
      }

      const gradient = `background: conic-gradient(${ratingColor} ${gradientValue}%, transparent 0 100%)`;

      this.rating.element.nativeElement.setAttribute('style', gradient);
    }
  }
}
