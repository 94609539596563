<footer class="lp-footer">
  <div class="d-flex">
    <div class="me-auto">
      <span style="vertical-align: -webkit-baseline-middle"
        >2019 - {{ currentYear }} © {{ appInfo.name }}</span
      ><br />
    </div>
    <ca-user-feedback></ca-user-feedback>
  </div>
</footer>
