import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class ColorUtils {
  getNguBlueColor(): any {
    return { background: 'var(--ngu-blue)', color: 'white' };
  }

  getColors(color: string): any {
    const rbg = this.hex2rgb(color);
    // tslint:disable-next-line: radix
    const o = Math.round(
      (parseInt(rbg[0]) * 299 + parseInt(rbg[1]) * 587 + parseInt(rbg[2]) * 114) / 1000
    );
    const force = o > 170 ? '#000' : 'white';
    return { background: color, color: force };
  }

  hex2rgb(colour) {
    let r;
    let g;
    let b;
    if (colour.charAt(0) === '#') {
      colour = colour.substr(1);
    }
    if (colour.length === 3) {
      colour =
        colour.substr(0, 1) +
        colour.substr(0, 1) +
        colour.substr(1, 2) +
        colour.substr(1, 2) +
        colour.substr(2, 3) +
        colour.substr(2, 3);
    }
    r = colour.charAt(0) + '' + colour.charAt(1);
    g = colour.charAt(2) + '' + colour.charAt(3);
    b = colour.charAt(4) + '' + colour.charAt(5);
    r = parseInt(r, 16);
    g = parseInt(g, 16);
    b = parseInt(b, 16);
    return [r, g, b];
  }
}
