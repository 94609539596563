import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';
import { AttachedDataService } from '../../services/attached-data.service';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs';
import { AttachedDataDto } from 'src/core/models/administration/attached-data/attached-data.dto';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { BaseDropdownSelectorComponent } from 'src/ui/base-selector/base-selector.module';

@Component({
  selector: 'ca-attached-data-selector',
  templateUrl: './attached-data-selector.component.html',
  styleUrls: ['./attached-data-selector.component.scss'],
  host: {
    class: 'ca-selector',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AttachedDataSelectorComponent),
    },
  ],
})
@AutoUnsubscribe()
export class AttachedDataSelectorComponent
  extends BaseDropdownSelectorComponent<AttachedDataDto>
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  constructor(
    protected elementRef: ElementRef,
    protected ngZone: NgZone,
    private service: AttachedDataService
  ) {
    super(elementRef, ngZone);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.quickSearchTermInput$
      .pipe(takeUntil(this.autoUnsubscribeNotifier), debounceTime(250), distinctUntilChanged())
      .subscribe(val => {
        this.pageIndex = 0;
        this.quickSearchTerm = val;

        this.load();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  compareFn(a: AttachedDataDto, b: AttachedDataDto): boolean {
    return a.id === b.id;
  }

  protected load(): void {
    this.loading = true;

    this.service
      .getList(this.quickSearchTerm, this.pageIndex, this.itemsPerPage)
      .pipe(take(1))
      .subscribe(response => {
        this.totalCount = response.totalCount;
        this.items = response.items;

        this.loading = false;
      });
  }
}
