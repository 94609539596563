import { Component, OnInit } from '@angular/core';
import { ActionLogDto } from 'src/core/models/action-log/action-log.dto';

@Component({
  selector: 'ca-conversation-analysis-start-action-log',
  templateUrl: './conversation-analysis-start-action-log.component.html',
  styleUrls: ['./conversation-analysis-start-action-log.component.scss'],
})
export class ConversationAnalysisStartActionLogComponent implements OnInit {
  data: ActionLogDto;

  get payloadData(): any {
    return JSON.parse(this.data?.payload);
  }

  constructor() {}

  ngOnInit(): void {}
}
