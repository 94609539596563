<span
  container="body"
  ngbTooltip="{{ 'Conversation::NoRecordsToExport' | abpLocalization }}"
  [disableTooltip]="!isDisabled">
  <button
    *ngIf="canExportConversation"
    [disabled]="isDisabled"
    class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
    (click)="onOpenModal()">
    <i class="far fa-file-export"></i>
    <span class="me-1">{{ '::Export' | abpLocalization }}</span>
  </button>
</span>

<ng-template #conversationResultExportModal let-modal>
  <ca-compact-form id="conversationResultExport" [formGroup]="form" (submit)="onExportExcelClick()">
    <ca-compact-form-header (closeButtonClick)="onModalClosed()">
      {{ 'Conversation::ConversationExport.Regular' | abpLocalization }}
    </ca-compact-form-header>
    <ca-compact-form-body>
      <ng-template form-row>
        <ca-compact-form-item class="align-self-center">
          <ca-form-label class="indicates-required-field" for="documentName">
            {{ 'Conversation::DocumentName' | abpLocalization }}
          </ca-form-label>
        </ca-compact-form-item>
        <ca-compact-form-item>
          <ca-form-input>
            <input type="text" id="documentName" formControlName="documentName" />
          </ca-form-input>
        </ca-compact-form-item>
      </ng-template>
      <ng-template form-row>
        <ca-compact-form-spacer></ca-compact-form-spacer>
      </ng-template>
      <ng-template form-row>
        <div class="row">
          <span class="ms-1 font-sm">
            {{ 'Conversation::ConversationExportExcelDescription' | abpLocalization }}
          </span>
        </div>
      </ng-template>
      <ng-template form-row>
        <ca-compact-form-spacer></ca-compact-form-spacer>
      </ng-template>
      <ng-template form-row>
        <ca-compact-form-checkbox-item>
          <ca-form-label for="includeUserDefinedCategories">
            {{ 'Conversation::IncludeUserDefinedCategories' | abpLocalization }}
            <div class="info-text">
              {{
                'Conversation::CountOfUserDefinedCategories'
                  | abpLocalization : userDefinedCategoryCount
              }}
            </div>
          </ca-form-label>
          <ca-form-input>
            <input
              type="checkbox"
              id="includeUserDefinedCategories"
              formControlName="includeUserDefinedCategories" />
          </ca-form-input>
        </ca-compact-form-checkbox-item>
      </ng-template>
      <ng-template form-row>
        <ca-compact-form-spacer></ca-compact-form-spacer>
      </ng-template>
      <ng-template form-row>
        <ca-compact-form-checkbox-item>
          <ca-form-label for="includeAIGeneratedCategories">
            {{ 'Conversation::IncludeAIGeneratedCategories' | abpLocalization }}
            <div class="info-text">
              {{
                'Conversation::CountOfAIGeneratedCategories'
                  | abpLocalization : aiGeneratedCategoryCount
              }}
            </div>
          </ca-form-label>
          <ca-form-input>
            <input
              type="checkbox"
              id="includeAIGeneratedCategories"
              formControlName="includeAIGeneratedCategories" />
          </ca-form-input>
        </ca-compact-form-checkbox-item>
      </ng-template>
    </ca-compact-form-body>
    <ca-compact-form-footer
      [buttons]="formButtonConfiguration"
      (saveButtonClick)="onExportExcelClick()"
      (cancelButtonClick)="onModalClosed()"></ca-compact-form-footer>
  </ca-compact-form>
</ng-template>
