import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { CallAnalysisDto } from 'src/core/models/call/call-analysis.dto';
import { PeriodicElement } from 'src/core/models/conversation/analysis/periodic-element';
import { GroupBy } from 'src/core/models/conversation/analysis/groupBy';
import { Observable } from 'rxjs';
import { ConversationMarkState } from 'src/core/states/conversation/conversation-mark.state';
import { ConversationMarkStateModel } from 'src/core/models/conversation/conversation-mark.state-model';
import { Store } from '@ngxs/store';
import { DataChange } from 'src/core/actions/conversation/conversation-mark.actions';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { CustomerGender } from 'src/core/models/generic-lookup-type/global/customer-gender.glt';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { map } from 'rxjs/operators';
import { CrudService } from 'src/core/services/crud/crud.service';
import { CallAnalysisStatus } from 'src/core/models/generic-lookup-type/call/call-analysis-status.glt';

const ELEMENT_DATA: (PeriodicElement | GroupBy)[] = [
  { initial: 'Call', isGroupBy: true },
  {
    name: 'callOverlapDuration',
    nameText: '',
    value: 1,
    unit: 'CallOverlapDuration:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: true,
    goToMarkOnClick: true,
  },
  {
    name: 'callOverlapRatio',
    nameText: '',
    value: 1,
    unit: 'CallOverlapRatio:Unit',
    tooltip: 'CallOverlapRatio:Tooltip',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'callMaxOverlapDuration',
    nameText: '',
    value: 0,
    unit: 'CallMaxOverlapDuration:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: true,
    goToMarkOnClick: true,
  },
  {
    name: 'callMaximumSilenceDuration',
    nameText: '',
    value: 53,
    unit: 'CallMaximumSilenceDuration:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: true,
    goToMarkOnClick: true,
  },
  {
    name: 'callSilenceRatio',
    nameText: '',
    value: 38,
    unit: 'CallSilenceRatio:Unit',
    tooltip: 'CallSilenceRatio:Tooltip',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'callSilenceDurationBeginning',
    nameText: '',
    value: 0,
    unit: 'CallSilenceDurationBeginning:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'callSilenceDurationEnd',
    nameText: '',
    value: 0,
    unit: 'CallSilenceDurationEnd:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'callHoldCount',
    nameText: '',
    value: 0,
    unit: '',
    tooltip: '',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'callTotalHoldDuration',
    nameText: '',
    value: 0,
    unit: 'CallTotalHoldDuration:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  { initial: 'Agent', isGroupBy: true },
  {
    name: 'agentTensionRatio',
    nameText: '',
    value: 0,
    unit: 'AgentTensionRatio:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: true,
    goToMarkOnClick: true,
  },
  {
    name: 'agentMonotonicityRatio',
    nameText: '',
    value: 75,
    unit: 'AgentMonotonicityRatio:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'agentInterruptionCountPerMinute',
    nameText: '',
    value: 2.35,
    unit: 'AgentInterruptionCountPerMinute:Unit',
    tooltip: 'AgentInterruptionCountPerMinute:Tooltip',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'agentSpeed',
    nameText: '',
    value: 16,
    unit: 'AgentSpeed:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'agentBlockCountPerMinute',
    nameText: '',
    value: 0.93,
    unit: 'AgentBlockCountPerMinute:Unit',
    tooltip: 'AgentBlockCountPerMinute:Tooltip',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'agentBlockRatio',
    nameText: '',
    value: 36,
    unit: 'AgentBlockRatio:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: true,
    goToMarkOnClick: true,
  },
  {
    name: 'agentHesitationCountPerMinute',
    nameText: '',
    value: 0,
    unit: 'AgentHesitationCountPerMinute:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  { initial: 'Customer', isGroupBy: true },
  {
    name: 'customerTensionRatio',
    nameText: '',
    value: 0,
    unit: 'CustomerTensionRatio:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: true,
    goToMarkOnClick: true,
  },
  {
    name: 'customerShare',
    nameText: '',
    value: 28,
    unit: 'CustomerShare:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'customerInterruptionCountPerMinute',
    nameText: '',
    value: 0,
    unit: 'CustomerInterruptionCountPerMinute:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'customerGenderText',
    nameText: '',
    value: 1,
    unit: 'CustomerGenderId:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'customerBlockCountPerMinute',
    nameText: '',
    value: 0,
    unit: 'CustomerBlockCountPerMinute:Unit',
    tooltip: 'CustomerBlockCountPerMinute:Tooltip',
    checked: false,
    visibleOnPlayer: false,
    goToMarkOnClick: false,
  },
  {
    name: 'customerBlockRatio',
    nameText: '',
    value: 0,
    unit: 'CustomerBlockRatio:Unit',
    tooltip: '',
    checked: false,
    visibleOnPlayer: true,
    goToMarkOnClick: true,
  },
];

@Component({
  selector: 'ca-call-analysis-panel',
  templateUrl: './call-analysis-panel.component.html',
  styleUrls: ['./call-analysis-panel.component.scss'],
})
export class CallAnalysisPanelComponent implements OnInit, AfterViewInit {
  rtlStylePosition: string;
  dataSource = ELEMENT_DATA;
  displayedColumns: string[] = ['name', 'value', 'action'];

  genders$: Observable<GenericLookupDto[]>;
  genders: GenericLookupDto[];
  private _analysisData: CallAnalysisDto;
  callAnalysisType = CallAnalysisStatus;

  @Input()
  set analysisData(analysisData: CallAnalysisDto) {
    this._analysisData = analysisData;
    this.loadAnalysis();
  }

  get analysisData(): CallAnalysisDto {
    return this._analysisData;
  }

  @Input()
  conversationId: number;

  @Output()
  goToMarkClick: EventEmitter<{ code: string; channelId: number }> = new EventEmitter();

  constructor(
    private store: Store,
    private localizationService: LocalizationService,
    private crudService: CrudService,
    private config: ConfigStateService
  ) {
    this.genders$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(CustomerGender)));
    this.genders$.subscribe(lookup => {
      this.genders = lookup;
    });
  }
  ngOnInit() {
    const localize = this.config.getOne('localization');
    const RTL = localize?.currentCulture.cultureName === 'ar';
    this.rtlStylePosition = RTL ? 'left: 20px' : 'right: 20px';
  }
  loadAnalysis() {
    if (this.analysisData == null) {
      this.analysisData = new CallAnalysisDto();
    }
    // if (!this.analysisData.callTotalHoldDuration) {
    //   this.dataSource.splice(11, 1);
    // }
    let lookupCode = '';
    this.genders.forEach(gl => {
      if (gl.id === this.analysisData.customerGenderId) {
        lookupCode = gl.code;
      }
    });
    this.analysisData['customerGenderText'] = this.localizationService.instant(
      'GenericLookup::' + lookupCode
    );

    const conversationMarkState =
      this.store.selectSnapshot<ConversationMarkStateModel>(ConversationMarkState);

    conversationMarkState.marks.forEach(data => {
      this.dataSource.forEach(dSource => {
        if (dSource['name'] && dSource['name'] == data.name) {
          dSource['checked'] = data.checked;
          dSource['color'] = data.color;
        }
      });
    });
  }

  ngAfterViewInit() {}

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  isNull(item): boolean {
    return this.analysisData ? this.analysisData[item.name] == null : false;
  }

  onVisibleChange(event) {
    const conversationMarkState =
      this.store.selectSnapshot<ConversationMarkStateModel>(ConversationMarkState);

    conversationMarkState.marks.forEach(data => {
      if (data.name && event.source.name == data.name) {
        data.checked = event.checked;
      }
    });

    var element = this.dataSource.filter(
      f => f['name'] != null && f['name'] == event.source.name
    )[0];
    if (element) {
      element['checked'] = event.checked;
    }

    const action = new DataChange(conversationMarkState);
    this.store.dispatch(action);
  }

  onGoToMarkClick(conversationMarkName) {
    const converationMarkState =
      this.store.selectSnapshot<ConversationMarkStateModel>(ConversationMarkState);

    var conversationMarkModel = converationMarkState.marks.find(
      data => conversationMarkName == data.name
    );

    if (conversationMarkModel && conversationMarkModel.code) {
      this.goToMarkClick.emit({
        code: conversationMarkModel.code,
        channelId: conversationMarkModel.channelId as number,
      });
    }
  }

  getUnitLabel(unit: string) {
    var label = this.localizationService.instant('Conversation::' + unit);
    return label.length > 0 ? `(${label})` : label;
  }
}
