import { Component, OnInit } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { OrderedSimpleTermQueryItemDto } from 'src/core/models/query/ordered-simple-term-query-item.dto';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { map } from 'rxjs/operators';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SimpleTermValidator } from 'src/core/validators/query/simple-term.validator';
import { Operators } from 'src/core/models/request/operator.enum';
import { QueryBuilderCategoryDto } from 'src/core/models/query/query-builder-category.dto';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';

@Component({
  selector: 'ca-query-tree-node-ordered-simple-term',
  templateUrl: './query-tree-node-ordered-simple-term.component.html',
  styleUrls: ['./query-tree-node-ordered-simple-term.component.scss'],
})
export class QueryTreeNodeOrderedSimpleTermComponent extends QueryEditorBase implements OnInit {
  payload: OrderedSimpleTermQueryItemDto;
  conversationSides$: Observable<GenericLookupDto[]>;
  queryForm: FormGroup = null;
  currentConversationSide: string;
  termInternal: string;
  categories: QueryBuilderCategoryDto[];
  conversationSideAny: number = ConversationSide.any;

  inputPhrases$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(
    private store: Store,
    private fb: FormBuilder,
    public queryFieldDataType: QueryFieldDataType,
    public operators: Operators,
    private genericLookupService: GenericLookupTypeService
  ) {
    super();
    this.conversationSides$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    this.queryForm = fb.group(
      {
        term: [
          null,
          {
            validators: [Validators.required, SimpleTermValidator.Validator],
          },
        ],
        conversationSide: [null],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.valueChanges.subscribe(() => {
      this.node.isDirty = this.queryForm.dirty;
    });

    this.queryForm.get('term').valueChanges.subscribe(value => {
      this.queryForm.updateValueAndValidity();

      if (this.queryForm.valid) {
        const newValue = this.queryForm.get('term').value;
        this.payload.operator = newValue.endsWith('*') ? operators.Contains : operators.Equals;
        this.payload.term = newValue.endsWith('*')
          ? newValue.substring(0, newValue.length - 1)
          : newValue.trim();
      }
    });

    this.queryForm.get('conversationSide').valueChanges.subscribe(value => {
      this.payload.sideCode = this.genericLookupService.findGenericLookupWithId(
        this.payload.sideId
      )?.code;
    });
  }

  get isFirstTwoEditor(): boolean {
    if (this.node.parentNode.children) {
      var orderedSimpleTerms = this.node.parentNode.children.filter(
        c => c.categoryItem.dataType === this.queryFieldDataType.OrderedSimpleTerm
      );

      const indexOfCurrent = orderedSimpleTerms.findIndex(
        o => o.categoryItem.payload.internalId === this.payload.internalId
      );
      return indexOfCurrent === 0 || indexOfCurrent === 1;
    }

    return false;
  }

  get isLastEditor(): boolean {
    if (this.node.parentNode.children) {
      var orderedSimpleTerms = this.node.parentNode.children.filter(
        c => c.categoryItem.dataType === this.queryFieldDataType.OrderedSimpleTerm
      );

      const indexOfCurrent = orderedSimpleTerms.findIndex(
        o => o.categoryItem.payload.internalId === this.payload.internalId
      );
      return indexOfCurrent === orderedSimpleTerms.length - 1;
    }

    return false;
  }

  ngOnInit() {
    this.categories = this.queryBuilder.visibleCategories;
    this.payload = this.node.categoryItem.payload as OrderedSimpleTermQueryItemDto;
    this.termInternal =
      this.payload.operator === this.operators.Contains
        ? this.payload.term + '*'
        : this.payload.term;

    this.queryForm.get('term').patchValue(this.termInternal);

    this.conversationSides$.subscribe((sides: GenericLookupDto[]) => {
      const conversationSide = sides.find(x => x.id == this.payload.sideId);
      this.currentConversationSide = conversationSide.name;
      this.payload.sideCode = conversationSide.code;
    });
    this.updateInputPhrases();
  }

  onAddClick(eventArgs: MouseEvent) {
    this.addOrderedSimpleTermNode();
  }

  getSiblingNodePhrases(): string[] {
    return this.queryBuilder.getSiblingNodePhrasesOfNode(this.node);
  }

  updateInputPhrases() {
    this.inputPhrases$.next(this.getSiblingNodePhrases());
  }

  private addOrderedSimpleTermNode() {
    this.categories.forEach(category => {
      category.items.forEach(item => {
        if (item.dataType === this.queryFieldDataType.OrderedSimpleTerm) {
          this.queryBuilder.addNodeFromAnotherNode(this.node.parentNode, category, item);
        }
      });
    });
  }
}
