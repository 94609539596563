import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { ToasterService } from '@abp/ng.theme.shared';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { FormGroup } from '@angular/forms';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { EvaluationDto } from 'src/core/models/quality/evaluation/evaluation.dto';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { EvaluationObjectionDto } from 'src/core/models/quality/evaluation/evaluation-objection.dto';
import { ObjectionStatus } from 'src/core/models/generic-lookup-type/quality/objection-status.glt';
import { ActivatedRoute } from '@angular/router';
import { ScopeService } from 'src/core/services/administration/scope.service';
import { FormatHelper } from 'src/core/helpers/format.helper';

@Component({
  selector: 'ca-call-summary',
  templateUrl: './call-summary.component.html',
  styleUrls: ['./call-summary.component.scss'],
})
export class CallSummaryComponent implements OnInit {
  @Input()
  hideCategoryChips = false;

  @Input()
  form: FormGroup;

  @Input()
  skeletonMode = false;

  @Input()
  showEvaluationDetailTab = false;

  @Input()
  set evaluation(value: EvaluationDto) {
    this._evaluation = value;
  }

  get evaluation(): EvaluationDto {
    const queryParamsSubscription = this.activatedRoute.queryParams;
    queryParamsSubscription.subscribe(params => {
      var objectionId = params.objectionId;

      if (objectionId > 0) {
        if (this._evaluation && this._evaluation.objections.length > 0) {
          const objection = this._evaluation.objections.find(f => f.id == objectionId);

          this.objection = objection;
        }
      } else if (this._evaluation?.objections.length > 0) {
        this.objection = this._evaluation.objections.sort(
          (first, second) => second.creationTime.getTime() - first.creationTime.getTime()
        )[0];
      }
    });

    return this._evaluation;
  }

  @Input()
  set conversation(value: ConversationDto) {
    this._conversation = value;
  }

  @Input()
  routeConversation = true;

  activeTab = 1;
  hasOnlyAgentScope: boolean;

  get conversation(): ConversationDto {
    if (this._conversation) {
      return this._conversation;
    }

    return new ConversationDto();
  }

  @Input()
  set categories(categories: ConversationCategoryDto[]) {
    this._categories = categories;
  }

  get categories(): ConversationCategoryDto[] {
    return this._categories;
  }

  @Output()
  categorySelectionChanged: EventEmitter<{
    selectedCategories: number[];
  }> = new EventEmitter();

  @Output()
  objectionAcceptClicked = new EventEmitter();

  @Output()
  objectionRejectClicked = new EventEmitter();

  get conversationTime(): string {
    if (this._conversation?.startTime) {
      const date = new Date(this._conversation?.startTime);

      return this.caDatePipe.transform(date, DateDisplayType.DateTime, true);
    }

    return '';
  }

  get isCall(): boolean {
    if (this._conversation?.typeId === ConversationType.call) {
      return true;
    } else {
      return false;
    }
  }

  get objectionIconClass(): string {
    if (this.objection.statusId === ObjectionStatus.objected) {
      return 'fa-regular fa-clock';
    } else if (this.objection.statusId === ObjectionStatus.accepted) {
      return 'far fa-check-circle';
    }

    return 'far fa-times-circle';
  }

  get hideObjection(): boolean {
    if (this.acceptedOrRejectedObjection) {
      return true;
    } else if (this.objection.statusId === ObjectionStatus.rejected) {
      return true;
    } else if (this.objection.statusId === ObjectionStatus.accepted) {
      return true;
    }

    return this.evaluation.evaluationMaster.conversation.userId == this.currentUserId;
  }

  comment = '';
  objection: EvaluationObjectionDto;
  userPhotoComponentStyles: any = {
    width: '64px',
    height: '64px',
    border: '2px solid rgba(0, 0, 0, .12)',
    'border-radius': '100%',
  };
  qualityManagementFeatureEnabled: boolean;
  evaluationDurationFeatureEnabled: boolean;
  acceptedOrRejectedObjection: boolean = false;
  sentimentFetureEnabled: boolean;

  skeletonRowTheme = {
    'margin-bottom': '0px',
  };

  private _conversation: ConversationDto;
  private _categories: ConversationCategoryDto[];
  private _customerSentimentScore: number;
  private _evaluation: EvaluationDto;
  private currentUserId: string;

  constructor(
    private toastr: ToasterService,
    private caDatePipe: CADatePipe,
    private featureService: FeatureService,
    private activatedRoute: ActivatedRoute,
    private scopeService: ScopeService,
    public formatHelper: FormatHelper,
    private localizationService: LocalizationService,
    private configStateService: ConfigStateService
  ) {
    this.qualityManagementFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.QualityManagement
    );
    this.evaluationDurationFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.EvaluationDurationStopWatch
    );
    this.hasOnlyAgentScope = this.scopeService.hasOnlyAgentScope();
    this.currentUserId = this.configStateService.getDeep('currentUser.id');
    this.sentimentFetureEnabled = this.featureService.isEnabled(FeatureConstants.SentimentAnalysis);
  }

  ngOnInit(): void { }

  onCategorySelectionChanged(event) {
    this.categorySelectionChanged.emit(event);
  }

  copyToClipboard() {
    this.toastr.success('::CopiedToClipboard', '', {});
  }

  getComment(): string | null {
    return this.showEvaluationDetailTab ? this.comment : null;
  }

  onAcceptObjectionClick() {
    this.objectionAcceptClicked.emit();
    this.acceptedOrRejectedObjection = true;
  }

  onRejectObjectionClick() {
    this.objectionRejectClicked.emit();
    this.acceptedOrRejectedObjection = true;
  }
}
