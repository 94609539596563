<ca-conversation-base-list
  [gridId]="gridId"
  [dataSource]="dataSource"
  [gridColumns]="gridColumns"
  (conversationIdChange)="onConversationIdChanged($event)"
  [compactPlayer]="compactPlayer">
</ca-conversation-base-list>

<!-- <table mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let gridColumn of gridColumns">
        <ng-container
            *ngIf="
                gridColumn.columnName !== 'menu' &&
                gridColumn.columnName !== 'icons'
            "
            matColumnDef="{{ gridColumn.columnName }}"
        >
            <th
                mat-header-cell
                *matHeaderCellDef
         
                [style.width]="gridColumn.width"
                [style.minWidth]="gridColumn.width"
                [style.maxWidth]="gridColumn.width"
            >
                {{ gridColumn.header }}
            </th>
            <td
                mat-cell
                *matCellDef="let conversation"
                [innerHtml]="conversation | objectparser: gridColumn"
            ></td>
        </ng-container>
        <ng-container
            *ngIf="gridColumn.columnName === 'menu'"
            matColumnDef="{{ gridColumn.columnName }}"
        >
            <th
                mat-header-cell
                *matHeaderCellDef
         
                [style.width]="gridColumn.width"
                [style.minWidth]="gridColumn.width"
                [style.maxWidth]="gridColumn.width"
            >
                {{ gridColumn.header }}
            </th>
            <td mat-cell *matCellDef="let conversation">
                <button
                    class="row-menu-button"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                        <mat-icon>info</mat-icon>
                        <span>Details</span>
                    </button>
                    <button mat-menu-item>
                        <mat-icon>delete_forever</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>
        <ng-container
            *ngIf="gridColumn.columnName === 'icons'"
            matColumnDef="{{ gridColumn.columnName }}"
        >
            <th
                mat-header-cell
                *matHeaderCellDef
         
                [style.width]="gridColumn.width"
                [style.minWidth]="gridColumn.width"
                [style.maxWidth]="gridColumn.width"
            >
                {{ gridColumn.header }}
            </th>
            <td mat-cell *matCellDef="let conversation">
                <ca-conversation-icon-container
                    [conversation]="conversation"
                    [showConversationType]="false"
                ></ca-conversation-icon-container>
            </td>
        </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>
</table> -->
