<form [formGroup]="toolbarForm">
  <div class="row entry-row submenu-row">
    <div class="col-auto">
      <h1 class="content-header-title">
        {{ 'UserGroup::Menu:UserGroups' | abpLocalization }}
      </h1>
    </div>
    <div class="col-md-auto pl-md-0">
      <abp-breadcrumb></abp-breadcrumb>
    </div>
    <div class="col">
      <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
        <div class="ca-toolbar-west-section">
          <div class="toolbar-item first">
            <ca-search-box
              #searchBox
              [placeholder]="'UserGroup::EnterUserGroupName' | abpLocalization"
              formControlName="quickSearchTerm"
              (searchRequested)="doSearch()"></ca-search-box>
          </div>
          <div class="toolbar-item pl-3 text-center">
            <mat-divider [vertical]="true" class="menu-divider"></mat-divider>
          </div>
          <div class="toolbar-item">
            <button
              class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
              type="button"
              (click)="addNew()">
              <i class="far fa-plus-circle"></i>
              <span>{{ '::AddNew' | abpLocalization }}</span>
            </button>
          </div>
        </div>
        <div class="ca-toolbar-east-section">
          <div class="toolbar-item last">
            <mat-slide-toggle
              labelPosition="before"
              (change)="onIncludePassivesChange()"
              formControlName="includePassives">
              {{ '::IncludePassives' | abpLocalization }}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
