import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { IntegerQueryItemDto } from 'src/core/models/query/integer-query-item.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { ConversationSentimentTrendStatus } from 'src/core/models/generic-lookup-type/conversation/sentiment-trend-status.glt';

@Component({
  selector: 'ca-query-tree-node-sentiment-trend',
  templateUrl: './query-tree-node-sentiment-trend.component.html',
  styleUrls: ['./query-tree-node-sentiment-trend.component.scss'],
})
export class QueryTreeNodeSentimentTrendComponent extends QueryEditorBase implements OnInit {
  trendStatuses$: Observable<GenericLookupDto[]>;
  currentTrendStatus: string;
  score: number;
  payload: IntegerQueryItemDto;

  constructor(private store: Store, private genericLookupService: GenericLookupTypeService) {
    super();
    this.trendStatuses$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSentimentTrendStatus)))
      .pipe(
        map(list => list.filter(item => item.code !== 'Conversation.SentimentTrendStatus.Unknown'))
      );
  }

  valueChanged() {
    this.node.isDirty = true;
    this.payload.genericLookupCode = this.genericLookupService.findGenericLookupWithId(
      this.payload.value
    )?.code;
  }

  ngOnInit(): void {
    this.payload = this.node.categoryItem.payload as IntegerQueryItemDto;
    this.trendStatuses$.subscribe((statusList: GenericLookupDto[]) => {
      this.currentTrendStatus = statusList.find(item => item.id === this.payload.value).code;
      this.payload.genericLookupCode = this.currentTrendStatus;
    });

    this.node.validationStatus = true;
  }

  getOptionIcon(code: string) {
    let icon = '';
    if (code?.includes('Worsening')) {
      icon = 'fa-solid fa-arrow-trend-down';
    } else if (code?.includes('Improving')) {
      icon = 'fa-solid fa-arrow-trend-up ';
    } else icon = 'fa fa-minus';
    return icon;
  }
}
