<ng-template #roleItemTemplate let-item="item">
  <div class="truncate">{{ item.name }}</div>
</ng-template>

<ng-template #roleSelectionTemplate let-selection="selection">
  <div class="truncate">{{ selection.name }}</div>
</ng-template>

<ng-container [formGroup]="editorForm">
  <ca-selector
    #roleSelector
    [url]="roleSelectorOptions.url"
    [pageSize]="roleSelectorOptions.pageSize"
    [itemTemplate]="roleItemTemplate"
    [selectionTemplate]="roleSelectionTemplate"
    [queryOperator]="roleSelectorOptions.queryOperator"
    [queryField]="roleSelectorOptions.queryField"
    [emptyText]="roleSelectorOptions.emptyText"
    [multiple]="roleSelectorOptions.multiple"
    [showSelections]="roleSelectorOptions.showSelections"
    [selectorPosition]="roleSelectorOptions.selectorPosition"
    [filterProperty]="roleSelectorOptions.filterProperty"
    [sorterProperty]="roleSelectorOptions.sorterProperty"
    [filters]="roleSelectorOptions.filters"
    [sorters]="roleSelectorOptions.sorters"
    [currentPage]="roleSelectorOptions.currentPage"
    [styles]="{ width: '100%', 'border-width': '0px' }"
    formControlName="editor"
  ></ca-selector>
</ng-container>
