import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { environment } from 'src/environments/environment';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { downloadBlob } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { EvaluationResultExcelDetailDto } from 'src/core/models/quality/evaluation/evaluation-result-excel-detail.dto';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor(
    private http: HttpClient,
    private operators: Operators,
    private toastr: ToasterService
  ) {}

  exportList(
    filters: FilterItemDto[],
    sorters: SorterItemDto[],
    excelType: number,
    excelDetail: EvaluationResultExcelDetailDto
  ): Observable<any> {
    let params = new HttpParams();

    filters.push({
      field: 'excelType',
      operator: this.operators.Equals,
      value: excelType,
    });

    params = params.append('filters', JSON.stringify(filters));
    params = params.append('sorters', JSON.stringify(sorters));
    params = params.append('excelDetail', JSON.stringify(excelDetail));

    return this.http.get(`${this.getApiUrl()}/export/`, {
      params,
    }) as Observable<any>;
  }

  getExcelReport(reportId: string, fileName: string, partionId?: number): Observable<File> {
    let parameters = { partionId: partionId, fileName: fileName };
    let queryParams = new HttpParams({ fromObject: parameters });

    return this.http.get<File>(`${this.getApiUrl()}/report/` + reportId, {
      params: queryParams,
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
  }

  downloadExcelReport(reportId: string, fileName: string, partionId?: number) {
    this.getExcelReport(reportId, fileName, partionId)
      .pipe(take(1))
      .subscribe({
        next: result => {
          if (result.size > 0) {
            downloadBlob(result, fileName);
          }
        },
        error: () => {
          this.toastr.error('Conversation::DownloadFileError');
        },
      });
  }

  protected getApiUrl(type = DtoHasApi): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0 ? apiBase + '/api/app/excel' : '/api/app/excel';
  }
}
