<ng-container [formGroup]="editorForm">
  <div class="row display-inline row-height">
    <div class="row display-inline">
      <div class="col overflow-hidden">
        <ca-select formControlName="editor" [(ngModel)]="selectedValue">
          <ca-option [value]="'-1'">
            {{ 'User::SelectLastLoginDate' | abpLocalization }}
          </ca-option>
          <ca-option *ngFor="let value of monthList" [value]="value.id">
            {{ value.name }}
          </ca-option>
        </ca-select>
      </div>
    </div>
  </div>
</ng-container>
