import { ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TopicResponseTupleDto } from 'src/core/models/conversation/ai-topic/topic-response-tuple.dto';
import { TopicTrainingHistoricalDto } from 'src/core/models/conversation/ai-topic/topic-training-historical.dto';

@Injectable({
  providedIn: 'root',
})
export class AITopicSettingsService {
  constructor(
    private config: ConfigStateService,
    private http: HttpClient,
  ) { }

  checkData(): Observable<TopicResponseTupleDto> {
    const url = this.getApiUrl() + '/data';

    return this.http.get(url) as Observable<TopicResponseTupleDto>;
  }

  resetData(): Observable<TopicResponseTupleDto> {
    const url = this.getApiUrl() + '/data/reset';

    return this.http.get(url) as Observable<TopicResponseTupleDto>;
  }

  sendData(trainOption: number): Observable<TopicResponseTupleDto> {
    const url = this.getApiUrl() + '/send-data/' + trainOption;

    return this.http.get(url) as Observable<TopicResponseTupleDto>;
  }

  resetSetting(): Observable<boolean> {
    const url = this.getApiUrl() + '/setting/reset';

    return this.http.get(url) as Observable<boolean>;
  }

  hasTraining(): Observable<boolean> {
    const url = this.getApiUrl() + '/has-training';

    return this.http.get(url) as Observable<boolean>;
  }

  LastTraining(): Observable<TopicTrainingHistoricalDto> {
    const url = this.getApiUrl() + '/last-training';

    return this.http.get(url) as Observable<TopicTrainingHistoricalDto>;
  }

  startInference(): Observable<TopicResponseTupleDto> {
    const url = this.getApiUrl() + '/start-inference';

    return this.http.get(url) as Observable<TopicResponseTupleDto>;
  }


  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase + '/' + 'api/app/ai-topic';
  }
}
