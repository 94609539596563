<div
  class="resize-handle d-flex flex-row justify-content-center"
  cdkDrag
  cdkDragLockAxis="x"
  (click)="onClick($event)"
  (cdkDragMoved)="onDragMove($event)"
  (cdkDragStarted)="onDragStart($event)"
  (cdkDragEnded)="onDragEnd($event)">
  <div *ngIf="!dragging" class="resize-handle-content"></div>
</div>

<!-- <div id="drag-line" [ngClass]="dragging ? 'drag-line visible' : 'drag-line'"></div> -->
