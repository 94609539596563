<div class="row entry-row submenu-row">
  <div class="col-auto">
    <h1 class="content-header-title">
      {{ 'Conversation::Conversations' | abpLocalization }}
    </h1>
  </div>
  <div class="col-md-auto pl-md-0" *ngIf="!conversationResult">
    <abp-breadcrumb></abp-breadcrumb>
  </div>
  <div class="col-md-auto pl-md-0" *ngIf="conversationResult">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a href="/"><i class="fa fa-home"></i></a>
      </li>
      <li class="breadcrumb-item">
        {{ '::Menu:Analytics' | abpLocalization }}
      </li>
      <li class="breadcrumb-item">
        {{ getConversationResultLabel() | abpLocalization : getConversationResultValues() }}
      </li>
    </ol>
  </div>
  <div class="col">
    <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
      <div class="ca-toolbar-west-section">
        <div *ngIf="!conversationResult" class="toolbar-item first">
          <ca-conversation-import></ca-conversation-import>
        </div>
        <div class="toolbar-item">
          <ca-conversation-export [isDisabled]="isEmptyResultSet"></ca-conversation-export>
        </div>
        <div class="toolbar-item" *ngIf="canAssignBatchTagging">
          <ca-conversation-batch-tagging></ca-conversation-batch-tagging>
        </div>
        <div *ngIf="!conversationResult" class="toolbar-item">
          <ca-conversation-cleaner></ca-conversation-cleaner>
        </div>
        <div class="toolbar-item pl-3 text-center">
          <mat-divider [vertical]="true" class="menu-divider"></mat-divider>
        </div>
        <div class="toolbar-item">
          <ca-column-options-button [gridId]="activeGridId"></ca-column-options-button>
        </div>
      </div>
    </div>
  </div>
</div>
