import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import { FormRowDirective } from '../../form-row.directive';

@Component({
  selector: 'ca-compact-form-body',
  templateUrl: './compact-form-body.component.html',
  styleUrls: ['./compact-form-body.component.scss'],
})
export class CompactFormBodyComponent implements OnInit, AfterContentInit {
  @ContentChildren(FormRowDirective) rowsDirectives: QueryList<FormRowDirective>;

  rows: FormRowDirective[];

  @Input()
  set mainContainerClass(clss: string) {
    this._mainContainerClass = clss;
  }

  @Input()
  set childContainerClass(clss: string) {
    this._childContainerClass = clss;
  }

  get mainContainerClass(): string {
    return this._mainContainerClass;
  }

  get childContainerClass(): string {
    return this._childContainerClass;
  }

  private _mainContainerClass: string = 'container pb-2 ps-3 pe-3';
  private _childContainerClass: string = 'row mt-2';

  constructor() {}

  ngAfterContentInit(): void {
    this.rows = this.rowsDirectives.toArray();
  }

  ngOnInit(): void {}
}
