import { Injectable } from '@angular/core';
import { AsyncValidator, ValidationErrors, FormGroup, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserService } from 'src/core/services/administration/user.service';

@Injectable({ providedIn: 'root' })
export class UserEmailValidator implements AsyncValidator {
  userId: string | null;

  constructor(private userService: UserService) {}

  validate(
    formControl: FormControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const id = this.userId == null ? '' : this.userId;
    const email = formControl.value;

    return this.userService.getByEmail(email).pipe(
      map(
        (response) => {
          return response !== null && response.id !== id ? { emailInUse: true } : null;
        },
        catchError(() => {
          return null;
        })
      )
    );
  }
}
