<div class="content-container">
  <div class="summary-container">
    <ca-email-summary [conversation]="conversation"></ca-email-summary>
  </div>
  <div class="center-container">
    <div class="tab-panel-container">
      <ul [destroyOnHide]="false" ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
        <li [ngbNavItem]="1" *ngIf="analyticsFeatureEnabled">
          <a ngbNavLink
            ><i class="fas fa-file-alt"></i
            ><span style="margin-left: 4px">{{
              'Conversation::Transcript' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <div
              style="height: 100%; overflow-y: auto; padding: 24px"
              [innerHTML]="conversation?.email?.message"></div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink
            ><i class="fas fa-paperclip"></i
            ><span style="margin-left: 4px">{{
              'Conversation::QueryBuilder:AttachedData' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-attached-data-panel [conversationId]="conversation?.id"></ca-attached-data-panel>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink
            ><i class="fas fa-comments"></i
            ><span style="margin-left: 4px">{{ '::Comment' | abpLocalization }}</span
            ><span class="badge bg-secondary ms-1 mb-1">{{ commentCount }}</span>
          </a>
          <ng-template ngbNavContent>
            <ca-conversation-comment-panel
              #commentPanel
              [conversationId]="conversation?.id"
              (goToCommentRequest)="onGoToComment($event)"
              (commentCountChanged)="onCommentCountChanged($event)">
            </ca-conversation-comment-panel>
          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink
            ><i class="fas fa-random"></i
            ><span style="margin-left: 4px">{{ 'Conversation::Thread' | abpLocalization }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-conversation-transfer-tab-panel
              [conversationId]="conversation?.id"
              [relatedGroupId]="conversation?.relatedGroupId"></ca-conversation-transfer-tab-panel>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2" style="height: calc(100% - 61px)"></div>
    </div>
  </div>
</div>
