import {
  Component,
  OnInit,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ElementRef,
  forwardRef,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { SelectorDatasourceModel } from '../../models/selector-datasource.model';
import { SelectorItemModel } from '../../models/selector-item.model';
import { HttpParams } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { CrudService } from 'src/core/services/crud/crud.service';
import { PageEvent } from '@angular/material/paginator';
import { CAConstants } from 'src/core/constants/ca-constant';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'ca-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectorComponent),
    },
  ],
})
@AutoUnsubscribe()
export class SelectorComponent implements OnInit, ControlValueAccessor {
  isRTL: boolean;

  @BlockUI('block-selector') blockUI: NgBlockUI;

  @ViewChild('edtSearch', { static: true })
  edtSearch: ElementRef;

  @Output()
  closeContainer: EventEmitter<{
    id: number;
  }> = new EventEmitter();

  @Output()
  itemClicked: EventEmitter<{
    tag: any;
  }> = new EventEmitter();

  private _datasource: SelectorDatasourceModel = {
    data: [],
    totalCount: 0,
  };

  private _pageSize = 10;
  private _currentPage = 1;
  private _pageCount = 0;
  private cache: any[] = [];
  private forceReload = false;
  private _disabled = false;
  private _emptyText: string;
  private _filters: FilterItemDto[] | string;
  private _sorters: SorterItemDto[] | string;
  private _autoSelectFirstItem = false;
  private text$ = new Subject<string>();
  private autoUnsubscribeNotifier = new Subject();

  statusItems = [
    { id: 1, label: this.localizationService.instant('::Active'), class: 'active-item' },
    { id: 2, label: this.localizationService.instant('::Passive'), class: 'passive-item' },
    { id: 3, label: this.localizationService.instant('::All'), class: 'all-item' },
  ];

  currentStatus = this.localizationService.instant('::Active');

  items: SelectorItemModel[] = [];
  selected: any[] = [];
  queryValue = '';
  currentPageInputValue = 1;
  disableTooltip = false;
  tooltip: any;

  @Input()
  filterProperty = 'filters';

  @Input()
  sorterProperty = 'sorters';

  @Input()
  skipCountProperty = 'skipCount';

  @Input()
  maxResultCountProperty = 'maxResultCount';

  @Input()
  paginationDisabled: boolean;

  @Input()
  multiple = false;

  @Input()
  styles: any = {};

  @Input()
  maxSelectionCount?: number;

  @Input()
  set emptyText(value: string) {
    this._emptyText = value;
  }

  get emptyText(): string {
    return this._emptyText;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  buttonCls: string;

  @Input()
  cls: string = 'p-1';

  @Input()
  url: string;

  @Input()
  idProperty = 'id';

  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  iconTemplate: TemplateRef<any>;

  @Input()
  selectionTemplate: TemplateRef<any>;

  @Input()
  filterToolbarTemplate: TemplateRef<any>;

  @Input()
  queryOperator: number;

  @Input()
  queryField: string;

  @Input()
  useSimpleFilter: boolean = false;

  @Input()
  useSimpleSorter: boolean = false;

  @Input()
  showClearSelectionLink: boolean = false;

  @Input()
  showSelections: boolean;

  @Input()
  selectorPosition: string = 'Right';

  @Input()
  parentComponent: string;

  @Input()
  showClearSelections: boolean = true;

  @Input()
  showStatusCombobox: boolean = false;

  @Input()
  excludedItemIds: any[] = [];

  @Input()
  selectOnClick: boolean = false;

  set datasource(d: SelectorDatasourceModel) {
    this._datasource = d;
  }

  get datasource(): SelectorDatasourceModel {
    return this._datasource;
  }

  get totalCount(): number {
    return this._datasource ? this._datasource.totalCount : 0;
  }

  @Input()
  set pageSize(p: number) {
    this._pageSize = p;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  @Input()
  set currentPage(p: number) {
    this._currentPage = p;
    this.currentPageInputValue = p;

    this.load();
  }

  get currentPage(): number {
    return this._currentPage;
  }

  get pageCount(): number {
    return this._pageCount;
  }

  get lowerBound(): number {
    return this._currentPage === 1 ? 1 : (this._currentPage - 1) * this._pageSize + 1;
  }

  get upperBound(): number {
    return this._currentPage === this._pageCount
      ? this.totalCount
      : this._pageSize * this._currentPage;
  }

  get value(): any[] {
    return this.selected;
  }

  @Input()
  set filters(value: FilterItemDto[] | string) {
    this._filters = value;
    this.forceReload = true;
  }

  @Input()
  set sorters(value: SorterItemDto[] | string) {
    this._sorters = value;
    this.forceReload = true;
  }

  @Input()
  set autoSelectFirstItem(value: any) {
    this._autoSelectFirstItem = value;
  }

  private calculatePageCount() {
    this._pageCount = Math.ceil(this.totalCount / this.pageSize);
  }

  private setItems() {
    this.items = [];

    if (!this._datasource) {
      return;
    }

    this._datasource.data.forEach(element => {
      this.items.push({
        data: element,
        selected: false,
      });
    });

    if (this._autoSelectFirstItem) {
      if (this._datasource && this._datasource.data?.length > 0) {
        this.selected = [];
        this.selected.push(this._datasource?.data[0]);
      }
    }
  }

  private setSelected() {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.selected.length; i++) {
      // tslint:disable-next-line: prefer-for-of
      for (let j = 0; j < this.items.length; j++) {
        if (this.selected[i][this.idProperty] === this.items[j].data[this.idProperty]) {
          this.items[j].selected = true;
          break;
        }
      }
    }

    this.onChange(this.value);
  }

  public setSorters(sorters: SorterItemDto[]) {
    this._sorters = sorters;
  }

  private getSorters(): SorterItemDto[] | string {
    if (typeof this._sorters === 'string') {
      return this._sorters;
    }

    let result: SorterItemDto[] = [];
    if (this._sorters && this._sorters.length > 0) {
      result = result.concat(this._sorters);
    }

    return result;
  }

  public setAutoSelectFirstItem(value: boolean) {
    this._autoSelectFirstItem = value;
  }

  public setFilters(filters: FilterItemDto[]) {
    this._filters = filters;
  }

  public getFilters(): FilterItemDto[] | string {
    if (typeof this._filters === 'string') {
      return this.queryValue;
    }

    let result: FilterItemDto[] = [];
    if (this._filters && this._filters.length > 0) {
      result = result.concat(this._filters);
    }

    if (this.queryValue && this.queryValue.trim().length > 0) {
      result.push({
        field: this.queryField,
        operator: this.queryOperator,
        value: this.queryValue,
      });
    }

    return result;
  }

  ngOnChanges(changes: SimpleChanges) {
    const disabled = changes['disabled'];
    if (disabled && disabled.currentValue === true) {
      this.disabled = true;
    }
  }

  load() {
    if (this.cache[this._currentPage] && !this.forceReload) {
      this._datasource = this.cache[this._currentPage];

      this.setItems();
      this.calculatePageCount();
      this.setSelected();

      return;
    }

    if (this.forceReload) {
      this.cache = [];
    }

    this.forceReload = false;

    const filters = this.getFilters();
    const sorters = this.getSorters();

    let params = new HttpParams();

    if (typeof filters === 'string') {
      params = params.append(this.filterProperty, filters);
    } else {
      params = params.append(this.filterProperty, JSON.stringify(filters));
    }
    if (typeof sorters === 'string') {
      params = params.append(this.sorterProperty, sorters);
    } else {
      params = params.append(this.sorterProperty, JSON.stringify(sorters));
    }

    params = params.append(
      this.skipCountProperty,
      JSON.stringify(this._pageSize * (this._currentPage - 1))
    );
    params = params.append(this.maxResultCountProperty, JSON.stringify(this._pageSize));

    const req = this.crudService.http.get(this.url, {
      params,
    }) as Observable<ListResponseDto<any>>;

    this.blockUI.start(this.localizationService.instant('AbpIdentity::LoadingWithThreeDot'));
    req.subscribe(response => {
      this.datasource = {
        data: response.items,
        totalCount: response.totalCount,
      };

      this.cache[this._currentPage] = this._datasource;

      this.setItems();
      this.calculatePageCount();
      this.setSelected();
      this.blockUI.stop();
    });
  }

  clearSelection() {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].selected = false;
    }

    this.selected = [];
  }

  changeSelection(item: SelectorItemModel, fromInput: boolean) {
    if (!item.selected) {
      if (!this.multiple) {
        this.clearSelection();
      }
      this.selected.push(item.data);
    } else {
      let idx = -1;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.selected.length; i++) {
        if (this.selected[i][this.idProperty] === item.data[this.idProperty]) {
          idx = i;
          break;
        }
      }

      this.selected.splice(idx, 1);
    }

    item.selected = !item.selected;

    this.onChange(this.value);
  }

  focusSearch() {
    this.edtSearch.nativeElement.focus();
  }

  public pageChanged(event?: PageEvent) {
    this.currentPage = event.pageIndex + 1;
  }

  onClickRemoveItem(eventArgs, item) {
    const idx = this.selected.indexOf(item);

    this.selected.splice(idx, 1);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.items.length; i++) {
      if (item[this.idProperty] === this.items[i].data[this.idProperty]) {
        this.items[i].selected = false;
        break;
      }
    }

    this.onChange(this.value);
  }

  onClearSelectionClick(eventArgs) {
    this.clearSelection();
    this.onChange(this.value);
  }

  onItemClick(eventArgs, item: SelectorItemModel) {
    if (!this.checkItemDisability(item)) {
      const fromInput = eventArgs.srcElement.checked !== undefined;
      let tempSelected = item.selected;

      this.changeSelection(item, fromInput);

      if (!this.multiple && !tempSelected) {
        this.closeContainer.emit();
      }

      if (this.selectOnClick) {
        this.itemClicked.emit(item.data);
      }
    }
  }

  checkItemDisability(item) {
    let selectedCount = this.selected.length;
    if (
      item.disabled ||
      (this.maxSelectionCount != null && selectedCount >= this.maxSelectionCount)
    ) {
      return true;
    } else {
      return false;
    }
  }

  onSearchRequest(eventArgs: KeyboardEvent) {
    eventArgs.preventDefault();
    eventArgs.stopPropagation();

    if (
      0 >= this.queryValue.length ||
      this.queryValue.length >= CAConstants.SEARCH_INPUT_MIN_LENGTH
    ) {
      this.text$.next(this.queryValue);
    }
  }

  isDisabled(item: SelectorItemModel): boolean {
    return this.excludedItemIds.some(e => e == item[this.idProperty]);
  }

  refresh(page?: number | null) {
    if (page === null) {
      page = this.currentPage;
    } else {
      this._currentPage = 1;
      this.currentPageInputValue = 1;
    }

    this.forceReload = true;
    this.load();
  }

  onRemoveFilterClick(eventArgs: MouseEvent) {
    this.queryValue = '';
    this.forceReload = true;
    this.currentPage = 1;
  }

  changeStatusFilter(eventArgs) {
    this.currentStatus = eventArgs.label;
    let isEdited = false;
    let currFilters = this.getFilters();
    if (currFilters !== 'string') {
      currFilters = currFilters as FilterItemDto[];
      const activeFilterCount = currFilters.filter(x => x.field == 'active').length;
      const passiveFilterCount = currFilters.filter(x => x.field == 'passive').length;
      currFilters = currFilters.filter(x => x.field != 'active');
      currFilters = currFilters.filter(x => x.field != 'passive');
      if (eventArgs.id == this.statusItems[0].id && activeFilterCount == 0) {
        currFilters.push({
          field: 'active',
          operator: this.operators.Equals,
          value: true,
        });
        isEdited = true;
      } else if (eventArgs.id == this.statusItems[1].id && passiveFilterCount == 0) {
        currFilters.push({
          field: 'passive',
          operator: this.operators.Equals,
          value: true,
        });
        isEdited = true;
      } else if (
        eventArgs.id == this.statusItems[2].id &&
        (activeFilterCount > 0 || passiveFilterCount > 0)
      ) {
        isEdited = true;
      }

      if (isEdited) {
        this.setFilters(currFilters);
        this.refresh(1);
      }
    }
  }

  writeValue(obj: any): void {
    obj = obj ? obj : [];
    this.selected = obj;
    if (this.selected.length === 0) {
      this.clearSelection();
    } else {
      this.setSelected();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(selection: any[]) {}

  onTouched() {}

  onPageInputChange(eventArgs: KeyboardEvent) {
    if (isNaN(this.currentPageInputValue)) {
      this.currentPage = 1;
    } else if (this.currentPageInputValue < 1) {
      this.currentPage = 1;
    } else if (this.currentPageInputValue > this._pageCount) {
      this.currentPage = this._pageCount;
    } else {
      this.currentPage = this.currentPageInputValue;
    }
  }

  onTooltipInitialized(tooltip) {
    this.tooltip = tooltip;
  }

  onScroll() {
    this.tooltip?.close();
  }

  constructor(
    private crudService: CrudService,
    private operators: Operators,
    private config: ConfigStateService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    const localize = this.config.getOne('localization');
    this.isRTL = localize?.currentCulture.cultureName === 'ar';
    this.text$
      .pipe(debounceTime(CAConstants.SEARCH_INPUT_DELAY), takeUntil(this.autoUnsubscribeNotifier))
      .subscribe(() => {
        this.forceReload = true;
        this.currentPage = 1;
      });
  }
}
