<ca-user-group-list-toolbar
  #toolbar
  (addNewClick)="onAddNewClick($event)"
  (dataRequest)="onDataRequest($event)"
></ca-user-group-list-toolbar>

<div class="row split-panel-container full-height">
  <as-split
    [unit]="splitConfig.unit"
    [direction]="splitConfig.direction"
    [useTransition]="splitConfig.useTransition"
    [gutterSize]="splitConfig.gutterSize"
    (dragEnd)="onDragEnd($event)"
    #split="asSplit"
  >
    <as-split-area
      [size]="(layout$ | async).config.west.size"
      [minSize]="(layout$ | async).config.west.minSize"
      [maxSize]="(layout$ | async).config.west.maxSize"
      [visible]="(layout$ | async).config.west.visible"
      [order]="0"
    >
    </as-split-area>
    <as-split-area
      [size]="(layout$ | async).config.center.size"
      [minSize]="(layout$ | async).config.center.minSize"
      [maxSize]="(layout$ | async).config.center.maxSize"
      [visible]="(layout$ | async).config.center.visible"
      [order]="1"
      class="center-panel"
    >
      <ca-user-group-list
        #list
        (showDetails)="onOpenDetails($event)"
        (listLoadRequestCompleted)="onListLoadRequestCompleted()"
      ></ca-user-group-list>
    </as-split-area>
    <as-split-area
      [size]="(layout$ | async).config.east.size"
      [minSize]="(layout$ | async).config.east.minSize"
      [maxSize]="(layout$ | async).config.east.maxSize"
      [visible]="(layout$ | async).config.east.visible"
      [order]="2"
      style="display: flex; flex-direction: column; justify-content: stretch; align-items: stretch"
    >
      <ca-user-group-form
        #form
        style="height: 100%; width: 100%"
        (saved)="onSaveDetails($event)"
        (closed)="onDetailPanelClose($event)"
      ></ca-user-group-form>
    </as-split-area>
  </as-split>
</div>
