import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.NonFcrStatus'
})
export class ConversationNonFcrStatus {
    @GenericLookup({
        code: 'Conversation.NonFcrStatus.NotProcessed'
    })
    static notProcessed: number;

    @GenericLookup({
        code: 'Conversation.NonFcrStatus.Fcr'
    })
    static fcr: number;

    @GenericLookup({
        code: 'Conversation.NonFcrStatus.NonFcr'
    })
    static nonFcr: number;
}
