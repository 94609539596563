import { Component, OnInit } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { Operators } from 'src/core/models/request/operator.enum';
import { Observable } from 'rxjs';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { IntegerQueryItemDto } from 'src/core/models/query/integer-query-item.dto';
import { CallDirection } from 'src/core/models/generic-lookup-type/call/call-direction.glt';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';

@Component({
  selector: 'ca-query-tree-node-conversation-direction',
  templateUrl: './query-tree-node-conversation-direction.component.html',
  styleUrls: ['./query-tree-node-conversation-direction.component.scss'],
})
export class QueryTreeNodeConversationDirectionComponent extends QueryEditorBase implements OnInit {
  directions$: Observable<GenericLookupDto[]>;
  currentDirectionCode: string;

  payload: IntegerQueryItemDto;

  constructor(private operators: Operators, private store: Store, private genericLookupService: GenericLookupTypeService) {
    super();

    this.directions$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(CallDirection)));
  }

  operatorChanged() {
    this.node.isDirty = true;
  }

  valueChanged() {
    this.node.isDirty = true;
    this.payload.genericLookupCode = this.genericLookupService.findGenericLookupWithId(this.payload.value)?.code;
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as IntegerQueryItemDto;
    this.directions$.subscribe((directions: GenericLookupDto[]) => {
      this.currentDirectionCode = directions.find(x => x.id == this.payload.value).code;
      this.payload.genericLookupCode = this.currentDirectionCode;
    });

    this.node.validationStatus = true;
  }
}
