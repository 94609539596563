import { Component, OnInit } from '@angular/core';
import { ActionLogDto } from 'src/core/models/action-log/action-log.dto';

@Component({
  selector: 'ca-conversation-automatic-evaluation-start-action-log',
  templateUrl: './conversation-automatic-evaluation-start-action-log.component.html',
  styleUrls: ['./conversation-automatic-evaluation-start-action-log.component.scss'],
})
export class ConversationAutomaticEvaluationStartActionLogComponent implements OnInit {
  data: ActionLogDto;
  private _payloadData: any;

  get payloadData(): any {
    if (!this._payloadData) {
      this._payloadData = JSON.parse(this.data?.payload);
    }
    return this._payloadData;
  }

  constructor() {}

  ngOnInit(): void {}
}
