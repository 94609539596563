import { FormControl, ValidatorFn } from '@angular/forms';
import { DropdownTreeNodeModel } from 'src/ca-shared/dropdown-tree/models/dropdown-tree-node.model';
import { DepartmentFilterModeOption } from 'src/core/models/generic-lookup-type/department/department-filter-mode-option.glt';

export class DepartmentAndUserValidator {
  static userControl: FormControl;
  static departmentTreeData: DropdownTreeNodeModel[] = [];

  static Validator: ValidatorFn = (control: FormControl) => {
    const selectedDepartments = control?.value?.selectedDepartments as [];
    const filterModeId = control?.value?.filterModeId as number;

    if (filterModeId == DepartmentFilterModeOption.AllDepartmentsExceptSelectedDeparment) {
      const selectedIds = selectedDepartments.map(({ id }) => id);

      const exceptData = DepartmentAndUserValidator.departmentTreeData.filter(
        f => !selectedIds.some(s => s == f.payload.id)
      );

      if (exceptData.length == 0) {
        return { noDepartmentsOtherThanSelectedDepartments: true };
      }
      if (DepartmentAndUserValidator.userControl?.enabled) {
        DepartmentAndUserValidator.userControl?.disable();
      }
      return null;
    }

    if (!selectedDepartments) {
      if (DepartmentAndUserValidator.userControl?.enabled) {
        DepartmentAndUserValidator.userControl?.disable();
      }
      return null;
    }

    if (selectedDepartments.length <= 0) {
      if (DepartmentAndUserValidator.userControl?.disabled) {
        DepartmentAndUserValidator.userControl?.enable();
      }
      return { required: true };
    }

    if (DepartmentAndUserValidator.userControl?.enabled) {
      DepartmentAndUserValidator.userControl?.disable();
    }
    return null;
  };
}
