import { Component, OnInit } from '@angular/core';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { FeatureService } from 'src/core/services/feature/feature.service';

@Component({
  selector: 'ca-real-time-settings-main',
  templateUrl: './real-time-settings-main.component.html',
  styleUrls: ['./real-time-settings-main.component.scss'],
})
export class RealTimeSettingsMainComponent implements OnInit {
  realTimeFeatureEnabled = false;
  generativeQnAFeatureEnabled = false;
  virtualAgentIntegrationEnabled = false;
  constructor(private featureService: FeatureService) {
    this.realTimeFeatureEnabled = this.featureService.isEnabled(FeatureConstants.RealTime);
    this.virtualAgentIntegrationEnabled = this.featureService.isEnabled(
      FeatureConstants.VirtualAgentIntegration
    );
    this.generativeQnAFeatureEnabled = this.featureService.isEnabled(FeatureConstants.GenerativeQA);
  }

  ngOnInit(): void {}
}
