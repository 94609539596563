import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'QualityManagement.Evaluation.Objection.Status',
})
export class ObjectionStatus {
  @GenericLookup({
    code: 'QualityManagement.Evaluation.Objection.Status.Objected',
  })
  static objected: number;

  @GenericLookup({
    code: 'QualityManagement.Evaluation.Objection.Status.Accepted',
  })
  static accepted: number;

  @GenericLookup({
    code: 'QualityManagement.Evaluation.Objection.Status.Rejected',
  })
  static rejected: number;
}
