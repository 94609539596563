import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'ca-timer-for-node-removal',
  templateUrl: './timer-for-node-removal.component.html',
  styleUrls: ['./timer-for-node-removal.component.scss'],
})
export class TimerForNodeRemovalComponent implements OnInit, OnDestroy {
  snackBarRef: any;
  counter: number;
  timer: any;
  countDownRef: () => void;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { durationInSeconds: number }) {
    this.snackBarRef = inject(MatSnackBarRef);
    this.counter = this.data.durationInSeconds;

    this.countDownRef = this.countDown.bind(this);

    this.timer = setInterval(this.countDownRef, 1000);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  countDown(): void {
    this.counter -= 1;
  }

  colorCalculatorFn(v: number | string): string {
    if (typeof v === 'string') {
      v = parseInt(v);
    }

    if (v <= 5) {
      return 'orangered';
    }

    return 'rgb(0, 120, 212)';
  }
}
