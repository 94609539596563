<ca-conversation-list-toolbar
  [conversationResult]="conversationResult"></ca-conversation-list-toolbar>
<div class="row split-panel-container full-height">
  <as-split
    [unit]="splitConfig.unit"
    [direction]="splitConfig.direction"
    [useTransition]="splitConfig.useTransition"
    [gutterSize]="splitConfig.gutterSize"
    (dragEnd)="onDragEnd($event)"
    #split="asSplit"
    [disabled]="true">
    <as-split-area
      [size]="(layout$ | async)?.west.size"
      [visible]="(layout$ | async)?.west.visible && !isConversationResult"
      [order]="0">
      <ca-conversation-list-filter-panel
        *ngIf="!isConversationResult"
        #filterPanel
        (collapsed)="onFilterPanelCollapsed($event)"
        (expanded)="onFilterPanelExpanded($event)"></ca-conversation-list-filter-panel>
    </as-split-area>
    <as-split-area
      [size]="(layout$ | async)?.center.size"
      [visible]="(layout$ | async)?.center.visible"
      [order]="1"
      class="center-panel">
      <div #listContainer class="center-panel-inner" (scroll)="onScroll($event)">
        <ca-conversation-mixed-list
          *ngIf="listVisibilities[conversationListTypes.mixed]"
          (conversationIdChange)="onConversationIdChanged($event)"
          (dataLoaded)="restoreScrollPosition()"
          [compactPlayer]="compactPlayer"></ca-conversation-mixed-list>
        <ca-conversation-call-list
          *ngIf="listVisibilities[conversationListTypes.call]"
          (conversationIdChange)="onConversationIdChanged($event)"
          (dataLoaded)="restoreScrollPosition()"
          [compactPlayer]="compactPlayer"></ca-conversation-call-list>
        <ca-conversation-chat-list
          *ngIf="listVisibilities[conversationListTypes.chat]"
          (conversationIdChange)="onConversationIdChanged($event)"
          (dataLoaded)="restoreScrollPosition()"></ca-conversation-chat-list>
        <ca-conversation-meeting-list
          *ngIf="listVisibilities[conversationListTypes.meeting]"
          (conversationIdChange)="onConversationIdChanged($event)"
          (dataLoaded)="restoreScrollPosition()"
          [compactPlayer]="compactPlayer"></ca-conversation-meeting-list>
        <ca-conversation-video-call-list
          *ngIf="listVisibilities[conversationListTypes.videoCall]"
          (conversationIdChange)="onConversationIdChanged($event)"
          (dataLoaded)="restoreScrollPosition()"
          [compactPlayer]="compactPlayer"></ca-conversation-video-call-list>
        <ca-conversation-email-list
          *ngIf="listVisibilities[conversationListTypes.email]"
          (conversationIdChange)="onConversationIdChanged($event)"
          (dataLoaded)="restoreScrollPosition()"></ca-conversation-email-list>
        <div *ngIf="isConversationResult && conversationResultNotFound" class="center">
          {{ 'Conversation::DrillDownAnalysisResultNotFound' | abpLocalization }}
        </div>
      </div>
      <ca-compact-player #compactPlayer></ca-compact-player>
    </as-split-area>
    <as-split-area
      [size]="(layout$ | async)?.east.size"
      [minSize]="(layout$ | async)?.east.minSize"
      [maxSize]="(layout$ | async)?.east.maxSize"
      [visible]="(layout$ | async)?.east.visible"
      [order]="2"
      style="display: flex; flex-direction: column; justify-content: center; align-items: center">
      <div style="height: 100%; width: 100%; overflow-y: hidden; overflow-x: hidden">
        <ca-conversation-main-form
          [selectedConversationId]="conversationId"></ca-conversation-main-form>
        <!-- <button
                    class="btn btn-outline-primary"
                    (click)="changeVisibilityForDetailPanel(false)"
                >
                    Hide Me!
                </button> -->
      </div>
    </as-split-area>
  </as-split>
</div>
