import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.Type',
})
export class ConversationType {
  @GenericLookup({
    code: 'Conversation.Type.Call',
  })
  static call: number;

  @GenericLookup({
    code: 'Conversation.Type.Chat',
  })
  static chat: number;

  @GenericLookup({
    code: 'Conversation.Type.Meeting',
  })
  static meeting: number;

  @GenericLookup({
    code: 'Conversation.Type.VideoCall',
  })
  static videoCall: number;

  @GenericLookup({
    code: 'Conversation.Type.Email',
  })
  static email: number;

  @GenericLookup({
    code: 'Conversation.Type.All',
  })
  static all: number;
}
