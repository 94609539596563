import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ca-compact-form-header',
  templateUrl: './compact-form-header.component.html',
  styleUrls: ['./compact-form-header.component.scss'],
})
export class CompactFormHeaderComponent implements OnInit {
  @Output()
  closeButtonClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onClickCloseButton(eventArgs: MouseEvent): void {
    this.closeButtonClick.emit();
  }
}
