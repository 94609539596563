import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'QualityManagement.EvaluationResult.Notification',
})
export class EvaluationResultNotificationType {
  @GenericLookup({
    code: 'QualityManagement.EvaluationResult.Notification.Hourly',
  })
  static hourly: number;

  @GenericLookup({
    code: 'QualityManagement.EvaluationResult.Notification.Daily',
  })
  static daily: number;

  @GenericLookup({
    code: 'QualityManagement.EvaluationResult.Notification.Weekly',
  })
  static weekly: number;

  @GenericLookup({
    code: 'QualityManagement.EvaluationResult.Notification.Monthly',
  })
  static monthly: number;
}
