<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label>{{ '(' + ('Query::SimpleQueryShortText' | abpLocalization) + ')' }}</label>
      </div>
      <div
        *ngIf="payload.sideId !== conversationSideAny"
        class="col-auto"
        ngbTooltip="{{ '::Channel' | abpLocalization }}"
        container="body"
        placement="bottom">
        <label>{{ 'GenericLookup::' + currentConversationSide | abpLocalization }}</label>
        <label>:</label>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::Term' | abpLocalization }}"
        container="body"
        placement="left auto">
        <label
          *ngIf="currentSimpleQueryType === SimpleQueryType.Not || payload.not"
          style="font-style: italic">
          {{ 'Query::NOT' | abpLocalization }}&nbsp;
        </label>
        <label>"</label>
        <label>{{ payload.term }}</label>
        <label *ngIf="payload.operator === operators.Contains">
          {{ payload.operator | operator }}
        </label>
        <label>"</label>
        <label
          *ngIf="
            currentSimpleQueryType !== SimpleQueryType.Basic &&
            currentSimpleQueryType !== SimpleQueryType.Not
          ">
          ,&nbsp;
        </label>
      </div>

      <div class="col-auto" *ngIf="currentSimpleQueryType === SimpleQueryType.Interval">
        <label>{{ '::TimeRange' | abpLocalization }}:&nbsp;</label>
      </div>
      <div
        class="col-auto"
        *ngIf="currentSimpleQueryType === SimpleQueryType.Interval"
        container="body"
        placement="bottom"
        ngbTooltip=" {{
          currentSimpleQueryIntervalType === SimpleQueryIntervalType.Opening
            ? getOpeningIntervalTooltip()
            : getClosingIntervalTooltip()
        }}">
        <label *ngIf="currentSimpleQueryIntervalType === SimpleQueryIntervalType.Opening">
          {{ 'Conversation::QueryBuilder:OpeningInterval' | abpLocalization }}
        </label>
        <label *ngIf="currentSimpleQueryIntervalType === SimpleQueryIntervalType.Closing">
          {{ 'Conversation::QueryBuilder:ClosingInterval' | abpLocalization }}
        </label>
      </div>
      <div class="col-auto" *ngIf="currentSimpleQueryType === SimpleQueryType.Occurence">
        <label>{{ '::MinimumOccurence' | abpLocalization }}:</label>
        <label>{{ formOccurence }}</label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <div ngbDropdown class="d-inline-block">
          <button ngbDropdownToggle class="btn btn-sm ca-btn ca-btn-neutral-command">
            <i class="far fa-bars"></i>
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              class="command-menu-item"
              (click)="onQueryTypeMenuItemClicked(SimpleQueryType.Basic)">
              <i class="fas fa-circle small-icon"></i>
              <span>{{ 'Conversation::QueryBuilder:SimpleQuery' | abpLocalization }}</span>
            </button>
            <button
              ngbDropdownItem
              class="command-menu-item"
              (click)="onQueryTypeMenuItemClicked(SimpleQueryType.Interval)">
              <i class="fas fa-circle small-icon"></i>
              <span>
                {{ 'Conversation::QueryBuilder:SimpleQuerywithInterval' | abpLocalization }}
              </span>
            </button>
            <button
              ngbDropdownItem
              class="command-menu-item"
              (click)="onQueryTypeMenuItemClicked(SimpleQueryType.Occurence)">
              <i class="fas fa-circle small-icon"></i>
              <span>
                {{ 'Conversation::QueryBuilder:SimpleQuerywithOccurence' | abpLocalization }}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <label>{{ 'Query::SimpleQueryShortText' | abpLocalization }}</label>
      </div>
      <div class="col-auto">
        <mat-slide-toggle
          #notToggle
          formControlName="isNotQuery"
          [disabled]="currentSimpleQueryType === SimpleQueryType.Occurence"
          [hidden]="
            currentSimpleQueryType === SimpleQueryType.Interval &&
            conversationType.call !== queryBuilder.conversationType
          ">
          {{ 'Query::NOT' | abpLocalization }}
        </mat-slide-toggle>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::Term' | abpLocalization }}"
        container="body"
        placement="bottom">
        <input
          *ngIf="!queryBuilder.isPhraseSuggestionSupported"
          type="text"
          class="form-control form-control-sm"
          formControlName="term"
          placeholder="{{ 'Query::QueryTermWatermark' | abpLocalization }}" />
        <ca-phrase-suggestion-dropdown-selector
          *ngIf="queryBuilder.isPhraseSuggestionSupported"
          formControlName="term"
          [languageCode]="queryBuilder.languageCode"
          [class]="'form-control form-control-sm'"
          [placeholder]="'Query::QueryTermWatermark' | abpLocalization"
          [inputPhrases$]="inputPhrases$"
          (phraseRequest)="updateInputPhrases()"></ca-phrase-suggestion-dropdown-selector>
      </div>
      <div class="col-auto">
        <ca-conversation-side-selector
          formControlName="conversationSide"
          [allSide]="true"></ca-conversation-side-selector>
      </div>
      <div
        class="col-auto radio-container"
        container="body"
        placement="bottom"
        *ngIf="currentSimpleQueryType === SimpleQueryType.Interval">
        <div ngbTooltip="{{ getOpeningIntervalTooltip() }}" container="body" placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioOpening_' + payload.internalId"
            #radioOpening
            value="{{ SimpleQueryIntervalType.Opening }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioOpening_' + payload.internalId" class="radio-label">
            {{ 'Conversation::QueryBuilder:OpeningInterval' | abpLocalization }}
          </label>
        </div>
        <div
          style="margin-left: 12px"
          ngbTooltip="{{ getClosingIntervalTooltip() }}"
          container="body"
          placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioClosing_' + payload.internalId"
            #radioClosing
            value="{{ SimpleQueryIntervalType.Closing }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioClosing_' + payload.internalId" class="radio-label">
            {{ 'Conversation::QueryBuilder:ClosingInterval' | abpLocalization }}
          </label>
        </div>
      </div>
      <div
        class="col-auto"
        container="body"
        placement="bottom"
        ngbTooltip="{{ '::MinimumOccurence' | abpLocalization }}"
        *ngIf="currentSimpleQueryType === SimpleQueryType.Occurence">
        <input
          type="number"
          formControlName="occurence"
          class="form-control from-control-sm"
          id="occurence"
          [min]="occurenceMin"
          autoSelectOnFocus
          [max]="occurenceMax"
          style="width: 75px" />
      </div>
      <div style="gap: 8px" class="col-auto d-flex flex-row justify-content-center">
        <button
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          (click)="onClickDuplicate($event)"
          ngbTooltip="{{ '::Duplicate' | abpLocalization }} (Shift + Enter)"
          container="body"
          placement="bottom">
          <i class="far fa-copy"></i>
        </button>
        <ca-query-builder-node-remove-button
          (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
      </div>
    </div>
  </div>
</ng-template>
