import { ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { StorageEngine } from '@ngxs/storage-plugin';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserStorageEngine implements StorageEngine {
  get length(): number {
    return localStorage.length;
  }

  constructor(private config: ConfigStateService) {}

  getItem(key: string): any {
    var userId = this.config.getDeep('currentUser.id');

    if (userId) {
      return localStorage.getItem(this.getKey(userId, key));
    }
  }

  setItem(key: string, val: any): void {
    var userId = this.config.getDeep('currentUser.id');

    if (userId) {
      localStorage.setItem(this.getKey(userId, key), val);
    }
  }

  removeItem(key: string): void {
    var userId = this.config.getDeep('currentUser.id');

    if (userId) {
      localStorage.removeItem(this.getKey(userId, key));
    }
  }

  clear(): void {
    localStorage.clear();
  }

  private getKey(userId, key) {
    return environment.stateNameSpace + '_' + userId + '_' + key;
  }
}
