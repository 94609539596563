<form [id]="id" [formGroup]="formGroup" (ngSubmit)="onSubmit()" #formDirective="ngForm">
  <div class="form-container">
    <div class="form-header">
      <ng-content select="ca-compact-form-header"></ng-content>
    </div>
    <div class="form-body">
      <ng-content select="ca-compact-form-body"></ng-content>
    </div>
    <div class="form-footer">
      <ng-content select="ca-compact-form-footer"></ng-content>
    </div>
  </div>
</form>
