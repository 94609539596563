import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PlayerTimelineConversationMarkMarkerDto } from '../models/player-timeline-conversationmark-marker.dto';
import { ConversationMarkDto } from 'src/core/models/mark/conversation-mark.dto';
import { LocalizationService } from '@abp/ng.core';
import { ConversationMarkType } from 'src/core/models/generic-lookup-type/conversation/conversation-mark-type.glt';
@Component({
  selector: 'ca-player-timeline-conversationmark-layer',
  templateUrl: './player-timeline-conversationmark-layer.component.html',
  styleUrls: ['./player-timeline-conversationmark-layer.component.scss'],
})
export class PlayerTimelineConversationMarkLayerComponent implements OnInit {
  private _width: number;
  private _duration: number;
  private _conversationMarks: ConversationMarkDto[];
  private _markInfo: any;
  private _markers: PlayerTimelineConversationMarkMarkerDto[];
  get markers(): PlayerTimelineConversationMarkMarkerDto[] {
    return this._markers;
  }
  set markers(markers: PlayerTimelineConversationMarkMarkerDto[]) {
    this._markers = markers;
  }
  @Input()
  set markerInfo(info: any) {
    this._markInfo = info;
  }

  @Input()
  styles: any = '';

  get markerInfo(): any {
    return this._markInfo;
  }

  @Output()
  markerClick: EventEmitter<{
    startMillisecond: number;
  }> = new EventEmitter();

  constructor(private localizationService: LocalizationService) {}
  initializeLayer(marks: ConversationMarkDto[], duration: number, width: number) {
    this._width = width;
    this._conversationMarks = marks;

    this._duration = duration * 1000;
    this.createMarkers();
  }
  ngOnInit(): void {}

  createMarkers() {
    this.markers = [];
    this._conversationMarks?.forEach(mark => {
      let marker = new PlayerTimelineConversationMarkMarkerDto();
      marker.position = this.findPosition(mark.startMillisecond);
      marker.width = this.calculateWidth(mark.startMillisecond, mark.endMillisecond);
      marker.text =
        this.getChannelText(mark) +
        this.localizationService.instant('GenericLookup::' + mark.type.code) +
        ' : ' +
        this.getSecondsString(mark);
      marker.color = '#000';
      marker.startMillisecond = mark.startMillisecond;
      if (this.markerInfo[mark.type.code]) {
        const info = this.markerInfo[mark.type.code][mark.channel.id];
        if (info) {
          marker.color = info.color;
          if (info.checked) {
            this.markers.push(marker);
          }
        }
      }
    });
  }

  getSecondsString(mark: ConversationMarkDto) {
    const difference = mark.endMillisecond - mark.startMillisecond;
    return (
      (difference / 1000).toFixed(1) + ' ' + this.localizationService.instant('::SecondsShort')
    );
  }

  getChannelText(mark: ConversationMarkDto): string {
    let text = '';
    if (mark.type.id === ConversationMarkType.emotion) {
      text = this.localizationService.instant('GenericLookup::' + mark.channel.code) + ' ';
    }

    return text;
  }

  calculatePixelIntevals(): number {
    const segmentWidth = this._width / 10;
    return Math.round(segmentWidth / (this._width / this._duration));
  }
  findPosition(startMillisecond: number): number {
    let percentage = 0;
    percentage = (startMillisecond / this._duration) * 100;
    return percentage;
  }
  calculateWidth(startMillisecond: number, endMillisecond: number): number {
    const diff = endMillisecond - startMillisecond;
    let percentage = (diff / this._duration) * 100;
    return (this._width * percentage) / 100;
  }
  onMarkerHover(eventArgs: { markerInfo: PlayerTimelineConversationMarkMarkerDto }) {
    const markerInfo = eventArgs.markerInfo;
  }

  onMarkerClick(eventArgs: { markerInfo: PlayerTimelineConversationMarkMarkerDto }) {
    const startMillisecond = eventArgs.markerInfo.startMillisecond;
    this.markerClick.emit({ startMillisecond: startMillisecond });
  }
}
