import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Global.Gender'
})
export class CustomerGender {
    @GenericLookup({
        code: 'Global.Gender.Male'
    })
    static male: number;

    @GenericLookup({
        code: 'Global.Gender.Female'
    })
    static female: number;

    @GenericLookup({
        code: 'Global.Gender.Unknown'
    })
    static unknown: number;

    
}
