import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ca-conversation-card-mixed-list',
    templateUrl: './conversation-card-mixed-list.component.html',
    styleUrls: ['./conversation-card-mixed-list.component.scss'],
})
export class ConversationCardMixedListComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
