import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[autoSelectOnFocus]',
})
export class AutoSelectOnFocus {
  constructor(private el: ElementRef) {}

  @HostListener('focus') onFocus() {
    if (this.el.nativeElement.value) {
      this.el.nativeElement.select();
    }
  }
}
