import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [DatetimePickerComponent],
  imports: [SharedModule, CommonModule, NgbModule, FormsModule],

  exports: [DatetimePickerComponent],
})
export class DatetimePickerModule {}
