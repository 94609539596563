import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.Listening.Status'
})
export class ConversationListeningStatus {
    @GenericLookup({
        code: 'Conversation.Listening.Status.ConversationNotListenedBefore'
    })
    static notListenedBefore: number;

    @GenericLookup({
        code: 'Conversation.Listening.Status.ConversationListenedBeforeByYou'
    })
    static listenedBeforeByYou: number;

    @GenericLookup({
        code: 'Conversation.Listening.Status.ConversationListenedBefore'
    })
    static listenedBefore: number;
}
