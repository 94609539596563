import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LocalizationService } from '@abp/ng.core';
import { TranslationWidth } from '@angular/common';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  private _i18n = {
    language: this.localizationService.currentLang,
  };

  constructor(private localizationService: LocalizationService) {
    super();
  }

  // TODO: C/P from getWeekdayShortName. Implement actual method.
  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return this.localizationService.instant(
      'DateTimeRangePicker::weekdayshort' + weekday.toString()
    );
  }

  getWeekdayShortName(weekday: number): string {
    return this.localizationService.instant(
      'DateTimeRangePicker::weekdayshort' + weekday.toString()
    );
  }
  getMonthShortName(month: number): string {
    return this.localizationService.instant('DateTimeRangePicker::monthshort' + month.toString());
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
