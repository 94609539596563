<ng-select
  appendTo="body"
  [placeholder]="placeholder"
  [items]="items"
  [multiple]="multiple"
  [closeOnSelect]="closeOnSelect"
  [searchable]="false"
  [clearable]="clearable"
  [(ngModel)]="value"
  [disabled]="isDisabled"
  bindLabel="code"
  bindValue="id"
  (change)="onChange($event.id)">
  <ng-template ng-label-tmp let-item="item">
    {{ 'GenericLookup::' + item.code | abpLocalization }}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    {{ 'GenericLookup::' + item.code | abpLocalization }}
  </ng-template>
</ng-select>
