import { Pipe, PipeTransform } from '@angular/core';
import { Operators } from '../models/request/operator.enum';
import { LogicalOperators } from '../models/enum/logical-operators.enum';

@Pipe({ name: 'logicalOperator' })
export class LogicalOperatorPipe implements PipeTransform {
    constructor(private operators: LogicalOperators) {}

    transform(operator: number): string {
        return this.operators.getNotation(operator);
    }
}
