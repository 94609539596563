import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';

@Component({
  selector: 'ca-screen-recorder-settings',
  templateUrl: './screen-recorder-settings.component.html',
  styleUrls: ['./screen-recorder-settings.component.scss'],
})
export class ScreenRecorderSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'ScreenRecorder';
  private readonly videoPrefix = 'VideoPlayer';
  private readonly playScreenRecordingsIndependently = this.prefix +'.'+ this.videoPrefix + '.Enabled';

  constructor(
    private config: ConfigStateService,
    private fb: FormBuilder,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.form = fb.group({
      playScreenRecordingsIndependently: [JSON.parse(this.config.getSetting(this.playScreenRecordingsIndependently).toLowerCase())],
    });
    this.form
      .get('playScreenRecordingsIndependently')
      .patchValue(JSON.parse(this.config.getSetting(this.playScreenRecordingsIndependently).toLowerCase()));
  }

  ngOnInit(): void {}

  onSubmitForm() {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push(
      {
        settingName: this.playScreenRecordingsIndependently,
        settingValue: this.form.get('playScreenRecordingsIndependently').value.toString(),
      },
    );

    this.settingService.saveSetting(settings).subscribe(() => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
