import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConversationNonFcrStatus } from 'src/core/models/generic-lookup-type/conversation/conversation-non-fcr-status.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { IntegerQueryItemDto } from 'src/core/models/query/integer-query-item.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';

@Component({
  selector: 'ca-query-tree-node-non-fcr-status',
  templateUrl: './query-tree-node-non-fcr-status.component.html',
  styleUrls: ['./query-tree-node-non-fcr-status.component.scss'],
})
export class QueryTreeNodeNonFcrStatusComponent extends QueryEditorBase implements OnInit {
  nonFcrStatuses$: Observable<GenericLookupDto[]>;
  currentNonFcrStatus: string;

  payload: IntegerQueryItemDto;

  constructor(private store: Store, private genericLookupService: GenericLookupTypeService) {
    super();

    this.nonFcrStatuses$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationNonFcrStatus)))
      .pipe(map(list => list.filter(item => item.code !== 'Conversation.NonFcrStatus.NotProcessed')));
  }

  valueChanged() {
    this.node.isDirty = true;
    this.payload.genericLookupCode = this.genericLookupService.findGenericLookupWithId(this.payload.value)?.code;
  }

  ngOnInit(): void {
    this.payload = this.node.categoryItem.payload as IntegerQueryItemDto;
    this.nonFcrStatuses$.subscribe((statusList: GenericLookupDto[]) => {
      this.currentNonFcrStatus = statusList.find(item => item.id === this.payload.value).code;
      this.payload.genericLookupCode = this.currentNonFcrStatus;
    });

    this.node.validationStatus = true;
  }
}
