<div class="content-container">
  <div class="summary-container">
    <ca-video-call-summary [conversation]="conversation"></ca-video-call-summary>
  </div>
  <div class="center-container">
    <div class="tab-panel-container">
      <ul [destroyOnHide]="false" ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
        <li [ngbNavItem]="1" *ngIf="analyticsFeatureEnabled">
          <a ngbNavLink
            ><i class="fas fa-file-alt"></i
            ><span style="margin-left: 4px">{{
              'Conversation::Transcript' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <p>
              <ca-meeting-transcript-panel
                [transcripts]="transcripts"
                [player]="player"
                [analysisStatusId]="
                  conversation?.videoCall?.analysisStatusId
                "></ca-meeting-transcript-panel>
            </p>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" *ngIf="analyticsFeatureEnabled && conversation?.videoCall?.analysisStatusId === videoCallAnalysisType.analyzed">
          <a ngbNavLink
            ><i class="fas fa-analytics"></i
            ><span style="margin-left: 4px">{{
              'Conversation::Analysis' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-call-analysis-panel [analysisData]="analysisData"></ca-call-analysis-panel>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink
            ><i class="fas fa-paperclip"></i
            ><span style="margin-left: 4px">{{
              'Conversation::QueryBuilder:AttachedData' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-attached-data-panel [conversationId]="conversation?.id"></ca-attached-data-panel>
          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink
            ><i class="fas fa-comments"></i
            ><span style="margin-left: 4px">{{ '::Comment' | abpLocalization }}</span>
            <span class="badge bg-secondary ms-1 mb-1">{{ commentCount }}</span>
          </a>
          <ng-template ngbNavContent>
            <ca-conversation-comment-panel
              #commentPanel
              [conversationId]="conversation?.id"
              (goToCommentRequest)="onGoToComment($event)"
              (commentCountChanged)="onCommentCountChanged($event)">
            </ca-conversation-comment-panel>
          </ng-template>
        </li>
        <li *ngIf="qualityManagementFeatureEnabled" [ngbNavItem]="5">
          <a ngbNavLink
            ><i class="fas fa-file-check"></i
            ><span style="margin-left: 4px">{{
              'Conversation::Evaluation' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-conversation-evaluation-list-panel
              *ngIf="activeTab == 5"
              [conversationId]="conversation?.id"
              [player]="player"
              [activeTab]="activeTab"></ca-conversation-evaluation-list-panel>
          </ng-template>
        </li>
        <li [ngbNavItem]="6">
          <a ngbNavLink
            ><i class="fas fa-history"></i
            ><span style="margin-left: 4px">{{ 'ActionLog::History' | abpLocalization }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-conversation-history-panel
              *ngIf="activeTab == 6"
              [conversationId]="conversation?.id"
              [activeTab]="activeTab"></ca-conversation-history-panel>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <div class="player-controller-container">
      <ca-player-controller
        [player]="player"
        [collapsible]="false"
        [hideCommentButton]="false"
        [showSentimentButtons]="!conversation?.call?.isDiarize && analyticsFeatureEnabled && sentimentFetureEnabled"
        (addCommentRequested)="onAddCommentRequest($event)"
        #playerController></ca-player-controller>
    </div>
  </div>
</div>
<div class="player-container">
  <ca-player
    style="width: 100%"
    [fillParent]="true"
    [expandedPlayerHeight]="70"
    [minimizedPlayerHeight]="18"
    [showSentiments]="analyticsFeatureEnabled"
    [expanded]="true"
    [splitChannels]="false"
    [hideChannelIcons]="true"
    [loadAtOnce]="false"
    #player
    (startPlaying)="setListeningStatus($event)"></ca-player>
</div>
