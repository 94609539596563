<div [style.padding]="analysisData?.statusId != callAnalysisType.analyzed ? '15px' : ''">
  <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.notAnalyzed">
    {{ 'Conversation::CallNotAnalyzed' | abpLocalization }}
  </div>

  <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.analyzing">
    {{ 'Conversation::CallIsAnalyzing' | abpLocalization }}
  </div>

  <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.noSpeech">
    {{ 'Conversation::CallAnalysisNoSpeech' | abpLocalization }}
  </div>

  <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.fileNotFound">
    {{ 'Conversation::CallAnalysisFileNotFound' | abpLocalization }}
  </div>

  <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.failed">
    <strong>{{ 'Conversation::CallAnalysisFaild' | abpLocalization }}</strong>
    {{ analysisData?.error }}
  </div>

  <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.notSupportedLanguageCode">
    {{ 'Conversation::CallAnalysisNotSupportedLanguageCode' | abpLocalization }}
  </div>

  <div
    class="m-2"
    *ngIf="analysisData?.statusId == callAnalysisType.languageDetectionServiceDisabled">
    {{ 'Conversation::CallAnalysisLanguageDetectionServiceDisabled' | abpLocalization }}
  </div>

  <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.notSupportedAudioFormat">
    {{ 'Conversation::CallAnalysisNotSupportedAudioFormat' | abpLocalization }}
  </div>

  <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.speechRecognitionError">
    {{ 'Conversation::CallAnalysisSpeechRecognitionError' | abpLocalization }}
  </div>
</div>

<table
  mat-table
  [dataSource]="dataSource"
  class="w-100"
  *ngIf="analysisData?.statusId == callAnalysisType.analyzed">
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>{{ '::Parameter' | abpLocalization }}</th>
    <td mat-cell *matCellDef="let element" class="position-relative">
      {{
        'Conversation::' + element.name.charAt(0).toUpperCase() + element.name.slice(1)
          | abpLocalization
      }}
      {{ getUnitLabel(element.unit) }}
      <!-- Mark color -->
      <div
        *ngIf="element.checked"
        [style.background-color]="element.color"
        [ngClass]="'check-element' + (element.goToMarkOnClick ? ' c-pointer' : '')"
        (click)="element.goToMarkOnClick && onGoToMarkClick(element.name)"
        [style]="rtlStylePosition">
        <i class="fas fa-play icon-container"></i>
      </div>
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef>{{ '::Value' | abpLocalization }}</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="element.name != 'customerGenderText'">
        {{ analysisData[element.name] | number : '1.0-1' }}
      </div>
      <div *ngIf="element.name == 'customerGenderText'">
        {{ analysisData[element.name] }}
      </div>
    </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle
        *ngIf="element.visibleOnPlayer"
        matTooltip="{{ '::ShowOnPlayer' | abpLocalization }}"
        name="{{ element.name }}"
        [checked]="element.checked"
        (change)="onVisibleChange($event)">
      </mat-slide-toggle>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" [hidden]="isNull(row)"></tr>

  <!-- Group header -->
  <ng-container matColumnDef="groupHeader">
    <td colspan="999" mat-cell *matCellDef="let groupBy">
      <strong>{{ '::' + groupBy.initial | abpLocalization }}</strong>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"></tr>
</table>
