import {
  FilterPanelValueChange,
  ChildPanelVisibilityChange,
  ChildPanelActivityChange,
  PanelSizeChange,
} from './../actions/filter-panel.actions';
import { Injectable } from '@angular/core';
import { State, StateContext, Action } from '@ngxs/store';
import { FilterPanelStateModel } from '../models/filter-panel.state-model';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { GenericState } from 'src/core/states/generic/generic.state';
const name = 'FilterPanelState';
@State<FilterPanelStateModel>({
  name: name,
  defaults: {
    values: {},
    hiddenPanels: {},
    activePanels: {},
    collapsed: {},
  },
})
@Injectable()
export class FilterPanelState extends GenericState<FilterPanelState> {
  constructor(userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;

    this.userSettingsService.register(this.name, this);
  }

  @Action(FilterPanelValueChange)
  changeValues(ctx: StateContext<FilterPanelStateModel>, action: FilterPanelValueChange) {
    const state = ctx.getState();

    state.values[action.key] = action.values;
    this.userSettingsService.setState(ctx, state, name);
  }

  @Action(ChildPanelVisibilityChange)
  changeVisibility(ctx: StateContext<FilterPanelStateModel>, action: ChildPanelVisibilityChange) {
    const state = ctx.getState();

    state.hiddenPanels[action.key] = action.hiddenPanels;

    this.userSettingsService.setState(ctx, state, name);
  }

  @Action(ChildPanelActivityChange)
  changeActivity(ctx: StateContext<FilterPanelStateModel>, action: ChildPanelActivityChange) {
    const state = ctx.getState();

    state.activePanels[action.key] = action.activePanels;

    this.userSettingsService.setState(ctx, state, name);
  }

  @Action(PanelSizeChange)
  changePanelSize(ctx: StateContext<FilterPanelStateModel>, action: PanelSizeChange) {
    const state = ctx.getState();

    state.collapsed[action.key] = action.collapsed;

    this.userSettingsService.setState(ctx, state, name);
  }
}
