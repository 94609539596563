import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { GenericLookupTypeDto } from 'src/core/models/generic-lookup-type/generic-lookup-type.dto';
import { DataChange } from 'src/core/actions/generic-lookup-type/generic-lookup-type.actions';
import { GenericLookupTypeContainer } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { Observable, of } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GenericLookupTypeService {
  private apiBase = environment.apis.default.url;
  private cache$: Observable<any>;
  constructor(private http: HttpClient, private store: Store) {}

  load(): Observable<boolean> {
    return this.requestGenericLookupTypes().pipe(
      map(response => {
        this.setState(response);
        this.setGenericLookupTypes();

        return true;
      }),
      catchError(err => {
        console.error('An error has occured while fetching generic lookups. [Error: ' + err + ']');

        return of(false);
      })
    );
  }

  private requestGenericLookupTypes() {
    let req = this.http.get(this.apiBase + '/api/app/generic-lookup-type');
    if (!this.cache$) {
      this.cache$ = req.pipe(shareReplay(1));
    }

    return this.cache$;
  }

  private setGenericLookupTypes() {
    const list = GenericLookupTypeContainer.list;

    list.forEach(glt => {
      const proto = glt.prototype;
      const mappings = proto.mappings;

      for (const key in mappings) {
        if (mappings.hasOwnProperty(key)) {
          const code = mappings[key];
          const genericLookup = this.findGenericLookup(proto.code, code);

          if (genericLookup) {
            glt[key] = genericLookup.id;
          }
        }
      }
    });
  }

  private findGenericLookupType(code: string): any {
    const genericLookupTypes = this.store.selectSnapshot(
      GenericLookupTypeState.getGenericLookupTypes
    );
    let result;

    for (const key in genericLookupTypes) {
      if (genericLookupTypes.hasOwnProperty(key)) {
        const element = genericLookupTypes[key];

        if (element.code === code) {
          result = element;
          break;
        }
      }
    }

    return result;
  }

  findGenericLookupWithId(id: number): GenericLookupTypeDto {
    const genericLookupTypes = this.store.selectSnapshot(
      GenericLookupTypeState.getGenericLookupTypes
    );
    let result;

    for (const key in genericLookupTypes) {
      if (genericLookupTypes.hasOwnProperty(key)) {
        const element = genericLookupTypes[key];

        const genericLookup = element.genericLookups.filter(gl => gl.id == id);
        if (genericLookup.length != 0) {
          result = genericLookup[0];
          break;
        }
      }
    }

    return result;
  }

  findGenericLookupWithCode(code: string): GenericLookupTypeDto {
    const genericLookupTypes = this.store.selectSnapshot(
      GenericLookupTypeState.getGenericLookupTypes
    );
    let result;

    for (const key in genericLookupTypes) {
      if (genericLookupTypes.hasOwnProperty(key)) {
        const element = genericLookupTypes[key];

        const genericLookup = element.genericLookups.filter(gl => gl.code == code);
        if (genericLookup.length != 0) {
          result = genericLookup[0];
          break;
        }
      }
    }

    return result;
  }

  private findGenericLookup(genericLookupTypeCode: string, genericLookupCode: string): any {
    const genericLookupType = this.findGenericLookupType(genericLookupTypeCode);
    const genericLookups = genericLookupType?.genericLookups;
    let result;

    for (const key in genericLookups) {
      if (genericLookups.hasOwnProperty(key)) {
        const element = genericLookups[key];

        if (element.code === genericLookupCode) {
          result = element;
          break;
        }
      }
    }

    return result;
  }

  private setState(response) {
    const genericLookupTypes = response.items as GenericLookupTypeDto[];

    const action = new DataChange(genericLookupTypes);
    this.store.dispatch(action);
  }

  showGenericLookup(id: number, lookupId: number) {
    return this.http.put(
      `${this.apiBase}/api/app/generic-lookup-type/${id}/genericlookup/${lookupId}/show`,
      null
    );
  }

  hideGenericLookup(id: number, lookupId: number) {
    return this.http.put(
      `${this.apiBase}/api/app/generic-lookup-type/${id}/genericlookup/${lookupId}/hide`,
      null
    );
  }

  fix(): Observable<any> {
    return this.http.get('api/app/generic-lookup-type/fix');
  }
}
