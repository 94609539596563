<div *ngIf="userPhotoUrl == null" class="default-image" [ngClass]="imgClass" [ngStyle]="styles">
  <span class="default-text">{{ initials }}</span>
</div>
<img
  *ngIf="!loading && userPhotoUrl != null"
  [src]="userPhotoUrl"
  [ngClass]="imgClass"
  [alt]="alt"
  [ngStyle]="styles"
  (load)="onImageLoad()" />
