<div
  style="position: relative; height: 100%; display: none"
  [ngClass]="closed === false ? 'active' : ''">
  <div class="resizer resizer-left"></div>
  <div class="resizer resizer-top"></div>
  <div class="resizer resizer-right"></div>
  <div class="resizer resizer-bottom"></div>

  <div class="resizer resizer-top-left"></div>
  <div class="resizer resizer-bottom-left"></div>
  <div class="resizer resizer-top-right"></div>
  <div class="resizer resizer-bottom-right"></div>

  <div class="text-lg-right pt-2 ca-toolbar bg-light">
    <div class="ca-toolbar-west-section">
      <div class="toolbar-item first">
        <i [ngClass]="'fad fa-video' + (maximized ? '' : ' drag-handle')"></i>
      </div>
      <div class="toolbar-item">
        <span>{{ title }}</span>
      </div>
    </div>
    <div class="ca-toolbar-east-section">
      <div
        class="toolbar-item"
        ngbTooltip="{{ 'Conversation::VideoPlayer:Minimize' | abpLocalization }}">
        <button class="btn btn-sm ca-btn ca-btn-neutral-command" (click)="onClickMinimize($event)">
          <i class="far fa-window-minimize"></i>
        </button>
      </div>
      <div
        class="toolbar-item last"
        *ngIf="!maximized"
        ngbTooltip="{{ 'Conversation::VideoPlayer:Maximize' | abpLocalization }}">
        <button
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          (click)="onClickMaximize($event, true)">
          <i class="far fa-window-maximize"></i>
        </button>
      </div>
      <div
        class="toolbar-item last"
        *ngIf="maximized"
        ngbTooltip="{{ 'Conversation::VideoPlayer:Restore' | abpLocalization }}">
        <button
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          (click)="onClickMaximize($event, false)">
          <i class="far fa-window-restore"></i>
        </button>
      </div>
      <!-- <div class="toolbar-item last" [ngbTooltip]="'Close'">
        <button class="btn btn-sm ca-btn ca-btn-neutral-command" (click)="onClickClose($event)">
          <i class="far fa-times"></i>
        </button>
      </div> -->
    </div>
  </div>
  <div class="video-player-wrapper" style="height: calc(100% - 41px)">
    <video width="100%" height="100%"></video>
  </div>
</div>
