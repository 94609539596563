import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { LayoutConfig } from 'src/core/models/split/layout-config.model';
import { LayoutChange, LayoutReset } from 'src/core/actions/layout/layout-actions';
import { LayoutStateModel } from 'src/core/models/layout/layout.state-model';
import { CALayoutState } from 'src/core/states/layout/ca-layout.state';
import { ObjectHelper } from 'src/core/helpers/object.helper';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(private store: Store) {}

  changeVisibilityForFilterPanel(viewName: string, visible: boolean) {
    let viewLayout = this.getCurrentLayout(viewName);

    if (viewLayout === undefined || viewLayout === null) {
      viewLayout = LayoutConfig.DEFAULT;
    }

    if (viewLayout.west.visible && visible) {
      return;
    } else if (!viewLayout.west.visible && !visible) {
      return;
    }

    viewLayout.west.visible = !viewLayout.west.visible;

    const action = new LayoutChange(viewName, viewLayout);
    this.store.dispatch(action);
  }

  changeVisibilityForDetailPanel(viewName: string, visible: boolean) {
    let viewLayout = this.getCurrentLayout(viewName);

    if (viewLayout === undefined || viewLayout === null) {
      viewLayout = LayoutConfig.DEFAULT;
    }

    if (viewLayout.east.visible && visible) {
      return;
    } else if (!viewLayout.east.visible && !visible) {
      return;
    }

    viewLayout.east.visible = !viewLayout.east.visible;

    const action = new LayoutChange(viewName, viewLayout);
    this.store.dispatch(action);
  }

  onDragEnd(viewName: string, e: { gutterNum: number; sizes: Array<number> }) {
    let viewLayout = this.getCurrentLayout(viewName);

    if (viewLayout === undefined || viewLayout === null) {
      viewLayout = LayoutConfig.DEFAULT;
    }

    if (viewLayout.west.visible) {
      viewLayout.west.size = e.sizes[0];
      viewLayout.center.size = e.sizes[1];
      viewLayout.east.size = e.sizes[2];
    } else {
      viewLayout.center.size = e.sizes[0];
      viewLayout.east.size = e.sizes[1];
    }

    const action = new LayoutChange(viewName, viewLayout);
    this.store.dispatch(action);
  }

  resetLayout(mainLayout: string, viewName: string) {
    const action = new LayoutReset(viewName);
    this.store.dispatch(action);
  }

  getCurrentLayout(viewName: string): LayoutConfig {
    let state = this.store.selectSnapshot(CALayoutState) as LayoutStateModel[];

    state = ObjectHelper.deepCopy(state);

    const viewLayouts = state.filter(l => l.name === viewName);
    let viewLayout: LayoutStateModel;

    if (viewLayouts.length > 0) {
      viewLayout = viewLayouts[0];
    } else {
      viewLayout = {
        name: viewName,

        config: ObjectHelper.deepCopy(LayoutConfig.DEFAULT),
      };
    }

    return viewLayout.config;
  }

  changeCurrentLayout(viewName: string, viewLayout: LayoutConfig) {
    const action = new LayoutChange(viewName, viewLayout);
    this.store.dispatch(action);
  }
}
