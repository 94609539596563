import { ServerSideEnum } from '../enum/server-side-enum.model';
import { Injectable, Injector } from '@angular/core';
import { MapToServerSideEnum } from 'src/core/decorators/enum/mapto-server-side-enum.decorator';

@Injectable({
  providedIn: 'root',
})
@MapToServerSideEnum('CallTranscriptChannel')
export class CallTranscriptChannel extends ServerSideEnum {
  Agent: number;
  Customer: number;

  constructor(injector: Injector) {
    super(injector);
  }
}
