import { Component, OnInit } from '@angular/core';
import { VersionInfoDto } from 'src/core/models/version/version-info.dto';
import { CrudService } from 'src/core/services/crud/crud.service';
import * as _uiVersionJson from 'src/version.json';
@Component({
  selector: 'ca-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss'],
})
export class VersionInfoComponent implements OnInit {
  versionInfo = new VersionInfoDto();
  private uiVersionJson = _uiVersionJson;
  constructor(private service: CrudService) {}
  ngOnInit(): void {
    if (this.uiVersionJson != null) {
      this.versionInfo.uiVersion = this.uiVersionJson.version;
      this.versionInfo.uiUpdateDate = this.uiVersionJson.updateDate;
    }
    this.service.getById<VersionInfoDto>(VersionInfoDto, null).subscribe(response => {
      this.versionInfo.version = response.version;
      this.versionInfo.updateDate = response.updateDate;
    });
  }
}
