import { FormGroup, FormBuilder } from '@angular/forms';
import { FilterPanelComponent } from '../filter-panel.module';
import { FilterPanelEditorConfigurationModel } from './filter-panel-editor-configuration.model';

export abstract class FilterPanelEditorComponent {
  abstract set config(value: FilterPanelEditorConfigurationModel);
  abstract get config(): FilterPanelEditorConfigurationModel;

  editorForm: FormGroup;
  parentFilterPanel: FilterPanelComponent;
  parentItemKey: string;
  parentFilterPanelKey: string;
  ignoreKeyOnValueCollection: boolean;
  extraData: any = {};
  emitChangeEvent = false;

  abstract autoSelectFirstItem(value: boolean | null): void;
  abstract reload(): void;
  abstract reset(): void;
  abstract set value(value: any);
  abstract get value(): any;

  init(): void {
    if (Array.isArray(this.config.validators)) {
      this.editorForm.controls.editor.setValidators(this.config.validators);
      this.editorForm.controls.editor.markAsDirty();
      this.editorForm.controls.editor.updateValueAndValidity();
    }
  }

  setExtraData(key: string, value: any): void {
    this.extraData[key] = value;
  }

  onChange(initial: boolean = false) {
    if (typeof this.config.onChange === 'function' && this.emitChangeEvent) {
      this.config.onChange(this.parentFilterPanel, this.value, initial);
    }
  }

  constructor(protected fb: FormBuilder) {
    this.editorForm = this.fb.group({
      editor: [null],
    });
  }
}
