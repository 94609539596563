<ng-container [formGroup]="editorForm">
  <ca-user-auto-complete-selector
    #userSelector
    formControlName="editor"
    [userRoleSelection]="actualConfig.userRoleSelection"
    [nullElement]="actualConfig.nullElement"
    [nullElementTerm]="actualConfig.nullElementTerm"
    [filterByStatus]="actualConfig.filterByStatus">
  </ca-user-auto-complete-selector>
</ng-container>
