import { Api } from 'src/core/decorators/dto/api.decorator';
import { TopicDto } from '../conversation/ai-topic/topic.dto';
import { DtoHasApi } from '../shared/dto-has-api.model';
import { QueryDto } from './query.dto';

@Api('api/app/category')
export class CategoryDto extends DtoHasApi {
  id: string;
  queryId?: number;
  topicId?: number;
  name: string;
  categoryType: number;
  categoryColor?: string;
  query?: QueryDto;
  topic?: TopicDto;
}
