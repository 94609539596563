import { AfterContentInit, Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ca-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit, AfterContentInit {
  private inputClassList = {
    input: {
      text: ['form-control', 'form-control-sm', 'mt-1'],
      checkbox: ['form-check-input'],
    },
    textarea: ['form-control', 'form-control-sm', 'mt-1'],
    select: ['form-select', 'form-select-sm', 'mt-1', 'ca-compact-form-select'],
    caSelect: ['mt-1'],
    noExtraStyling: ['mt-1'],
  };

  @Input()
  doNotApplyStyling: boolean = false;

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    const el = this.elRef.nativeElement as HTMLElement;
    const caSelect = el.querySelector('ca-select ng-select') as HTMLSelectElement;
    const input = el.querySelector('input') as HTMLInputElement;
    const select = el.querySelector('select') as HTMLSelectElement;
    const textarea = el.querySelector('textarea');
    let target: HTMLElement = caSelect ?? input ?? select ?? textarea;
    let classList: string[];

    if (this.doNotApplyStyling) {
      target = el.children[0] as HTMLElement;
      classList = this.inputClassList.noExtraStyling;
    } else {
      if (caSelect) {
        classList = this.inputClassList.caSelect;
      } else if (select) {
        classList = this.inputClassList.select;
      } else if (input) {
        classList = this.inputClassList.input[input.type];
      } else if (textarea) {
        classList = this.inputClassList.textarea;
      }
    }

    if (classList) {
      classList.forEach(cls => {
        target.classList.add(cls);
      });
    }
  }
}
