import { Pipe, PipeTransform } from '@angular/core';
import { Operators } from '../models/request/operator.enum';
import { LogicalOperators } from '../models/enum/logical-operators.enum';

@Pipe({ name: 'operator' })
export class OperatorPipe implements PipeTransform {
    constructor(private operators: Operators) {}

    transform(operator: number): string {
        return this.operators.getNotation(operator);
    }
}
