export class GenericLookupTypeContainer {
    static list: any[] = [];
}

export function GenericLookupType(param: any) {
    const actualDecorator = (target: any) => {
        const prototype = target.prototype;
        prototype.code = param.code;

        GenericLookupTypeContainer.list.push(target);
    };

    return actualDecorator;
}
