<div class="snackbar-container d-flex flex-row justify-content-stretch align-items-center">
  <div class="counter">
    <ca-rating
      [maximumValue]="15"
      [value]="counter"
      [colorCalculatorFn]="colorCalculatorFn"></ca-rating>
  </div>
  <div class="flex-grow-1 flex-shrink-1 ms-2 me-4">
    <span matSnackBarLabel>{{ 'Query::RemovedCategoryGroup' | abpLocalization }}</span>
  </div>
  <div class="ms-4">
    <button
      class="btn ca-btn ca-btn-primary"
      type="button"
      (click)="snackBarRef.dismissWithAction()">
      <span class="with-no-icon">{{ 'Query::Undo' | abpLocalization }}</span>
    </button>
  </div>
  <div>
    <button class="btn ca-btn ca-btn-neutral" type="button" (click)="snackBarRef.dismiss()">
      <span class="with-no-icon">{{ 'Query::Dismiss' | abpLocalization }}</span>
    </button>
  </div>
</div>
