import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  OnDestroy,
  Input,
  HostListener,
} from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTree } from '@angular/material/tree';
import { QueryBuilderCategoryDto } from 'src/core/models/query/query-builder-category.dto';
import { QueryTreeNodeFactoryComponent } from '../../query-tree-node-factory/query-tree-node-factory.component';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { QueryItemDto } from 'src/core/models/query/query-item.dto';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { IQueryBuilderCategoryItem } from 'src/core/models/query/query-builder-category-item.interface';
import { QueryDto } from 'src/core/models/query/query.dto';
import { QueryTreeNodeModel } from 'src/core/models/query/query-tree-node.model';
import { IQueryItemDto } from 'src/core/models/query/query-item-dto.interface';
import { ConversationFilterableFields } from 'src/core/models/conversation/conversation-filterable-fields.enum';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { LocalizationService } from '@abp/ng.core';
import { Router } from '@angular/router';
import { QueryService } from 'src/core/services/query/query.service';
import { QueryTreeNodeOrderedGroupComponent } from '../../query-builder-nodes/query-tree-node-ordered-group/query-tree-node-ordered-group.component';
import { SimpleQueryDto } from 'src/core/models/query/simple-query.dto';
import { IUnsavedChangesTracker } from 'src/core/models/shared/unsaved-changes-tracker-interface';
import { PermissionService } from '@abp/ng.core';
import { StringService } from 'src/core/services/helper/string.service';
import { SimpleFormDto } from 'src/core/models/quality-management/simple-form.dto';
import { FormService } from 'src/core/services/quality-management/form.service';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { BehaviorSubject, combineLatest, take } from 'rxjs';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { AbstractControl } from '@angular/forms';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { CategoryService } from 'src/core/services/category/category.service';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { TimerForNodeRemovalComponent } from '../timer-for-node-removal/timer-for-node-removal.component';
import { QueryConstants } from 'src/core/constants/query-constant';

@Component({
  selector: 'ca-query-detail-builder',
  templateUrl: './query-detail-builder.component.html',
  styleUrls: ['./query-detail-builder.component.scss'],
})
export class QueryDetailBuilderComponent implements OnInit, OnDestroy, IUnsavedChangesTracker {
  private _categoriesLoaded = false;
  private _loading = false;
  private _firstLoadFlag: boolean;
  private _hasUnsavedChanges = false;
  private _textSearchQueryItemLimit: number;
  private _snackBarRefForNodeRemoval: any;
  private _hasActiveSnackBar: boolean;
  private _tempRemoval: {
    source: QueryTreeNodeModel[];
    nodes: QueryTreeNodeModel[];
    index: number;
  }[] = [];

  thisRef: QueryDetailBuilderComponent;
  categories: QueryBuilderCategoryDto[] = [];
  treeData: QueryTreeNodeModel[] = [];
  treeControl = new NestedTreeControl<QueryTreeNodeModel>(node => node.children);
  treeScrollTop = 0;
  @ViewChild('queryTreeBody')
  queryTreeBody: ElementRef;
  @ViewChild('queryTree')
  queryTree: MatTree<QueryTreeNodeModel>;
  nodeFactories: QueryTreeNodeFactoryComponent[] = [];
  callVisible: string[];
  chatVisible: string[];
  resetVisible = false;
  resetTreeVisible = false;
  currentQuery: QueryDto;
  canEditQuery: boolean = false;
  queryFormNameControl: AbstractControl;

  valuesChanged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  formFilters: FilterItemDto[] = [];

  @HostListener('window:keypress', ['$event'])
  handleKeyPress(eventArgs: KeyboardEvent) {
    if (eventArgs.key === 'Enter' && eventArgs.shiftKey) {
      const el = eventArgs.target as HTMLElement;
      const parent = el.closest('.query-node');

      el.blur();

      if (parent) {
        const strInternalIdToDuplicate = parent.getAttribute('data-internal-id-to-duplicate');
        const strAllowDuplication = parent.getAttribute('data-allow-duplication');
        const internalIdToDuplicate = Number(strInternalIdToDuplicate);
        const allowDuplication = strAllowDuplication === 'true';

        if (allowDuplication) {
          const newInternalId = this.duplicateNode(internalIdToDuplicate);

          setTimeout(() => {
            let newNode = document.querySelectorAll(
              `.query-node[data-internal-id="${newInternalId + 2}"]`
            );

            newNode =
              newNode.length > 0
                ? newNode
                : document.querySelectorAll(`.query-node[data-internal-id="${newInternalId}"]`);

            const inputToFocus = newNode
              .item(0)
              .querySelector('input[type="text"]') as HTMLInputElement;

            inputToFocus.select();
          }, 100);
        }
      }

      eventArgs.preventDefault();
      eventArgs.stopPropagation();
    }
  }

  public processing: boolean = false;
  @Output()
  saveQueryRequested: EventEmitter<{
    closeAfterSave: boolean;
  }> = new EventEmitter();

  @Output()
  deleteQueryRequested: EventEmitter<{
    queryId: number;
  }> = new EventEmitter();

  @Output()
  queryParseRequested: EventEmitter<{
    node: QueryTreeNodeModel;
  }> = new EventEmitter();

  @Input()
  isReadonly: boolean;

  @Input()
  isImportOrCreateNew: boolean;

  @Input()
  set languageCode(value: string) {
    this._languageCode = value;
  }

  get languageCode(): string {
    return this._languageCode;
  }

  get isPhraseSuggestionSupported(): boolean {
    return this.queryService?.isPhraseSuggestionSupportedForLanguage(this.languageCode) ?? false;
  }

  private _languageCode: string;

  dataSource = new MatTreeNestedDataSource<QueryTreeNodeModel>();

  refreshTreeData(data: QueryTreeNodeModel[]) {
    const newData = Array.from(data);
    this.dataSource.data = null;
    this.dataSource = new MatTreeNestedDataSource<QueryTreeNodeModel>();
    this.dataSource.data = newData;
    this.valuesChanged$.next(true);
  }

  get conversationType(): any {
    return this.currentQuery.queryConversationTypeId;
  }

  get opeatorCategory(): QueryBuilderCategoryDto {
    let result: QueryBuilderCategoryDto = null;
    this.categories.forEach(ct => {
      if (ct.id === 1) {
        result = ct;
      }
    });

    return result;
  }

  get visibleCategories(): QueryBuilderCategoryDto[] {
    return this.categories.filter(v => v.visible);
  }

  get builderTreeData(): QueryTreeNodeModel[] {
    return this.treeData;
  }

  get textSearchQueryItemLimit(): number {
    return this._textSearchQueryItemLimit;
  }

  getFieldsetClasses() {
    return this.isReadonly ? 'builder-fieldset-readonly' : 'builder-fieldset';
  }

  getMatTreeOuterClasses() {
    return this.isReadonly
      ? 'mat-tree-outer readonly-children full-height'
      : 'mat-tree-outer full-height';
  }

  enableProcessingStatus() {
    this.processing = true;
  }
  disableProcessingStatus() {
    this.processing = false;
  }
  clearTree() {
    this.treeData.splice(0, this.treeData.length);
    this.refreshTreeData(this.treeData);
    this.resetCategoryVisible();
    this.resetQueryTreeScroll();
  }

  private addConversationTypeNode() {
    let targetCategory: QueryBuilderCategoryDto;
    let targetCategoryItem: IQueryBuilderCategoryItem;

    for (let i = 0; i < this.categories.length; i++) {
      let found = false;
      const category = this.categories[i];

      for (let j = 0; j < category.items.length; j++) {
        const categoryItem = category.items[j];
        const payload = categoryItem.payload as any;

        if (payload.field && payload.field === this.queryField.Conversation_Type) {
          targetCategory = category;
          targetCategoryItem = categoryItem;
          payload.value = this.currentQuery.queryConversationTypeId;
          found = true;
        }
      }

      if (found) {
        break;
      }
    }

    targetCategoryItem.payload.categoryId = targetCategory.id;
    targetCategoryItem.payload.categoryItemId = targetCategoryItem.id;
    targetCategoryItem.payload.internalId = this.queryService.getInternalId();

    this.addNode(targetCategory, targetCategoryItem, null, false, false, false);
  }

  setCategoryVisible() {
    this.categories.forEach(category => {
      if (
        category.cssClass === 'conversation' ||
        (!this.resetVisible &&
          this.currentQuery.queryConversationTypeId === ConversationType.call &&
          this.callVisible.includes(category.cssClass)) ||
        (this.currentQuery.queryConversationTypeId === ConversationType.chat &&
          this.chatVisible.includes(category.cssClass)) ||
        (this.currentQuery.queryConversationTypeId === ConversationType.all &&
          this.callVisible.includes(category.cssClass) &&
          this.chatVisible.includes(category.cssClass))
      ) {
        category.visible = true;
      } else {
        category.visible = false;
      }
    });
  }

  resetCategoryVisible() {
    this.setCategoryVisible();
  }

  hasChild = (_: number, node: QueryTreeNodeModel) =>
    // tslint:disable-next-line: semicolon
    !!node.children && node.children.length > 0;

  onSaveClick(eventArgs: MouseEvent, closeAfterSave: boolean) {
    this.saveQueryRequested.emit({ closeAfterSave: closeAfterSave });
  }

  confirmDeletion(query, eventArgs) {
    this.getControlObservables(query).subscribe(response =>
      this.confirmDeletionQuery(query, response[0].items, response[1].items, eventArgs)
    );
  }

  confirmDeletionQuery(
    query: QueryDto,
    mergedQueries: SimpleQueryDto[],
    forms: SimpleFormDto[],
    eventArgs
  ) {
    if (mergedQueries.length <= 0) {
      this.confirmationService
        .warn('::DeletionConfirmationMessage', '', {
          messageLocalizationParams: [query.name],
          yesText: '::Delete',
        })
        .subscribe((status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.delete(eventArgs);
          }
        });
    } else {
      if (mergedQueries.length > 0) {
        this.toastr.error(
          `${this.localizationService.instant(
            'Query::DeleteMergedQueryInfo'
          )} ${this.stringService.getCombinedStringsMaxTwo(mergedQueries.map(x => x.name))}`
        );
      }
      if (forms.length > 0) {
        this.toastr.error(
          `${this.localizationService.instant(
            'Query::DeleteFormsInfo'
          )} ${this.stringService.getCombinedStringsMaxTwo(forms.map(x => x.name))}`
        );
      }
    }
  }

  getControlObservables(query: QueryDto) {
    var mergedQueriesObservable = this.queryService.getMergedQueries(query.id);
    var formsObservable = this.formService.getEvaluableForms<SimpleFormDto>({
      filters: this.formFilters.concat({
        field: 'hasQuery',
        operator: this.operators.Equals,
        value: query.id,
      }),
      sorters: [],
      maxResultCount: 99999,
      skipCount: 0,
    });
    return combineLatest(mergedQueriesObservable, formsObservable);
  }

  delete(eventArgs) {
    this.deleteQueryRequested.emit({ queryId: this.currentQuery.id });
  }

  loadQueryItems(
    queryItemsArg: QueryItemDto[] | null,
    categoriesArg: QueryBuilderCategoryDto[] | null,
    queryArg: QueryDto | null,
    isImport: boolean = false,
    queryFormNameControl: AbstractControl = null
  ) {
    this._firstLoadFlag = true;
    this.queryFormNameControl = queryFormNameControl ? queryFormNameControl : null;

    // if (!this._categoriesLoaded) {
    //   setTimeout(() => {
    //     this.load(queryId);
    //   }, 100);

    //   return;
    // }

    if (queryArg != null) {
      this.currentQuery = queryArg;
    }

    if (categoriesArg != null) {
      this.categories = categoriesArg;
      this.sortCategoryItems();
      this._categoriesLoaded = true;
    }

    if (this._loading) {
      return;
    }

    if (queryItemsArg != null) {
      this._loading = true;

      this.clearTree();
      this.buildQuery(queryItemsArg, isImport);

      this._loading = false;
      this._firstLoadFlag = false;
    } else {
      this.clearTree();
      this._firstLoadFlag = false;
    }

    this._hasUnsavedChanges = false;
  }

  buildQuery(items: QueryItemDto[], isImport: boolean = false): void {
    const nodes = {};
    items.forEach(item => {
      const initialOperatorId = this.queryService.getInternalId();

      let payload = JSON.parse(item.serializedPayload) as IQueryItemDto;
      const multipleValuePayloads: IQueryItemDto[] = [];

      // do not render multiple value items
      if (payload.multiple) {
        return;
      }

      const multipleValueChildren = items
        .filter(i => i.dataType === item.dataType)
        .map(i => JSON.parse(i.serializedPayload) as IQueryItemDto)
        .filter(i => i.multiple === true)
        .filter(i => i.parentInternalId === item.internalId);

      multipleValueChildren.forEach(c => {
        multipleValuePayloads.push(c);

        this.queryService.adjustInternalIdCounter(c.internalId);
      });

      const category = this.findCategory(payload.categoryId);
      const categoryItem = this.findCategoryItem(category, payload.categoryItemId);
      const parent = nodes[payload.parentInternalId];
      if (categoryItem) {
        const arrToAdd = parent ? parent.children : this.treeData;

        if (arrToAdd.length === 0) {
          const opeatorCategory = ObjectHelper.deepCopy(this.opeatorCategory);
          const opeatorCategoryItem = ObjectHelper.deepCopy(this.opeatorCategory.items[0]);

          opeatorCategoryItem.payload.categoryId = opeatorCategory.id;
          opeatorCategoryItem.payload.categoryItemId = opeatorCategoryItem.id;
          opeatorCategoryItem.payload.internalId = initialOperatorId;

          this.addNode(opeatorCategory, opeatorCategoryItem, parent, true, true, isImport);
        }

        categoryItem.payload = payload;
        categoryItem.multipleValuePayloads = multipleValuePayloads;
        const disabled = categoryItem.dataType === this.queryFieldDataType.Operator;
        const hidden = categoryItem.dataType === this.queryFieldDataType.Operator;
        nodes[payload.internalId] = this.addNode(
          category,
          categoryItem,
          parent,
          disabled,
          hidden,
          isImport
        );

        if (arrToAdd.length === 3) {
          const existingOperator = arrToAdd[2] as QueryTreeNodeModel;
          (arrToAdd[0].categoryItem.payload as any).value = (
            existingOperator.categoryItem.payload as any
          ).value;
        }

        this.checkFirstOperator(arrToAdd);

        this.queryService.adjustInternalIdCounter(payload.internalId);
      }
    });
  }

  findCategory(categoryId: number): QueryBuilderCategoryDto | null {
    const filterResult = this.categories.filter(c => c.id === categoryId);

    return filterResult.length > 0 ? ObjectHelper.deepCopy(filterResult[0]) : null;
  }

  findCategoryItem(category: QueryBuilderCategoryDto, categoryItemId: number) {
    const filterResult = category.items.filter(c => c.id === categoryItemId);

    return filterResult.length > 0 ? ObjectHelper.deepCopy(filterResult[0]) : null;
  }

  setNodeValue(node: QueryTreeNodeModel, data: QueryItemDto[]) {
    const queryItem: QueryItemDto = {
      internalId: node.categoryItem.payload.internalId,
      dataType: node.categoryItem.dataType,
      serializedPayload: JSON.stringify(node.categoryItem.payload),
    };

    data.push(queryItem);

    if (node.children != null && node.children.length > 0) {
      node.children.forEach(t => {
        this.setNodeValue(t, data);
      });
    }
  }

  onCancelClick() {
    this.router.navigate(['categories']);
  }

  onAddNodeClick(category: QueryBuilderCategoryDto, categoryItem: IQueryBuilderCategoryItem) {
    if (
      this.checkTextSearchLimitExceeded(categoryItem) ||
      this.checkGenAILimitExceeded(categoryItem) ||
      (this.treeData.find(f => f.categoryItem.itemName === 'ConversationType') != null &&
        categoryItem.itemName === 'ConversationType') ||
      !this.checkDataTypeInUse(categoryItem)
    ) {
      return;
    }

    const disabled = this.treeData.length > 0;
    const opeatorCategory = ObjectHelper.deepCopy(this.opeatorCategory);
    const opeatorCategoryItem = ObjectHelper.deepCopy(this.opeatorCategory.items[0]);

    opeatorCategoryItem.payload.categoryId = opeatorCategory.id;
    opeatorCategoryItem.payload.categoryItemId = opeatorCategoryItem.id;
    opeatorCategoryItem.payload.internalId = this.queryService.getInternalId();

    if (disabled) {
      const previousOperatorNode = this.treeData[0];
      const value = (previousOperatorNode.categoryItem.payload as any).value;

      (opeatorCategoryItem.payload as any).value = value;
    }

    this.addNode(opeatorCategory, opeatorCategoryItem, null, true, true, false);

    const newCategory = ObjectHelper.deepCopy(category);
    const newCategoryItem = ObjectHelper.deepCopy(categoryItem);

    newCategoryItem.payload.categoryId = newCategory.id;
    newCategoryItem.payload.categoryItemId = newCategoryItem.id;
    newCategoryItem.payload.internalId = this.queryService.getInternalId();
    if (newCategory.cssClass == 'analysis') {
      let field = (newCategoryItem.payload as any).field;
      this.conversationService
        .getDefaultAcousticParameterValueForQuery(field)
        .subscribe(defaultAcousticParameterValues => {
          (newCategoryItem.payload as any).value = Math.round(defaultAcousticParameterValues.mean);
        });
    }
    let node = this.addNode(newCategory, newCategoryItem, null, false, false, false);

    this.checkFirstOperator(this.treeData);
    return node;
  }

  addNode(
    category: QueryBuilderCategoryDto,
    categoryItem: IQueryBuilderCategoryItem,
    parent: QueryTreeNodeModel | null,
    disabled = false,
    hidden = false,
    isImport: boolean,
    index: number | null = null
  ): QueryTreeNodeModel {
    const node: QueryTreeNodeModel = {
      children: [],
      parentNode: parent,
      category,
      categoryItem,
      disabled,
      hidden,
      validationStatus: false,
      isDirty: false,
      isImport,
    };

    if (node.parentNode && node.categoryItem.dataType !== this.queryFieldDataType.Operator) {
      node.hidden = !this.checkOperatorVisibility(node);
    }

    if (index === null) {
      if (parent == null) {
        this.treeData.push(node);
      } else {
        parent.children.push(node);
      }
    } else {
      if (parent == null) {
        this.treeData.splice(index, 0, node);
      } else {
        parent.children.splice(index, 0, node);
      }
    }

    this.refreshTreeData(this.treeData);
    if (parent) {
      this.treeControl.expand(parent);
    }

    this.queryQueryTreeScrollToBottom();
    this._hasUnsavedChanges = true;
    return node;
  }

  checkFirstOperator(source: QueryTreeNodeModel[]): void {
    if (source.length === 0) {
      return;
    }

    if (source.length === 2) {
      source[0].hidden = true;
      source[0].disabled = true;
    } else {
      // show and enable first operator node if two or more nodes added
      if (
        source[0].parentNode &&
        (source[0].parentNode.categoryItem.dataType === this.queryFieldDataType.OrderedGroup ||
          source[0].parentNode.categoryItem.dataType ===
            this.queryFieldDataType.OrderedGroupWithRange ||
          source[0].parentNode.categoryItem.dataType ===
            this.queryFieldDataType.AdvancedOrderedGroup)
      ) {
        // Hide first operator for Ordered Queries
        source[0].hidden = true;
        source[0].disabled = true;
      } else {
        source[0].hidden = false;
        source[0].disabled = false;
      }
    }
  }

  removeNode(id: number) {
    const found = this.findAndRemoveNode(this.treeData, id);

    if (found) {
      this._hasUnsavedChanges = true;
      this.refreshTreeData(this.treeData);
    }
  }

  duplicateNode(id: number): number {
    const searchResult = this.findNode(null, this.treeData, id);
    const isOrderedGroup =
      searchResult.node.categoryItem.dataType === this.queryFieldDataType.OrderedGroup ||
      searchResult.node.categoryItem.dataType === this.queryFieldDataType.OrderedGroupWithRange;

    if (searchResult.node !== null) {
      if (
        this.checkTextSearchLimitExceeded(searchResult.node.categoryItem) ||
        this.checkGenAILimitExceeded(searchResult.node.categoryItem) ||
        (this.treeData.find(f => f.categoryItem.itemName === 'ConversationType') != null &&
          searchResult.node.categoryItem.itemName === 'ConversationType') ||
        !this.checkDataTypeInUse(searchResult.node.categoryItem)
      ) {
        return;
      }

      const disabled = this.treeData.length > 0;
      const opeatorCategory = ObjectHelper.deepCopy(this.opeatorCategory);
      const opeatorCategoryItem = ObjectHelper.deepCopy(this.opeatorCategory.items[0]);

      opeatorCategoryItem.payload.categoryId = opeatorCategory.id;
      opeatorCategoryItem.payload.categoryItemId = opeatorCategoryItem.id;
      opeatorCategoryItem.payload.internalId = this.queryService.getInternalId();
      let previousOperatorNode: QueryTreeNodeModel = null;
      if (searchResult.parent !== null) {
        opeatorCategoryItem.payload.parentInternalId =
          searchResult.parent.categoryItem.payload.internalId;
        previousOperatorNode = searchResult.parent.children[0];
      } else {
        previousOperatorNode = this.treeData[0];
      }
      (opeatorCategoryItem.payload as any).id = 0;

      if (disabled) {
        const value = (previousOperatorNode.categoryItem.payload as any).value;

        (opeatorCategoryItem.payload as any).value = value;
      }

      this.addNode(
        opeatorCategory,
        opeatorCategoryItem,
        searchResult.parent,
        true,
        true,
        false,
        searchResult.index + 1
      );

      const newCategory = ObjectHelper.deepCopy(searchResult.node.category);
      const newCategoryItem = ObjectHelper.deepCopy(searchResult.node.categoryItem);

      newCategoryItem.payload.categoryId = newCategory.id;
      newCategoryItem.payload.categoryItemId = newCategoryItem.id;
      newCategoryItem.payload.internalId = this.queryService.getInternalId();
      (newCategoryItem.payload as any).id = 0;

      if (newCategory.cssClass == 'analysis') {
        let field = (newCategoryItem.payload as any).field;

        this.conversationService
          .getDefaultAcousticParameterValueForQuery(field)
          .subscribe(defaultAcousticParameterValues => {
            (newCategoryItem.payload as any).value = Math.round(
              defaultAcousticParameterValues.mean
            );
          });
      }

      const newNode = this.addNode(
        newCategory,
        newCategoryItem,
        searchResult.parent,
        false,
        false,
        isOrderedGroup,
        searchResult.index + 2
      );

      searchResult.node.children?.forEach(child => {
        this.duplicateChild(child, newNode);
      });

      this.checkFirstOperator(
        searchResult.parent !== null ? searchResult.parent.children : this.treeData
      );

      if (isOrderedGroup) {
        this.checkFirstOperator(newNode.children);
      }

      return newNode.categoryItem.payload.internalId;
    }
  }

  duplicateChild(node: QueryTreeNodeModel, parent: QueryTreeNodeModel): void {
    const disabled = this.treeData.length > 0;

    const newCategory = ObjectHelper.deepCopy(node.category);
    const newCategoryItem = ObjectHelper.deepCopy(node.categoryItem);

    newCategoryItem.payload.categoryId = newCategory.id;
    newCategoryItem.payload.categoryItemId = newCategoryItem.id;
    newCategoryItem.payload.parentInternalId = parent.categoryItem.payload.internalId;
    newCategoryItem.payload.internalId = this.queryService.getInternalId();
    (newCategoryItem.payload as any).id = 0;

    if (node.categoryItem.dataType === this.queryFieldDataType.Operator && disabled) {
      const previousOperatorNode = this.treeData[0];
      const value = (previousOperatorNode.categoryItem.payload as any).value;

      (newCategoryItem.payload as any).value = value;
    }

    if (newCategory.cssClass == 'analysis') {
      let field = (newCategoryItem.payload as any).field;

      this.conversationService
        .getDefaultAcousticParameterValueForQuery(field)
        .subscribe(defaultAcousticParameterValues => {
          (newCategoryItem.payload as any).value = Math.round(defaultAcousticParameterValues.mean);
        });
    }

    const hidden = parent.children.length > 0;

    this.addNode(newCategory, newCategoryItem, parent, disabled, hidden, false);
  }

  findNode(
    parent: QueryTreeNodeModel | null,
    source: QueryTreeNodeModel[],
    id: number
  ): { index: number | null; node: QueryTreeNodeModel | null; parent: QueryTreeNodeModel | null } {
    let result: { index: number | null; node: QueryTreeNodeModel; parent: QueryTreeNodeModel } = {
      index: null,
      node: null,
      parent: null,
    };

    for (let i = 0; i < source.length; i++) {
      const node = source[i];

      if (node.categoryItem.payload.internalId === id) {
        result.node = node;
        result.parent = parent;
        result.index = i;

        return result;
      }
    }

    for (let i = 0; i < source.length; i++) {
      const node = source[i];

      result = this.findNode(node, node.children, id);

      if (result.node !== null) {
        return result;
      }
    }

    return result;
  }

  checkDataTypeInUse(categoryItem: IQueryBuilderCategoryItem) {
    return true;
  }

  findAndRemoveNode(source: QueryTreeNodeModel[], id: number): boolean {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < source.length; i++) {
      const node = source[i];

      if (node.categoryItem.payload.internalId === id) {
        const undoAvailable = node.categoryItem.dataType === this.queryFieldDataType.QueryGroup;

        i = i === 1 ? 0 : i - 1;

        if (this._hasActiveSnackBar) {
          this._snackBarRefForNodeRemoval.dismiss();
        }

        if (undoAvailable) {
          this.removeNodeTemporarily(source, node, i);
          this.startTimerForRemoval(source, node, i);
        } else {
          source.splice(i, 2);
        }

        this.checkFirstOperator(source);

        return true;
      }
    }

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < source.length; i++) {
      const node = source[i];

      const found = this.findAndRemoveNode(node.children, id);

      if (found) {
        return found;
      }
    }

    return false;
  }

  removeNodeTemporarily(
    source: QueryTreeNodeModel[],
    node: QueryTreeNodeModel,
    operatorIndex: number
  ): void {
    const removed = source.splice(operatorIndex, 2);

    const removal = {
      source: source,
      nodes: removed,
      index: operatorIndex,
    };

    this._tempRemoval.push(removal);

    // reset operator visibilities
    for (let i = 0; i < removal.source.length; i += 2) {
      removal.source[i].hidden = i > 0;
      removal.source[i].disabled = i > 0;
    }

    this.refreshTreeData(this.treeData);
  }

  undoRemove(): void {
    const removal = this._tempRemoval.pop();

    removal.source.splice(removal.index, 0, ...removal.nodes);

    // reset operator visibilities
    for (let i = 0; i < removal.source.length; i += 2) {
      removal.source[i].hidden = i > 0;
      removal.source[i].disabled = i > 0;
    }

    this.refreshTreeData(this.treeData);
  }

  startTimerForRemoval(
    source: QueryTreeNodeModel[],
    node: QueryTreeNodeModel,
    nodeIndex: number
  ): void {
    const snackBarDurationInMilliseconds = 15000;

    this._snackBarRefForNodeRemoval = this.snackBar.openFromComponent(
      TimerForNodeRemovalComponent,
      {
        duration: snackBarDurationInMilliseconds,
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
        data: {
          durationInSeconds: snackBarDurationInMilliseconds / 1000,
        },
      }
    );

    this._snackBarRefForNodeRemoval
      .afterDismissed()
      .pipe(take(1))
      .subscribe((eventArgs: MatSnackBarDismiss) => {
        this._hasActiveSnackBar = false;

        if (eventArgs.dismissedByAction) {
          this.undoRemove();
        } else {
          this._tempRemoval.splice(0, 1);
        }
      });

    this._hasActiveSnackBar = true;
  }

  addNodeFromAnotherNode(
    parent: QueryTreeNodeModel,
    category: QueryBuilderCategoryDto,
    categoryItem: IQueryBuilderCategoryItem
  ) {
    if (
      this.checkTextSearchLimitExceeded(categoryItem) ||
      this.checkGenAILimitExceeded(categoryItem)
    ) {
      return;
    }

    const disabled = parent.children.length > 0;
    const opeatorCategory = ObjectHelper.deepCopy(this.opeatorCategory);
    const opeatorCategoryItem = ObjectHelper.deepCopy(this.opeatorCategory.items[0]);

    opeatorCategoryItem.payload.categoryId = opeatorCategory.id;
    opeatorCategoryItem.payload.categoryItemId = opeatorCategoryItem.id;
    opeatorCategoryItem.payload.internalId = this.queryService.getInternalId();
    opeatorCategoryItem.payload.parentInternalId = parent.categoryItem.payload.internalId;

    if (disabled) {
      const previousOperatorNode = parent.children[0];
      const value = (previousOperatorNode.categoryItem.payload as any).value;

      (opeatorCategoryItem.payload as any).value = value;
    }
    this.addNode(opeatorCategory, opeatorCategoryItem, parent, true, true, false);

    const newCategory = ObjectHelper.deepCopy(category);
    const newCategoryItem = ObjectHelper.deepCopy(categoryItem);

    newCategoryItem.payload.categoryId = newCategory.id;
    newCategoryItem.payload.categoryItemId = newCategoryItem.id;
    newCategoryItem.payload.internalId = this.queryService.getInternalId();
    newCategoryItem.payload.parentInternalId = parent.categoryItem.payload.internalId;

    var node = this.addNode(newCategory, newCategoryItem, parent, false, false, false);

    this.checkFirstOperator(parent.children);
    return node;
  }

  onResetTreeClick(eventArgs) {
    this.clearTree();
  }

  isCategoryItemVisible(categoryItem: IQueryBuilderCategoryItem): boolean {
    if (
      categoryItem.dataType === this.queryFieldDataType.QueryParser ||
      categoryItem.dataType === this.queryFieldDataType.OrderedSimpleTerm ||
      categoryItem.conversationTypes.includes(this.queryField.Conversation_Type)
    ) {
      return false;
    }
    if (categoryItem.conversationTypes.length > 0) {
      if (categoryItem.conversationTypes.indexOf(this.currentQuery.queryConversationTypeId) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  constructor(
    public queryFieldDataType: QueryFieldDataType,
    public queryField: ConversationFilterableFields,
    private confirmationService: ConfirmationService,
    private localizationService: LocalizationService,
    private permissionService: PermissionService,
    private queryService: QueryService,
    private categoryService: CategoryService,
    private formService: FormService,
    private stringService: StringService,
    private operators: Operators,
    private toastr: ToasterService,
    private router: Router,
    private featureService: FeatureService,
    private conversationService: ConversationService,
    private snackBar: MatSnackBar
  ) {
    this.queryService.resetInternalIdCounter();

    this.categoryService.getCategoryTextItemLimit().subscribe({
      next: response => {
        this._textSearchQueryItemLimit = response;
      },
    });
    let analyticsFeatureEnabled = this.featureService.isEnabled(FeatureConstants.Analytics);
    let sentimentAnalysisFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.SentimentAnalysis
    );
    this.callVisible = ['advanced', 'call'];
    this.chatVisible = ['advanced', 'chat'];
    if (analyticsFeatureEnabled) {
      this.callVisible.push('analysis', 'emotional', 'text-search', 'gen-ai');
      this.chatVisible.push('text-search', 'gen-ai');
    }

    if (sentimentAnalysisFeatureEnabled && analyticsFeatureEnabled) {
      this.callVisible.push('sentiment');
    }

    this.thisRef = this;
    this.canEditQuery = this.permissionService.getGrantedPolicy('Query.QueryManagement.Edit');

    this.formFilters.push({
      field: 'onlyAgentCanAcess',
      operator: this.operators.Equals,
      value: 'false',
    });
  }

  hasUnsavedChanges(): boolean {
    const hasDirtyNode = this.hasDirtyBuilderNode(this.treeData);
    return this._hasUnsavedChanges || hasDirtyNode;
  }

  resetUnsavedChangesState() {
    this.resetDirtyStatesOfBuilderNodes(this.treeData);
    this._hasUnsavedChanges = false;
  }

  hasDirtyBuilderNode(nodes: QueryTreeNodeModel[]): boolean {
    for (const node of nodes) {
      if (node.isDirty) {
        return true;
      } else {
        if (node.children.length > 0) {
          if (this.hasDirtyBuilderNode(node.children)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  resetDirtyStatesOfBuilderNodes(nodes: QueryTreeNodeModel[]) {
    for (const node of nodes) {
      if (node.isDirty) {
        node.isDirty = false;
      }

      if (node.children.length > 0) {
        this.resetDirtyStatesOfBuilderNodes(node.children);
      }
    }
  }

  checkOperatorVisibility(node: QueryTreeNodeModel) {
    let visibility = true;
    if (node.parentNode && node.categoryItem.dataType === this.queryFieldDataType.Operator) {
      switch (node.parentNode.categoryItem.dataType) {
        case this.queryFieldDataType.OrderedGroup: {
          visibility = false;
          break;
        }
        case this.queryFieldDataType.OrderedGroupWithRange: {
          visibility = false;
          break;
        }
        default: {
          visibility = true;
          break;
        }
      }
    }

    return visibility;
  }

  sortCategoryItems() {
    this.categories.forEach(category => {
      category.items.forEach(item => {
        item.itemName = this.localizationService.instant('Conversation::' + item.title);
      });
      category.items.sort((a, b) => a.itemName.localeCompare(b.itemName));
    });
  }

  queryQueryTreeScrollToBottom() {
    setTimeout(() => {
      const element = this.queryTreeBody.nativeElement;
      element.scrollTop = element.scrollHeight;
    }, 20);
  }

  resetQueryTreeScroll() {
    this.treeScrollTop = 0;
  }

  ngOnInit(): void {
    QueryTreeNodeOrderedGroupComponent.requiredTwoOrderedSimpleTermEditorsCreatedForComponent = [];
  }

  ngOnDestroy() {
    this._categoriesLoaded = false;
  }

  getQueryBuilderDataForSave(): QueryItemDto[] {
    if (this._hasActiveSnackBar) {
      this._snackBarRefForNodeRemoval.dismiss();
    }

    return this.queryService.getQueryBuilderData(this.treeData);
  }

  requestQueryParse(node: QueryTreeNodeModel) {
    this.queryParseRequested.emit({ node: node });
  }

  checkTextSearchLimitExceeded(categoryItem: IQueryBuilderCategoryItem): boolean {
    if (this.queryService.isTextSearchQueryItem(categoryItem.dataType)) {
      let builderData = this.queryService.getQueryBuilderData(this.treeData, true);
      if (builderData == null) return;
      let textSearchQueryItemLimit = +this.textSearchQueryItemLimit;

      if (
        builderData.filter(x => this.queryService.isTextSearchQueryItem(x.dataType)).length >=
        textSearchQueryItemLimit
      ) {
        this.toastr.warn(
          this.localizationService.instant(
            'Validation::Query:TextSearchLimitMessage',
            textSearchQueryItemLimit.toString()
          )
        );
        return true;
      }
    }

    return false;
  }

  checkGenAILimitExceeded(categoryItem: IQueryBuilderCategoryItem): boolean {
    if (this.queryService.isGenAIQueryItem(categoryItem.dataType)) {
      let builderData = this.queryService.getQueryBuilderData(this.treeData, true);
      if (builderData == null) return;
      let genAIQueryItemLimit = QueryConstants.CATEGORY_ITEM_ID_GENAI_LIMIT;

      if (
        builderData.filter(x => this.queryService.isGenAIQueryItem(x.dataType)).length >=
        genAIQueryItemLimit
      ) {
        this.toastr.warn(
          this.localizationService.instant(
            'Validation::Query:GenAILimitMessage',
            genAIQueryItemLimit.toString()
          )
        );
        return true;
      }
    }

    return false;
  }

  getSiblingNodePhrasesOfNode(node: QueryTreeNodeModel): string[] {
    var nodes = node.parentNode
      ? this.getParentQueryGroupNodeChildren(node.parentNode)
      : this.treeData;
    nodes = nodes.filter(n => n.categoryItem.dataType !== this.queryFieldDataType.Operator);
    var phrases = this.queryService
      .getQueryBuilderTerms(nodes.reverse())
      .filter(s => s && s.length > 0);
    return phrases.length > 0 ? phrases : this.getPhrasesFromQueryName();
  }

  private getPhrasesFromQueryName(): string[] {
    return this.queryFormNameControl && this.queryFormNameControl.value
      ? this.queryFormNameControl.value.trim().split(' ')
      : [];
  }

  private getParentQueryGroupNodeChildren(node: QueryTreeNodeModel): QueryTreeNodeModel[] {
    var queryGroupNode = node;

    while (
      queryGroupNode.categoryItem.dataType !== this.queryFieldDataType.QueryGroup &&
      queryGroupNode.parentNode
    ) {
      queryGroupNode = queryGroupNode.parentNode;
    }

    return queryGroupNode.categoryItem.dataType === this.queryFieldDataType.QueryGroup
      ? queryGroupNode.children
      : this.treeData;
  }
}
