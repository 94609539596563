import { TranscriptRow } from 'src/core/models/conversation/transcript/transcript-row.model';
import { LocalizationService, PermissionService } from '@abp/ng.core';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatTranscriptDto } from 'src/core/models/chat/chat-transcript.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { ChatService } from 'src/core/services/conversation/chat.service';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { TranscriptWord } from 'src/core/models/conversation/transcript/transcript-word.model';
import { take } from 'rxjs';

export interface DataTableTranscripts {
  chat: string;
  startTime: string;
  channel: number;
}

@Component({
  selector: 'ca-chat-transcript-panel',
  templateUrl: './chat-transcript-panel.component.html',
  styleUrls: ['./chat-transcript-panel.component.scss'],
})
export class ChatTranscriptPanelComponent implements OnInit, AfterViewInit {
  transcriptDisplayedColumns: string[] = ['comment', 'startTime', 'channel', 'chat'];
  commentPopover: NgbPopover;
  editMode: boolean = false;
  processing: boolean;
  indexedWordContainers: any;
  canExportTranscript: boolean = false;
  canEditTranscript: boolean = false;
  private _transcripts: ChatTranscriptDto[];
  dataTableTranscripts: TranscriptRow[] = [];
  mouseOverIndex = -1;
  private _modifiedTranscripts: ChatTranscriptDto[];
  dirty = false;

  @Input()
  set startTime(value: string) {
    if (value == null) {
      return;
    }
    setTimeout(() => {
      const transcript = document.getElementById('text-' + value) as HTMLTextAreaElement;
      if (transcript == null) {
        return;
      }
      transcript.scrollIntoView();
      transcript.style.backgroundColor = 'yellow';
      setTimeout(() => {
        transcript.style.backgroundColor = null;
      }, 3000);
    }, 200);
  }

  @Input()
  set transcripts(transcripts: ChatTranscriptDto[]) {
    this._transcripts = transcripts;
    this._modifiedTranscripts = transcripts;
    if (this._transcripts.length > 0) {
      this.fillDataTableTranscript(this._transcripts);
    }
  }

  @Input()
  showEditor: boolean = false;

  @Input()
  conversationId: number;

  get transcripts(): ChatTranscriptDto[] {
    return this._transcripts;
  }

  get isTranscriptEditorVisible() {
    if (this.showEditor && this.canEditTranscript && this.transcripts.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  get isTranscriptExportVisible() {
    if (this.canExportTranscript && this.transcripts.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  onEdit() {
    this.editMode = true;
  }

  @Output()
  excelExportRequested: EventEmitter<{
    data: any;
  }> = new EventEmitter();

  @Output()
  addCommentRequested: EventEmitter<{
    comment: any;
    startTime: any;
    endTime: any;
  }> = new EventEmitter();

  @Output()
  transcriptSaved: EventEmitter<{ transcripts: ChatTranscriptDto[] }> = new EventEmitter();

  @Output()
  transcriptSaveFailed = new EventEmitter();

  constructor(
    private localizationService: LocalizationService,
    private caDatePipe: CADatePipe,
    private permissionService: PermissionService,
    private chatService: ChatService,
    private toastr: ToasterService,
    private confirmationService: ConfirmationService
  ) {
    this.canExportTranscript = this.permissionService.getGrantedPolicy(
      'Conversation.Transcript.Export'
    );
    this.canEditTranscript = this.permissionService.getGrantedPolicy(
      'Conversation.Transcript._Edit'
    );
  }

  fillDataTableTranscript(data: any[]) {
    this.dataTableTranscripts = [];
    let row: TranscriptRow;
    const currentLang = this.localizationService.currentLang;

    for (let i = 0; i < data.length; i++) {
      const curr = data[i];
      const prev = i === 0 ? null : data[i - 1];

      if (i === 0 || curr.channel !== prev.channel || curr.timeStamp !== prev.timeStamp) {
        row = {
          time: this.caDatePipe.transform(new Date(curr.timeStamp), DateDisplayType.Time),
          channel: curr.channel,
          words: [],
        };

        this.dataTableTranscripts.push(row);
      }

      row.words.push({
        startTime: curr.timeStamp,
        endTime: 1,
        word: curr.line,
      });
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}

  exportTranscript() {
    var headers = [
      this.localizationService.instant('::Time'),
      this.localizationService.instant('::Channel'),
      this.localizationService.instant('::Chat'),
    ];

    let excelData = this.populateExcelData();

    let reportData = {
      data: excelData,
      headers: headers,
    };
    this.excelExportRequested.emit({ data: reportData });
  }

  saveTranscript() {
    this.processing = true;
    this.chatService
      .updateTranscript(this.conversationId, this._modifiedTranscripts)
      .pipe(take(1))
      .subscribe({
        next: result => {
          this.processing = false;
          this.toastr.success(this.localizationService.instant('::SuccessfullySaved'));
          this.editMode = false;
          this.transcripts = this._modifiedTranscripts;
          this.transcriptSaved.emit({ transcripts: this.transcripts });
        },
        error: () => {
          this.processing = false;
          this.transcriptSaveFailed.emit();
        },
      });
  }

  onTranscriptRowChanged(
    eventArgs: { oldWords: TranscriptWord[]; newWords: TranscriptWord[] },
    channel: number
  ) {
    this.dirty = true;

    eventArgs.oldWords.forEach(element => {
      this._modifiedTranscripts = this._modifiedTranscripts.filter(
        x =>
          !(
            x.channel === channel &&
            x.line === element.word &&
            new Date(x.timeStamp).getTime() === new Date(element.startTime).getTime()
          )
      );
    });

    console.log(this._modifiedTranscripts);

    eventArgs.newWords.forEach(element => {
      this._modifiedTranscripts.push({
        channel: channel,
        line: element.word,
        timeStamp: new Date(element.startTime),
      });
    });

    this._modifiedTranscripts.sort(
      (a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime()
    );
  }

  cancelEdit() {
    if (this.dirty) {
      this.confirmationService
        .warn('::SaveWithoutChangeConfirmationMessage', '', {
          messageLocalizationParams: [
            this.localizationService.instant('Conversation::EditTranscript'),
          ],
          yesText: '::ContinueWithoutSave',
        })
        .pipe(take(1))
        .subscribe((status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.editMode = false;
            this._modifiedTranscripts = this.transcripts;
            this.dirty = false;
          }
        });
    } else {
      this.editMode = false;
    }
  }

  onMouseOver(index) {
    if (this.commentPopover && this.commentPopover.isOpen()) {
      return;
    }
    this.mouseOverIndex = index;
  }

  toggleQueryPreviewPopover(popover: NgbPopover, element: ChatTranscriptDto, index: any) {
    this.commentPopover = popover;

    popover.open({ element: element, index: index });
  }

  onSaveComment(element, index) {
    const comment = (document.getElementById('comment-text-' + index) as HTMLTextAreaElement).value;

    this.addCommentRequested.emit({ comment: comment, startTime: element.time, endTime: null });

    this.commentPopover.close();
  }

  onPopoverShown(index: number): void {
    setTimeout(() => {
      const textarea = document.getElementById('comment-text-' + index) as HTMLTextAreaElement;

      if (textarea) {
        textarea.focus();
      }
    }, 100);
  }

  private populateExcelData(): any[] {
    let excelData = [];
    this.dataTableTranscripts.forEach(transcript => {
      let sentence = '';
      transcript.words.forEach(wordObj => {
        sentence += wordObj.word + ' ';
      });
      let channel =
        transcript.channel === 1
          ? this.localizationService.instant('Conversation::Agent')
          : this.localizationService.instant('Conversation::Customer');
      let row = [transcript.time, channel, sentence];
      excelData.push(row);
    });
    return excelData;
  }
}
