<div
  ngbDropdown
  #selectorDropdown="ngbDropdown"
  style="width: 100%"
  class="d-inline-block"
  [container]="'body'">
  <button
    [ngClass]="'dropdown-selector-button btn btn-sm ' + buttonCls"
    [disabled]="disabled"
    style="width: 100%"
    type="button"
    ngbDropdownToggle
    #selectorButton>
    <ng-container class="dropdown-button-label">
      <span class="dropdown-button-label" *ngIf="selections?.length == 0">{{
        buttonString | abpLocalization
      }}</span>
      <span class="dropdown-button-label" *ngIf="selections?.length > 1">{{
        selectedMessage
      }}</span>
      <span class="dropdown-button-label" *ngIf="selections?.length == 1"
        ><span *ngIf="!except">
          <span *ngFor="let item of selections">
            <ng-container *ngTemplateOutlet="selectionTemplate; context: { item: item }">
            </ng-container> </span></span
        ><span *ngIf="except">
          <span *ngFor="let item of selections">
            <ng-container *ngTemplateOutlet="selectionExceptTemplate; context: { item: item }">
            </ng-container> </span></span
      ></span>
    </ng-container>
  </button>
  <div ngbDropdownMenu class="dropdown-selector-menu-single dropdown-menu" style="width: 100%">
    <div class="dropdown-selector-menu-content-wrapper">
      <div class="card dropdown-selector-card">
        <div class="card-body dropdown-selector-card-body">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
