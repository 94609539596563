<div class="content-container">
  <div class="summary-container">
    <ca-meeting-summary [conversation]="conversation"></ca-meeting-summary>
  </div>
  <div class="center-container">
    <div class="tab-panel-container">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
        <li [ngbNavItem]="1" *ngIf="analyticsFeatureEnabled">
          <a ngbNavLink
            ><i class="fas fa-file-alt"></i
            ><span style="margin-left: 4px">{{
              'Conversation::Transcript' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <p>
              <ca-meeting-transcript-panel
                [transcripts]="transcripts"
                [player]="player"
                [analysisStatusId]="
                  conversation?.meeting?.analysisStatusId
                "></ca-meeting-transcript-panel>
            </p>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <div class="player-controller-container">
      <ca-player-controller
        [player]="player"
        [collapsible]="false"
        [showSentimentButtons]="conversation?.call?.isDiarize && analyticsFeatureEnabled && sentimentFetureEnabled"
        #playerController></ca-player-controller>
    </div>
  </div>
</div>
<div class="player-container">
  <ca-player
    style="width: 100%"
    [fillParent]="true"
    [expandedPlayerHeight]="70"
    [showSentiments]="analyticsFeatureEnabled"
    [minimizedPlayerHeight]="18"
    [expanded]="true"
    [splitChannels]="false"
    [hideChannelIcons]="true"
    [loadAtOnce]="false"
    #player
    (startPlaying)="setListeningStatus($event)"></ca-player>
</div>
