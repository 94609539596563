import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { UserLoginDateChange } from 'src/core/actions/administration/user-login.action';
import { UserLoginStateModel } from 'src/core/models/administration/user/user-login.state-model';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { GenericState } from 'src/core/states/generic/generic.state';
const name = 'UserLoginState';
@State<UserLoginStateModel>({
  name: name,
  defaults: {
    loginDate: new Date(),
  } as UserLoginStateModel,
})
@Injectable()
export class UserLoginState extends GenericState<UserLoginState> {
  @Selector()
  static getUserLoginDate(state: UserLoginStateModel): Date {
    return state.loginDate;
  }

  @Action(UserLoginDateChange)
  changeUserLoginDate(ctx: StateContext<UserLoginStateModel>, action: UserLoginDateChange) {
    const currentState = ctx.getState();
    currentState.loginDate = action.userLoginDate;
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  constructor(userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;
    this.userSettingsService.register(this.name, this);
  }
}
