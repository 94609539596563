import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.Channel',
})
export class ConversationChannel {
    @GenericLookup({
        code: 'Conversation.Channel.Voice',
    })
    static voice: number;

    @GenericLookup({
        code: 'Conversation.Channel.Text',
    })
    static text: number;

    @GenericLookup({
        code: 'Conversation.Channel.Video',
    })
    static video: number;
}
