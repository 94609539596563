import { ConfigStateService } from '@abp/ng.core';
import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { DropdownTreeNodeModel } from '../../models/dropdown-tree-node.model';
import { DropdownTreeComponent } from '../dropdown-tree/dropdown-tree.component';

@Component({
  selector: 'ca-dropdown-tree-node',
  templateUrl: './dropdown-tree-node.component.html',
  styleUrls: ['./dropdown-tree-node.component.scss'],
})
export class DropdownTreeNodeComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  private _disabledItemMessage: string;
  @Input()
  level: number;

  @Input()
  data: DropdownTreeNodeModel;

  @Input()
  nodeTemplate: TemplateRef<any>;

  @Input()
  owner: DropdownTreeComponent;

  @Input()
  idProperty: string;

  @Input()
  parentIdProperty: string;

  @Input()
  disabled: boolean = false;

  @Input()
  hasSelectedChild: boolean = false;

  @Input()
  set disabledItemMessage(message: string) {
    this._disabledItemMessage = message;
  }

  get disabledItemMessage() {
    return this._disabledItemMessage;
  }

  marginBase = 16;
  margin = 0;
  marginPositionWithValue = null;

  get children(): DropdownTreeNodeModel[] {
    return this.owner.getChildren(this.data.payload[this.idProperty]);
  }

  get id() {
    return this.data.payload[this.idProperty];
  }

  toggleNode() {
    if (!this.data.leaf) {
      this.data.expanded = !this.data.expanded;
    }
  }

  onSelectionChange(eventArgs: MouseEvent | null) {
    this.owner.onNodeCheckChanged(this.id, this.data.selected);
  }

  onDblClick(eventArgs: MouseEvent) {
    this.toggleNode();
  }

  onExpandIconClick(eventArgs: MouseEvent) {
    this.toggleNode();
  }

  constructor(private config: ConfigStateService) {}

  ngOnInit() {
    this.owner.addNode(this);
    const localize = this.config.getOne('localization');
    const isRTL = localize?.currentCulture.cultureName === 'ar';
    const marginPosition = isRTL ? 'margin-right' : 'margin-left';
    this.marginPositionWithValue = marginPosition + ':' + this.marginBase * this.level + 'px';
  }

  hasUnselectedChild(node): boolean {
    let data = this.owner.getChildren(node.payload[this.idProperty]);
    let childs = data.filter(
      x => x.payload[this.parentIdProperty] == node.payload[this.idProperty]
    );
    for (let i = 0; i < childs.length; i++) {
      if (childs[i].selected || this.hasUnselectedChild(childs[i])) {
        return true;
      }
    }
    return false;
  }
}
