import { FormControl, ValidatorFn } from '@angular/forms';
import { AgentListFilterModeOption } from 'src/core/models/generic-lookup-type/identity/agent-list-filter-mode-option.glt';
import { EvaluatorFilterModeOption } from 'src/core/models/generic-lookup-type/identity/evaluator-filter-mode-option.glt';
import { UserFilterModeOption } from 'src/core/models/generic-lookup-type/identity/user-filter-mode-option.glt';

export class UserAndDepartmentValidator {
  static departmentControl: FormControl;

  static Validator: ValidatorFn = (control: FormControl) => {
    const selectedUsers = control?.value?.selectedUsers as [];
    const filterModeId = control?.value?.filterModeId as number;

    if (
      filterModeId == AgentListFilterModeOption.AllAgentsExceptSelectedAgent ||
      filterModeId == EvaluatorFilterModeOption.AllEvaluatorsExceptSelectedEvaluator ||
      filterModeId == UserFilterModeOption.AllUsersExceptSelectedUser
    ) {
      if (UserAndDepartmentValidator.departmentControl?.enabled) {
        UserAndDepartmentValidator.departmentControl?.disable();
      }
      return null;
    }

    if (!selectedUsers) {
      if (UserAndDepartmentValidator.departmentControl?.enabled) {
        UserAndDepartmentValidator.departmentControl?.disable();
      }
      return null;
    }

    if (selectedUsers.length <= 0) {
      if (UserAndDepartmentValidator.departmentControl?.disabled) {
        UserAndDepartmentValidator.departmentControl?.enable();
      }
      return { required: true };
    }

    if (UserAndDepartmentValidator.departmentControl?.enabled) {
      UserAndDepartmentValidator.departmentControl?.disable();
    }
    return null;
  };
}
