import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { CategoryModeChange, FilterChange, ViewModeChange } from 'src/core/actions/analysis/statistic-comparison.action';
import { StatisticComparisonStateModel } from 'src/core/models/analysis/statistic-comparison/statistic-comparison.state-model';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { GenericState } from '../generic/generic.state';
const name = 'StatisticComparisonState';
@State<StatisticComparisonStateModel>({
  name: name,
  defaults: {
    filters: {},
    queries: {},
    cloudMode: true,
    aiGeneratedMode: false,
  } as StatisticComparisonStateModel,
})
@Injectable()
export class StatisticComparisonState extends GenericState<StatisticComparisonState> {
  @Action(FilterChange)
  changeFilter(ctx: StateContext<StatisticComparisonStateModel>, action: FilterChange) {
    const currentState = ctx.getState();
    if (!currentState.filters) {
      currentState.filters = {};
    }
    currentState.filters = action.filters;
    this.userSettingsService.patchState(ctx, currentState, name, action.debounceTime);
  }

  @Action(ViewModeChange)
  changeViewMode(ctx: StateContext<StatisticComparisonStateModel>, action: ViewModeChange) {
    const currentState = ctx.getState();
    if (!currentState.cloudMode) {
      currentState.cloudMode = true;
    }
    currentState.cloudMode = action.cloudMode;
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(CategoryModeChange)
  changeCategoryMode(ctx: StateContext<StatisticComparisonStateModel>, action: CategoryModeChange) {
    const currentState = ctx.getState();
    if (!currentState.aiGeneratedMode) {
      currentState.aiGeneratedMode = true;
    }
    currentState.aiGeneratedMode = action.aiGeneratedMode;
    this.userSettingsService.patchState(ctx, currentState, name);
  }
  
  constructor(userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;

    this.userSettingsService.register(this.name, this);
  }
}
