import { LocalizationService } from '@abp/ng.core';
import { Pipe, PipeTransform } from '@angular/core';

enum TimeInterval {
  Minute = 60,
  Hour = 3600,
  Day = 86400,
  Month = 2592000,
  Year = 31536000,
}
@Pipe({ name: 'timeSince' })
export class TimeSincePipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {}

  transform(value: Date | string): any {
    if (value !== null) {
      const date = new Date(value);

      var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

      var interval = seconds / TimeInterval.Year;
      if (interval > 1) {
        return this.getTimeSinceLabel(interval, this.localizationService.instant('::Years'));
      }

      interval = seconds / TimeInterval.Month;
      if (interval > 1) {
        return this.getTimeSinceLabel(interval, this.localizationService.instant('::Months'));
      }

      interval = seconds / TimeInterval.Day;
      if (interval > 1) {
        return this.getTimeSinceLabel(interval, this.localizationService.instant('::Days'));
      }

      interval = seconds / TimeInterval.Hour;
      if (interval > 1) {
        return this.getTimeSinceLabel(interval, this.localizationService.instant('::Hours'));
      }

      interval = seconds / TimeInterval.Minute;
      if (interval > 1) {
        return this.getTimeSinceLabel(interval, this.localizationService.instant('::Minutes'));
      }

      return this.localizationService.instant('::JustNow');
    } else {
      return '';
    }
  }

  private getTimeSinceLabel(since: number, interval: string): string {
    return this.localizationService.instant('::TimeSince', Math.floor(since).toString(), interval);
  }
}
