import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'AITopic.InferenceSettingOption',
})
export class AITopicInferenceSettingOption {
    @GenericLookup({
        code: 'AITopic.InferenceSettingOption.LastOneMonth',
    })
    static lastOneMonth: number;

    @GenericLookup({
        code: 'AITopic.InferenceSettingOption.LastTwoMonths',
    })
    static lastTwoMonths: number;

    @GenericLookup({
        code: 'AITopic.InferenceSettingOption.LastThreeMonths',
    })
    static lastThreeMonths: number;
}