<table mat-table [dataSource]="dataSource" class="fixed-table">
  <ng-container *ngFor="let gridColumn of gridColumns">
    <ng-container
      *ngIf="gridColumn.columnName !== 'menu' && gridColumn.columnName !== 'name'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td
        mat-cell
        *matCellDef="let userGroup"
        class="truncate"
        [innerHtml]="userGroup | objectparser : gridColumn"
        container="body"
        [ngbTooltip]="userGroup | objectparser : gridColumn"
        showTooltipIfTruncated></td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'menu'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let userGroup">
        <div class="icon-container" *ngIf="userGroup.isActive">
          <button class="btn btn-sm ca-btn ca-btn-neutral-command" (click)="deactivate(userGroup)">
            <i class="far fa-pause"></i>
          </button>
        </div>
        <div class="icon-container" *ngIf="!userGroup.isActive">
          <button
            class="btn btn-sm ca-btn ca-btn-neutral-command"
            (click)="activate(userGroup)"
            *ngIf="!userGroup.isActive">
            <i class="far fa-play"></i>
          </button>
        </div>
      </td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'name'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td
        mat-cell
        *matCellDef="let userGroup"
        container="body"
        [ngbTooltip]="userGroup | objectparser : gridColumn"
        showTooltipIfTruncated>
        <ca-clickable-link
          [innerHtml]="userGroup | objectparser : gridColumn"
          (linkClicked)="openDetails(userGroup.id)"
          [showTooltipIfTruncated]="true"></ca-clickable-link>
      </td>
    </ng-container>
  </ng-container>
  <!-- Info column -->
  <ng-container matColumnDef="info">
    <td
      mat-footer-cell
      *matFooterCellDef
      style="text-align: left"
      [attr.colspan]="displayedColumns.length">
      <span>
        <mat-paginator
          style="display: flex; justify-content: left"
          (page)="pageChanged($event)"
          [pageIndex]="currentPage"
          [length]="totalCount"
          [pageSize]="pageSize"
          class="paginator"
          hidePageSize
          showFirstLastButtons></mat-paginator>
      </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    class="user-group-row-{{ myRowData.id }}"
    (dblclick)="myRowData.isStatic ? '' : onRowDoubleClick($event, myRowData.id)"
    *matRowDef="let myRowData; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="['info']; sticky: true"></tr>
</table>
