import { EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { ChatTranscriptDto } from 'src/core/models/chat/chat-transcript.dto';
import { ChatDto } from 'src/core/models/chat/chat.dto';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ChatTranscriptService } from 'src/core/services/conversation/chat-transcript.service';
import { CrudService } from 'src/core/services/crud/crud.service';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { LocalizationService } from '@abp/ng.core';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { ConversationCommentComponentPanel } from '../../conversation-comment-panel/conversation-comment-panel.component';
import {
  ExcelWorkBook,
  ClientExcelService,
  ExcelRowModel,
} from 'src/ca-shared/client-excel/client-excel.module';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';
import { PlayerComponent } from 'src/ca-shared/player/player.module';
import { ConversationMediaType } from 'src/core/models/generic-lookup-type/conversation/conversation-media-type.glt';
import { PlayerStatus } from 'src/ca-shared/player/models/player-status.enum';
import { CategoryMarkerDto } from 'src/ca-shared/player/models/category-marker.dto';

@Component({
  selector: 'ca-chat-detail-tab-panel',
  templateUrl: './chat-detail-tab-panel.component.html',
  styleUrls: ['./chat-detail-tab-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatDetailTabPanelComponent implements OnInit {
  private _conversation: ConversationDto;
  private _categories: ConversationCategoryDto[];
  private _categoryMarks: CategoryMarkerDto[];
  private _loadPlayerFn;

  qualityManagementFeatureEnabled: boolean;
  transcripts: ChatTranscriptDto[] = [];
  startTime: string;
  commentCount: number = 0;
  analyticsFeatureEnabled: boolean;
  sentimentFetureEnabled: boolean;

  set categoryMarks(categoryMarks: CategoryMarkerDto[]) {
    this._categoryMarks = categoryMarks;
  }

  get categoryMarks(): CategoryMarkerDto[] {
    return this._categoryMarks ?? [];
  }
  activeTab: number = 1;

  @Input()
  set conversation(value) {
    this._conversation = value;
    if (this._conversation) {
      this.getTranscript();
      this.loadPlayer();
    }
  }

  @Input()
  set categories(categories: ConversationCategoryDto[]) {
    this._categories = categories;
  }

  get conversation(): ConversationDto {
    return this._conversation;
  }

  get categories(): ConversationCategoryDto[] {
    return this._categories;
  }

  @ViewChild('commentPanel', {
    read: ConversationCommentComponentPanel,
    static: false,
  })
  commentPanel: ConversationCommentComponentPanel;

  @ViewChild('player', {
    read: PlayerComponent,
    static: true,
  })
  player: PlayerComponent;

  @Output()
  addCommentRequested: EventEmitter<{
    comment: any;
    startTime: any;
    endTime: any;
  }> = new EventEmitter();

  constructor(
    private crudService: CrudService,
    private transcriptService: ChatTranscriptService,
    private featureService: FeatureService,
    private activatedRoute: ActivatedRoute,
    private localizationService: LocalizationService,
    private caDatePipe: CADatePipe,
    private excelService: ClientExcelService,
    private caTimePipe: CATimePipe
  ) {
    this.qualityManagementFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.QualityManagement
    );

    this.analyticsFeatureEnabled = this.featureService.isEnabled(FeatureConstants.Analytics);
    this.sentimentFetureEnabled = this.featureService.isEnabled(FeatureConstants.SentimentAnalysis);

    this._loadPlayerFn = this.loadPlayer.bind(this);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const activeTab = this.activatedRoute.snapshot.queryParams.activeTab;
      if (activeTab) {
        this.activeTab = Number(activeTab);
      }
    });
  }

  getTranscript() {
    this.crudService
      .getById<ChatTranscriptDto[]>(ChatDto, this.conversation.chat.id, 'transcript')
      .subscribe(response => {
        this.transcripts = response;
        const searchedQuery = this.categories.filter(
          category => category.isQuickSearchMarker == true || category.isFilterSearchMarker == true || category.isAIGeneratedMarker == true
        );
        let categories = [];
        if (searchedQuery && searchedQuery.length > 0) {
          let mergedQueries = this.categories.filter(category => category.rootQueryId != null);
          categories = [...searchedQuery, ...mergedQueries];
        }
        this.categoryMarks = this.transcriptService.getTranscriptAnalysis(
          categories,
          this.transcripts
        );
      });
  }

  onTranscriptSaved(eventArgs: { transcripts: ChatTranscriptDto[] }) {
    this.transcripts = eventArgs.transcripts;
    this.categoryMarks = this.transcriptService.getTranscriptAnalysis(
      this.categories,
      this.transcripts
    );
  }

  onTranscriptSaveFailed() {
    this.categoryMarks = this.transcriptService.getTranscriptAnalysis(
      this.categories,
      this.transcripts
    );
  }

  onAddCommentRequest(eventArgs) {
    this.addCommentRequested.emit(eventArgs);
  }

  onExcelExportRequest(eventArgs) {
    var excelData = eventArgs.data;

    let titleStyle = {
      bold: true,
      size: 12,
    };

    const header = excelData.headers;
    const data = excelData.data;

    let headerRow: ExcelRowModel = {
      cells: [],
    };
    header.forEach(headerValue => {
      headerRow.cells.push({
        value: headerValue,
        properties: {
          font: titleStyle,
        },
      });
    });

    let dataRows = [];
    data.forEach(d => {
      let row: ExcelRowModel = {
        cells: [],
      };
      d.forEach(cellValue => {
        row.cells.push({ value: cellValue });
      });
      dataRows.push(row);
    });

    let workbook: ExcelWorkBook = {
      fileName:
        this.conversation.userName +
        ' - ' +
        this.conversation.id +
        ' (' +
        this.localizationService.instant('Conversation::Transcript') +
        ')' +
        '.xlsx',

      worksheets: [
        {
          properties: {
            defaultColWidth: 30,
          },
          title: this.localizationService.instant('Conversation::Transcript'),
          rows: [
            {
              cells: [
                {
                  value: this.localizationService.instant('::Id'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.conversation.id.toString(),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Agent'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.conversation.userName,
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Department'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.conversation.departmentName,
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Type'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.localizationService.instant(
                    'GenericLookup::' + this.conversation.typeName
                  ),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Time'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.caDatePipe.transform(
                    this.conversation.startTime,
                    DateDisplayType.DateTime
                  ),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Channel'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.localizationService.instant(
                    'GenericLookup::' + this.conversation.channelName
                  ),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Duration'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.caTimePipe.transform(this.conversation.duration),
                },
              ],
            },
            {
              cells: [
                {
                  value: this.localizationService.instant('::Source'),
                  properties: {
                    font: titleStyle,
                  },
                },
                {
                  value: this.conversation?.conversationSourceName,
                },
              ],
            },
            {
              cells: [],
              mergeOptions: 'A:J',
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'A6A6A6' },
                bgColor: { argb: '' },
              },
            },
            ...[headerRow],
            ...dataRows,
          ],
        },
      ],
    };

    this.excelService.generateAndDownload(workbook);
  }

  onCategorySelectionChanged(eventArgs: { selectedCategories: number[] }) {
    this.categoryMarks = [];

    this.categoryMarks = this.transcriptService.getTranscriptAnalysis(
      this.categories,
      this.transcripts,
      x => eventArgs.selectedCategories.indexOf(x.id) !== -1
    );
  }

  onGoToComment(eventArgs) {
    this.startTime = null;

    setTimeout(() => {
      this.activeTab = eventArgs.activeTab;
      this.startTime = eventArgs.startTime;
    }, 200);
  }

  onCommentCountChanged(eventArgs) {
    this.commentCount = eventArgs.comments.length;
  }

  private loadPlayer() {
    if (this.player.status === PlayerStatus.NotInitialized) {
      setTimeout(this._loadPlayerFn, 100);

      return;
    }

    this.setPlayer();
  }

  private setPlayer(forceToLoadAudio = false) {
    if (this.conversation) {
      this.player.contentSource = `api/app/conversation/${this._conversation.id}/media/${ConversationMediaType.chatRecording}`;
      this.player.peakSource = 'api/app/conversation/' + this.conversation.id + '/waveform';
      this.player.downloadUrl = 'api/app/conversation/' + this.conversation.id + '/download';
      this.player.fileName = this.conversation.id.toString();
      this.player.conversationId = this.conversation.id;

      this.player.isVideo = false;
      this.player.load();
    }
  }
}
