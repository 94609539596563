<div
  class="form-group"
  [ngClass]="customClass"
  [formGroup]="editorForm"
  [ngbTooltip]="actualConfig.placeholder | abpLocalization"
  [disableTooltip]="(actualConfig.placeholder | abpLocalization).length < 50"
  container="body"
>
  <label
    *ngIf="actualConfig.label && actualConfig.label.length > 0"
    [for]="'number-editor-' + this.actualConfig.key"
    [ngClass]="labelClass"
    >{{ actualConfig.label | abpLocalization }}</label
  >
  <div class="input-wrapper" *ngIf="hasIcon && showValidationMessage">
    <i [ngClass]="actualConfig.iconCls"></i>
    <input
      type="number"
      [placeholder]="actualConfig.placeholder | abpLocalization"
      class="form-control form-control-sm editor"
      formControlName="editor"
      [min]="actualConfig.minValue"
      [max]="actualConfig.maxValue"
      (change)="onChangeValue()"
    />
  </div>
  <input
    *ngIf="!hasIcon && showValidationMessage"
    type="number"
    [placeholder]="actualConfig.placeholder | abpLocalization"
    class="form-control form-control-sm"
    formControlName="editor"
    [min]="actualConfig.minValue"
    [max]="actualConfig.maxValue"
    (change)="onChangeValue()"
  />
  <div class="input-wrapper" *ngIf="hasIcon && !showValidationMessage">
    <i [ngClass]="actualConfig.iconCls"></i>
    <input
      type="number"
      [placeholder]="actualConfig.placeholder | abpLocalization"
      class="form-control form-control-sm editor"
      formControlName="editor"
      (change)="onChangeValue()"
    />
  </div>
  <input
    *ngIf="!hasIcon && !showValidationMessage"
    type="number"
    [placeholder]="actualConfig.placeholder | abpLocalization"
    class="form-control form-control-sm"
    formControlName="editor"
    (change)="onChangeValue()"
  />
</div>
