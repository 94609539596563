import { LocalizationService } from '@abp/ng.core';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { RoleSelectionConfigurationModel } from 'src/ca-shared/filter-panel/children/role-selection/models/role-selection-configuration.model';
import { RoleSelectionType } from 'src/ca-shared/filter-panel/children/role-selection/models/role-selection-type.enum';
import {
  DepartmentSelectionConfigurationModel,
  DepartmentSelectionType,
  FilterPanelComponent,
  FilterPanelConfigurationModel,
  TextEditorConfigurationModel,
} from 'src/ca-shared/filter-panel/filter-panel.module';
import { Editor } from 'src/ca-shared/filter-panel/models/editor.enum';
import { DepartmentFilterModeOption } from 'src/core/models/generic-lookup-type/department/department-filter-mode-option.glt';
import { LastLoginDateSelectionConfigurationModel } from 'src/ca-shared/filter-panel/children/last-login-date-selection/models/last-login-date-selection-panel.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ca-user-list-filter-panel',
  templateUrl: './user-list-filter-panel.component.html',
  styleUrls: ['./user-list-filter-panel.component.scss'],
})
export class UserListFilterPanelComponent implements OnInit {
  filterPanelConfig: FilterPanelConfigurationModel;
  

  @ViewChild(FilterPanelComponent, { read: FilterPanelComponent })
  filterPanel: FilterPanelComponent;

  private lastLoginDateValue: number;

  @Output()
  collapsed: EventEmitter<{
    width: number;
  }> = new EventEmitter();

  @Output()
  expanded = new EventEmitter();

  @Output()
  valueChanges: EventEmitter<{
    config: FilterPanelConfigurationModel;
    values: any;
  }> = new EventEmitter();

  onFilterPanelCollapsed(eventArgs: { width: number }): void {
    this.collapsed.emit(eventArgs);
  }

  onFilterPanelExpanded(eventArgs): void {
    this.expanded.emit(eventArgs);
  }

  onFilterValueChanges(eventArgs: { config: FilterPanelConfigurationModel; values: any }): void {
    this.valueChanges.emit(eventArgs);
  }

  constructor(private datePipe: DatePipe,
     private localizationService: LocalizationService,
     private route: ActivatedRoute,) {
    const me = this;

    const lastLoginDateParam = this.route.snapshot.queryParams.lastLoginDate;
    this.lastLoginDateValue = lastLoginDateParam !== undefined ? Number(lastLoginDateParam) : -1;
    const allExceptText = this.localizationService.instant('::AllExcept');

    const quickSearchTermConfiguration: TextEditorConfigurationModel = {
      key: 'quickSearchTerm',
      editor: Editor.Text,
      defaultValue: '',
      placeholder: 'User::QuickSearchPhrase',
      iconCls: 'fas fa-search',
      clearBottomMargin: true,
      rememberMe(values: any): boolean {
        const quickSearchTerm = values?.quickSearchTerm;
        const id = Number(quickSearchTerm);

        if (!isNaN(id) && id > 0) {
          // if an id is set to input then not remember value
          return false;
        }

        return true;
      },
    };

    const roleSelectionConfiguration: RoleSelectionConfigurationModel = {
      key: 'role',
      editor: Editor.Role,
      defaultValue: null,
      selectionType: RoleSelectionType.Multiple,
      autoLoad: true,
    };

    const departmentSelectionConfiguration: DepartmentSelectionConfigurationModel = {
      key: 'department',
      editor: Editor.Department,
      defaultValue: {
        selectedDepartments: [],
        filterModeId: DepartmentFilterModeOption.SelectedDepartments,
      },
      selectionType: DepartmentSelectionType.Multiple,
      filterByStatus: true,
    };

    const lastLoginDateSelectionConfiguration: LastLoginDateSelectionConfigurationModel = {
      key: 'lastLoginDate',
      editor: Editor.LastLoginDate,
      defaultValue: null,
    };

    this.filterPanelConfig = {
      key: 'user-list',
      collapsible: true,
      hideHeader: false,
      closeOthers: true,
      buttons: {
        expandAll: false,
        clear: true,
        filter: true,
        collapseAll: false,
      },
      items: [
        {
          title: '::QuickSearch',
          key: 'quickSearchTerm',
          items: [quickSearchTermConfiguration],
          ignoreKeyOnValueCollection: true,
          hideHeader: true,
          sticky: true,
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            return values.quickSearchTerm && values.quickSearchTerm.trim().length > 0
              ? {
                  visible: true,
                  text: values.quickSearchTerm,
                  count: 1,
                }
              : {
                  visible: false,
                  text: '',
                  count: 0,
                };
          },
        },
        {
          title: 'User::Role',
          key: 'role',
          ignoreKeyOnValueCollection: true,
          items: [roleSelectionConfiguration],
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            return values.role && values.role.length > 0
              ? {
                  visible: true,
                  text: values.role.length,
                  count: values.role.length,
                }
              : {
                  visible: false,
                  text: '',
                  count: 0,
                };
          },
        },
        {
          title: '::Department',
          key: 'department',
          ignoreKeyOnValueCollection: true,
          items: [departmentSelectionConfiguration],
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            let selectionText = '';
            if (
              values.department?.filterModeId ==
              DepartmentFilterModeOption.AllDepartmentsExceptSelectedDeparment
            ) {
              selectionText += allExceptText.replace(
                '{0}',
                values.department?.selectedDepartments?.length
              );
            } else {
              selectionText += values.department?.selectedDepartments?.length;
            }
            return values.department?.selectedDepartments
              ? {
                  visible: values.department?.selectedDepartments?.length > 0,
                  text: selectionText,
                  count: values.department?.selectedDepartments?.length,
                }
              : {
                  visible: false,
                  text: '',
                  count: 0,
                };
          },
        },
        {
          title: 'User::LastLoginDate',
          key: 'lastLoginDate',
          ignoreKeyOnValueCollection: true,
          items: [lastLoginDateSelectionConfiguration],
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            return values.lastLoginDate
              ? {
                visible: true,
                text: values.lastLoginDate.name,
                count: 0,
              }
              : {
                visible: false,
                text: '',
                count: 0,
              };
          },
        },
      ],
    };
  }
  ngOnInit(): void {}

  clearFilter(){
    this.filterPanel.onClearFilters();
  }
  
  setFilterValues(filters: any) {
    if (filters && this.filterPanel) {
      this.filterPanel.setItemValue(
        'lastLoginDate',
        'lastLoginDate',
        filters.values.lastLoginDate
      );
    }
  }
}
