import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-conversation-comment-notification',
  templateUrl: './conversation-comment-notification.component.html',
  styleUrls: ['./conversation-comment-notification.component.scss']
})

@NotificationType('ConversationCommentNotification', ConversationCommentNotificationComponent)
export class ConversationCommentNotificationComponent implements OnInit {

  data: NotificationDto;
  commentTab = 4;

  get payloadData(): any {
    return JSON.parse(this.data?.payload);
  }

  constructor(private notificationService: NotificationService, private router: Router) { }

  ngOnInit(): void { }

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'Conversation::ConversationCommentToastrAndNotificationMessage',
      parsedPayload.conversationId
    );
    const activeTab = 4;
    var toastrId = toastr.info(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(notificationService, notificationId, parsedPayload, router, activeTab);
    });
  }

  static toasterClickedHandler(
    notificationService: NotificationService,
    notificationId: string,
    payload: any,
    router: Router,
    activeTab: number,
  ) {
    notificationService.markAsRead(notificationId).subscribe();
    this.navigateToConversation(payload, router, activeTab);
    notificationService.markAsRead(notificationId);
  }

  static navigateToConversation(payloadData: any, router: Router, activeTab: number) {
    const queryParams: any = {
      activeTab: activeTab
    };

    router.navigate(['/conversation', payloadData.conversationId], {
      queryParams: queryParams,
    });
  }

  onClick() {
    const parsedPayload = JSON.parse(this.data.payload);
    ConversationCommentNotificationComponent.navigateToConversation(parsedPayload, this.router, this.commentTab);
    this.notificationService.markAsRead(this.data.id);
  }
}
