import { Injectable } from '@angular/core';
import { AsyncValidator, ValidationErrors, FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserService } from 'src/core/services/administration/user.service';

@Injectable({ providedIn: 'root' })
export class UserDeviceIdValidator implements AsyncValidator {
  userId: string | null;
  isActive: boolean;

  constructor(private userService: UserService) {}

  validate(
    formControl: FormControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const id = this.userId == null ? '' : this.userId;
    const deviceId = formControl.value;
    if( deviceId == null || deviceId == ''){
        return of(null);
    }
    if (!this.isActive) {
      return of(null);
    }
    return this.userService.getByDeviceId(deviceId).pipe(
      map(
        (response) => {
          return response !== null && response.id !== id ? { deviceIdInUse: true } : null;
        },
        catchError(() => {
          return null;
        })
      )
    );
  }
}
