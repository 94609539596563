import { Component, OnInit, Input } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LocalizationService } from '@abp/ng.core';
import { UserGroupService } from 'src/core/services/user-group/user-group.service';
import { UserInteractedUserListDto } from 'src/core/models/user-group/user-interacted-user-list.dto';

@Component({
  selector: 'ca-user-interaction-list',
  templateUrl: './user-interaction-list.component.html',
  styleUrls: ['./user-interaction-list.component.scss'],
})
export class UserInteractionListComponent implements OnInit {
  @Input()
  set userId(value: string) {
    this._userId = value;

    if (this._userId !== '') {
      this.load();
    }
  }

  get userId() {
    return this._userId;
  }
  
  @BlockUI('permissions-block') blockUI: NgBlockUI;
  userInteractedUsers: UserInteractedUserListDto[] = [];

  private _userId: string;

  load() {
    this.blockUI.start(this.localizationService.instant('AbpIdentity::LoadingWithThreeDot'));
    this.userGroupService.getUserInteractedUsers(this._userId).subscribe(response => {
      this.userInteractedUsers = response;
      this.blockUI.stop();
    });
  }

  constructor(
    private userGroupService: UserGroupService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {}
}
