import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.AgentSentiment',
})
export class ConversationAgentSentiment {
  @GenericLookup({
    code: 'Conversation.AgentSentiment.VeryNegative',
  })
  static veryNegative: number;

  @GenericLookup({
    code: 'Conversation.AgentSentiment.Negative',
  })
  static negative: number;

  @GenericLookup({
    code: 'Conversation.AgentSentiment.Neutral',
  })
  static neutral: number;

  @GenericLookup({
    code: 'Conversation.AgentSentiment.Positive',
  })
  static positive: number;

  @GenericLookup({
    code: 'Conversation.AgentSentiment.VeryPositive',
  })
  static veryPositive: number;

  @GenericLookup({
    code: 'Conversation.AgentSentiment.Unknown',
  })
  static unknown: number;
}
