import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { DatetimeRangePickerComponent } from './datetime-range-picker/datetime-range-picker.component';
import { DatetimeRangePickerInputModel } from 'src/ca-shared/datetime-range-picker/models/datetime-range-picker-input.model';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '../icons/icons.module';

export * from './datetime-range-picker/datetime-range-picker.component';
export * from './models/datetime-range-picker-input.model';
@NgModule({
  declarations: [DatetimeRangePickerComponent],
  imports: [SharedModule, CommonModule, NgbModule, FormsModule, IconsModule],

  exports: [DatetimeRangePickerComponent],
})
export class DatetimeRangePickerModule {}
