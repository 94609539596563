import { GridConfig } from '../models/grid-config.model';

export class ColumnConfiguratorModuleStateModel {
  grids: GridConfig[];

  static getDefaults(): ColumnConfiguratorModuleStateModel {
    const defaults: ColumnConfiguratorModuleStateModel = {
      grids: [],
    };

    return defaults;
  }
}
