import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationTagDto } from 'src/core/models/conversation/tag/conversation-tag.dto';
import { TagDto } from 'src/core/models/conversation/tag/tag.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { AssignTagModel } from './assign-tag.model';

@Injectable()
export class ConversationMarkAsCommunicationService {
  private readonly debounceTime: number = 3000;
  private readonly assignTags = new Subject<AssignTagModel>();
  private conversationTagsCache = new Map<number, ConversationTagDto[]>();
  private _tagsUpdating: boolean = false;

  get tagsUpdating(): boolean {
    return this._tagsUpdating;
  }

  constructor(
    private conversationService: ConversationService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.assignTags.pipe(debounceTime(this.debounceTime)).subscribe(a => {
      this.conversationService
        .assignTags(a.conversation.id, a.tags)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.conversationTagsCache.delete(a.conversation.id);
            this.toastr.success(this.localizationService.instant('::SuccessfullySaved'));
          },
          error: () => {
            if (this.conversationTagsCache.has(a.conversation.id)) {
              var oldTags = this.conversationTagsCache.get(a.conversation.id);
              a.conversation.conversationTags = ObjectHelper.deepCopy(oldTags);
              this.conversationTagsCache.delete(a.conversation.id);
            }
          },
          complete: () => {
            this._tagsUpdating = false;
          },
        });
    });
  }

  addTag(conversation: ConversationDto, tag: TagDto) {
    if (!conversation) {
      return;
    }

    var tags = conversation.conversationTags ? conversation.conversationTags : [];

    if (!this.conversationTagsCache.has(conversation.id)) {
      this.conversationTagsCache.set(conversation.id, ObjectHelper.deepCopy(tags));
    }

    if (!tags.some(t => t.tagId === tag.id)) {
      let conversationTag = new ConversationTagDto();
      conversationTag.conversationId = conversation.id;
      conversationTag.tagId = tag.id;
      conversationTag.name = tag.name;
      tags.push(conversationTag);
      conversation.conversationTags = ObjectHelper.deepCopy(tags);
    }

    this.assignTags;

    this._tagsUpdating = true;
    this.assignTags.next({
      conversation: conversation,
      tags: tags,
    });
  }

  removeTag(conversation: ConversationDto, tag: ConversationTagDto) {
    if (!conversation) {
      return;
    }

    var tags = conversation.conversationTags ? conversation.conversationTags : [];

    if (!this.conversationTagsCache.has(conversation.id)) {
      this.conversationTagsCache.set(conversation.id, ObjectHelper.deepCopy(tags));
    }

    var newTags = conversation.conversationTags.filter(t => t.tagId != tag.tagId);
    conversation.conversationTags = ObjectHelper.deepCopy(newTags);

    this._tagsUpdating = true;
    this.assignTags.next({
      conversation: conversation,
      tags: newTags,
    });
  }
}
