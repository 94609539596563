import { CdkDragEnd, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { ColumnConfiguratorService } from '../../services/column-configurator.service';

@Component({
  selector: 'ca-column-resizer',
  templateUrl: './column-resizer.component.html',
  styleUrls: ['./column-resizer.component.scss'],
})
export class ColumnResizerComponent implements OnInit, AfterViewInit {
  @Input()
  gridId: string;

  @Input()
  columnId: string;

  @HostBinding('style.height')
  componentHeight = '40px';

  @HostBinding('style.display')
  componentDisplay = 'block';

  @HostBinding('style.width')
  componentWidth = '16px';

  @HostBinding('style.position')
  componentFloat = 'absolute';

  @HostBinding('style.top')
  componentTop = '0';

  @HostBinding('style.right')
  componentRight = '0px';

  dragging = false;
  tableEl: HTMLTableElement;
  tableContainerEl: HTMLElement;
  tablePosition: DOMRect;

  private dragLine: HTMLDivElement;

  constructor(private service: ColumnConfiguratorService, private elementRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const el = this.elementRef.nativeElement;
    const parent = el.parentElement;
    const th = el.closest('th') as HTMLElement;

    this.tableEl = this.elementRef.nativeElement.closest('table');
    this.tableContainerEl = this.tableEl.parentElement;
    this.tablePosition = this.tableEl.getBoundingClientRect();

    if (parent != th) {
      parent.removeChild(el);
      th.appendChild(el);
    }
  }

  onClick(eventArgs): void {
    eventArgs.stopPropagation();
    eventArgs.preventDefault();
  }

  onDragStart(eventArgs: CdkDragStart): void {
    eventArgs.event.preventDefault();
    eventArgs.event.stopPropagation();

    this.dragging = true;

    this.dragLine = document.createElement('div');
    this.dragLine.classList.add('drag-line');

    this.tableContainerEl.appendChild(this.dragLine);
  }

  onDragEnd(eventArgs: CdkDragEnd): void {
    this.dragging = false;

    this.service.changeColumnWidth(this.gridId, this.columnId, eventArgs.distance.x);
    eventArgs.source._dragRef.reset();

    this.dragLine.remove();
  }

  onDragMove(eventArgs: CdkDragMove): void {
    this.dragLine.style.left = `${eventArgs.pointerPosition.x - this.tablePosition.left}px`;
  }
}
