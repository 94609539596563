import { ConfigStateService } from '@abp/ng.core';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { PlayerComponent } from 'src/ca-shared/player/player.module';
import { MeetingTranscriptDto } from 'src/core/models/meeting/meeting-transcript.dto';
import { TranscriptRow } from 'src/core/models/conversation/transcript/transcript-row.model';
import { MeetingAnalysisStatus } from 'src/core/models/generic-lookup-type/meeting/meeting-analysis-status.glt';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';

@Component({
  selector: 'ca-meeting-transcript-panel',
  templateUrl: './meeting-transcript-panel.component.html',
  styleUrls: ['./meeting-transcript-panel.component.scss'],
})
export class MeetingTranscriptPanelComponent implements OnInit, AfterViewInit {
  @ViewChildren('wordContainer') wordContainers: QueryList<ElementRef>;

  defaultChannelColor = '#9c9c9c';
  channelList = [];
  transcriptDisplayedColumns: string[] = ['startTime', 'channel', 'chat'];
  channelColors: string[] = ['#ff9d09', '#6fa8dc', '#74cd73', '#cd73b0', '#cd7374', '#d3db69'];
  dataTableTranscripts: TranscriptRow[] = [];
  indexedWordContainers: any;
  private _transcripts: MeetingTranscriptDto[];
  private _chatViewThreshold = 0;
  private _analysisStatusId;
  private _lastHighlighteds = [];
  private _lastHighlightedTime = null;
  private _lastScrollIndex = null;
  private _clsHighlighted = 'highlighted';
  private _transcriptScrollOptions = { behavior: 'smooth', block: 'center', inline: 'nearest' };

  meetingAnalysisType = MeetingAnalysisStatus;

  @Input()
  player: PlayerComponent;

  @Input()
  set transcripts(transcripts: any[]) {
    this._transcripts = transcripts;
    if (this._transcripts.length > 0) {
      this.fillDataTableTranscript(this._transcripts);
    }
  }

  @Input()
  set analysisStatusId(analysisStatusId: number) {
    this._analysisStatusId = analysisStatusId;
  }

  get analysisStatusId(): number {
    return this._analysisStatusId;
  }

  constructor(private config: ConfigStateService, private caTimePipe: CATimePipe) {
    this._chatViewThreshold = parseInt(
      this.config.getSetting('Conversation.ChatViewThreshold'),
      10
    );
  }

  ngOnInit(): void {
    if (this.player) {
      this.player.positionChange.subscribe(this.highlightWord.bind(this));
    }
  }

  getChannelColor(channelName) {
    const index = this.channelList.indexOf(channelName);
    if (this.channelColors[index] != null) {
      return this.channelColors[index];
    } else {
      return this.defaultChannelColor;
    }
  }

  fillDataTableTranscript(data: any[]) {
    this._transcripts = this._transcripts.sort((a, b) => a.startTime - b.startTime);
    this.dataTableTranscripts = [];

    let row: TranscriptRow;

    for (let i = 0; i < data.length; i++) {
      const curr = data[i];
      if (this.channelList.filter(x => x === curr.channel).length === 0) {
        this.channelList.push(curr.channel);
      }
      if (i === 0 || curr.channel !== row.channel) {
        let currentChannelRows = this.dataTableTranscripts.filter(x => x.channel == curr.channel);
        let channelLastRow = currentChannelRows[currentChannelRows.length - 1];
        if (
          channelLastRow &&
          channelLastRow.endMillisecond + this._chatViewThreshold >= curr.startTime
        ) {
          channelLastRow.words.push({
            startTime: curr.startTime,
            word: curr.word,
            endTime: curr.endTime,
          });
          continue;
        } else {
          row = {
            time: this.calculateTime(curr.startTime),
            startMillisecond: curr.startTime,
            endMillisecond: curr.endTime,
            channel: curr.channel,
            words: [],
          };
          this.dataTableTranscripts.push(row);
        }
      }
      row.endMillisecond = curr.endTime;
      row.words.push({
        startTime: curr.startTime,
        word: curr.word,
        endTime: curr.endTime,
      });
    }
  }

  calculateTime(currentTime: number) {
    const seconds = Math.floor(currentTime / 1000);
    return this.caTimePipe.transform(seconds);
  }

  public setupWordContainers() {
    this.indexedWordContainers = {};
    this.wordContainers.forEach(el => {
      const nativeEl = el.nativeElement;
      let channel = nativeEl.getAttribute('data-channel');
      let time = nativeEl.getAttribute('data-time');
      let endTime = nativeEl.getAttribute('data-end-time');
      let index = nativeEl.getAttribute('data-index');
      channel = channel;
      time = parseFloat(time).toFixed(2);
      if (this.indexedWordContainers[channel] == null) {
        this.indexedWordContainers[channel] = [];
      }
      this.indexedWordContainers[channel].push({
        time: time,
        nativeEl: nativeEl,
        endTime: endTime,
        index: index,
      });
    });
  }

  highlightWord(playerArgs: { currentTime: number; currentTimeString: string }) {
    if (this.indexedWordContainers) {
      const t = playerArgs.currentTime.toFixed(2); // ms to sec

      if (playerArgs.currentTime === 0) {
        this.clearAllHighlights(this._lastHighlighteds);
      } else if (this._lastHighlightedTime > playerArgs.currentTime) {
        this.clearAllHighlights(this._lastHighlighteds);
      } else if (playerArgs.currentTime - this._lastHighlightedTime > 1) {
        this.clearAllHighlights(this._lastHighlighteds);
      }

      const sorted = Array.from(this.dataTableTranscripts);

      if (!this._lastHighlightedTime) {
        this._lastHighlightedTime = sorted
          .sort((a, b) => b.startMillisecond - a.startMillisecond)
          .find(x => x.startMillisecond < playerArgs.currentTime * 1000);
      }

      var wordArray = [];
      for (let container in this.indexedWordContainers) {
        wordArray = wordArray.concat(
          this.indexedWordContainers[container].filter(
            x => x.time <= playerArgs.currentTime && x.time > this._lastHighlightedTime
          )
        );
      }

      if (wordArray.length > 0) {
        this.clearHighlight(this._lastHighlighteds, playerArgs.currentTime);
        wordArray.forEach(element => {
          element.nativeEl.classList.add(this._clsHighlighted);
          let elementIndex = parseInt(element.index);
          if (this._lastScrollIndex == null || elementIndex > this._lastScrollIndex) {
            element.nativeEl.scrollIntoView(this._transcriptScrollOptions);
            this._lastScrollIndex = elementIndex;
          }
          this._lastHighlighteds.push(element);
        });
      }
      this._lastHighlightedTime = playerArgs.currentTime;
    }
  }

  ngAfterViewInit() {
    this.wordContainers.changes.subscribe(r => {
      this.setupWordContainers();
    });
  }

  clearAllHighlights(lastHighlighteds: any[]) {
    if (lastHighlighteds.length > 0) {
      lastHighlighteds.forEach(lastHighlighted => {
        lastHighlighted.nativeEl.classList.remove(this._clsHighlighted);
      });
      this._lastHighlighteds = [];
    }
    this._lastHighlightedTime = null;
    this._lastScrollIndex = null;
  }

  clearHighlight(lastHighlighteds: any[], time: number) {
    if (lastHighlighteds.length > 0) {
      lastHighlighteds
        .filter(x => x.endTime < time)
        .forEach(lastHighlighted => {
          lastHighlighted.nativeEl.classList.remove(this._clsHighlighted);
        });
    }
  }
}
