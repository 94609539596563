import { EventEmitter, Injectable, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { InitializationRequested } from 'src/core/actions/video-player/video-player.actions';
@Injectable({
  providedIn: 'root',
})
export class VideoPlayerService {
  private _videoElement: HTMLVideoElement;

  private closed = true;
  private minimized = false;
  private maximized = false;
  private title = '';

  closed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.closed);
  minimized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.minimized);
  maximized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.maximized);

  @Output()
  restorationRequested: EventEmitter<{
    title: string;
    changeTitle: boolean;
  }> = new EventEmitter();

  get videoElement(): HTMLVideoElement {
    return this._videoElement;
  }

  set videoElement(el: HTMLVideoElement) {
    this._videoElement = el;
  }

  constructor(private store: Store) {
    const action = new InitializationRequested();

    this.store.dispatch(action);
  }

  show(title: string = ''): void {
    this.title = title;

    this.restorationRequested.emit({
      title: title,
      changeTitle: true,
    });
  }

  close(): void {
    this.closed = true;
    this.closed$.next(this.closed);

    this.minimized = false;
    this.minimized$.next(this.minimized);

    this.maximized = false;
    this.maximized$.next(this.maximized);
  }

  minimize(): void {
    this.minimized = true;
    this.minimized$.next(this.minimized);
  }

  maximize(): void {
    this.maximized = true;
    this.maximized$.next(this.maximized);
  }

  restore(): void {
    if (this.minimized) {
      this.minimized = false;
      this.minimized$.next(this.minimized);
    }

    this.restorationRequested.emit({
      title: null,
      changeTitle: false,
    });
  }
}
