import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PlayerTimelineConversationMarkMarkerDto } from '../models/player-timeline-conversationmark-marker.dto';
import { ColorUtils } from 'src/core/helpers/color-utils';
@Component({
  selector: 'ca-player-timeline-conversationmark-marker',
  templateUrl: './player-timeline-conversationmark-marker.component.html',
  styleUrls: ['./player-timeline-conversationmark-marker.component.scss'],
})
export class PlayerTimelineConversationMarkMarkerComponent implements OnInit {
  @Input()
  marker: PlayerTimelineConversationMarkMarkerDto;
  @Output()
  hover: EventEmitter<{
    markerInfo: PlayerTimelineConversationMarkMarkerDto;
  }> = new EventEmitter();

  @Output()
  markerClick: EventEmitter<{
    markerInfo: PlayerTimelineConversationMarkMarkerDto;
  }> = new EventEmitter();

  constructor(public colorUtils: ColorUtils) { }

  ngOnInit(): void { }

  onMouseEnter(marker: PlayerTimelineConversationMarkMarkerDto) {
    this.hover.emit({ markerInfo: marker });
  }

  onClick(marker: PlayerTimelineConversationMarkMarkerDto) {
    this.markerClick.emit({ markerInfo: marker });
  }
}
