import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConversationCategoryPanelComponent } from './conversation-category-panel.component';

@NgModule({
  declarations: [ConversationCategoryPanelComponent],
  imports: [CommonModule, SharedModule],
  exports: [ConversationCategoryPanelComponent],
})
export class ConversationCategoryPanelModule {}
