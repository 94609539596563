import { Component, Input, OnInit } from '@angular/core';
import { ColumnConfiguratorService } from '../../services/column-configurator.service';
import { Store } from '@ngxs/store';
import { LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'ca-column-options-button',
  templateUrl: './column-options-button.component.html',
  styleUrls: ['./column-options-button.component.scss'],
})
export class ColumnOptionsButtonComponent implements OnInit {
  @Input()
  buttonClass: string = 'btn ca-btn ca-btn-neutral-toolbar toolbar-button';

  @Input()
  iconClass: string = 'fa-regular fa-wrench';

  @Input()
  buttonText: string = this.localizationService.instant('CA::ColumnConfigurator:ColumnOptions');

  @Input()
  gridId: string;

  constructor(
    private service: ColumnConfiguratorService,
    private store: Store,
    private localizationService: LocalizationService
  ) {}

  ngOnInit(): void {}

  onClick(eventArgs: MouseEvent): void {
    this.service.showConfigurator(this.gridId);
  }
}
