import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DepartmentDto } from 'src/core/models/administration/department/department.dto';
import { GenericTreeNodeDto } from 'src/core/models/mat-tree/generic-tree-node.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { ListRequestDto } from 'src/core/models/request/list-request.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  constructor(private http: HttpClient, private operators: Operators) {}

  getRootDepartments(
    isActiveFilter: 'all' | 'active' | 'passive',
    selectedIds: number[] = []
  ): Observable<ListResponseDto<DepartmentDto>> {
    const apiUrl = this.getApiUrl(DepartmentDto) + '/root';
    const request = new ListRequestDto();
    let params = new HttpParams();

    request.filters = [];
    request.sorters = [];
    request.skipCount = 0;
    request.maxResultCount = -1;

    if (isActiveFilter !== 'all') {
      request.filters.push({
        field: 'isActive',
        value: isActiveFilter === 'active',
        operator: this.operators.Equals,
      });
    }

    if (selectedIds.length > 0) {
      request.filters.push({
        field: 'selectedIds',
        value: JSON.stringify(selectedIds),
        operator: this.operators.In,
      });
    }

    params = params.append('filters', JSON.stringify(request.filters));
    params = params.append('sorters', JSON.stringify(request.sorters));
    params = params.append('skipCount', JSON.stringify(request.skipCount));
    params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));

    return this.http.get(apiUrl, {
      params,
    }) as Observable<ListResponseDto<DepartmentDto>>;
  }

  getChildDepartments(
    parentId: number,
    isActiveFilter: 'all' | 'active' | 'passive',
    includeAllChildren: boolean
  ): Observable<ListResponseDto<DepartmentDto>> {
    const apiUrl = this.getApiUrl(DepartmentDto) + `/${parentId}/children`;

    const request = new ListRequestDto();
    let params = new HttpParams();

    request.filters = [];
    request.sorters = [];
    request.skipCount = 0;
    request.maxResultCount = -1;

    if (isActiveFilter !== 'all') {
      request.filters.push({
        field: 'isActive',
        value: isActiveFilter === 'active',
        operator: this.operators.Equals,
      });
    }

    if (includeAllChildren) {
      request.filters.push({
        field: 'includeAllChildren',
        value: true,
        operator: this.operators.Equals,
      });
    }

    params = params.append('filters', JSON.stringify(request.filters));
    params = params.append('sorters', JSON.stringify(request.sorters));
    params = params.append('skipCount', JSON.stringify(request.skipCount));
    params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));

    return this.http.get(apiUrl, {
      params,
    }) as Observable<ListResponseDto<DepartmentDto>>;
  }

  getParentIds(id: number): Observable<number[]> {
    const apiUrl = this.getApiUrl(DepartmentDto) + `/${id}/parent-ids`;

    return this.http.get(apiUrl) as Observable<number[]>;
  }

  getFilteredDepartments(
    quickSearchTerm: string,
    isActiveFilter: 'all' | 'active' | 'passive'
  ): Observable<ListResponseDto<DepartmentDto>> {
    const apiUrl = this.getApiUrl(DepartmentDto);
    const filters: FilterItemDto[] = [
      {
        field: 'name',
        operator: this.operators.StartsWith,
        value: quickSearchTerm,
      },
    ];

    if (isActiveFilter !== 'all') {
      filters.push({
        field: 'isActive',
        value: isActiveFilter === 'active',
        operator: this.operators.Equals,
      });
    }

    let params = new HttpParams();

    params = params.append('filters', JSON.stringify(filters));
    params = params.append('sorters', JSON.stringify([]));
    params = params.append('skipCount', JSON.stringify(0));
    params = params.append('maxResultCount', JSON.stringify(99999));

    return this.http.get(apiUrl, {
      params,
    }) as Observable<ListResponseDto<DepartmentDto>>;
  }

  protected getApiUrl(type = DtoHasApi): string {
    return 'api/app/department/tree';
  }
}
