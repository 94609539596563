import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { GenericState } from '../generic/generic.state';
import { FilterBreadcrumbStateModel } from 'src/core/models/filter-breadcrumb/filter-breadcrumb.state-model';
import {
  FilterAdd,
  FilterClear,
  FilterClearUndo,
  FilterRemove,
  FilterUndo,
  WidgetFilterChange,
} from 'src/core/actions/filter-breadcrumb/filter-breadcrumb.actions';
import { FilterBreadcrumbModel } from 'src/core/models/filter-breadcrumb/filter-breadcrumb.model';
const name = 'FilterBreadcurmbState';
@State<FilterBreadcrumbStateModel>({
  name: name,
  defaults: {
    currentIndex: 1,
    breadcrumbs: [],
    previousBreadcrumbs: [],
    widgetFilters: [],
  },
})
@Injectable()
export class FilterBreadcurmbState extends GenericState<FilterBreadcurmbState> {
  @Selector()
  static getFilterBreadcrumbs(state: FilterBreadcrumbStateModel): FilterBreadcrumbModel[] {
    return state.breadcrumbs;
  }

  @Selector()
  static getPreviousFilterBreadcrumbs(state: FilterBreadcrumbStateModel): FilterBreadcrumbModel[] {
    return state.previousBreadcrumbs;
  }

  @Selector()
  static getCurrentIndex(state: FilterBreadcrumbStateModel): number {
    return state.currentIndex;
  }

  @Action(FilterAdd)
  addFilter(ctx: StateContext<FilterBreadcrumbStateModel>, action: FilterAdd) {
    const currentState = ctx.getState();

    if (!currentState.breadcrumbs) {
      currentState.breadcrumbs = [];
    }

    currentState.previousBreadcrumbs = [];
    currentState.breadcrumbs.push(action.breadcrumb);
    currentState.breadcrumbs = [...currentState.breadcrumbs];
    currentState.currentIndex = currentState.currentIndex + 1;

    if (currentState.widgetFilters) {
      currentState.widgetFilters = currentState.widgetFilters.filter(x =>
        currentState.breadcrumbs.map(b => b.id).includes(x.id)
      );
    }

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(FilterRemove)
  removeFilter(ctx: StateContext<FilterBreadcrumbStateModel>, action: FilterRemove) {
    const currentState = ctx.getState();

    if (!currentState.breadcrumbs) {
      currentState.breadcrumbs = [];
    }

    currentState.previousBreadcrumbs = [];
    currentState.breadcrumbs = currentState.breadcrumbs.filter(bc => bc.id !== action.id);
    if (currentState.breadcrumbs.length <= 1) {
      currentState.currentIndex = currentState.breadcrumbs.length + 1;
    }

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(FilterClear)
  clearFilter(ctx: StateContext<FilterBreadcrumbStateModel>, action: FilterClear) {
    const currentState = ctx.getState();

    if (!currentState.breadcrumbs) {
      currentState.breadcrumbs = [];
    }

    if (!currentState.previousBreadcrumbs) {
      currentState.previousBreadcrumbs = [];
    }

    currentState.previousBreadcrumbs = currentState.breadcrumbs.slice(
      -(currentState.breadcrumbs.length - 1)
    );
    if (currentState.breadcrumbs.length > 0) {
      currentState.breadcrumbs = [currentState.breadcrumbs[0]];
    }
    currentState.currentIndex = currentState.breadcrumbs.length + 1;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(FilterUndo)
  undoFilter(ctx: StateContext<FilterBreadcrumbStateModel>, action: FilterUndo) {
    const currentState = ctx.getState();

    if (!currentState.breadcrumbs) {
      currentState.breadcrumbs = [];
    }

    if (!currentState.previousBreadcrumbs) {
      currentState.previousBreadcrumbs = [];
    }

    while (currentState.previousBreadcrumbs.length > 0) {
      const previousBread = currentState.previousBreadcrumbs.shift();
      currentState.breadcrumbs.push(previousBread);
    }
    currentState.breadcrumbs = [...currentState.breadcrumbs];

    if (currentState.breadcrumbs && currentState.breadcrumbs.length > 0) {
      currentState.currentIndex =
        currentState.breadcrumbs[currentState.breadcrumbs.length - 1].index + 1;
    }

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(FilterClearUndo)
  clearUndoFilter(ctx: StateContext<FilterBreadcrumbStateModel>, action: FilterClearUndo) {
    const currentState = ctx.getState();

    currentState.previousBreadcrumbs = [];

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(WidgetFilterChange)
  changeWidgetFilter(ctx: StateContext<FilterBreadcrumbStateModel>, action: WidgetFilterChange) {
    const currentState = ctx.getState();

    if (!currentState.widgetFilters) {
      currentState.widgetFilters = [];
    }

    let currentWidgetState = currentState.widgetFilters.find(
      x => x.id == action.breadcrumbId && x.component == action.component
    );
    if (!currentWidgetState) {
      currentState.widgetFilters.push({
        id: action.breadcrumbId,
        filters: action.filters,
        component: action.component,
      });
    } else {
      currentWidgetState.filters = action.filters;
    }

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  constructor(userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;

    this.userSettingsService.register(this.name, this);
  }
}
