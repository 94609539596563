import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../shared/dto-has-api.model';

@Api('api/app/chat')
export class ChatDto extends DtoHasApi {
    id: number;
    participantName: string;
    title: string;
    conversationId: number;
    userId: string;
    userGroupId: number;
    releasingPartyId: number;
    releasingPartyName: string;
}
