import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CategoryDto } from 'src/core/models/query/category.dto';
@Injectable({
    providedIn: 'root',
})
export class CategoryService {

    constructor(
        private http: HttpClient,
    ) { }

    getNonFcrPrebuildCategories(): Observable<CategoryDto[]> {
        const url = this.getApiUrl() + '/nonfcr-prebuilt-categories';

        return this.http.get(url) as Observable<CategoryDto[]>;
    }

    getCategoryLimit(): Observable<number> {
        const url = this.getApiUrl() + '/category-limit';

        return this.http.get(url) as Observable<number>;
    }

    getCategoryTextItemLimit(): Observable<number> {
        const url = this.getApiUrl() + '/category-text-item-limit';

        return this.http.get(url) as Observable<number>;
    }

    private getApiUrl(): string {
        const apiBase = environment.apis.default.url;

        return apiBase + '/' + CategoryDto.apiUrl;
    }
}
