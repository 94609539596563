<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div>
    <div>
      <div>
        <label class="mb-0">{{ payload.value | logicalOperator }}</label>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #editableContent>
  <div class="form-check-input-wrapper" [class]="cssClass">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <div
          class="form-check form-check-inline"
          *ngFor="let operator of logicalOperatorArr; let i = index"
        >
          <input
            #operatorContainer
            class="form-check-input"
            type="radio"
            [id]="'operatorRadio_' + this.payload.internalId + '_' + i"
            [attr.name]="'operatorRadioOptions_' + this.payload.internalId"
            [attr.id]="'operatorRadio_' + this.payload.internalId + '_' + i"
            [attr.value]="operator"
            [disabled]="node.disabled"
            (change)="onOperatorChange($event)"
            [checked]="this.payload.value === operator"
          />
          <label
            class="form-check-label"
            [for]="'operatorRadio_' + this.payload.internalId + '_' + i"
            >{{ operator | logicalOperator }}</label
          >
        </div>
        <select
          style="display: none"
          class="form-select form-select-sm"
          [(ngModel)]="payload.value"
          [disabled]="node.disabled"
          (change)="onOperatorChange($event)"
        >
          <option *ngFor="let operator of logicalOperatorArr" [value]="operator">
            {{ operator | logicalOperator }}
          </option>
        </select>
      </div>
      <div class="col-auto show-on-hover">
        <small *ngIf="node.disabled" class="form-text text-muted text-warning">
          <i class="fas fa-exclamation-circle"></i>
          {{ 'Query::ChangeOperatorInfo' | abpLocalization }}
        </small>
      </div>
    </div>
  </div>
</ng-template>
