<div class="form-control form-control-sm conversation-side-selector pt-0 pl-0">
  <mat-select
    [(value)]="value"
    [disabled]="disabled"
    (selectionChange)="onSelectionChange($event.value)"
    panelClass="mat-select-position conversation-side-panel-class"
    disableOptionCentering>
    <mat-option *ngFor="let side of conversationMarkChannel$ | async" [value]="side.id">
      <div class="mat-option-container">
        <div class="icon-container">
          <div class="w-100" *ngIf="side.id == conversationMarkChannel.undefined">
            <div class="float-start">
              <span class="boder-single">
                <i class="far fa-headphones icon-size conversation-side-agent-color"></i>
              </span>
            </div>
            <div class="float-end">
              <span class="boder-single">
                <i class="far fa-user icon-size conversation-side-customer-color"></i>
              </span>
            </div>
          </div>
          <div class="w-100" *ngIf="side.id == conversationMarkChannel.customer">
            <div class="float-end">
              <span class="boder-single">
                <i class="far fa-user icon-size conversation-side-customer-color"></i>
              </span>
            </div>
          </div>
          <div class="w-100" *ngIf="side.id == conversationMarkChannel.agent">
            <div class="float-end">
              <span class="boder-single">
                <i class="far fa-headphones icon-size conversation-side-agent-color"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="ms-1">
          {{
            side.id == conversationMarkChannel.undefined
              ? ('GenericLookup::Any.Label' | abpLocalization)
              : ('GenericLookup::' + side.code + '.Label' | abpLocalization)
          }}
        </div>
      </div>
    </mat-option>

    <mat-select-trigger>
      <div
        class="align-left mt-1"
        ngbTooltip="{{ getTooltipText() }}"
        container="body"
        placement="bottom">
        <span class="w-100" *ngIf="value == conversationMarkChannel.undefined">
          <span class="boder-single-small smaller-font" style="margin-right: 6px">
            <i
              class="far fa-headphones icon-size conversation-side-agent-color"
              style="padding-left: 0.5px"></i>
          </span>
          <span class="boder-single-small smaller-font">
            <i
              class="far fa-user icon-size conversation-side-customer-color"
              style="padding-left: 1.4px"></i>
          </span>
        </span>
        <span
          class="boder-single-small smaller-font"
          *ngIf="value == conversationMarkChannel.customer">
          <i
            class="far fa-user icon-size conversation-side-customer-color"
            style="padding-left: 1.4px"></i>
        </span>
        <span
          class="boder-single-small smaller-font"
          *ngIf="value == conversationMarkChannel.agent">
          <i
            class="far fa-headphones icon-size conversation-side-agent-color"
            style="padding-left: 0.5px"></i>
        </span>
      </div>
    </mat-select-trigger>
  </mat-select>
</div>
