<div>
  <h2>{{ 'Settings::CorporateIdentity' | abpLocalization }}</h2>
</div>
<br />
<hr />
<div [formGroup]="settingsForm">
  <div class="form-group mt-4">
    <label for="title" class="font-weight-bold">
      {{ 'Settings::CorporateIdentity.Title' | abpLocalization }}</label
    >
    <i
      class="ms-2 fas fa-info-circle"
      ngbTooltip="{{ 'Settings::CorporateIdentity.TitleTooltip' | abpLocalization }}"
      container="body"></i>
    <input type="text" class="form-control form-control-sm" id="title" formControlName="title" />
  </div>

  <div class="form-group mt-4">
    <label for="logoBase64">
      {{ 'Settings::CorporateIdentity.Logo' | abpLocalization }}</label
    >
    <i
      class="ms-2 fas fa-info-circle"
      ngbTooltip="{{ 'Settings::CorporateIdentity.LogoTooltip' | abpLocalization }}"
      container="body"></i>
    <span class="d-flex">
      <img *ngIf="logoImageSource" [src]="logoImageSource" class="image-preview" id="imgLogo" />

      <label class="no-file-label" *ngIf="!logoImageSource">{{
        'Settings::LogoFileNotSet' | abpLocalization
      }}</label>
      <div class="file-input-container">
        <button
          class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
          type="button"
          (click)="fileInputLogo.click()">
          <i class="far fa-file-upload"></i>
          <span>{{ 'CA::Change' | abpLocalization }}</span>
        </button>
        <label class="btn btn-warning d-none" for="fileSelectorLogo">
          <input
            #fileInputLogo
            id="fileSelectorLogo"
            type="file"
            class="d-none"
            (click)="$event.target.value = ''"
            (change)="onLogoFileChange($event.target.files)"
            accept="{{ logoAllowedExtensions.join(',') }}" />
          {{ 'Conversation::UploadFile' | abpLocalization }}
        </label>
      </div>
    </span>
    <label *ngIf="!isLogoFileSelectionValid" class="invalid-label">{{
      logoFileSelectionInvalidMessage
    }}</label>
  </div>

  <div class="form-group mt-4">
    <label for="faviconBase64" class="font-weight-bold">
      {{ 'Settings::CorporateIdentity.Favicon' | abpLocalization }}</label
    >
    <i
      class="ms-2 fas fa-info-circle"
      ngbTooltip="{{ 'Settings::CorporateIdentity.FaviconTooltip' | abpLocalization }}"
      container="body"></i>
    <span class="d-flex">
      <img
        [src]="faviconImageSource"
        *ngIf="faviconImageSource"
        class="image-preview"
        id="imgFavicon" />
      <label class="no-file-label" *ngIf="!faviconImageSource">{{
        'Settings::FaviconFileNotSet' | abpLocalization
      }}</label>
      <div class="file-input-container">
        <button
          class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
          type="button"
          (click)="fileInputFavicon.click()">
          <i class="far fa-file-upload"></i>
          <span>{{ 'CA::Change' | abpLocalization }}</span>
        </button>
        <label class="btn btn-warning d-none" for="fileSelectorFavicon">
          <input
            #fileInputFavicon
            id="fileSelectorFavicon"
            type="file"
            class="d-none"
            (click)="$event.target.value = ''"
            (change)="onFaviconFileChange($event.target.files)"
            accept="{{ faviconAllowedExtensions.join(',') }}" />
          {{ 'Conversation::UploadFile' | abpLocalization }}
        </label>
      </div>
    </span>
    <label *ngIf="!isFaviconFileSelectionValid" class="invalid-label">{{
      faviconFileSelectionInvalidMessage
    }}</label>
  </div>

  <button
    type="button"
    [disabled]="!settingsForm.valid"
    (click)="saveSettings()"
    class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</div>
