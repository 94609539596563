<table mat-table [dataSource]="dataSource" style="width: 100%">
  <ng-container *ngFor="let gridColumn of gridColumns">
    <ng-container
      *ngIf="gridColumn.columnName !== 'creationTime' && gridColumn.columnName !== 'totalPoint'"
      matColumnDef="{{ gridColumn.columnName }}">
      <ng-container>
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width"
          scope="col">
          {{ gridColumn.header }}
        </th>
      </ng-container>
      <td
        mat-cell
        *matCellDef="let evaluation"
        [innerHtml]="evaluation | objectparser : gridColumn"></td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'creationTime'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let evaluation">
        <ca-clickable-link
          [innerHtml]="evaluation | objectparser : gridColumn"
          [targetLink]="['/quality', 'evaluation', evaluation.evaluationMaster.id]"
          [params]="{
            referrer: evaluationReferrer.conversation,
            referrerTab: activeTab,
            evaluationResultId: this.evaluation.id
          }">
        </ca-clickable-link>
      </td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'totalPoint'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span>
            {{ element | objectparser : gridColumn }}
          </span>
          <span *ngIf="element.zeroPoint">
            <i
              class="fas fa-info-circle"
              #reasonListPopover="ngbPopover"
              [ngbPopover]="reasonListTemplate"
              [popoverTitle]="reasonListTitle"
              popoverClass="query-preview-popover"
              placement="left auto"
              triggers="mouseenter:mouseleave"
              [autoClose]="'outside'"
              container="body"
              (mouseover)="reasonListPopover.open({ evaluation: element })"
              (mouseleave)="reasonListPopover.close()"></i>
          </span>
        </div>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    class="evaluation-result-detail-row-{{ myRowData.id }}"
    *matRowDef="let myRowData; columns: displayedColumns"></tr>
</table>

<ng-template #reasonListTitle
  ><b>{{
    'QualityManagement::CompletedWithZeroPointReasonsTitle' | abpLocalization
  }}</b></ng-template
>

<ng-template #reasonListTemplate let-evaluation="evaluation">
  <div *ngFor="let reason of evaluation.zeroPointReasons">{{ reason.reason.text }}</div>
</ng-template>
