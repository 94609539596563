import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Identity.AgentList.FilterModeOption',
})
export class AgentListFilterModeOption {
  @GenericLookup({
    code: 'Identity.AgentList.FilterModeOption.SelectedAgents',
  })
  static SelectedAgents: number;

  @GenericLookup({
    code: 'Identity.AgentList.FilterModeOption.AllAgentsExceptSelectedAgent',
  })
  static AllAgentsExceptSelectedAgent: number;
}
