import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { OperatorQueryItemDto } from 'src/core/models/query/operator-query-item.dto';
import { LogicalOperators } from 'src/core/models/enum/logical-operators.enum';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';

@Component({
  selector: 'ca-query-tree-node-operator',
  templateUrl: './query-tree-node-operator.component.html',
  styleUrls: ['./query-tree-node-operator.component.scss'],
})
export class QueryTreeNodeOperatorComponent extends QueryEditorBase implements OnInit {
  payload: OperatorQueryItemDto;
  @ViewChildren('operatorContainer') operatorContainers: QueryList<ElementRef>;

  get logicalOperatorArr() {
    return [this.logicalOperators.And, this.logicalOperators.Or];
  }

  onOperatorChange(eventArgs: any) {
    this.node.isDirty = true;
    const input = eventArgs.currentTarget as HTMLInputElement;
    // tslint:disable-next-line: radix
    const value = parseInt(input.value);
    const siblings = this.node.parentNode
      ? this.node.parentNode.children
      : this.queryBuilder.treeData;

    this.payload.value = value;

    // we are sure that changeable operator has the index 0, so start by 1.
    for (let i = 1; i < siblings.length; i++) {
      const otherNode = siblings[i];

      // find operator nodes
      if (otherNode.category.id === this.node.category.id) {
        const payload = otherNode.categoryItem.payload as OperatorQueryItemDto;
        payload.value = value;
      }
    }
  }

  constructor(
    private logicalOperators: LogicalOperators,
    public queryFieldDataType: QueryFieldDataType
  ) {
    super();
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as OperatorQueryItemDto;
    this.payload.value ??= this.logicalOperators.And;
    this.node.validationStatus = true;
  }
}
