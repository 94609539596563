import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';

@Component({
  selector: 'ca-query-index-aborted-notification',
  templateUrl: './query-index-aborted-notification.component.html',
  styleUrls: ['./query-index-aborted-notification.component.scss'],
})
@NotificationType('QueryIndexAbortedNotification', QueryIndexAbortedNotificationComponent)
export class QueryIndexAbortedNotificationComponent implements OnInit {
  data: NotificationDto;

  get queryName(): string {
    return this.data ? JSON.parse(this.data.payload).queryName : '';
  }

  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'Query::IndexingAbortedNotification',
      parsedPayload.queryName
    );

    toastr.warn(message);
  }
}
