import { Component, OnInit } from '@angular/core';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { ConversationImportWizardService } from './conversation-import-wizard.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { StepDefinition } from 'src/core/models/call/step-definition.model';
import { ConversationImportCommunicationService } from './conversation-import-communication.service';
import { Observable } from 'rxjs';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { PermissionService } from '@abp/ng.core';
import { CallImportStepOne } from './steps/call-steps/step-one/call-import-step-one.component';
import { CallImportStepThree } from './steps/call-steps/step-three/call-import-step-three.component';
import { CallImportStepTwo } from './steps/call-steps/step-two/call-import-step-two.component';
import { ChatImportStepOne } from './steps/chat-steps/step-one/chat-import-step-one.component';
import { ChatImportStepThree } from './steps/chat-steps/step-three/chat-import-step-three.component';
import { ChatImportStepTwo } from './steps/chat-steps/step-two/chat-import-step-two.component';
import { ConversationImportStepZero } from './steps/step-zero/conversation-import-step-zero.component';

@Component({
    selector: 'ca-conversation-import',
    templateUrl: './conversation-import.component.html',
    styleUrls: ['./conversation-import.component.scss'],
})
export class ConversationImportComponent implements OnInit {
    thisRef: ConversationImportComponent;
    config: NgWizardConfig;
    stepDefinitions: StepDefinition[];

    selectedTheme: THEME;
    selectedStepIndex: number;

    preButtonHidden: boolean = true;
    showNextButton: boolean = false;
    showImportButton: boolean = false;
    isStepThree: boolean = false;
    conversationImportWizardService: ConversationImportWizardService;

    canImportConversation: boolean = false;
    cancelImport: boolean = false;

    progressValue: number = 0;

    stepStates = {
        normal: STEP_STATE.normal,
        disabled: STEP_STATE.disabled,
        error: STEP_STATE.error,
        hidden: STEP_STATE.hidden
    };

    get closeButtonDisabled(): boolean {
        return this.progressValue < 100;
    }

    constructor(
        private ngWizardService: NgWizardService,
        conversationImportWizardService: ConversationImportWizardService,
        private modalService: NgbModal,
        config: NgbModalConfig,
        private confirmationService: ConfirmationService,
        private communicationService: ConversationImportCommunicationService,
        private permissionService: PermissionService
    ) {
        this.thisRef = this;
        this.config = conversationImportWizardService.config;
        this.stepDefinitions = conversationImportWizardService.stepDefinitions;
        this.conversationImportWizardService = conversationImportWizardService;
        config.backdrop = 'static';
        config.keyboard = false;
        this.canImportConversation = this.permissionService.getGrantedPolicy('Conversation.ConversationImport');
    }

    ngOnInit() {
        this.selectedStepIndex = this.config.selected;
    }

    stepChanged(args: StepChangedArgs) {
        this.selectedStepIndex = args.step.index;

        this.showNextButton = this.selectedStepIndex === 0 ||
            this.selectedStepIndex === this.stepDefinitions.length - 3 ||
            this.selectedStepIndex === this.stepDefinitions.length - 6;

        this.preButtonHidden = this.selectedStepIndex <= 0 || (this.selectedStepIndex % 3 == 0);

        this.showImportButton = this.selectedStepIndex === this.stepDefinitions.length - 5 || this.selectedStepIndex === this.stepDefinitions.length - 2;
        this.isStepThree = this.selectedStepIndex === this.stepDefinitions.length - 1 || this.selectedStepIndex === this.stepDefinitions.length - 4;

        if (this.selectedStepIndex == 0) {
            this.communicationService.isFilesSelected = false;
            this.communicationService.isFilesValid = false;
        }
    }

    onChangeStepState(){
        if((!this.isFilesSelected() || !this.isFilesValid())){
            return this.stepStates.disabled;
        }

        return this.stepStates.normal;
    }

    showPreviousStep(event?: Event) {
        this.ngWizardService.previous();
    }

    showNextStep(event?: Event) {
        this.ngWizardService.next();
    }

    onImport(event?: Event) {
        this.ngWizardService.next();
    }

    openCallImportModal(content) {
        this.cancelImport = false;
        this.modalService.open(content, { size: 'lg', scrollable: true });
    }

    onCloseModal() {
        this.modalService.dismissAll();
    }

    onCancelModal() {
        this.cancelImport = true;
        this.confirmationService
            .warn('Conversation::CancelImportPhraseConfirmationMessage',
                'Conversation::CancelImportPhraseConfirmationQuestion', {
                yesText: '::Cancel',
                cancelText: '::Stay'
            })
            .subscribe((status: Confirmation.Status) => {
                if (status === Confirmation.Status.confirm) {
                    this.modalService.dismissAll();
                }
                else {
                    this.cancelImport = false;
                }
            }
            );
    }

    isFilesSelected() {
        return this.communicationService.isFilesSelected;
    }

    isFilesValid() {
        return this.communicationService.isFilesValid;
    }

    isCallImport() {
        return this.communicationService.isCallImport;
    }

    validateStepZero(stepZeroCmp: ConversationImportStepZero, stepOneCmp: CallImportStepOne | ChatImportStepOne, stepTwoCmp: CallImportStepTwo | ChatImportStepTwo,
        type: string, eventArgs: StepValidationArgs): Observable<boolean> {
        if (this.isCallImport()) {
            return this.conversationImportWizardService.validateStepZero(stepZeroCmp, stepOneCmp as CallImportStepOne, stepTwoCmp as CallImportStepTwo,
                type, eventArgs);
        }
        return this.conversationImportWizardService.validateStepZero(stepZeroCmp, stepOneCmp as ChatImportStepOne, stepTwoCmp as ChatImportStepTwo,
            type, eventArgs);
    }

    validateStepOne(stepOneCmp: CallImportStepOne | ChatImportStepOne, stepTwoCmp: CallImportStepTwo | ChatImportStepTwo,
        type: string, eventArgs: StepValidationArgs): Observable<boolean> {
        if (this.isCallImport()) {
            return this.conversationImportWizardService.validateStepOne(stepOneCmp as CallImportStepOne, stepTwoCmp as CallImportStepTwo, type, eventArgs);
        }
        return this.conversationImportWizardService.validateStepOne(stepOneCmp as ChatImportStepOne, stepTwoCmp as ChatImportStepTwo, type, eventArgs);
    }

    validateStepTwo(stepOneCmp: CallImportStepOne | ChatImportStepOne, stepTwoCmp: CallImportStepTwo | ChatImportStepTwo,
        stepThreeCmp: CallImportStepThree | ChatImportStepThree, type: string, eventArgs: StepValidationArgs): Observable<boolean> {
        if (this.isCallImport()) {
            return this.conversationImportWizardService.validateStepTwo(stepOneCmp as CallImportStepOne, stepTwoCmp as CallImportStepTwo,
                stepThreeCmp as CallImportStepThree, type, eventArgs);
        }
        return this.conversationImportWizardService.validateStepTwo(stepOneCmp as ChatImportStepOne, stepTwoCmp as ChatImportStepTwo,
            stepThreeCmp as ChatImportStepThree, type, eventArgs);
    }

    validateStepThree(stepTwoCmp: CallImportStepTwo | ChatImportStepTwo, stepThreeCmp: CallImportStepThree | ChatImportStepThree,
        type: string, eventArgs: StepValidationArgs): Observable<boolean> {
        if (this.isCallImport()) {
            return this.conversationImportWizardService.validateStepThree(stepTwoCmp as CallImportStepTwo, stepThreeCmp as CallImportStepThree, type, eventArgs);
        }
        return this.conversationImportWizardService.validateStepThree(stepTwoCmp as ChatImportStepTwo, stepThreeCmp as CallImportStepThree, type, eventArgs);
    }

    changeProgressValue(eventArgs: { progressValue: number }) {
        this.progressValue = eventArgs.progressValue;
    }
}
