import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[form-row]',
})
export class FormRowDirective {
  @Input()
  visible = true;

  constructor(public templateRef: TemplateRef<any>) {}
}
