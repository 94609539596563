import { Subject } from 'rxjs';

export function AutoUnsubscribe() {
  return function (constructor) {
    const base = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {
      const notifier = this['autoUnsubscribeNotifier'] as any;

      if (notifier) {
        notifier.next();
        notifier.complete();
      }

      if (typeof base === 'function') {
        base.apply(this);
      }
    };
  };
}
