<ca-user-detail-toolbar
  #toolbar
  [user]="user"
  [processing]="form.processing"
  (saveRequest)="form.onSaveRequested($event.closeAfterSave)"
  (deleteRequest)="form.confirmDeletion()"></ca-user-detail-toolbar>
<div class="row split-panel-container full-height">
  <as-split
    [unit]="splitConfig.unit"
    [direction]="splitConfig.direction"
    [useTransition]="splitConfig.useTransition"
    [gutterSize]="splitConfig.gutterSize"
    #split="asSplit">
    <as-split-area
      [size]="(layout$ | async)?.config.west.size"
      [minSize]="(layout$ | async).config.west.minSize"
      [maxSize]="(layout$ | async).config.west.maxSize"
      [visible]="(layout$ | async).config.west.visible"
      [order]="1"
      style="overflow-y: hidden; border-right: 1px solid rgba(0, 0, 0, 0.12)">
      <ca-user-summary #summary></ca-user-summary>
    </as-split-area>
    <as-split-area
      [size]="(layout$ | async)?.config.center.size"
      [minSize]="(layout$ | async).config.center.minSize"
      [maxSize]="(layout$ | async).config.center.maxSize"
      [visible]="(layout$ | async).config.center.visible"
      [order]="2">
      <ca-user-form
        (saveUserRequested)="onSaveUserRequested($event)"
        (deleteUserRequested)="onDeleteUserRequested($event)"
        #form
        style="height: 100%"></ca-user-form>
    </as-split-area>
  </as-split>
</div>
