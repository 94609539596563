import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserPhotoComponent } from 'src/ca-shared/user-photo/user-photo.component';
import { UserDto } from 'src/core/models/shared/user.dto';

@Component({
  selector: 'ca-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss'],
})
export class UserSummaryComponent implements OnInit {
  private _user: UserDto;

  tempProfilePictureFile: any;
  profilePictureFile: any;
  photoUploadIsDefault = false;

  @ViewChild('userProfilePhoto')
  userProfilePhoto: UserPhotoComponent;

  @Input()
  set user(value: UserDto) {
    this._user = value;
  }
  get user(): UserDto {
    return this._user;
  }
  get photoBorderColor(): string {
    return this.user.extraProperties.IsActive ? 'green' : '#9e9e9e';
  }

  constructor() {
    this.user = new UserDto();
    this.user.extraProperties.IsActive = false;
  }

  loadUser(userArg: UserDto | null) {
    if (userArg != null) {
      this.user = userArg;
    }
  }

  ngOnInit(): void {}
}
