<div class="ca-toolbar" style="padding-right: 0px">
  <div class="ca-toolbar-west-section"></div>
  <div class="ca-toolbar-east-section expanded">
    <div class="toolbar-item">
      <button
        class="btn btn-outline-primary btn-sm toolbar-button"
        title="{{ '::ExpandAll' | abpLocalization }}"
        (click)="onExpandAllClick($event)">
        <i class="fas fa-plus-square"></i>
      </button>
    </div>
    <div class="toolbar-item">
      <button
        class="btn btn-outline-primary btn-sm toolbar-button"
        title="{{ '::CollapseAll' | abpLocalization }}"
        (click)="onCollapseAllClick($event)">
        <i class="fas fa-minus-square"></i>
      </button>
    </div>
    <div class="toolbar-item" [hidden]="!multiple">
      <button
        class="btn btn-outline-success btn-sm toolbar-button"
        title="{{ '::SelectAll' | abpLocalization }}"
        (click)="onSelectAllClick($event)">
        <i class="fas fa-check-square"></i>
      </button>
    </div>
    <div class="toolbar-item last">
      <button
        class="btn btn-outline-danger btn-sm toolbar-button"
        title="{{ '::Clear' | abpLocalization }}"
        (click)="onClearClick($event)">
        <ca-delete-icon></ca-delete-icon>
      </button>
    </div>
  </div>
</div>
