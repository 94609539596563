import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConversationImportFileModel } from 'src/core/models/conversation/conversation-import-file-model';
import { ConversationImportCommunicationService } from '../../../conversation-import-communication.service';

@Component({
  selector: 'ca-call-import-step-one',
  templateUrl: './call-import-step-one.component.html',
  styleUrls: ['./call-import-step-one.component.scss'],
})
export class CallImportStepOne implements OnInit {
  @ViewChild('fileInput')
  fileInputVariable: ElementRef;

  public selectedConversationFiles: ConversationImportFileModel[] = [];
  selectedFileSizes: number = 0;
  acceptFileExtensions: string[] = [
    '.wav',
    '.mp3',
    '.ssf',
    '.8svx',
    '.aif',
    '.aifc',
    '.aiff',
    '.aiffc',
    '.al',
    '.amb',
    '.amr-nb',
    '.amr-wb',
    '.anb',
    '.au',
    '.avr',
    '.awb',
    '.cdda',
    '.cdr',
    '.cvs',
    '.cvsd',
    '.cvu',
    '.dat',
    '.dvms',
    '.f32',
    '.f4',
    '.f64',
    '.f8',
    '.fssd',
    '.gsm',
    '.gsrt',
    '.hcom',
    '.htk',
    '.ima',
    '.ircam',
    '.la',
    '.lpc',
    '.lpc10',
    '.lu',
    '.maud',
    '.mp2',
    '.mp3',
    '.nist',
    '.ogg',
    '.opus',
    '.prc',
    '.raw',
    '.s1',
    '.s16',
    '.s2',
    '.s24',
    '.s3',
    '.s32',
    '.s4',
    '.s8',
    '.sb',
    '.sf',
    '.sl',
    '.sln',
    '.smp',
    '.snd',
    '.sndr',
    '.sndt',
    '.sou',
    '.sox',
    '.sph',
    '.sw',
    '.txw',
    '.u1',
    '.u16',
    '.u2',
    '.u24',
    '.u3',
    '.u32',
    '.u4',
    '.u8',
    '.ub',
    '.ul',
    '.uw',
    '.vms',
    '.voc',
    '.vorbis',
    '.vox',
    '.wav',
    '.wavpcm',
    '.wv',
    '.wve',
    '.xa',
  ];
  fileList: FileList;
  infoLabel: string = '';
  maxCallFileSize: number = 500 * 1024 * 1024;

  constructor(
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private communicationService: ConversationImportCommunicationService
  ) {
    this.infoLabel = this.localizationService.instant('Conversation::UploadCallStepInfo');
    this.infoLabel = this.infoLabel.replace(
      '{0}',
      '<b>' + this.localizationService.instant('Conversation::UploadCallMaxSize') + '</b>'
    );
  }

  ngOnInit(): void {
    this.communicationService.isFilesSelected = false;
    this.communicationService.isFilesValid = false;
  }

  ngOnDestroy(): void {
    this.communicationService.isFilesSelected = false;
    this.communicationService.isFilesValid = false;
  }

  onFileChange(files: FileList) {
    if (files.length > 1000) {
      this.toastr.error(
        this.localizationService.instant('Conversation::ConversationImportFileLimit')
      );
      return;
    }
    this.selectedConversationFiles = [];
    this.selectedFileSizes = 0;
    this.fileList = files;
    this.communicationService.isFilesSelected = files.length > 0;

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      const splitName = file.name.split('.');
      let isValid = true;
      let invalidMessage = '';
      if (this.acceptFileExtensions.indexOf('.' + splitName[splitName.length - 1]) <= -1) {
        isValid = false;
        invalidMessage = this.localizationService.instant('::FileTypeNotAllowed');
      }
      if (file.size > this.maxCallFileSize) {
        isValid = false;
        invalidMessage = this.localizationService.instant('Conversation::FileSizeTooLarge');
      }
      this.selectedFileSizes += this.getSize(file.size);
      this.selectedConversationFiles.push(
        new ConversationImportFileModel(i, file, isValid, invalidMessage)
      );
    }

    this.communicationService.isFilesValid = this.isCallFilesValid();
  }

  validateEntryToStep() {
    return true;
  }

  validateExitFromStep() {
    if (this.selectedFileSizes > 0) {
      return true;
    } else {
      return false;
    }
  }

  removeFile(data) {
    this.selectedConversationFiles = this.selectedConversationFiles.filter(x => x.id != data.id);
    this.selectedFileSizes -= this.getSize(data.file.size);
    this.communicationService.isFilesSelected = this.selectedConversationFiles.length > 0;
    this.communicationService.isFilesValid = this.isCallFilesValid();
    this.fileInputVariable.nativeElement.value = '';
  }

  getSize(num) {
    return num / 1024 / 1024;
  }

  isCallFilesValid() {
    let invalidFileCount = this.selectedConversationFiles.filter(f => !f.isValid).length;
    if (invalidFileCount == 0) {
      return true;
    }
    return false;
  }
}
