import { GenericLookupDto } from '../generic-lookup/generic-lookup.dto';

export class CallAnalysisDto {
  error: string;
  agentTensionRatio: number;
  agentMonotonicityRatio: number;
  agentInterruptionCountPerMinute: number;
  agentSpeed: number;
  agentBlockCountPerMinute: number;
  agentBlockRatio: number;
  agentHesitationCountPerMinute: number;
  customerTensionRatio: number;
  customerShare: number;
  customerInterruptionCountPerMinute: number;
  customerGenderId: number;
  customerBlockCountPerMinute: number;
  customerBlockRatio: number;
  callOverlapDuration: number;
  callOverlapRatio: number;
  callMaxOverlapDuration: number;
  callMaximumSilenceDuration: number;
  callMaximumSilenceStart: number;
  callMaximumSilenceEnd: number;
  callSilenceRatio: number;
  callSilenceDurationBeginning: number;
  callSilenceDurationEnd: number;
  callId: number;
  callHoldCount: number;
  callTotalHoldDuration: number;
  callMaxHoldDuration: number;
  callIvrHoldCount: number;
  callTotalIvrHoldDuration: number;
  callMaxIvrHoldDuration: number;
  statusId: number;
  status: GenericLookupDto;
  customerGender: GenericLookupDto;
  mostUsedWord1: string;
  mostUsedWordCount1: number;
  mostUsedWord2: string;
  mostUsedWordCount2: number;
  mostUsedWord3: string;
  mostUsedWordCount3: number;
}
