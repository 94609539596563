import { NgModule } from '@angular/core';
import { ObjectParserPipe } from './pipes/object-parser.pipe';
import { OperatorPipe } from './pipes/operator.pipe';
import { LogicalOperatorPipe } from './pipes/logical-operator.pipe';
import { CADatePipe } from './pipes/ca-date.pipe';
import { ReasonTextPipe } from './pipes/reason-text-pipe';
import { EmailReceiverPipe } from './pipes/email-receiver-pipe';
import { TimeSincePipe } from './pipes/time-since.pipe';
import { CATimePipe } from './pipes/ca-time.pipe';
import { CAUpperCasePipe } from './pipes/ca-upper-case.pipe';
import { CANumberPipe } from './pipes/ca-number.pipe';
import { TrimPipe } from './pipes/trim.pipe';

@NgModule({
  declarations: [
    ObjectParserPipe,
    OperatorPipe,
    LogicalOperatorPipe,
    CADatePipe,
    TimeSincePipe,
    CATimePipe,
    CAUpperCasePipe,
    ReasonTextPipe,
    EmailReceiverPipe,
    CANumberPipe,
    TrimPipe,
  ],
  exports: [
    ObjectParserPipe,
    OperatorPipe,
    LogicalOperatorPipe,
    CADatePipe,
    TimeSincePipe,
    CATimePipe,
    CAUpperCasePipe,
    ReasonTextPipe,
    EmailReceiverPipe,
    CANumberPipe,
    TrimPipe,
  ],
  imports: [],
})
export class CoreModule {}
