import { State, Action, StateContext, Selector } from '@ngxs/store';
import { FilterChange, ViewModeChange } from 'src/core/actions/analysis/trend-analysis.actions';
import { Injectable } from '@angular/core';
import { TrendAnalysisStateModel } from 'src/core/models/analysis/trend-analysis/trend-analysis.state-model';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { GenericState } from '../generic/generic.state';
const name = 'TrendAnalysisState';
@State<TrendAnalysisStateModel>({
  name: name,
  defaults: {
    period: {},
    queries: {},
  } as TrendAnalysisStateModel,
})
@Injectable()
export class TrendAnalysisState extends GenericState<TrendAnalysisState> {
  @Selector()
  static getFilter(state: TrendAnalysisStateModel): any {
    return state.period;
  }

  @Action(FilterChange)
  changeFilter(ctx: StateContext<TrendAnalysisStateModel>, action: FilterChange) {
    const currentState = ctx.getState();
    if (!currentState.filters) {
      currentState.filters = {};
    }
    currentState.filters = action.filters;
    this.userSettingsService.patchState(ctx, currentState, name);
  }
  constructor(userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;

    this.userSettingsService.register(this.name, this);
  }

  @Action(ViewModeChange)
  changeViewMode(ctx: StateContext<TrendAnalysisStateModel>, action: ViewModeChange) {
    const currentState = ctx.getState();
    if (!currentState.numberMode) {
      currentState.numberMode = true;
    }
    currentState.numberMode = action.numberMode;
    this.userSettingsService.patchState(ctx, currentState, name);
  }
}
