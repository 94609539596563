<div *ngIf="loading == false">
  <div class="form-group">
    <label for="dateSelector" class="col-form-label col-sm-5 form-label"
      >{{ '::DateRange' | abpLocalization }}
    </label>
    <div class="col-sm-8">
      <label
        >{{ filterData?.startTime | caDatePipe }} - {{ filterData?.endTime | caDatePipe }}</label
      >
    </div>
  </div>
  <div *ngIf="filterData?.filterType === 'UniqueCustomerKey'" class="form-group">
    <label class="col-form-label col-sm-5 form-label"
      >{{ 'Conversation::UniqueCustomerKey' | abpLocalization }}
    </label>
    <div class="col-sm-6">
      <label>{{ filterData?.uniqueCustomerKey }} </label>
    </div>
  </div>
  <div *ngIf="filterData?.filterType === 'CalledOrCallerNumber'" class="form-group">
    <label class="col-form-label col-sm-5 form-label"
      >{{ 'Conversation::CalledOrCallerNumber' | abpLocalization }}
    </label>
    <div class="col-sm-6">
      <label>{{ filterData?.calledOrCallerNumber }} </label>
    </div>
  </div>
</div>
<div class="form-group" *ngIf="conversationCount > 0 && loading == false">
  <div class="col" [innerHtml]="deleteConversationInformationText"></div>
  <div class="col">
    <input class="confirmation-input mt-2" type="text" [(ngModel)]="deleteConfirmationText" (ngModelChange)="deleteConfirmationTextChange($event)" /></div>
</div>

<div class="form-group" *ngIf="conversationCount === 0 && loading == false">
  <div class="col">
    {{ 'Conversation::NoConversationFoundToDelete' | abpLocalization }}
  </div>
</div>
