import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { DateSelectionPanelComponent } from 'src/ca-shared/filter-panel/children/date-selection/date-selection-panel/date-selection-panel.component';
import { DateSelectionValueModel } from 'src/ca-shared/filter-panel/children/date-selection/models/date-selection-value.model';
import { DateSelectionConfigurationModel } from 'src/ca-shared/filter-panel/filter-panel.module';
import { Editor } from 'src/ca-shared/filter-panel/models/editor.enum';
import { ConversationCleanerFilterDto } from 'src/core/models/conversation/cleaner/conversation-cleaner-filter.dto';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { ConversationCleanerCommunicationService } from '../../conversation-cleaner-communication.service';

@Component({
  selector: 'ca-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss'],
})
export class StepOneComponent implements OnInit, AfterViewInit {
  @ViewChild('dateSelector')
  dateSelectionPanel: DateSelectionPanelComponent;
  filterType: any;
  filterTypes: string[] = ['UniqueCustomerKey', 'CalledOrCallerNumber'];

  dateSelectionConfiguration: DateSelectionConfigurationModel = {
    key: 'date',
    editor: Editor.Date,
    minDate: this.globalSettingsService.systemStartDate,
    maxDate: this.globalSettingsService.systemEndDate,
    useNoEndDate: false,
    useShortcuts: false,
    defaultValue: {
      shortcut: 0,
      start: null,
      end: null,
    },
  };
  form: FormGroup;

  @ViewChild('deleteConversationForm') deleteConversationForm: FormGroupDirective;

  constructor(
    private fb: FormBuilder,
    private globalSettingsService: GlobalSettingsService,
    private conversationCleanerCommunicationService: ConversationCleanerCommunicationService
  ) {
    this.form = this.fb.group({
      filterType: [-1],
      uniqueCustomerKey: [null, { validators: [Validators.required], updateOn: 'change' }],
      calledOrCallerNumber: [null, { validators: [Validators.required], updateOn: 'change' }],
    });
  }

  ngAfterViewInit(): void {
    const today = new Date();
    const dateValue: DateSelectionValueModel = {
      shortcut: null,
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0),
      end: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999),
      noEndDate: true,
    };

    this.dateSelectionPanel.value = dateValue;
    this.form.controls['calledOrCallerNumber'].disable();
    this.form.controls['uniqueCustomerKey'].disable();
    this.conversationCleanerCommunicationService.validForm = this.form.valid;
    this.form.statusChanges.subscribe(() => {
      this.conversationCleanerCommunicationService.validForm = this.form.valid;
    });
  }

  ngOnInit(): void {}

  onFilterTypeChange(eventArgs) {
    this.form.get('uniqueCustomerKey').patchValue('');
    this.form.get('calledOrCallerNumber').patchValue('');
    if (eventArgs === 'UniqueCustomerKey') {
      this.form.controls['calledOrCallerNumber'].disable();
      this.form.controls['uniqueCustomerKey'].enable();
    } else if (eventArgs === 'CalledOrCallerNumber') {
      this.form.controls['calledOrCallerNumber'].enable();
      this.form.controls['uniqueCustomerKey'].disable();
    } else {
      this.form.controls['calledOrCallerNumber'].disable();
      this.form.controls['uniqueCustomerKey'].disable();
    }
  }

  validateEntryToStep() {
    return true;
  }

  validateExitFromStep() {
    if (
      this.form.get('filterType').value === 'UniqueCustomerKey' ||
      this.form.get('filterType').value === 'CalledOrCallerNumber'
    ) {
      this.form.controls['calledOrCallerNumber'].updateValueAndValidity();
      this.form.controls['uniqueCustomerKey'].updateValueAndValidity();
    }

    if (this.form.valid) {
      return of(true);
    } else {
      return of(false);
    }
  }

  submit() {
    this.deleteConversationForm.ngSubmit.emit();
  }

  changeFilterType(filterType: string) {
    let uniqueCustomerKeyControl = this.form.get('uniqueCustomerKey');
    let calledOrCallerNumberControl = this.form.get('calledOrCallerNumber');
    if (filterType === 'UniqueCustomerKey') {
      calledOrCallerNumberControl.clearValidators();
      calledOrCallerNumberControl.setValue('');
      uniqueCustomerKeyControl.setValidators(Validators.required);
      uniqueCustomerKeyControl.markAsDirty();
    } else if (filterType === 'CalledOrCallerNumber') {
      uniqueCustomerKeyControl.clearValidators();
      uniqueCustomerKeyControl.setValue('');
      calledOrCallerNumberControl.setValidators(Validators.required);
      calledOrCallerNumberControl.markAsDirty();
    }
  }

  getData(): ConversationCleanerFilterDto {
    let formData = new ConversationCleanerFilterDto();
    formData.startTime = this.dateSelectionPanel.value.start;
    formData.endTime = this.dateSelectionPanel.value.end;
    formData.uniqueCustomerKey = this.form.controls.uniqueCustomerKey.value;
    formData.calledOrCallerNumber = this.form.controls.calledOrCallerNumber.value;
    formData.filterType = this.form.controls.filterType.value;

    return formData;
  }
}
