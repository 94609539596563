<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="customerKey">
    <label for="customerKey">
      {{ 'Settings::PuzzelIntegrationCustomerKey' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="customerKey"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="userName">
    <label for="userName">{{ 'Settings::PuzzelIntegrationUserName' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="userName" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="password">
    <label for="password">{{ 'Settings::PuzzelIntegrationPassword' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="password" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="ftpUserName">
    <label for="ftpUserName">
      {{ 'Settings::PuzzelIntegrationFtpUserName' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="ftpUserName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="ftpPassword">
    <label for="ftpPassword">
      {{ 'Settings::PuzzelIntegrationFtpPassword' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="ftpPassword"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="ftpRecordingPath">
    <label for="ftpRecordingPath">
      {{ 'Settings::PuzzelIntegrationFtpRecordingPath' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="ftpRecordingPath"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="fileExtension">
    <label for="fileExtension">
      {{ 'Settings::PuzzelIntegrationFileExtension' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="fileExtension"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="adminUserId">
    <label for="adminUserId">
      {{ 'Settings::PuzzelIntegrationAdminUserId' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="adminUserId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="sourceName">
    <label for="sourceName">{{ 'Settings::PuzzelIntegrationSourceName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sourceName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="migrationStartDate">
    <label for="migrationStartDate">
      {{ 'Settings::PuzzelIntegrationMigrationStartDate' | abpLocalization }}
    </label>
    <input
      type="text"
      placeholder="2023-01-31"
      class="form-control form-control-sm"
      id="migrationStartDate"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
