import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Input
} from '@angular/core';
import { ConversationLayoutService } from 'src/core/services/conversation/conversation-layout.service';
import { HttpClient } from '@angular/common/http';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { AttachedDataValueDto } from 'src/core/models/administration/attached-data/attached-data-value.dto';
import { FileUploadService } from 'src/core/services/fileupload/fileupload.service';
import { ConversationCommentInputDto } from 'src/core/models/comment/comment-input.dto';
import { ConversationCommentDto } from 'src/core/models/comment/comment.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { ConfigStateService } from '@abp/ng.core';
import { ChatTranscriptDto } from 'src/core/models/chat/chat-transcript.dto';
import { ChatDto } from 'src/core/models/chat/chat.dto';
import { CrudService } from 'src/core/services/crud/crud.service';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { LocalizationService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';

export interface Comment {
  user: string;
  comment: string;
  datetime: Date;
}

@Component({
  selector: 'ca-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.scss'],
})
export class ChatFormComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('transcriptContainer', {
    read: ViewContainerRef,
  })
  transcriptContainer: ViewContainerRef;

  @Input()
  conversation: ConversationDto;

  userPhotoComponentStyles: any = { 'width': '48px', 'height': '48px', 'border': '2px solid black', 'border-radius': '100%' };
  attachedDataDisplayedColumns: string[] = ['name', 'value'];
  attachedDataDataSource: AttachedDataValueDto[];
  conversationComment: ConversationCommentInputDto;
  commentText: string;
  userId: string;
  comments: ConversationCommentDto[] = [];
  categoryCount = 0;
  hasTranscript = false;

  constructor(
    private conversationLayoutService: ConversationLayoutService,
    private http: HttpClient,
    private fileUploadService: FileUploadService,
    private configStateService: ConfigStateService,
    private conversationService: ConversationService,
    private crudService: CrudService,
    private confirmationService: ConfirmationService,
    private localizationService: LocalizationService
  ) {


  }

  ngOnInit() {
    this.userId = this.configStateService.getDeep('currentUser.id');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['conversation'].currentValue != null) {
      //get comments of conversation
      this.getCommentsByConversationId(changes['conversation'].currentValue['id'] as number);

      this.attachedDataDataSource =
        changes['conversation'].currentValue['attachedData'];

      if (this.conversation.typeId == ConversationType.chat) {
        this.crudService
          .getById<ChatTranscriptDto[]>(ChatDto, this.conversation.chat.id, 'transcript')
          .subscribe((response) => {
            if (response.length > 0) {
              this.hasTranscript = true;
              this.renderTranscript(response);
            }
            else {
              this.hasTranscript = false;
            }
          });
      }
    }
  }

  ngAfterViewInit() { }

  renderTranscript(data: any[]) {
    let htmlToAdd = '';
    const currentLang = this.localizationService.currentLang;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < data.length; i++) {
      const curr = data[i];
      const prev = data[i - 1];
      const next = data[i + 1];

      const align = curr.channel === 1 ? 'flex-start' : 'flex-end';
      const channelText = curr.channel === 1 ? this.localizationService.instant('Conversation::Agent') : this.localizationService.instant('Conversation::Customer');
      const channelColor = curr.channel === 1 ? 'black' : 'silver';
      const style =
        curr.channel === 1
          ? // tslint:disable-next-line: max-line-length
          'border: 1px solid silver; max-width: 70%; margin-left: 0px; margin-right: auto; border-radius: 10px; background-color: rgb(33, 150, 243); color: white; padding: 10px; font-size: 0.9em'
          : // tslint:disable-next-line: max-line-length
          'border: 1px solid silver; max-width: 70%; margin-right: 0px; margin-left: auto; border-radius: 10px; background-color: rgb(156, 39, 176); color: white; padding: 10px; text-align: left; font-size: 0.9em';

      if (i === 0 || curr.channel !== prev.channel) {

        const textTime = new Date(curr.timeStamp).toLocaleTimeString(currentLang);

        htmlToAdd +=
          '<div style="width: 100%; margin-top: 8px; dis: flex; flex-direction: row; justify-content: ' +
          align +
          '"><div style="' +
          style +
          '"><small style="color: ' +
          channelColor +
          '; float: right;">' +
          textTime +
          '</small><br />' +
          '<small style="color: ' +
          channelColor +
          '">' +
          channelText +
          '</small><br />';
      }

      htmlToAdd += '<span> ' + curr.line + '</span>';

      if (i === data.length - 1 || curr.channel !== next.channel) {
        htmlToAdd += '</div></div>';
      }
    }

    this.transcriptContainer.element.nativeElement.insertAdjacentHTML('afterbegin', htmlToAdd);
  }

  showTab(eventArgs) {
    const el = eventArgs.currentTarget;
    const targetTabId = el.getAttribute('data-target-tab-id');
    const allItems = document.querySelectorAll(
      'a.list-group-item'
    ) as NodeListOf<HTMLElement>;
    const tabPanes = document.querySelectorAll(
      'div.tab-pane'
    ) as NodeListOf<HTMLElement>;
    const targetTabPane = document.querySelector(
      'div[data-tab-id="' + targetTabId + '"]'
    ) as HTMLElement;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < allItems.length; i++) {
      allItems[i].classList.remove('active');
    }

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < tabPanes.length; i++) {
      tabPanes[i].classList.remove('show');
      tabPanes[i].style.display = 'none';
    }

    el.classList.add('active');
    targetTabPane.classList.add('show');
    targetTabPane.style.display = 'initial';

    return false;
  }

  closePanel() {
    this.conversationLayoutService.changeVisibilityForDetailPanel(false);
  }

  getCommentsByConversationId(id: number) {
    return this.conversationService.getCommentsByConversationId(id).subscribe((data) => {
      this.comments = data;
    });
  }

  addComment(eventArgs, edtComment: HTMLTextAreaElement) {
    const comment = edtComment.value;
    this.conversationComment = new ConversationCommentInputDto();
    this.conversationComment.conversationId = this.conversation.id;
    this.conversationComment.comment = comment;
    this.conversationService.addComment(this.conversationComment).subscribe((data) => {
      this.comments.unshift(data);
      edtComment.value = '';
    });
  }

  deleteComment(id: number, commentId: number) {
    this.conversationService.deleteComment(id, commentId).subscribe((data) => {
      this.getCommentsByConversationId(id);
    });
  }

  confirmDeletion(conversationComment, eventArgs) {
    this.confirmationService
      .warn('::DeletionConfirmationMessage', '', {
        messageLocalizationParams: [this.localizationService.instant('::Comment')],
        yesText: '::Delete',
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.deleteComment(this.conversation.id, conversationComment.id);
        }
      });
  }

  setCategoryCount(event: any) {
    this.categoryCount = event.count;
  }
}
