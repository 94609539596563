<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{
          'Conversation::' + text | abpLocalization
        }}</label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ payload.operator | operator }}</label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ payload.value }}</label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{
          'Conversation::' + text | abpLocalization
        }}</label>
      </div>
      <div class="col-auto">
        <select class="form-select form-select-sm" formControlName="operator">
          <option *ngFor="let operator of node.categoryItem.operators" [value]="operator">
            {{ operator | operator }}
          </option>
        </select>
      </div>
      <div class="col-auto">
        <input type="text" formControlName="term" class="form-control form-control-sm" />
      </div>
      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"
      ></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>
