import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StateContext } from '@ngxs/store';
import { UserStateSettingsDto } from 'src/core/models/configuration-setting/user-state-settings.dto';
@Injectable({
  providedIn: 'root',
})
export class UserStateSettingsService {
  timerMap = {};
  stateList = {};
  debounceMethod: any;

  constructor(private http: HttpClient) {}

  debounce(cb, duration, key) {
    return function (this: any, ...args: any[]) {
      clearTimeout(this.timerMap[key]);
      this.timerMap[key] = setTimeout(
        function () {
          cb.apply(null, args);
        }.bind(this),
        duration
      );
    };
  }

  patchState(ctx: StateContext<any> | StateContext<any[]>, value: any, key: string, debounceTime: number = 3000) {
    ctx.patchState(value);
    this.debounceMethod = this.debounce(
      function () {
        this.setStateSettings(key, JSON.stringify(value));
      }.bind(this),
      debounceTime,
      key
    );
    this.debounceMethod(key, value);
  }

  setState(ctx: StateContext<any> | StateContext<any[]>, value: any, key: string) {
    ctx.setState(value);
    this.debounceMethod = this.debounce(
      function () {
        this.setStateSettings(key, JSON.stringify(value));
      }.bind(this),
      3000,
      key
    );
    this.debounceMethod(key, value);
  }

  setStateSettings(key: string, value: string) {
    let params = { key: key, value: value };
    this.http
      .post(this.getApiUrl() + '/save', params, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .subscribe(data => {});
  }

  resetUserStateSettings() {
    return this.http.delete(this.getApiUrl() + '/reset', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  register(name, stateToRegister): void {
    this.stateList[name] = stateToRegister;
  }

  protected getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase + UserStateSettingsDto.apiUrl;
  }
}
