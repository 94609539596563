import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserPhotoModule } from 'src/ca-shared/user-photo/user-photo.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoreModule } from 'src/core/core.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ConversationIconContainerModule } from 'src/ca-shared/conversation-icon-container/conversation-icon-container.module';
import { EmailSummaryComponent } from './email-summary.component';
import { ConversationCategoryPanelModule } from "../conversation-category-panel/conversation-category-panel.module";

@NgModule({
    declarations: [EmailSummaryComponent],
    exports: [EmailSummaryComponent],
    imports: [
        CommonModule,
        SharedModule,
        UserPhotoModule,
        MatDividerModule,
        ClipboardModule,
        MatTooltipModule,
        CoreModule,
        NgbTooltipModule,
        ConversationIconContainerModule,
        ConversationCategoryPanelModule
    ]
})
export class EmailSummaryModule { }
