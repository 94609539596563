import { Component, OnInit } from '@angular/core';
import { ErrorService } from 'src/core/services/error/error.service';

@Component({
  selector: 'ca-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
  constructor(private errorService: ErrorService) {}

  ngOnInit(): void {
    this.errorService.showError(404);
  }
}
