<form [formGroup]="realTimeSettingsForm" (ngSubmit)="onSubmitAnalysisSettings()">
  <div formGroupName="scenarioSettingsForm">
    <ng-template #scenarioSpeechRatePopContent>
      <p class="text-start h4">
        {{ 'Settings::UsageValueRecommendation' | abpLocalization }}
      </p>
      <div class="text-start">{{ 'Settings::ScenarioSpeechRate' | abpLocalization }}: 12-18</div>
    </ng-template>
    <ng-template #scenarioMonotonicityPopContent>
      <p class="text-start h4">
        {{ 'Settings::UsageValueRecommendation' | abpLocalization }}
      </p>
      <div class="text-start">{{ 'Settings::ScenarioMonotonicity' | abpLocalization }}: 45-65</div>
    </ng-template>

    <div class="row form-row">
      <div class="form-group">
        <label class="col-form-label indicates-required-field">
          {{ ('Settings::ScenarioSpeechRate' | abpLocalization) + ' ' }}
        </label>
        <i
          class="ms-2 fas fa-info-circle"
          [ngbTooltip]="scenarioSpeechRatePopContent"
          container="body"></i>
      </div>
      <div class="range-input">
        <div class="col-3 first">
          <input
            type="number"
            min="0"
            class="form-control form-control-sm"
            id="minSpeechRate"
            formControlName="minSpeechRate" />
        </div>
        <div class="col-3 second">
          <input
            type="number"
            min="0"
            class="form-control form-control-sm"
            id="maxSpeechRate"
            formControlName="maxSpeechRate" />
        </div>
      </div>
    </div>
    <div class="row form-row">
      <div class="form-group">
        <label for="minMonotonicity" class="col-form-label indicates-required-field">
          {{ ('Settings::ScenarioMonotonicity' | abpLocalization) + ' ' }}
        </label>
        <i
          class="ms-2 fas fa-info-circle"
          [ngbTooltip]="scenarioMonotonicityPopContent"
          container="body"></i>
      </div>
      <div class="range-input">
        <div class="col-3 first">
          <input
            type="number"
            min="0"
            max="100"
            class="form-control form-control-sm"
            id="minMonotonicity"
            formControlName="minMonotonicity" />
        </div>
        <div class="col-3 second">
          <input
            type="number"
            min="0"
            max="100"
            class="form-control form-control-sm"
            id="maxMonotonicity"
            formControlName="maxMonotonicity" />
        </div>
      </div>
    </div>
  </div>

  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
