<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="sourceName">
    <label for="sourceName">{{ 'Settings::Five9IntegrationSourceName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sourceName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="tenantName">
    <label for="tenantName">{{ 'Settings::Five9IntegrationTenantName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="tenantName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::Five9IntegrationClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="clientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">
      {{ 'Settings::Five9IntegrationClientSecret' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientSecret"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="supervisorId">
    <label for="supervisorId">
      {{ 'Settings::Five9IntegrationSupervisorId' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="supervisorId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="orgId">
    <label for="orgId">{{ 'Settings::Five9IntegrationOrgId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="orgId" formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
