import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';

@Component({
  selector: 'ca-conversation-type-icon',
  templateUrl: './conversation-type-icon.component.html',
  styleUrls: ['./conversation-type-icon.component.scss', '../conversation-icons.scss'],
})
export class ConversationTypeIconComponent implements OnInit {
  @Input()
  typeId: number | null;

  get iconCls(): string {
    let result = '';

    switch (this.typeId) {
      case ConversationType.call:
        result = 'fas fa-phone-alt';
        break;
      case ConversationType.chat:
        result = 'fas fa-comment-dots';
        break;
      case ConversationType.meeting:
        result = 'fas fa-users';
        break;
      case ConversationType.videoCall:
        result = 'fas fa-video';
        break;
      case ConversationType.email:
        result = 'fas fa-envelope';
        break;
      default:
        result = 'fas fa-question';
    }

    return result;
  }

  get tooltip(): string {
    let result = '';

    switch (this.typeId) {
      case ConversationType.call:
        result = `${this.localizationService.instant(
          '::ConversationType'
        )}: ${this.localizationService.instant('GenericLookup::Conversation.Type.Call')}`;
        break;
      case ConversationType.chat:
        result = `${this.localizationService.instant(
          '::ConversationType'
        )}: ${this.localizationService.instant('GenericLookup::Conversation.Type.Chat')}`;
        break;
      case ConversationType.meeting:
        result = `${this.localizationService.instant(
          '::ConversationType'
        )}: ${this.localizationService.instant('GenericLookup::Conversation.Type.Meeting')}`;
        break;
      case ConversationType.videoCall:
        result = `${this.localizationService.instant(
          '::ConversationType'
        )}: ${this.localizationService.instant('GenericLookup::Conversation.Type.VideoCall')}`;
        break;
      case ConversationType.email:
        result = `${this.localizationService.instant(
          '::ConversationType'
        )}: ${this.localizationService.instant('GenericLookup::Conversation.Type.Email')}`;
        break;
      default:
        result = `${this.localizationService.instant(
          '::ConversationType'
        )}: ${this.localizationService.instant('Query::Unknown')}`;
    }

    return result;
  }

  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void { }
}
