<div *ngIf="evaluations == null || evaluations.length === 0" class="row m-0 p-3">
  {{ 'Conversation::ConversationNotContainEvaluations' | abpLocalization }}
</div>
<table
  mat-table
  *ngIf="evaluations != null && evaluations.length > 0"
  [dataSource]="evaluations"
  aria-describedby="evaluation-result-table"
  style="width: 100%"
  class="with-detail"
  multiTemplateDataRows>
  <ng-container *ngFor="let gridColumn of gridColumns">
    <ng-container
      *ngIf="
        gridColumn.columnName !== 'menu' &&
        gridColumn.columnName !== 'icons' &&
        gridColumn.columnName !== 'creationTime' &&
        gridColumn.columnName !== 'totalPoint'
      "
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let element" [innerHtml]="element | objectparser : gridColumn"></td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'icons'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let evaluation">
        <i
          *ngIf="
            !loadingDetail[evaluation.id] &&
            evaluationMasterMap[evaluation.evaluationMaster.id] &&
            evaluationMasterMap[evaluation.evaluationMaster.id].length > 0
          "
          [ngClass]="evaluation == expandedElement ? 'fas fa-angle-up' : 'fas fa-angle-down'"
          (click)="
            loadingDetail[evaluation.id] = expandedElement !== evaluation;
            expandedElement = expandedElement === evaluation ? null : evaluation
          "></i>
        <i
          *ngIf="
            loadingDetail[evaluation.id] &&
            evaluationMasterMap[evaluation.evaluationMaster.id] &&
            evaluationMasterMap[evaluation.evaluationMaster.id].length > 0
          "
          class="fas fa-spinner fa-spin"></i>
      </td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'menu'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let evaluation">
        <button
          class="row-menu-button"
          mat-icon-button
          [matMenuTriggerFor]="menus"
          aria-label="Example icon-button with a menu"
          *ngIf="checkCalibration(evaluation) || checkEditEvaluation(evaluation)">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menus="matMenu">
          <button
            mat-menu-item
            (click)="onCalibrateClick(evaluation)"
            *ngIf="checkCalibration(evaluation)">
            <i style="margin-right: 6px" class="far fa-balance-scale"></i>
            <span>{{ 'QualityManagement::Calibrate' | abpLocalization }}</span>
          </button>
          <button
            mat-menu-item
            (click)="onEditEvaluationClick(evaluation)"
            *ngIf="checkEditEvaluation(evaluation)">
            <i style="margin-right: 6px" class="far fa-money-check-edit"></i>
            <span>{{ 'QualityManagement::EditEvaluation' | abpLocalization }}</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'creationTime'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let evaluation">
        <ca-clickable-link
          [innerHtml]="evaluation | objectparser : gridColumn"
          [targetLink]="['/quality', 'evaluation', evaluation.evaluationMaster.id]"
          [params]="{
            referrer: evaluationReferrer.conversation,
            referrerTab: activeTab,
            t: player?.currentTime,
            evaluationResultId: this.evaluation.id
          }">
        </ca-clickable-link>
      </td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'totalPoint'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div>
          <span>
            {{ element | objectparser : gridColumn }}
          </span>
          <span *ngIf="element.zeroPoint">
            <i
              class="fas fa-info-circle"
              #reasonListPopover="ngbPopover"
              [ngbPopover]="reasonListTemplate"
              [popoverTitle]="reasonListTitle"
              popoverClass="query-preview-popover"
              placement="left auto"
              triggers="mouseenter:mouseleave"
              [autoClose]="'outside'"
              container="body"
              (mouseover)="reasonListPopover.open({ evaluation: element })"
              (mouseleave)="reasonListPopover.close()"></i>
          </span>
        </div>
      </td>
    </ng-container>
  </ng-container>
  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let element"
      style="padding-left: 0px; padding-right: 0px"
      [attr.colspan]="displayedColumns.length">
      <div
        class="evaluation-result-detail"
        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <ca-conversation-evaluation-detail-panel
          *ngIf="element == expandedElement"
          [evaluations]="getDetailList(expandedElement.evaluationMaster.id)"
          (loaded)="loadingDetail[element.id] = false"
          [activeTab]="activeTab">
        </ca-conversation-evaluation-detail-panel>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    class="evaluation-result-row evaluation-result-row-{{ myRowData.id }}"
    [class.evaluation-result-expanded-row]="expandedElement === myRowData"
    *matRowDef="let myRowData; columns: displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    class="detail-row evaluation-result-detail-row"></tr>
</table>

<ng-template #reasonListTitle
  ><b>{{
    'QualityManagement::CompletedWithZeroPointReasonsTitle' | abpLocalization
  }}</b></ng-template
>

<ng-template #reasonListTemplate let-evaluation="evaluation">
  <div *ngFor="let reason of evaluation.zeroPointReasons">{{ reason.reason.text }}</div>
</ng-template>
