<ng-select
  #ngSelect
  appendTo="body"
  bindLabel="item.header"
  placeholder="{{ 'CA::PleaseSelectWithThreeDots' | abpLocalization }}"
  [ngClass]="classList"
  [clearable]="false"
  [items]="items"
  [readonly]="readonly"
  [compareWith]="compareFn"
  [(ngModel)]="value"
  (change)="onChange($event)"
  (open)="onOpenColumnSelector($event)">
</ng-select>
