import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ConversationsChange,
  LastConversationIdChange,
  ScrollPositionChange,
  NonFcrMasterConversationIdChange,
  StateClearRequest,
} from 'src/core/actions/analysis/non-fcr.action';
import { NonFcrStateModel } from 'src/core/models/analysis/non-fcr/non-fcr.state-model';
import { ConversationListResponseDto } from 'src/core/models/conversation/conversation-list-response.dto';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { GenericState } from 'src/core/states/generic/generic.state';
const name = 'NonFcrState';
@State<NonFcrStateModel>({
  name: name,
  defaults: {
    nonFcrMasterConversationId: -1,
    conversations: {},
    conversationsTotalCount: 0,
    lastConversationId: -1,
    scrollPosition: 0,
  } as NonFcrStateModel,
})
@Injectable()
export class NonFcrState extends GenericState<NonFcrState> {
  @Selector()
  static getSelectedNonFcrMasterConversationId(state: NonFcrStateModel): number {
    return state.nonFcrMasterConversationId;
  }

  @Selector()
  static getLastConversationId(state: NonFcrStateModel): number {
    return state.lastConversationId;
  }

  @Selector()
  static getScrollPosition(state: NonFcrStateModel): number {
    return state.scrollPosition;
  }

  @Action(NonFcrMasterConversationIdChange)
  changeSelectedNonFcrMasterConversationId(
    ctx: StateContext<NonFcrStateModel>,
    action: NonFcrMasterConversationIdChange
  ) {
    const currentState = ctx.getState();
    currentState.nonFcrMasterConversationId = action.nonFcrMasterConversationId;
  }

  @Action(ConversationsChange)
  changeConversations(ctx: StateContext<NonFcrStateModel>, action: ConversationsChange) {
    const currentState = ctx.getState();

    currentState.conversations[action.masterConversationId] = {
      data: action.conversations,
      totalCount: action.conversationsTotalCount,
    };
  }

  @Action(ScrollPositionChange)
  changeScrollPosition(ctx: StateContext<NonFcrStateModel>, action: ScrollPositionChange) {
    const currentState = ctx.getState();
    currentState.scrollPosition = action.scrollPosition;
  }

  @Action(LastConversationIdChange)
  changeLastConversationId(ctx: StateContext<NonFcrStateModel>, action: LastConversationIdChange) {
    const currentState = ctx.getState();
    currentState.lastConversationId = action.lastConversationId;
  }

  @Action(StateClearRequest)
  requestStateClear(ctx: StateContext<NonFcrStateModel>, action: StateClearRequest) {
    const currentState = ctx.getState();
    currentState.lastConversationId = -1;
    currentState.nonFcrMasterConversationId = -1;
    currentState.scrollPosition = 0;

    if (action.masterConversationId == null) {
      currentState.conversations = {};
    } else {
      delete currentState.conversations[action.masterConversationId];
    }

    ctx.patchState(currentState);
  }

  constructor(userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;

    this.userSettingsService.register(this.name, this);
  }
}
