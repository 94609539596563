import { Component, OnInit } from '@angular/core';
import { ActionLogDto } from 'src/core/models/action-log/action-log.dto';

@Component({
  selector: 'ca-conversation-not-suitable-for-evaluation-added-action-log.component',
  templateUrl: './conversation-not-suitable-for-evaluation-added-action-log.component.html',
  styleUrls: ['./conversation-not-suitable-for-evaluation-added-action-log.component.scss'],
})
export class ConversationNotSuitableForEvaluationAddedActionLogComponent implements OnInit {
  data: ActionLogDto;

  get userName(): string {
    var parsedData = JSON.parse(this.data?.payload);
    return parsedData?.userName;
  }

  constructor() {}

  ngOnInit(): void {}
}
