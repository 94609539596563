import {
  AfterViewChecked,
  Component,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ListeningStatusChange } from 'src/core/actions/conversation/conversation-module.actions';
import { MeetingTranscriptDto } from 'src/core/models/meeting/meeting-transcript.dto';
import { MeetingDto } from 'src/core/models/meeting/meeting.dto';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationListeningStatus } from 'src/core/models/generic-lookup-type/conversation/conversation-listening-status.glt';
import { CrudService } from 'src/core/services/crud/crud.service';
import { Store } from '@ngxs/store';
import { PlayerComponent } from 'src/ca-shared/player/player.module';
import { PlayerStatus } from 'src/ca-shared/player/models/player-status.enum';
import { ConversationMediaType } from 'src/core/models/generic-lookup-type/conversation/conversation-media-type.glt';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { LocalizationService } from '@abp/ng.core';
import { VideoPlayerService } from 'src/core/services/video-player/video-player.service';
import { ConversationChannel } from 'src/core/models/generic-lookup-type/conversation/conversation-channel.glt';

@Component({
  selector: 'ca-meeting-detail-tab-panel',
  templateUrl: './meeting-detail-tab-panel.component.html',
  styleUrls: ['./meeting-detail-tab-panel.component.scss'],
})
export class MeetingDetailTabPanelComponent implements OnInit {
  @ViewChild('player', {
    read: PlayerComponent,
    static: true,
  })
  player: PlayerComponent;

  @ViewChild('transcriptContainer', {
    read: ViewContainerRef,
  })
  transcriptContainer: ViewContainerRef;

  transcripts: MeetingTranscriptDto[] = [];
  activeTab: number = 1;
  lastPosition = -1;
  highlightedEls: Element[] = [];
  analyticsFeatureEnabled: boolean;
  sentimentFetureEnabled: boolean;

  private _loadPlayerFn;

  @Input()
  set conversation(value) {
    this._conversation = value;

    this.loadPlayer();

    if (this._conversation) {
      this.getTranscript();
    }
  }

  get conversation(): ConversationDto {
    return this._conversation;
  }

  private _conversation: ConversationDto;

  constructor(
    private crudService: CrudService,
    private store: Store,
    private featureService: FeatureService,
    private renderer: Renderer2,
    private videoPlayerService: VideoPlayerService,
    private localizationService: LocalizationService
  ) {
    this.analyticsFeatureEnabled = this.featureService.isEnabled(FeatureConstants.Analytics);
    this.sentimentFetureEnabled = this.featureService.isEnabled(FeatureConstants.SentimentAnalysis);
    this._loadPlayerFn = this.loadPlayer.bind(this);
  }

  getTranscript() {
    this.crudService
      .getById<MeetingTranscriptDto[]>(MeetingDto, this.conversation.meeting.id, 'transcript')
      .subscribe(response => {
        this.transcripts = response;
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.videoPlayerService.close();
  }

  setListeningStatus(eventArgs) {
    const action = new ListeningStatusChange(
      ConversationListeningStatus.listenedBeforeByYou,
      this.conversation.id
    );
    this.store.dispatch(action);
  }

  loadPlayer() {
    if (this.player.status === PlayerStatus.NotInitialized) {
      setTimeout(this._loadPlayerFn, 100);

      return;
    }

    this.setPlayer();
  }

  private setPlayer() {
    if (
      this.conversation &&
      this.conversation.meeting.recordingFileName !== null &&
      this.conversation.meeting.recordingFileName.length > 0
    ) {
      this.player.peakSource = 'api/app/conversation/' + this.conversation.id + '/waveform';
      this.player.downloadUrl = 'api/app/conversation/' + this.conversation.id + '/download';
      let indexOfExtension = this.conversation.meeting.recordingFileName.lastIndexOf('.');
      this.player.fileName = this.conversation.meeting.recordingFileName.substring(
        0,
        indexOfExtension
      );
      this.player.conversationId = this.conversation.id;
      if (this.conversation.channelId == ConversationChannel.video) {
        this.player.contentSource = `api/app/conversation/${this._conversation.id}/media/${ConversationMediaType.videoRecording}`;
        this.openVideoPlayer();
        this.player.isVideo = true;
      } else {
        this.player.contentSource = `api/app/conversation/${this._conversation.id}/media/${ConversationMediaType.voiceRecording}`;
        this.player.isVideo = false;
      }
      this.player.load();
    } else {
      this.player.reset();
    }

    this.player.positionChange.subscribe(this.onAudioProcess.bind(this));
  }

  private openVideoPlayer(): void {
    this.videoPlayerService.show(
      `${this.localizationService.instant('Conversation::Conversation')} #${this.conversation.id}`
    );
  }

  onAudioProcess(args: { currentTime: number; currentTimeString: string }) {
    const position: number = Math.floor(args.currentTime * 10);
    if (position === this.lastPosition) {
      return;
    }

    this.lastPosition = position;

    if (this.transcriptContainer != null) {
      const targetEl: HTMLElement = this.transcriptContainer.element.nativeElement;
      const targetDivArr: HTMLCollection = targetEl.getElementsByClassName(
        position.toLocaleString()
      );

      if (targetDivArr.length > 0) {
        for (let j = 0; j < this.highlightedEls.length; j++) {
          this.renderer.setStyle(this.highlightedEls[j], 'background-color', 'transparent');
        }

        this.highlightedEls = [];

        for (let i = 0; i < targetDivArr.length; i++) {
          targetDivArr[i].scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
          this.renderer.setStyle(targetDivArr[i], 'background-color', 'yellow');

          this.highlightedEls.push(targetDivArr[i]);
        }
      }
    }
  }
}
