import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ColumnSelectionItem } from '../models/column-selection-item.model';

export class ColumnSelectionValidator {
  static validColumnRequired: ValidatorFn = (fc: FormControl) => {
    const value = fc.value as ColumnSelectionItem;

    if (!value || !value.item.id || value.item.id.length <= 0) {
      return { required: true };
    }

    return null;
  };
}
