<div class="main-container">
  <div class="row pb-2">
    <div class="col-3 text-start">
      <ngx-skeleton-loader
        *ngIf="skeletonMode"
        animation="false"
        appearance="circle"
        [theme]="{
          width: '64px',
          height: '64px',
          border: '2px',
          'border-color': 'rgba(0, 0, 0, 0.12)'
        }"></ngx-skeleton-loader>

      <ca-user-photo
        *ngIf="!skeletonMode"
        [useCurrentUser]="false"
        [userId]="conversation?.userId"
        [alt]="'user'"
        [classes]="['user-avatar']"
        [styles]="userPhotoComponentStyles">
      </ca-user-photo>
    </div>
    <div class="summary-label-container" [ngClass]="skeletonMode ? 'col-9' : 'col-8'">
      <div *ngIf="skeletonMode" class="summary-label user-full-name-label">
        <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
      </div>
      <div *ngIf="!skeletonMode" class="summary-label user-full-name-label">
        {{ conversation?.userName }}
      </div>
      <div *ngIf="skeletonMode" class="summary-label department-label">
        <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
      </div>
      <div *ngIf="!skeletonMode" class="summary-label department-label">
        {{ conversation?.departmentName }}
      </div>
      <div *ngIf="skeletonMode" class="summary-label conversation-id-label">
        <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
      </div>
      <div *ngIf="!skeletonMode" class="summary-label conversation-id-label">
        {{ conversation?.id }}
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div *ngIf="!showEvaluationDetailTab || evaluation == null" class="d-flex flex-row">
    <span class="col-5"></span>
    <ca-conversation-icon-container
      class="container-class col-7 no-padding"
      [conversation]="conversation"
      [inDetail]="true"></ca-conversation-icon-container>
  </div>
  <mat-divider></mat-divider>
  <div class="tab-panel-container">
    <div *ngIf="showEvaluationDetailTab && evaluation != null">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" class="nav-tabs">
        <li [ngbNavItem]="1" class="tab">
          <a ngbNavLink>{{ 'Conversation::Evaluation' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <div class="col p-4 content-panel">
              <div class="row form-row">
                <div class="col-12 p-2">
                  <div class="row">
                    <div class="row p-2 text-start" *ngIf="skeletonMode">
                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>

                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>

                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>

                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>

                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                    </div>
                    <div class="row p-2 text-start" *ngIf="!skeletonMode">
                      <span class="col-5 bold">
                        {{ 'QualityManagement::EvaluationId' | abpLocalization }}
                      </span>
                      <span class="col-7">{{ evaluation?.id }}</span>

                      <span *ngIf="!hasOnlyAgentScope" class="col-5 bold">
                        {{ 'QualityManagement::Evaluator' | abpLocalization }}
                      </span>
                      <span *ngIf="!hasOnlyAgentScope" class="col-7">{{
                        evaluation?.evaluationMaster?.automaticAssignmentId > 0
                          ? ('QualityManagement::Automatic' | abpLocalization)
                          : evaluation?.evaluator?.fullName
                      }}</span>

                      <span class="col-5 bold">
                        {{ 'QualityManagement::EvaluationDate' | abpLocalization }}
                      </span>
                      <span class="col-7">
                        {{ evaluation?.creationTime | date : 'dd.MM.yyyy HH:mm' }}
                      </span>

                      <span
                        class="col-5 bold text-truncate"
                        container="body"
                        [ngbTooltip]="
                          'QualityManagement::AutomaticAssignmentFilterTitle' | abpLocalization
                        "
                        [disableTooltip]="
                          !(evaluation?.evaluationMaster?.automaticAssignmentId > 0)
                        ">
                        {{
                          evaluation?.evaluationMaster?.automaticAssignmentId > 0
                            ? ('QualityManagement::AutomaticAssignmentFilterTitle'
                              | abpLocalization)
                            : ('QualityManagement::Assignment' | abpLocalization)
                        }}
                      </span>
                      <span class="col-7">
                        {{
                          evaluation?.evaluationMaster?.automaticAssignmentId > 0
                            ? evaluation?.evaluationMaster?.automaticAssignment?.name
                            : evaluation?.evaluationMaster.assignment
                            ? evaluation.evaluationMaster.assignment.name
                            : 'N/A'
                        }}
                      </span>

                      <span class="col-5 bold">{{
                        'QualityManagement::Score' | abpLocalization
                      }}</span>
                      <span class="col-7">
                        {{ evaluation?.totalPoint.toFixed(1) }}/{{
                          evaluation?.evaluationMaster.maxPoint
                        }}
                      </span>

                      <span
                        class="col-5 bold text-truncate"
                        container="body"
                        [ngbTooltip]="'QualityManagement::EvaluationDuration' | abpLocalization"
                        *ngIf="evaluationDurationFeatureEnabled"
                        >{{ 'QualityManagement::EvaluationDuration' | abpLocalization }}</span
                      >
                      <span class="col-7" *ngIf="evaluationDurationFeatureEnabled">
                        {{
                          formatHelper.convertTimerSecondToShortString(
                            evaluation?.evaluationDuration
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="row" *ngIf="objection?.id > 0">
                    <fieldset>
                      <legend>
                        <i class="{{ objectionIconClass }}"></i>
                        {{ 'QualityManagement::ObjectionReason' | abpLocalization }}
                      </legend>
                      <div class="row m-2 objection-text">
                        {{ objection?.reason }}
                      </div>
                      <div class="row m-2">
                        <div
                          style="
                            flex: 0 auto;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            justify-items: stretch;
                            width: 100%;
                          "
                          *ngIf="!hideObjection">
                          <button
                            type="button"
                            class="btn btn-sm ca-btn ca-btn-primary"
                            style="flex: 1"
                            (click)="onAcceptObjectionClick()">
                            <i class="far fa-check"></i>
                            <span>{{ 'QualityManagement::Accept' | abpLocalization }}</span>
                          </button>
                          <div style="flex: 0 0 16px"></div>
                          <button
                            class="btn btn-sm ca-btn ca-btn-neutral ng-star-inserted"
                            style="flex: 1"
                            (click)="onRejectObjectionClick()">
                            <i class="far fa-xmark"></i>
                            <span>{{ 'QualityManagement::Reject' | abpLocalization }}</span>
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" class="tab">
          <a ngbNavLink>{{ 'Conversation::Conversation' | abpLocalization }}</a>
          <ng-template ngbNavContent>
            <div class="col p-4 content-panel">
              <div class="row form-row">
                <div class="col-12 p-2">
                  <div class="row">
                    <div class="row p-2 text-start" *ngIf="skeletonMode">
                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>

                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>

                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>

                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>

                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>

                      <span class="col-3 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                      <span class="col-9 text-start">
                        <ngx-skeleton-loader
                          [theme]="skeletonRowTheme"
                          animation="false"></ngx-skeleton-loader>
                      </span>
                    </div>
                    <div class="row p-2 text-start" *ngIf="!skeletonMode">
                      <span class="col-5 bold text-start">{{ '::Type' | abpLocalization }}</span>
                      <span class="col-7 text-start">
                        {{ 'GenericLookup::' + conversation?.typeName | abpLocalization }}
                      </span>

                      <span class="col-5 bold text-start">{{ '::Time' | abpLocalization }}</span>
                      <span class="col-7 text-start">{{ conversationTime }}</span>

                      <span [hidden]="!isCall" class="col-5 bold text-start">
                        {{ '::Direction' | abpLocalization }}
                      </span>
                      <span [hidden]="!isCall" class="col-7 text-start">
                        {{
                          isCall
                            ? ('GenericLookup::' + conversation?.call.directionCode
                              | abpLocalization)
                            : ''
                        }}
                      </span>

                      <span class="col-5 bold text-start">{{ '::Channel' | abpLocalization }}</span>
                      <span class="col-7 text-start">
                        {{ 'GenericLookup::' + conversation?.channelName | abpLocalization }}
                      </span>

                      <span class="col-5 bold text-start">{{
                        '::Duration' | abpLocalization
                      }}</span>
                      <span class="col-7 text-start">{{
                        conversation?.duration | caTimePipe : { showShortcuts: true }
                      }}</span>

                      <span class="col-5 bold text-start">{{ '::Source' | abpLocalization }}</span>
                      <span class="col-7 text-start">{{
                        conversation?.conversationSourceName
                      }}</span>

                      <span class="col-5 bold text-start text-truncate">
                        {{ 'Conversation::ExternalId' | abpLocalization }}
                      </span>
                      <span class="col-7 text-start">
                        <div class="d-flex">
                          <div class="truncated">
                            {{ conversation?.externalId }}
                          </div>
                          <div [hidden]="!conversation?.externalId" class="ms-1">
                            <i
                              class="fas fa-copy text-primary clipboard-link"
                              [cdkCopyToClipboard]="conversation?.externalId"
                              (click)="copyToClipboard()"
                              title="{{
                                'Conversation::CopyExternalIdtoClipboard' | abpLocalization
                              }}"></i>
                          </div>
                        </div>
                      </span>

                      <span
                        [hidden]="!isCall || !conversation?.call?.callerNumber"
                        class="col-5 bold text-start">
                        {{ 'Conversation::Caller' | abpLocalization }}
                      </span>
                      <span [hidden]="!isCall || !conversation?.call?.callerNumber" class="col-7">
                        {{ conversation?.call?.callerNumber }}
                      </span>

                      <span
                        [hidden]="!isCall || !conversation?.call?.calledNumber"
                        class="col-5 bold text-start">
                        {{ 'Conversation::Called' | abpLocalization }}
                      </span>
                      <span
                        [hidden]="!isCall || !conversation?.call?.calledNumber"
                        class="col-7 text-start">
                        {{ conversation?.call?.calledNumber }}
                      </span>

                      <span
                        [hidden]="!conversation?.relatedGroupId"
                        class="col-5 text-start bold text-truncate">
                        {{ 'Conversation::RelatedGroupId' | abpLocalization }}
                      </span>
                      <span class="col-7 text-start">
                        <div class="d-flex">
                          <div class="truncated">
                            {{ conversation?.relatedGroupId }}
                          </div>
                          <div [hidden]="!conversation?.relatedGroupId" class="ms-1">
                            <i
                              class="fas fa-copy text-primary clipboard-link"
                              [cdkCopyToClipboard]="conversation?.relatedGroupId"
                              (click)="copyToClipboard()"
                              title="{{
                                'Conversation::CopyRelatedGroupIdtoClipboard' | abpLocalization
                              }}"></i>
                          </div>
                        </div>
                      </span>

                      <span class="col-12" *ngIf="!hideCategoryChips">
                        <ca-conversation-category-panel
                          (categorySelectionChanged)="onCategorySelectionChanged($event)"
                          [categories]="categories"></ca-conversation-category-panel>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div
        [ngbNavOutlet]="nav"
        class="p-0 shadow-none h-100"
        style="overflow-x: hidden; overflow-y: auto"></div>
    </div>
    <div *ngIf="!showEvaluationDetailTab || evaluation == null">
      <div class="row p-2" *ngIf="skeletonMode">
        <span class="col-3 bold text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>
        <span class="col-9 text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>
        <span class="col-3 bold text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>
        <span class="col-9 text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>

        <span class="col-3 bold text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>
        <span class="col-9 text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>

        <span class="col-3 bold text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>
        <span class="col-9 text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>

        <span class="col-3 bold text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>
        <span class="col-9 text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>

        <span class="col-3 bold text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>
        <span class="col-9 text-start">
          <ngx-skeleton-loader [theme]="skeletonRowTheme" animation="false"></ngx-skeleton-loader>
        </span>
      </div>
      <div class="row p-2 text-start w-100" *ngIf="!skeletonMode">
        <span class="col-5 bold mb-1 text-start">{{ '::Time' | abpLocalization }}</span>
        <span class="col-7 text-start">{{ conversationTime }}</span>

        <span class="col-5 bold mb-1 text-start">{{ '::Channel' | abpLocalization }}</span>
        <span class="col-7 text-start">
          {{ 'GenericLookup::' + conversation?.channelName | abpLocalization }}
        </span>

        <span class="col-5 bold mb-1 text-start">{{ '::Duration' | abpLocalization }}</span>
        <span class="col-7 text-start">{{
          conversation?.duration | caTimePipe : { showShortcuts: true }
        }}</span>

        <span class="col-5 bold mb-1 text-start">{{ '::Source' | abpLocalization }}</span>
        <span class="col-7 text-start">{{ conversation?.conversationSourceName }} </span>

        <span class="col-5 bold mb-1 text-start text-truncate">{{
          'Conversation::ExternalId' | abpLocalization
        }}</span>
        <span class="col-7 text-start">
          <div class="d-flex">
            <div class="truncated">
              {{ conversation?.externalId }}
            </div>
            <div [hidden]="!conversation?.externalId" class="ms-1">
              <i
                class="fas fa-copy text-primary clipboard-link"
                [cdkCopyToClipboard]="conversation?.externalId"
                (click)="copyToClipboard()"
                title="{{ 'Conversation::CopyExternalIdtoClipboard' | abpLocalization }}"></i>
            </div>
          </div>
        </span>
        <span
          [hidden]="!isCall || !conversation?.call?.callerNumber"
          class="col-5 bold mb-1 text-start">
          {{ 'Conversation::Caller' | abpLocalization }}
        </span>
        <span [hidden]="!isCall || !conversation?.call?.callerNumber" class="col-7 text-start">
          {{ conversation?.call?.callerNumber }}
        </span>

        <span
          [hidden]="!isCall || !conversation?.call?.calledNumber"
          class="col-5 bold mb-1 text-start">
          {{ 'Conversation::Called' | abpLocalization }}
        </span>
        <span [hidden]="!isCall || !conversation?.call?.calledNumber" class="col-7 text-start">
          {{ conversation?.call?.calledNumber }}
        </span>

        <span
          [hidden]="!conversation?.relatedGroupId"
          class="col-5 text-start mb-1 bold text-truncate">
          {{ 'Conversation::RelatedGroupId' | abpLocalization }}
        </span>
        <span class="col-7 text-start" [hidden]="!conversation?.relatedGroupId">
          <div class="d-flex">
            <div class="truncated">
              {{ conversation?.relatedGroupId }}
            </div>
            <div class="ms-1">
              <i
                class="fas fa-copy mb-1 text-primary clipboard-link"
                [cdkCopyToClipboard]="conversation?.relatedGroupId"
                (click)="copyToClipboard()"
                title="{{ 'Conversation::CopyRelatedGroupIdtoClipboard' | abpLocalization }}"></i>
            </div>
          </div>
        </span>

        <span
          [hidden]="conversation?.averageCustomerSentimentScore == null"
          *ngIf="sentimentFetureEnabled"
          class="col-5 text-start mb-1 bold text-start">
          {{ 'Conversation::CustomerSentiment' | abpLocalization }}
        </span>
        <span
          [hidden]="conversation?.averageCustomerSentimentScore == null"
          *ngIf="sentimentFetureEnabled"
          class="col-5 mb-1 text-truncate">
          <ca-conversation-sentiment-label
            [score]="conversation?.averageCustomerSentimentScore"></ca-conversation-sentiment-label>
        </span>

        <span class="col-12" *ngIf="!hideCategoryChips">
          <ca-conversation-category-panel
            (categorySelectionChanged)="onCategorySelectionChanged($event)"
            [categories]="categories"></ca-conversation-category-panel>
        </span>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div
    class="row p-2 text-start same-row"
    *ngIf="!skeletonMode && showEvaluationDetailTab && evaluation?.zeroPoint"
    [ngbTooltip]="evaluation?.zeroPointReasons | reasonTooltipList"
    container="body">
    <span class="alert-icon"> <i class="bi bi-info-circle-fill"></i></span>
    <span class="text-danger">
      {{ 'QualityManagement::EvaluationCompletedWithZeroPoint' | abpLocalization }}
    </span>
  </div>
  <div class="comment-container" *ngIf="showEvaluationDetailTab" [formGroup]="form">
    <textarea
      class="form-control"
      placeholder="{{ 'QualityManagement::EvaluationComment' | abpLocalization }}"
      [(ngModel)]="comment"
      formControlName="comment"></textarea>
  </div>
</div>
