import { Pipe, PipeTransform } from '@angular/core';
import { NumberHelper } from '../helpers/number-helper';

@Pipe({ name: 'caNumberPipe' })
export class CANumberPipe implements PipeTransform {
  constructor(private numberHelper: NumberHelper) { }

  transform(value: string | number): string {
    if (value == null) {
      return '-';
    }

    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    const absValue = Math.abs(value);

    if (absValue >= 9.95) {
      return Math.round(value).toString();
    }
    else if (0.995 <= absValue && absValue < 9.95) {
      return this.numberHelper.roundWithFraction(value, 1).toString();
    }
    else if (0.005 <= absValue && absValue < 0.995) {
      return this.numberHelper.roundWithFraction(value, 2).toString();
    }
    else if (this.numberHelper.isNumberConvergingZero(value)) {
      return '≈0';
    }


    return value.toString();
  }
}
