import { ToasterService } from '@abp/ng.theme.shared';
import { Component, HostBinding, OnInit } from '@angular/core';
import { CacheService } from 'src/core/services/cache/cache.service';

@Component({
  selector: 'ca-clear-cache',
  templateUrl: './clear-cache.component.html',
  styleUrls: ['./clear-cache.component.scss'],
})
export class ClearCacheComponent implements OnInit {
  @HostBinding('style.width')
  componentWidth = '100%';

  constructor(private service: CacheService, private toastr: ToasterService) {}

  ngOnInit(): void {}

  clearCache(eventArgs: MouseEvent): void {
    this.toastr.info('::ClearingCache');

    this.service.clear().subscribe(val => {
      this.toastr.success('::ClearedCache');
    });
  }
}
