import { LocalizationService, PermissionService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import {
  NgWizardConfig,
  NgWizardService,
  NgWizardStep,
  StepChangedArgs,
  StepValidationArgs,
  STEP_STATE,
  THEME,
} from 'ng-wizard';
import { isObservable } from 'rxjs';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { StepOneComponent } from './steps/step-one/step-one.component';
import { StepTwoComponent } from './steps/step-two/step-two.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { ConversationCleanerFilterDto } from 'src/core/models/conversation/cleaner/conversation-cleaner-filter.dto';
import { ToasterService } from '@abp/ng.theme.shared';
import { ConversationCleanerCommunicationService } from './conversation-cleaner-communication.service';

@Component({
  selector: 'ca-conversation-cleaner',
  templateUrl: './conversation-cleaner.component.html',
  styleUrls: ['./conversation-cleaner.component.scss'],
})
export class ConversationCleanerComponent implements OnInit {
  canDeleteConversation: boolean = false;
  selectedTheme: THEME;
  selectedStepIndex: number;
  conversationCount: number = 0;
  filterData: ConversationCleanerFilterDto;
  stepOneComponent: StepOneComponent;

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden,
  };

  get conversationsDeleteDisabled(): boolean {
    return this.conversationCleanerCommunicationService.isDeleteDisabled;
  }

  constructor(
    private permissionService: PermissionService,
    private conversationService: ConversationService,
    private operators: Operators,
    private modalService: NgbModal,
    private ngWizardService: NgWizardService,
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private conversationCleanerCommunicationService: ConversationCleanerCommunicationService
  ) {
    this.canDeleteConversation = this.permissionService.getGrantedPolicy('Conversation._Delete');
  }

  ngOnInit(): void {}

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.dots,
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
    anchorSettings: {
      anchorClickable: true,
      markDoneStep: false,
      enableAllAnchors: true,
    },
  };

  stepDefinitions: any[] = [
    {
      title: this.localizationService.instant('Conversation::ChooseCriteriaStepTitle'),
      component: StepOneComponent,
      canEnter: this.validateStep.bind(this, 'entry'),
      canExit: this.validateStep.bind(this, 'exit'),
    },
    {
      title: this.localizationService.instant('Conversation::DeleteSummaryStepTitle'),
      state: STEP_STATE.normal,
      component: StepTwoComponent,
    },
  ];

  private validateStep(type: string, args: StepValidationArgs) {
    let step = type == 'entry' ? args.toStep : args.fromStep;
    let stepSpecificValidateMethod;

    if (step && step.componentRef) {
      if (!this.stepOneComponent) {
        this.stepOneComponent = step.componentRef.instance;
      }
      stepSpecificValidateMethod =
        type == 'entry'
          ? step.componentRef.instance.validateEntryToStep
          : step.componentRef.instance.validateExitFromStep;
    }

    if (stepSpecificValidateMethod) {
      if (typeof stepSpecificValidateMethod === typeof true) {
        return <boolean>stepSpecificValidateMethod;
      } else if (stepSpecificValidateMethod instanceof Function) {
        stepSpecificValidateMethod = stepSpecificValidateMethod.bind(step.componentRef.instance);
        let result = stepSpecificValidateMethod();

        var isObservableObject = isObservable(result);
        if (isObservableObject as boolean) {
          return result;
        } else if (typeof result === typeof true) {
          return <boolean>result;
        }
      }
    }

    return true;
  }

  private getFilters(step: NgWizardStep): FilterItemDto[] {
    const filters: FilterItemDto[] = [];
    if (step && step.componentRef) {
      this.filterData = step.componentRef.instance.getData();

      if (this.filterData.filterType == 'CalledOrCallerNumber') {
        filters.push({
          field: 'calledOrCallerNumber',
          operator: this.operators.Equals,
          value: this.filterData.calledOrCallerNumber,
        });
      } else if (this.filterData.filterType == 'UniqueCustomerKey') {
        filters.push({
          field: 'uniqueCustomerKey',
          operator: this.operators.Equals,
          value: this.filterData.uniqueCustomerKey,
        });
      }

      filters.push({
        field: 'dateRange',
        operator: this.operators.Equals,
        value: {
          dateFrom: this.filterData.startTime,
          dateTo: this.filterData.endTime,
        },
      });
    }
    return filters;
  }

  openCleanerWizard(content) {
    this.modalService.open(content, { size: 'md', scrollable: true });
  }

  stepChanged(args: StepChangedArgs) {
    this.selectedStepIndex = args.step.index;

    if (this.selectedStepIndex === 1) {
      args.step.componentRef.instance.setLoader();
      let filters = this.getFilters(args.previousStep);
      this.conversationService.getDeletableConversationCount(filters).subscribe(res => {
        this.conversationCount = res;
        args.step.componentRef.instance.setData({
          conversationCount: this.conversationCount,
          filterData: this.filterData,
        });
      });
    }
  }

  deleteConversations() {
    this.conversationService.deleteConversations(this.filterData).subscribe(result => {
      this.toastr.success(
        this.localizationService.instant('Conversation::DeleteRequestHasCreated')
      );
      this.modalService.dismissAll();
    });
  }

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
    if (this.stepOneComponent) {
      this.stepOneComponent.submit();
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  onChangeStepState(event) {
    if (
      event.component.name == 'StepTwoComponent' &&
      !this.conversationCleanerCommunicationService.validForm
    ) {
      return this.stepStates.disabled;
    }

    return this.stepStates.normal;
  }
}
