import { Component, OnInit } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { IntegerQueryItemDto } from 'src/core/models/query/integer-query-item.dto';
import { ValidationRules } from 'src/core/models/validation/validation-rules';
import { ConfigStateService } from '@abp/ng.core';

@Component({
  selector: 'ca-query-tree-node-number',
  templateUrl: './query-tree-node-number.component.html',
  styleUrls: ['./query-tree-node-number.component.scss'],
})
export class QueryTreeNodeNumberComponent extends QueryEditorBase implements OnInit {
  payload: IntegerQueryItemDto;
  min = 0;
  max: number = Number(this.configStateService.getSetting('Number.IntMaxValue'));

  constructor(private configStateService: ConfigStateService) {
    super();
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as IntegerQueryItemDto;
    const validationRules = this.node.categoryItem.validationRules as ValidationRules;
    if (validationRules) {
      this.min = validationRules.min;
      this.max = validationRules.max;
    }
    this.node.validationStatus = true;
  }

  operatorChanged() {
    this.node.isDirty = true;
  }

  valueChanged() {
    this.node.isDirty = true;
  }

  validateInput(value: number) {
    value = Number(value);
    if (value <= this.min) {
      this.payload.value = this.min;
    } else if (this.max && value > this.max) {
      this.payload.value = this.max;
    } else {
      this.payload.value = Math.round(value);
    }
  }
}
