import { Injectable } from '@angular/core';
import { QueryFieldDataType } from '../models/query/query-field-data-type.enum';
import { FeatureService } from '../services/feature/feature.service';
import { FeatureConstants } from './feature-constant';

@Injectable({
  providedIn: 'root',
})
export class QueryConstants {
  static readonly RANGE_UNIT_SECONDS_CODE = 'Query.RangeUnit.Seconds';
  static readonly RANGE_OPENING_START_SECOND = 0;
  static readonly RANGE_OPENING_END_SECOND = 60;
  static readonly RANGE_CLOSING_START_SECOND = 60;
  static readonly RANGE_CLOSING_END_SECOND = 0;

  static readonly CATEGORY_ITEM_ID_ORDERED = 1;
  static readonly CATEGORY_ITEM_ID_ORDERED_INTERVAL = 2;
  static readonly CATEGORY_ITEM_ID_SIMPLE = 3;
  static readonly CATEGORY_ITEM_ID_SIMPLE_NOT = 4;
  static readonly CATEGORY_ITEM_ID_SIMPLE_OCCURENCE = 5;
  static readonly CATEGORY_ITEM_ID_SIMPLE_INTERVAL = 6;

  static readonly CATEGORY_ITEM_ID_NEAR = 8;
  static readonly CATEGORY_ITEM_ID_NEAR_INTERVAL = 11;

  static readonly CATEGORY_ITEM_ID_NER = 12;
  static readonly CATEGORY_ITEM_ID_NER_VALUE = 13;
  static readonly CATEGORY_ITEM_ID_NER_RANGE = 14;

  static readonly CATEGORY_ITEM_ID_GENAI_YESNO = 1;
  static readonly CATEGORY_ITEM_ID_GENAI_YESNO_RANGE = 2;

  static readonly CATEGORY_ITEM_ID_GENAI_LIMIT = 5;

  get categoriesToHide(): number[] {
    let categories: number[] = [];
    // These data types are all handled by the new Simple Term Editor.
    categories.push(this.queryFieldDataType.SimpleTermWithNot);
    categories.push(this.queryFieldDataType.SimpleTermWithOccurence);
    categories.push(this.queryFieldDataType.SimpleTermWithRange);
    categories.push(this.queryFieldDataType.SimpleTermWithRangeAndOccurence);

    // This data type is handled by the new Ordered Editor.
    categories.push(this.queryFieldDataType.OrderedGroupWithRange);

    // This data type is handled by the new Advanced Ordered Editor.
    categories.push(this.queryFieldDataType.AdvancedOrderedNearTerm);
    categories.push(this.queryFieldDataType.AdvancedOrderedSimpleTerm);

    // If Index Without Full TextSearch is not enabled, dont allow Advanced Ordered Group queries
    if (!this.indexWithoutFullTextSearchEnabled) {
      categories.push(this.queryFieldDataType.AdvancedOrderedGroup);
    }

    // This data type is handled by the new Near Editor.
    categories.push(this.queryFieldDataType.NearQueryWithRange);

    // These data types are all handled by the new Ner Editor.
    categories.push(this.queryFieldDataType.ConversationNerWithRange);
    categories.push(this.queryFieldDataType.ConversationNerWithValue);

    // This data type is handled by the new GenAI YesNo Editor.
    categories.push(this.queryFieldDataType.GenAIYesNoWithRange);

    return categories;
  }

  private indexWithoutFullTextSearchEnabled: boolean;

  constructor(
    public queryFieldDataType: QueryFieldDataType,
    private featureService: FeatureService
  ) {
    this.indexWithoutFullTextSearchEnabled = this.featureService.isEnabled(
      FeatureConstants.IndexWithoutFullTextSearch
    );
  }
}
