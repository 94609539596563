<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="sourceName">
    <label for="sourceName">{{ 'Settings::AmazonIntegrationSourceName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sourceName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="instanceId">
    <label for="instanceId">{{ 'Settings::AmazonIntegrationInstanceId' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="instanceId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::AmazonIntegrationClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="clientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">
      {{ 'Settings::AmazonIntegrationClientSecret' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientSecret"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="regionSystemName">
    <label for="regionSystemName">
      {{ 'Settings::AmazonIntegrationRegionSystemName' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="regionSystemName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="groupId">
    <label for="groupId">{{ 'Settings::AmazonIntegrationGroupId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="groupId" formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
