import { FormControl, ValidatorFn } from '@angular/forms';

export class RegexTermValidator {
  static Validator: ValidatorFn = (control: FormControl) => {
    if (!control.value) {
      return null;
    }

    let text: string = control.value.toString();

    try {
      new RegExp(text);
      return null;
    } catch (e) {
      return { regexTermInvalidPattern: true };
    }
  };
}
