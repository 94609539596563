<form *ngIf="generativeQAFeatureEnabled" [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2">
    <label for="service">
      {{ 'Settings::GenerativeQA:Service' | abpLocalization }}
    </label>
    <ca-select formControlName="service" class="below-all" (change)="onTypeChange($event)">
      <ca-option *ngFor="let serviceType of serviceTypes" [value]="serviceType.code">
        {{ 'GenericLookup::' + serviceType.code | abpLocalization }}
      </ca-option>
    </ca-select>
  </div>
  <div
    *ngIf="isServiceSelected('GenerativeQA.Service.KnovvuVA')"
    class="form-group pt-2"
    formGroupName="project">
    <label class="indicates-required-field" for="project">
      {{ 'Settings::GenerativeQA:Project' | abpLocalization }}
    </label>
    <input type="text" class="form-control form-control-sm" id="project" formControlName="value" />
  </div>
  <div
    *ngIf="
      isServiceSelected('GenerativeQA.Service.KnovvuML') ||
      isServiceSelected('GenerativeQA.Service.GenesysKnowledge')
    "
    class="form-group pt-2"
    formGroupName="endpoint">
    <label class="indicates-required-field" for="endpoint">
      {{ 'Settings::GenerativeQA:Endpoint' | abpLocalization }}
    </label>
    <input type="text" class="form-control form-control-sm" id="endpoint" formControlName="value" />
  </div>
  <div
    *ngIf="
      isServiceSelected('GenerativeQA.Service.KnovvuML') ||
      isServiceSelected('GenerativeQA.Service.GenesysKnowledge')
    "
    class="form-group pt-2"
    formGroupName="identityBaseEndpoint">
    <label class="indicates-required-field" for="identityBaseEndpoint">
      {{ 'Settings::GenerativeQA:IdentityBaseEndpoint' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="identityBaseEndpoint"
      formControlName="value" />
  </div>
  <div
    *ngIf="
      isServiceSelected('GenerativeQA.Service.KnovvuML') ||
      isServiceSelected('GenerativeQA.Service.GenesysKnowledge')
    "
    class="form-group pt-2"
    formGroupName="clientId">
    <label [ngClass]="!isSavedBefore ? 'indicates-required-field' : ''" for="clientId">
      {{ 'Settings::GenerativeQA:ClientId' | abpLocalization }}
    </label>
    <input
      type="password"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      class="form-control form-control-sm"
      id="clientId"
      formControlName="value" />
  </div>
  <div
    *ngIf="
      isServiceSelected('GenerativeQA.Service.KnovvuML') ||
      isServiceSelected('GenerativeQA.Service.GenesysKnowledge')
    "
    class="form-group pt-2"
    formGroupName="clientSecret">
    <label [ngClass]="!isSavedBefore ? 'indicates-required-field' : ''" for="clientSecret">
      {{ 'Settings::GenerativeQA:ClientSecret' | abpLocalization }}
    </label>
    <input
      type="password"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      class="form-control form-control-sm"
      id="clientSecret"
      formControlName="value" />
  </div>
  <div
    *ngIf="isServiceSelected('GenerativeQA.Service.KnovvuML')"
    class="form-group pt-2"
    formGroupName="promptMessage">
    <label class="indicates-required-field" for="promptMessage">
      {{ 'Settings::GenerativeQA:PromptMessage' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="promptMessage"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
