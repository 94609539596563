export enum PlayerStatus {
  NotInitialized,
  Initialized,
  ContentSet,
  LoadingPeaks,
  LoadingContent,
  DrawingWaveform,
  ReadyToPlay,
  Playing,
  Paused,
  Stopped,
  Error,
}
