<div class="row entry-row submenu-row">
  <div class="col-auto">
    <h1 class="content-header-title">
      {{ '::Users' | abpLocalization }}
    </h1>
  </div>
  <div class="col-md-auto pl-md-0">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="['..']">
          <i class="fas fa-arrow-circle-left"></i>
          {{ '::BackToList' | abpLocalization }}
        </a>
      </li>
      <li class="breadcrumb-item active">
        {{ user?.id != '' ? user.userName : ('User::NewUser' | abpLocalization) }}
      </li>
    </ol>
  </div>
  <div class="col">
    <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
      <div class="ca-toolbar-west-section"></div>
      <div class="ca-toolbar-east-section">
        <div class="toolbar-item">
          <button
            type="button"
            class="btn ca-btn ca-btn-neutral"
            (click)="onClickDelete($event)"
            *ngIf="user.id !== null && user.id !== ''"
            [disabled]="processing || user.extraProperties.LdapUser">
            <span class="with-no-icon">
              {{ '::Delete' | abpLocalization }}
            </span>
          </button>
        </div>
        <div class="toolbar-item">
          <button
            type="button"
            class="btn ca-btn ca-btn-neutral"
            (click)="onClickLoginWithUser($event)"
            *ngIf="impersonateVisibility"
            [disabled]="processing">
            <span class="with-no-icon">
              {{ 'User::LoginWithThisUser' | abpLocalization }}
            </span>
          </button>
        </div>
        <div class="toolbar-item last">
          <div class="btn-group">
            <button
              type="button"
              class="btn ca-btn ca-btn-primary-toolbar"
              (click)="onClickSaveAndClose($event)"
              [disabled]="processing">
              <ng-container *ngIf="!processing">
                <i class="far fa-save"></i>
                <span>{{ '::SaveClose' | abpLocalization }}</span>
              </ng-container>

              <ng-container *ngIf="processing">
                <i class="far fa-spinner"></i>
                <span>{{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span>
              </ng-container>
            </button>
            <div
              class="btn-group"
              [dropdownClass]="'ca-popup-dropdown-menu'"
              ngbDropdown
              role="group"
              container="body">
              <button
                class="btn ca-btn ca-btn-primary-toolbar dropdown-toggle-split"
                [disabled]="processing"
                ngbDropdownToggle></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button
                  ngbDropdownItem
                  class="dropdown-item-with-icon"
                  [disabled]="processing"
                  (click)="onClickSave($event)">
                  <i class="far fa-save"></i>
                  <span>{{ '::Save' | abpLocalization }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
