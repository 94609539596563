import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../../shared/dto-has-api.model';
@Api('api/app/tag')
export class TagDto extends DtoHasApi {
  id: number;
  name: string;
  typeId: number;
  isStatic: boolean;
  selected?: boolean;
}
