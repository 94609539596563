import { Component, EventEmitter, HostBinding, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ca-query-builder-node-remove-button',
  templateUrl: './query-builder-node-remove-button.component.html',
  styleUrls: ['./query-builder-node-remove-button.component.scss'],
})
export class QueryBuilderNodeRemoveButtonComponent implements OnInit {
  @HostBinding('class')
  componentClass = 'col-auto p-0';

  @Output()
  buttonClick: EventEmitter<MouseEvent> = new EventEmitter();

  onButtonClick(e: MouseEvent): void {
    this.buttonClick.emit(e);
  }

  constructor() {}

  ngOnInit(): void {}
}
