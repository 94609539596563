import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { AITopicSettingsService } from 'src/core/services/settings/ai-topic-settings.service';
import { CAConfigStateService } from 'src/core/services/config/ca-config-state.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { AITopicInferenceSettingOption } from 'src/core/models/generic-lookup-type/conversation/ai-topic-inference-setting-option.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { map } from 'rxjs/operators';
import { AITopicClassificationServiceOption } from 'src/core/models/generic-lookup-type/conversation/ai-topic-classification-service-option.glt';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { RoleHelper } from 'src/core/helpers/role-helper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ca-ai-topic-category-settings',
  templateUrl: './ai-topic-category-settings.component.html',
  styleUrls: ['./ai-topic-category-settings.component.scss'],
})
export class AITopicCategorySettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'AITopic';
  private readonly apiAddress = this.prefix + '.ApiAddress';
  private readonly clusteringAddress = this.prefix + '.ClusteringAddress';
  private readonly publishedAddress = this.prefix + '.PublishedAddress';
  private readonly identityBaseUrl = this.prefix + '.IdentityBaseUrl';
  private readonly language = this.prefix + '.Language';
  private readonly serviceStartDateType = this.prefix + '.Service.StartDateTypeId';
  private readonly featureDim = this.prefix + '.Process.FeatureDim';
  private readonly clusterCount = this.prefix + '.Process.ClusterCount';
  private readonly classificationServiceType = this.prefix + '.ClassificationServiceType';
  private readonly conversationCount = this.prefix + '.Process.ConversationCount';
  
  private formClassificationServiceTypeSubscription: Subscription;

  publishedAddressRadio: string = 'serviceAddress';

  inferenceSettingOptions: GenericLookupDto[];
  classificationServiceOptions: GenericLookupDto[];
  startDateInfo: string;
  isGenerativeAIEnabled: boolean = false;
  isSuperAdminUser: boolean = false;
  minConversationCount: number = 20;
  maxConversationCount: number = 5000;

  constructor(
    private config: ConfigStateService,
    private store: Store,
    private fb: FormBuilder,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private aiTopicSettingService: AITopicSettingsService,
    private configStateService: CAConfigStateService,
    private featureService: FeatureService,
    private roleHelper: RoleHelper,
    private localizationService: LocalizationService
  ) {
    if (this.featureService.isEnabled(FeatureConstants.GenerativeAI)) {
      this.isGenerativeAIEnabled = true;
      this.store
        .select(GenericLookupTypeState.getGenericLookups)
        .pipe(map(filterFn => filterFn(AITopicClassificationServiceOption)))
        .subscribe(result => {
          this.classificationServiceOptions = result;
        });
    }

    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(AITopicInferenceSettingOption)))
      .subscribe(result => {
        this.inferenceSettingOptions = result;
      });

    let clusteringAddress = this.config.getSetting(this.clusteringAddress);
    if (clusteringAddress.length > 0) {
      this.publishedAddressRadio = 'clusterAddress';
    }

    let serviceStartDateTypeValue = this.config.getSetting(this.serviceStartDateType);
    this.startDateInfo = this.localizationService
      .instant('GenericLookup::' + serviceStartDateTypeValue)
      .toLowerCase();

    this.form = this.fb.group({
      apiAddress: this.fb.group({
        name: this.apiAddress,
        value: [
          this.config.getSetting(this.apiAddress),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      clusteringAddress: this.fb.group({
        name: this.clusteringAddress,
        value: [this.config.getSetting(this.clusteringAddress)],
      }),
      identityBaseUrl: this.fb.group({
        name: this.identityBaseUrl,
        value: [
          this.config.getSetting(this.identityBaseUrl),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      language: this.fb.group({
        name: this.language,
        value: [
          this.config.getSetting(this.language),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      classificationServiceType: this.fb.group({
        name: this.classificationServiceType,
        value: [
          this.config.getSetting(this.classificationServiceType),
          {
            validators: [Validators.required],
          },
        ],
      }),
      serviceStartDateTypeId: this.fb.group({
        name: this.serviceStartDateType,
        value: [
          this.config.getSetting(this.serviceStartDateType),
          {
            validators: [Validators.required],
          },
        ],
      }),
      featureDim: this.fb.group({
        name: this.featureDim,
        value: [
          this.config.getSetting(this.featureDim),
          {
            validators: [Validators.required, Validators.min(1)],
          },
        ],
      }),
      conversationCount: this.fb.group({
        name: this.conversationCount,
        value: [
          this.config.getSetting(this.conversationCount),
          {
            validators: [Validators.min(this.minConversationCount), Validators.max(this.maxConversationCount)],
          },
        ],
      }),
      categoriesAutomatically: [Number(this.config.getSetting(this.clusterCount)) <= 0],
      clusterCount: this.fb.group({
        name: this.clusterCount,
        value: [this.config.getSetting(this.clusterCount)],
      }),
    });

    if (Number(this.config.getSetting(this.clusterCount)) > 0) {
      this.form
        .get('clusterCount')
        .get('value')
        .setValidators([Validators.required, Validators.min(2), Validators.max(100)]);
      this.form.get('clusterCount').updateValueAndValidity();
    }

    if (this.form.get('classificationServiceType').get('value').value.toString() === "AITopic.ClassificationService.OpenAI") {
      this.maxConversationCount = 1000;
      if (this.form.get('conversationCount').get('value').value > this.maxConversationCount) {
        this.form.get('conversationCount').get('value').setValue(this.maxConversationCount);
      }
      this.form
        .get('conversationCount')
        .get('value')
        .setValidators([Validators.required, Validators.min(this.minConversationCount), Validators.max(this.maxConversationCount)]);
      this.form.get('conversationCount').updateValueAndValidity();
    }

    this.form.get('serviceStartDateTypeId').valueChanges.subscribe(serviceStartDateTypeId => {
      this.startDateInfo = this.localizationService
        .instant('GenericLookup::' + serviceStartDateTypeId.value)
        .toLowerCase();
    });

    this.form.get('categoriesAutomatically').valueChanges.subscribe(categoriesAutomatically => {
      if (categoriesAutomatically) {
        this.form.get('clusterCount').get('value').setValue(null);
        this.form.controls['clusterCount'].clearValidators();
        this.removeValidationError('clusterCount');
      } else {
        this.form
          .get('clusterCount')
          .get('value')
          .setValidators([Validators.required, Validators.min(2), Validators.max(100)]);
        this.form.get('clusterCount').updateValueAndValidity();
      }
    });

    this.setClassificationServiceTypeSubscription();

    this.isSuperAdminUser = this.roleHelper.isSuperAdminUser();
  }

  ngOnInit(): void { }

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    let clusterCountValue = null;
    if (this.form.get('categoriesAutomatically').value == false) {
      clusterCountValue = this.form.get('clusterCount').get('value').value?.toString();
    }

    const settings: ConfigurationSettingsDto[] = [];
    settings.push(
      {
        settingName: this.form.get('apiAddress').get('name').value,
        settingValue: this.form.get('apiAddress').get('value').value,
      },
      {
        settingName: this.form.get('clusteringAddress').get('name').value,
        settingValue: this.form.get('clusteringAddress').get('value').value,
      },
      {
        settingName: this.form.get('identityBaseUrl').get('name').value,
        settingValue: this.form.get('identityBaseUrl').get('value').value,
      },
      {
        settingName: this.form.get('language').get('name').value,
        settingValue: this.form.get('language').get('value').value,
      },
      {
        settingName: this.form.get('classificationServiceType').get('name').value,
        settingValue: this.form.get('classificationServiceType').get('value').value.toString(),
      },
      {
        settingName: this.form.get('serviceStartDateTypeId').get('name').value,
        settingValue: this.form.get('serviceStartDateTypeId').get('value').value.toString(),
      },
      {
        settingName: this.form.get('clusterCount').get('name').value,
        settingValue: clusterCountValue,
      },
      {
        settingName: this.form.get('conversationCount').get('name').value,
        settingValue: this.form.get('conversationCount').get('value').value.toString(),
      }
    );
    this.formClassificationServiceTypeSubscription.unsubscribe();
    this.settingService.saveSetting(settings).subscribe(() => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
      this.setClassificationServiceTypeSubscription();
      this.config.refreshAppState();
    });
  }

  resetSetting() {
    this.aiTopicSettingService.resetSetting().subscribe(res => {
      if (res) {
        this.configStateService.refreshAppState().subscribe(response => {
          this.form
            .get('apiAddress')
            .get('value')
            .setValue(this.config.getSetting(this.apiAddress));
          this.form
            .get('clusteringAddress')
            .get('value')
            .setValue(this.config.getSetting(this.clusteringAddress));
          this.form
            .get('identityBaseUrl')
            .get('value')
            .setValue(this.config.getSetting(this.identityBaseUrl));
          this.form.get('language').get('value').setValue(this.config.getSetting(this.language));
          this.form
            .get('clusterCount')
            .get('value')
            .setValue(this.config.getSetting(this.clusterCount));
          this.form
            .get('classificationServiceType')
            .get('value')
            .setValue(this.config.getSetting(this.classificationServiceType));
          this.form
            .get('serviceStartDateTypeId')
            .get('value')
            .setValue(this.config.getSetting(this.serviceStartDateType));
        });
      }
    });
  }

  popoverClicked(popover: NgbPopover, event: any): boolean {
    popover.toggle();
    event.stopPropagation();
    return false;
  }

  onRadioChange(value) {
    this.publishedAddressRadio = value;
    let clusteringAddress = null;
    if (value == 'clusterAddress') {
      clusteringAddress = this.config.getSetting(this.apiAddress);
    }
    this.form.get('clusteringAddress').get('value').setValue(clusteringAddress);
  }

  removeValidationError(controlName) {
    let control = this.form.get(controlName)['controls']['value'];

    if (control.errors) {
      delete control.errors.required;
      delete control.errors.min;
      delete control.errors.max;

      const errorLength = Object.keys(control.errors).length;

      if (errorLength === 0) {
        control.setErrors(null);
      } else {
        control.setErrors(control.errors);
      }
    } else {
      control.setErrors(null);
    }
  }

  setClassificationServiceTypeSubscription() {
    this.formClassificationServiceTypeSubscription = this.form.get('classificationServiceType').valueChanges.subscribe(classificationServiceType => {
      if (classificationServiceType.value === "AITopic.ClassificationService.OpenAI") {
        this.maxConversationCount = 1000;
      } else {
        this.maxConversationCount = 5000;
      }

      this.form.get('conversationCount').get('value').setValue(this.maxConversationCount);
      this.form
        .get('conversationCount')
        .get('value')
        .setValidators([Validators.required, Validators.min(this.minConversationCount), Validators.max(this.maxConversationCount)]);
      this.form.get('conversationCount').updateValueAndValidity();
    });
  }
}
