import { Sort } from '@angular/material/sort';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';

export class FilterChange {
  static readonly type = '[AgentPerformanceModule] FilterChange';
  constructor(public filters: any) {}
}

export class WidgetsChange {
  static readonly type = '[AgentPerformanceModule] WidgetsChange';
  constructor(public widgets: any) {}
}

export class WidgetFilterChange {
  static readonly type = '[AgentPerformanceModule] WidgetFilterChange';
  component: string;
  constructor(public filters: FilterItemDto[]) {}
}

export class AgentPerformanceSorterChange {
  static readonly type = '[AgentPerformanceModule] AgentPerformanceSorterChange';

  constructor(public sort: Sort) {}
}

export class AgentPerformanceDetailSorterChange {
  static readonly type = '[AgentPerformanceModule] AgentPerformanceDetailSorterChange';

  constructor(public sort: Sort) {}
}
