import { ObjectHelper } from 'src/core/helpers/object.helper';
import { NgbPanelChangeEvent, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { FilterPanelState } from 'src/ca-shared/filter-panel/states/filter-panel.state';
import { FilterPanelStateModel } from './../models/filter-panel.state-model';
import {
  FilterPanelValueChange,
  ChildPanelVisibilityChange,
  ChildPanelActivityChange,
  PanelSizeChange,
} from './../actions/filter-panel.actions';
import { Store } from '@ngxs/store';
import { FilterPanelEditorComponent } from './../models/filter-panel-editor.component';
import { FilterPanelConfigurationModel } from './../models/filter-panel-configuration.model';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { FilterPanelItemConfigurationModel } from '../models/filter-panel-item-configuration.model';
import { FilterButtonType } from 'src/core/models/enum/filter-button-type.enum';

@Component({
  selector: 'ca-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent implements OnInit, AfterViewInit {
  @ViewChild('accordion', { static: true, read: NgbAccordion })
  accordion: NgbAccordion;

  _collapsed = false;
  thisRef = this;
  items: { itemKey: string; editorKey: string; cmp: FilterPanelEditorComponent }[] = [];
  hiddenPanels: string[] = [];
  activePanels: string[] = [];
  activeStickyPanels: string[] = [];
  collapsedWidth = 40;

  private _config: FilterPanelConfigurationModel = {
    key: '',
    hideHeader: false,
    title: '',
    items: [],
    collapsible: false,
    closeOthers: false,
    buttons: {
      expandAll: true,
      clear: true,
      filter: true,
      collapseAll: true,
    },
  };

  @Input()
  set config(value: FilterPanelConfigurationModel) {
    this.items = this.items.filter(x => !value.items.map(i => i.key).includes(x.editorKey));
    value.items = value.items.filter(x => x.items.filter(y => y.hidden !== true).length > 0);
    value.items.forEach(item => {
      item.items = item.items.filter(x => x.hidden !== true);
    });
    this._config = value;

    this.setActiveStickyPanels();
    this.changeDetectorRef.detectChanges();
    if (this.config.disableRestoreFromState !== true) {
      this.restoreState();
    } else {
      this.loadValues(null);
    }
  }

  get config(): FilterPanelConfigurationModel {
    return this._config;
  }

  @Output()
  valueChanges: EventEmitter<{
    config: FilterPanelConfigurationModel;
    values: any;
    buttonClicked: FilterButtonType;
    initial: boolean;
  }> = new EventEmitter();

  @Output()
  collapsed: EventEmitter<{
    width: number;
  }> = new EventEmitter();

  @Output()
  expanded = new EventEmitter();

  get values(): any {
    const result = {};

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];

      const hidden =
        this.hiddenPanels.indexOf(item.itemKey) > -1 || item.cmp.config.hidden === true;

      if (hidden) {
        continue;
      }

      if (item.itemKey && item.itemKey.length > 0) {
        if (!result[item.itemKey]) {
          result[item.itemKey] = {};
        }

        if (item.cmp.ignoreKeyOnValueCollection) {
          result[item.editorKey] = item.cmp.value;
        } else {
          result[item.itemKey][item.editorKey] = item.cmp.value;
        }
      } else {
        result[item.editorKey] = item.cmp.value;
      }
    }

    return ObjectHelper.deepCopy(result);
  }

  get stickyItems(): FilterPanelItemConfigurationModel[] {
    return this.config?.items.filter(i => i.sticky === true);
  }

  get nonStickyItems(): FilterPanelItemConfigurationModel[] {
    return this.config?.items.filter(i => i.sticky !== true);
  }

  hidePanel(key: string): void {
    if (this.hiddenPanels.indexOf(key) < 0) {
      this.hiddenPanels.push(key);
    }
  }

  showPanel(key: string): void {
    const idx = this.hiddenPanels.indexOf(key);

    if (idx > -1) {
      this.hiddenPanels.splice(idx, 1);
    }
  }

  changePanelVisibility(key: string, visible: boolean) {
    const fn = visible ? this.showPanel : this.hidePanel;

    fn.call(this, key);
  }

  getEditor(itemKey: string, editorKey: string): FilterPanelEditorComponent {
    const collection = this.items.filter(x => x.editorKey === editorKey && x.itemKey === itemKey);

    return collection.length > 0 ? collection[0].cmp : null;
  }

  saveState(): void {
    const result = this.getStateObject();

    const actionForValues = new FilterPanelValueChange(this.config.key, result);
    this.store.dispatch(actionForValues);

    const actionForPanelVisibilities = new ChildPanelVisibilityChange(
      this.config.key,
      this.hiddenPanels
    );
    this.store.dispatch(actionForPanelVisibilities);

    const actionForPanelActivities = new ChildPanelActivityChange(
      this.config.key,
      this.activePanels
    );
    this.store.dispatch(actionForPanelActivities);
  }

  getStateObject(): any {
    const result = {};

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];

      if (item.itemKey && item.itemKey.length > 0) {
        if (!result[item.itemKey]) {
          result[item.itemKey] = {};
        }

        if (typeof item.cmp.config.rememberMe === 'function') {
          const rememberMe = item.cmp.config.rememberMe(this.values);

          if (!rememberMe) {
            result[item.editorKey].value = ObjectHelper.deepCopy(item.cmp.config.defaultValue);
            continue;
          }
        }

        if (item.cmp.ignoreKeyOnValueCollection) {
          result[item.editorKey].value = item.cmp.value;
          result[item.editorKey].extraData = item.cmp.extraData;
        } else {
          result[item.itemKey][item.editorKey] = {
            value: item.cmp.value,
            extraData: item.cmp.extraData,
          };
        }
      } else {
        result[item.editorKey].value = item.cmp.value;
        result[item.editorKey].extraData = item.cmp.extraData;
      }
    }
    return ObjectHelper.deepCopy(result);
  }

  expandPanel(panelKey): void {
    var panel;
    if ((panel = this._config.items.find(f => f.key === panelKey))) {
      const panelKey = 'filter-panel-item-' + panel.key;
      this.collapseAll();
      this.accordion.expand(panelKey);
      this.activePanels = [panelKey];
    }
  }

  restoreState(): void {
    let state = this.store.selectSnapshot<FilterPanelStateModel>(FilterPanelState);

    state = ObjectHelper.deepCopy(state);

    let values: any;
    let hiddenPanels: string[];
    let collapsed: boolean;
    if (state.values) {
      state.collapsed[this.config.key];
      hiddenPanels = state.hiddenPanels[this.config.key];
      values = state.values[this.config.key];
    }

    this.hiddenPanels = hiddenPanels ?? [];
    // this.activePanels = activePanels ?? [];
    this._collapsed = collapsed !== null && collapsed !== undefined ? collapsed : false;

    for (let i = 0; i < this._config.items.length; i++) {
      const item = this._config.items[i];
      const panelKey = 'filter-panel-item-' + item.key;

      if (
        this._config.items[i].hideHeader === true &&
        !this.activePanels.includes(panelKey) &&
        this._config.items[i].sticky !== true
      ) {
        this.activePanels.push(panelKey);
      }
    }

    if (this._collapsed) {
      this.collapsed.emit({ width: this.collapsedWidth });
    } else {
      this.expanded.emit();
    }
    if (typeof this.config.filterFnRef === 'function') {
      values = this.config.filterFnRef(values);
    }
    this.loadValues(values, true);
  }

  loadValues(values: any, initial: boolean = false) {
    if (!values) {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];

        item.cmp.value = ObjectHelper.deepCopy(item.cmp.config.defaultValue);
      }
    } else {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];

        if (item.itemKey && item.itemKey.length > 0) {
          if (item.cmp.ignoreKeyOnValueCollection) {
            item.cmp.value = this.validateObjectValue(
              values[item.editorKey]?.value,
              item.cmp.config.defaultValue
            );
            if (values[item.editorKey]?.extraData) {
              item.cmp.extraData = values[item.editorKey]?.extraData;
            }
          } else {
            item.cmp.value = this.validateObjectValue(
              values[item.itemKey][item.editorKey]?.value,
              item.cmp.config.defaultValue
            );
            if (values[item.itemKey][item.editorKey]?.extraData) {
              item.cmp.extraData = values[item.itemKey][item.editorKey]?.extraData;
            }
          }
        } else {
          item.cmp.value = this.validateObjectValue(
            values[item.editorKey]?.value,
            item.cmp.config.defaultValue
          );
          if (values[item.editorKey]?.extraData) {
            item.cmp.extraData = values[item.editorKey]?.extraData;
          }
        }
      }
    }

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];

      item.cmp.emitChangeEvent = true;
      item.cmp.init();
      item.cmp.onChange(initial);
    }

    this.valueChanges.emit({
      config: this.config,
      values: this.values,
      buttonClicked: FilterButtonType.NotButton,
      initial: initial,
    });
  }
  get totalCount(): number {
    let result = 0;

    for (let i = 0; i < this.config.items.length; i++) {
      const item = this.config.items[i];
      const badgeResult = item.getBadgeText(this.values);

      result += badgeResult.count;
    }

    return result;
  }

  get isValid(): boolean {
    let valid = true;

    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      const form = item.cmp.editorForm;

      if (this.hiddenPanels.indexOf(item.cmp.parentItemKey) > -1) {
        continue;
      }

      valid = valid && form.valid;
    }

    return valid;
  }

  fireChangeEventAndSaveState(): void {
    this.valueChanges.emit({
      config: this.config,
      values: this.values,
      buttonClicked: FilterButtonType.FilterButton,
      initial: false,
    });
    this.saveState();
  }

  getCardClass(item: FilterPanelItemConfigurationModel) {
    let cls =
      this.hiddenPanels.indexOf(item.key) > -1
        ? `filter-panel-item hidden filter-panel-item-${item.key}`
        : `filter-panel-item filter-panel-item-${item.key}`;

    if (item.hideHeader === true) {
      cls = `${cls} with-hidden-header`;
    }

    if (item.sticky === true) {
      cls = `${cls} sticky-item`;
    }

    return cls;
  }

  onExpandClick(eventArgs) {
    this.expanded.emit();
    this._collapsed = false;

    const action = new PanelSizeChange(this.config.key, this._collapsed);
    this.store.dispatch(action);
  }

  onCollapseClick() {
    this.collapsed.emit({ width: this.collapsedWidth });
    this._collapsed = true;

    const action = new PanelSizeChange(this.config.key, this._collapsed);
    this.store.dispatch(action);
  }

  onPanelChange(eventArgs: NgbPanelChangeEvent) {
    if (eventArgs.nextState && !this.activePanels.includes(eventArgs.panelId)) {
      this.activePanels.push(eventArgs.panelId);
    } else {
      const idx = this.activePanels.indexOf(eventArgs.panelId);

      this.activePanels.splice(idx, 1);
    }
  }

  onSubmitFilters(eventArgs: MouseEvent, accordion: NgbAccordion) {
    this.activePanels = [];
    this.fireChangeEventAndSaveState();

    if (accordion) {
      accordion.collapseAll();
    }
  }

  onClearFilters() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];

      if (item.cmp.config.emitChangeEvent !== true) {
        item.cmp.emitChangeEvent = false;
      }

      if (item.cmp.config.preventReset !== true) {
        item.cmp.value = ObjectHelper.deepCopy(item.cmp.config.defaultValue);
        item.cmp.extraData = {};
        item.cmp.reset();

        item.cmp.onChange();
        item.cmp.emitChangeEvent = true;
      }
    }

    this.valueChanges.emit({
      config: this.config,
      values: this.values,
      buttonClicked: FilterButtonType.ClearButton,
      initial: false,
    });

    this.saveState();
  }

  onEnterKeyPressed(eventArgs: KeyboardEvent) {
    this.collapseAll();
    this.fireChangeEventAndSaveState();
  }

  constructor(private store: Store, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  collapseAll() {
    this.activePanels = [];
    if (this.accordion) {
      this.accordion.collapseAll();
    }
  }

  setItemValue(itemKey: string, editorKey: string, value: any) {
    var item = this.items.find(x => x.itemKey === itemKey && x.editorKey === editorKey);
    if (item) {
      item.cmp.value = value;
      this.onSubmitFilters(null, null);
    }
  }

  private setActiveStickyPanels() {
    this.stickyItems?.forEach(item => {
      this.activeStickyPanels.push(`filter-panel-item-${item.key}`);
    });
  }

  private removeHiddenComponents(value: FilterPanelConfigurationModel) {
    value.items = value.items.filter(x => x.items.filter(y => y.hidden !== true).length > 0);
    value.items.forEach(item => {
      item.items = item.items.filter(x => x.hidden !== true);
    });
    return value;
  }

  private validateObjectValue(value, defaultValue): any {
    let newValue = value;
    if (defaultValue != null) {
      let defautlValueType = typeof defaultValue;

      if (value != null) {
        if (Array.isArray(defaultValue)) {
          if (!Array.isArray(value)) {
            newValue = defaultValue;
          }
        } else if (defautlValueType == 'object') {
          if (!ObjectHelper.isObjectKeysEqual(value, defaultValue)) {
            newValue = defaultValue;
          }
        }
      } else {
        newValue = defaultValue;
      }
    }
    return newValue;
  }
}
