import { PlayerService } from './../services/player.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ProgressAnimationEnd } from '@angular/material/progress-bar';
import WaveSurfer from 'node_modules/wavesurfer.js';
import { PlayerStatus } from '../models/player-status.enum';
import RegionsPlugin from 'node_modules/wavesurfer.js/dist/plugin/wavesurfer.regions.js';
import { ConversationMarkDto } from 'src/core/models/mark/conversation-mark.dto';
import { ConversationMarkState } from 'src/core/states/conversation/conversation-mark.state';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ConfigStateService, downloadBlob, LocalizationService } from '@abp/ng.core';
import { MarksStateModel } from 'src/core/models/conversation/marks-data.state-model';
import { PlayerTimelineCategoryLayerComponent } from '../player-timeline-category-layer/player-timeline-category-layer.component';
import { PlayerTimelineConversationMarkLayerComponent } from '../player-timeline-conversationmark-layer/player-timeline-conversationmark-layer.component';
import { take, tap, takeUntil } from 'rxjs/operators';
import { CallService } from 'src/core/services/conversation/call.service';
import { VideoPlayerService } from 'src/core/services/video-player/video-player.service';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { CallAgentChannel } from 'src/core/models/generic-lookup-type/call/call-agent-channel.glt';
import { ConversationMarkChannel } from 'src/core/models/generic-lookup-type/conversation/conversation-mark-channel.glt';
import { ConversationMarkType } from 'src/core/models/generic-lookup-type/conversation/conversation-mark-type.glt';
import { PlayerTimelineSentimentLayerComponent } from '../player-timeline-sentiment-layer/player-timeline-sentiment-layer.component';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { ConversationModuleStateModel } from 'src/core/models/conversation/conversation-module.state-model';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { CompactPlayerPositionChange } from 'src/core/actions/conversation/conversation-module.actions';
import { CategoryMarkerDto } from '../models/category-marker.dto';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';

@Component({
  selector: 'ca-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class PlayerComponent extends HasSubscription implements OnInit, OnDestroy {
  leftGain: any;
  rightGain: any;
  primaryChannelCategoryMarks: CategoryMarkerDto[];
  secondaryChannelCategoryMarks: CategoryMarkerDto[];
  errorMessage = '';
  lastEndPosition: number;
  @Select(ConversationMarkState.getData)
  markData$: Observable<MarksStateModel[]>;
  speed: number;
  volume: number;
  autoPlaySettingEnable = true;
  defaultAgentChannelId = CallAgentChannel.right;
  _sentimentsActivated: boolean;
  conversationId: number;
  playScreenRecordingsIndependently: boolean;
  sideId: number = ConversationSide.any;
  
  private _splitChannels: boolean = true;
  private continueConversationId: number;
  private continueConversationPosition: number;

  get sentimentsActivated(): boolean {
    return this._sentimentsActivated;
  }
  set sentimentsActivated(val) {
    this._sentimentsActivated = val;
    this.cdr.detectChanges();
  }

  @Input()
  set splitChannels(value: boolean) {
    this._splitChannels = value;

    if (this._wavesurferInstance) {
      const height = value ? this._height / 2 : this._height;

      this._wavesurferInstance.params.splitChannels = value;
      this._wavesurferInstance.params.height = height;

      this._wavesurferInstance.drawer.setHeight(height);
      this._wavesurferInstance.drawBuffer();
    }
  }

  @Input()
  hideChannelIcons = false;

  @Input()
  hideLabels = false;

  @Input()
  showSentiments = false;

  @Input()
  chatMode = false;

  @Input()
  set fillParent(value: boolean) {
    this._fillParent = value;
    this.cdr.detectChanges();
  }
  @Input()
  agentChannel: number = this.defaultAgentChannelId;

  @Input()
  set categoryMarks(categoryMarks: CategoryMarkerDto[]) {
    if (categoryMarks.length > 0 && this.sideId !== ConversationSide.any) {
      if (this.sideId === ConversationSide.agent) {
        categoryMarks = categoryMarks.filter(x => x.channel == 1)
      } else {
        categoryMarks = categoryMarks.filter(x => x.channel == 2)
      }
    }
    
    if (this.isAgentChannelRight) {
      this.primaryChannelCategoryMarks = categoryMarks.filter(x => x.channel === 1);
      this.secondaryChannelCategoryMarks = categoryMarks.filter(x => x.channel === 2);
    } else {
      this.primaryChannelCategoryMarks = categoryMarks.filter(x => x.channel === 2);
      this.secondaryChannelCategoryMarks = categoryMarks.filter(x => x.channel === 1);
    }
    let main = this;
    main.initializeLayers();
    this.cdr.detectChanges();
  }

  @Input()
  set conversationMarks(conversationMarks: ConversationMarkDto[]) {
    this._conversationMarks = conversationMarks;
  }

  get conversationMarks(): ConversationMarkDto[] {
    return this._conversationMarks;
  }

  get fillParent(): boolean {
    return this._fillParent;
  }

  get miniPlayerValue() {
    return this._miniPlayerValue;
  }

  get loadingProgress(): number {
    return this._loadingProgress;
  }

  get agentSentimentMarks() {
    return this.conversationMarks?.filter(
      cm =>
        cm.channel.id == ConversationMarkChannel.agent &&
        [
          ConversationMarkType.negativeSentiment,
          ConversationMarkType.neutralSentiment,
          ConversationMarkType.positiveSentiment,
        ].indexOf(cm.type.id) > -1
    );
  }

  get customerSentimentMarks() {
    return this.conversationMarks?.filter(
      cm =>
        cm.channel.id == ConversationMarkChannel.customer &&
        [
          ConversationMarkType.negativeSentiment,
          ConversationMarkType.neutralSentiment,
          ConversationMarkType.positiveSentiment,
        ].indexOf(cm.type.id) > -1
    );
  }

  @Input()
  set width(value: number) {
    this._width = value;

    this.cdr.detectChanges();

    if (this._wavesurferInstance) {
      setTimeout(() => {
        this._wavesurferInstance.drawer.setWidth(this._width);
        this._wavesurferInstance.drawBuffer();
      }, 100);
    }
  }

  get width(): number {
    return this._width;
  }

  @Input()
  set expandedPlayerHeight(value: number) {
    this._expandedPlayerHeight = value;

    if (this._expanded) {
      this._height = this._expandedPlayerHeight;
      this.cdr.detectChanges();
    }
  }

  get expandedPlayerHeight(): number {
    return this._expandedPlayerHeight;
  }

  @Input()
  autoPlay: boolean = true;

  @Input()
  mutableChannel: boolean;

  @Input()
  isVideo = false;

  @Input()
  set minimizedPlayerHeight(value: number) {
    this._minimizedPlayerHeight = value;

    if (!this._expanded) {
      this._height = this._minimizedPlayerHeight;
      this.cdr.detectChanges();
    }
  }

  get minimizedPlayerHeight(): number {
    return this._minimizedPlayerHeight;
  }

  @Input()
  set expanded(value: boolean) {
    this._expanded = value;

    this.height = this._expanded ? this._expandedPlayerHeight : this._minimizedPlayerHeight;

    if (
      this._status !== PlayerStatus.NotInitialized &&
      this._status !== PlayerStatus.Initialized &&
      this._expanded
    ) {
      setTimeout(() => {
        this._wavesurferInstance.drawBuffer();
      }, 100);
    }

    this.cdr.detectChanges();
  }

  get expanded(): boolean {
    return this._expanded;
  }

  @Input()
  set contentSource(value: string) {
    this._contentSource = value;
  }

  get contentSource(): string {
    return this._contentSource;
  }

  @Input()
  set contentOtherSource(value: string) {
    this._contentOtherSource = value;
  }

  get contentOtherSource(): string {
    return this._contentOtherSource;
  }

  @Input()
  set peakSource(value: string) {
    this._peakSource = value;
  }

  get peakSource(): string {
    return this._peakSource;
  }

  @Input()
  set downloadUrl(value: string) {
    this._downloadUrl = value;
  }

  get downloadUrl(): string {
    return this._downloadUrl;
  }

  @Input()
  set fileName(value: string) {
    this._fileName = value;
  }

  get fileName(): string {
    return this._fileName;
  }

  set hideAgent(value: boolean) {
    this._hideAgent = value;
    this.cdr.detectChanges();
  }
  get hideAgent(): boolean {
    return this._hideAgent;
  }

  @Input()
  set loadAtOnce(value: boolean) {
    this._loadAtOnce = value;
  }

  get loadAtOnce(): boolean {
    return this._loadAtOnce;
  }

  get height(): number {
    return this._height;
  }

  set height(value: number) {
    this._previousHeight = this._height;
    this._height = value;

    this.heightChange.emit({
      previousHeight: this._previousHeight,
      newHeight: this._height,
    });

    this.cdr.detectChanges();
  }

  get playerHeight(): number {
    const layerHeights = this.hideLabels ? 0 : 80;
    return this._height + layerHeights;
  }

  get playing(): boolean {
    return this._wavesurferInstance ? this._wavesurferInstance.isPlaying() : false;
  }

  get durationString(): string {
    return this._durationString;
  }
  get duration(): number {
    return this._duration;
  }
  get loading(): boolean {
    return this._status === PlayerStatus.LoadingContent;
  }

  set status(value: PlayerStatus) {
    this._status = value;

    if (!this._destroying) {
      this.statusChange.emit({ status: this._status });
      this.cdr.detectChanges();
    }
  }

  get status(): PlayerStatus {
    return this._status;
  }

  get isAgentChannelRight(): boolean {
    return this.agentChannel === this.defaultAgentChannelId;
  }

  get blocked(): boolean {
    const blockedStatus = [
      PlayerStatus.Initialized,
      PlayerStatus.ContentSet,
      PlayerStatus.DrawingWaveform,
      PlayerStatus.LoadingContent,
      PlayerStatus.LoadingPeaks,
      PlayerStatus.Error,
    ];

    return blockedStatus.indexOf(this._status) > -1;
  }

  get currentTime(): number {
    return this._currentTime;
  }

  get categoryLayerClass() {
    if (!this.hideChannelIcons) {
      return 'channel-spacer';
    } else {
      return '';
    }
  }
  get conversationMarkClass() {
    if (!this.hideChannelIcons) {
      return 'channel-spacer';
    } else {
      return '';
    }
  }

  get sentimentClass(): string {
    let sentimentClass = '';
    if (this.blocked || !this._splitChannels) {
      sentimentClass = 'd-none';
    } else if (this.sentimentsActivated) {
      sentimentClass = 'sentiment-layer';
    } else {
      sentimentClass = 'sentiment-layer passive-sentiment';
    }

    if (!this.hideChannelIcons) {
      sentimentClass += ' with-channel-icons';
    }

    return sentimentClass;
  }

  @Output()
  heightChange: EventEmitter<{
    previousHeight: number;
    newHeight: number;
  }> = new EventEmitter();

  @Output()
  ready: EventEmitter<{
    duration: number;
    durationString: string;
  }> = new EventEmitter();

  @Output()
  positionChange: EventEmitter<{
    currentTime: number;
    currentTimeString: string;
  }> = new EventEmitter();

  @Output()
  startPlaying: EventEmitter<{
    byUserInteraction: boolean;
  }> = new EventEmitter();

  @Output()
  pausePlaying: EventEmitter<{
    byUserInteraction: boolean;
  }> = new EventEmitter();

  @Output()
  finishPlaying = new EventEmitter();

  @Output()
  volumeChange: EventEmitter<{
    volume: number;
  }> = new EventEmitter();

  @Output()
  statusChange: EventEmitter<{
    status: PlayerStatus;
  }> = new EventEmitter();

  @Output()
  error: EventEmitter<{
    message: string;
    isVideo: boolean;
  }> = new EventEmitter();

  @ViewChild('mainContainer', {
    read: ViewContainerRef,
    static: true,
  })
  mainContainer: ViewContainerRef;

  @ViewChild('expandedPlayerWrapper', {
    read: ViewContainerRef,
    static: true,
  })
  expandedPlayerWrapper: ViewContainerRef;

  @ViewChild('miniPlayerWrapper', {
    read: ViewContainerRef,
    static: true,
  })
  miniPlayerWrapper: ViewContainerRef;
  @ViewChild('conversationMarkLayer')
  conversationMarkLayer: PlayerTimelineConversationMarkLayerComponent;
  @ViewChild('secondaryCategoryLayer') secondaryCategoryLayer: PlayerTimelineCategoryLayerComponent;
  @ViewChild('primaryCategoryLayer') primaryCategoryLayer: PlayerTimelineCategoryLayerComponent;
  @ViewChild('primarySentimentLayer') primarySentimentLayer: PlayerTimelineSentimentLayerComponent;
  @ViewChild('secondarySentimentLayer')
  secondarySentimentLayer: PlayerTimelineSentimentLayerComponent;

  private _width: number;
  private _height: number;
  private _previousHeight: number;
  private _expanded = true;
  private _expandedPlayerHeight: number;
  private _minimizedPlayerHeight: number;
  private _wavesurferInstance: any;
  private _duration = 0;
  private _durationString = '00:00';
  private _currentTime = 0;
  private _currentTimeString = '00:00';
  private _miniPlayerValue = 0;
  private _resumeOnAnimationEnd = false;
  private _contentSource: string;
  private _contentOtherSource: string;
  private _peakSource: string;
  private _loadAtOnce = false;
  private _loadingProgress = 0;
  private _playWhenReady = false;
  private _status: PlayerStatus = PlayerStatus.NotInitialized;
  private _fillParent = false;
  private _destroying = false;
  private _conversationMarks: ConversationMarkDto[] = [];
  private waveformReady = false;
  private audioReady = false;
  private _hideAgent: boolean;
  private _downloadUrl: string;
  private _fileName: string;
  private _byUserInteraction: boolean;
  private _videoElement: HTMLVideoElement = null;

  markInfo: any = {};

  playerStatus = PlayerStatus;

  private initializeInstance(): void {
    let backend = 'MediaElement';

    if (this.mutableChannel) {
      backend = 'WebAudio';
    }

    this._wavesurferInstance = WaveSurfer.create({
      barWidth: 2,
      barHeight: 1,
      barGap: null,
      barMinHeight: 0.1,
      backend: backend,
      splitChannels: this._splitChannels,
      splitChannelsOptions: {
        overlay: false,
        filterChannels: [],
        channelColors: {
          0: {
            progressColor: this.isAgentChannelRight ? '#e1d3bd' : '#c8d1dc',
            waveColor: this.isAgentChannelRight ? '#feba57' : '#8191a6',
          },
          1: {
            progressColor: this.isAgentChannelRight ? '#c8d1dc' : '#e1d3bd',
            waveColor: this.isAgentChannelRight ? '#8191a6' : '#feba57',
          },
        },
      },
      fillParent: true,
      responsive: true,
      hideScrollbar: true,
      container: this.expandedPlayerWrapper.element.nativeElement as Element,
      height: this._splitChannels ? this._height / 2 : this._height,
      normalize: true,
      cursorColor: '#007bff',
      drawingContextAttributes: {
        // Boolean that hints the user agent to reduce the latency
        // by desynchronizing the canvas paint cycle from the event
        // loop
        desynchronized: false,
      },
      plugins: [
        RegionsPlugin.create({
          regions: [],
          dragSelection: true,
        }),
      ],
    });
    this.initializeChannelMuteOptions();
  }
  initializeChannelMuteOptions() {
    if (this.mutableChannel) {
      const splitter = this._wavesurferInstance.backend.ac.createChannelSplitter(2);
      const merger = this._wavesurferInstance.backend.ac.createChannelMerger(2);
      this.leftGain = this._wavesurferInstance.backend.ac.createGain();
      this.rightGain = this._wavesurferInstance.backend.ac.createGain();
      splitter.connect(this.leftGain, 0);
      splitter.connect(this.rightGain, 1);
      this.leftGain.connect(merger, 0, 0);
      this.rightGain.connect(merger, 0, 1);
      this._wavesurferInstance.backend.setFilters([splitter, this.leftGain, merger]);
    }
  }
  private destroyInstance(): void {
    if (this._status !== PlayerStatus.NotInitialized) {
      this._wavesurferInstance?.destroy();
      this._wavesurferInstance = null;
      this.status = PlayerStatus.NotInitialized;
      this._destroying = false;
    }
  }
  private onWavesurferReady(): void {
    if (!this.mutableChannel) {
      this.audioReady = true;
      this.checkForReadiness();
    } else {
      this.setDuration();
      this.status = PlayerStatus.ReadyToPlay;
      this.ready.emit({
        duration: this._duration,
        durationString: this._durationString,
      });
      this.initializeLayers();
      if (this._playWhenReady) {
        this._wavesurferInstance.play();
      }
    }
  }

  private onWaveformReady() {
    this.waveformReady = true;
    this.checkForReadiness();
  }

  private checkForReadiness() {
    if (this.audioReady && this.waveformReady) {
      this.setDuration();
      this.status = PlayerStatus.ReadyToPlay;
      this.ready.emit({
        duration: this._duration,
        durationString: this._durationString,
      });
      this.initializeLayers();
      if (this._playWhenReady) {
        this._wavesurferInstance.play();
      }
    }
  }

  onTryAgainLinkClicked(eventArgs: MouseEvent) {
    this.load(false, true);
  }

  initializeLayers() {
    if (this.hideLabels) {
      return;
    }

    if (this.showSentiments) {
      if (this.isAgentChannelRight) {
        this.primarySentimentLayer.initializeLayer(this.agentSentimentMarks, this.duration);
        this.secondarySentimentLayer.initializeLayer(this.customerSentimentMarks, this.duration);
      } else {
        this.secondarySentimentLayer.initializeLayer(this.agentSentimentMarks, this.duration);
        this.primarySentimentLayer.initializeLayer(this.customerSentimentMarks, this.duration);
      }
    }

    this.primaryCategoryLayer.initializeLayer(this.primaryChannelCategoryMarks, this.duration);
    this.secondaryCategoryLayer.initializeLayer(this.secondaryChannelCategoryMarks, this.duration);
    this.initializeConversationMarkLayer();
  }

  initializeConversationMarkLayer() {
    if (this.hideLabels) {
      return;
    }

    const playerWidth = this.expandedPlayerWrapper
      ? this.expandedPlayerWrapper.element.nativeElement.offsetWidth
      : 0;
    if (this.conversationMarkLayer != undefined) {
      this.conversationMarkLayer.initializeLayer(
        this.conversationMarks,
        this.duration,
        playerWidth
      );
    }
  }

  private onWavesurferPlay(): void {
    if (this.chatMode) {
      this._wavesurferInstance.pause();
    }

    this.status = PlayerStatus.Playing;
    this.startPlaying.emit({ byUserInteraction: this._byUserInteraction });

    this._byUserInteraction = false;
  }

  private onWavesurferPause(): void {
    this.status = PlayerStatus.Paused;
    this.pausePlaying.emit({ byUserInteraction: this._byUserInteraction });

    this._byUserInteraction = false;
  }

  private onWavesurferAudioProcess(position: number): void {
    const previous = this._currentTime;

    this.setCurrentTime(position);
    this.updateMiniPlayerValue(position);

    if (previous !== this._currentTime) {
      this.positionChange.emit({
        currentTime: this._currentTime,
        currentTimeString: this._currentTimeString,
      });
    }
  }

  private onWavesurferSeek(progress: number): void {
    const position = Math.floor(this._duration * progress);

    this.setCurrentTime(position);
    this.updateMiniPlayerValue(position);

    if (this.status === PlayerStatus.Playing) {
      this._wavesurferInstance.play(position);
    }

    this.positionChange.emit({
      currentTime: this._currentTime,
      currentTimeString: this._currentTimeString,
    });

    this.clearRegion();
  }

  private onWavesurferFinish(): void {
    this.status = PlayerStatus.Stopped;
    this.finishPlaying.emit();
  }

  private onWavesurferVolumeChange(volume: number): void {
    this.volumeChange.emit({ volume: volume * 100 });
  }

  private onWavesurferLoading(progress: number) {
    if (this.status !== PlayerStatus.LoadingContent) {
      this.status = PlayerStatus.LoadingContent;
    }

    if (progress === 100) {
      this.status = PlayerStatus.DrawingWaveform;
    }

    this._loadingProgress = progress;

    this.cdr.detectChanges();
  }

  private onWavesurferError(msg: string) {
    this.status = PlayerStatus.Error;
    this.errorMessage = this.localizationService.instant('Conversation::VoiceRecordCouldNotLoaded');
    if (msg == 'Error loading media element') {
      this.errorMessage +=
        ' ' +
        this.localizationService.instant('Conversation::RecordCannotBeAccessedOrNotSupported');
    }
    this.cdr.detectChanges();

    console.warn('Player error: ' + msg);
    this.error.emit({ message: msg, isVideo: this.isVideo });
  }

  private bindWavesurferEvents(): void {
    this._wavesurferInstance.on('ready', this.onWavesurferReady.bind(this));

    this._wavesurferInstance.on('play', this.onWavesurferPlay.bind(this));

    this._wavesurferInstance.on('pause', this.onWavesurferPause.bind(this));

    this._wavesurferInstance.on('audioprocess', this.onWavesurferAudioProcess.bind(this));

    this._wavesurferInstance.on('seek', this.onWavesurferSeek.bind(this));

    this._wavesurferInstance.on('finish', this.onWavesurferFinish.bind(this));

    this._wavesurferInstance.on('volume', this.onWavesurferVolumeChange.bind(this));

    this._wavesurferInstance.on('loading', this.onWavesurferLoading.bind(this));

    this._wavesurferInstance.on('error', this.onWavesurferError.bind(this));

    this._wavesurferInstance.on('region-click', this.onRegionClick.bind(this));

    this._wavesurferInstance.on('region-created', this.onRegionCreated.bind(this));

    this._wavesurferInstance.on('waveform-ready', this.onWaveformReady.bind(this));
  }

  private unbindWavesurferEvents(): void {
    if (this._wavesurferInstance) {
      this._wavesurferInstance.un('ready');
      this._wavesurferInstance.un('play');
      this._wavesurferInstance.un('pause');
      this._wavesurferInstance.un('audioprocess');
      this._wavesurferInstance.un('seek');
      this._wavesurferInstance.un('finish');
      this._wavesurferInstance.un('mute');
      this._wavesurferInstance.un('volume');
      this._wavesurferInstance.un('loading');
      this._wavesurferInstance.un('error');
      this._wavesurferInstance.un('waveform-ready');
    }
  }

  loadWithPeaks() {
    this.waveformReady = false;
    this.audioReady = false;

    this.service
      .getPeaks(this._peakSource)
      .pipe(take(1))
      .pipe(
        tap({
          next: (peakResponse: any) => {
            const peaks = [[], []];

            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < peakResponse.data.length; i++) {
              peaks[1].push(peakResponse.data[i++]);
              peaks[1].push(peakResponse.data[i++]);
              peaks[0].push(peakResponse.data[i++]);
              peaks[0].push(peakResponse.data[i]);
            }

            if (this.playScreenRecordingsIndependently) {
              this.loadSource(this._contentOtherSource, peaks, 'metadata');
            } else {
              this.loadSource(this._contentSource, peaks, 'metadata');
            }
          },
          error: () => {
            const emptyPeak = [[], []];

            if (this.playScreenRecordingsIndependently) {
              this.loadSource(this._contentOtherSource, emptyPeak, 'metadata');
            } else {
              this.loadSource(this._contentSource, emptyPeak, 'metadata');
            }
          },
        })
      )
      .subscribe();
  }

  play(positionSec?: number, endPositionSec?: number, byUserInteraction?: boolean) {
    this._byUserInteraction = byUserInteraction != null ? byUserInteraction : false;

    if (this.continueConversationId > 0 || this.continueConversationPosition > 0) {
      if (this.continueConversationId == this.conversationId) {
        positionSec = this.continueConversationPosition;
      }

      this.continueConversationId = -1;
      this.continueConversationPosition = 0;

      const CompactPlayerPositionAction = new CompactPlayerPositionChange(-1, 0);
      this.store.dispatch(CompactPlayerPositionAction);
    }

    if (!this._loadAtOnce && this._status === PlayerStatus.ContentSet) {
      this._playWhenReady = true;
      this.status = PlayerStatus.LoadingContent;
      this.loadWithPeaks();
      this._wavesurferInstance.setPlaybackRate(this.speed);
      this._wavesurferInstance.setVolume(this.volume / 100);

      this.cdr.detectChanges();

      this.ready.pipe(takeUntil(this.autoUnsubscribeNotifier)).subscribe(args => {
        this.onReadyActions(positionSec, endPositionSec);
      });
    } else {
      this.onReadyActions(positionSec, endPositionSec);
    }
  }

  private onReadyActions(positionSec?: number, endPositionSec?: number) {
    if (endPositionSec == null) {
      if (
        this.lastEndPosition != null &&
        Math.floor(this.lastEndPosition) > Math.floor(this._currentTime)
      ) {
        this._wavesurferInstance.play(positionSec, this.lastEndPosition);
      } else {
        this.lastEndPosition = null;
        this._wavesurferInstance.play(positionSec);
      }
    } else {
      this.lastEndPosition = endPositionSec;
      this._wavesurferInstance.play(positionSec, endPositionSec);
    }
  }

  pause(byUserInteraction?: boolean) {
    this._byUserInteraction = byUserInteraction != null ? byUserInteraction : false;

    this._wavesurferInstance.pause();
  }

  stop(byUserInteraction?: boolean) {
    this._byUserInteraction = byUserInteraction != null ? byUserInteraction : false;

    this.lastEndPosition = null;
    if (this._wavesurferInstance) {
      if (this._status != PlayerStatus.Stopped && this._status != PlayerStatus.Error) {
        this._status = PlayerStatus.Stopped;
        this._currentTime = 0;
        this._wavesurferInstance.stop();
      }
    }
  }

  mute() {
    this._wavesurferInstance.setMute(true);
  }

  unmute() {
    this._wavesurferInstance.setMute(false);
  }

  download() {
    this.callService
      .downloadAudio(this.downloadUrl)
      .pipe(take(1))
      .subscribe(result => {
        if (result.size > 0) {
          if (this.fileName) {
            let extension = result.type.split('/')[1];
            downloadBlob(result, this.fileName + '.' + extension);
          } else {
            downloadBlob(result, 'audioFile.wav');
          }
        }
      });
  }

  muteAgentChannel() {
    this._wavesurferInstance.params.splitChannelsOptions.filterChannels = [0];
    this.leftGain.gain.value = 0;
    this.hideAgent = true;
    this.refresh();
  }
  unMuteAgentChannel() {
    this._wavesurferInstance.params.splitChannelsOptions.filterChannels = [];
    this.leftGain.gain.value = 1;
    this.hideAgent = false;
    this.refresh();
  }

  changeVolume(volume: number) {
    this.volume = volume;
    this._wavesurferInstance.setVolume(volume / 100);
  }

  changeSpeed(speed: number) {
    this.speed = speed;
    this._wavesurferInstance.setPlaybackRate(this.speed);
  }

  updateMiniPlayerValue(position: number) {
    this._miniPlayerValue = Number(((position / this._duration) * 100).toFixed(2));

    this.cdr.detectChanges();
  }

  load(reset: boolean = true, loadAtOnceTemporarily: boolean = false) {
    if (reset) {
      this.reset();
    }

    if (this.isVideo) {
      this._videoElement = this.videoPlayerService.videoElement;

      this._videoElement.setAttribute('src', this.contentSource);
      this._videoElement.controls = true;
    }
    this._wavesurferInstance.params.removeMediaElementOnDestroy = false;
    this._wavesurferInstance.params.mediaControls = false;
    this._wavesurferInstance.params.mediaType = this.isVideo ? 'video' : 'audio';

    if (!this.mutableChannel) {
      if (this.autoPlay && this.autoPlaySettingEnable) {
        let subscription = this.statusChange.subscribe(res => {
          if (res.status === PlayerStatus.ContentSet) {
            let audioContext = new window.AudioContext();
            if (audioContext.state !== 'suspended') {
              this.play();
              if (this._videoElement) {
                this._videoElement.play();
              }
            } else {
              console.warn('browser does not allow autoplay');
            }
            audioContext.close();

            subscription.unsubscribe();
          }
        });
      }

      if (this._loadAtOnce || loadAtOnceTemporarily || this.chatMode) {
        this.status = PlayerStatus.LoadingContent;
        this._playWhenReady = false;
        this.loadWithPeaks();
      } else {
        this.status = PlayerStatus.ContentSet;
      }
    } else {
      this.status = PlayerStatus.LoadingContent;
      this._playWhenReady = false;
      this.loadSource(this._contentSource);
    }

    this.cdr.detectChanges();
  }

  loadSource(url, peaks: any[][] = null, preload: string = null): void {
    if (this.isVideo) {
      if (this.playScreenRecordingsIndependently) {
        this._wavesurferInstance.load(url, peaks, preload);
      } else {
        this._wavesurferInstance.load(this._videoElement, peaks);
      }

      this._videoElement.controls = true;
    } else if (peaks) {
      this._wavesurferInstance.load(url, peaks, preload);
    } else {
      this._wavesurferInstance.load(url);
    }
  }

  setDuration(clear = false) {
    const duration = clear ? 0 : this._wavesurferInstance.getDuration();
    const seconds = Math.floor(duration);

    this._wavesurferInstance.setPlayEnd(seconds);

    this._duration = duration;
    this._durationString = this.caTimePipe.transform(seconds);
  }

  setCurrentTime(position: number) {
    const seconds = Math.floor(position);

    this._currentTime = position;
    this._currentTimeString = this.caTimePipe.transform(seconds);
  }

  reset() {
    this.stop();
    this._wavesurferInstance.empty();
    this.setDuration(true);
    this.setCurrentTime(0);
    this._loadingProgress = 0;
    this.status = PlayerStatus.Initialized;

    this.destroyPlayer();
    this.initPlayer();

    this.cdr.detectChanges();
  }

  onPlayButtonClick(eventArgs: MouseEvent) {
    this.play();
  }

  onMiniPlayerClick(eventArgs: MouseEvent) {
    const el = eventArgs.currentTarget as HTMLElement;
    const offsetLeft = el.offsetParent
      ? (el.offsetParent as HTMLElement).offsetLeft
      : el.offsetLeft;
    const clickedPosition = eventArgs.pageX - offsetLeft;
    const barWidth = this.miniPlayerWrapper.element.nativeElement.offsetWidth;
    const duration = this._wavesurferInstance.getDuration();
    const unitDuration = duration / barWidth;
    const target = unitDuration * clickedPosition;

    if (this.playing) {
      this._wavesurferInstance.pause();
      this._resumeOnAnimationEnd = true;
    }

    this._wavesurferInstance.seekTo(target / duration);
  }

  onSentimentLayerClick(eventArgs: MouseEvent) {
    const el = eventArgs.currentTarget as HTMLElement;
    const offsetLeft = el.offsetParent
      ? (el.offsetParent as HTMLElement).offsetLeft
      : el.offsetLeft;
    const clickedPosition = eventArgs.pageX - 50;
    const barWidth = el.offsetWidth;
    const duration = this._wavesurferInstance.getDuration();
    const unitDuration = duration / barWidth;
    const target = unitDuration * clickedPosition;

    if (this.playing) {
      this._wavesurferInstance.pause();
      this._resumeOnAnimationEnd = true;
    }

    this._wavesurferInstance.seekTo(target / duration);
  }

  onMiniPlayerAnimationEnd(eventArgs: ProgressAnimationEnd) {
    if (this._resumeOnAnimationEnd) {
      this._wavesurferInstance.play();
      this._resumeOnAnimationEnd = false;
    }
  }

  private onRegionClick(region, event) {
    event.stopPropagation();
    region.play();
  }

  private onRegionCreated(region, event) {
    this.clearRegion();

    region.id = 'region_1';
  }

  private clearRegion() {
    if (this._wavesurferInstance.regions.list['region_1'] != null) {
      this._wavesurferInstance.regions.list['region_1'].remove();
    }
  }

  addRegion(startMillisecond, endMillisecond) {
    this._wavesurferInstance.addRegion({
      id: 'region_1',
      start: startMillisecond / 1000,
      end: endMillisecond / 1000,
      loop: false,
    });
  }

  regions() {
    return this._wavesurferInstance?.regions;
  }

  refresh() {
    if (this._wavesurferInstance) {
      this._wavesurferInstance.drawBuffer();
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private localizationService: LocalizationService,
    private service: PlayerService,
    private store: Store,
    private callService: CallService,
    private videoPlayerService: VideoPlayerService,
    private globalSettingsService: GlobalSettingsService,
    private config: ConfigStateService,
    private caTimePipe: CATimePipe
  ) {
    super();

    const state = this.store.selectSnapshot<ConversationModuleStateModel>(ConversationModuleState);
    this.continueConversationId = state.player.conversationId;
    this.continueConversationPosition = state.player.position;
    this.playScreenRecordingsIndependently =
      this.config.getSetting('ScreenRecorder.VideoPlayer.Enabled').toLowerCase() === 'true'
        ? true
        : false;
    this.autoPlaySettingEnable = this.globalSettingsService.autoPlayEnabled;

    if (state && state.filterFormValues && state.filterFormValues.quickSearchTerm && state.filterFormValues.quickSearchTerm.sideId)
    {
      this.sideId = state.filterFormValues.quickSearchTerm.sideId;
    }
  }

  ngOnInit() {
    this.initPlayer();

    this.markData$.pipe(takeUntil(this.autoUnsubscribeNotifier)).subscribe(markData => {
      markData?.forEach(md => {
        if (!this.markInfo[md.code]) {
          this.markInfo[md.code] = [];
        }
        if (Array.isArray(md.channelId)) {
          md.channelId.forEach(channel => {
            this.markInfo[md.code][channel] = md;
          });
        } else if (md.channelId == ConversationMarkChannel.undefined) {
          this.markInfo[md.code][ConversationMarkChannel.undefined] = md;
          this.markInfo[md.code][ConversationMarkChannel.agent] = md;
          this.markInfo[md.code][ConversationMarkChannel.customer] = md;
        } else {
          this.markInfo[md.code][md.channelId] = md;
        }
      });
      this.initializeConversationMarkLayer();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroyPlayer();
  }

  initPlayer() {
    this.initializeInstance();
    this.bindWavesurferEvents();

    this.status = PlayerStatus.Initialized;
  }

  destroyPlayer() {
    if (this.isVideo && this._videoElement) {
    this._videoElement.pause();
    this._videoElement.currentTime = 0;
    this._videoElement.setAttribute('src', '');
    this._videoElement.load();
  }
    this._destroying = true;
    this.unbindWavesurferEvents();
    this.destroyInstance();
  }

  transfer() {
    // TODO: Try without destroy init
    this.destroyPlayer();
    this.initPlayer();
  }

  getLayerHiddenStatus(layerItems: any) {
    if (!layerItems || layerItems.length === 0 || this.blocked || !this.expanded) {
      return true;
    } else {
      return false;
    }
  }

  onCatergoryMarkerClick(eventArgs: { startMillisecond: number }) {
    this._wavesurferInstance.play(eventArgs.startMillisecond / 1000);
  }

  onConversationMarkClick(eventArgs: { startMillisecond: number }) {
    this._wavesurferInstance.play(eventArgs.startMillisecond / 1000);
  }

  onGoToMarkClick(eventArgs: { code: string; channelId: any }) {
    let channels = [];
    if (Array.isArray(eventArgs.channelId)) {
      channels = eventArgs.channelId;
    } else if (eventArgs.channelId == ConversationMarkChannel.undefined) {
      channels.push(ConversationMarkChannel.undefined);
      channels.push(ConversationMarkChannel.agent);
      channels.push(ConversationMarkChannel.customer);
    } else {
      channels.push(eventArgs.channelId);
    }
    let currentTime = this._currentTime * 1000;

    let nextMarks = this.conversationMarks
      .sort((a, b) => a.startMillisecond - b.startMillisecond)
      .filter(
        cm =>
          cm.startMillisecond > currentTime &&
          cm.type.code === eventArgs.code &&
          channels.filter(x => x === cm.channel.id).length > 0
      ).length;

    if (nextMarks === 0) {
      currentTime = 0;
    }

    let conversationMark = this.conversationMarks
      .sort((a, b) => a.startMillisecond - b.startMillisecond)
      .find(
        cm =>
          cm.startMillisecond >= currentTime &&
          cm.type.code === eventArgs.code &&
          channels.filter(x => x === cm.channel.id).length > 0
      );
    if (conversationMark) {
      this.onConversationMarkClick({ startMillisecond: conversationMark.startMillisecond });
    }
  }

  onShowSentiment() {
    this.setWaveOpacity('0.08');
    this.sentimentsActivated = true;
  }

  onShowWaveForm() {
    this.setWaveOpacity('1');
    this.sentimentsActivated = false;
  }

  setWaveOpacity(opacity) {
    var waveElements = document.getElementsByTagName('wave') as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < waveElements.length; i++) {
      let canvasElements = waveElements[i].getElementsByTagName(
        'canvas'
      ) as HTMLCollectionOf<HTMLElement>;
      for (var k = 0; k < canvasElements.length; k++) {
        let canvasElement = canvasElements[k];
        canvasElement.style.opacity = opacity;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.initializeLayers();
  }
}
