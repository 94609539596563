<div class="p-4" style="height: 440px">
  <div class="row" style="height: 360px; overflow: auto">
    <table
      class="table"
      style="height: max-content !important; margin-bottom: 0px"
      *ngIf="this.callImportStatus.length > 0">
      <thead>
        <tr>
          <th scope="col">{{ '::FileName' | abpLocalization }}</th>
          <th scope="col" class="text-center">{{ '::Status' | abpLocalization }}</th>
          <th scope="col">{{ 'Conversation::ConversationId' | abpLocalization }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let status of callImportStatus">
          <td class="table-row truncate">{{ status.fileName }}</td>
          <td class="table-row text-center">
            <i
              [ngClass]="status.success === true ? 'far fa-check' : 'far fa-exclamation'"
              [style.color]="status.success === true ? 'green' : 'red'"
              style="font-size: 14pt"
              *ngIf="status.success !== undefined"
              placement="bottom"
              ngbTooltip="{{ status.error }}"
              [disableTooltip]="status.success === true">
            </i>
          </td>
          <td class="table-row">
            <a
              href="/conversation/v2/{{ status.conversationId }}"
              target="_blank"
              placement="bottom"
              ngbTooltip="{{ 'Conversation::GoToDetail' | abpLocalization }}"
              >{{ status.conversationId }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row pt-4">
    <div class="col">
      <ngb-progressbar
        type="success"
        textType="white"
        [value]="progressValue"
        [showValue]="progressValue !== 100"
        >{{ progressValue == 100 ? ('::Completed' | abpLocalization) : '' }}
      </ngb-progressbar>
    </div>
  </div>
</div>
