import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { ActionLogDto } from 'src/core/models/action-log/action-log.dto';

@Component({
  selector: 'ca-conversation-tag-removed-action-log',
  templateUrl: './conversation-tag-removed-action-log.component.html',
  styleUrls: ['./conversation-tag-removed-action-log.component.scss'],
})
export class ConversationTagRemovedActionLogComponent implements OnInit {
  data: ActionLogDto;

  get userName(): string {
    var parsedData = JSON.parse(this.data?.payload);
    if (parsedData?.impersonatorUserName) {
      var userText = parsedData?.impersonatorIsHostTenant
        ? 'Host:' + parsedData?.impersonatorUserName
        : parsedData?.impersonatorUserName;
      var impersonatorText = this.localizationService.instant(
        'ActionLog::ActionType:Impersonator',
        userText
      );

      return parsedData.userName + '(' + impersonatorText + ')';
    } else return parsedData?.userName;
  }

  get userTagNames(): string {
    var parsedData = JSON.parse(this.data?.payload);
    if (parsedData?.tags && parsedData?.tags.length && parsedData?.tags.length > 0) {
      return parsedData?.tags.map(t => t.name).join(', ');
    } else return '';
  }

  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {}
}
