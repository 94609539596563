import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { ActionLogDto } from 'src/core/models/action-log/action-log.dto';
import { ActionLogActionGroupType } from 'src/core/models/generic-lookup-type/action-log/action-log-action-group-type.glt';
import { ActionLogTargetType } from 'src/core/models/generic-lookup-type/action-log/action-log-target-type.glt';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { ActionLogService } from 'src/core/services/action-log/action-log.service';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { Store } from '@ngxs/store';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { map } from 'rxjs/operators';
import { Operators } from 'src/core/models/request/operator.enum';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { ConversationStateMachineStates } from 'src/core/models/conversation/conversation-state-machine-states.dto';

@Component({
  selector: 'ca-conversation-history-panel',
  templateUrl: './conversation-history-panel.component.html',
  styleUrls: ['./conversation-history-panel.component.scss'],
})
export class ConversationHistoryPanelComponent implements OnInit {
  private _conversationId: number;

  dataSource = new MatTableDataSource<ActionLogDto>();
  data: ActionLogDto[];
  displayedColumns: string[] = [];
  totalCount = 0;
  currentPage = 0;
  pageSize = 25;
  isLoading = false;
  actionGroupTypes: GenericLookupDto[];
  selectedActionGroupTypeId: number;
  filters: FilterItemDto[] = [];
  sorters: SorterItemDto[] = [];
  statesDto: ConversationStateMachineStates;

  @Input()
  set conversationId(conversationId: number) {
    if (conversationId) {
      this._conversationId = conversationId;
      this.getTargetInformation();
    }
  }

  get conversationId(): number {
    return this._conversationId;
  }

  @Input()
  set activeTab(activeTab: number) {
    this._activeTab = activeTab;
  }

  get activeTab(): number {
    return this._activeTab;
  }

  get isSelectedSystem(): boolean {
    return this.selectedActionGroupTypeId == ActionLogActionGroupType.system;
  }

  private _activeTab: number;

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'actionType',
      header: this.localizationService.instant('ActionLog::Event'),
      binding: 'actionTypeId',
      valueParser: undefined,
      pipes: undefined,
      width: '80%',
      tooltip: undefined,
    },
    {
      columnName: 'creationTime',
      header: this.localizationService.instant('ActionLog::OccurrenceTime'),
      binding: 'creationTime',
      valueParser: val => {
        return new Date(val);
      },
      pipes: [
        {
          pipe: CADatePipe,
          args: [DateDisplayType.DateTime, true],
        },
      ],
      width: '20%',
      tooltip: undefined,
    },
  ];

  constructor(
    public localizationService: LocalizationService,
    private actionLogService: ActionLogService,
    private store: Store,
    private operators: Operators,
    private conversationService: ConversationService,
    private datePipe: CADatePipe
  ) {
    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ActionLogActionGroupType)))
      .subscribe(result => {
        this.actionGroupTypes = result;
        this.selectedActionGroupTypeId = this.actionGroupTypes.find(
          f => f.id === ActionLogActionGroupType.system
        ).id;
      });

    this.filters.push({
      field: 'actionGroupTypeId',
      operator: this.operators.Equals,
      value: this.selectedActionGroupTypeId,
    });

    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }
  }

  ngOnInit(): void {}

  getTargetInformation() {
    return this.load(this.filters, this.sorters);
  }

  load(filters: FilterItemDto[], sorters: SorterItemDto[]) {
    if (this.isSelectedSystem) {
      this.conversationService.getConversationStates(this.conversationId).subscribe(data => {
        this.statesDto = data;
      });
    } else {
      this.filters = filters;
      this.sorters = sorters;
      this.isLoading = true;

      this.actionLogService
        .getByTargetInformation(this.conversationId, ActionLogTargetType.conversation, {
          filters,
          sorters,
          maxResultCount: this.pageSize,
          skipCount: this.pageSize * this.currentPage,
        })
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe(response => {
          this.data = response.items;

          this.totalCount = response.totalCount;
          this.dataSource.connect().next(this.data);
        });
    }
  }

  onPageChange(event?: PageEvent) {
    this.currentPage = event.pageIndex;
    this.load(this.filters, this.sorters);
  }

  onFilterActionGroupClick(actionGroupTypeId) {
    this.selectedActionGroupTypeId = actionGroupTypeId;
    this.filters = this.filters.filter(f => f.field != 'actionGroupTypeId');
    this.filters.push({
      field: 'actionGroupTypeId',
      operator: this.operators.Equals,
      value: this.selectedActionGroupTypeId,
    });
    this.currentPage = 0;
    this.load(this.filters, this.sorters);
  }

  getToggleClass(actionGroupTypeId) {
    if (actionGroupTypeId == this.selectedActionGroupTypeId) {
      return 'badge category-badge category-box';
    } else {
      return 'badge category-badge category-box disabled';
    }
  }

  getIconClass(actionGroupTypeId) {
    if (actionGroupTypeId == ActionLogActionGroupType.system) {
      return 'far fa-circle system-action-group';
    } else {
      return 'far fa-circle operation-action-group';
    }
  }

  getStateText(state: string) {
    if (state == null) {
      return this.localizationService.instant('Conversation::StateMachine:StatusNotFound');
    }
    return this.localizationService.instant('Conversation::StateMachine:' + state);
  }

  getCss(state: string) {
    const successList = [
      'Compressed',
      'Deleted',
      'Aborted',
      'Categorized',
      'Analyzed',
      'Indexed',
      'Completed',
      'Generated',
      'Evaluated',
    ];

    if (state == null || state.includes('Skipped')) {
      return 'flow-not-started';
    }

    if (successList.indexOf(state) > -1) {
      return 'flow-completed';
    }

    if (state.includes('Failed')) {
      return 'flow-failed';
    }

    return 'flow-started';
  }

  transformDate(date: Date) {
    const dateText = this.datePipe.transform(date, DateDisplayType.DateTime, true);

    return dateText;
  }
}
