import { NgModule } from '@angular/core';
import { PageModule } from '@abp/ng.components/page';
import { SharedModule } from '../shared/shared.module';
import { ExternalRoutingModule } from './external-routing.module';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/core/core.module';
import { CaSharedModule } from 'src/ca-shared/ca-shared.module';
import { ExternalMainComponent } from './external-main/external-main.component';

@NgModule({
  declarations: [ExternalMainComponent],
  imports: [
    CaSharedModule,
    SharedModule,
    CommonModule,
    CoreModule,
    ExternalRoutingModule,
    PageModule,
  ],
})
export class ExternalModule {}
