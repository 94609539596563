import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { AttachedDataMultipleSelectionConfigurationModel } from '../models/attached-data-multiple-selection-configuration.model';
import { AttachedDataMultipleValueModel } from 'src/core/models/administration/attached-data/attached-data-multiple-value.model';

@Component({
  selector: 'ca-attached-data-multiple-selection-panel',
  templateUrl: './attached-data-multiple-selection-panel.component.html',
  styleUrls: ['./attached-data-multiple-selection-panel.component.scss'],
})
export class AttachedDataMultipleSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit
{
  actualConfig: AttachedDataMultipleSelectionConfigurationModel;
  _value: AttachedDataMultipleValueModel[];

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as AttachedDataMultipleSelectionConfigurationModel;
  }

  get config(): AttachedDataMultipleSelectionConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.setValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  constructor(protected fb: FormBuilder) {
    super(fb);

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  ngOnInit(): void {}

  init() {
    super.init();
  }

  reload(): void {}

  reset(): void {}

  autoSelectFirstItem(): void {}
}
