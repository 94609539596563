import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionLogDto } from 'src/core/models/action-log/action-log.dto';
import { ListRequestDto } from 'src/core/models/request/list-request.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ActionLogService {
  constructor(private http: HttpClient) {}

  getByTargetInformation(
    targetId: Number,
    targetTypeId: Number,
    request: ListRequestDto | null
  ): Observable<ListResponseDto<ActionLogDto>> {
    const url = this.getApiUrl() + '/by-target-information/' + targetId + '/' + targetTypeId;

    let params = request === null ? null : new HttpParams();

    if (request !== null) {
      params = params.append('filters', JSON.stringify(request.filters));
      params = params.append('sorters', JSON.stringify(request.sorters));
      params = params.append('skipCount', JSON.stringify(request.skipCount));
      params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));
    }

    return this.http.get(url, {
      params,
    }) as Observable<ListResponseDto<ActionLogDto>>;
  }

  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase + '/' + ActionLogDto.apiUrl;
  }
}
