import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PlayerTimelineCategoryMarkerGroupDto } from '../models/player-timeline-category-marker-group.dto';
import { ColorUtils } from 'src/core/helpers/color-utils';
import { FormatHelper } from 'src/core/helpers/format.helper';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { TimelineService } from '../services/timeline-service';
import { CategoryMarkerDto } from '../models/category-marker.dto';
@Component({
  selector: 'ca-player-timeline-category-marker',
  templateUrl: './player-timeline-category-marker.component.html',
  styleUrls: ['./player-timeline-category-marker.component.scss'],
})
export class PlayerTimelineCategoryMarkerComponent implements OnInit {
  popoverTime: number;
  popover: NgbPopover;
  hasCommentandOther: boolean = false;
  markerNameForComment: string;
  markerName: string;
  markerIcon: string;
  markerColor: string;
  commentMarkers: CategoryMarkerDto[];
  searchMarkers: CategoryMarkerDto[];
  aiGeneratedMarkers: CategoryMarkerDto[];
  queryMarkers: CategoryMarkerDto[];

  @Input()
  markerGroup: PlayerTimelineCategoryMarkerGroupDto;

  @Input()
  cls: string;

  @Output()
  markerClick: EventEmitter<{
    markerInfo: CategoryMarkerDto;
  }> = new EventEmitter();

  constructor(
    public colorUtils: ColorUtils,
    public formatHelper: FormatHelper,
    private timelineService: TimelineService
  ) {
    this.timelineService.onPopoverClose().subscribe(msg => {
      if (this.popoverTime != msg.popoverTime) {
        this.closePopover();
      }
    });
  }

  ngOnInit(): void {}

  onMarkClick(eventargs: any, mark: CategoryMarkerDto) {
    this.markerClick.emit({ markerInfo: mark });
  }

  onClick(eventargs: any, marker: PlayerTimelineCategoryMarkerGroupDto) {
    this.markerClick.emit({ markerInfo: marker.markers[0] });
  }

  onPopoverShown(popover: NgbPopover) {
    this.popover = popover;
    this.popoverTime = new Date().getTime();
    this.timelineService.closePopover(this.popoverTime);
  }

  getPopoverClass() {
    if (this.markerGroup && this.markerGroup.markers) {
      const commentMarkers = this.markerGroup.markers.filter(x => x.isCommentMarker);
      if (commentMarkers && commentMarkers.length > 0) {
        for (let i = 0; i < commentMarkers.length; i++) {
          if (commentMarkers[i].endMillisecond > 0) {
            return 'custom-popover-l';
          }
        }
      }
    }
    return 'custom-popover';
  }

  getMarkerName() {
    if (this.markerGroup) {
      if (this.markerGroup.markers.length > 1) {
        return this.markerGroup.markers.length.toString();
      } else {
        const text = this.markerGroup.markers[0].text;
        return text.length < 16 ? text : text.substring(0, 12) + '...';
      }
    }

    return null;
  }

  getCategoryIconClass(marker: PlayerTimelineCategoryMarkerGroupDto) {
    const tempMarker = marker;
    if (tempMarker.isQuickSearchMarker) {
      return 'fas fa-search';
    } else if (tempMarker.isAIGeneratedMarker) {
      return 'far fa-circle';
    } else if (tempMarker.isCommentMarker) {
      return 'far fa-comment';
    } else {
      return 'fas fa-circle';
    }
  }

  closePopover() {
    if (this.popover) {
      this.popover.close();
    }
  }

  getMarkerTimeLine(marker: PlayerTimelineCategoryMarkerGroupDto) {
    let markerTimeLineText = this.formatHelper.convertMillisecondToTimeString(
      marker.startMillisecond
    );
    if (marker.isCommentMarker && marker.endMillisecond > 0) {
      markerTimeLineText +=
        '-' + this.formatHelper.convertMillisecondToTimeString(marker.endMillisecond);
    }

    return markerTimeLineText;
  }

  hasCommentandOthers(markerGroup: PlayerTimelineCategoryMarkerGroupDto) {
    if (markerGroup && markerGroup.markers) {
      this.commentMarkers = markerGroup.markers.filter(x => x.isCommentMarker);
      this.searchMarkers = markerGroup.markers.filter(x => x.isQuickSearchMarker);
      this.aiGeneratedMarkers = markerGroup.markers.filter(x => x.isAIGeneratedMarker);
      this.queryMarkers = markerGroup.markers.filter(
        x => !x.isQuickSearchMarker && !x.isAIGeneratedMarker && !x.isCommentMarker
      );
      if (
        this.commentMarkers.length > 0 &&
        (this.searchMarkers.length > 0 ||
          this.aiGeneratedMarkers.length > 0 ||
          this.queryMarkers.length > 0)
      ) {
        this.markerNameForComment = this.commentMarkers.length.toString();
        this.markerName = (
          this.searchMarkers.length +
          this.aiGeneratedMarkers.length +
          this.queryMarkers.length
        ).toString();
        this.hasCommentandOther = true;
        if (this.searchMarkers.length > 0) {
          this.markerIcon = 'fas fa-search';
          this.markerColor = this.searchMarkers[0].color;
        } else if (this.queryMarkers.length > 0) {
          this.markerIcon = 'fas fa-circle';
          this.markerColor = this.queryMarkers[0].color;
        } else if (this.aiGeneratedMarkers.length > 0) {
          this.markerIcon = 'far fa-circle';
          this.markerColor = this.aiGeneratedMarkers[0].color;
        }
      } else {
        this.hasCommentandOther = false;
        this.markerNameForComment = null;
        this.markerName = null;
        this.markerIcon = null;
        this.markerColor = null;
      }
    }

    return this.hasCommentandOther;
  }
}
