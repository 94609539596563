import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CrudService } from '../crud/crud.service';
import { HttpClient } from '@angular/common/http';
import { AttachedDataDto } from 'src/core/models/administration/attached-data/attached-data.dto';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { environment } from 'src/environments/environment';
import { AttachedDataLookupDto } from 'src/core/models/administration/attached-data/attached-data-lookup.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
@Injectable({
    providedIn: 'root'
})
export class AttachedDataService {

    public isKeyAvailable(id: number, key: string): Observable<boolean> {
        const apiUrl = this.getApiUrl(AttachedDataDto) + '/' + id + '/' + encodeURIComponent(key);
        return this.http.get(apiUrl) as Observable<boolean>;
    }

    constructor(private http: HttpClient, private service: CrudService) {}

    protected getApiUrl(type = DtoHasApi): string {
        const apiBase = environment.apis.default.url;
        return apiBase + '/' + type.apiUrl;
    }

    getAttachedDataLookups(id: number): Observable<AttachedDataLookupDto[]> {
        const url = this.getApiUrl(AttachedDataDto) + '/' + id + '/lookup';
        return this.http.get(url) as Observable<AttachedDataLookupDto[]>;
    }

    getAttachedDataList(): Observable<ListResponseDto<AttachedDataDto>> {
        const filters: FilterItemDto[] = [];
        const sorters: SorterItemDto[] = [];

        return this.service.get<AttachedDataDto>(AttachedDataDto, {
            filters,
            sorters,
            maxResultCount: 99999,
            skipCount: 0,
        });
    }
}
