<ng-template #itemTemplate let-item="item">
  <div class="selection-panel-name">
    <div class="line-ellipsis">{{ item.version }}</div>
  </div>
</ng-template>

<ng-template #selectionTemplate let-item="item">
  <div class="filter-name">{{ item.version }}</div>
</ng-template>

<ng-container [formGroup]="editorForm">
  <input
    id="useAlwaysLatest"
    type="checkbox"
    formControlName="useAlwaysLatest"
    (change)="onUseAlwaysLatestChange($event.currentTarget.checked)" />
  <label for="useAlwaysLatest" class="ps-1 pb-2" for="useAlwaysLatest">
    {{ 'Dashboard::QualityReport:UseLatestVersion' | abpLocalization }} {{ '(v' + (versionList?.length > 0 ? versionList[0].version : '') + ')' }}
  </label>
  <div class="row display-inline row-height">
    <div [class]="'col overflow-hidden pb-2'">
      <div class="row display-inline">
        <div class="col overflow-hidden">
          <ca-select formControlName="editor" 
          [disabled]="isDisabled"
          [(ngModel)]="selectedVersion">
            <ca-option *ngFor="let version of versionList" [value]="version">
              {{ version.version }}
            </ca-option></ca-select
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>
