import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { FormVersionDto } from 'src/core/models/quality-management/form-version.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { FormVersionSelectionConfigurationModel } from '../models/form-version-selection-configuration.model';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { CrudService } from 'src/core/services/crud/crud.service';
import { FormVersionSelectionModel } from 'src/core/models/quality-management/form-version-selection.model';

@Component({
  selector: 'ca-form-version-selection-panel',
  templateUrl: './form-version-selection-panel.component.html',
  styleUrls: ['./form-version-selection-panel.component.scss'],
})
export class FormVersionSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit
{
  actualConfig: FormVersionSelectionConfigurationModel;
  formGroup: FormGroup;
  _useLatest: boolean = false;
  _value: FormVersionSelectionModel;
  versionList: FormVersionDto[];
  selectedVersion: any;
  isDisabled: boolean = false;
  @ViewChild(SelectorComponent, { read: SelectorComponent })
  versionSelector: SelectorComponent;
  filters: FilterItemDto[] = [];

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as FormVersionSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value?.formVersion ?? undefined,
      useAlwaysLatest: value?.useAlwaysLatest ?? false,
    });
    this._value = value;
  }

  onUseAlwaysLatestChange(checked: boolean) {

    if (checked) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }

  constructor(
    private localizationService: LocalizationService,
    private operators: Operators,
    protected fb: FormBuilder,
    private service: CrudService
  ) {
    super(fb);

    this.editorForm.addControl('useAlwaysLatest', new FormControl(false));
    this.editorForm.valueChanges.subscribe(val => {
      this._value = { formVersion: val.editor, useAlwaysLatest: val.useAlwaysLatest };

      if ((val.useAlwaysLatest || !this._value.formVersion) && this.versionList?.length > 0) {
        this._value.formVersion = this.versionList[0];
        this.selectedVersion = this.versionList[0];
      }

      this.onChange();
    });
  }

  versionSelectionChanged(event): void {
    if (event > 0) {
      this.selectedVersion = event;
    }
  }

  ngOnInit(): void {}

  autoSelectFirstItem(value: boolean): void {
    //this.versionSelector.setAutoSelectFirstItem(value);
  }

  reload(): void {
    this.innerLoad();
  }

  reset(): void {
    this.value = null;
  }

  init() {
    super.init();

    if (this.actualConfig.autoLoad) {
      this.innerLoad();
    }
  }

  private innerLoad() {
    if (this.extraData.formId) {
      this.service
        .get<FormVersionDto>(FormVersionDto, {
          filters: [
            {
              field: 'formId',
              operator: this.operators.Equals,
              value: this.extraData.formId,
            },
          ],
          sorters: [],
          maxResultCount: 1000,
          skipCount: 0,
        })
        .subscribe(data => {
          this.versionList = data.items;

          if (this.value?.useAlwaysLatest && this.versionList?.length > 0) {
            this.value.formVersion = this.versionList[0];
            this.isDisabled = true;
          } else {
            this.isDisabled = false;
          }
          var version = this.versionList.find(v => v.id == this.value?.formVersion?.id);

          this.value = { formVersion: version, useAlwaysLatest: this.value?.useAlwaysLatest };
        });
    } else {
      this.versionList = [];
      this.isDisabled = false;
    }
  }
}
