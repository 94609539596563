import { FilterItemDto } from 'src/core/models/request/filter-item.dto';

export class FilterChange {
  static readonly type = '[DashboardModule] FilterChange';
  constructor(public filters: any) {}
}

export class WidgetsChange {
  static readonly type = '[DashboardModule] WidgetsChange';
  constructor(public widgets: any) {}
}
export class WidgetFilterChange {
  static readonly type = '[DashboardModule] WidgetFilterChange';
  component: string;
  constructor(public filters: FilterItemDto[]) {}
}
