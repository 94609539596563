<div class="attachedData-container" [style.padding]="data.length === 0 ? '15px' : ''">
  <div class="m-2" *ngIf="data.length == 0">
    {{ 'Conversation::ConversationNotContainAttachedData' | abpLocalization }}
  </div>

  <table *ngIf="data.length > 0" mat-table [dataSource]="data" style="width: 100%">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ '::Field' | abpLocalization }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>{{ '::Value' | abpLocalization }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.value }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="attachedDataDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: attachedDataDisplayedColumns"></tr>
  </table>
</div>
