import { FilterBreadcrumbModel } from 'src/core/models/filter-breadcrumb/filter-breadcrumb.model';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';

export class FilterAdd {
  static readonly type = '[FilterBreadcrumb] FilterAdd';
  constructor(public breadcrumb: FilterBreadcrumbModel) {}
}

export class FilterRemove {
  static readonly type = '[FilterBreadcrumb] FilterRemove';
  constructor(public id: string) {}
}

export class FilterUndo {
  static readonly type = '[FilterBreadcrumb] FilterUndo';
  constructor() {}
}

export class FilterClearUndo {
  static readonly type = '[FilterBreadcrumb] FilterClearUndo';
  constructor() {}
}

export class FilterClear {
  static readonly type = '[FilterBreadcrumb] FilterClear';
  constructor() {}
}

export class WidgetFilterChange {
  static readonly type = '[FilterBreadcrumb] WidgetFilterChange';
  constructor(public breadcrumbId: string, public component, public filters: FilterItemDto[]) {}
}
