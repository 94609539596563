<div
  [ngClass]="
    multiple && showSelections && selectorPosition == 'Right'
      ? 'dropdown-selector-menu'
      : 'dropdown-selector-menu-single'
  "
  [style]="styles">
  <div class="dropdown-selector-menu-content-wrapper" [class]="cls">
    <div
      [class.disabled]="disabled"
      class="card dropdown-selector-card {{ parentComponent == 'dropDown' ? '' : 'drop-shadow' }}">
      <div class="card-header dropdown-selector-card-header">
        <ng-container *ngIf="showSelections && selected.length > 0 && selectorPosition == 'Top'">
          <div class="row p-0">
            <div class="col-11 pb-2 top-selection-container">
              <div class="row">
                <div class="col-6" *ngFor="let item of selected">
                  <div class="dropdown-selector-card-item-wrapper tag d-flex">
                    <span
                      [ngbTooltip]="item.name"
                      container="body"
                      class="truncate-text"
                      showTooltipIfTruncated>
                      <ng-container
                        *ngTemplateOutlet="
                          selectionTemplate;
                          context: { selection: item }
                        "></ng-container>
                    </span>
                    <span
                      (click)="onClickRemoveItem($event, item)"
                      class="dropdown-selector-remove-selection-icon tagicon">
                      <i class="fas fa-times"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1 pb-2 top-trash-container" *ngIf="showClearSelections">
              <div class="row">
                <ca-delete-icon
                  type="button"
                  (click)="onClearSelectionClick($event)"
                  title="{{ '::Clear' | abpLocalization }}"></ca-delete-icon>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="row m-0" style="align-items: center">
          <div class="col p-0" style="position: relative">
            <i [ngClass]="'fas fa-search search-icon'"></i>
            <div class="input-group input-group-sm">
              <input
                type="text"
                class="form-control search-text"
                placeholder="{{ 'AbpUi::Search' | abpLocalization }}"
                [(ngModel)]="queryValue"
                (keyup)="onSearchRequest($event)"
                #edtSearch />
              <div class="input-group-text">
                <button
                  class="btn btn-sm btn-outline-dark"
                  type="button"
                  (click)="onRemoveFilterClick($event)"
                  title="{{ '::Clear' | abpLocalization }}">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-3" *ngIf="showStatusCombobox">
            <ng-select
              [clearable]="false"
              [searchable]="false"
              [items]="statusItems"
              [ngModel]="currentStatus"
              [ngbTooltip]="currentStatus"
              [disableTooltip]="disableTooltip"
              (open)="disableTooltip = true"
              (close)="disableTooltip = false"
              (change)="changeStatusFilter($event)">
              <ng-template ng-label-tmp let-item="item">
                <div placement="top" [ngClass]="item.class"></div>
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <div [ngClass]="item.class"></div>
                {{ item.label }}
              </ng-template>
            </ng-select>
          </div>
          <div class="col-2" *ngIf="showClearSelectionLink && selected.length > 0">
            <button
              class="btn btn-link p-0"
              (click)="onClearSelectionClick($event)"
              style="font-size: 9pt">
              {{ '::Clear' | abpLocalization }}
            </button>
          </div>
          <div
            class="col p-0 d-flex justify-content-center flex-column"
            *ngIf="filterToolbarTemplate">
            <ng-container *ngTemplateOutlet="filterToolbarTemplate"></ng-container>
          </div>
        </div>
      </div>
      <div class="card-body dropdown-selector-card-body" (scroll)="onScroll()">
        <ul class="card-text dropdown-selector-card-text m-0 p-0">
          <span *ngIf="items?.length === 0">{{ '::NoItemsFound' | abpLocalization }}</span>
          <div class="float-container" *ngFor="let item of items">
            <li class="float-content" (click)="onItemClick($event, item)">
              <div *ngIf="!selectOnClick" class="dropdown-selector-card-item-wrapper">
                <input
                  type="{{ multiple ? 'checkbox' : 'radio' }}"
                  [checked]="item.selected"
                  [disabled]="checkItemDisability(item)" />
                <span
                  #t="ngbTooltip"
                  class="me-1 ms-1 truncate-text"
                  style="display: inline-block"
                  [ngClass]="{ disabled: item.disabled }"
                  [ngbTooltip]="item.data.name"
                  [autoClose]="'manual'"
                  [openDelay]="750"
                  (mouseenter)="onTooltipInitialized(t)"
                  placement="top-left"
                  container="body">
                  <ng-container
                    *ngTemplateOutlet="itemTemplate; context: { item: item.data }"></ng-container>
                </span>
              </div>
              <div *ngIf="selectOnClick" class="dropdown-selector-card-item-wrapper">
                <button
                  (change)="onItemClick($event.currentTarget.value)"
                  class="plus-button mb-1 truncate-text"
                  [disabled]="isDisabled(item.data)"
                  [ngbTooltip]="item.data.name"
                  container="body">
                  <i class="far fa-plus me-2"></i>
                  <ng-container
                    class="line-ellipsis"
                    *ngTemplateOutlet="itemTemplate; context: { item: item.data }"></ng-container>
                </button>
              </div>
            </li>
            <div class="icons">
              <span>
                <ng-container
                  *ngTemplateOutlet="iconTemplate; context: { item: item.data }"></ng-container>
              </span>
            </div>
          </div>
        </ul>
      </div>
      <div *ngIf="!paginationDisabled">
        <span>
          <mat-paginator
            style="display: flex; justify-content: left"
            (page)="pageChanged($event)"
            [pageIndex]="currentPage - 1"
            [length]="totalCount"
            [pageSize]="pageSize"
            class="paginator"
            hidePageSize
            showFirstLastButtons></mat-paginator>
        </span>
      </div>
    </div>
    <ng-container *ngIf="multiple && showSelections && selectorPosition == 'Right'">
      <div class="card text-center mb-0 card-style">
        <div class="card-header dropdown-selector-selection-card-header">
          {{ '::SelectedRecords' | abpLocalization }} ({{ this.selected.length }})
        </div>
        <div class="card-body dropdown-selector-card-body">
          <ul class="card-text dropdown-selector-card-text">
            <li *ngFor="let item of selected">
              <div class="dropdown-selector-card-item-wrapper">
                <span
                  [class.disabled]="disabled"
                  (click)="onClickRemoveItem($event, item)"
                  class="dropdown-selector-remove-selection-icon">
                  <i class="fas fa-times"></i>
                </span>
                <span>
                  <ng-container
                    *ngTemplateOutlet="itemTemplate; context: { item: item }"></ng-container>
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div
          [class.disabled]="disabled"
          class="card-footer text-muted dropdown-selector-selection-card-footer">
          <button
            class="btn btn-sm btn-outline-dark dropdown-selector-clear-selection-button"
            type="button"
            (click)="onClearSelectionClick($event)">
            {{ '::Clear' | abpLocalization }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
