import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.TagType',
})
export class TagType {
    @GenericLookup({
        code: 'Conversation.TagType.UserDefined',
    })
    static userDefined: number;

    @GenericLookup({
        code: 'Conversation.TagType.TrainingData',
    })
    static trainingData: number;
}
