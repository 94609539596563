import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserDto } from 'src/core/models/shared/user.dto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getByUsername(username: string): Observable<UserDto> {
    const url = this.getApiUrl() + '/by-username/' + username;

    return this.http.get(url) as Observable<UserDto>;
  }

  getByEmail(email: string): Observable<UserDto> {
    const url = this.getApiUrl() + '/by-email/' + email;

    return this.http.get(url) as Observable<UserDto>;
  }

  getByPbxAgentId(pbxAgentId: string): Observable<UserDto> {
    const url = this.getApiUrl() + '/by-pbx-agent-id/' + pbxAgentId;

    return this.http.get(url) as Observable<UserDto>;
  }

  getByDeviceId(deviceId: string): Observable<UserDto> {
    const url = this.getApiUrl() + '/by-device-id/' + deviceId;

    return this.http.get(url) as Observable<UserDto>;
  }

  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0 ? apiBase + '/' + UserDto.apiUrl : UserDto.apiUrl;
  }
}
