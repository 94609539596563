import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.Evaluation',
})
export class ConversationEvaluation {
    @GenericLookup({
      code: 'Conversation.Evaluation.NotEvaluated',
    })
    static notEvaluated: number;

    @GenericLookup({
      code: 'Conversation.Evaluation.EvaluatedByMe',
    })
    static evaluatedByMe: number;
    
    @GenericLookup({
      code: 'Conversation.Evaluation.EvaluatedByOthers',
    })
    static evaluatedByOthers: number;
}
