import { downloadBlob, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
import { ExcelService } from 'src/core/services/excel/excel.service';
import { Router } from '@angular/router';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';

@Component({
  selector: 'ca-redaction-exist-category-notification.component',
  templateUrl: './redaction-exist-category-notification.component.html',
  styleUrls: ['./redaction-exist-category-notification.component.scss'],
})
@NotificationType('RedactionExistCategoryNotification', RedactionExistCategoryNotificationComponent)
export class RedactionExistCategoryNotificationComponent implements OnInit {
  data: NotificationDto;

  get categoryNames(): string {
    return this.data ? JSON.parse(this.data.payload).categoryNames : '';
  }

  constructor(
    private excelService: ExcelService,
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const message = localizationService.instant('Settings::RedactionExistCategoryMessage');

    var toastrId = toastr.warn(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(router);
    });
  }

  static toasterClickedHandler(router: Router) {
    this.navigateToEvaluation(router);
  }

  static navigateToEvaluation(router: Router) {
    router.navigate(['notifications']);
  }
}
