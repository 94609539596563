import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'GenerativeQA.Service'
})
export class GenerativeQAService {
    @GenericLookup({
        code: 'GenerativeQA.Service.KnovvuVA'
    })
    static knovvuVA: string;

    @GenericLookup({
        code: 'GenerativeQA.Service.KnovvuML'
    })
    static knovvuML: string;

    @GenericLookup({
        code: 'GenerativeQA.Service.GenesysKnowledge'
    })
    static genesysKnowledge: string;
}
