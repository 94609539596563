import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { RoleConstants } from 'src/core/constants/role.constant';
import { ConversationCommentInputDto } from 'src/core/models/comment/comment-input.dto';
import { ConversationCommentDto } from 'src/core/models/comment/comment.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';

@Component({
  selector: 'ca-conversation-comment-panel',
  templateUrl: './conversation-comment-panel.component.html',
  styleUrls: ['./conversation-comment-panel.component.scss'],
})
export class ConversationCommentComponentPanel implements OnInit {
  private _conversationId: number;
  userId: string;
  roles: string[] = [];
  conversationComment: ConversationCommentInputDto;
  comments: ConversationCommentDto[] = [];
  commentCount: number = 0;
  commentText: string;
  dateDisplayType: DateDisplayType = DateDisplayType.DateTime;

  @Output()
  goToCommentRequest: EventEmitter<{
    activeTab: number;
    startTime: string;
    endTime: string;
  }> = new EventEmitter();

  @Output()
  commentCountChanged: EventEmitter<{
    comments: ConversationCommentDto[];
  }> = new EventEmitter();

  @Input()
  set conversationId(conversationId: number) {
    if (conversationId) {
      this._conversationId = conversationId;
      this.getCommentsByConversationId(conversationId);
    }
  }

  get conversationId(): number {
    return this._conversationId;
  }

  onLinkClicked(startTime: string, endTime: string) {
    this.goToCommentRequest.emit({ activeTab: 1, startTime: startTime, endTime: endTime });
  }

  constructor(
    private confirmationService: ConfirmationService,
    private conversationService: ConversationService,
    private localizationService: LocalizationService,
    private configStateService: ConfigStateService
  ) {}

  ngOnInit(): void {
    this.userId = this.configStateService.getDeep('currentUser.id');
    this.roles = this.configStateService.getDeep('currentUser.roles');
  }

  getCommentsByConversationId(id: number) {
    return this.conversationService.getCommentsByConversationId(id).subscribe(data => {
      this.comments = data;
      this.commentCountChanged.emit({ comments: this.comments });
    });
  }

  addComment(eventArgs, edtComment: HTMLTextAreaElement) {
    const comment = edtComment.value;
    this.conversationComment = new ConversationCommentInputDto();
    this.conversationComment.conversationId = this.conversationId;
    this.conversationComment.comment = comment;
    this.conversationService.addComment(this.conversationComment).subscribe(data => {
      this.comments.unshift(data);
      this.commentCountChanged.emit({ comments: this.comments });
      edtComment.value = '';
    });
  }

  deleteComment(id: number, commentId: number) {
    this.conversationService.deleteComment(id, commentId).subscribe(data => {
      this.getCommentsByConversationId(id);
    });
  }

  confirmDeletion(conversationComment, eventArgs) {
    this.confirmationService
      .warn('::DeletionConfirmationMessage', '', {
        messageLocalizationParams: [this.localizationService.instant('::Comment')],
        yesText: '::Delete',
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.deleteComment(this.conversationId, conversationComment.id);
        }
      });
  }

  canDeleteComment(comment: ConversationCommentDto) {
    return (
      comment.userId === this.userId ||
      this.roles.findIndex(x => x == RoleConstants.admin) > -1 ||
      this.roles.findIndex(x => x == RoleConstants.tenantAdmin) > -1
    );
  }

  getCommentColumnWidth(): string {
    return document.body.offsetWidth <= 1200 ? '11' : '9';
  }

  getCommentDeleteColumnWidth(): string {
    return document.body.offsetWidth <= 1200 ? '1' : '3';
  }
}
