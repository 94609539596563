import { SubscriptionService } from '@abp/ng.core';
import { ErrorHandler, HttpErrorWrapperComponent } from '@abp/ng.theme.shared';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private errorHandler: ErrorHandler) {}

  showError(status: number) {
    var error = new HttpErrorWrapperComponent(new SubscriptionService());
    error.status = status;
    this.errorHandler.createErrorComponent(error);
  }
}
