import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TopicDto } from 'src/core/models/conversation/ai-topic/topic.dto';
import { TopicMetadataDto } from 'src/core/models/conversation/ai-topic/topic-metadata.dto';
import { TopicClusterKeywordDto } from 'src/core/models/conversation/ai-topic/topic-cluster-keyword.dto';
import { MergeTopicRequestDto } from 'src/core/models/conversation/ai-topic/merge-topic-request.dto';

@Injectable({
  providedIn: 'root',
})
export class TopicService {
  constructor(private http: HttpClient) {}

  duplicate(topic: TopicDto): Observable<TopicDto> {
    const url = this.getApiUrl() + '/' + topic.id + '/duplicate';
    return this.http.post(url, topic) as Observable<TopicDto>;
  }

  getByName(name: string): Observable<TopicDto> {
    const url = this.getApiUrl() + '/by-name?name=' + encodeURIComponent(name);
    return this.http.get(url) as Observable<TopicDto>;
  }

  getTopicKeywordsById(topicId: number): Observable<TopicClusterKeywordDto[]> {
    const url = this.getApiUrl() + '/' + topicId + '/keywords';
    return this.http.get(url) as Observable<TopicClusterKeywordDto[]>;
  }

  getTopicMetadataById(topicId: number): Observable<TopicMetadataDto> {
    const url = this.getApiUrl() + '/' + topicId + '/metadata';
    return this.http.get(url) as Observable<TopicMetadataDto>;
  }

  mergeTopics(mergeTopicRequest: MergeTopicRequestDto): Observable<boolean> {
    const url = this.getAITopicApiUrl() + '/merge-topic';
    return this.http.post(url, mergeTopicRequest) as Observable<boolean>;
  }

  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0 ? apiBase + '/' + TopicDto.apiUrl : TopicDto.apiUrl;
  }

  private getAITopicApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase + '/' + 'api/app/ai-topic';
  }
}
