import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnInit,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { fromEvent, merge, Observable } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import {
  WindowPositionChanged,
  WindowSizeChanged,
} from 'src/core/actions/video-player/video-player.actions';
import { DragType } from 'src/core/models/enum/drag-type.enum';
import { VideoPlayerStateModel } from 'src/core/models/video-player/video-player.state-model';
import { WindowPosition } from 'src/core/models/window/window-position.model';
import { WindowSize } from 'src/core/models/window/window-size.model';
import { VideoPlayerService } from 'src/core/services/video-player/video-player.service';
import { VideoPlayerState } from 'src/core/states/video-player/video-player.state';

@Component({
  selector: 'ca-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, AfterViewInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.windowProperties.visible.enabled) {
      this.restore({ title: this.title, changeTitle: false });
    }
  }

  @Select(VideoPlayerState.getWindowSize)
  windowSize$: Observable<WindowSize>;

  @Select(VideoPlayerState.getWindowPosition)
  windowPosition$: Observable<WindowSize>;

  maximized = false;
  closed = false;

  rect: any;
  incr: number[] = [0, 0, 0, 0];
  nativeElement: any;
  dragType: DragType;
  origin: any;
  dragging: boolean = false;
  moveSubscription: any;
  style: any = null;
  title: string;
  windowMargin = 5;

  private readonly minimumSize = new WindowSize(350, 200);
  private readonly defaultSize = new WindowSize(500, 500);

  private videoElement: HTMLVideoElement;
  private tmpUserSelect: string;

  private resizerClassNames = [
    'drag-handle',
    'resizer-top',
    'resizer-bottom',
    'resizer-left',
    'resizer-right',
    'resizer-top-right',
    'resizer-bottom-right',
    'resizer-top-left',
    'resizer-bottom-left',
  ];

  private windowProperties = {
    container: {
      enabled: true,
      cssClass: 'video-player-container',
    },
    visible: {
      enabled: false,
      cssClass: 'visible',
    },
    hidden: {
      enabled: false,
      cssClass: 'hidden',
    },
    maximized: {
      enabled: false,
      cssClass: 'maximized',
    },
    shadow: {
      enabled: true,
      cssClass: 'has-shadow',
    },
    border: {
      enabled: true,
      cssClass: 'has-border',
    },
  };

  @HostBinding('class')
  get class(): string {
    return Object.keys(this.windowProperties)
      .map(key => this.windowProperties[key])
      .filter(prop => prop.enabled)
      .map(prop => prop.cssClass)
      .join(' ');
  }

  constructor(
    private store: Store,
    private elementRef: ElementRef,
    private videoPlayerService: VideoPlayerService
  ) {
    this.windowProperties.hidden.enabled = true;
  }

  ngOnInit(): void {
    this.windowSize$.subscribe(this.resize.bind(this));
    this.windowPosition$.subscribe(this.relocate.bind(this));

    this.videoPlayerService.closed$.subscribe(this.close.bind(this));
    this.videoPlayerService.minimized$.subscribe(this.minimize.bind(this));
    this.videoPlayerService.maximized$.subscribe(this.maximize.bind(this));
    this.videoPlayerService.restorationRequested.subscribe(this.restore.bind(this));

    this.initializeDragDropEventsAndResizers();
  }

  ngAfterViewInit(): void {
    this.videoElement = this.elementRef.nativeElement.querySelector('video') as HTMLVideoElement;

    this.videoPlayerService.videoElement = this.videoElement;
  }

  // START
  // TOOLBAR ACTIONS

  onClickClose(eventArgs: MouseEvent): void {
    this.videoPlayerService.close();
  }

  onClickMinimize(eventArgs: MouseEvent): void {
    this.videoPlayerService.minimize();
  }

  onClickMaximize(eventArgs: MouseEvent, maximize: boolean): void {
    if (maximize) {
      this.videoPlayerService.maximize();
    } else {
      this.videoPlayerService.restore();
    }
  }

  // END
  // TOOLBAR ACTIONS

  // START
  // DRAG DROP AND RESIZE EVENTS

  private initializeDragDropEventsAndResizers(): void {
    merge(
      fromEvent(this.elementRef.nativeElement, 'mousedown'),
      fromEvent(this.elementRef.nativeElement, 'touchstart').pipe(
        map((event: TouchEvent) => ({
          target: event.target,
          screenX: event.touches[0].screenX,
          screenY: event.touches[0].screenY,
        }))
      )
    )
      .pipe(filter(this.isEventEligibleForDraggingOrResizing.bind(this)))
      .subscribe(this.startDraggingAndResizing.bind(this));
  }

  private startDraggingAndResizing(event: MouseEvent): void {
    this.tmpUserSelect = document.body.style['user-select'];
    document.body.style['user-select'] = 'none';

    this.rect = this.elementRef.nativeElement.getBoundingClientRect();
    this.origin = { x: event.screenX, y: event.screenY };

    this.dragging = true;
    const className = (event.target as any).className.split(' ');
    this.dragType =
      className.indexOf('drag-handle') >= 0
        ? DragType.Move
        : (this.resizerClassNames.indexOf(className[1]) as DragType);

    this.incr = this.getIncrements(this.dragType);

    this.dragging = true;

    merge(fromEvent(document, 'mouseup'), fromEvent(document, 'touchend'))
      .pipe(take(1))
      .subscribe(this.stopDraggingAndResizing.bind(this));

    if (!this.moveSubscription) {
      this.moveSubscription = merge(
        fromEvent(document, 'mousemove'),
        fromEvent(document, 'touchmove').pipe(
          map((event: TouchEvent) => ({
            target: event.target,
            screenX: event.touches[0].screenX,
            screenY: event.touches[0].screenY,
          }))
        )
      )
        .pipe(startWith({ screenY: this.origin.y, screenX: this.origin.x }))
        .subscribe(this.moveAndResize.bind(this));
    }
  }

  private stopDraggingAndResizing(): void {
    document.body.style['user-select'] = this.tmpUserSelect;

    if (this.moveSubscription) {
      this.moveSubscription.unsubscribe();
      this.moveSubscription = undefined;
      this.dragging = false;
    }
  }

  private getIncrements(dragType): Array<number> {
    return dragType == DragType.Move
      ? [1, 0, 1, 0]
      : dragType == DragType.Top
      ? [1, -1, 0, 0]
      : dragType == DragType.Bottom
      ? [0, 1, 0, 0]
      : dragType == DragType.Right
      ? [0, 0, 0, 1]
      : dragType == DragType.Left
      ? [0, 0, 1, -1]
      : dragType == DragType.TopRight
      ? [1, -1, 0, 1]
      : dragType == DragType.TopLeft
      ? [1, -1, 1, -1]
      : dragType == DragType.BottomRight
      ? [0, 1, 0, 1]
      : [0, 1, 1, -1];
  }

  private isEventEligibleForDraggingOrResizing(event: MouseEvent): boolean {
    if (this.maximized) {
      return false;
    }

    let classs = (event.target as any).className;

    if (classs && typeof classs === 'string') {
      const className = classs.split(' ');

      classs = className[1];

      return className.indexOf('drag-handle') >= 0
        ? true
        : this.resizerClassNames.indexOf(classs) >= 0;
    }
    return false;
  }

  private moveAndResize(moveEvent: MouseEvent): void {
    const incrTop = moveEvent.screenY - this.origin.y;
    const incrLeft = moveEvent.screenX - this.origin.x;

    const width = this.rect.width + this.incr[3] * incrLeft;
    const height = this.rect.height + this.incr[1] * incrTop;
    var position = {
      top: this.rect.top + this.incr[0] * incrTop,
      left: this.rect.left + this.incr[2] * incrLeft,
    };

    this.validatePlayerPosition(position, this.rect);
    this.publishSizeChangeAction(width, height);
    this.publishPositionChangeAction(position.top, position.left);
  }

  // END
  // DRAG DROP AND RESIZE EVENTS

  private close(): void {
    this.closed = true;

    this.windowProperties.visible.enabled = false;
    this.windowProperties.hidden.enabled = true;

    if (this.videoElement) {
      this.videoElement.removeAttribute('src');
    }
  }

  private minimize(minimized: boolean): void {
    if (this.closed) {
      return;
    }

    this.windowProperties.visible.enabled = !minimized;
    this.windowProperties.hidden.enabled = minimized;
  }

  private maximize(maximized: boolean): void {
    let width = window.innerWidth - 50;
    let height = window.innerHeight - 50;

    this.maximized = maximized;
    this.windowProperties.maximized.enabled = maximized;

    this.elementRef.nativeElement.style['width'] = `${width}px`;
    this.elementRef.nativeElement.style['height'] = `${height}px`;

    this.elementRef.nativeElement.style['max-width'] = `${width}px`;
    this.elementRef.nativeElement.style['max-height'] = `${height}px`;

    this.elementRef.nativeElement.style['left'] = '25px';
    this.elementRef.nativeElement.style['top'] = '25px';
  }

  private restore(eventArgs: { title: string; changeTitle: boolean }): void {
    const state = this.store.selectSnapshot<VideoPlayerStateModel>(VideoPlayerState);

    this.closed = false;

    if (eventArgs.changeTitle) {
      this.title = eventArgs.title;
    }

    this.resize(state.size);
    this.relocate(state.position, state.size);

    this.maximized = false;

    this.windowProperties.visible.enabled = true;
    this.windowProperties.hidden.enabled = false;
  }

  private resize(size: WindowSize): void {
    if (!size) {
      return;
    }

    let width = size.width <= this.minimumSize.width ? this.minimumSize.width : size.width;
    let height = size.height <= this.minimumSize.height ? this.minimumSize.height : size.height;

    this.elementRef.nativeElement.style['width'] = `${width}px`;
    this.elementRef.nativeElement.style['height'] = `${height}px`;

    this.elementRef.nativeElement.style['max-width'] = `${width}px`;
    this.elementRef.nativeElement.style['max-height'] = `${height}px`;
  }

  private relocate(position: WindowPosition, size: WindowSize): void {
    if (!position) {
      return;
    }

    if (size) {
      this.validatePlayerPosition(position, size);
    }

    this.elementRef.nativeElement.style['left'] = `${position.left}px`;
    this.elementRef.nativeElement.style['top'] = `${position.top}px`;
  }

  private publishSizeChangeAction(width: number, height: number): void {
    const action = new WindowSizeChanged({ width: width, height: height } as WindowSize);
    this.store.dispatch(action);
  }

  private publishPositionChangeAction(top: number, left: number): void {
    const action = new WindowPositionChanged({ top: top, left: left } as WindowPosition);
    this.store.dispatch(action);
  }

  private validatePlayerPosition(position: any, size: WindowSize) {
    let clientHeight = document.body.clientHeight;
    let clientWidth = document.body.clientWidth;

    if (size.height + position.top > clientHeight) {
      position.top = clientHeight - size.height - this.windowMargin;
    }

    if (position.top < this.windowMargin) {
      position.top = this.windowMargin;
    }

    if (size.width + position.left > clientWidth) {
      position.left = clientWidth - size.width - this.windowMargin;
    }

    if (position.left < this.windowMargin) {
      position.left = this.windowMargin;
    }
  }
}
