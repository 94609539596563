import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { IntegerValidator } from 'src/core/validators/shared/integer.validator';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { CallNonFcrGroup } from 'src/core/models/generic-lookup-type/call/call-non-fcr-group.glt';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { QueryItemDto } from 'src/core/models/query/query-item.dto';
import { QueryItemsCategoriesTupleDto } from 'src/core/models/query/query-items-categories-tuple.dto';
import { QueryDto } from 'src/core/models/query/query.dto';
import { QueryService } from 'src/core/services/query/query.service';
import { CrudService } from 'src/core/services/crud/crud.service';
import { combineLatest, Subscription } from 'rxjs';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { AttachedDataDto } from 'src/core/models/administration/attached-data/attached-data.dto';
import { CategoryDto } from 'src/core/models/query/category.dto';
import { CategoryType } from 'src/core/models/enum/category-type.enum';
import { TopicClusterKeywordDto } from 'src/core/models/conversation/ai-topic/topic-cluster-keyword.dto';
import { TopicService } from 'src/core/services/ai-topic/topic.service';
import { CategoryService } from 'src/core/services/category/category.service';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { FilterPanelStateModel } from 'src/ca-shared/filter-panel/models/filter-panel.state-model';
import { FilterPanelState } from 'src/ca-shared/filter-panel/filter-panel.module';

@Component({
  selector: 'ca-analysis-settings',
  templateUrl: './analysis-settings.component.html',
  styleUrls: ['./analysis-settings.component.scss'],
})
export class AnalysisSettingsComponent implements OnInit {
  nonFcrSettingsForm: FormGroup;
  nonFcrSettingDtos: ConfigurationSettingsDto[] = [];
  callDirections: GenericLookupDto[];
  nonFcrCallGroups: GenericLookupDto[];
  analyticsFeatureEnabled = false;

  isQueryPreviewLoading: boolean;
  previewQueryId: number;
  popover: NgbPopover;
  queryPreviewDataCache = new Map<number, QueryItemsCategoriesTupleDto>();
  queryPreviewDataSubscription: Subscription;
  queryDropdownOptions: any;

  categoryDropdownOptions: any;
  isTopicPreviewLoading: boolean;
  previewTopicId: number;
  topicPreviewDataCache = new Map<number, TopicClusterKeywordDto[]>();
  filterByCategorySelectorFilters: FilterItemDto[] = [];
  groupByCategorySelectorFilters: FilterItemDto[] = [];
  categorySelectorSorters: SorterItemDto[] = [];

  attachedDataSelectorOptions: any;
  attachedDataSelectorFilters: FilterItemDto[] = [];
  attachedDatas: AttachedDataDto[] = [];

  categories: CategoryDto[] = [];
  AIGeneratedCategories: CategoryDto[] = [];
  userDefinedCategories: CategoryDto[] = [];
  prebuiltUserDefinedCategories: CategoryDto[] = [];

  private readonly settingKeyEnabled = 'Analysis.NonFcr.Enabled';
  private readonly settingKeyNonFcrCallGroup = 'Analysis.NonFcr.AccordingTo';
  private readonly settingKeyCallInterval = 'Analysis.NonFcr.CallInterval';
  private readonly settingKeyMinCallCount = 'Analysis.NonFcr.MinCallCount';
  private readonly settingKeyMaxCallCount = 'Analysis.NonFcr.MaxCallCount';
  private readonly settingKeyFilterByCategoriesEnabled =
    'Analysis.NonFcr.FilterByCategoriesEnabled';
  private readonly settingKeyFilterByCategories = 'Analysis.NonFcr.FilterByCategories';
  private readonly settingKeyGroupByCategoriesEnabled = 'Analysis.NonFcr.GroupByCategoriesEnabled';
  private readonly settingKeyGroupByCategories = 'Analysis.NonFcr.GroupByCategories';
  private readonly settingKeyGroupByAttachedDataEnabled =
    'Analysis.NonFcr.GroupByAttachedDataEnabled';
  private readonly settingKeyGroupByAttachedData = 'Analysis.NonFcr.GroupByAttachedData';

  get disableFilterByCategoriesSelection(): boolean {
    return !this.nonFcrSettingsForm.get('filterByCategoriesEnabled').value;
  }

  get disableGroupByCategoriesSelection(): boolean {
    return !this.nonFcrSettingsForm.get('groupByCategoriesEnabled').value;
  }

  get disableAttachedDataSelection(): boolean {
    return !this.nonFcrSettingsForm.get('groupByAttachedDataEnabled').value;
  }

  get categoryType(): typeof CategoryType {
    return CategoryType;
  }

  constructor(
    private config: ConfigStateService,
    private store: Store,
    private fb: FormBuilder,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private confirmationService: ConfirmationService,
    private featureService: FeatureService,
    private categoryService: CategoryService,
    private queryService: QueryService,
    private topicService: TopicService,
    private service: CrudService,
    private operators: Operators
  ) {
    this.analyticsFeatureEnabled = this.featureService.isEnabled(FeatureConstants.Analytics);
    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(CallNonFcrGroup)))
      .subscribe(result => {
        this.nonFcrCallGroups = result;
      });

    this.nonFcrSettingsForm = fb.group({
      nonFcrEnabled: [JSON.parse(this.config.getSetting(this.settingKeyEnabled).toLowerCase())],
      nonFcrCallInterval: [
        this.config.getSetting(this.settingKeyCallInterval),
        { validators: [Validators.required, IntegerValidator.minMax(0, null)] },
      ],
      nonFcrMinCallCount: [
        this.config.getSetting(this.settingKeyMinCallCount),
        { validators: [Validators.required, IntegerValidator.minMax(1, null)] },
      ],
      nonFcrMaxCallCount: [
        this.config.getSetting(this.settingKeyMaxCallCount),
        { validators: [Validators.required, IntegerValidator.minMax(1, null)] },
      ],
      nonFcrCallGroup: [this.config.getSetting(this.settingKeyNonFcrCallGroup)],
      filterByCategoriesEnabled: [
        JSON.parse(this.config.getSetting(this.settingKeyFilterByCategoriesEnabled).toLowerCase()),
      ],
      filterByCategories: [null],
      groupByCategoriesEnabled: [
        JSON.parse(this.config.getSetting(this.settingKeyGroupByCategoriesEnabled).toLowerCase()),
      ],
      groupByCategories: [null],
      groupByAttachedDataEnabled: [
        JSON.parse(this.config.getSetting(this.settingKeyGroupByAttachedDataEnabled).toLowerCase()),
      ],
      groupByAttachedData: [null],
    });

    this.attachedDataSelectorFilters.push({
      field: 'includeInvisibles',
      operator: this.operators.Equals,
      value: true,
    });

    this.filterByCategorySelectorFilters.push({
      field: 'categoryType',
      operator: this.operators.Equals,
      value: CategoryType.Query,
    },
      {
        field: 'isActive',
        operator: this.operators.Equals,
        value: true,
      }
    );

    this.categorySelectorSorters.push({
      field: 'Name',
      direction: 'ASC',
    });

    this.groupByCategorySelectorFilters.push({
      field: 'isActive',
      operator: this.operators.Equals,
      value: true,
    },
      {
        field: 'notMerged',
        operator: this.operators.Equals,
        value: true,
      }
    );

    this.service
      .get<CategoryDto>(CategoryDto, {
        maxResultCount: 9999,
        skipCount: 0,
        filters: this.groupByCategorySelectorFilters,
        sorters: [],
      })
      .subscribe(response => {
        this.categories = response.items;
        this.userDefinedCategories = [
          ...this.categories.filter(x => x.categoryType == this.categoryType.Query),
        ];
        this.AIGeneratedCategories = [
          ...this.categories.filter(x => x.categoryType == this.categoryType.Topic),
        ];
        this.setCategorySelectors();
      });

    this.categoryService.getNonFcrPrebuildCategories().subscribe(response => {
      this.prebuiltUserDefinedCategories = response;
      this.setPrebuiltNonFCRCategories();
    });

    this.service
      .get<AttachedDataDto>(AttachedDataDto, {
        maxResultCount: 9999,
        skipCount: 0,
        filters: this.attachedDataSelectorFilters,
        sorters: [],
      })
      .subscribe(response => {
        this.attachedDatas = response.items;
      });

    this.categoryDropdownOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      emptyText: this.localizationService.instant('::SelectQuery'),
      multiple: true,
      url: 'api/app/category',
      currentPage: 1,
      showSelections: true,
      selectorPosition: 'None',
    };

    this.attachedDataSelectorOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      emptyText: this.localizationService.instant('::SelectAttachedData'),
      multiple: true,
      url: AttachedDataDto.apiUrl,
      currentPage: 1,
      showSelections: true,
      selectorPosition: 'Top',
    };
  }

  ngOnInit(): void { }

  onFilterByCategoriesChange(eventArgs) {
    if (eventArgs.target.checked) {
      this.nonFcrSettingsForm
        .get('filterByCategories')
        .setValue([...this.prebuiltUserDefinedCategories]);
      this.nonFcrSettingsForm.get('filterByCategories').setValidators(Validators.required);
      this.nonFcrSettingsForm.get('filterByCategories').updateValueAndValidity();
    } else {
      this.nonFcrSettingsForm.get('filterByCategories').clearValidators();
      this.nonFcrSettingsForm.get('filterByCategories').setValue(null);
      this.nonFcrSettingsForm.get('filterByCategories').updateValueAndValidity();
    }
  }

  onGroupByCategoriesChange(eventArgs) {
    if (eventArgs.target.checked) {
      this.nonFcrSettingsForm.get('groupByCategories').setValue([...this.AIGeneratedCategories]);
      this.nonFcrSettingsForm.get('groupByCategories').setValidators(Validators.required);
      this.nonFcrSettingsForm.get('groupByCategories').updateValueAndValidity();
    } else {
      this.nonFcrSettingsForm.get('groupByCategories').clearValidators();
      this.nonFcrSettingsForm.get('groupByCategories').setValue(null);
      this.nonFcrSettingsForm.get('groupByCategories').updateValueAndValidity();
    }
  }

  onGroupByAttachedDataChange(eventArgs) {
    if (eventArgs.target.checked) {
      this.nonFcrSettingsForm.get('groupByAttachedData').setValidators(Validators.required);
      this.nonFcrSettingsForm.get('groupByAttachedData').updateValueAndValidity();
    } else {
      this.nonFcrSettingsForm.get('groupByAttachedData').clearValidators();
      this.nonFcrSettingsForm.get('groupByAttachedData').setValue(null);
      this.nonFcrSettingsForm.get('groupByAttachedData').updateValueAndValidity();
    }
  }

  onSubmitNonFcrSettings(eventArgs) {
    if (this.nonFcrSettingsForm.invalid) {
      return;
    }

    if (eventArgs.submitter.getAttribute("data-restart") == 'true') {
      this.saveAndRestartNonFcr();
    }
    else {
      this.saveNonFcr();
    }
  }

  saveNonFcr() {
    this.addNonFcrSettings();

    this.settingService.saveSetting(this.nonFcrSettingDtos).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }

  saveAndRestartNonFcr() {
    this.confirmationService
      .warn(
        'Settings::NonFcrSaveAndRestartConfirmInformation',
        'Settings::NonFcrSaveAndRestartConfirmTitle',
        {
          yesText: 'Settings::SaveAndRestart',
        }
      )
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.addNonFcrSettings();

          this.settingService.saveSetting(this.nonFcrSettingDtos).subscribe(res => {
            this.settingService.restartNonFcr().subscribe(res => {
              this.toastr.success(
                this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
              );
            });
          });
        }
      });
  }

  setPrebuiltNonFCRCategories() {
    const filterByCategories = JSON.parse(
      this.config.getSetting(this.settingKeyFilterByCategories)
    );
    if (
      this.nonFcrSettingsForm.get('filterByCategoriesEnabled').value &&
      filterByCategories.length == 0 &&
      this.prebuiltUserDefinedCategories?.length > 0
    ) {
      this.nonFcrSettingsForm
        .get('filterByCategories')
        .setValue([...this.prebuiltUserDefinedCategories]);
    }
  }

  setCategorySelectors() {
    if (this.nonFcrSettingsForm.get('filterByCategoriesEnabled').value) {
      const filterByCategories = JSON.parse(
        this.config.getSetting(this.settingKeyFilterByCategories)
      );
      if (filterByCategories.length > 0) {
        this.nonFcrSettingsForm
          .get('filterByCategories')
          .setValue([...this.userDefinedCategories.filter(x => filterByCategories.includes(x.id))]);
      }
      this.nonFcrSettingsForm.get('filterByCategories').setValidators(Validators.required);
      this.nonFcrSettingsForm.get('filterByCategories').updateValueAndValidity();
    }
    if (this.nonFcrSettingsForm.get('groupByCategoriesEnabled').value) {
      const groupByCategories = JSON.parse(
        this.config.getSetting(this.settingKeyGroupByCategories)
      );
      this.nonFcrSettingsForm
        .get('groupByCategories')
        .setValue([...this.categories.filter(x => groupByCategories.includes(x.id))]);
      this.nonFcrSettingsForm.get('groupByCategories').setValidators(Validators.required);
      this.nonFcrSettingsForm.get('groupByCategories').updateValueAndValidity();
    }
    if (this.nonFcrSettingsForm.get('groupByAttachedDataEnabled').value) {
      const groupByAttachedData = JSON.parse(
        this.config.getSetting(this.settingKeyGroupByAttachedData)
      );
      this.nonFcrSettingsForm
        .get('groupByAttachedData')
        .setValue([...this.attachedDatas.filter(x => groupByAttachedData.includes(x.id))]);
      this.nonFcrSettingsForm.get('groupByAttachedData').setValidators(Validators.required);
      this.nonFcrSettingsForm.get('groupByAttachedData').updateValueAndValidity();
    }
  }

  private addNonFcrSettings() {
    this.nonFcrSettingDtos = new Array();
    this.nonFcrSettingDtos.push({
      settingName: this.settingKeyEnabled,
      settingValue: this.nonFcrSettingsForm.get('nonFcrEnabled').value.toString(),
    });

    if (this.nonFcrSettingsForm.get('nonFcrEnabled').value) {
      this.nonFcrSettingDtos.push(
        {
          settingName: this.settingKeyCallInterval,
          settingValue: this.nonFcrSettingsForm.get('nonFcrCallInterval').value.toString(),
        },
        {
          settingName: this.settingKeyMinCallCount,
          settingValue: this.nonFcrSettingsForm.get('nonFcrMinCallCount').value.toString(),
        },
        {
          settingName: this.settingKeyMaxCallCount,
          settingValue: this.nonFcrSettingsForm.get('nonFcrMaxCallCount').value.toString(),
        },
        {
          settingName: this.settingKeyNonFcrCallGroup,
          settingValue: this.nonFcrSettingsForm.get('nonFcrCallGroup').value.toString(),
        },
        {
          settingName: this.settingKeyFilterByCategoriesEnabled,
          settingValue: this.nonFcrSettingsForm.get('filterByCategoriesEnabled').value.toString(),
        },
        {
          settingName: this.settingKeyFilterByCategories,
          settingValue: JSON.stringify(
            this.nonFcrSettingsForm.get('filterByCategories').value?.map(x => x.id) ?? []
          ),
        },
        {
          settingName: this.settingKeyGroupByCategoriesEnabled,
          settingValue: this.nonFcrSettingsForm.get('groupByCategoriesEnabled').value.toString(),
        },
        {
          settingName: this.settingKeyGroupByCategories,
          settingValue: JSON.stringify(
            this.nonFcrSettingsForm.get('groupByCategories').value?.map(x => x.id) ?? []
          ),
        },
        {
          settingName: this.settingKeyGroupByAttachedDataEnabled,
          settingValue: this.nonFcrSettingsForm.get('groupByAttachedDataEnabled').value.toString(),
        },
        {
          settingName: this.settingKeyGroupByAttachedData,
          settingValue: JSON.stringify(
            this.nonFcrSettingsForm.get('groupByAttachedData').value?.map(x => x.id) ?? []
          ),
        }
      );
    }
  }

  popoverClicked(popover: NgbPopover, event: any): boolean {
    popover.toggle();
    event.stopPropagation();
    return false;
  }

  onOpenChanged(event) {
    if (!event) {
      if (this.popover && this.popover.isOpen()) {
        this.popover.close();
      }
    }
  }

  toggleCategoryPreviewPopover(popover: NgbPopover, category: CategoryDto, event: any): boolean {
    this.previewQueryId = category.queryId;

    if (popover.isOpen()) {
      popover.close();
      this.previewQueryId = 0;
    } else {
      const data = this.queryPreviewDataCache.get(category.queryId);
      if (data == null) {
        this.isQueryPreviewLoading = true;
        const categoriesRequest = this.queryService.getQueryCategories();
        const queryItemsRequest = this.service.getById<QueryItemDto[]>(
          QueryDto,
          category.queryId,
          'items'
        );

        this.queryPreviewDataSubscription = combineLatest([
          categoriesRequest,
          queryItemsRequest,
        ]).subscribe(([categories, queryItems]) => {
          const previewData: QueryItemsCategoriesTupleDto = {
            queryBuilderCategoryData: categories.items,
            queryItems: queryItems,
          };

          this.queryPreviewDataCache.set(category.queryId, previewData);
          this.isQueryPreviewLoading = false;
          popover.open({
            query: category.query,
            queryItems: queryItems,
            categories: categories.items,
          });
        });
      } else {
        popover.open({
          query: category.query,
          queryItems: data.queryItems,
          categories: data.queryBuilderCategoryData,
        });
      }
    }

    event.stopPropagation();
    return false;
  }

  toggleTopicPreviewPopover(popover: NgbPopover, category: CategoryDto, event: any): boolean {
    this.previewTopicId = category.topicId;

    if (popover.isOpen()) {
      popover.close();
      this.previewTopicId = 0;
    } else {
      const data = this.topicPreviewDataCache.get(category.topicId);
      if (data == null) {
        this.isTopicPreviewLoading = true;

        this.topicService.getTopicKeywordsById(category.topicId).subscribe(data => {
          this.topicPreviewDataCache.set(category.topicId, data);
          this.isTopicPreviewLoading = false;
          popover.open({ phrases: data.map(k => k.token) });
        });
      } else {
        popover.open({ phrases: data.map(k => k.token) });
      }
    }

    event.stopPropagation();
    return false;
  }
}
