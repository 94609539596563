import { State, Action, StateContext, Selector } from '@ngxs/store';
import { EvaluationResultStateModel } from 'src/core/models/quality/evaluation/evalulation-result.state-model';
import { Injectable } from '@angular/core';
import { GenericState } from '../generic/generic.state';
import {
  EvaluationExcelDetailChange,
  EvaluationScrollChange,
  PageChange,
  SorterChange,
} from 'src/core/actions/quality/evaluation-result.actions';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { EvaluationResultExcelDetailDto } from 'src/core/models/quality/evaluation/evaluation-result-excel-detail.dto';
const name = 'EvaluationResultState';
@State<EvaluationResultStateModel>({
  name: name,
  defaults: {
    currentPage: 0,
    sort: null,
    lastScrollPosition: 0,
  } as EvaluationResultStateModel,
})
@Injectable()
export class EvaluationResultState extends GenericState<EvaluationResultState> {
  @Selector()
  static getSortField(state: EvaluationResultStateModel): string {
    var a = state.sort == null ? 'CreationTime' : state.sort.active;
    return a;
  }

  @Selector()
  static getSortDirection(state: EvaluationResultStateModel): 'asc' | 'desc' | '' {
    return state.sort == null ? 'desc' : state.sort.direction;
  }

  @Selector()
  static getCurrentPage(state: EvaluationResultStateModel): number {
    return state.currentPage;
  }

  @Selector()
  static getLastScrollPosition(state: EvaluationResultStateModel): number {
    return state.lastScrollPosition;
  }

  @Selector()
  static getExcelDetail(state: EvaluationResultStateModel): EvaluationResultExcelDetailDto {
    return state.excelDetail;
  }

  @Action(SorterChange)
  changeSorter(ctx: StateContext<EvaluationResultStateModel>, action: SorterChange) {
    const currentState = ctx.getState();

    currentState.sort = action.sort;
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(PageChange)
  changePage(ctx: StateContext<EvaluationResultStateModel>, action: PageChange) {
    const currentState = ctx.getState();

    currentState.currentPage = action.currentPage;
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  constructor(userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;

    this.userSettingsService.register(this.name, this);
  }

  @Action(EvaluationScrollChange)
  changeScrollPosition(
    ctx: StateContext<EvaluationResultStateModel>,
    action: EvaluationScrollChange
  ) {
    const currentState = ctx.getState();
    currentState.lastScrollPosition = action.lastScrollPosition;

    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(EvaluationExcelDetailChange)
  changeEvaluationExcelDetail(
    ctx: StateContext<EvaluationResultStateModel>,
    action: EvaluationExcelDetailChange
  ) {
    const currentState = ctx.getState();
    currentState.excelDetail = action.excelDetail;

    this.userSettingsService.patchState(ctx, currentState, name);
  }
}
