<div>
  <h2>{{ 'Settings::Licensing' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="form" (ngSubmit)="onSubmitForm()">
  <div class="form-group">
    <input type="checkbox" id="excludeImportAgent" formControlName="excludeImportAgent" />
    <label class="ps-1" for="excludeImportAgent">
      {{ 'Settings::Licensing.ExcludeImportAgent' | abpLocalization }}
    </label>
  </div>

  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
