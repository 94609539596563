import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { merge, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'ca-template-dropdown-selector',
  templateUrl: './template-dropdown-selector.component.html',
  styleUrls: ['./template-dropdown-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TemplateDropdownSelectorComponent),
    },
  ],
})
export class TemplateDropdownSelectorComponent implements OnInit, ControlValueAccessor {
  @ViewChild('inputField', { static: true })
  inputField: ElementRef;

  @Input()
  input$: Observable<any>;

  @Input()
  placeholder: string;

  @Input()
  class: string;

  @Input()
  title: string;

  @Input()
  template: string;

  @Input()
  list: string = null;

  @Output()
  input = new EventEmitter();

  @Output()
  focus = new EventEmitter();

  private get text(): string {
    return this.inputField.nativeElement.value;
  }

  private set text(value: string) {
    this.inputField.nativeElement.value = value;
  }

  private templates: any[];
  private focus$ = new Subject<string>();
  private _model: string;

  constructor() {}

  formatter = item => item[this.template];

  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) => {
    return merge(text$, this.focus$).pipe(
      switchMap(term => (term.length > 0 ? of([]) : of(this.templates)))
    );
  };

  ngOnInit(): void {
    this.input$.pipe(take(1)).subscribe(input => {
      if (this.list == null) {
        this.templates = [
          { index: -1 },
          ...input.map((item, index) => {
            item['index'] = index;
            return item;
          }),
        ];
      } else {
        this.templates = [
          { index: -1 },
          ...input[this.list].map((item, index) => {
            item['index'] = index;
            return item;
          }),
        ];
      }
    });
  }

  selectItem(value: any) {
    this.text = value.item[this.template];
    this.onInputChange();
  }

  onInputChange() {
    this.onChange(this.text);
    this.input.emit();
  }

  onInputFocus() {
    this.focus.emit();
    if (this.text.length <= 0) {
      this.focus$.next(this.text);
    }
  }

  onInputBlur() {
    this.onChange(this.text);
    this.onTouched();
  }

  writeValue(obj: any): void {
    if (obj) {
      this.text = obj.toString();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(value: any) {}

  onTouched() {}
}
