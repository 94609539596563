import { Directive, Input } from '@angular/core';
import { QueryDetailBuilderComponent } from 'src/app/query/query-detail/query-detail-builder/query-detail-builder.component';
import { QueryTreeNodeModel } from './query-tree-node.model';

@Directive()
export class QueryEditorBase {
  @Input()
  node: QueryTreeNodeModel;

  @Input()
  queryBuilder: QueryDetailBuilderComponent;

  @Input()
  isReadonly: boolean;

  currentQueryId: number;

  baseCssClass = 'query-tree-node-wrapper query left-bordered';
  baseCssClassReadonly = 'query-tree-node-wrapper-readonly query left-bordered';

  get cssClass(): string {
    return this.isReadonly
      ? this.baseCssClassReadonly + ' ' + this.node.category.cssClass
      : this.baseCssClass + ' ' + this.node.category.cssClass;
  }

  get text(): string {
    return this.node.categoryItem.title;
  }

  get regexTerm(): RegExp {
    return /^[a-zA-ZığüşöçĞÜŞÖÇİ ]*$/;
  }

  removeNode(eventArgs) {
    this.queryBuilder.removeNode(this.node.categoryItem.payload.internalId);
  }

  onClickDuplicate(eventArgs: MouseEvent): void {
    this.queryBuilder.duplicateNode(this.node.categoryItem.payload.internalId);
  }

  constructor() {}
}
