import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
  Input,
  AfterViewChecked,
  OnChanges,
  SimpleChanges,
  Renderer2,
} from '@angular/core';
import { ConversationLayoutService } from 'src/core/services/conversation/conversation-layout.service';
import { HttpClient } from '@angular/common/http';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { AttachedDataValueDto } from 'src/core/models/administration/attached-data/attached-data-value.dto';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { LayoutConfig } from 'src/core/models/split/layout-config.model';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { CallAnalysisStatus } from 'src/core/models/generic-lookup-type/call/call-analysis-status.glt';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { CrudService } from 'src/core/services/crud/crud.service';
import { CallTranscriptDto } from 'src/core/models/call/call-transcript.dto';
import { CallDto } from 'src/core/models/call/call.dto';
import { PlayerComponent } from 'src/ca-shared/player/player.module';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { ConversationCommentInputDto } from 'src/core/models/comment/comment-input.dto';
import { ConversationCommentDto } from 'src/core/models/comment/comment.dto';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { FileUploadService } from 'src/core/services/fileupload/fileupload.service';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { ConversationMediaType } from 'src/core/models/generic-lookup-type/conversation/conversation-media-type.glt';

@Component({
  selector: 'ca-call-form',
  templateUrl: './call-form.component.html',
  styleUrls: ['./call-form.component.scss'],
})
export class CallFormComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {
  @ViewChild('transcriptContainer', {
    read: ViewContainerRef,
  })
  transcriptContainer: ViewContainerRef;

  @ViewChild('player', {
    read: PlayerComponent,
    static: true,
  })
  player: PlayerComponent;
  callAnalysisType = CallAnalysisStatus;

  @Input()
  set conversation(value: ConversationDto) {
    this._conversation = value;

    if (this.initialized$) {
      this.setPlayer();
    } else {
      this.pendingPlayerRequest$ = true;
    }
  }

  get conversation(): ConversationDto {
    return this._conversation;
  }

  @Select(ConversationModuleState.getCurrentLayout)
  layout$: Observable<LayoutConfig>;

  attachedDataDisplayedColumns: string[] = ['name', 'value'];
  attachedDataDataSource: AttachedDataValueDto[];
  mainRowHeight = 'calc(100% - 80px)';
  initialized$ = false;
  pendingPlayerRequest$ = false;

  private _conversation: ConversationDto;
  conversationComment: ConversationCommentInputDto;
  userPhotoComponentStyles: any = {
    width: '48px',
    height: '48px',
    border: '2px solid black',
    'border-radius': '100%',
  };
  commentText: string;
  userId: string;
  comments: ConversationCommentDto[] = [];
  lastPosition = -1;
  highlightedEls: Element[] = [];
  categoryCount: number;
  private setPlayer() {
    if (this._conversation && this._conversation.typeId === ConversationType.call) {
      this.player.agentChannel = this.conversation?.call?.agentChannelId;
      this.player.contentSource = `api/app/conversation/${this._conversation.id}/media/${ConversationMediaType.voiceRecording}`;
      this.player.conversationId = this.conversation.id;
      this.player.load();
    } else {
      this.player.reset();
    }

    this.player.positionChange.subscribe(this.onAudioProcess.bind(this));
  }

  constructor(
    private conversationLayoutService: ConversationLayoutService,
    private crudService: CrudService,
    private http: HttpClient,
    private conversationService: ConversationService,
    private configStateService: ConfigStateService,
    private fileUploadService: FileUploadService,
    private renderer: Renderer2,
    private confirmationService: ConfirmationService,
    private localizationService: LocalizationService
  ) { }

  ngAfterViewChecked(): void {
    if (this.pendingPlayerRequest$) {
      this.pendingPlayerRequest$ = false;
      this.setPlayer();
    }
  }

  ngOnInit() {
    this.layout$.subscribe(value => {
      this.player.refresh();
      if (!value.east.visible) {
        this.player.stop();
      }
    });

    this.initialized$ = true;
    this.userId = this.configStateService.getDeep('currentUser.id');
  }

  ngOnChanges(changes: SimpleChanges) {
    // tslint:disable-next-line: no-string-literal
    if (changes['conversation'].currentValue != null) {
      // get comments of conversation
      this.getCommentsByConversationId(changes['conversation'].currentValue['id'] as number);

      // tslint:disable-next-line: no-string-literal
      let attachedData = changes['conversation'].currentValue[
        // tslint:disable-next-line: no-string-literal
        'attachedData'
      ] as AttachedDataValueDto[];
      attachedData = attachedData.filter(f => f.visible === true);
      this.attachedDataDataSource = attachedData;

      if (
        this.conversation.typeId === ConversationType.call &&
        this.conversation.call.callAnalysis.status.id === CallAnalysisStatus.analyzed
      ) {
        this.crudService
          .getById<CallTranscriptDto[]>(CallDto, this.conversation.call.id, 'transcript')
          .subscribe(response => {
            this.renderTranscript(response);
          });
      }
    }
  }

  onHeightChange(eventArgs: { previousHeight: number; newHeight: number }) {
    this.mainRowHeight = 'calc(100% - ' + eventArgs.newHeight + 'px)';
  }

  ngAfterViewInit() { }

  renderTranscript(data: any[]) {
    let htmlToAdd = '';

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < data.length; i++) {
      const curr = data[i];
      const prev = data[i - 1];
      const next = data[i + 1];

      const align = curr.channel === 1 ? 'flex-start' : 'flex-end';
      const channelText =
        curr.channel === 1
          ? this.localizationService.instant('Conversation::Agent')
          : this.localizationService.instant('Conversation::Customer');
      const channelColor = 'black';
      const style =
        curr.channel === 1
          ? // tslint:disable-next-line: max-line-length
          'border: 1px solid silver; max-width: 70%; margin-left: 0px; margin-right: auto; border-radius: 10px; background-color: rgb(235, 206, 237); color: black; padding: 10px; font-size: 0.9em'
          : // tslint:disable-next-line: max-line-length
          'border: 1px solid silver; max-width: 70%; margin-right: 0px; margin-left: auto; border-radius: 10px; background-color: rgb(185, 214, 243); color: black; padding: 10px; text-align: left; font-size: 0.9em';

      if (i === 0 || curr.channel !== prev.channel) {
        const textTime = this.calculateTime(curr.startTime);

        htmlToAdd +=
          '<div style="width: 100%; margin-top: 8px; dis: flex; flex-direction: row; justify-content: ' +
          align +
          '"><div style="' +
          style +
          '"><small style="color: ' +
          channelColor +
          '; float: right; ">' +
          textTime +
          '</small><br />' +
          '<small style="color: ' +
          channelColor +
          '">' +
          channelText +
          '</small><br />';
      }

      const seconds = Math.floor((curr.startTime / 1000) * 10);

      htmlToAdd += '<span class="' + seconds + '"> ' + curr.word + '</span>';

      // if (i === 0 || curr.channel === prev.channel) {
      //     htmlToAdd += '<span> ' + curr.word + '</span>';
      // }

      if (i === data.length - 1 || curr.channel !== next.channel) {
        htmlToAdd += '</div></div>';
      }
    }
    this.transcriptContainer.element.nativeElement.insertAdjacentHTML('afterbegin', htmlToAdd);
  }

  calculateTime(currentTime: number) {
    const seconds = Math.floor(currentTime / 1000);
    const durationHours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, '0');
    const durationMins = Math.floor((seconds / 60) % 60)
      .toString()
      .padStart(2, '0');
    const durationSecs = Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0');
    return durationHours + ':' + durationMins + ':' + durationSecs;
  }

  showTab(eventArgs) {
    const el = eventArgs.currentTarget;
    const targetTabId = el.getAttribute('data-target-tab-id');
    const allItems = document.querySelectorAll('a.list-group-item') as NodeListOf<HTMLElement>;
    const tabPanes = document.querySelectorAll('div.tab-pane') as NodeListOf<HTMLElement>;
    const targetTabPane = document.querySelector(
      'div[data-tab-id="' + targetTabId + '"]'
    ) as HTMLElement;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < allItems.length; i++) {
      allItems[i].classList.remove('active');
    }

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < tabPanes.length; i++) {
      tabPanes[i].classList.remove('show');
      tabPanes[i].style.display = 'none';
    }

    el.classList.add('active');
    targetTabPane.classList.add('show');
    targetTabPane.style.display = 'initial';

    return false;
  }

  closePanel() {
    this.conversationLayoutService.changeVisibilityForDetailPanel(false);
    this.player.stop();
  }

  getCommentsByConversationId(id: number) {
    return this.conversationService.getCommentsByConversationId(id).subscribe(data => {
      this.comments = data;
    });
  }

  addComment(eventArgs, edtComment: HTMLTextAreaElement) {
    const comment = edtComment.value;
    this.conversationComment = new ConversationCommentInputDto();
    this.conversationComment.conversationId = this.conversation.id;
    this.conversationComment.comment = comment;
    this.conversationService.addComment(this.conversationComment).subscribe(data => {
      this.comments.unshift(data);
      edtComment.value = '';
    });
  }

  deleteComment(id: number, commentId: number) {
    this.conversationService.deleteComment(id, commentId).subscribe(data => {
      this.getCommentsByConversationId(id);
    });
  }

  confirmDeletion(conversationComment, eventArgs) {
    this.confirmationService
      .warn('::DeletionConfirmationMessage', '', {
        messageLocalizationParams: [this.localizationService.instant('::Comment')],
        yesText: '::Delete',
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.deleteComment(this.conversation.id, conversationComment.id);
        }
      });
  }

  onAudioProcess(args: { currentTime: number; currentTimeString: string }) {
    const position: number = Math.floor(args.currentTime * 10);
    if (position === this.lastPosition) {
      return;
    }

    this.lastPosition = position;

    if (this.transcriptContainer != null) {
      const targetEl: HTMLElement = this.transcriptContainer.element.nativeElement;
      const targetDivArr: HTMLCollection = targetEl.getElementsByClassName(
        position.toLocaleString()
      );

      if (targetDivArr.length > 0) {
        for (let j = 0; j < this.highlightedEls.length; j++) {
          this.renderer.setStyle(this.highlightedEls[j], 'background-color', 'transparent');
        }

        this.highlightedEls = [];

        for (let i = 0; i < targetDivArr.length; i++) {
          targetDivArr[i].scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
          this.renderer.setStyle(targetDivArr[i], 'background-color', 'yellow');

          this.highlightedEls.push(targetDivArr[i]);
        }
      }
    }
  }
  setCategoryCount(event: any) {
    this.categoryCount = event.count;
  }
}
