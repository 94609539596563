<div class="filter-container">
  <div [class]="filterByStatus ? 'col-9 p-0' : 'col-12 p-0'">
    <ca-select
      *ngIf="!hideFilterMode"
      [disabled]="disabled"
      [(ngModel)]="value.filterModeId"
      (change)="onFilterModeChange()">
      <ca-option *ngFor="let filterMode of filterModes" [value]="filterMode.id">
        {{ 'GenericLookup::' + filterMode.code | abpLocalization }}
      </ca-option>
    </ca-select>
  </div>
  <div *ngIf="filterByStatus" [class]="filterByStatus ? 'col-3 pe-0' : ''">
    <ng-select
      [clearable]="false"
      [searchable]="false"
      [items]="statusItems"
      [ngModel]="currentStatus"
      [ngbTooltip]="currentStatus"
      [disableTooltip]="disableTooltip"
      (open)="disableTooltip = true"
      (close)="disableTooltip = false"
      (change)="changeStatusFilter($event)"
      appendTo="body">
      <ng-template ng-label-tmp let-item="item">
        <div placement="top" [ngClass]="item.class"></div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div class="d-flex flex-row align-items-center">
          <span [ngClass]="item.class"></span>
          <span class="ms-2">{{ item.label }}</span>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>

<div class="pt-2">
  <ca-dropdown-tree
    [selectChilds]="true"
    [hideToolbar]="departmentTreeOptions.hideToolbar"
    [searchable]="departmentTreeOptions.searchable"
    [showSelections]="false"
    #departmentDropdownTree
    [nodeTemplate]="nodeTemplate"
    [emptyText]="departmentTreeOptions.emptyText"
    [idProperty]="'id'"
    [selectionTemplate]="departmentSelectionTemplate"
    [parentIdProperty]="'parentId'"
    [dropdownEnabled]="false"
    [multiple]="departmentTreeOptions.multiple"
    [styles]="{ width: '100%' }"
    [selectionText]="departmentTreeOptions.selectionText"
    [selectionStyle]="{ 'max-height': '150px', 'overflow-y': 'auto' }"
    [width]="'100%'"
    [disabled]="disabled"
    [disabledItemMessage]="disabledItemMessage"
    [(ngModel)]="value.selectedDepartments"
    (ngModelChange)="onSelectionChange()"
    style="width: 100%"></ca-dropdown-tree>
</div>
<ng-template #nodeTemplate let-payload="payload">
  <ng-container
    *ngIf="filterByStatus"
    class="truncate-text"
    [ngbTooltip]="payload.name"
    container="body">
    <i
      [ngClass]="
        payload?.item?.isActive
          ? 'fas fa-circle me-1 ms-1 active-item-icon'
          : 'fas fa-circle me-1 ms-1 passive-item-icon'
      "></i>
    {{ payload.name }}
  </ng-container>
  <ng-container
    *ngIf="!filterByStatus"
    class="truncate-text"
    [ngbTooltip]="payload.name"
    showTooltipIfTruncated
    container="body">
    {{ payload.name }}
  </ng-container>
</ng-template>
<ng-template #departmentSelectionTemplate let-selection="selection">
  {{ selection.name }}
</ng-template>
