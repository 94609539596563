import { downloadBlob, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { CallLanguageCodeDto } from 'src/core/models/call/call-language-code.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { CallService } from 'src/core/services/conversation/call.service';
import { ConversationImportFileModel } from 'src/core/models/conversation/conversation-import-file-model';

@Component({
  selector: 'ca-call-import-step-two',
  templateUrl: './call-import-step-two.component.html',
  styleUrls: ['./call-import-step-two.component.scss'],
})
export class CallImportStepTwo implements OnInit {
  languages: CallLanguageCodeDto[];

  constructor(
    private callService: CallService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private globalSettingsService: GlobalSettingsService,
    private settingService: ConfigurationSettingsService
  ) {
    this.infoLabel = this.localizationService.instant('Conversation::UploadCallCsvStepInfo');
    this.infoLabel = this.infoLabel.replace(
      '{0}',
      '<b>' + this.localizationService.instant('Conversation::UploadCallCsvMaxSize') + '</b>'
    );

    this.systemLangCode = this.globalSettingsService.systemLanguageCode;

    this.systemLangCode = this.globalSettingsService.systemLanguageCode;
    this.langCodes = this.globalSettingsService.otherLangCodes.split(';').filter(x => x.length > 0);
    this.langCodes.push(this.systemLangCode);
    this.settingService.getLanguageCodeList().subscribe(res => {
      this.languages = res.filter(l => this.langCodes.includes(l.code));
      this.languages.sort((x, y) => (x.name > y.name ? 1 : -1));
    });
  }

  public selectedConversationFiles: ConversationImportFileModel[] = [];
  public selectedCsvFile: File;
  public systemLangCode: string;
  public langCodes: string[];

  acceptFileExtensions: string[] = ['.csv'];
  infoLabel: string = '';
  maxMetadataSize: number = 200 * 1024 * 1024;

  ngOnInit(): void {}

  validateEntryToStep() {
    return true;
  }

  validateExitFromStep() {
    return of(true);
  }

  downloadSampleCsv() {
    this.callService.downloadSampleCsv().subscribe(result => {
      if (result.size > 0) {
        downloadBlob(result, 'sampleCallImport.csv');
      }
    });
  }

  onFileChange(files) {
    if (files.length > 0) {
      const splitName = files.item(0).name.split('.');
      if (files.item(0).size > this.maxMetadataSize) {
        this.selectedCsvFile = null;
        this.toastr.error(this.localizationService.instant('Conversation::FileSizeTooLarge'));
        return;
      }
      if (this.acceptFileExtensions.indexOf('.' + splitName[splitName.length - 1]) <= -1) {
        this.selectedCsvFile = null;
        this.toastr.error(
          this.localizationService.instant(
            '::FileExtensionError',
            this.acceptFileExtensions.join(',')
          )
        );
        return;
      }
      this.selectedCsvFile = files.item(0);
      return;
    }
    this.selectedCsvFile = null;
  }
}
