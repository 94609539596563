import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ReasonDto } from 'src/core/models/quality/reason.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { ReasonPoolService } from 'src/core/services/administration/reason-pool.service';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'ca-reason-dropdown-selector',
  templateUrl: './reason-dropdown-selector.component.html',
  styleUrls: ['./reason-dropdown-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ReasonDropdownSelectorComponent),
    },
  ],
})
export class ReasonDropdownSelectorComponent implements OnInit, ControlValueAccessor {
  @Input()
  reasonType: number;

  @Input()
  canAddNewReason: boolean;

  @Input()
  useExternalData: boolean;

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  selectedItem: any;

  selectedReasonList: ReasonDto[] = [];

  @Input()
  set reasonData(value: ReasonDto[]) {
    this._reasonData = value;
    this.reasonList = this._reasonData;
  }

  get reasonData(): ReasonDto[] {
    return this._reasonData;
  }

  @Input()
  set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  reasonList: ReasonDto[];

  isDisabled: boolean = false;

  filters: FilterItemDto[] = [];
  sorters: SorterItemDto[] = [];

  get value(): ReasonDto[] {
    return this.selectedReasonList;
  }

  private _reasonData: ReasonDto[] = [];
  constructor(
    private service: CrudService,
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private reasonPoolService: ReasonPoolService,
    private operators: Operators
  ) {
    this.reasonPoolService.changed.subscribe(() => {
      if (this.useExternalData !== true) {
        this.loadReasons(this.filters, this.sorters);
      }
    });
  }

  writeValue(obj: any): void {
    obj = obj ? obj : [];
    this.selectedReasonList = obj;
    this.selectedItem = this.selectedReasonList;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(selection: any[]) {}

  onTouched() {}
  ngOnInit(): void {
    if (this.useExternalData !== true) {
      this.filters.push({
        field: 'reasonType',
        operator: this.operators.Equals,
        value: this.reasonType,
      });
      this.sorters.push({
        field: 'Text',
        direction: 'ASC',
      });
      this.loadReasons(this.filters, this.sorters);
    }
  }

  setDisabledState(isDisabled) {
    this.isDisabled = isDisabled;
  }

  loadReasons(filters: FilterItemDto[], sorters: SorterItemDto[]) {
    this.service
      .get<ReasonDto>(ReasonDto, {
        filters: filters,
        sorters: sorters,
        maxResultCount: 99999,
        skipCount: 0,
      })
      .subscribe(response => {
        this.reasonData = response.items;
        this.reasonList = this.reasonData;
      });
  }

  onClickDropDown(reason?: ReasonDto) {
    if (reason.id == 0) {
      if (reason.text.length < 3 || reason.text.length > 256) {
        this.reasonList = [...this.reasonData];
        this.ngSelectComponent.itemsList.unselect(
          this.ngSelectComponent.selectedItems.filter(x => x.index == 0)[0]
        );
        this.ngSelectComponent.detectChanges();
        this.toastr.error(
          this.localizationService.instant('QualityManagement::ReasonTextMinMaxValidation')
        );
      } else {
        this.service.save<ReasonDto>(ReasonDto, reason).subscribe(data => {
          this.reasonList = this.reasonList.filter(r => r.id !== 0);
          this.reasonList.push(data);
          this.selectedReasonList.push(data);
          this.reasonList = [...this.reasonList];
          this.toastr.success(this.localizationService.instant('::SuccessfullySaved'));
          this.reasonPoolService.notify();
        });
      }
    } else {
      this.selectedReasonList.push(reason);
      this.reasonList = this.reasonData;
    }
    this.onChange(this.value);
  }

  onCloseDropDown() {
    this.reasonList = this.reasonData;
  }

  search(eventArgs) {
    let filter = eventArgs.term.toLowerCase().trim();
    let searchedItem = this.reasonData.filter(reason => reason.text.toLowerCase() == filter);
    let result = this.reasonData.filter(reason => reason.text.toLowerCase().includes(filter));
    if ((result.length == 0 || searchedItem.length == 0) && filter && this.canAddNewReason) {
      const reason: ReasonDto = {
        id: 0,
        text: eventArgs.term.trim(),
        reasonTypeId: this.reasonType,
        lastModificationDate: null,
      };
      result.splice(0, 0, reason);
    }
    this.reasonList = result;
    this.ngSelectComponent.searchTerm = filter;
  }

  checkOverflow(element) {
    return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
  }

  clearModel() {
    this.selectedReasonList = [];
    this.onCloseDropDown();
    this.ngSelectComponent.clearModel();
    this.onChange(this.value);
  }

  addReason(item) {
    this.onClickDropDown(item);
  }

  removeReason(item) {
    this.selectedReasonList = this.selectedReasonList.filter(r => r.id != item.value.id);
    this.reasonList = this.reasonData;
    this.onChange(this.value);
  }
}
