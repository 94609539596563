import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownSelectorComponent } from './components/dropdown-selector/dropdown-selector.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectorComponent } from './components/selector/selector.component';
import { AutoCompleteSelectorComponent } from './components/auto-complete-selector/auto-complete-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconsModule } from '../icons/icons.module';
import { MatchWidthDirective } from 'src/core/directives/match-width.directive';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BlockUIModule } from 'ng-block-ui';
import { SelectComponent } from './components/select/select.component';
import { OptionComponent } from './components/option/option.component';
import { ShowTooltipIfTruncatedDirective } from './directives/show-tooltip-if-truncated.directive';

export * from './components/dropdown-selector/dropdown-selector.component';
export * from './components/selector/selector.component';
export * from './models/selector-datasource.model';

@NgModule({
  declarations: [
    DropdownSelectorComponent,
    SelectorComponent,
    SelectComponent,
    AutoCompleteSelectorComponent,
    MatchWidthDirective,
    OptionComponent,
    ShowTooltipIfTruncatedDirective,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgbDropdownModule,
    BlockUIModule,
    MatSlideToggleModule,
    NgSelectModule,
    IconsModule,
    NgbModule,
    MatPaginatorModule,
    NgbTooltipModule,
  ],
  exports: [
    DropdownSelectorComponent,
    SelectorComponent,
    SelectComponent,
    OptionComponent,
    AutoCompleteSelectorComponent,
    MatchWidthDirective,
  ],
})
export class SelectorModule { }
