import { Component, OnInit } from '@angular/core';
import { ConfigStateService } from '@abp/ng.core';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ToasterService } from '@abp/ng.theme.shared';
import { LocalizationService } from '@abp/ng.core';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Component({
  selector: 'ca-indexing-settings',
  templateUrl: './indexing-settings.component.html',
  styleUrls: ['./indexing-settings.component.scss'],
})
export class IndexingSettingsComponent implements OnInit {
  indexingSettingDtos: ConfigurationSettingsDto[] = [];
  indexingSettingsForm: FormGroup;

  private readonly settingQueryCleanupStartTime = 'Indexing.QueryCleanupStartTime';

  constructor(
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private fb: FormBuilder,
  ) {

    this.indexingSettingsForm = fb.group({
      queryCleanupStartTime: [
        this.config.getSetting(this.settingQueryCleanupStartTime),
        { validators: [Validators.required] },
      ],
    });
  }

  ngOnInit(): void { }

  popoverClicked(popover: NgbPopover, event: any): boolean {
    popover.toggle();
    event.stopPropagation();
    return false;
  }

  onSubmitIndexingSettings() {

    if (this.indexingSettingsForm.invalid) {
      return;
    }

    this.addIndexingSettings();

    this.settingService.saveSetting(this.indexingSettingDtos).subscribe((res) => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }

  private addIndexingSettings() {
    this.indexingSettingDtos.push(
      {
        settingName: this.settingQueryCleanupStartTime,
        settingValue: this.indexingSettingsForm
          .get('queryCleanupStartTime')
          .value,
      },
    );
  }
}
