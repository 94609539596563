import { EntityDto } from '../shared/entity.dto';

export class SimpleTermWithRangeQueryItemDto extends EntityDto {
  operator: number;
  categoryId: number;
  categoryItemId: number;
  internalId: number;
  parentInternalId: number;
  queryId: number;
  sideId: number;
  sideCode: string;
  term: string;
  rangeUnitId: number;
  rangeUnitCode: number;
  startTime: number;
  endTime: number;
  not: boolean;
  multiple: boolean;
}
