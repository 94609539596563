import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { DashboardModuleStateModel } from 'src/core/models/dashboard/dashboard-module.state-model';

import { GenericState } from '../generic/generic.state';
import { WidgetFilterChange, WidgetsChange } from 'src/core/actions/analysis-report/analysis-report-module.actions';
const name = 'DashboardModuleState';
@State<DashboardModuleStateModel>({
  name: name,
  defaults: {
    widgets: {},
  } as DashboardModuleStateModel,
})
@Injectable()
export class DashboardModuleState extends GenericState<DashboardModuleState> {
  constructor(userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;

    this.userSettingsService.register(this.name, this);
  }

  @Action(WidgetsChange)
  changeWidgets(ctx: StateContext<DashboardModuleStateModel>, action: WidgetsChange) {
    const currentState = ctx.getState();

    if (!Array.isArray(currentState.widgets)) {
      currentState.widgets = [];
    }
    action.widgets.forEach(widget => {
      let stateWidget = currentState.widgets.find(x => x.component == widget.component);
      if (!stateWidget) {
        currentState.widgets.push(widget);
      } else {
        stateWidget.widgetConfigs = widget.widgetConfigs;
      }
    });
    currentState.widgets.forEach(widget => {
      let stateWidget = action.widgets.find(x => x.component == widget.component);
      if (!stateWidget && !widget.default) {
        const index = currentState.widgets.map(x => x.component).indexOf(widget.component);
        currentState.widgets.splice(index, 1);
      }
    });
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(WidgetFilterChange)
  changeWidgetFilter(ctx: StateContext<DashboardModuleStateModel>, action: WidgetFilterChange) {
    let currentState = ctx.getState();
    if (!Array.isArray(currentState.widgets)) {
      currentState.widgets = [];
    }

    let currentWidgetState = currentState.widgets.find(x => x.component == action.component);
    if (!currentWidgetState) {
      currentState.widgets.push({
        component: action.component,
        default: true,
        widgetFilters: action.filters,
      });
    } else {
      currentWidgetState.widgetFilters = action.filters;
    }
    this.userSettingsService.patchState(ctx, currentState, name);
  }
}
