import { MapToServerSideEnum } from 'src/core/decorators/enum/mapto-server-side-enum.decorator';
import { ServerSideEnum } from '../../enum/server-side-enum.model';
import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@MapToServerSideEnum('ConversationTranscriptItemChannel')
export class ConversationTranscriptItemChannel extends ServerSideEnum {
  Agent: number;
  Customer: number;
  Participant: number;

  constructor(injector: Injector) {
    super(injector);
  }
}
