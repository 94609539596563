import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { ConversationListResponseDto } from 'src/core/models/conversation/conversation-list-response.dto';
import { Observable } from 'rxjs';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { MatTableDataSource } from '@angular/material/table';
import { LocalizationService, PermissionService } from '@abp/ng.core';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { DecimalPipe } from '@angular/common';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';
import { CompactPlayerComponent } from 'src/ca-shared/player/compact-player/compact-player.component';
import { ColumnConfiguratorService } from 'src/ui/column-configurator/column-configurator.module';
import { ConversationListType } from 'src/core/models/generic-lookup-type/conversation/conversation-list-type.glt';
import { FormatHelper } from 'src/core/helpers/format.helper';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';

@Component({
  selector: 'ca-conversation-standard-call-list',
  templateUrl: './standard-call-list.component.html',
  styleUrls: ['./standard-call-list.component.scss'],
})
export class StandardCallListComponent implements OnInit {
  @Select(ConversationModuleState.getData)
  data$: Observable<ConversationListResponseDto>;

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<ConversationDto>();
  data: ConversationDto[] = [];

  @Input()
  compactPlayer: CompactPlayerComponent;

  @Output()
  conversationIdChange: EventEmitter<{
    id: number;
  }> = new EventEmitter();

  @Output()
  dataLoaded = new EventEmitter();

  gridId: string;

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'icons',
      header: this.localizationService.instant('::Icons'),
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: '185px',
      tooltip: 'Conversation Type',
      locked: true,
      hideHeader: true,
      visible: true,
    },
    {
      columnName: 'conversationId',
      header: 'ID',
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: '100px',
      minWidth: '100px',
      tooltip: this.localizationService.instant('Conversation::ConversationId'),
      sticky: true,
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: true,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'userName',
      header: this.localizationService.instant('::Agent'),
      binding: 'userName',
      valueParser: undefined,
      pipes: undefined,
      width: '150px',
      minWidth: '150px',
      tooltip: undefined,
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'departmentName',
      header: this.localizationService.instant('::Department'),
      binding: 'departmentName',
      valueParser: undefined,
      pipes: undefined,
      width: '150px',
      minWidth: '150px',
      tooltip: undefined,
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'channelName',
      header: this.localizationService.instant('::Channel'),
      binding: 'channelName',
      valueParser: val => {
        return this.localizationService.instant('GenericLookup::' + val);
      },
      pipes: undefined,
      width: '100px',
      tooltip: undefined,
      locked: false,
      hideHeader: false,
      visible: false,
    },
    {
      columnName: 'conversationSourceName',
      header: this.localizationService.instant('::Source'),
      binding: 'conversationSourceName',
      valueParser: undefined,
      pipes: undefined,
      width: '100px',
      minWidth: '100px',
      tooltip: undefined,
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'mainCategoryName',
      header: this.localizationService.instant('::MainCategoryName'),
      binding: 'mainCategoryName',
      valueParser: undefined,
      pipes: undefined,
      width: '100px',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      tooltip: undefined,
      visible: false,
      locked: false,
    },
    {
      columnName: 'caller',
      header: this.localizationService.instant('Conversation::Caller'),
      binding: 'call.callerNumber',
      valueParser: undefined,
      pipes: undefined,
      width: '100px',
      minWidth: '100px',
      tooltip: undefined,
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible:
        !this.globalSettingsService.dynamicDataMaskingEnabled ||
        this.permissionService.getGrantedPolicy('Conversation.CanViewRedactedData'),
    },
    {
      columnName: 'called',
      header: this.localizationService.instant('Conversation::Called'),
      binding: 'call.calledNumber',
      valueParser: undefined,
      pipes: undefined,
      width: '100px',
      minWidth: '100px',
      tooltip: undefined,
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible:
        !this.globalSettingsService.dynamicDataMaskingEnabled ||
        this.permissionService.getGrantedPolicy('Conversation.CanViewRedactedData'),
    },
    {
      columnName: 'holdCount',
      header: this.localizationService.instant('::Holds'),
      binding: 'call.holdCount',
      valueParser: undefined,
      pipes: undefined,
      width: '100px',
      tooltip: undefined,
      sortable: true,
      sorterFieldName: 'Call.HoldCount',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'totalHoldDuration',
      header: this.localizationService.instant('Conversation::CallTotalHoldDuration'),
      binding: 'call.totalHoldDuration',
      valueParser: val => {
        if (val) {
          return this.formatHelper.convertTimerMillisecondToShortStringWithSymbol(val);
        }

        return (
          '00 ' +
          this.localizationService.instant('::MinuteShort') +
          ' 00 ' +
          this.localizationService.instant('::SecondsShort')
        );
      },
      pipes: undefined,
      width: '110px',
      tooltip: undefined,
      sortable: true,
      sorterFieldName: 'Call.TotalHoldDuration',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'transferCount',
      header: this.localizationService.instant('Conversation::Transfers'),
      binding: 'transferCount',
      valueParser: undefined,
      pipes: undefined,
      width: '110px',
      tooltip: undefined,
      sortable: true,
      sorterFieldName: 'TransferCount',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'relatedGroupId',
      header: this.localizationService.instant('Conversation::RelatedGroupId'),
      binding: 'relatedGroupId',
      valueParser: undefined,
      pipes: undefined,
      width: '120px',
      tooltip: this.localizationService.instant('Conversation::RelatedGroupIdHeaderInfo'),
      sortable: false,
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'startTime',
      header: this.localizationService.instant('::Time'),
      binding: 'startTime',
      valueParser: val => {
        return new Date(val);
      },
      pipes: [
        {
          pipe: CADatePipe,
          args: [DateDisplayType.DateTimeWithoutSeconds, true],
        },
      ],
      width: '150px',
      tooltip: undefined,
      sortable: true,
      sorterFieldName: 'StartTime',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'duration',
      header: this.localizationService.instant('::Duration'),
      binding: 'duration',
      valueParser: undefined,
      pipes: [
        {
          pipe: CATimePipe,
          args: [true],
        },
      ],
      width: '150px',
      tooltip: undefined,
      sortable: true,
      sorterFieldName: 'Duration',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'ratingForNPS',
      header: this.localizationService.instant('::RatingForNPS'),
      binding: 'ratingForNPS',
      valueParser: undefined,
      pipes: [
        {
          pipe: DecimalPipe,
          args: ['1.2-2'],
        },
      ],
      width: '140px',
      tooltip: undefined,
      sortable: true,
      sorterFieldName: 'RatingForNPS',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'ratingForCSAT',
      header: this.localizationService.instant('::RatingForCSAT'),
      binding: 'ratingForCSAT',
      valueParser: undefined,
      pipes: [
        {
          pipe: DecimalPipe,
          args: ['1.2-2'],
        },
      ],
      width: '110px',
      tooltip: undefined,
      sortable: true,
      sorterFieldName: 'RatingForCSAT',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'predictedCSATScore',
      header: this.localizationService.instant('Conversation::PredictedCSATScore'),
      binding: 'predictedCSATScore',
      valueParser: undefined,
      pipes: [
        {
          pipe: DecimalPipe,
          args: ['1.2-2'],
        },
      ],
      width: '110px',
      tooltip: undefined,
      sortable: true,
      sorterFieldName: 'PredictedCSATScore',
      headerClass: 'header-align-right',
      cellClass: 'cell-align-right',
      locked: false,
      hideHeader: false,
      visible: true,
    },
    {
      columnName: 'uniqueCustomerKey',
      header: this.localizationService.instant('Conversation::UniqueCustomerKey'),
      binding: 'uniqueCustomerKey',
      valueParser: undefined,
      pipes: undefined,
      width: '150px',
      minWidth: '150px',
      tooltip: undefined,
      locked: false,
      hideHeader: false,
      visible: false,
    },
    {
      columnName: 'externalId',
      header: this.localizationService.instant('::ExternalId'),
      binding: 'externalId',
      valueParser: undefined,
      pipes: undefined,
      width: '150px',
      minWidth: '150px',
      tooltip: undefined,
      visible: false,
      locked: false,
      hideHeader: false,
    },
  ];

  constructor(
    private localizationService: LocalizationService,
    private conversationService: ConversationService,
    private columnConfiguratorService: ColumnConfiguratorService,
    private formatHelper: FormatHelper,
    private globalSettingsService: GlobalSettingsService,
    private permissionService: PermissionService
  ) {
    this.gridId = `conversation-list-${ConversationListType.call}`;

    this.conversationService.checkAndAddAutomaticEvaluationScoreColumn(this.gridColumns);

    this.data$.subscribe(data => {
      this.data = data === null ? [] : data.items;

      this.dataSource.connect().next(this.data);
      this.dataLoaded.emit();
    });
  }

  ngOnInit() {}

  onConversationIdChanged(conversationId) {
    this.conversationIdChange.emit(conversationId);
  }
}
