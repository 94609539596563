<div ngbDropdown [ngStyle]="styles" class="d-inline-block dropdown-wrapper" *ngIf="dropdownEnabled">
  <button
    type="button"
    [ngClass]="'dropdown-tree-button ' + buttonCls"
    [style.width]="width"
    [disabled]="disabled"
    ngbDropdownToggle>
    <ng-container *ngIf="value.length <= 0">
      <ng-container>
        {{ emptyText }}
      </ng-container>
    </ng-container>
    <ng-container *ngIf="value.length > 0">
      <ng-container *ngTemplateOutlet="selectionTemplate; context: { selection: value }">
      </ng-container>
    </ng-container>
  </button>
  <div ngbDropdownMenu>
    <div [ngTemplateOutlet]="treeForm"></div>
  </div>
</div>
<div *ngIf="!dropdownEnabled">
  <div [ngTemplateOutlet]="treeForm"></div>
</div>
<ng-template #treeForm>
  <div class="drowdown-menu" [style.width]="menuWidth">
    <ca-dropdown-tree-toolbar
      [hidden]="hideToolbar"
      [multiple]="multiple"
      (expandAllClick)="onExpandAllClick($event)"
      (collapseAllClick)="onCollapseAllClick($event)"
      (clearClick)="onClearClick($event)"
      (selectAllClick)="onSelectAllClick($event)"></ca-dropdown-tree-toolbar>
    <ng-container *ngIf="rootNodes.length > 0 && showSelections && selected.length > 0">
      <div class="row p-0">
        <div class="col-11 pb-2 pl-3">
          <div class="row">
            <div class="col-6" *ngFor="let item of selected">
              <div class="dropdown-tree-card-item-wrapper tag">
                <span>
                  <ng-container *ngTemplateOutlet="selectionTemplate; context: { selection: item }">
                  </ng-container>
                </span>
                <span
                  (click)="onClickRemoveItem($event, item)"
                  *ngIf="!disabled"
                  class="dropdown-tree-remove-selection-icon tagicon">
                  <i class="fas fa-times"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-1 pb-2 pt-1">
          <div class="row">
            <ca-delete-icon
              type="button"
              (click)="onClearClick($event)"
              *ngIf="!disabled"
              title="{{ '::Clear' | abpLocalization }}"></ca-delete-icon>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="search-container" *ngIf="rootNodes.length > 0 && searchable">
      <label
        *ngIf="selectionText?.length > 0"
        class="selection-label pe-3 text-primary"
        for="input-container">
        {{ selectionText }}</label
      >
      <div class="input-container">
        <div class="input-group input-group-sm">
          <i [ngClass]="'fas fa-search search-icon'"></i>
          <input
            type="text"
            class="form-control search-text"
            placeholder="{{ 'AbpUi::Search' | abpLocalization }}"
            [(ngModel)]="queryValue"
            (keyup)="onSearchRequest($event)"
            [disabled]="disabled"
            #edtSearch />
          <div class="input-group-text">
            <button
              class="btn btn-sm btn-outline-dark btn-remove-filter"
              type="button"
              (click)="onRemoveFilterClick($event)"
              [disabled]="disabled">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="information" class="text-danger text-start text-nowrap">
      <i class="fas fa-info ps-1 pt-1 fa-xs"></i>
      <span class="ps-1">
        <small>{{ this.information }}</small>
      </span>
    </div>
    <div class="mt-2" [ngStyle]="selectionStyle">
      <span *ngIf="!hasVisibleNode">{{ '::NoItemsFound' | abpLocalization }}</span>
      <ca-dropdown-tree-node
        [hidden]="node.hidden"
        [hasSelectedChild]="hasUnselectedChild(node)"
        [level]="0"
        [data]="node"
        [nodeTemplate]="nodeTemplate"
        [owner]="thisRef"
        [idProperty]="idProperty"
        [parentIdProperty]="parentIdProperty"
        [disabled]="disabled"
        [disabledItemMessage]="disabledItemMessage"
        *ngFor="let node of rootNodes"></ca-dropdown-tree-node>
    </div>
  </div>
</ng-template>
