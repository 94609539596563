<ng-container [formGroup]="editorForm">
  <ng-container *ngIf="actualConfig.type == selectionType.Checkbox">
    <ng-container *ngFor="let genericLookup of genericLookups$ | async; let i = index">
      <div *ngIf="isVisible(genericLookup)" class="form-check">
        <input
          [id]="actualConfig.key + '-' + genericLookup.id + '-' + randomKey"
          class="form-check-input me-0"
          type="checkbox"
          [value]="genericLookup.id"
          (change)="onCheckChanged($event)"
          [checked]="this.value.indexOf(genericLookup.id) > -1" />
        <label
          class="form-check-label"
          [for]="actualConfig.key + '-' + genericLookup.id + '-' + randomKey">
          {{ 'GenericLookup::' + genericLookup.code | abpLocalization }}
        </label>
      </div>
    </ng-container>
    <div
      class="mt-2 text-danger"
      style="font-style: italic; font-size: 0.9em"
      *ngIf="errorMessage.length > 0">
      <ng-container *ngFor="let message of errorMessage"> * {{ message }} </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="actualConfig.type == selectionType.Radio">
    <div class="form-group" style="margin-bottom: 5px">
      <div class="display-inline">
        <ng-container *ngFor="let genericLookup of genericLookups$ | async; let i = index">
          <div *ngIf="isVisible(genericLookup)" class="form-check" style="margin-right: 10px">
            <input
              [id]="actualConfig.key + '-' + genericLookup.id + '-' + randomKey"
              class="form-check-input me-0"
              type="radio"
              [value]="genericLookup.id"
              (change)="onCheckChanged($event)"
              [checked]="this.value.indexOf(genericLookup.id) > -1"
              [name]="'g-' + genericLookup.genericLookupTypeId" />
            <label
              class="form-check-label"
              [for]="actualConfig.key + '-' + genericLookup.id + '-' + randomKey">
              {{ 'GenericLookup::' + genericLookup.code | abpLocalization }}
            </label>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="mt-2 text-danger"
      style="font-style: italic; font-size: 0.9em"
      *ngIf="errorMessage.length > 0">
      <ng-container *ngFor="let message of errorMessage"> * {{ message }} </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="actualConfig.type == selectionType.Combobox">
    <div
      class="form-group"
      [ngClass]="
        actualConfig.conversationInline === true || actualConfig.evaluationInline === true
          ? 'display-inline'
          : ''
      ">
      <label
        [ngClass]="labelClass"
        [for]="actualConfig.key"
        *ngIf="actualConfig.label != null && actualConfig.label != ' '">
        {{ actualConfig.label | abpLocalization }}
      </label>
      <ca-select
        class="w-100 mw-100"
        [value]="value"
        (change)="actualConfig.multiple === true ? onCheckChanged($event) : onSelectChange($event)"
        [actualConfig]="true"
        [multiple]="actualConfig.multiple"
        formControlName="editor"
        ><ca-option
          *ngIf="
            actualConfig.showAllOption == null ||
            (actualConfig.showAllOption !== null && actualConfig.showAllOption)
          "
          [value]="-1">
          {{ 'GenericLookup::Conversation.Listening.Status.All' | abpLocalization }}
        </ca-option>
        <ca-option
          *ngFor="let genericLookup of genericLookups$ | async; let i = index"
          [value]="genericLookup.id">
          {{ 'GenericLookup::' + genericLookup.code | abpLocalization }}
        </ca-option></ca-select
      >
    </div>
  </ng-container>
</ng-container>
