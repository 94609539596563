<div class="main-container">
  <div class="row pb-2">
    <div class="col-sm-3">
      <ca-user-photo
        [useCurrentUser]="false"
        [userId]="conversation?.userId"
        [alt]="'user'"
        [classes]="['user-avatar']"
        [styles]="userPhotoComponentStyles">
      </ca-user-photo>
    </div>
    <div class="col-sm-7 summary-label-container">
      <div class="summary-label user-full-name-label">{{ conversation?.meeting?.source }}</div>
      <div class="summary-label user-full-name-label">{{ conversation?.userName }}</div>
      <div class="summary-label department-label">{{ conversation?.departmentName }}</div>
      <div class="summary-label conversation-id-label">{{ conversation?.id }}</div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="d-flex flex-row">
    <span class="col-sm-3"></span>
    <ca-conversation-icon-container
      class="container-class col-sm-9"
      [conversation]="conversation"
      [inDetail]="true"></ca-conversation-icon-container>
  </div>
  <mat-divider></mat-divider>
  <dl class="row" style="padding: 8px; text-align: left">
    <dt class="col-sm-3">{{ '::Time' | abpLocalization }}</dt>
    <dd class="col-sm-9">{{ conversationTime }}</dd>

    <dt class="col-sm-3">{{ '::Duration' | abpLocalization }}</dt>
    <dd class="col-sm-9">{{ conversation?.duration | caTimePipe : { showShortcuts: true } }}</dd>

    <dt class="col-sm-3">{{ '::Source' | abpLocalization }}</dt>
    <dd class="col-sm-9">{{ conversation?.conversationSourceName }}</dd>

    <dt class="col-sm-3 text-truncate">{{ 'Conversation::ExternalId' | abpLocalization }}</dt>
    <dd class="col-sm-9 text-truncate">
      {{ conversation?.externalId }}
      <span [hidden]="!conversation?.externalId">
        <i
          class="fas fa-copy text-primary clipboard-link"
          [cdkCopyToClipboard]="conversation?.externalId"
          (click)="copyToClipboard()"
          title="{{ 'Conversation::CopyExternalIdtoClipboard' | abpLocalization }}"></i>
      </span>
    </dd>
  </dl>

  <mat-divider></mat-divider>
</div>
