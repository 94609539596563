import { downloadBlob, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
import { ExcelService } from 'src/core/services/excel/excel.service';

@Component({
  selector: 'ca-category-list-export-ready-notification',
  templateUrl: './category-list-export-ready-notification.component.html',
  styleUrls: ['./category-list-export-ready-notification.component.scss']
})
@NotificationType(
  'CategoryListExportReadyNotification',
  CategoryListExportReadyNotificationComponent
)
export class CategoryListExportReadyNotificationComponent implements OnInit {
  data: NotificationDto;

  constructor(
    private excelService: ExcelService,
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'Query::ClickHereToDownloadCategoryReport'
    );
    var toastrId = toastr.success(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(
        injector,
        notificationService,
        notificationId,
        parsedPayload.fileId,
        localizationService
      );
    });
  }

  static toasterClickedHandler(
    injector: Injector,
    notificationService: NotificationService,
    notificationId: string,
    fileId: string,
    localizationService: LocalizationService
  ) {
    const fileName = localizationService.instant(
      'Query::ExcelExport.FileName'
    );
    injector.get(ExcelService).downloadExcelReport(fileId, fileName);
    notificationService.markAsRead(notificationId).subscribe();
  }

  downloadFile() {
    const parsedPayload = JSON.parse(this.data.payload);
    this.downloadFileById(parsedPayload.fileId);
    this.notificationService.getReadStatus(this.data.id).subscribe(result => {
      if (!result) {
        this.notificationService.markAsRead(this.data.id).subscribe();
      }
    });
  }

  downloadFileById(fileId: string) {
    let fileName = this.localizationService.instant(
      'Query::ExcelExport.FileName'
    );
    this.excelService.getExcelReport(fileId, fileName).subscribe(
      result => {
        if (result.size > 0) {
          downloadBlob(result, fileName);
        }
      },
      error => {
        this.toastr.error('Conversation::DownloadFileError');
      }
    );
  }
}