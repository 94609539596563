import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CAToasterHelperService {
  constructor(private toastrService: ToastrService) {}

  getToastById(toasterId: string | number): ActiveToast<any> {
    var toastr = this.toastrService.toasts.find(t => t.toastId === toasterId);
    return toastr;
  }
}
