<span>
  {{ 'ActionLog::ActionType:ConversationAutomaticEvaluationStart' | abpLocalization }}
  {{ 'ActionLog::FormName' | abpLocalization }}:
  <ca-clickable-link
    [innerHtml]="payloadData?.formName"
    [targetLink]="[
      '/quality/form',
      'v2',
      payloadData?.formId,
      'version',
      payloadData?.formVersionId
    ]"
    [withoutDiv]="true"></ca-clickable-link>
</span>
