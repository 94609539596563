import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Call.ReleasingParty'
})
export class CallReleasingParty {
    @GenericLookup({
        code: 'Call.ReleasingParty.Customer'
    })
    static customer: number;

    @GenericLookup({
        code: 'Call.ReleasingParty.Agent'
    })
    static agent: number;

    @GenericLookup({
        code: 'Call.ReleasingParty.Transfer'
    })
    static transfer: number;

    @GenericLookup({
        code: 'Call.ReleasingParty.Unknown'
    })
    static unknown: number;

    @GenericLookup({
        code: 'Call.ReleasingParty.CustomData'
    })
    static customData: number;
}
