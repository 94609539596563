import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { EvaluationFormwithMultipleVersionsDto } from 'src/core/models/quality-management/evaluation-form-with-multiple-versions.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { FormSelectionConfigurationModel } from '../models/form-selection-configuration.model';

@Component({
  selector: 'ca-form-selection-panel',
  templateUrl: './form-selection-panel.component.html',
  styleUrls: ['./form-selection-panel.component.scss'],
})
export class FormSelectionPanelComponent extends FilterPanelEditorComponent implements OnInit {
  actualConfig: FormSelectionConfigurationModel;
  filters: FilterItemDto[] = [];
  sorters: SorterItemDto[] = [];

  _value: EvaluationFormwithMultipleVersionsDto[];
  formSelectorOptions: any;

  @ViewChild(SelectorComponent, { read: SelectorComponent })
  formSelector: SelectorComponent;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as FormSelectionConfigurationModel;
    this.refreshFilters();
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  get showPassives(): boolean {
    return this.actualConfig?.showPassives ? true : false;
  }

  get multipleSelection(): boolean {
    return this.actualConfig.selectionType == 0 ? false : true;
  }

  constructor(
    private localizationService: LocalizationService,
    private operators: Operators,
    protected fb: FormBuilder
  ) {
    super(fb);

    this.sorters.push({
      field: 'Name',
      direction: 'ASC',
    });

    this.filters.push({
      field: 'active',
      operator: this.operators.Equals,
      value: true,
    });

    this.formSelectorOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      emptyText: this.localizationService.instant('QualityManagement::SelectForms'),
      multiple: true,
      url: 'api/app/form',
      currentPage: 1,
      showSelections: false,
      showClearSelections: false,
      showClearSelectionLink: true,
      selectorPosition: 'Top',
    };

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  ngOnInit(): void {}

  reload(): void {
    this.innerLoad();
  }

  reset(): void {
    this.value = null;
  }

  refreshFilters() {
    this.filters = this.filters.filter(x => x.field != 'onlyAutomatic' && x.field != 'onlyManual');
    if (this.actualConfig.onlyAutomatic) {
      this.filters.push({
        field: 'onlyAutomatic',
        operator: -1,
        value: true,
      });
    } else if (this.actualConfig.onlyManual) {
      this.filters.push({
        field: 'onlyManual',
        operator: -1,
        value: true,
      });
    }
  }

  autoSelectFirstItem(value: boolean): void {
    this.formSelector.setAutoSelectFirstItem(value);
  }

  private innerLoad() {
    this.filters = this.filters.filter(
      x =>
        x.field != 'onlyAutomatic' &&
        x.field != 'onlyManual' &&
        x.field != 'assignmentId' &&
        x.field != 'automaticAssignmentId'
    );

    if (this.extraData) {
      if (this.extraData.onlyAutomatic === true) {
        this.filters.push({
          field: 'onlyAutomatic',
          operator: -1,
          value: true,
        });
      } else if (this.extraData.onlyManual === true) {
        this.filters.push({
          field: 'onlyManual',
          operator: -1,
          value: true,
        });
      }
      if (this.extraData.assignmentId) {
        this.filters.push({
          field: 'assignmentId',
          operator: this.operators.Equals,
          value: this.extraData.assignmentId,
        });
      }
      if (this.extraData.automaticAssignmentId) {
        this.filters.push({
          field: 'automaticAssignmentId',
          operator: this.operators.Equals,
          value: this.extraData.automaticAssignmentId,
        });
      }
    }

    this.formSelector.setFilters(this.filters);
    this.formSelector.refresh();
  }
}
