import { Component, OnInit, Input } from '@angular/core';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';

@Component({
    selector: 'ca-conversation-main-form',
    templateUrl: './conversation-main-form.component.html',
    styleUrls: ['./conversation-main-form.component.scss'],
})
export class ConversationMainFormComponent implements OnInit {
    conversation: ConversationDto;
    mainRowHeight = 'calc(100% - 63px)';
    callDetailVisible = true;
    chatDetailVisible = false;

    @Input() set selectedConversationId(value: number) {
        if (value != null && value > 0) this.getConversation(value);
    }

    constructor(private conversationService: ConversationService) {}

    getConversation(id: number) {
        this.conversationService.getById(id).subscribe((response) => {
            this.conversation = response;

            if (this.conversation.typeId === ConversationType.call) {
                this.callDetailVisible = true;
                this.chatDetailVisible = false;
            } else {
                this.callDetailVisible = false;
                this.chatDetailVisible = true;
            }
        });
    }

    ngOnInit() {}
}
