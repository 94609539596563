<ng-template #notSuitableForEvaluationTooltip>
  <div>
    <div>
      <b>{{
        'GenericLookup::Conversation.EvaluationMark.NotSuitableForEvaluation' | abpLocalization
      }}</b>
    </div>
    <div>{{ conversation?.notSuitableForEvaluationReasons | reasonTooltipList }}</div>
  </div>
</ng-template>

<div class="tag-item-container ps-2">
  <div
    *ngIf="conversation && !conversation?.isSuitableForEvaluation"
    class="tag-item"
    [ngbTooltip]="notSuitableForEvaluationTooltip">
    <div class="tag-box tag-item-truncate c-default">
      {{ 'GenericLookup::Conversation.EvaluationMark.NotSuitableForEvaluation' | abpLocalization }}
    </div>
    <div class="tag-delete" role="button" (click)="onDeleteEvaluableMarkClick()">
      <i class="fa-regular fa-x"></i>
    </div>
  </div>
  <div *ngIf="conversation?.isPriorForEvaluation" class="tag-item">
    <div
      class="tag-box tag-item-truncate c-default"
      [ngbTooltip]="
        'GenericLookup::Conversation.EvaluationMark.PriorForEvaluation' | abpLocalization
      "
      showTooltipIfTruncated>
      {{ 'GenericLookup::Conversation.EvaluationMark.PriorForEvaluation' | abpLocalization }}
    </div>
    <div class="tag-delete" role="button" (click)="onDeleteEvaluableMarkClick()">
      <i class="fa-regular fa-x"></i>
    </div>
  </div>
  <div class="tag-item" *ngFor="let tag of displayedTags()">
    <div class="tag-box tag-item-truncate c-default" [ngbTooltip]="tag.name" showTooltipIfTruncated>
      {{ tag.name }}
    </div>
    <div class="tag-delete" role="button" (click)="onDeleteClick(tag)">
      <i class="fa-regular fa-x"></i>
    </div>
  </div>
  <div
    ngbDropdown
    #markAsDropdown="ngbDropdown"
    *ngIf="conversation?.conversationTags?.length > displayedTagCount"
    class="tag-item"
    [container]="'body'">
    <button ngbDropdownToggle class="tag-box">
      <i class="fa-regular fa-ellipsis"></i>
    </button>
    <div ngbDropdownMenu class="fit-content-width">
      <div class="pt-2 ps-2 pe-2" *ngIf="markAsDropdown.isOpen()">
        <div class="remaining-tag-item pb-2" *ngFor="let tag of remainingTags()">
          <div
            class="tag-box tag-item-truncate c-default"
            [ngbTooltip]="tag.name"
            showTooltipIfTruncated>
            {{ tag.name }}
          </div>
          <div class="tag-delete" role="button" (click)="onDeleteClick(tag)">
            <i class="fa-regular fa-x"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
