import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Call.NonFcrGroup'
})
export class CallNonFcrGroup {
    @GenericLookup({
        code: 'Call.NonFcrGroup.Number'
    })
    static number: number;

    @GenericLookup({
        code: 'Call.NonFcrGroup.CustomerId'
    })
    static customerId: number;
}
