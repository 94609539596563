<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div class="row m-0 align-items-center full-height">
      <div
        class="col-auto"
        ngbTooltip="{{ '::Term' | abpLocalization }}"
        container="body"
        placement="bottom">
        <label style="margin-bottom: 0px !important">{{ payload.term }}</label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ payload.operator | operator }}</label>
      </div>
      <div
        *ngIf="payload.sideId !== conversationSideAny"
        class="col-auto"
        ngbTooltip="{{ '::Channel' | abpLocalization }}"
        container="body"
        placement="bottom">
        <label style="margin-bottom: 0px !important">{{
          'GenericLookup::' + currentConversationSide | abpLocalization
        }}</label>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::StartTimeRange' | abpLocalization }}"
        container="body"
        placement="bottom">
        <label style="margin-bottom: 0px !important">{{ payload.startTime }}</label>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::EndTimeRange' | abpLocalization }}"
        container="body"
        placement="bottom">
        <label style="margin-bottom: 0px !important">{{ payload.endTime }}</label>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::RangeUnit' | abpLocalization }}"
        container="body"
        placement="bottom">
        <label style="margin-bottom: 0px !important">{{
          'Query::' + currentUnitName | abpLocalization
        }}</label>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::MinimumOccurence' | abpLocalization }}"
        container="body"
        placement="bottom"
        [hidden]="occurenceHidden">
        <label style="margin-bottom: 0px !important">{{ payload.occurence }}</label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div
        class="col-auto"
        ngbTooltip="{{ '::Term' | abpLocalization }}"
        container="body"
        placement="bottom">
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="termInternal"
          formControlName="term" />
      </div>
      <div class="col-auto">
        <ca-conversation-side-selector
          formControlName="conversationSide"
          [(ngModel)]="payload.sideId"
          [allSide]="true">
        </ca-conversation-side-selector>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::StartTimeRange' | abpLocalization }}"
        container="body"
        placement="bottom">
        <input
          type="number"
          style="width: 75px"
          class="form-control form-control-sm"
          [(ngModel)]="payload.startTime"
          min="0"
          formControlName="startTime" />
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::EndTimeRange' | abpLocalization }}"
        container="body"
        placement="bottom">
        <input
          type="number"
          style="width: 75px"
          class="form-control form-control-sm"
          [(ngModel)]="payload.endTime"
          min="0"
          formControlName="endTime" />
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::RangeUnit' | abpLocalization }}"
        container="body"
        placement="bottom">
        <select
          style="width: 120px"
          class="form-select form-select-sm"
          [(ngModel)]="payload.rangeUnitId"
          formControlName="rangeUnit">
          <option *ngFor="let unit of rangeUnits$ | async" [value]="unit.id">
            {{ 'Query::' + unit.name | abpLocalization }}
          </option>
        </select>
      </div>
      <div
        class="col-auto"
        ngbTooltip="{{ '::MinimumOccurence' | abpLocalization }}"
        container="body"
        placement="bottom"
        [hidden]="occurenceHidden">
        <input
          style="width: 75px"
          type="number"
          class="form-control form-control-sm"
          [(ngModel)]="payload.occurence"
          [max]="max"
          [min]="min"
          formControlName="occurrence" />
      </div>
      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>
