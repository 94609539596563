import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { CallDirection } from 'src/core/models/generic-lookup-type/call/call-direction.glt';

@Component({
  selector: 'ca-screen-record-icon',
  templateUrl: './screen-record-icon.component.html',
  styleUrls: ['./screen-record-icon.component.scss', '../conversation-icons.scss'],
})
export class ScreenRecordIconComponent implements OnInit {
  @Input()
  hasScreenRecording: boolean | null;

  get iconCls(): string {
    let result = '';

    if (this.hasScreenRecording) {
      result = 'fas fa-desktop';
    }

    return result;
  }

  get tooltip(): string {
    let result = '';

    if (this.hasScreenRecording) {
      result = `${this.localizationService.instant(
        'Conversation::ScreenRecord'
      )}`;
    }

    return result;
  }

  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void { }
}
