import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'ca-clickable-link',
  templateUrl: './clickable-link.component.html',
  styleUrls: ['./clickable-link.component.scss'],
})
export class ClickableLinkComponent implements OnInit {
  @Input()
  innerHtml: string;

  @Input()
  targetLink: any[];

  @Input()
  params: NavigationExtras;

  @Input()
  state: any;

  @Input()
  withoutDiv: boolean = false;

  @Input()
  icon: string = '';

  @Input()
  newTab: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  showTooltipIfTruncated: boolean = false;

  @Input()
  cls: string = '';

  @Output()
  linkClicked = new EventEmitter();

  get target() {
    return this.newTab ? '_blank' : null;
  }

  ngOnInit(): void {}

  onLinkClick(): void {
    this.linkClicked.emit();
  }
}
