import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../../shared/dto-has-api.model';
import { AttachedDataLookupDto } from './attached-data-lookup.dto';

@Api('api/app/attached-data')
export class AttachedDataDto extends DtoHasApi {
  id: number;
  dataKey: string;
  name: string;
  visible: boolean;
  deletable: boolean;
  canPassiveToggle: boolean;
  isMultipleChoice: boolean;
  attachedDataLookups: AttachedDataLookupDto[] = [];
  deletableMessage: string;
}
