<table mat-table [dataSource]="dataSource">
  <ng-container *ngFor="let gridColumn of gridColumns; let i = index">
    <ng-container
      *ngIf="gridColumn.columnName !== 'menu' && gridColumn.columnName !== 'type'"
      matColumnDef="{{ gridColumn.columnName }}"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.minWidth || gridColumn.width"
        [style.maxWidth]="gridColumn.width"
      ></th>
      <td mat-cell *matCellDef="let notification">
        <ng-container *ngIf="notification.type">
          <div
            [ngClass]="notification.markedAsRead ? '' : 'unread-notification-row'"
            [innerHtml]="notification | objectparser: gridColumn"
          ></div>
        </ng-container>
        <ng-container *ngIf="notification.name && i == 1">
          <div style="font-weight: bold; font-size: 10pt; margin-left: -32px; color: dimgray">
            {{ notification.name }}
          </div>
        </ng-container>
      </td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'menu'"
      matColumnDef="{{ gridColumn.columnName }}"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width"
      ></th>
      <td mat-cell *matCellDef="let notification">
        <button
          *ngIf="notification.type"
          (click)="toggleStatus(notification)"
          [ngbTooltip]="
            notification.markedAsRead
              ? localizationService.instant('Notification::MarkAsUnread')
              : localizationService.instant('Notification::MarkAsRead')
          "
          class="btn btn-sm ca-btn ca-btn-neutral-command"
        >
          <i class="far fa-envelope-open" *ngIf="!notification.markedAsRead"></i>
          <i class="fal fa-envelope" *ngIf="notification.markedAsRead"></i>
        </button>
      </td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'type'"
      matColumnDef="{{ gridColumn.columnName }}"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width"
      ></th>
      <td mat-cell *matCellDef="let notification">
        <ng-container *ngIf="notification.type">
          <div #container [ngClass]="notification.markedAsRead ? '' : 'unread-notification-row'">
            <ca-notification-row [notification]="notification"></ca-notification-row>
          </div>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>
  <!-- Info column -->
  <ng-container matColumnDef="info">
    <td
      mat-footer-cell
      *matFooterCellDef
      style="text-align: left"
      [attr.colspan]="displayedColumns.length"
    >
      <span>
        <mat-paginator
          style="display: flex; justify-content: left"
          (page)="onPageChange($event)"
          [pageIndex]="currentPage"
          [length]="totalCount"
          [pageSize]="pageSize"
          class="paginator"
          hidePageSize
          showFirstLastButtons
        ></mat-paginator>
      </span>
    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns; sticky: true"
    style="height: 0px !important; border-bottom: 0px !important"
  ></tr>
  <tr
    mat-row
    [ngClass]="myRowData.name ? 'bg-light' : ''"
    class="notification-row-{{ myRowData.id }}"
    *matRowDef="let myRowData; columns: displayedColumns"
  ></tr>
  <tr mat-footer-row *matFooterRowDef="['info']; sticky: true"></tr>
  <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
    <td class="mat-cell" [colSpan]="displayedColumns.length">
      {{ 'CA::NoUnreadNotification' | abpLocalization }}
    </td>
  </tr>
</table>
