import { Component, OnInit, ViewChild } from '@angular/core';
import { StringValidator } from 'src/core/validators/shared/string.validator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { UserFeedbackService } from 'src/core/services/user-feedback/user-feedback.service';
import { UserFeedbackDto } from 'src/core/models/user-feedback/user-feedback.dto';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ca-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss'],
})
export class UserFeedbackComponent implements OnInit {
  feedbackForm: FormGroup;
  feedbackComment: '';
  feedbackSent = false;
  feedbackFeatureEnabled = false;
  @ViewChild('feedbackPopover') public feedbackPopover: NgbPopover;

  constructor(
    private userFeedbackService: UserFeedbackService,
    private featureService: FeatureService,
    private fb: FormBuilder,
    readonly router: Router
  ) {
    this.feedbackFeatureEnabled = this.featureService.isEnabled(FeatureConstants.UserFeedback);
  }

  ngOnInit(): void {
    this.feedbackForm = this.fb.group({
      feedbackComment: [
        null,
        {
          validators: [Validators.required, Validators.minLength(3), Validators.maxLength(1000)],
          updateOn: 'submit',
        },
      ],
    });
  }

  sendFeedback() {
    if (this.feedbackForm.invalid) {
      return;
    }

    const feedback: UserFeedbackDto = {
      content: this.feedbackForm.get('feedbackComment').value,
      pageUrl: this.router.url,
      environment: window.location.origin,
    };

    this.userFeedbackService.create(feedback).subscribe(result => {
      this.feedbackForm.reset();
      this.feedbackSent = true;

      setTimeout(() => {
        this.feedbackPopover.close();
        this.feedbackSent = false;
      }, 1000);
    });
  }
}
