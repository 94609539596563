import { Injectable } from '@angular/core';
import { GenericLookupDto } from '../models/generic-lookup/generic-lookup.dto';
@Injectable({
    providedIn: 'root',
})
export class OrderHelper {
    constructor() { }
    getOrderedGenericLookupList(items: GenericLookupDto[], desiredOrder: string[]) {
        const result = [];

        if (items?.length > 0 && desiredOrder?.length > 0) {
            for (let i = 0; i < desiredOrder.length; i++) {
                const order = items.filter(x => x.code === desiredOrder[i]);
                if (order?.length > 0) {
                    result.push(order[0]);
                }
            }
        }

        return result;
    }
}
