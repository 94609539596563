import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Identity.User.FilterModeOption',
})
export class UserFilterModeOption {
  @GenericLookup({
    code: 'Identity.User.FilterModeOption.SelectedUsers',
  })
  static SelectedUsers: number;

  @GenericLookup({
    code: 'Identity.User.FilterModeOption.AllUsersExceptSelectedUser',
  })
  static AllUsersExceptSelectedUser: number;
}
