import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConversationImportStatus } from 'src/core/models/conversation/conversation-import-status.dto';
import { environment } from 'src/environments/environment';
import { ChatTranscriptDto } from 'src/core/models/chat/chat-transcript.dto';
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private http: HttpClient) {}

  private apiBase = environment.apis.default.url;

  import(files: FormData): Observable<ConversationImportStatus> {
    return this.http.post(
      `${this.apiBase}/api/app/chat/import`,
      files
    ) as Observable<ConversationImportStatus>;
  }

  csvImport(files: FormData): Observable<boolean> {
    return this.http.post(`${this.apiBase}/api/app/chat/import-csv`, files) as Observable<boolean>;
  }

  downloadSampleChatCsv(): Observable<File> {
    return this.http.get<File>(`${this.apiBase}/api/app/chat/sample-chat-csv`, {
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
  }

  downloadSampleChatMetadataCsv(): Observable<File> {
    return this.http.get<File>(`${this.apiBase}/api/app/chat/sample-chat-metadata-csv`, {
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
  }

  updateTranscript(id: number, transcripts: ChatTranscriptDto[]) {
    return this.http.put(`${this.apiBase}/api/app/chat/${id}/transcript`, transcripts);
  }
}
