<div>
  <h2>{{ 'Settings::CallRecorder' | abpLocalization }}</h2>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group" formGroupName="notifyUrl">
    <label for="notifyUrl">{{ 'Settings::CallRecorderNotifyUrl' | abpLocalization }} :</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="notifyUrl"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
