import { TranscriptWord } from 'src/core/models/conversation/transcript/transcript-word.model';

export class CategoryMarkerDto {
    color: string;
    text: string;
    startMillisecond: number;
    endMillisecond: number;
    channel: number;
    categoryName: string;
    isQuickSearchMarker?: boolean;
    isFilterSearchMarker?: boolean;
    isAIGeneratedMarker?: boolean;
    isCommentMarker?: boolean;
    words: TranscriptWord[];
    timeStamp?: Date;
}