import { LocalizationService } from '@abp/ng.core';
import { AfterViewInit, Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { DepartmentFilterModeOption } from 'src/core/models/generic-lookup-type/department/department-filter-mode-option.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { DepartmentService } from 'src/core/services/administration/department-service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { DropdownTreeComponent } from '../dropdown-tree/components/dropdown-tree/dropdown-tree.component';
import { DropdownTreeNodeModel } from '../dropdown-tree/dropdown-tree.module';
import { DepartmentSelectorInputModel } from './models/department-selector-input.model';
import { Store } from '@ngxs/store';
import { Operators } from 'src/core/models/request/operator.enum';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';

@Component({
  selector: 'ca-department-selector',
  templateUrl: './department-selector.component.html',
  styleUrls: ['./department-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DepartmentSelectorComponent),
    },
  ],
})
export class DepartmentSelectorComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  // tslint:disable-next-line: variable-name
  private _disabledItemMessage: string;
  private _disabledItemIdList: number[] = [];
  @ViewChild('departmentDropdownTree')
  departmentDropdownTree: DropdownTreeComponent;

  @Input()
  hideFilterMode: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  filterByStatus: boolean = false;

  @Input()
  set disabledItemIdList(items: number[]) {
    this._disabledItemIdList = items;
    setTimeout(() => {
      this.refreshDisabledData();
    }, 500);
  }

  get disabledItemIdList(): number[] {
    return this._disabledItemIdList;
  }

  @Input()
  set disabledItemMessage(message: string) {
    this._disabledItemMessage = message;
  }

  get disabledItemMessage() {
    return this._disabledItemMessage;
  }

  queryOperator: number;
  autoCompleteSelectorOptions: any;
  filterModes: GenericLookupDto[];
  departmentTreeOptions: any;
  departmentTreeData: DropdownTreeNodeModel[] = [];
  filters: FilterItemDto[] = [];

  statusItems = [
    { id: 1, label: this.localizationService.instant('::Active'), class: 'active-filter-icon' },
    { id: 2, label: this.localizationService.instant('::Passive'), class: 'passive-filter-icon' },
    { id: 3, label: this.localizationService.instant('::All'), class: 'all-filter-icon' },
  ];
  currentStatus = this.localizationService.instant('::Active');
  disableTooltip = false;

  private _value: DepartmentSelectorInputModel;

  set value(val: DepartmentSelectorInputModel) {
    this._value = val;
  }

  get value(): DepartmentSelectorInputModel {
    return this._value;
  }

  constructor(
    private localizationService: LocalizationService,
    private departmentService: DepartmentService,
    private store: Store,
    private operators: Operators
  ) {
    this.departmentTreeOptions = {
      hideToolbar: true,
      showSelections: true,
      searchable: true,
      multiple: true,
      emptyText: this.localizationService.instant('::SelectDepartment'),
      selectionText: this.localizationService.instant('::Selections'),
    };
  }

  ngAfterViewInit(): void {
    if (!this.filterByStatus) {
      this.filters.push({
        field: 'includePassives',
        operator: this.operators.Equals,
        value: false,
      });
    }
    setTimeout(() => {
      this.loadDepartmentData();
    }, 500);
  }

  ngOnInit(): void {
    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(DepartmentFilterModeOption)))
      .subscribe(result => {
        this.filterModes = result;
      });
  }

  loadDepartmentData() {
    this.departmentService
      .getTreeNodesForSelector<DropdownTreeNodeModel>({
        filters: this.filters,
        sorters: [],
        maxResultCount: 99999,
        skipCount: 0,
      })
      .subscribe(data => {
        this.departmentTreeData = [];
        data.forEach(element => {
          this.departmentTreeData.push({
            expanded: element.expandable,
            leaf: !element.expandable,
            payload: element,
            disabled: this.disabledItemIdList.indexOf(element.id) === -1 ? false : true,
            selected:
              this.value.filterModeId ===
                DepartmentFilterModeOption.AllDepartmentsExceptSelectedDeparment
                ? this.disabledItemIdList.indexOf(element.id) === -1
                  ? false
                  : true
                : false,
          });
        });
        this.departmentDropdownTree.data = this.departmentService.setRootNode(
          this.departmentTreeData
        );
        if (this.filterByStatus) {
          this.departmentDropdownTree.changeStatusFilter(this.statusItems[0]);
          this.applyChanges();
        }
      });
  }

  writeValue(obj: DepartmentSelectorInputModel): void {
    obj = obj
      ? obj
      : {
        selectedDepartments: [],
        filterModeId: DepartmentFilterModeOption.SelectedDepartments,
      };
    this.value = obj;
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }
  onChange: Function = (_: DepartmentSelectorInputModel) => { };
  onTouched: Function = (_: DepartmentSelectorInputModel) => { };

  onFilterModeChange() {
    this.departmentTreeData.forEach(element => {
      const departmentId = element.payload['id'];
      if (
        this.value.filterModeId ==
        DepartmentFilterModeOption.AllDepartmentsExceptSelectedDeparment &&
        this.disabledItemIdList.indexOf(departmentId) > -1
      ) {
        element.selected = true;
        this.value.selectedDepartments.push(element.payload);
      }
      if (
        this.value.filterModeId == DepartmentFilterModeOption.SelectedDepartments &&
        this.disabledItemIdList.indexOf(departmentId) > -1
      ) {
        const index = this.value.selectedDepartments.indexOf(element.payload);
        element.selected = false;
        if (index > -1) {
          this.value.selectedDepartments.splice(index);
        }
      }
    });
    this.onSelectionChange();
  }

  onSelectionChange() {
    this.applyChanges();
  }

  changeStatusFilter(eventArgs) {
    this.currentStatus = eventArgs.label;
    this.departmentDropdownTree.changeStatusFilter(eventArgs);
    this.applyChanges();
  }

  applyChanges() {
    this.onChange(ObjectHelper.deepCopy(this.value));
  }

  refreshDisabledData() {
    this.departmentTreeData.forEach(data => {
      data.disabled = this.disabledItemIdList.indexOf(data.payload.id) === -1 ? false : true;
    })
  }
}
