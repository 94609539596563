import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { DataChange } from 'src/core/actions/enum/enum.actions';

@State<any>({
  name: 'EnumState',
  defaults: null,
})
@Injectable()
export class EnumState {
  @Action(DataChange)
  changeData(ctx: StateContext<any>, action: DataChange) {
    let currentState = ctx.getState();
    currentState = action.payload;

    ctx.patchState(currentState);
  }
}
