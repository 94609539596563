<ng-template #itemTemplate let-item="item">
  <div class="topic-selection-panel-topic-name">
    <div class="line-ellipsis">{{ item.name }}</div>
  </div>
</ng-template>

<ng-template #selectionTemplate let-item="item">
  <div class="filter-name">{{ item.Name }}</div>
</ng-template>

<ng-container [formGroup]="editorForm">
  <ca-selector
    #topicSelector
    [url]="topicSelectorOptions.url"
    [pageSize]="topicSelectorOptions.pageSize"
    [itemTemplate]="itemTemplate"
    [selectionTemplate]="selectionTemplate"
    [queryOperator]="topicSelectorOptions.queryOperator"
    [queryField]="topicSelectorOptions.queryField"
    [multiple]="topicSelectorOptions.multiple"
    [emptyText]="topicSelectorOptions.emptyText"
    [showSelections]="topicSelectorOptions.showSelections"
    [showClearSelections]="topicSelectorOptions.showClearSelections"
    [showClearSelectionLink]="topicSelectorOptions.showClearSelectionLink"
    [selectorPosition]="topicSelectorOptions.selectorPosition"
    [styles]="{ width: '100%', 'border-width': '0px' }"
    formControlName="editor"
  ></ca-selector>
</ng-container>
