import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationService } from 'src/core/services/notification/notification.service';

@Component({
  selector: 'ca-topic-training-ready-notification',
  templateUrl: './topic-training-ready-notification.component.html',
  styleUrls: ['./topic-training-ready-notification.component.scss'],
})
@NotificationType(
  'TopicTrainingReadyNotification',
  TopicTrainingReadyNotificationComponent
)
export class TopicTrainingReadyNotificationComponent implements OnInit {
  data: NotificationDto;

  constructor() {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
  ) {
    let message = localizationService.instant(
      'AITopic::TopicTrainingReadyMessage'
    );
    toastr.success(message);
  }
}
