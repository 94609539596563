import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { ConfigStateService } from '@abp/ng.core';
import { FileUploadService } from 'src/core/services/fileupload/fileupload.service';
import { PROFILE_PICTURE } from '@volo/abp.commercial.ng.ui/config';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ca-user-photo',
  templateUrl: './user-photo.component.html',
  styleUrls: ['./user-photo.component.scss'],
})
export class UserPhotoComponent implements OnInit {
  @Input()
  useCurrentUser = false;

  @Input()
  isThumbnail = true;

  @Input()
  classes: string[] = [];

  @Input()
  disableLoader: boolean;

  @Input()
  alt = '';

  @Input()
  styles: any = {};

  @Input()
  set userId(value: string) {
    const forceRefresh = this._userId !== value;

    this._userId = value;
    if (this.isDefault || forceRefresh) {
      this.refresh();
    }
  }

  @Input()
  set nameAndSurname(value: string[]) {
    if (value.length > 0 && value[0] && value[0].length > 0) {
      this._initials = '';
      this._initials += value[0][0].toUpperCase();

      if (value.length > 1 && value[1] && value[1].length > 0) {
        this._initials += value[1][0].toUpperCase();
      }
    }
  }

  get initials(): string {
    return this._initials;
  }

  private _userId: string;
  private _initials: string = '-';

  loading = true;
  defaultPhotoUrl = '/assets/images/default_user.png';
  userPhotoUrl: string;
  imgClass = 'default-size';

  get isDefault(): boolean {
    return this.userPhotoUrl === this.defaultPhotoUrl;
  }

  setDefault(): void {
    this.userPhotoUrl = null;
  }

  refresh(): void {
    this.setImageUrl();
  }

  constructor(
    private config: ConfigStateService,
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    private fileUploadService: FileUploadService
  ) {
    this.setDefault();
  }

  ngOnInit(): void {
    if (this.classes.length > 0) {
      this.imgClass = this.classes.join(' ');
    }

    if (this.useCurrentUser) {
      this.config.getOne$('currentUser').subscribe(currentUser => {
        const profilePicture$ = this.injector.get(PROFILE_PICTURE);
        this.defaultPhotoUrl = profilePicture$.value.source;
        this.loading = false;
      });
    } else {
      this.setImageUrl();
    }
  }

  onImageLoad() {
    this.loading = false;
  }

  private setImageUrl() {
    if (this._userId && this._userId.length > 0) {
      this.loading = true;
      var download = this.isThumbnail
        ? this.fileUploadService.downloadProfileThumbnail(this._userId)
        : this.fileUploadService.downloadProfile(this._userId);
      download.pipe(take(1)).subscribe({
        next: res => {
          if (res.size > 0) {
            var reader = new FileReader();
            var me = this;
            reader.readAsDataURL(res);
            reader.onloadend = function () {
              me.userPhotoUrl = reader.result as string;
            };

            this.loading = false;
          } else {
            this.setDefault();
            this.loading = false;
          }
          this.cdr.detectChanges();
        },
        error: () => {
          this.loading = false;
          this.cdr.detectChanges();
        },
      });
    }
  }
}
