<div class="row entry-row submenu-row" style="margin-left: 0px; margin-right: 0px">
  <div class="col p-0">
    <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
      <div class="ca-toolbar-west-section">
        <div class="toolbar-item first">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <mat-icon>cloud_download</mat-icon>
              <span>{{ 'Conversation::DownloadRecord' | abpLocalization }}</span>
            </button>
            <button mat-menu-item>
              <mat-icon>priority_high</mat-icon>
              <span>{{ 'Conversation::SetPriorForEvaluation' | abpLocalization }}</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="flags">
              <mat-icon>flag</mat-icon>
              <span>{{ '::Flags' | abpLocalization }}</span>
            </button>
          </mat-menu>
          <mat-menu #flags="matMenu">
            <button mat-menu-item>
              <mat-checkbox></mat-checkbox
              ><span style="margin-left: 8px">{{
                'Conversation::AcousticDescription_AAR' | abpLocalization
              }}</span>
            </button>
            <button mat-menu-item>
              <mat-checkbox></mat-checkbox
              ><span style="margin-left: 8px">{{
                'Conversation::CustomerSilenceDuration' | abpLocalization
              }}</span>
            </button>
          </mat-menu>
        </div>
      </div>
      {{ '::Chat' | abpLocalization }}
      <div class="ca-toolbar-east-section">
        <div class="toolbar-item last">
          <button
            (click)="closePanel()"
            matTooltip="{{ '::ClosePanel' | abpLocalization }}"
            mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row m-0" style="padding-bottom: 50px; height: 100%">
  <div class="col-md-4 p-0">
    <div class="list-group" id="list-tab" role="tablist">
      <a
        class="list-group-item list-group-item-action active"
        style="text-align: center"
        id="list-home-list"
        data-toggle="list"
        href="#list-home"
        role="tab"
        aria-controls="home"
        data-target-tab-id="home"
        (click)="showTab($event)">
        <ca-user-photo
          [useCurrentUser]="false"
          [userId]="conversation?.userId"
          [alt]="'user'"
          [classes]="['user-avatar']"
          [styles]="userPhotoComponentStyles">
        </ca-user-photo>
        <div style="margin-top: 8px; font-weight: bold">
          <span>
            {{ conversation?.userName }}
          </span>
        </div>
        <div style="margin-top: 8px">
          <span>
            {{ conversation?.departmentName }}
          </span>
        </div>
        <div style="margin-top: 8px">
          <span>
            {{ conversation?.id }}
          </span>
        </div></a
      >
      <a
        class="list-group-item list-group-item-action"
        id="list-settings-list"
        data-toggle="list"
        href="#list-settings"
        role="tab"
        aria-controls="settings"
        data-target-tab-id="transcript"
        (click)="showTab($event)"
        ><i class="fas fa-file-alt" style="font-size: 1.5em"></i
        ><span style="margin-left: 8px">{{ 'Conversation::Transcript' | abpLocalization }}</span>
        <i
          *ngIf="!hasTranscript"
          class="fas fa-exclamation-circle"
          style="font-size: 1.5em; color: orange; margin-left: 8px"></i>
      </a>
      <a
        class="list-group-item list-group-item-action"
        id="list-messages-list"
        data-toggle="list"
        href="#list-messages"
        role="tab"
        aria-controls="messages"
        data-target-tab-id="attached-data"
        (click)="showTab($event)"
        ><i class="fas fa-clipboard-list" style="font-size: 1.5em"></i
        ><span style="margin-left: 8px">{{ '::AttachedData' | abpLocalization }}</span>
      </a>
      <a
        class="list-group-item list-group-item-action"
        id="list-settings-list"
        data-toggle="list"
        href="#list-settings"
        role="tab"
        aria-controls="settings"
        data-target-tab-id="comments"
        (click)="showTab($event)"
        ><i class="fas fa-comments" style="font-size: 1.5em"></i
        ><span style="margin-left: 8px">{{ '::Comments' | abpLocalization }}</span></a
      >
      <a
        class="list-group-item list-group-item-action"
        id="list-settings-list"
        data-toggle="list"
        href="#list-settings"
        role="tab"
        aria-controls="settings"
        data-target-tab-id="words"
        hidden
        (click)="showTab($event)"
        ><i class="fas fa-sort-alpha-down" style="font-size: 1.5em"></i
        ><span style="margin-left: 8px">{{ '::Words' | abpLocalization }}</span></a
      >
      <a
        class="list-group-item list-group-item-action"
        id="list-categories-list"
        data-toggle="list"
        href="#list-categories"
        role="tab"
        aria-controls="categories"
        data-target-tab-id="categories"
        (click)="showTab($event)"
        ><i class="fas fa-tag" style="font-size: 1.5em"></i
        ><span style="margin-left: 8px">{{ 'Conversation::Categories' | abpLocalization }} </span>
        <i
          *ngIf="categoryCount == 0"
          class="fas fa-exclamation-circle"
          style="font-size: 1.5em; color: orange; margin-left: 8px"></i>
        <span style="font-size: small" *ngIf="categoryCount > 0" class="badge badge-secondary">{{
          categoryCount
        }}</span>
      </a>
    </div>
  </div>
  <div class="col-md-8 p-0" style="height: 100%">
    <div
      class="tab-content p-0"
      id="nav-tabContent"
      style="height: 100%; overflow-y: auto; overflow-x: hidden">
      <div
        class="tab-pane fade show active"
        id="list-home"
        role="tabpanel"
        aria-labelledby="list-home-list"
        data-tab-id="home"
        style="padding: 10px">
        <form>
          <div class="row m-0">
            <div class="col">
              <div class="form-group">
                <label for="edtConversationType">{{ '::Type' | abpLocalization }}</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="edtConversationType"
                  value="{{ 'GenericLookup::' + conversation?.typeName | abpLocalization }}"
                  disabled />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="edtConversationTime">{{
                  'Conversation::ConversationTime' | abpLocalization
                }}</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="edtConversationTime"
                  value="{{ conversation?.startTime }}"
                  disabled />
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col">
              <div class="form-group">
                <label for="edtChannel">{{ '::Channel' | abpLocalization }}</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="edtChannel"
                  value="{{ 'GenericLookup::' + conversation?.channelName | abpLocalization }}"
                  disabled />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="edtDuration">{{ '::Duration' | abpLocalization }}</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="edtDuration"
                  value="{{ conversation?.duration | caTimePipe }}"
                  disabled />
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col">
              <div class="form-group">
                <label for="edtSource">{{ '::Source' | abpLocalization }}</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="edtSource"
                  value="{{ conversation?.conversationSourceName }}"
                  disabled />
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div class="col">
              <div class="form-group">
                <label for="edtExtId">{{ 'Conversation::ExternalId' | abpLocalization }}</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="edtExtId"
                  value="{{ conversation?.externalId }}"
                  disabled />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        class="tab-pane fade"
        id="list-messages"
        role="tabpanel"
        aria-labelledby="list-messages-list"
        data-tab-id="attached-data"></div>
      <div
        class="tab-pane fade"
        id="list-settings"
        role="tabpanel"
        aria-labelledby="list-settings-list"
        data-tab-id="words">
        ...
      </div>
      <div
        class="tab-pane fade"
        id="list-settings"
        role="tabpanel"
        aria-labelledby="list-settings-list"
        style="padding: 10px"
        data-tab-id="transcript">
        <div class="row m-0" style="padding: 10px">
          <div class="col" #transcriptContainer></div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="list-settings"
        role="tabpanel"
        aria-labelledby="list-settings-list"
        data-tab-id="comments">
        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          ">
          <div style="flex: 1; overflow-y: auto">
            <div class="row m-0">
              <div class="col">
                <div *ngIf="comments.length === 0" style="font-style: italic">
                  {{ 'Conversation::NoCommentsYet' | abpLocalization }}
                </div>
              </div>
            </div>
            <div
              class="row m-0"
              style="margin-top: 16px !important"
              *ngFor="let comment of comments">
              <div class="col">
                <div style="width: 100%; text-align: right">
                  <small style="font-weight: bold">
                    {{ comment.userName }} @
                    {{ comment.commentDate | date : 'dd.MM.yyyy HH:mm:ss' }}
                  </small>
                </div>
                <mat-divider></mat-divider>
                <div class="row">
                  <div class="col-6">
                    <div style="margin-top: 8px">
                      {{ comment.comment }}
                    </div>
                  </div>
                  <div class="col-6" *ngIf="comment.userId === userId">
                    <button
                      class="btn btn-sm"
                      style="float: right"
                      (click)="confirmDeletion(comment, $event)">
                      <ca-delete-icon></ca-delete-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="height: 75px">
            <div class="row m-0">
              <div class="col-md-9" style="padding-top: 10px">
                <textarea #edtComment style="width: 100%; height: 100%" value=""></textarea>
              </div>
              <div class="col-md-3" style="padding-top: 10px">
                <button
                  (click)="addComment($event, edtComment)"
                  [disabled]="edtComment.textLength == 0"
                  class="btn btn-sm btn-primary">
                  {{ '::Comment' | abpLocalization }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0" style="height: 100%; display: none">
          <div class="col p-0">
            <div
              style="
                height: calc(100% - 75px);
                max-height: calc(100% - 75px);
                padding-top: 10px;
                padding-bottom: 10px;
              ">
              <div class="row m-0">
                <div class="col">
                  <div *ngIf="comments.length === 0" style="font-style: italic">
                    {{ 'Conversation::NoCommentsYet' | abpLocalization }}
                  </div>
                </div>
              </div>
              <div
                class="row m-0"
                style="margin-bottom: 16px !important"
                *ngFor="let comment of comments">
                <div class="col">
                  <div style="width: 100%; text-align: right">
                    <small style="font-weight: bold">
                      {{ comment.userName }} @
                      {{ comment.commentDate | date : 'dd.MM.yyyy HH:mm:ss' }}
                    </small>
                  </div>
                  <mat-divider></mat-divider>
                  <div style="margin-top: 8px">
                    {{ comment.comment }}
                  </div>
                </div>
              </div>
            </div>
            <div style="height: 75px">
              <div class="row m-0">
                <div class="col-md-9" style="padding: 5px">
                  <textarea
                    [(ngModel)]="commentText"
                    style="width: 100%; height: 100%"
                    value=""></textarea>
                </div>
                <div class="col-md-3" style="padding: 5px">
                  <button (click)="addComment($event, edtComment)" class="btn btn-sm btn-primary">
                    {{ '::Comment' | abpLocalization }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tab-pane fade"
        id="list-categories"
        role="tabpanel"
        aria-labelledby="list-categories-list"
        data-tab-id="categories">
        <div class="row m-0 p-2">
          <ca-conversation-category-panel
            (categoryCountChange)="setCategoryCount($event)"
            [conversationId]="conversation"></ca-conversation-category-panel>
        </div>
      </div>
    </div>
  </div>
</div>
