import { Component, OnInit, Input } from '@angular/core';
import { PermissionService } from 'src/core/services/administration/permission.service';
import { PermissionGroupDto } from 'src/core/models/administration/permission/permission-group.dto';
import {
  PermissionDto,
  PermissionSaveInput,
} from 'src/core/models/administration/permission/permission.dto';
import { MatSelectionListChange, MatSelectionList } from '@angular/material/list';
import { Observable, of } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'ca-user-permission-selector',
  templateUrl: './user-permission-selector.component.html',
  styleUrls: ['./user-permission-selector.component.scss'],
})
export class UserPermissionSelectorComponent implements OnInit {
  @Input()
  set userId(value: string) {
    this._userId = value;

    if (this._userId !== '') {
      this.load();
    }
  }

  get userId() {
    return this._userId;
  }
  @BlockUI('permissions-block') blockUI: NgBlockUI;
  permissionGroups: PermissionGroupDto[] = [];
  changes: PermissionSaveInput[] = [];

  private _userId: string;

  load() {
    this.changes = [];
    this.blockUI.start(this.localizationService.instant('AbpIdentity::LoadingWithThreeDot'));
    this.permissionService.get(this._userId, 'U').subscribe(response => {
      this.permissionGroups = response;
      this.blockUI.stop();
    });
  }

  shouldDisable(permission: PermissionDto): boolean {
    let disable = false;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < permission.grantedProviders.length; i++) {
      if (permission.grantedProviders[i].providerName === 'R') {
        disable = true;
        break;
      }
    }

    return disable;
  }

  onSelectionChange(eventArgs: MatSelectionListChange) {
    let found = false;
    const name = eventArgs.options[0].value;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.changes.length; i++) {
      const change = this.changes[i];

      if (change.name === name) {
        change.isGranted = eventArgs.options[0].selected;
        found = true;
        break;
      }
    }

    if (!found) {
      this.changes.push({
        name,
        isGranted: eventArgs.options[0].selected,
      });
    }
  }

  save(userId: string): Observable<any> {
    if (userId != '' && this.changes.length > 0) {
      return this.permissionService.save(userId, 'U', this.changes);
    } else {
      return of(null);
    }
  }

  getSelectionCount(selectionList: MatSelectionList): number {
    let result = 0;

    if (selectionList.selectedOptions.selected) {
      result = selectionList.selectedOptions.selected.length;
    }

    return result;
  }

  getSelectionOptionCount(selectionList: MatSelectionList): number {
    let result = 0;

    if (selectionList.options) {
      result = selectionList.options.length;
    }

    return result;
  }

  constructor(
    private permissionService: PermissionService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {}
}
