import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TagDto } from 'src/core/models/conversation/tag/tag.dto';

@Injectable({
  providedIn: 'root',
})
export class SentimentService {
  constructor() {}

  getSentimentScoreColor(score: number): string {
    switch (true) {
      case score === 1:
        return '#00dcff';
      case score >= 0.8:
        return '#05f9eb';
      case score >= 0.6:
        return '#05f9eb';
      case score >= 0.4:
        return '#0df1e4';
      case score >= 0.2:
        return '#4bb4c5';
      case score === 0:
        return '#738db4';
      case score >= -0.2:
        return '#9c65a3';
      case score >= -0.4:
        return '#AF3D96';
      case score >= -0.6:
        return '#C523A2';
      case score >= -0.8:
        return '#E312AC';
      default:
        return '#EE0883';
    }
  }
}
