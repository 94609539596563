import { AfterContentInit, Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ca-compact-form-item',
  templateUrl: './compact-form-item.component.html',
  styleUrls: ['./compact-form-item.component.scss'],
})
export class CompactFormItemComponent implements OnInit, AfterContentInit {
  @Input()
  autoWidth = false;

  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    const el = this.elRef.nativeElement as HTMLElement;

    el.classList.add(this.autoWidth ? 'col-auto' : 'col');
  }
}
