import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { CrudService } from 'src/core/services/crud/crud.service';
import { FormGroupStatus } from 'src/core/constants/form/form-group-status.constant';
import { StringValidator } from 'src/core/validators/shared/string.validator';
import { UserGroupDto } from 'src/core/models/user-group/user-group.dto';
import { DepartmentSelectorComponent } from 'src/ca-shared/department-selector/department-selector.component';
import { UserRoleSelectionEnum } from 'src/ca-shared/filter-panel/children/user-selection/models/user-role-selection.enum';
import { DepartmentFilterModeOption } from 'src/core/models/generic-lookup-type/department/department-filter-mode-option.glt';
import { UserFilterModeOption } from 'src/core/models/generic-lookup-type/identity/user-filter-mode-option.glt';
import { DepartmentDto } from 'src/core/models/administration/department/department.dto';
import { CAUserDto } from 'src/core/models/administration/user/CAUser.dto';
import { UserGroupNameValidator } from 'src/core/validators/user-group/user-group-name.validator';
import { UserGroupService } from 'src/core/services/user-group/user-group.service';
import { DepartmentAndUserValidator } from 'src/core/validators/shared/department-and-user.validator';
import { UserAndDepartmentValidator } from 'src/core/validators/shared/user-and-department.validator';
import { take } from 'rxjs';

@Component({
  selector: 'ca-user-group-form',
  templateUrl: './user-group-form.component.html',
  styleUrls: ['./user-group-form.component.scss'],
})
export class UserGroupFormComponent implements OnInit {
  @ViewChild('departmentSelector')
  departmentSelector: DepartmentSelectorComponent;

  @Output()
  saved: EventEmitter<{
    id: number;
  }> = new EventEmitter();

  @Output()
  closed = new EventEmitter();

  @ViewChild('formDirective') formDirective: FormGroupDirective;

  userGroupForm: FormGroup;
  userGroupDto: UserGroupDto;
  userRole = UserRoleSelectionEnum.User;
  userGroupNameValidator: UserGroupNameValidator;
  userErrorClass: string;
  departmentErrorClass: string;

  processing = false;

  get selectedUsers(): any[] {
    return this.userGroupForm &&
      this.userGroupForm.get('userGroupUsers').value &&
      this.userGroupForm.get('userGroupUsers').value.selectedUsers
      ? this.userGroupForm.get('userGroupUsers').value.selectedUsers
      : [];
  }

  get isUsersExceptSelected(): boolean {
    return this.userGroupForm &&
      this.userGroupForm.get('userGroupUsers').value &&
      this.userGroupForm.get('userGroupUsers').value.filterModeId
      ? this.userGroupForm.get('userGroupUsers').value.filterModeId ==
          UserFilterModeOption.AllUsersExceptSelectedUser
      : false;
  }

  get selectedDepartments(): any[] {
    return this.userGroupForm &&
      this.userGroupForm.get('userGroupDepartments').value &&
      this.userGroupForm.get('userGroupDepartments').value.selectedDepartments
      ? this.userGroupForm.get('userGroupDepartments').value.selectedDepartments
      : [];
  }

  get isDepartmentsExceptSelected(): boolean {
    return this.userGroupForm &&
      this.userGroupForm.get('userGroupDepartments').value &&
      this.userGroupForm.get('userGroupDepartments').value.filterModeId
      ? this.userGroupForm.get('userGroupDepartments').value.filterModeId ==
          DepartmentFilterModeOption.AllDepartmentsExceptSelectedDeparment
      : false;
  }

  load(userGroupId: number | null) {
    DepartmentAndUserValidator.departmentTreeData = this.departmentSelector.departmentTreeData;
    UserAndDepartmentValidator.departmentControl = this.userGroupForm.get(
      'userGroupDepartments'
    ) as FormControl;
    DepartmentAndUserValidator.userControl = this.userGroupForm.get(
      'userGroupUsers'
    ) as FormControl;
    this.userGroupNameValidator.id = userGroupId;
    if (userGroupId == null) {
      this.userGroupDto = {
        id: null,
        name: null,
        isActive: true,
        lastModificationTime: null,
        userFilterModeId: UserFilterModeOption.SelectedUsers,
        departmentFilterModeId: DepartmentFilterModeOption.SelectedDepartments,
        userGroupUsers: [],
        userGroupDepartments: [],
        userGroupInteractions: [],
      };
      this.userGroupForm.patchValue(this.userGroupDto);
      this.userGroupForm.get('userGroupUsers').patchValue({
        selectedUsers: [],
        filterModeId: UserFilterModeOption.SelectedUsers,
      });
      this.userGroupForm.get('userGroupDepartments').patchValue({
        selectedDepartments: [],
        filterModeId: DepartmentFilterModeOption.SelectedDepartments,
      });
      this.userGroupForm.get('userGroupUsers').setErrors(null);
      this.userGroupForm.get('userGroupDepartments').setErrors(null);
    } else {
      this.service
        .getById<UserGroupDto>(UserGroupDto, userGroupId)
        .pipe(take(1))
        .subscribe(data => {
          this.userGroupDto = data;
          this.userGroupForm.patchValue(this.userGroupDto);
          var users = data.userGroupUsers.map(x => x.user);
          var departments = data.userGroupDepartments.map(x => x.department);
          this.userGroupForm.get('userGroupUsers').patchValue({
            selectedUsers: users,
            filterModeId: UserFilterModeOption.SelectedUsers,
          });
          this.userGroupForm.get('userGroupDepartments').patchValue({
            selectedDepartments: departments,
            filterModeId: DepartmentFilterModeOption.SelectedDepartments,
          });
        });
    }
  }

  checkSelectionErrors() {
    this.departmentErrorClass = this.userGroupForm.controls['userGroupDepartments'].errors
      ? 'error-border'
      : '';
    this.userErrorClass = this.userGroupForm.controls['userGroupUsers'].errors
      ? 'error-border'
      : '';
  }

  closePanel() {
    this.userGroupForm.reset();

    this.closed.emit();
  }

  onSubmitForm() {
    this.userGroupForm.controls['userGroupDepartments'].updateValueAndValidity();
    this.userGroupForm.controls['userGroupUsers'].updateValueAndValidity();

    if (this.processing || this.userGroupForm.invalid || this.userGroupForm.disabled) {
      setTimeout(() => {
        this.checkSelectionErrors();
      }, 100);
      return;
    }

    this.processing = true;

    // if form is valid, then save object.
    if (this.userGroupForm.valid) {
      this.save();
    }
    // if async validation is in progress, wait it/them to finish and listen for status changes.
    else if (this.userGroupForm.pending) {
      const subscription = this.userGroupForm.statusChanges.subscribe(status => {
        if (status === FormGroupStatus.VALID) {
          subscription.unsubscribe();
          this.save();
        } else if (status === FormGroupStatus.PENDING) {
          this.processing = true;
        } else {
          subscription.unsubscribe();
          this.processing = false;
        }
      });
    }
  }

  save() {
    this.userGroupDto.userGroupUsers = [];
    this.userGroupDto.userGroupDepartments = [];
    this.userGroupDto.id = this.userGroupDto.id == null ? 0 : this.userGroupDto.id;
    this.userGroupDto.name = this.userGroupForm.get('name').value;
    this.userGroupDto.isActive = this.userGroupForm.get('isActive').value;

    var departments = this.userGroupForm.get('userGroupDepartments').value;
    var users = this.userGroupForm.get('userGroupUsers').value;

    departments.selectedDepartments.forEach(f => {
      const departmentItem: DepartmentDto = {
        id: f.id,
        name: f.name,
        parentId: f.parentId,
        hasUser: f.hasUser,
        isActive: f.isActive,
        isIntegrated: f.isIntegrated,
        hasChildren: false,
      };

      this.userGroupDto.userGroupDepartments.push({
        userGroupId: this.userGroupDto.id > 0 ? this.userGroupDto.id : 0,
        departmentId: departmentItem.id,
        department: departmentItem,
      });
    });

    users.selectedUsers.forEach(f => {
      const userItem: CAUserDto = new CAUserDto();

      userItem.id = f.id;

      this.userGroupDto.userGroupUsers.push({
        userGroupId: this.userGroupDto.id > 0 ? this.userGroupDto.id : 0,
        userId: userItem.id,
        user: userItem,
      });
    });

    this.userGroupDto.userFilterModeId = users.filterModeId;
    this.userGroupDto.departmentFilterModeId = departments.filterModeId;

    this.service
      .save<UserGroupDto>(UserGroupDto, this.userGroupDto)
      .pipe(take(1))
      .subscribe({
        next: res => {
          this.saved.emit({ id: res.id });
          this.userGroupForm.patchValue(res);
          this.userGroupForm.reset();
          this.processing = false;
        },
        error: () => {
          this.processing = false;
          return null;
        },
      });
  }

  onClickSaveAndDeactivate() {
    this.userGroupForm.get('isActive').setValue(false);
    this.formDirective.ngSubmit.emit();
  }

  onClickSaveAndActivate() {
    this.userGroupForm.get('isActive').setValue(true);
    this.formDirective.ngSubmit.emit();
  }

  constructor(
    private service: CrudService,
    private fb: FormBuilder,
    private userGroupService: UserGroupService
  ) {
    this.userGroupNameValidator = new UserGroupNameValidator(this.userGroupService);

    this.userGroupForm = this.fb.group({
      id: [null],
      name: [
        null,
        {
          validators: [
            Validators.required,
            StringValidator.whitespace,
            Validators.minLength(3),
            Validators.maxLength(50),
          ],
          asyncValidators: [this.userGroupNameValidator],
          updateOn: 'change',
        },
      ],
      userGroupUsers: [
        {
          selectedUsers: [],
          filterModeId: UserFilterModeOption.SelectedUsers,
        },
        {
          validators: [UserAndDepartmentValidator.Validator],
          updateOn: 'change',
        },
      ],
      userGroupDepartments: [
        {
          selectedDepartments: [],
          filterModeId: DepartmentFilterModeOption.SelectedDepartments,
        },
        {
          validators: [DepartmentAndUserValidator.Validator],
          updateOn: 'change',
        },
      ],
      isActive: [null],
    });
  }

  ngOnInit() {}
}
