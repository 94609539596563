import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConversationSentimentLabelComponent } from './conversation-sentiment-label.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ConversationSentimentLabelComponent],
  imports: [CommonModule, SharedModule, NgbTooltipModule],
  exports: [ConversationSentimentLabelComponent],
})
export class ConversationSentimentLabelModule {}
