import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { environment } from 'src/environments/environment';
import { ListRequestDto } from 'src/core/models/request/list-request.dto';
import { DepartmentDto } from 'src/core/models/administration/department/department.dto';
import { GenericTreeNodeDto } from 'src/core/models/mat-tree/generic-tree-node.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { shareReplay } from 'rxjs/operators';
import { DropdownTreeNodeModel } from 'src/ca-shared/dropdown-tree/dropdown-tree.module';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  private cache$: Observable<any>;
  constructor(private http: HttpClient, private operators: Operators) { }

  public getTreeNodes<T>(request: ListRequestDto): Observable<GenericTreeNodeDto<T>[]> {
    const apiUrl = this.getApiUrl(DepartmentDto) + '/tree-nodes-with-detail/';

    let params = new HttpParams();

    params = params.append('filters', JSON.stringify(request.filters));
    params = params.append('sorters', JSON.stringify(request.sorters));
    params = params.append('skipCount', JSON.stringify(request.skipCount));
    params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));

    return this.http.get(apiUrl, {
      params,
    }) as Observable<GenericTreeNodeDto<T>[]>;
  }

  public getTreeNodesForSelector<T>(request: ListRequestDto): Observable<GenericTreeNodeDto<T>[]> {
    const apiUrl = this.getApiUrl(DepartmentDto) + '/tree-nodes/';

    let params = new HttpParams();
    request.filters.push({
      field: 'includeUserCount',
      operator: this.operators.Contains,
      value: false,
    });

    params = params.append('filters', JSON.stringify(request.filters));
    params = params.append('sorters', JSON.stringify(request.sorters));
    params = params.append('skipCount', JSON.stringify(request.skipCount));
    params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));

    let req = this.http.get(apiUrl, {
      params,
    }) as Observable<GenericTreeNodeDto<T>[]>;
    if (!this.cache$) {
      this.cache$ = req.pipe(shareReplay(1));
    }

    return this.cache$;
  }

  public setRootNode(departmentTreeData: DropdownTreeNodeModel[]): DropdownTreeNodeModel[] {
    let result = departmentTreeData.filter(item => {
      return item.payload['parentId'] === null;
    });

    if (result.length == 0 && departmentTreeData.length > 0) {
      let unparentedNodes = departmentTreeData.filter(
        item =>
          item.payload['parentId'] != null &&
          !departmentTreeData.some(n => n.payload['id'] == item.payload['parentId'])
      );

      unparentedNodes?.forEach(unparented => {
        let parent = departmentTreeData.filter(
          item => item.payload['id'] === unparented.payload['parentId']
        );
        while (parent.length != 0) {
          unparented = parent[0];
          parent = departmentTreeData.filter(
            item => item.payload['id'] === unparented.payload['parentId']
          );
        }
        unparented.payload['parentId'] = null;
      });
    }
    return departmentTreeData;
  }

  clearDepartmentCache() {
    this.cache$ = null;
  }

  protected getApiUrl(type = DtoHasApi): string {
    const apiBase = environment.apis.default.url;

    return apiBase + '/' + type.apiUrl;
  }
}
