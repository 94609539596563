import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { RoleDto } from 'src/core/models/shared/role.dto';
import { RoleSelectionConfigurationModel } from '../models/role-selection-configuration.model';
import { RoleSelectionType } from '../models/role-selection-type.enum';

@Component({
  selector: 'ca-role-selection-panel',
  templateUrl: './role-selection-panel.component.html',
  styleUrls: ['./role-selection-panel.component.scss'],
})
export class RoleSelectionPanelComponent extends FilterPanelEditorComponent implements OnInit {
  roleSelectorOptions: any;
  roleFilters: FilterItemDto[] = [];
  actualConfig: RoleSelectionConfigurationModel;
  _value: RoleDto[];
  initialLoad = true;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as RoleSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  @ViewChild(SelectorComponent, { read: SelectorComponent }) roleSelector: SelectorComponent;

  constructor(
    protected fb: FormBuilder,
    private operators: Operators,
    private localizationService: LocalizationService
  ) {
    super(fb);

    this.roleSelectorOptions = {
      pageSize: 5,
      filterProperty: 'filter',
      sorterProperty: 'sorting',
      sorters: 'Name',
      useSimpleFilter: true,
      filters: '',
      emptyText: this.localizationService.instant('::SelectRole'),
      multiple: true,
      showSelections: true,
      selectorPosition: 'Top',
      url: 'api/identity/roles',
      currentPage: 1,
    };

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  ngOnInit(): void {}

  init(): void {
    super.init();

    this.roleSelectorOptions.multiple =
      this.actualConfig.selectionType === RoleSelectionType.Multiple;

    this.initialLoad = true;

    if (this.actualConfig.autoLoad) {
      this.innerLoad();
    }
  }

  reload(): void {
    this.innerLoad();
  }

  reset(): void {
    this.roleSelector.currentPage = 1;
  }
  
  autoSelectFirstItem(): void {}

  private innerLoad() {
    this.roleSelector.filters = '';
    this.roleSelector.refresh();
  }
}
