import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { ConversationCleanerFilterDto } from 'src/core/models/conversation/cleaner/conversation-cleaner-filter.dto';
import { ConversationCleanerCommunicationService } from 'src/app/conversation/conversation-cleaner/conversation-cleaner-communication.service';

@Component({
  selector: 'ca-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss'],
})
export class StepTwoComponent implements OnInit {
  loading = false;
  conversationCount: number = 0;
  filterData: ConversationCleanerFilterDto;
  deleteConfirmationText: string = '';

  get deleteConversationInformationText(): string {
    const deleteMessage = this.localizationService.instant('Conversation::DeleteMultipleConfirmationText').toLocaleUpperCase();
    let message = this.localizationService.instant(
      'Conversation::DeleteMultipleConversationWarning1',
      this.conversationCount.toString()
    );
    message +=
      '<br>' + this.localizationService.instant('Conversation::DeleteMultipleConversationWarning2');
    message +=
      '<br>' + this.localizationService.instant('Conversation::DeleteMultipleConversationWarning3', deleteMessage);

    return message;
  }
  constructor(private localizationService: LocalizationService,
    private conversationCleanerCommunicationService: ConversationCleanerCommunicationService) {}

  setData(data: any) {
    this.conversationCount = data.conversationCount;
    this.filterData = data.filterData;
    this.loading = false;
  }

  setLoader() {
    this.loading = true;
  }

  ngOnInit(): void {}

  validateEntryToStep() {
    return true;
  }

  validateExitFromStep() {
    if (this.conversationCount > 0) {
      return of(true);
    } else {
      return of(false);
    }
  }

  deleteConfirmationTextChange(value) {
    const deleteMessage = this.localizationService.instant('Conversation::DeleteMultipleConfirmationText').toLocaleUpperCase();
    const deleteConfirmControl = value !== deleteMessage;
    this.conversationCleanerCommunicationService.isDeleteDisabled = deleteConfirmControl;
  }
}
