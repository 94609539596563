import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  WindowPositionChanged,
  WindowSizeChanged,
  WindowStatusChanged,
  InitializationRequested,
} from 'src/core/actions/video-player/video-player.actions';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { VideoPlayerStateModel } from 'src/core/models/video-player/video-player.state-model';
import { WindowPosition } from 'src/core/models/window/window-position.model';
import { WindowSize } from 'src/core/models/window/window-size.model';

@State<VideoPlayerStateModel>({
  name: 'VideoPlayerState',
  defaults: {
    position: null,
    size: null,
    initialized: false,
  } as VideoPlayerStateModel,
})
@Injectable()
export class VideoPlayerState {
  private static defaultSize: WindowSize = {
    width: 640,
    height: 360,
  };

  private static get defaultPosition(): WindowPosition {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const left = screenWidth - VideoPlayerState.defaultSize.width - 25;
    const top = screenHeight / 2 - VideoPlayerState.defaultSize.height / 2;

    return { left: left, top: top };
  }

  @Selector()
  static getWindowSize(state: VideoPlayerStateModel): WindowSize {
    return state.size ? state.size : VideoPlayerState.defaultSize;
  }

  @Selector()
  static getWindowPosition(state: VideoPlayerStateModel): WindowPosition {
    return state.position ? state.position : VideoPlayerState.defaultPosition;
  }

  @Action(WindowPositionChanged)
  changeWindowLocation(ctx: StateContext<VideoPlayerStateModel>, action: WindowPositionChanged) {
    const currentState = ctx.getState();

    currentState.position = action.newPosition;

    ctx.patchState(currentState);
  }

  @Action(WindowSizeChanged)
  changeWindowSize(ctx: StateContext<VideoPlayerStateModel>, action: WindowSizeChanged) {
    const currentState = ctx.getState();

    currentState.size = action.newSize;

    ctx.patchState(currentState);
  }

  @Action(WindowStatusChanged)
  changeWindowStatus(ctx: StateContext<VideoPlayerStateModel>) {
    const currentState = ctx.getState();

    currentState.size = ObjectHelper.deepCopy(currentState.size);
    currentState.position = ObjectHelper.deepCopy(currentState.position);

    ctx.patchState(currentState);
  }

  @Action(InitializationRequested)
  initialize(ctx: StateContext<VideoPlayerStateModel>, action: InitializationRequested) {
    const currentState = ctx.getState();

    if (!currentState.initialized) {
      currentState.position = ObjectHelper.deepCopy(VideoPlayerState.defaultPosition);
      currentState.size = ObjectHelper.deepCopy(VideoPlayerState.defaultSize);
      currentState.initialized = true;

      ctx.patchState(currentState);
    }
  }
}
