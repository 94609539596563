<ng-template #userItemTemplate let-item="item">
  <ca-user-identity
    [divClass]="'ps-2'"
    [id]="item.id"
    [name]="item.fullName && item.fullName.length > 0 ? item.fullName : ''"
    [surname]="item.surname"></ca-user-identity>
</ng-template>
<ng-template #userSelectionTemplate let-selection="selection">
  {{ selection.length === 1 ? selection[0].fullName : ('::SelectAgent' | abpLocalization) }}
</ng-template>

<ng-template #filterToolbarTemplate let-filter="userFilter">
  <mat-slide-toggle
    name="isActive"
    (change)="onShowActivesOnlyChange()"
    [(ngModel)]="showActiveUsers"
    [checked]="showActiveUsers">
    {{ '::ShowActivesOnly' | abpLocalization }}</mat-slide-toggle
  >
</ng-template>

<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ '::Agent' | abpLocalization }}</label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ payload.operator | operator }}</label>
      </div>

      <div class="col-auto" [attr.title]="userFullNameList">
        <span *ngIf="loading"> {{ 'CA::Loading' | abpLocalization }}... </span>
        <span *ngIf="!loading">
          {{
            users.length === 1
              ? users[0].fullName
              : ('CA::SelectedAgentsWithCount' | abpLocalization : users.length)
          }}
        </span>
      </div>

      <div class="col-auto show-on-hover">
        <button
          mat-mini-fab
          color="warn"
          (click)="removeNode($event)"
          ngbTooltip="{{ '::RemoveNode' | abpLocalization }}"
          container="body"
          placement="bottom">
          <ca-delete-icon></ca-delete-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ '::Agent' | abpLocalization }}</label>
      </div>
      <div class="col-auto">
        <select
          class="form-select form-select-sm"
          [(ngModel)]="payload.operator"
          (ngModelChange)="operatorChanged()">
          <option *ngFor="let operator of node.categoryItem.operators" [value]="operator">
            {{ operator | operator }}
          </option>
        </select>
      </div>

      <div class="col-auto" [formGroup]="userForm">
        <ca-user-dropdown-selector
          id="userSelector"
          formControlName="agent"
          userType="agent"
          style="width: 350px"
          [multiple]="true"
          [closeOnSelect]="false"
          class="d-block"></ca-user-dropdown-selector>
      </div>

      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>
