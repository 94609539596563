<div
  [ngClass]="{ 'query-builder-outer': !isReadonly }"
  [style.height]="!isReadonly && 'calc(100% - 5px) !important'">
  <fieldset [ngClass]="getFieldsetClasses()">
    <legend class="builder-legend" *ngIf="!isReadonly">{{ '::Builder' | abpLocalization }}</legend>

    <div
      [style.overflow]="!isReadonly && 'auto'"
      [ngClass]="getMatTreeOuterClasses()"
      [scrollTop]="treeScrollTop"
      #queryTreeBody>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="query-tree" #queryTree>
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [hidden]="node.hidden">
          <li [ngClass]="{ 'mat-tree-node': !isReadonly }">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled *ngIf="!isReadonly"></button>
            <ca-query-tree-node-factory
              [node]="node"
              [queryBuilder]="thisRef"
              [isReadonly]="isReadonly"
              [currentQueryId]="currentQuery.id"></ca-query-tree-node-factory>
          </li>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [hidden]="node.hidden">
          <li>
            <div [ngClass]="{ 'mat-tree-node': !isReadonly }" tabIndex="0">
              <button
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.text"
                *ngIf="!isReadonly">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
              </button>
              <ca-query-tree-node-factory
                [node]="node"
                [queryBuilder]="thisRef"
                [isReadonly]="isReadonly"
                [currentQueryId]="currentQuery.id"></ca-query-tree-node-factory>
            </div>
            <ul [class.query-tree-invisible]="!treeControl.isExpanded(node)">
              <div matTreeNodeOutlet></div>
            </ul>
          </li>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
  </fieldset>
  <div [hidden]="true" class="text-lg-right pt-2 ca-toolbar" *ngIf="!isReadonly">
    <div class="ca-toolbar-west-section">
      <div class="toolbar-item first"></div>
      <div class="toolbar-item"></div>
      <div class="toolbar-item"></div>
    </div>
    <div class="ca-toolbar-east-section">
      <div class="toolbar-item">
        <button
          [disabled]="processing"
          class="btn btn-info toolbar-button"
          *ngIf="canEditQuery || isImportOrCreateNew"
          (click)="onSaveClick($event, false)">
          <span *ngIf="!processing"
            ><i class="fas fa-save"></i> {{ 'AbpUi::Save' | abpLocalization }}</span
          >
          <span *ngIf="processing"
            ><i class="fas fa-spinner fa-spin"></i>
            {{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span
          >
        </button>
      </div>
      <div [ngClass]="currentQuery && currentQuery.id > 0 ? 'toolbar-item' : 'toolbar-item last'">
        <button
          [disabled]="processing"
          class="btn btn-success toolbar-button"
          *ngIf="canEditQuery || isImportOrCreateNew"
          (click)="onSaveClick($event, true)">
          <span *ngIf="!processing"
            ><i class="fas fa-save"></i> {{ '::SaveClose' | abpLocalization }}</span
          >
          <span *ngIf="processing"
            ><i class="fas fa-spinner fa-spin"></i>
            {{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span
          >
        </button>
      </div>
    </div>
  </div>
</div>
