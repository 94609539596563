<ng-template #callImportContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'Conversation::ConversationImport.Regular' | abpLocalization }}
    </h4>
  </div>
  <div class="modal-body">
    <div class="container p-2">
      <ng-wizard [config]="config" (stepChanged)="stepChanged($event)" class="import-wizard">
        <ng-wizard-step
          title="{{ stepDefinitions[0].title | abpLocalization }}"
          [description]="stepDefinitions[0].description"
          [state]="stepDefinitions[0].state"
          [canEnter]="validateStepZero.bind(thisRef, stepZeroCmp, stepOneCmp, stepTwoCmp, 'entry')"
          [canExit]="validateStepZero.bind(thisRef, stepZeroCmp, stepOneCmp, stepTwoCmp, 'exit')">
          <div class="w-100">
            <ca-conversation-import-step-zero #stepZeroCmp> </ca-conversation-import-step-zero>
          </div>
        </ng-wizard-step>
        <ng-wizard-step
          title="{{ stepDefinitions[1].title | abpLocalization }}"
          [description]="stepDefinitions[1].description"
          [state]="isCallImport() ? stepDefinitions[1].state : stepStates.hidden"
          [canEnter]="validateStepOne.bind(thisRef, stepOneCmp, stepTwoCmp, 'entry')"
          [canExit]="validateStepOne.bind(thisRef, stepOneCmp, stepTwoCmp, 'exit')">
          <ca-call-import-step-one #stepOneCmp> </ca-call-import-step-one>
        </ng-wizard-step>
        <ng-wizard-step
          title="{{ stepDefinitions[2].title | abpLocalization }}"
          [description]="stepDefinitions[2].description"
          [state]="isCallImport() ? onChangeStepState() : stepStates.hidden"
          [canEnter]="validateStepTwo.bind(thisRef, stepOneCmp, stepTwoCmp, stepThreeCmp, 'entry')"
          [canExit]="validateStepTwo.bind(thisRef, stepOneCmp, stepTwoCmp, stepThreeCmp, 'exit')">
          <ca-call-import-step-two #stepTwoCmp> </ca-call-import-step-two>
        </ng-wizard-step>
        <ng-wizard-step
          title="{{ stepDefinitions[3].title | abpLocalization }}"
          [description]="stepDefinitions[3].description"
          [state]="isCallImport() ? onChangeStepState() : stepStates.hidden"
          [canEnter]="validateStepThree.bind(thisRef, stepTwoCmp, stepThreeCmp, 'entry')"
          [canExit]="validateStepThree.bind(thisRef, stepTwoCmp, stepThreeCmp, 'exit')">
          <ca-call-import-step-three
            #stepThreeCmp
            [cancelImport]="cancelImport"
            (progress)="changeProgressValue($event)">
          </ca-call-import-step-three>
        </ng-wizard-step>
        <ng-wizard-step
          title="{{ stepDefinitions[4].title | abpLocalization }}"
          [description]="stepDefinitions[4].description"
          [state]="isCallImport() ? stepStates.hidden : stepDefinitions[4].state"
          [canEnter]="validateStepOne.bind(thisRef, chatStepOneCmp, chatStepTwoCmp, 'entry')"
          [canExit]="validateStepOne.bind(thisRef, chatStepOneCmp, chatStepTwoCmp, 'exit')">
          <ca-chat-import-step-one #chatStepOneCmp> </ca-chat-import-step-one>
        </ng-wizard-step>
        <ng-wizard-step
          title="{{ stepDefinitions[5].title | abpLocalization }}"
          [description]="stepDefinitions[5].description"
          [state]="isCallImport() ? stepStates.hidden : onChangeStepState()"
          [canEnter]="
            validateStepTwo.bind(thisRef, chatStepOneCmp, chatStepTwoCmp, chatStepThreeCmp, 'entry')
          "
          [canExit]="
            validateStepTwo.bind(thisRef, chatStepOneCmp, chatStepTwoCmp, chatStepThreeCmp, 'exit')
          ">
          <ca-chat-import-step-two #chatStepTwoCmp> </ca-chat-import-step-two>
        </ng-wizard-step>
        <ng-wizard-step
          title="{{ stepDefinitions[6].title | abpLocalization }}"
          [description]="stepDefinitions[6].description"
          [state]="isCallImport() ? stepStates.hidden : onChangeStepState()"
          [canEnter]="validateStepThree.bind(thisRef, chatStepTwoCmp, chatStepThreeCmp, 'entry')"
          [canExit]="validateStepThree.bind(thisRef, chatStepTwoCmp, chatStepThreeCmp, 'exit')">
          <ca-chat-import-step-three
            #chatStepThreeCmp
            [cancelImport]="cancelImport"
            (progress)="changeProgressValue($event)">
          </ca-chat-import-step-three>
        </ng-wizard-step>
      </ng-wizard>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-toolbar ng-wizard-toolbar ng-wizard-toolbar-top">
      <button
        type="button"
        [disabled]="!closeButtonDisabled && isStepThree"
        (click)="closeButtonDisabled && isStepThree ? onCancelModal() : onCloseModal()"
        container="body"
        style="margin-right: 6px !important"
        class="btn ca-btn ca-btn-neutral"
        nzType="primary">
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <span class="toolbar-item">
        <mat-divider [vertical]="true" class="menu-divider"></mat-divider>
      </span>
      <button
        type="button"
        [hidden]="preButtonHidden"
        (click)="showPreviousStep($event)"
        class="btn ca-btn ca-btn-neutral"
        nzType="primary"
        style="margin-left: 6px">
        <i class="fas fa-angle-left"></i
        ><span class="me-1 ms-1"> {{ '::Previous' | abpLocalization }}</span>
      </button>
      <span
        [hidden]="!showNextButton"
        container="body"
        ngbTooltip="{{
          !isFilesSelected()
            ? ('Conversation::PleaseSelectTheFiles' | abpLocalization)
            : !isFilesValid()
            ? ('Conversation::PleaseDontSelectInvalidFile' | abpLocalization)
            : ''
        }}"
        [disableTooltip]="this.selectedStepIndex === 0">
        <button
          type="button"
          (click)="showNextStep($event)"
          [disabled]="this.selectedStepIndex !== 0 && (!isFilesSelected() || !isFilesValid())"
          class="btn ca-btn ca-btn-primary reverse"
          nzType="primary"
          style="float: left; margin-left: 6px">
          <span class="me-1 ms-1"> {{ '::Next' | abpLocalization }}</span
          ><i class="fas fa-angle-right"></i>
        </button>
      </span>
      <button
        type="button"
        (click)="onImport($event)"
        [hidden]="!showImportButton"
        class="btn ca-btn ca-btn-primary"
        nzType="default"
        style="margin-left: 4px">
        <i class="far fa-file-import"></i>
        <span>{{ 'Conversation::Import' | abpLocalization }}</span>
      </button>
      <button
        type="button"
        (click)="onCloseModal()"
        [hidden]="!isStepThree"
        [disabled]="closeButtonDisabled"
        class="btn ca-btn ca-btn-primary"
        nzType="default"
        style="margin-left: 6px">
        <i class="far fa-times"></i>
        <span class="me-1 ms-1">{{ '::Close' | abpLocalization }}</span>
      </button>
    </div>
  </div>
</ng-template>

<button
  *ngIf="canImportConversation"
  class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
  (click)="openCallImportModal(callImportContent)">
  <i class="far fa-file-import"></i>
  <span class="me-1">{{ '::Import' | abpLocalization }}</span>
</button>
