import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private apiBase = environment.apis.default.url;

  constructor(private http: HttpClient) {}

  clear(): Observable<any> {
    return this.http.get(`${this.apiBase}/api/app/cache/clear`) as Observable<any>;
  }
}
