import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Department.FilterModeOption',
})
export class DepartmentFilterModeOption {
  @GenericLookup({
    code: 'Department.FilterModeOption.SelectedDepartments',
  })
  static SelectedDepartments: number;

  @GenericLookup({
    code: 'Department.FilterModeOption.AllDepartmentsExceptSelectedDeparment',
  })
  static AllDepartmentsExceptSelectedDeparment: number;
}
