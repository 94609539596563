import {
  normalizePassiveListenerOptions,
  supportsPassiveEventListeners,
} from '@angular/cdk/platform';
import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';

@Component({
  selector: 'ca-base-dropdown-selector',
  templateUrl: './base-dropdown-selector.component.html',
  styleUrls: ['./base-dropdown-selector.component.scss'],
})
export abstract class BaseDropdownSelectorComponent<TItem>
  extends HasSubscription
  implements OnDestroy, OnInit
{
  @Input()
  placeholder: string;

  @Input()
  multiple: boolean = false;

  @Input()
  closeOnSelect: boolean = true;

  @Input()
  bringToFront: boolean = false;

  @Input()
  set filters(value: FilterItemDto[]) {
    this._filters = value;
    this.pageIndex = 0;

    if (!this.initialLoad) {
      this.load();
    }
  }

  get filters(): FilterItemDto[] {
    return this._filters;
  }

  get classList(): string {
    let result = this.classListArray.join(' ');

    if (this._mutationClassList) {
      result = result + ' ' + this._mutationClassList.value;
    }

    return result;
  }

  @ViewChild('ngSelect', { read: NgSelectComponent, static: false })
  ngSelect: NgSelectComponent;

  changes: MutationObserver;
  classListArray: string[] = [];
  pageIndex: number = 0;
  totalCount: number = 0;
  itemsPerPage: number = 9999;
  loading: boolean = false;
  quickSearchTerm: string = '';
  initialLoad: boolean = true;
  value: any;
  isDisabled: boolean = false;
  quickSearchTermInput$ = new Subject<string>();
  items: TItem[] = [];

  protected abstract load(): void;

  private _filters: FilterItemDto[] = [];
  private _onScrollFn: () => void;
  private _mutationClassList: DOMTokenList;

  constructor(protected elementRef: ElementRef, protected ngZone: NgZone) {
    super();

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        const nativeElement = this.elementRef.nativeElement as HTMLElement;

        this._mutationClassList = nativeElement.classList;
      });
    });

    this.changes.observe(this.elementRef.nativeElement, {
      attributeFilter: ['class'],
    });
  }

  ngOnInit(): void {
    let eventOptions: AddEventListenerOptions | boolean;

    if (supportsPassiveEventListeners()) {
      //use the implementation on mozilla
      eventOptions = normalizePassiveListenerOptions({
        capture: true,
        passive: true,
      });
    } else {
      eventOptions = true;
    }

    this._onScrollFn = this.onScroll.bind(this);

    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', this._onScrollFn, eventOptions);
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
    window.removeEventListener('scroll', this._onScrollFn, true);
  }

  onChange(args: any) {}

  onTouched() {}

  onOpen(eventArgs: any): void {
    setTimeout(() => {
      const dropdownId = this.ngSelect.dropdownId;
      const dropdownEl = document.getElementById(dropdownId);

      dropdownEl.classList.add('ca-selector-dropdown-panel');

      if (this.bringToFront) {
        dropdownEl.classList.add('bring-to-front');
      }
    }, 1);

    if (this.initialLoad) {
      this.load();
      this.initialLoad = false;
    }
  }

  onClear(eventArgs: any): void {
    this.quickSearchTerm = '';
    this.pageIndex = 0;

    this.load();
  }

  onPageChange(eventArgs: PageEvent): void {
    this.pageIndex = eventArgs.pageIndex;

    this.load();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private onScroll(): void {
    if (this.ngSelect && this.ngSelect.isOpen) {
      this.ngZone.run(() => {
        this.ngSelect.dropdownPanel.adjustPosition();
      });
    }
  }
}
