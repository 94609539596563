import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Meeting.Analysis.Status',
})
export class MeetingAnalysisStatus {
  @GenericLookup({
    code: 'Meeting.Analysis.Status.NotAnalyzed',
  })
  static notAnalyzed: number;

  @GenericLookup({
    code: 'Meeting.Analysis.Status.Analyzing',
  })
  static analyzing: number;

  @GenericLookup({
    code: 'Meeting.Analysis.Status.Analyzed',
  })
  static analyzed: number;

  @GenericLookup({
    code: 'Meeting.Analysis.Status.Failed',
  })
  static failed: number;

  @GenericLookup({
    code: 'Meeting.Analysis.Status.NoSpeech',
  })
  static noSpeech: number;

  @GenericLookup({
    code: 'Meeting.Analysis.Status.FileNotFound',
  })
  static fileNotFound: number;

  @GenericLookup({
    code: 'Meeting.Analysis.Status.NotSupportedLanguageCode',
  })
  static notSupportedLanguageCode: number;

  @GenericLookup({
    code: 'Meeting.Analysis.Status.LanguageDetectionServiceDisabled',
  })
  static languageDetectionServiceDisabled: number;

  @GenericLookup({
    code: 'Meeting.Analysis.Status.NotSupportedAudioFormat',
  })
  static notSupportedAudioFormat: number;

  @GenericLookup({
    code: 'Meeting.Analysis.Status.SpeechRecognitionError',
  })
  static speechRecognitionError: number;
}
