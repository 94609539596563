<form *ngIf="virtualAgentIntegration" [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="endpoint">
    <label class="indicates-required-field" for="endpoint">
      {{ 'Settings::RTGVirtualAgent:Endpoint' | abpLocalization }}
    </label>
    <input type="text" class="form-control form-control-sm" id="endpoint" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="identityBaseEndpoint">
    <label class="indicates-required-field" for="identityBaseEndpoint">
      {{ 'Settings::RTGVirtualAgent:IdentityBaseEndpoint' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="identityBaseEndpoint"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientId">
    <label [ngClass]="!isSavedBefore ? 'indicates-required-field' : ''" for="clientId">
      {{ 'Settings::RTGVirtualAgent:ClientId' | abpLocalization }}
    </label>
    <input
      type="password"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      class="form-control form-control-sm"
      id="clientId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label [ngClass]="!isSavedBefore ? 'indicates-required-field' : ''" for="clientSecret">
      {{ 'Settings::RTGVirtualAgent:ClientSecret' | abpLocalization }}
    </label>
    <input
      type="password"
      class="form-control form-control-sm"
      id="clientSecret"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="webChatId">
    <label for="webChatId">
      {{ 'Settings::RTGVirtualAgent:WebChatId' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="webChatId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="postConversationProject">
    <label for="postConversationProject">
      {{ 'Settings::RTGVirtualAgent:PostConversationProject' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="postConversationProject"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
