<ng-template #itemTemplate let-item="item">
  <span class="truncate-text">{{ item.name }}</span>
</ng-template>

<div class="d-flex">
  <div
    ngbDropdown
    #markAsDropdown="ngbDropdown"
    [autoClose]="autoClose"
    [container]="'body'"
    (openChange)="onDropdownOpen($event)"
    class="{{ divClass }}">
    <button
      ngbDropdownToggle
      class="btn ca-btn ca-btn-neutral-toolbar toolbar-button mark-as-button {{ buttonClass }}"
      *ngIf="qualityManagementFeatureEnabled || canAssignTag">
      <i class="far fa-highlighter"></i>
      <span>{{ 'Conversation::MarkAs' | abpLocalization }}</span>
    </button>
    <div ngbDropdownMenu>
      <div class="modal-body mark-modal-fixed-width">
        <div *ngIf="canAssignTag && isAnalysed">
          <h3 class="content-header-title content-title">
            {{ 'Conversation::Tag.Plural' | abpLocalization }}
          </h3>
          <ca-selector
            #tagSelector
            [url]="tagDropdownOptions.url"
            [filters]="tagDropdownOptions.filters"
            [itemTemplate]="itemTemplate"
            [selectionTemplate]="selectionTemplate"
            [queryOperator]="tagDropdownOptions.queryOperator"
            [queryField]="tagDropdownOptions.queryField"
            [selectOnClick]="true"
            [excludedItemIds]="getExcludedItemIds()"
            (itemClicked)="addTag($event)"
            [emptyText]="tagDropdownOptions.emptyText"
            [showSelections]="tagDropdownOptions.showSelections"
            [showClearSelections]="tagDropdownOptions.showClearSelections"
            [showClearSelectionLink]="tagDropdownOptions.showClearSelectionLink"
            [selectorPosition]="tagDropdownOptions.selectorPosition"
            [styles]="{ width: '100%', 'border-width': '0px' }"
            [pageSize]="tagDropdownOptions.pageSize"
            [currentPage]="tagDropdownOptions.currentPage"></ca-selector>
        </div>
        <div *ngIf="qualityManagementFeatureEnabled">
          <h3 class="content-header-title content-title">
            {{ 'Conversation::Evaluation' | abpLocalization }}
          </h3>
          <div class="mark-div">
            <div class="row form-group evaluable-mark" [hidden]="!canMarkNotSuitableForEvaluation">
              <div class="col-6 button-container">
                <button
                  class="mark-button"
                  id="markAsNotEvaluable"
                  [disabled]="!conversation?.isSuitableForEvaluation || disableEvaluableMarks"
                  (click)="markAsNotSuitableForEvaluation()">
                  <i class="far fa-plus me-2 mt-auto mb-auto"></i>
                  <span for="markAsNotEvaluable" class="mt-auto mb-auto">
                    {{ 'Conversation::MarkAsNotEvaluable' | abpLocalization }}
                  </span>
                </button>
              </div>
              <div class="col-5 reason-selector-dropdown mt-auto mb-auto">
                <ca-reason-dropdown-selector
                  #reasonSelector
                  id="reasonSelector"
                  [disabled]="!conversation?.isSuitableForEvaluation || disableEvaluableMarks"
                  [reasonType]="reasonType"
                  [canAddNewReason]="false"></ca-reason-dropdown-selector>
              </div>
            </div>
            <div>
              <button
                class="mark-button"
                id="markAsPriorForEvaluation"
                [disabled]="conversation?.isPriorForEvaluation || disableEvaluableMarks"
                (click)="markAsPriorForEvaluation()">
                <i class="far fa-plus me-2 mt-auto mb-auto"></i>
                <span for="markAsPriorForEvaluation" class="mt-auto mb-auto">
                  {{ 'Conversation::MarkAsPriorForEvaluation' | abpLocalization }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading pt-2">
    <i
      class="fad fa-spinner-third fa-spin"
      *ngIf="conversationMarkAsCommunicationService.tagsUpdating"
      [ngbTooltip]="'::Saving' | abpLocalization"></i>
  </div>
  <ca-conversation-mark-as-detail
    [conversation]="conversation"
    (evaluableMarkChanged)="setNotSuitableForEvaluationReasons()"></ca-conversation-mark-as-detail>
</div>
