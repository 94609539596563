import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.ResultType',
})
export class ConversationResultType {
    @GenericLookup({
        code: 'Conversation.ResultType.Phrase',
    })
    static phrase: number;

    @GenericLookup({
        code: 'Conversation.ResultType.Query',
    })
    static query: number;

    @GenericLookup({
        code: 'Conversation.ResultType.Topic',
    })
    static topic: number;
}
