import { Component, OnInit } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { TimeQueryItemDto } from 'src/core/models/query/time-query-item.dto';

@Component({
  selector: 'ca-query-tree-node-time',
  templateUrl: './query-tree-node-time.component.html',
  styleUrls: ['./query-tree-node-time.component.scss'],
})
export class QueryTreeNodeTimeComponent extends QueryEditorBase implements OnInit {
  payload: TimeQueryItemDto;

  constructor() {
    super();
  }

  operatorChanged() {
    this.node.isDirty = true;
  }

  valueChanged() {
    this.node.isDirty = true;
  }

  ngOnInit() {
    this.payload = this.node.categoryItem.payload as TimeQueryItemDto;
    this.node.validationStatus = true;
  }
}
