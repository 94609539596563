import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Sections } from 'src/core/models/administration/section.enum';
import { LayoutStateModel } from 'src/core/models/layout/layout.state-model';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { LayoutConfig } from 'src/core/models/split/layout-config.model';
import { SplitConfig } from 'src/core/models/split/split-config.model';
import { LayoutService } from 'src/core/services/layout/layout.service';
import { CALayoutState } from 'src/core/states/layout/ca-layout.state';
import { Select } from '@ngxs/store';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { UserGroupListToolbarComponent } from '../user-group-list-toolbar/user-group-list-toolbar.component';
import { UserGroupListComponent } from '../user-group-list/user-group-list.component';
import { UserGroupFormComponent } from '../user-group-form/user-group-form.component';
import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'ca-user-group-main',
  templateUrl: './user-group-main.component.html',
  styleUrls: ['./user-group-main.component.scss'],
})
export class UserGroupMainComponent implements OnInit {
  splitConfig = SplitConfig.DEFAULT;
  @Select(CALayoutState.layout(Sections.sources))
  layout$: Observable<LayoutStateModel>;

  @ViewChild('toolbar', { static: true, read: UserGroupListToolbarComponent })
  toolbar: UserGroupListToolbarComponent;

  @ViewChild('list', { static: true, read: UserGroupListComponent })
  list: UserGroupListComponent;

  @ViewChild('form', { static: true, read: UserGroupFormComponent })
  form: UserGroupFormComponent;

  onDataRequest(eventArgs: { filters: FilterItemDto[]; sorters: SorterItemDto[] }) {
    this.list.loadFromToolbar(eventArgs.filters, eventArgs.sorters);
  }

  onListLoadRequestCompleted() {
    this.toolbar.disableSearchProcessing();
  }

  onOpenDetails(eventArgs: { id: number }) {
    this.layoutService.changeVisibilityForDetailPanel(Sections.sources, true);
    this.form.load(eventArgs.id);
    this.list.selectRow(eventArgs.id);
  }

  onDetailPanelClose(eventArgs) {
    this.layoutService.changeVisibilityForDetailPanel(Sections.sources, false);

    this.list.clearSelection();
  }

  onAddNewClick(eventArgs) {
    this.layoutService.changeVisibilityForDetailPanel(Sections.sources, true);
    this.form.load(null);
  }

  onSaveDetails(eventArgs: { id: number }) {
    this.list.refresh();
    this.toastr.success(this.localizationService.instant('::SuccessfullySaved'));
    this.layoutService.changeVisibilityForDetailPanel(Sections.sources, false);
  }

  onDragEnd(eventArgs) {
    this.layoutService.onDragEnd(Sections.sources, eventArgs);
  }

  constructor(
    private layoutService: LayoutService,
    private localizationService: LocalizationService,
    private toastr: ToasterService
  ) {
    this.layoutService.changeVisibilityForDetailPanel(Sections.sources, false);
    const currentLayout = this.layoutService.getCurrentLayout(Sections.sources);
    if (currentLayout === LayoutConfig.DEFAULT) {
      currentLayout.east.size = 700;
    }
    currentLayout.east.maxSize = 800;
    currentLayout.east.minSize = 600;
    this.layoutService.changeCurrentLayout(Sections.sources, currentLayout);
  }

  ngOnInit(): void {}
}
