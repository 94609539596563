import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { ConversationImportStatus } from 'src/core/models/conversation/conversation-import-status.dto';
import { CallService } from 'src/core/services/conversation/call.service';
import { DataRequest } from 'src/core/actions/conversation/conversation-module.actions';
import { Store } from '@ngxs/store';
import { ConversationImportFileModel } from 'src/core/models/conversation/conversation-import-file-model';
import { ConversationImportWizardService } from '../../../conversation-import-wizard.service';

@Component({
  selector: 'ca-call-import-step-three',
  templateUrl: './call-import-step-three.component.html',
  styleUrls: ['./call-import-step-three.component.scss'],
})
export class CallImportStepThree implements OnInit {

  @Input()
  set cancelImport(value: boolean) {
    if (value) {
      this.pauseImporting();
    }
    else {
      this.resumeImporting();
    }
  }
  get cancelImport(): boolean {
    return this.pauseTag;
  }

  @Output()
  progress: EventEmitter<{
    progressValue: number
  }> = new EventEmitter();

  constructor(
    private callService: CallService,
    private store: Store,
  ) { }

  formData = new FormData();
  selectedCsvFile: File;
  public selectedConversationFiles: ConversationImportFileModel[] = [];
  public systemLangCode: string;
  processCode: string = Math.round((Math.pow(36, 10 + 1) - Math.random() * Math.pow(36, 10))).toString(36).slice(1);
  callImportStatus: ConversationImportStatus[] = [];
  public progressValue: number = 0;
  conversationImportWizardService: ConversationImportWizardService;

  private lastIndex: number = 0;
  private pauseTag = false;

  ngOnInit(): void { }

  async bindFormData() {
    this.formData = new FormData();
    this.formData.append('processCode', this.processCode);
    this.formData.append('languageCode', this.systemLangCode);
    if (this.selectedCsvFile) {
      this.formData.append('csvFile', this.selectedCsvFile, this.selectedCsvFile.name);
      try {
        await this.callService.csvImport(this.formData).toPromise();
      } catch (error) {
        return false;
      }
      this.formData.delete('csvFile');
    }
    return true;
  }

  async validateEntryToStep() {
    if (!await this.bindFormData()) {
      return;
    }

    for (const data of this.selectedConversationFiles) {
      var status = new ConversationImportStatus();
      status.fileName = data.file.name;
      this.callImportStatus.push(status);
    }

    this.startImporting();

    return true;
  }

  validateExitFromStep() {
    return of(false);
  }

  async startImporting(startIndex: number = 0) {
    for (let i = startIndex; i < this.selectedConversationFiles.length; i++) {
      if (this.pauseTag) {
        this.lastIndex = i;
        return false;
      }
      else {
        var data = this.selectedConversationFiles[i];
        const file = data.file;
        this.formData.delete('File');
        this.formData.append('File', file, file.name);
        var status = this.callImportStatus[i];

        try {
          this.callImportStatus[i] = await this.callService.import(this.formData).toPromise();
        }
        catch (error) {
          status.error = error.error.error.message;
          status.success = false;
          status.fileName = file.name;
          this.callImportStatus[i] = status;
        }

        this.progressValue = 100 * (i + 1) / this.selectedConversationFiles.length;
        this.progress.emit({ progressValue: this.progressValue });

        if (this.progressValue === 100 && status.success) {
          const dataRequestAction = new DataRequest();
          this.store.dispatch(dataRequestAction);
        }
      }
    }
    return true;
  }

  pauseImporting(): void {
    this.pauseTag = true;
  }

  resumeImporting(): void {
    this.pauseTag = false;
    this.startImporting(this.lastIndex);
  }
}