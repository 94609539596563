import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.DeletionScopeType',
})
export class DeletionScopeType {
  @GenericLookup({
    code: 'Conversation.DeletionScopeType.CompleteData',
  })
  static completeData: number;

  @GenericLookup({
    code: 'Conversation.DeletionScopeType.AudioVideoScreenRecordings',
  })
  static audioVideoScreenRecords: number;

  @GenericLookup({
    code: 'Conversation.DeletionScopeType.ScreenRecords',
  })
  static screenRecords: number;
}
