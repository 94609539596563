<div class="row m-0" *blockUI="'permissions-block'" style="min-height: 300px">
  <div class="col p-2">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let permissionGroup of permissionGroups">
        <mat-expansion-panel-header>
          <mat-panel-title style="color: #2196f3">
            {{ permissionGroup.displayName }}
          </mat-panel-title>
          <mat-panel-description
            style="display: flex; flex-direction: row; justify-content: flex-end"
          >
            <b>{{ getSelectionCount(selectionList) }}</b
            >&nbsp;of
            {{ getSelectionOptionCount(selectionList) }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-selection-list
          #selectionList
          [disableRipple]="true"
          (selectionChange)="onSelectionChange($event)"
        >
          <ng-container *ngFor="let permission of permissionGroup.permissions">
            <mat-list-option
              [selected]="permission.isGranted"
              [value]="permission.name"
              [disabled]="shouldDisable(permission)"
            >
              <div>{{ permission.displayName }}</div>
              <div *ngFor="let provider of permission.grantedProviders">
                <small style="font-weight: bold"
                  >{{ provider.providerName }}: {{ provider.providerKey }}</small
                >
              </div>
            </mat-list-option>
          </ng-container>
        </mat-selection-list>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- <mat-selection-list
            [disableRipple]="true"
            (selectionChange)="onSelectionChange($event)"
            *ngFor="let permissionGroup of permissionGroups"
        >
            <mat-toolbar color="primary">
                {{ permissionGroup.displayName }}
            </mat-toolbar>
            <ng-container
                *ngFor="let permission of permissionGroup.permissions"
            >
                <mat-list-option
                    [selected]="permission.isGranted"
                    [value]="permission.name"
                    [disabled]="shouldDisable(permission)"
                >
                    <div>{{ permission.displayName }}</div>
                    <div *ngFor="let provider of permission.grantedProviders">
                        <small style="font-weight: bold"
                            >{{ provider.providerName }}:
                            {{ provider.providerKey }}</small
                        >
                    </div>
                </mat-list-option>
            </ng-container>
        </mat-selection-list> -->
  </div>
</div>
