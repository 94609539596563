<div class="p-4" style="height: 440px">
  <div class="row">
    <label class="first-label" [innerHtml]="infoLabel"> </label>
  </div>

  <div class="row">
    <div class="step-item">
      <div class="first">
        <button (click)="csvFile.click()" class="btn ca-btn ca-btn-neutral-toolbar toolbar-button">
          <i class="far fa-upload"></i>
          <span>{{ 'Conversation::UploadFile' | abpLocalization }}</span>
        </button>
        <!-- <label style="padding: 5px 18px; margin: 8px 0px 0px" class="btn btn-warning" for="csvFile" class="btn btn-warning">{{ 'Conversation::UploadFile' | abpLocalization }}</label> -->
        <input
          id="csvFile"
          [hidden]="true"
          type="file"
          (click)="$event.target.value = ''"
          (change)="onFileChange($event.target.files)"
          accept="{{ acceptFileExtensions.join(',') }}"
          #csvFile />
      </div>

      <div class="second">
        <button
          class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
          (click)="downloadSampleChatMetadataCsv()">
          <i class="far fa-download"></i>
          <span>{{ 'Conversation::ExampleCsv' | abpLocalization }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <label *ngIf="selectedCsvFile?.size > 0">
        {{ '::FileName' | abpLocalization }}: {{ selectedCsvFile?.name }}
      </label>
    </div>
    <div class="col-12">
      <label *ngIf="selectedCsvFile?.size > 0">
        {{ 'Conversation::SizeOfSelectedFile' | abpLocalization }}:
        {{ (selectedCsvFile.size / 1024).toFixed(2) }} KB
      </label>
    </div>
  </div>
</div>
