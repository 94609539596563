import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Operators } from 'src/core/models/request/operator.enum';
import { TagDto } from 'src/core/models/conversation/tag/tag.dto';
import { ConversationBatchTaggingCommunicationService } from '../../conversation-batch-tagging-communication.service';
import { ConversationBatchTaggingDto } from 'src/core/models/conversation/tag/conversation-batch-tagging-filter.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';

@Component({
  selector: 'ca-conversation-batch-tagging-step-two',
  templateUrl: './conversation-batch-tagging-step-two.component.html',
  styleUrls: ['./conversation-batch-tagging-step-two.component.scss'],
})
export class ConversationBatchTaggingStepTwo implements OnInit {
  selectedTags: TagDto[];
  tagSelectorOptions: any;
  tagForm: FormGroup;
  tagSelectorFilters: FilterItemDto[] = [];
  

  constructor(
    private localizationService: LocalizationService,
    private operators: Operators,
    private communicationService: ConversationBatchTaggingCommunicationService,
    private fb: FormBuilder
  ) {  
    this.tagForm = fb.group(
      {
        tag: [null, { updateOn: 'change' }],
      },
      { updateOn: 'blur' }
    );

    this.tagSelectorOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      emptyText: this.localizationService.instant('::SelectTag'),
      multiple: true,
      url: 'api/app/tag',
      currentPage: 1,
      showSelections: false,
      showClearSelections: false,
      showClearSelectionLink: true,
      selectorPosition: 'Top',
    };

    this.tagSelectorFilters.push({
      field: 'onlyWithoutTraining',
      operator: this.operators.Equals,
      value: true,
    });

    this.tagForm.valueChanges.subscribe(val => {
      this.selectedTags = val.tag;
      this.onChange();
    });
  }

  ngOnInit(): void { 
    this.communicationService.isTagsSelected = false;
  }

  ngOnDestroy(): void {
    this.communicationService.isTagsSelected = false;
  }

  onChange() { 
    if (this.selectedTags && this.selectedTags.length > 0) {
      this.communicationService.isTagsSelected = true;
    } else {
      this.communicationService.isTagsSelected = false;
    }
    this.communicationService.tags = this.selectedTags;
  }

  validateEntryToStep() {
    return true;
  }

  validateExitFromStep() {
    return of(true);
  }
}