import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { QueryDetailBuilderComponent } from '../query/query-detail/query-detail-builder/query-detail-builder.component';
import { QueryTreeNodeFactoryComponent } from '../query/query-tree-node-factory/query-tree-node-factory.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [QueryDetailBuilderComponent, QueryTreeNodeFactoryComponent],
  imports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    QueryDetailBuilderComponent,
    QueryTreeNodeFactoryComponent,
  ],
  providers: [],
})
export class SharedModule {}
