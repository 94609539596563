<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label>{{ '(' + ('Query::SimpleQueryShortText' | abpLocalization) + ')' }}</label>
      </div>
      <ng-template #emptySpace class="col-auto">
        <label>&nbsp;</label>
      </ng-template>
      <div
        class="col-auto"
        ngbTooltip="{{ '::Term' | abpLocalization }}"
        container="body"
        placement="left auto">
        <label *ngIf="payload.not" style="font-style: italic">
          {{ 'Query::NOT' | abpLocalization }}&nbsp;
        </label>
        <label>"</label>
        <label>{{ payload.term }}</label>
        <label>"</label>
      </div>
      <div
        class="col-auto"
        *ngIf="!isLastEditor"
        ngbTooltip="{{ 'Query::MaximumDistanceToNextItem' | abpLocalization }}"
        container="body"
        placement="left auto">
        <i class="fas fa-level-down"></i>
        <label>{{ payload.maximumDistanceToNextItem }}</label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-9 row align-items-center full-height">
        <div class="col-auto">
          <label>{{ 'Query::SimpleQueryShortText' | abpLocalization }}</label>
        </div>
        <div class="col-auto" *ngIf="isLastEditor">
          <mat-slide-toggle [(ngModel)]="payload.not" formControlName="not">
            {{ 'Query::NOT' | abpLocalization }}
          </mat-slide-toggle>
        </div>
        <div
          class="col"
          ngbTooltip="{{ '::Term' | abpLocalization }}"
          container="body"
          placement="bottom">
          <input
            type="text"
            [(ngModel)]="payload.term"
            class="form-control form-control-sm"
            formControlName="term" />
        </div>
        <div class="col-auto"></div>
      </div>
      <div class="col-3 row align-items-center full-height item-divider">
        <div
          [hidden]="isLastEditor"
          class="col-auto"
          ngbTooltip="{{ 'Query::MaximumDistanceToNextItem' | abpLocalization }}"
          container="body"
          placement="bottom">
          <input
            type="number"
            [min]="minDistance"
            [max]="maxDistance"
            style="width: 75px"
            class="form-control form-control-sm"
            formControlName="maximumDistanceToNextItem"
            [(ngModel)]="payload.maximumDistanceToNextItem" />
        </div>
        <ca-query-builder-node-remove-button
          *ngIf="!isFirstTwoEditor"
          (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
      </div>
    </div>
  </div>
</ng-template>
