<ng-template #tagItemTemplate let-item="item">
  {{ item.name }}
</ng-template>
<ng-template #tagSelectionTemplate let-selection="selection">
  {{ selection[0].name }}
</ng-template>

<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ '::Tag' | abpLocalization }}</label>
      </div>

      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ payload.operator | operator }}</label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ currentTagName }}</label>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #editableContent>
  <div [class]="cssClass">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ '::Tag' | abpLocalization }}</label>
      </div>
      <div class="col-auto">
        <select class="form-select form-select-sm" [(ngModel)]="payload.operator">
          <option *ngFor="let operator of node.categoryItem.operators" [value]="operator">
            {{ operator | operator }}
          </option>
        </select>
      </div>

      <div class="col-auto" [formGroup]="tagForm">
        <ca-tag-dropdown-selector
          id="tagSelector"
          formControlName="tag"
          style="width: 350px"
          class="d-block">
        </ca-tag-dropdown-selector>
      </div>

      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>
