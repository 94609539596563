import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input, OnInit } from '@angular/core';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';

@Component({
  selector: 'ca-video-call-summary',
  templateUrl: './video-call-summary.component.html',
  styleUrls: ['./video-call-summary.component.scss'],
})
export class VideoCallSummaryComponent implements OnInit {
  userPhotoComponentStyles: any = {
    width: '64px',
    height: '64px',
    border: '2px solid rgba(0, 0, 0, .12)',
    'border-radius': '100%',
  };

  @Input()
  set conversation(value: ConversationDto) {
    this._conversation = value;
  }

  get conversationTime(): string {
    if (this._conversation?.startTime) {
      let date = new Date(this._conversation?.startTime);
      return this.caDatePipe.transform(date, DateDisplayType.DateTime, true);
    } else {
      return '';
    }
  }

  get conversation(): ConversationDto {
    if (this._conversation) {
      return this._conversation;
    }

    return new ConversationDto();
  }

  private _conversation: ConversationDto;

  constructor(private caDatePipe: CADatePipe, private toastr: ToasterService) {}

  ngOnInit(): void {}

  copyToClipboard() {
    this.toastr.success('::CopiedToClipboard', '', {});
  }
}
