import { LocalizationService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StringService {
  constructor(private localizationService: LocalizationService) {}

  toLowerForCurrentCulture(value: string): string {
    return value.toLocaleLowerCase(this.localizationService.currentLang);
  }

  toUpperForCurrentCulture(value: string): string {
    return value.toLocaleUpperCase(this.localizationService.currentLang);
  }

  indexOf(text: string, searchValue: string): number {
    const index = text
      .toLocaleLowerCase(this.localizationService.currentLang)
      .indexOf(searchValue.toLocaleLowerCase(this.localizationService.currentLang));
    return index;
  }

  getCombinedStringsMaxTwo(strings: string[]) {
    const result = strings.slice(0, 2).join(', ');
    return strings.length > 2 ? result + '...' : result;
  }

  // Copied from
  // https://en.wikibooks.org/wiki/Algorithm_Implementation/Strings/Levenshtein_distance#JavaScript
  getLevenshteinDistance(a: string, b: string): number | null {
    if (typeof a !== 'string' || typeof b !== 'string') {
      return null;
    }

    if (a.length === 0) {
      return b.length;
    }

    if (b.length === 0) {
      return a.length;
    }

    const matrix = [];

    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1
            )
          ); // deletion
        }
      }
    }

    return matrix[b.length][a.length];
  }
}
