<div class="main-container">
  <div class="row p-2">
    <div class="col-3 justify-content-start d-flex">
      <div
        style="border-width: 4px; border-style: solid; border-radius: 50px"
        [style.border-color]="photoBorderColor">
        <ca-user-photo
          #userProfilePhoto
          [useCurrentUser]="false"
          [userId]="user.id"
          [nameAndSurname]="[user.name, user.surname]"
          [classes]="['user-summary-image']">
        </ca-user-photo>
      </div>
    </div>
    <div class="summary-label-container col-9 align-self-center">
      <div
        class="summary-label user-full-name-label bold truncate"
        container="body"
        [ngbTooltip]="user.name + ' ' + user.middleName + ' ' + user.surname">
        {{ user.name }} {{ user.extraProperties.MiddleName }} {{ user.surname }}
      </div>
      <div
        class="summary-label user-full-name-label bold truncate"
        container="body"
        [ngbTooltip]="user.id">
        <small class="id-label">{{ user.id }}</small>
      </div>
    </div>
  </div>
  <mat-divider class="mt-1 mb-1"></mat-divider>
  <div class="p-2">
    <div class="row">
      <span class="col-6 bold">{{ 'User::Username' | abpLocalization }}</span>
      <span class="col-6">{{ user.userName }}</span>

      <span class="col-6 bold">{{ 'User::E-mail' | abpLocalization }}</span>
      <span class="col-6">{{ user.email }}</span>

      <span class="col-6 bold">{{ 'User::Department' | abpLocalization }}</span>
      <span class="col-6">
        {{
          user.extraProperties.Department?.length > 0 ? user.extraProperties.Department[0].name : ''
        }}
      </span>

      <span class="col-6 bold">{{ 'User::FirstDeviceId' | abpLocalization }}</span>
      <span class="col-6">{{ user.phoneNumber }}</span>

      <span class="col-6 bold">{{ 'User::SecondDeviceId' | abpLocalization }}</span>
      <span class="col-6">{{ user.extraProperties.SecondPhoneNumber }}</span>

      <span class="col-6 bold">{{ 'User::PbxAgentId' | abpLocalization }}</span>
      <span class="col-6">{{ user.extraProperties.PbxAgentId }}</span>
    </div>
  </div>
</div>
