import { downloadBlob, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { ChatService } from 'src/core/services/conversation/chat.service';
import { ConversationImportFileModel } from 'src/core/models/conversation/conversation-import-file-model';

@Component({
  selector: 'ca-chat-import-step-two',
  templateUrl: './chat-import-step-two.component.html',
  styleUrls: ['./chat-import-step-two.component.scss'],
})
export class ChatImportStepTwo implements OnInit {
  constructor(
    private chatService: ChatService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private globalSettingsService: GlobalSettingsService,
  ) {
    this.infoLabel = this.localizationService.instant('Conversation::UploadChatCsvStepInfo');
    this.infoLabel = this.infoLabel.replace('{0}', "<b>" + this.localizationService.instant('Conversation::UploadChatCsvMaxSize') + "</b>");

    this.systemLangCode = this.globalSettingsService.systemLanguageCode;
  }

  public selectedConversationFiles: ConversationImportFileModel[] = [];
  public selectedCsvFile: File;
  public systemLangCode: string;

  acceptFileExtensions: string[] = [".csv"];
  infoLabel: string = "";
  maxMetadataSize: number = 2 * 1024 * 1024;

  ngOnInit(): void { }

  validateEntryToStep() {
    return true;
  }

  validateExitFromStep() {
    return of(true);
  }

  downloadSampleChatMetadataCsv() {
    this.chatService.downloadSampleChatMetadataCsv().subscribe(result => {
      if (result.size > 0) {
        downloadBlob(result, 'sampleChatMetadataFile.csv');
      }
    });
  }

  onFileChange(files) {
    if (files.length > 0) {
      const splitName = files.item(0).name.split('.');
      if (files.item(0).size > this.maxMetadataSize) {
        this.selectedCsvFile = null;
        this.toastr.error(this.localizationService.instant('Conversation::FileSizeTooLarge'));
        return;
      }
      if (this.acceptFileExtensions.indexOf('.' + splitName[splitName.length - 1]) <= -1) {
        this.selectedCsvFile = null;
        this.toastr.error(this.localizationService.instant('::FileExtensionError', this.acceptFileExtensions.join(',')));
        return;
      }
      this.selectedCsvFile = files.item(0);
      return;
    }
    this.selectedCsvFile = null;
  }
}