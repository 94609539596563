import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Category.Sort.Type',
})
export class CategorySortType {
  @GenericLookup({
    code: 'Category.Sort.Type.ByName',
  })
  static byName: number;

  @GenericLookup({
    code: 'Category.Sort.Type.ByMatchingCount',
  })
  static byMatchingCount: number;
}
