import { Component, Input, OnInit, Type, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Store } from '@ngxs/store';
import { map, takeUntil } from 'rxjs';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';

@Component({
  selector: 'ca-generic-lookup-dropdown-selector',
  templateUrl: './generic-lookup-dropdown-selector.component.html',
  styleUrls: ['./generic-lookup-dropdown-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => GenericLookupDropdownSelectorComponent),
    },
  ],
})
@AutoUnsubscribe()
export class GenericLookupDropdownSelectorComponent
  extends HasSubscription
  implements OnInit, ControlValueAccessor
{
  @Input()
  placeholder: string;

  @Input()
  multiple: boolean = false;

  @Input()
  closeOnSelect: boolean = true;

  @Input()
  clearable: boolean = true;

  @Input()
  set genericLookupType(value: any) {
    this._genericLookupType = value;

    this.setItems();
  }

  get genericLookupType(): any {
    return this._genericLookupType;
  }

  @Input()
  compareFn: (a: unknown, b: unknown) => number;

  @Input()
  set filterFn(fn: (i: GenericLookupDto) => boolean) {
    this._filterFn = fn;

    this.setItems();
  }

  get filterFn(): (i: GenericLookupDto) => boolean {
    return this._filterFn;
  }

  value: any;
  isDisabled: boolean = false;
  items: GenericLookupDto[];

  private _genericLookupType: any;
  private _filterFn: (item: GenericLookupDto) => boolean;

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {}

  onChange(args: any) {}

  onTouched() {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private setItems(): void {
    const stateItemsFn = this.store.selectSnapshot(GenericLookupTypeState.getGenericLookups);

    this.items = stateItemsFn(this.genericLookupType);

    if (this.filterFn) {
      this.items = this.items.filter(this.filterFn);
    }

    if (this.compareFn) {
      this.items = this.items.sort(this.compareFn);
    }
  }
}
