import { CategoryMarkerDto } from "./category-marker.dto";

export class PlayerTimelineCategoryMarkerGroupDto {
  color: string;
  text: string;
  startMillisecond: number;
  endMillisecond: number;
  class: string;
  markers: CategoryMarkerDto[] = [];
  position: number;
  isQuickSearchMarker?: boolean;
  isAIGeneratedMarker?: boolean;
  isCommentMarker?: boolean;
}
