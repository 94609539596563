import { Component, Injector, OnInit } from '@angular/core';
import { ToasterService } from '@abp/ng.theme.shared';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
import { LocalizationService } from '@abp/ng.core';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';

@Component({
  selector: 'ca-conversation-delete-completed-notification',
  templateUrl: './conversation-delete-completed-notification.component.html',
  styleUrls: ['./conversation-delete-completed-notification.component.scss'],
})
@NotificationType(
  'ConversationDeleteCompletedNotification',
  ConversationDeleteCompletedNotificationComponent
)
export class ConversationDeleteCompletedNotificationComponent implements OnInit {
  data: NotificationDto;
  private static t: ConversationDeleteCompletedNotificationComponent;
  constructor(
    public localizationService: LocalizationService,
    private toastr: ToasterService,
    private notificationService: NotificationService,
    private caDatePipe: CADatePipe
  ) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe
  ) {
    let parsedPayload = JSON.parse(payload);
    var filterValue = '';
    var filterLabel = '';
    var noCriteria = false;
    if (parsedPayload.uniqueCustomerKey) {
      filterLabel = localizationService.instant('Conversation::UniqueCustomerKey');
      filterValue = parsedPayload.uniqueCustomerKey;
    } else if (parsedPayload.calledOrCallerNumber) {
      filterLabel = localizationService.instant('Conversation::CalledOrCallerNumber');
      filterValue = parsedPayload.calledOrCallerNumber;
    } else {
      noCriteria = true;
    }

    let message = '';

    if (noCriteria) {
      message = localizationService.instant(
        'Conversation::DeleteCompletedMessageWithoutCriteria',
        parsedPayload.deletedCount,
        caDatePipe.transform(parsedPayload.startTime, DateDisplayType.Date),
        caDatePipe.transform(parsedPayload.endTime, DateDisplayType.Date)
      );
    } else {
      message = localizationService.instant(
        'Conversation::DeleteCompletedMessage',
        parsedPayload.deletedCount,
        caDatePipe.transform(parsedPayload.startTime, DateDisplayType.Date),
        caDatePipe.transform(parsedPayload.endTime, DateDisplayType.Date),
        filterLabel,
        filterValue
      );
    }

    if (parsedPayload.errorCount > 0) {
      message +=
        ' ' +
        localizationService.instant(
          'Conversation::DeleteCompletedErrorMessage',
          parsedPayload.errorCount
        );
    }

    if (parsedPayload.errorCount > 0) {
      toastr.warn(message);
    } else {
      toastr.success(message);
    }
  }

  getMessage(payload, localizationService) {
    const parsedPayload = JSON.parse(payload);
    var filterValue = '';
    var filterLabel = '';
    var noCriteria = false;
    if (parsedPayload.uniqueCustomerKey) {
      filterLabel = localizationService.instant('Conversation::UniqueCustomerKey');
      filterValue = parsedPayload.uniqueCustomerKey;
    } else if (parsedPayload.calledOrCallerNumber) {
      filterLabel = localizationService.instant('Conversation::CalledOrCallerNumber');
      filterValue = parsedPayload.calledOrCallerNumber;
    } else {
      noCriteria = true;
    }

    let message = '';

    if (noCriteria) {
      message = localizationService.instant(
        'Conversation::DeleteCompletedMessageWithoutCriteria',
        parsedPayload.deletedCount,
        this.caDatePipe.transform(parsedPayload.startTime, DateDisplayType.Date),
        this.caDatePipe.transform(parsedPayload.endTime, DateDisplayType.Date)
      );
    } else {
      message = localizationService.instant(
        'Conversation::DeleteCompletedMessage',
        parsedPayload.deletedCount,
        this.caDatePipe.transform(parsedPayload.startTime, DateDisplayType.Date),
        this.caDatePipe.transform(parsedPayload.endTime, DateDisplayType.Date),
        filterLabel,
        filterValue
      );
    }

    if (parsedPayload.errorCount > 0) {
      message +=
        ' ' +
        localizationService.instant(
          'Conversation::DeleteCompletedErrorMessage',
          parsedPayload.errorCount
        );
    }

    return message;
  }
}
