<a class="btn" [routerLink]="['/notifications']">
  <i *ngIf="unreadNotificationCount <= 0" class="far fa-bell fa-lg"></i>
  <i *ngIf="unreadNotificationCount > 0" class="fas fa-bell fa-lg"></i>
  <span
    *ngIf="unreadNotificationCount > 0"
    class="position-absolute translate-middle badge rounded-pill bg-danger"
    style="color: white; top: 3px; right: 0px; font-size: 6pt"
  >
    {{ unreadNotificationCount > 99 ? '99+' : unreadNotificationCount }}
  </span>
</a>
