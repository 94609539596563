import { LayoutConfig } from 'src/core/models/split/layout-config.model';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConversationListResponseDto } from 'src/core/models/conversation/conversation-list-response.dto';
import { Sort } from '@angular/material/sort';

export class LayoutChange {
  static readonly type = '[ConversationModule] LayoutChange';

  constructor(public payload: LayoutConfig) {}
}

export class ActiveListChange {
  static readonly type = '[ConversationModule] ActiveListChange';

  constructor(public payload: number) {}
}

export class LayoutReset {
  static readonly type = '[ConversationModule] LayoutReset';

  constructor() {}
}

export class ViewOptionsChange {
  static readonly type = '[ConversationModule] ViewOptionsChange';

  constructor(public payload: GenericLookupDto[]) {}
}

export class ViewChange {
  static readonly type = '[ConversationModule] ViewChange';

  constructor(public payload: number) {}
}

export class DataRequest {
  static readonly type = '[ConversationModule] DataRequest';

  constructor(public pageIndex: number = 0) {}
}

export class DataChange {
  static readonly type = '[ConversationModule] DataChange';

  constructor(public payload: ConversationListResponseDto) {}
}

export class FilterChange {
  static readonly type = '[ConversationModule] FilterChange';

  constructor(public filterFormValues: any) {}
}

export class SorterChange {
  static readonly type = '[ConversationModule] SorterChange';

  constructor(public sort: Sort) {}
}

export class SpeedChange {
  static readonly type = '[ConversationModule] SpeedChange';

  constructor(public speed: number) {}
}

export class CompactPlayerPositionChange {
  static readonly type = '[ConversationModule] CompactPlayerPositionChange';

  constructor(public conversationId: number, public position: number) {}
}

export class CacheOptionChange {
  static readonly type = '[ConversationModule] CacheOptionChange';

  constructor(public getCachedData: boolean) {}
}

export class VolumeChange {
  static readonly type = '[ConversationModule] VolumeChange';

  constructor(public volume: number) {}
}

export class BeforeVolumeChange {
  static readonly type = '[ConversationModule] BeforeVolumeChange';

  constructor(public beforeVolume: number) {}
}

export class ListeningStatusChange {
  static readonly type = '[ConversationModule] ListeningStatusChange';
  constructor(public listeningStatus: number, public conversationId: number) {}
}

export class ConversationScrollChange {
  static readonly type = '[ConversationModule] ConversationScrollChange';

  constructor(public lastScrollPosition: number = 0) {}
}

export class SavePage {
  static readonly type = '[ConversationModule] SavePage';

  constructor(public pageIndex: number = 0) {}
}
