import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  AllMarkedAsRead,
  NotificationInitialized,
  NotificationReceived,
  NotificationStatusChanged,
} from 'src/core/actions/notification/notification.actions';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { NotificationCountSummaryDto } from 'src/core/models/notification/notification-count-summary.dto';
import { NotificationStateModel } from 'src/core/models/notification/notification.state-model';

@State<NotificationStateModel>({
  name: 'NotificationState',
  defaults: {
    initialized: false,
    countSummary: { unread: 0, total: 0, recent: 0 },
  } as NotificationStateModel,
})
@Injectable()
export class NotificationState {
  @Selector()
  static getCountSummary(state: NotificationStateModel): NotificationCountSummaryDto {
    return state.countSummary;
  }

  @Action(NotificationInitialized)
  initializeNotification(
    ctx: StateContext<NotificationStateModel>,
    action: NotificationInitialized
  ) {
    const currentState = ctx.getState();

    currentState.countSummary = ObjectHelper.deepCopy(action.countSummary);
    currentState.initialized = true;

    ctx.patchState(currentState);
  }

  @Action(NotificationReceived)
  actionForNotificationReceived(
    ctx: StateContext<NotificationStateModel>,
    action: NotificationReceived
  ): void {
    const currentState = ctx.getState();

    currentState.countSummary = ObjectHelper.deepCopy(currentState.countSummary);

    this.updateUnreadNotificationCount(currentState.countSummary, 1);
    this.updateRecentNotificationCount(currentState.countSummary, 1);
    this.updateTotalNotificationCount(currentState.countSummary, 1);
    ctx.patchState(currentState);
  }

  @Action(NotificationStatusChanged)
  actionForNotificationStatusChanged(
    ctx: StateContext<NotificationStateModel>,
    action: NotificationStatusChanged
  ): void {
    const currentState = ctx.getState();
    const increment = action.newStatus ? -1 : 1;

    currentState.countSummary = ObjectHelper.deepCopy(currentState.countSummary);

    this.updateUnreadNotificationCount(currentState.countSummary, increment);

    ctx.patchState(currentState);
  }

  @Action(AllMarkedAsRead)
  updateNotificationCount(
    ctx: StateContext<NotificationStateModel>,
    action: NotificationStatusChanged
  ): void {
    const currentState = ctx.getState();

    currentState.countSummary = ObjectHelper.deepCopy(currentState.countSummary);

    this.updateUnreadNotificationCount(currentState.countSummary, 0);

    ctx.patchState(currentState);
  }

  // 0 means, all marked as readonly
  private updateUnreadNotificationCount(countSummary: NotificationCountSummaryDto, count: number) {
    countSummary.unread = count == 0 ? 0 : countSummary.unread + count;
  }

  private updateRecentNotificationCount(countSummary: NotificationCountSummaryDto, count: number) {
    countSummary.recent = countSummary.recent + count;
  }

  private updateTotalNotificationCount(countSummary: NotificationCountSummaryDto, count: number) {
    countSummary.total = countSummary.total + count;
  }
}
