import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConversationBatchTaggingCommunicationService } from '../../conversation-batch-tagging-communication.service';
import { DateSelectionConfigurationModel } from 'src/ca-shared/filter-panel/filter-panel.module';
import { Editor } from 'src/ca-shared/filter-panel/models/editor.enum';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { Operators } from 'src/core/models/request/operator.enum';
import { AttachedDataDto } from 'src/core/models/administration/attached-data/attached-data.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConversationBatchTaggingDto } from 'src/core/models/conversation/tag/conversation-batch-tagging-filter.dto';
import { DateSelectionPanelComponent } from 'src/ca-shared/filter-panel/children/date-selection/date-selection-panel/date-selection-panel.component';
import { DateSelectionValueModel } from 'src/ca-shared/filter-panel/children/date-selection/models/date-selection-value.model';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'ca-conversation-batch-tagging-step-one',
  templateUrl: './conversation-batch-tagging-step-one.component.html',
  styleUrls: ['./conversation-batch-tagging-step-one.component.scss'],
})
@AutoUnsubscribe()
export class ConversationBatchTaggingStepOne extends HasSubscription implements OnInit {
  @ViewChild('fileInput')
  fileInputVariable: ElementRef;

  @ViewChild('dateSelector')
  dateSelectionPanel: DateSelectionPanelComponent;

  public selectedCsvFile: File;
  acceptFileExtensions: string[] = ['.csv'];
  fileList: FileList;
  maxCsvFileSize: number = 1 * 1024 * 1024;
  strMaxCsvFileSize: string = '0';

  dateSelectionConfiguration = {
    key: 'date',
    editor: Editor.Date,
    minDate: this.globalSettingsService.systemStartDate,
    maxDate: this.globalSettingsService.systemEndDate,
    useNoEndDate: false,
    useShortcuts: false,
    defaultValue: {
      shortcut: 0,
      start: null,
      end: null,
    },
  };

  attachedData: AttachedDataDto[] = [];
  attachedDataSelectorOptions: any;
  attachedDataSelectorFilters: FilterItemDto[] = [];
  attachedDataForm: FormGroup;
  selectedAttachedData: any;
  isAttachedData: boolean = true;

  constructor(
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private globalSettingsService: GlobalSettingsService,
    private operators: Operators,
    private fb: FormBuilder,
    private communicationService: ConversationBatchTaggingCommunicationService
  ) {
    super();

    this.strMaxCsvFileSize = this.localizationService.instant(
      'Conversation::UploadTaggingCsvMaxSize'
    );

    this.attachedDataForm = fb.group(
      {
        attachedData: [null, { validators: [Validators.required], updateOn: 'change' }],
      },
      { updateOn: 'blur' }
    );

    this.attachedDataSelectorOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      emptyText: this.localizationService.instant('::SelectAttachedData'),
      multiple: false,
      url: AttachedDataDto.apiUrl,
      currentPage: 1,
      showSelections: true,
      selectorPosition: 'Top',
    };

    this.attachedDataSelectorFilters.push({
      field: 'visible',
      operator: this.operators.Equals,
      value: true,
    });

    this.attachedDataForm
      .get('attachedData')
      .valueChanges.pipe(takeUntil(this.autoUnsubscribeNotifier))
      .subscribe(this.onDropdownChange.bind(this));
  }

  ngOnInit(): void {
    this.communicationService.isFilesSelected = false;
    this.communicationService.isValid = false;
  }

  ngOnDestroy(): void {
    this.communicationService.isFilesSelected = false;
    this.communicationService.isValid = false;
  }

  ngAfterViewInit(): void {
    const today = new Date();
    const dateValue: DateSelectionValueModel = {
      shortcut: null,
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0),
      end: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999),
      noEndDate: true,
    };

    this.dateSelectionPanel.value = dateValue;
  }

  onFileChange(files) {
    if (files.length > 0) {
      const splitName = files.item(0).name.split('.');
      if (files.item(0).size > this.maxCsvFileSize) {
        this.selectedCsvFile = null;
        this.toastr.error(this.localizationService.instant('Conversation::FileSizeTooLarge'));
        return;
      }
      if (this.acceptFileExtensions.indexOf('.' + splitName[splitName.length - 1]) <= -1) {
        this.selectedCsvFile = null;
        this.toastr.error(
          this.localizationService.instant(
            '::FileExtensionError',
            this.acceptFileExtensions.join(',')
          )
        );
        return;
      }
      this.selectedCsvFile = files.item(0);
      this.communicationService.isFilesSelected = true;
      if (this.selectedAttachedData != null || !this.isAttachedData) {
        this.communicationService.isValid = true;
      }
      return;
    }
    this.selectedCsvFile = null;
  }

  onDropdownChange() {
    if (this.attachedDataForm.get('attachedData').value) {
      this.selectedAttachedData = this.attachedDataForm.get('attachedData').value;
      if (this.selectedCsvFile != null) {
        this.communicationService.isValid = true;
      }
    } else {
      this.communicationService.isValid = false;
    }
  }

  validateEntryToStep() {
    return true;
  }

  validateExitFromStep() {
    if (
      this.selectedCsvFile != null &&
      (this.selectedAttachedData != null || !this.isAttachedData)
    ) {
      return true;
    } else {
      return false;
    }
  }

  onTaggingCriteriaTypeChange(eventArgs: MouseEvent) {
    const chk = eventArgs.target as HTMLInputElement;

    this.isAttachedData = chk.value === 'AttachedDataRadioValue';

    if (!this.isAttachedData) {
      this.selectedAttachedData = null;
      this.attachedDataForm.controls['attachedData'].setValue(null);
      if (this.selectedCsvFile != null) {
        this.communicationService.isValid = true;
      }
    } else {
      this.communicationService.isValid = false;
    }
  }

  getData(): ConversationBatchTaggingDto {
    let data = new ConversationBatchTaggingDto();
    data.startTime = this.dateSelectionPanel.value.start;
    data.endTime = this.dateSelectionPanel.value.end;
    data.taggingCriteria = this.selectedAttachedData;
    data.file = this.selectedCsvFile;

    return data;
  }
}
