import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  constructor(private http: HttpClient) {}

  getPeaks(url: string): Observable<any> {
    return this.http.get(url, { headers: { 'hide-errors': 'true' } });
  }
}
