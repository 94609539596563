import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Query.RangeUnit',
})
export class QueryRangeUnit {
    @GenericLookup({
        code: 'Query.RangeUnit.Seconds',
    })
    static seconds: number;

    @GenericLookup({
        code: 'Query.RangeUnit.Percentage',
    })
    static percentage: number;
}
