import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
@Component({
  selector: 'ca-evaluator-performance-export-failed-notification',
  templateUrl: './evaluator-performance-export-failed-notification.component.html',
  styleUrls: ['./evaluator-performance-export-failed-notification.component.scss'],
})
@NotificationType(
  'EvaluatorPerformanceExportFailedNotification',
  EvaluatorPerformanceExportFailedNotificationComponent
)
export class EvaluatorPerformanceExportFailedNotificationComponent implements OnInit {
  data: NotificationDto;

  constructor() {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService
  ) {
    var toastrId = toastr.error('Dashboard::EvaluatorPerformanceReportFailedMessage');

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap
      .pipe(take(1))
      .subscribe(() => this.toasterClickedHandler(notificationService, notificationId));
  }

  static toasterClickedHandler(notificationService: NotificationService, notificationId: string) {
    notificationService.markAsRead(notificationId).subscribe();
  }
}
