import { Injectable } from '@angular/core';
import { GlobalSettingsService } from '../settings/global-settings.service';

@Injectable({
  providedIn: 'root',
})
export class TimeZoneHelper {
  private readonly difference = 60000;

  constructor(private globalSettingsService: GlobalSettingsService) {}

  GetSystemTimeZoneConvertedDate(date: Date): Date {
    return new Date(
      date.getTime() -
        (this.GetSystemTimeZoneOffsetInMinutes() + date.getTimezoneOffset()) * this.difference
    );
  }

  GetTimeZoneConvertedDate(date: Date, timeZone: string): Date {
    return new Date(
      date.getTime() -
        (this.GetTimeZoneOffsetInMinutes(timeZone) + date.getTimezoneOffset()) * this.difference
    );
  }

  GetSystemTimeZoneConvertedDateInverse(date: Date): Date {
    return new Date(
      date.getTime() +
        (this.GetSystemTimeZoneOffsetInMinutes() + date.getTimezoneOffset()) * this.difference
    );
  }

  GetTimeZoneConvertedDateInverse(date: Date, timeZone: string): Date {
    return new Date(
      date.getTime() +
        (this.GetTimeZoneOffsetInMinutes(timeZone) + date.getTimezoneOffset()) * this.difference
    );
  }

  GetSystemTimeZoneOffsetInMinutes(): number {
    var systemTimeZone = this.globalSettingsService.systemTimeZone;
    return this.GetTimeZoneOffsetInMinutes(systemTimeZone);
  }

  GetTimeZoneOffsetInMinutes(timeZone: string): number {
    var minutes = 0;

    if (this.CheckTimeZoneFormat(timeZone)) {
      var sign = timeZone.slice(0, 1);

      var t = timeZone.slice(1, 3);
      var hour = Number(timeZone.slice(1, 3));

      if (!isNaN(hour)) {
        minutes += hour * 60;
      }

      var k = timeZone.slice(3, 5);
      var minute = Number(timeZone.slice(3, 5));

      if (!isNaN(minute)) {
        minutes += minute;
      }

      minutes = sign == '-' ? -minutes : minutes;
    }

    return minutes;
  }

  CheckTimeZoneFormat(timeZone: string): boolean {
    if (timeZone != null && timeZone.length == 5) {
      var sign = timeZone.slice(0, 1);

      if (sign != '+' && sign != '-') {
        return false;
      }

      if (isNaN(Number(timeZone.slice(1, 5)))) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  }
}
