<div class="p-2 h-440" (change)="onImportTypeChange()">
  <div>
    <input
      id="callRadio"
      type="radio"
      value="Call"
      class="me-1 ms-1"
      name="conversationType"
      [checked]="isCallImport"
    />
    <label for="callRadio" class="radio-label me-1 ms-1">
      {{ '::Call' | abpLocalization }}
    </label>
  </div>
  <div>
    <input
      id="chatRadio"
      type="radio"
      value="Chat"
      name="conversationType"
      class="me-1 ms-1"
      [checked]="!isCallImport"
    />
    <label for="chatRadio" class="radio-label me-1 ms-1">
      {{ '::Chat' | abpLocalization }}
    </label>
  </div>
</div>
