import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';

@Component({
  selector: 'ca-batch-tagging-completed-notification',
  templateUrl: './batch-tagging-completed-notification.component.html',
  styleUrls: ['./batch-tagging-completed-notification.component.scss'],
})
@NotificationType(
  'BatchTaggingCompletedNotification',
  BatchTaggingCompletedNotificationComponent
)
export class BatchTaggingCompletedNotificationComponent implements OnInit {
  data: NotificationDto;

  get tagNames(): string {
    return this.data ? JSON.parse(this.data.payload).tagNames : '';
  }

  constructor() {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
  ) {
    const parsedPayload = JSON.parse(payload);
    let message = localizationService.instant(
      'Conversation::BatchTaggingCompletedMessage',
      parsedPayload.tagNames
    );
    toastr.success(message);
  }
}
