import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { Store } from '@ngxs/store';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { SearchBoxComponent } from 'src/ca-shared/search-box/search-box.component';

@Component({
  selector: 'ca-user-group-list-toolbar',
  templateUrl: './user-group-list-toolbar.component.html',
  styleUrls: ['./user-group-list-toolbar.component.scss'],
})
export class UserGroupListToolbarComponent implements OnInit {
  @Output()
  addNewClick = new EventEmitter();

  @Output()
  dataRequest: EventEmitter<{
    filters: FilterItemDto[];
    sorters: SorterItemDto[];
  }> = new EventEmitter();

  @ViewChild('searchBox', { static: true, read: SearchBoxComponent })
  searchBox: SearchBoxComponent;

  toolbarForm = null;

  doSearch() {
    const filters: FilterItemDto[] = [];
    const sorters: SorterItemDto[] = [];
    const formValues = this.toolbarForm.value;

    if (formValues.quickSearchTerm != null && formValues.quickSearchTerm.trim().length > 0) {
      filters.push({
        field: 'quickSearchTerm',
        operator: this.operators.Contains,
        value: this.toolbarForm.get('quickSearchTerm').value,
      });
    }

    filters.push({
      field: 'includePassives',
      operator: this.operators.Equals,
      value: formValues.includePassives,
    });

    sorters.push({
      field: 'LastModificationTime',
      direction: 'DESC',
    });

    this.dataRequest.emit({
      filters,
      sorters,
    });
  }

  addNew() {
    this.addNewClick.emit();
  }

  onFocusQuickSearchInput(eventArgs: { currentTarget: HTMLInputElement }) {
    eventArgs.currentTarget.classList.add('focused');
  }

  onBlurQuickSearchInput(eventArgs: { currentTarget: HTMLInputElement }) {
    if (eventArgs.currentTarget.value.length <= 0) {
      eventArgs.currentTarget.classList.remove('focused');
    }
  }

  onIncludePassivesChange() {
    this.doSearch();
  }

  constructor(private fb: FormBuilder, private store: Store, private operators: Operators) {
    this.toolbarForm = this.fb.group({
      quickSearchTerm: [null],
      includePassives: [false],
    });
  }

  ngAfterViewInit() {
    this.doSearch();
  }

  disableSearchProcessing() {
    this.searchBox.processing = false;
  }

  ngOnInit() {}
}
