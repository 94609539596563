<a class="dropdown-item pointer" (click)="openVersionModal(versionInfoModal)">{{
  '::About' | abpLocalization
}}</a>

<ng-template #versionInfoModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex justify-content-center">
      <abp-logo></abp-logo>
    </h4>
  </div>
  <div class="modal-body">
    <ca-version-info></ca-version-info>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('cancelled')">
      <i class="fas fa-times"></i> {{ 'AbpUi::Close' | abpLocalization }}
    </button>
  </div>
</ng-template>
