import { ConfigStateService, RoutesService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SettingService {
    constructor(
        private config: ConfigStateService,
        private routesService: RoutesService,
    ) { }

    checkRoutesForSettings(): void {
        const flatItems = this.routesService.flat;

        for (let i = 0; i < flatItems.length; i++) {
            const item = flatItems[i] as any;

            if (Array.isArray(item.forSettings)) {
                const isEnabled = this.isEnabled(item.forSettings);

                if (!isEnabled) {
                    this.routesService.remove([item.name]);
                }
            }
        }
    }

    isEnabled(settings: string | string[]): boolean {
        let result = true;

        if (!Array.isArray(settings)) {
            settings = [settings];
        }

        if (!Array.isArray(settings) || settings.length <= 0) {
            result = false;
        } else {
            for (let i = 0; i < settings.length; i++) {
                let settingEnabled = false;

                try {
                    settingEnabled = JSON.parse(this.config.getSetting(settings[i]).toLowerCase());
                } catch (e) {
                    settingEnabled = false;
                }

                result = result && settingEnabled;
            }
        }

        return result;
    }
}
