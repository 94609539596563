<div
  *ngIf="(dropdownLanguages$ | async)?.length > 0"
  class="dropdown"
  ngbDropdown
  #languageDropdown="ngbDropdown"
  display="static"
>
  <a
    ngbDropdownToggle
    class="nav-link"
    href="javascript:void(0)"
    role="button"
    id="dropdownMenuLink"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <i class="far fa-globe-americas fa-lg"></i>
    <span class="ms-1">{{ defaultLanguage$ | async }}</span>
  </a>
  <div
    class="dropdown-menu dropdown-menu-right border-0 shadow-sm"
    aria-labelledby="dropdownMenuLink"
    [class.d-block]="smallScreen && languageDropdown.isOpen()"
    ngbDropdownMenu
  >
    <a
      *ngFor="let lang of dropdownLanguages$ | async"
      href="javascript:void(0)"
      class="dropdown-item"
      (click)="onChangeLang(lang.cultureName)"
      >{{ lang?.displayName }}</a
    >
  </div>
</div>
