<div class="p-4" style="height: 440px">
  <div class="row">
    <label [innerHtml]="this.infoLabel" style="text-align: justify"></label>
    <div style="margin-top: 8px">
      <button
        class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
        type="button"
        (click)="fileInput.click()">
        <i class="far fa-upload"></i>
        <span>{{ 'Conversation::UploadFile' | abpLocalization }}</span>
      </button>
      <label class="btn btn-warning" for="fileSelector" style="display: none">
        <input
          #fileInput
          id="fileSelector"
          type="file"
          class="d-none"
          multiple
          (click)="$event.target.value = ''"
          (change)="onFileChange($event.target.files)"
          accept="{{ acceptFileExtensions.join(',') }}" />
        {{ 'Conversation::UploadFile' | abpLocalization }}
      </label>
    </div>
  </div>
  <div class="row" style="margin-top: 8px">
    <div class="col">
      <div class="row">
        <label *ngIf="this.selectedFileSizes > 0">
          {{ 'Conversation::CallFiles' | abpLocalization }}:
          {{ this.selectedConversationFiles.length }}
        </label>
      </div>
      <div class="row">
        <label *ngIf="this.selectedFileSizes > 0">
          {{ '::SizeOfSelectedFiles' | abpLocalization }}:
          {{ this.selectedFileSizes.toFixed(2) }} MB
        </label>
      </div>
    </div>
  </div>

  <div class="row" style="height: 252px; overflow: auto; margin-top: 8px">
    <table
      class="table table-sm"
      style="height: max-content !important; margin-bottom: 0px"
      *ngIf="this.selectedFileSizes > 0">
      <thead>
        <tr>
          <th scope="col">{{ '::FileName' | abpLocalization }}</th>
          <th scope="col">{{ 'Conversation::Size' | abpLocalization }}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of selectedConversationFiles">
          <td class="table-row">
            <p container="body" [ngbTooltip]="data.file.name" class="truncate">
              {{ data.file.name }}
            </p>
            <p *ngIf="!data.isValid" style="color: red; margin: 0 !important">
              {{ data.invalidMessage }}
            </p>
          </td>
          <td class="table-row">
            <p style="margin: 0 !important">{{ getSize(data.file.size).toFixed(2) }} MB</p>
          </td>
          <td class="table-row">
            <button class="btn btn-sm ca-btn ca-btn-neutral-command" (click)="removeFile(data)">
              <ca-delete-icon></ca-delete-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
