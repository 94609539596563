<div class="input-wrapper">
  <i [ngClass]="processing ? 'fas fa-spinner fa-spin' : 'fas fa-search'"></i>
  <input
    type="text"
    [placeholder]="placeholder"
    class="form-control search-box"
    [(ngModel)]="value"
    [attr.style]="styles"
    [disabled]="processing"
    (focus)="onFocusQuickSearchInput($event)"
    (blur)="onBlurQuickSearchInput($event)"
    (keyup)="onKeyUp($event)"
  />
</div>
