<div class="main-container">
  <div class="row pb-2">
    <div class="col-3">
      <ca-user-photo
        [useCurrentUser]="false"
        [userId]="conversation?.userId"
        [alt]="'user'"
        [classes]="['user-avatar']"
        [styles]="userPhotoComponentStyles">
      </ca-user-photo>
    </div>
    <div class="col-8 summary-label-container">
      <div class="summary-label user-full-name-label">{{ conversation?.userName }}</div>
      <div class="summary-label department-label">{{ conversation?.departmentName }}</div>
      <div class="summary-label conversation-id-label">{{ conversation?.id }}</div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="d-flex flex-row">
    <span class="col-4"></span>
    <ca-conversation-icon-container
      class="container-class col-8"
      [conversation]="conversation"
      [inDetail]="true"></ca-conversation-icon-container>
  </div>
  <mat-divider></mat-divider>
  <div class="row p-2 text-start">
    <span class="col-4 bold mb-1">{{ '::Time' | abpLocalization }}</span>
    <span class="col-8">{{ conversationTime }}</span>

    <span [hidden]="!isCall" class="col-4 bold mb-1">{{ '::Direction' | abpLocalization }}</span>
    <span [hidden]="!isCall" class="col-8">
      {{ isCall ? ('GenericLookup::' + conversation?.call.directionCode | abpLocalization) : '' }}
    </span>

    <span class="col-4 bold mb-1">{{ '::Channel' | abpLocalization }}</span>
    <span class="col-8">{{ 'GenericLookup::' + conversation?.channelName | abpLocalization }}</span>

    <span class="col-4 bold mb-1">{{ '::Duration' | abpLocalization }}</span>
    <span class="col-8">{{ conversation?.duration | caTimePipe : { showShortcuts: true } }}</span>

    <span class="col-4 bold mb-1">{{ '::Source' | abpLocalization }}</span>
    <span class="col-8">{{ conversation?.conversationSourceName }}</span>

    <span class="col-4 bold mb-1 text-truncate">{{
      'Conversation::ExternalId' | abpLocalization
    }}</span>
    <span class="col-8 text-truncate">
      {{ conversation?.externalId }}
      <span [hidden]="!conversation?.externalId">
        <i
          class="fas fa-copy text-primary clipboard-link"
          [cdkCopyToClipboard]="conversation?.externalId"
          (click)="copyToClipboard()"
          title="{{ 'Conversation::CopyExternalIdtoClipboard' | abpLocalization }}"></i>
      </span>
    </span>
    <span class="col-12">
      <ca-conversation-category-panel
        (categorySelectionChanged)="onCategorySelectionChanged($event)"
        [categories]="categories"></ca-conversation-category-panel>
    </span>
  </div>
</div>
