import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-gen-ai-project-ready-notification',
  templateUrl: './gen-ai-project-ready-notification.component.html',
  styleUrls: ['./gen-ai-project-ready-notification.component.scss'],
})
@NotificationType('GenAIProjectReadyNotification', GenAiProjectReadyNotificationComponent)
export class GenAiProjectReadyNotificationComponent implements OnInit {
  data: NotificationDto;

  get payloadData(): any {
    return JSON.parse(this.data?.payload);
  }

  constructor(private notificationService: NotificationService, private router: Router) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'Analysis::GenAI.Project.ReadyNotificationMessage',
      parsedPayload.name
    );

    var toastrId = toastr.success(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(notificationService, notificationId, parsedPayload, router);
    });
  }

  static toasterClickedHandler(
    notificationService: NotificationService,
    notificationId: string,
    payload: any,
    router: Router
  ) {
    notificationService.markAsRead(notificationId).subscribe();
    this.navigateToGenAIProject(payload, router);
  }

  static navigateToGenAIProject(payloadData: any, router: Router) {
    const queryParams: any = {
      goToResponse: 'true',
    };

    router.navigate(['analysis', 'gen-ai-project', payloadData.id.toString()], {
      queryParams: queryParams,
    });
  }

  onClick() {
    GenAiProjectReadyNotificationComponent.navigateToGenAIProject(this.payloadData, this.router);
    if (!this.data.markedAsRead) {
      this.notificationService.markAsRead(this.data.id).subscribe();
    }
  }
}
