<div class="col">
  <table mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let gridColumn of gridColumns">
      <ng-container
        *ngIf="gridColumn.columnName !== 'menu' && gridColumn.columnName !== 'icons'"
        matColumnDef="{{ gridColumn.columnName }}"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width"
        >
          {{ gridColumn.header }}
        </th>
        <td mat-cell *matCellDef="let reason" [innerHtml]="reason | objectparser: gridColumn"></td>
      </ng-container>
      <ng-container
        *ngIf="gridColumn.columnName === 'menu'"
        matColumnDef="{{ gridColumn.columnName }}"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width"
        >
          {{ gridColumn.header }}
        </th>
        <td mat-cell *matCellDef="let reason">
          <input type="checkbox" id="{{ reason.id }}" />
        </td>
      </ng-container>
      <ng-container
        *ngIf="gridColumn.columnName === 'icons'"
        matColumnDef="{{ gridColumn.columnName }}"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.width]="gridColumn.width"
          [style.minWidth]="gridColumn.width"
          [style.maxWidth]="gridColumn.width"
        >
          {{ gridColumn.header }}
        </th>
        <td mat-cell *matCellDef="let reason"></td>
      </ng-container>
    </ng-container>
    <!-- Info column -->
    <ng-container matColumnDef="info">
      <td
        mat-footer-cell
        *matFooterCellDef
        style="text-align: left"
        [attr.colspan]="displayedColumns.length"
      >
        <i class="fas fa-info-circle me-1 ms-1"></i>
        <span class="ms-2"> {{ data.length }} / {{ totalCount }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      class="reason-row-{{ myRowData.id }}"
      (click)="selectReason($event, myRowData)"
      *matRowDef="let myRowData; columns: displayedColumns"
    ></tr>
    <tr mat-footer-row *matFooterRowDef="['info']; sticky: true"></tr>
  </table>
</div>
