import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'ConversationMark.Type'
})
export class ConversationMarkType {
    @GenericLookup({
        code: 'ConversationMark.Type.Block'
    })
    static block: number;

    @GenericLookup({
        code: 'ConversationMark.Type.Emotion'
    })
    static emotion: number;

    @GenericLookup({
        code: 'ConversationMark.Type.Hesitation'
    })
    static hesitation: number;

    @GenericLookup({
        code: 'ConversationMark.Type.Overlap'
    })
    static overlap: number;

    @GenericLookup({
        code: 'ConversationMark.Type.Silence'
    })
    static silence: number;

    @GenericLookup({
        code: 'ConversationMark.Type.MaxSilence'
    })
    static maxSilence: number;

    @GenericLookup({
        code: 'ConversationMark.Type.Hold'
    })
    static hold: number;

    @GenericLookup({
        code: 'ConversationMark.Type.MaxOverlap'
    })
    static maxOverlap: number;

    @GenericLookup({
        code: 'ConversationMark.Type.PositiveSentiment'
    })
    static positiveSentiment: number;

    @GenericLookup({
        code: 'ConversationMark.Type.NegativeSentiment'
    })
    static negativeSentiment: number;

    @GenericLookup({
        code: 'ConversationMark.Type.NeutralSentiment'
    })
    static neutralSentiment: number;

    @GenericLookup({
        code: 'ConversationMark.Type.IVRHold'
    })
    static IVRHold: number;
}
