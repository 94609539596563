import { EntityDto } from '../shared/entity.dto';

export class NearQueryItemDto extends EntityDto {
  internalId: number;
  parentInternalId: number;
  categoryId: number;
  categoryItemId: number;
  not: boolean;
  firstTerm: string;
  firstOperator: number;
  secondTerm: string;
  secondOperator: number;
  maximumDistance: number;
  searchInOrder: boolean;
  queryId: number;
  sideId: number;
  sideCode: string;
}
