import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { CompactPlayerComponent } from 'src/ca-shared/player/compact-player/compact-player.component';

@Component({
  selector: 'ca-conversation-meeting-list',
  templateUrl: './conversation-meeting-list.component.html',
  styleUrls: ['./conversation-meeting-list.component.scss'],
})
export class ConversationMeetingListComponent implements OnInit {
  @Select(ConversationModuleState.getActiveList)
  activeList$: Observable<number>;

  @Select(ConversationModuleState.getActiveView)
  activeView$: Observable<number>;

  @Input()
  compactPlayer: CompactPlayerComponent;

  viewOptions$: Observable<GenericLookupDto[]>;
  viewOptions: GenericLookupDto[] = [];

  @Output()
  conversationIdChange: EventEmitter<{
    id: number;
  }> = new EventEmitter();

  @Output()
  dataLoaded = new EventEmitter();

  standardListVisible = true;
  constructor() {}

  ngOnInit() {}

  onConversationIdChanged(conversationId) {
    this.conversationIdChange.emit(conversationId);
  }

  onDataLoaded() {
    this.dataLoaded.emit();
  }
}
