import { NotificationCountSummaryDto } from 'src/core/models/notification/notification-count-summary.dto';

export class NotificationInitialized {
  static readonly type = '[Notification] Initialized';

  constructor(public countSummary: NotificationCountSummaryDto) {}
}

export class NotificationReceived {
  static readonly type = '[Notification] Received';

  constructor() {}
}

export class NotificationStatusChanged {
  static readonly type = '[Notification] Status Changed';

  constructor(public newStatus: boolean) {}
}

export class AllMarkedAsRead {
  static readonly type = '[Notification] All Marked as Read';

  constructor() {}
}
