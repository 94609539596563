import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NotificationCountSummaryDto } from 'src/core/models/notification/notification-count-summary.dto';
import { Observable } from 'rxjs';
import { NotificationState } from 'src/core/states/notification/notification.state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'ca-notification-list-toolbar',
  templateUrl: './notification-list-toolbar.component.html',
  styleUrls: ['./notification-list-toolbar.component.scss'],
})
export class NotificationListToolbarComponent implements OnInit {
  countSummary: NotificationCountSummaryDto;

  @Output()
  markAllAsReadClick = new EventEmitter();

  @Output()
  filterChange: EventEmitter<{
    status: string;
  }> = new EventEmitter();

  @Select(NotificationState.getCountSummary)
  notificationCountSummary$: Observable<NotificationCountSummaryDto>;

  constructor(private router: Router, private location: Location) {}

  ngOnInit(): void {}

  onClickBack(eventArgs: MouseEvent): void {
    if (history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  onMarkAllAsClick(eventArgs: MouseEvent): void {
    this.markAllAsReadClick.emit();
  }

  onClickFilter(eventArgs: MouseEvent, status: string) {
    this.filterChange.emit({ status: status });
  }
}
