import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'ca-compact-form',
  templateUrl: './compact-form.component.html',
  styleUrls: ['./compact-form.component.scss'],
})
export class CompactFormComponent implements OnInit {
  @ViewChild('formDirective')
  formDirective: FormGroupDirective;

  @Input()
  id: string;

  @Input()
  formGroup: any;

  @Output()
  submit = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onSubmit(): void {
    this.submit.emit();
  }
}
