<div>
  <h2>{{ 'Settings::ScreenRecorder' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="form" (ngSubmit)="onSubmitForm()">
  <h4>
    {{ 'Settings::VideoPlayerSettings' | abpLocalization }}
  </h4>
  <div class="form-group mt-3">
    <input type="checkbox" id="playScreenRecordingsIndependently" formControlName="playScreenRecordingsIndependently" />
    <label class="ps-1" for="playScreenRecordingsIndependently">
      {{ 'Settings::PlayScreenRecordingsIndependently' | abpLocalization }}
    </label>
  </div>
  <div class="btn-group mt-4">
    <button type="submit" class="btn btn-primary">
      <i class="me-1 fas fa-save"></i
      ><span>
        {{ 'AbpUi::Save' | abpLocalization }}
      </span>
    </button>
  </div>
</form>
