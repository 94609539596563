import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';

@Component({
  selector: 'ca-licensing-settings',
  templateUrl: './licensing-settings.component.html',
  styleUrls: ['./licensing-settings.component.scss'],
})
export class LicensingSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'License';
  private readonly settingKeyExcludeImportAgent = this.prefix + '.ExcludeImportAgent';

  constructor(
    private config: ConfigStateService,
    private fb: FormBuilder,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.form = fb.group({
      excludeImportAgent: [
        JSON.parse(this.config.getSetting(this.settingKeyExcludeImportAgent).toLowerCase()),
      ],
    });
    this.form
      .get('excludeImportAgent')
      .patchValue(
        JSON.parse(this.config.getSetting(this.settingKeyExcludeImportAgent).toLowerCase())
      );
  }

  ngOnInit(): void {}

  onSubmitForm() {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.settingKeyExcludeImportAgent,
      settingValue: this.form.get('excludeImportAgent').value.toString(),
    });

    this.settingService.saveSetting(settings).subscribe(() => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
