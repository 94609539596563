import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  forwardRef,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { DropdownTreeNodeModel } from '../../models/dropdown-tree-node.model';
import { DropdownTreeNodeComponent } from '../dropdown-tree-node/dropdown-tree-node.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizationService } from '@abp/ng.core';
import { StringService } from 'src/core/services/helper/string.service';
import { CAConstants } from 'src/core/constants/ca-constant';

enum StatusType {
  Active = 1,
  Passive = 2,
  All = 3,
}
@Component({
  selector: 'ca-dropdown-tree',
  templateUrl: './dropdown-tree.component.html',
  styleUrls: ['./dropdown-tree.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownTreeComponent),
    },
  ],
})
export class DropdownTreeComponent implements OnInit, ControlValueAccessor {
  // tslint:disable-next-line: variable-name
  private _data: DropdownTreeNodeModel[] = [];
  // tslint:disable-next-line: variable-name
  private _emptyText: string;
  // tslint:disable-next-line: variable-name
  private _disabled: boolean;
  // tslint:disable-next-line: variable-name
  private _disabledItemMessage: string;
  private nodes: DropdownTreeNodeComponent[] = [];
  private renderedNodeCount = 0;

  selected: any[] = [];
  queryValue: string;
  thisRef: DropdownTreeComponent;
  currentStatus = StatusType.Active;

  @Input()
  set data(data: DropdownTreeNodeModel[]) {
    this._data = data;
    this.cdr.detectChanges();
    this.setSelected();
  }

  @Input()
  set emptyText(emptyText: string) {
    this._emptyText = emptyText;
  }

  get emptyText(): string {
    return this._emptyText;
  }
  @Input()
  dropdownEnabled: boolean = true;

  @Input()
  information: string;

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  get disabled() {
    return this._disabled;
  }
  @Input()
  showSelections: boolean = false;

  @Input()
  multiple: boolean = false;

  @Input()
  nodeTemplate: TemplateRef<any>;

  @Input()
  selectionTemplate: TemplateRef<any>;

  @Input()
  buttonCls: string;

  @Input()
  idProperty: string;

  @Input()
  parentIdProperty: string;

  @Input()
  styles: any = {};

  @Input()
  menuHeight: string | number = 'auto';

  @Input()
  menuWidth: string | number = 'auto';

  @Input()
  width: string | number = 'auto';

  @Input()
  hideToolbar: boolean = false;

  @Input()
  searchable: boolean = false;

  @Input()
  selectChilds: boolean = false;

  @Input()
  selectionText: string;

  @Input()
  selectionStyle: string;

  @Input()
  set disabledItemMessage(message: string) {
    this._disabledItemMessage = message;
  }

  get disabledItemMessage() {
    return this._disabledItemMessage;
  }

  @Output()
  selectionChange: EventEmitter<{
    value: any[];
  }> = new EventEmitter();

  get rootNodes() {
    return this._data.filter(item => {
      return item.payload[this.parentIdProperty] === null;
    });
  }

  get value(): any[] {
    return this.selected;
  }

  private get rendered(): boolean {
    return this._data.length === this.renderedNodeCount;
  }

  public get hasVisibleNode() {
    if (this.rootNodes?.filter(x => x.hidden === false || !x.hasOwnProperty('hidden')).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  private doClearValue() {
    this.unselectAll();
    this.selected = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    const disabled = changes['disabled'];
    if (disabled && disabled.currentValue === true) {
      this.disabled = true;
    }
  }

  onClickRemoveItem(eventArgs, item) {
    this.deselectNode(item.id);
  }

  onNodeCheckChanged(id: string, selected: boolean) {
    if (!this.multiple) {
      this.doClearValue();
    }
    let state = selected;
    state ? this.selectNode(id) : this.deselectNode(id);
    this.onChange(this.value);
    this.onTouched();
  }

  onExpandAllClick(eventArgs: MouseEvent) {
    this.expandAll();
  }

  onCollapseAllClick(eventArgs: MouseEvent) {
    this.collapseAll();
  }

  onClearClick(eventArgs: MouseEvent) {
    this.clearValue();
  }

  onSelectAllClick(eventArgs: MouseEvent) {
    this.selectAll();
  }

  expandAll() {
    this._data.forEach(item => {
      item.expanded = true;
    });
  }

  collapseAll() {
    this._data.forEach(item => {
      item.expanded = false;
    });
  }

  hideAll() {
    this._data.forEach(item => {
      item.hidden = true;
      item.expanded = false;
    });
  }

  showAll() {
    this._data.forEach(item => {
      item.hidden = false;
      item.expanded = true;
    });
  }

  selectAll() {
    this.doClearValue();
    this.nodes.forEach(node => {
      node.data.selected = true;
      this.selected.push(node.data.payload);
    });

    this.onChange(this.value);
    this.onTouched();
  }

  private removeFromSelected(id: string) {
    for (let i = 0; i < this.selected.length; i++) {
      let node = this.selected[i];
      if (node[this.idProperty] === id) {
        const idx = this.selected.indexOf(node);
        this.selected.splice(idx, 1);
        break;
      }
    }
  }

  private setSelected() {
    this.unselectAll();
    for (let i = 0; i < this.selected.length; i++) {
      for (let j = 0; j < this._data.length; j++) {
        if (this.selected[i][this.idProperty] === this._data[j].payload[this.idProperty]) {
          this._data[j].selected = true;
          break;
        }
      }
    }

    this.onChange(this.value);
  }

  private unselectAll() {
    this.nodes.forEach(node => {
      node.data.selected = false;
    });
  }

  hasUnselectedChild(node): boolean {
    let childs = this.nodes.filter(
      x => x.data.payload[this.parentIdProperty] == node.payload[this.idProperty]
    );
    for (let i = 0; i < childs.length; i++) {
      if (childs[i].data.selected || this.hasUnselectedChild(childs[i].data)) {
        return true;
      }
    }
    return false;
  }

  clearValue(): void {
    this.doClearValue();
    this.onChange(this.value);
  }

  selectNode(id) {
    this.nodes.forEach(node => {
      const nodeId = node.data.payload[this.idProperty];

      if (nodeId === id) {
        if (!node.data.disabled) {
          node.data.selected = true;
          if (this.selected.filter(x => x[this.idProperty] === id).length === 0) {
            this.selected.push(node.data.payload);
          }
        }

        if (this.selectChilds && this.multiple) {
          let childs = this.nodes.filter(x => x.data.payload[this.parentIdProperty] == nodeId);
          if (this.currentStatus == StatusType.Active) {
            childs = childs.filter(x => x.data?.payload?.item?.isActive);
          } else if (this.currentStatus == StatusType.Passive) {
            childs = childs.filter(x => !x.data?.payload?.item?.isActive);
          }
          childs.forEach(child => {
            this.selectNode(child[this.idProperty]);
          });
        }
      }
    });

    this.onChange(this.value);
  }
  deselectNode(id) {
    this.nodes.forEach(node => {
      const nodeId = node.data.payload[this.idProperty];

      if (nodeId === id) {
        node.data.selected = false;
        this.removeFromSelected(id);
      }
    });

    this.onChange(this.value);
  }

  addNode(node: DropdownTreeNodeComponent) {
    this.nodes.push(node);
    this.renderedNodeCount++;
  }

  getChildren(parentId): DropdownTreeNodeModel[] {
    return this._data.filter(item => {
      return item.payload[this.parentIdProperty] === parentId;
    });
  }

  writeValue(obj: any[] | null): void {
    this.onRemoveFilterClick(null);
    if (obj === null) {
      this.doClearValue();
    }
    if (Array.isArray(obj)) {
      this.selected = obj;
      if (obj.length > 0) {
        this.setSelected();
      } else {
        this.doClearValue();
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(selection: any[]) {}

  onTouched() {}

  constructor(
    private localizationService: LocalizationService,
    private stringService: StringService,
    private cdr: ChangeDetectorRef
  ) {
    this.thisRef = this;
  }

  ngOnInit() {
    if (!this.nodeTemplate) {
      console.error('You must define a template for nodes.[nodeTemplate]');
    }
    if (!this.selectionTemplate) {
      console.error('You must define a template for selection.[selectionTemplate]');
    }

    this.buttonCls = this.buttonCls ? this.buttonCls : 'btn btn-outline-primary btn-sm';
  }

  onSearchRequest(eventArgs: KeyboardEvent) {
    eventArgs.preventDefault();
    eventArgs.stopPropagation();

    if (
      this.queryValue.length > 0 &&
      this.queryValue.length < CAConstants.SEARCH_INPUT_MIN_LENGTH
    ) {
      return;
    }

    this.hideAll();

    this._data.forEach(node => {
      if (
        this.currentStatus == StatusType.All ||
        (this.currentStatus == StatusType.Active && node.payload.item.isActive) ||
        (this.currentStatus == StatusType.Passive && !node.payload.item.isActive)
      ) {
        if (this.stringService.indexOf(node.payload.name, this.queryValue) > -1) {
          node.hidden = false;
          node.expanded = true;
          this.expandParent(node);
        }
      }
    });

    return false;
  }

  expandParent(node: DropdownTreeNodeModel) {
    if (node.payload.item.parentId) {
      let parentNode = this._data.find(n => n.payload.item.id === node.payload.item.parentId);
      if (parentNode) {
        if (!parentNode.leaf) {
          parentNode.hidden = false;
        }
        parentNode.expanded = true;
        this.expandParent(parentNode);
      }
    }
  }

  onRemoveFilterClick(eventArgs: MouseEvent) {
    this.queryValue = '';
    this.showAll();
    this.doChangeStatusFilter(this.currentStatus);
  }

  changeStatusFilter(eventArgs) {
    this.currentStatus = eventArgs.id;
    this.doChangeStatusFilter(this.currentStatus);
  }

  doChangeStatusFilter(status: StatusType) {
    if (status == StatusType.Active) {
      this.hideAll();
      this._data.forEach(node => {
        if (
          node.payload.item.isActive &&
          this.stringService.indexOf(node.payload.name, this.queryValue) > -1
        ) {
          node.hidden = false;
          node.expanded = true;
          this.expandParent(node);
        }
      });
    } else if (status == StatusType.Passive) {
      this.hideAll();
      this._data.forEach(node => {
        if (
          !node.payload.item.isActive &&
          this.stringService.indexOf(node.payload.name, this.queryValue) > -1
        ) {
          node.hidden = false;
          node.expanded = true;
          this.expandParent(node);
        }
      });
    } else {
      this.showAll();
    }
  }
}
