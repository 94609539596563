import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserGroupDto } from 'src/core/models/user-group/user-group.dto';
import { UserInteractedUserListDto } from 'src/core/models/user-group/user-interacted-user-list.dto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  constructor(private http: HttpClient) {}

  getByName(name: string): Observable<UserGroupDto> {
    const url = this.getApiUrl() + '/by-name/';

    let params = new HttpParams();
    params = params.append('name', name);

    return this.http.get(url, {
      params,
    }) as Observable<UserGroupDto>;
  }

  deleteInteractionsById(id: number): Observable<UserGroupDto> {
    const url = this.getApiUrl() + '/' + id.toString() + '/interactions/';

    return this.http.delete(url) as Observable<UserGroupDto>;
  }

  getUserInteractedUsers(userId: string): Observable<UserInteractedUserListDto[]> {
    const url = this.getApiUrl() + '/interacted-users/' + userId;

    return this.http.get(url) as Observable<UserInteractedUserListDto[]>;
  }

  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase + '/' + UserGroupDto.apiUrl;
  }
}
