import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'ca-dropdown-tree-toolbar',
    templateUrl: './dropdown-tree-toolbar.component.html',
    styleUrls: ['./dropdown-tree-toolbar.component.scss'],
})
export class DropdownTreeToolbarComponent implements OnInit {
    @Input()
    multiple:boolean = false;
    
    @Output()
    expandAllClick = new EventEmitter<MouseEvent>();

    @Output()
    collapseAllClick = new EventEmitter<MouseEvent>();

    @Output()
    clearClick = new EventEmitter<MouseEvent>();

    @Output()
    selectAllClick = new EventEmitter<MouseEvent>();

    onExpandAllClick(eventArgs: MouseEvent) {
        this.expandAllClick.emit(eventArgs);
    }

    onCollapseAllClick(eventArgs: MouseEvent) {
        this.collapseAllClick.emit(eventArgs);
    }

    onClearClick(eventArgs: MouseEvent) {
        this.clearClick.emit(eventArgs);
    }

    onSelectAllClick(eventArgs: MouseEvent) {
        this.selectAllClick.emit(eventArgs);
    }

    constructor() {}

    ngOnInit() {}
}
