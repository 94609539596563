<div>
  <h2>{{ 'SpeechRecognition::SpeechRecognitionAgentTraining' | abpLocalization }}</h2>
</div>
<form *ngIf="trainingFeatureEnabled" [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="serviceUrl">
    <label class="indicates-required-field" for="serviceUrl">{{ 'SpeechRecognition::ServiceUrl' | abpLocalization }} </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="serviceUrl"
      formControlName="value"
    />
  </div>
  <div class="form-group pt-2" formGroupName="tokenEndpoint">
    <label for="tokenEndpoint">{{ 'SpeechRecognition::TokenEndpoint' | abpLocalization }} </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="tokenEndpoint"
      formControlName="value"
    />
  </div>
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'SpeechRecognition::ClientId' | abpLocalization }} </label>
    <input 
      type="password" 
      class="form-control form-control-sm" 
      id="clientId" formControlName="value" 
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization" 
    />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">{{ 'SpeechRecognition::ClientSecret' | abpLocalization }} </label>
    <input
      type="password"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      class="form-control form-control-sm"
      id="clientSecret"
      formControlName="value"
    />
  </div>
  <div class="form-group pt-2" formGroupName="modelName">
    <label class="indicates-required-field" for="modelName">{{ 'SpeechRecognition::ModelName' | abpLocalization }} </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="modelName"
      formControlName="value"
    />
  </div>
  <div class="form-group pt-2" formGroupName="modelVersion">
    <label class="indicates-required-field" for="modelVersion">{{ 'SpeechRecognition::ModelVersion' | abpLocalization }} </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="modelVersion"
      formControlName="value"
    />
  </div>
  <div class="form-group pt-2" formGroupName="sampleRate">
    <label class="indicates-required-field" for="sampleRate">{{ 'SpeechRecognition::SampleRate' | abpLocalization }}  </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sampleRate"
      formControlName="value"
    />
  </div>
  <div class="form-group pt-2" formGroupName="tenant">
    <label class="indicates-required-field" for="tenant">{{ 'SpeechRecognition::Tenant' | abpLocalization }} </label>
    <input type="text" class="form-control form-control-sm" id="tenant" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="silenceTimeout">
    <label class="indicates-required-field" for="silenceTimeout"
      >{{ 'SpeechRecognition::SilenceTimeout' | abpLocalization }} </label
    >
    <input
      type="number"
      class="form-control form-control-sm"
      id="silenceTimeout"
      formControlName="value"
    />
  </div>
  <div class="form-group pt-2" formGroupName="pointsToSucceed">
    <label class="indicates-required-field" for="pointsToSucceed"
      >{{ 'SpeechRecognition::PointsToSucceed' | abpLocalization }} </label
    >
    <input
      type="number"
      class="form-control form-control-sm"
      id="pointsToSucceed"
      formControlName="value"
    />
  </div>
  <div class="form-group pt-2" formGroupName="maxNumberOfTriesToSkipRow">
    <label for="maxNumberOfTriesToSkipRow"
      >{{ 'SpeechRecognition::MaxNumberOfTriesToSkipRow' | abpLocalization }} </label
    >
    <input
      type="number"
      class="form-control form-control-sm"
      id="maxNumberOfTriesToSkipRow"
      formControlName="value"
    />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
