import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { EvaluationReferrer } from 'src/core/constants/evaluation-referrer.constant';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { EvaluationResultNotificationType } from 'src/core/models/generic-lookup-type/quality/evaluation-result-notification-type.glt';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-evaluation-result-automatic-manager-notification',
  templateUrl: './evaluation-result-automatic-manager-notification.component.html',
  styleUrls: ['./evaluation-result-automatic-manager-notification.component.scss'],
})
@NotificationType(
  'EvaluationResultAutomaticManagerNotification',
  EvaluationResultAutomaticManagerNotificationComponent
)
export class EvaluationResultAutomaticManagerNotificationComponent implements OnInit {
  data: NotificationDto;

  get payloadData(): any {
    return JSON.parse(this.data?.payload);
  }

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private caDatePipe: CADatePipe
  ) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'QualityManagement::EvaluationResultAutomaticNotificationMessage',
      parsedPayload.count,
      EvaluationResultAutomaticManagerNotificationComponent.getDateRangeTextInner(
        parsedPayload.date,
        parsedPayload.endDate,
        parsedPayload.notificationType,
        caDatePipe
      )
    );

    var toastrId = toastr.success(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(notificationService, notificationId, parsedPayload, router);
    });
  }

  static toasterClickedHandler(
    notificationService: NotificationService,
    notificationId: string,
    payload: any,
    router: Router
  ) {
    notificationService.markAsRead(notificationId).subscribe();
    this.navigateToEvaluation(payload, router);
    notificationService.markAsRead(notificationId);
  }

  static navigateToEvaluation(payloadData: any, router: Router) {
    const queryParams: any = {
      departmentManager: payloadData.departmentManager,
      supervisor: payloadData.supervisor,
      supervisorId: payloadData.supervisorId,
      departmentId: payloadData.departmentId,
      date: payloadData.date,
      endDate: payloadData.endDate,
      isAutomatic: 'true',
      referrer: EvaluationReferrer.evaluationResult,
    };

    router.navigate(['quality', 'evaluation-result'], {
      queryParams: queryParams,
    });
  }

  static getDateRangeTextInner(
    startDate: string,
    endDate: string,
    notificationType: string,
    caDatePipe: CADatePipe
  ): string {
    var type = +notificationType;
    var sDate = new Date(startDate);
    var eDate = new Date(endDate);

    if (type == EvaluationResultNotificationType.daily) {
      return `(${caDatePipe.transform(sDate, DateDisplayType.Date)})`;
    } else if (type == EvaluationResultNotificationType.weekly) {
      return `(${caDatePipe.transform(sDate, DateDisplayType.Date)} - ${caDatePipe.transform(
        eDate,
        DateDisplayType.Date
      )})`;
    } else if (type == EvaluationResultNotificationType.monthly) {
      return `(${sDate.getMonth()}.${sDate.getFullYear()})`;
    }

    return `(${caDatePipe.transform(
      sDate,
      DateDisplayType.TimeWithoutSeconds
    )} - ${caDatePipe.transform(eDate, DateDisplayType.TimeWithoutSeconds)})`;
  }

  getDateRangeText(startDate: string, endDate: string, notificationType: string): string {
    return EvaluationResultAutomaticManagerNotificationComponent.getDateRangeTextInner(
      startDate,
      endDate,
      notificationType,
      this.caDatePipe
    );
  }

  onClick() {
    const parsedPayload = JSON.parse(this.data.payload);
    EvaluationResultAutomaticManagerNotificationComponent.navigateToEvaluation(
      parsedPayload,
      this.router
    );
    this.notificationService.markAsRead(this.data.id);
  }
}
