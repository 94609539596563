import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationHelper } from 'src/core/models/notification/types/notification.helper';

@Component({
  selector: 'ca-notification-row',
  templateUrl: './notification-row.component.html',
  styleUrls: ['./notification-row.component.scss'],
})
export class NotificationRowComponent implements OnInit {
  @Input()
  notification: NotificationDto;

  constructor(private resolver: ComponentFactoryResolver, private vcRef: ViewContainerRef) {}

  ngOnInit(): void {
    const cmp = NotificationHelper.notificationList.get(this.notification.type);

    const factory = cmp ? this.resolver.resolveComponentFactory(cmp?.prototype.constructor) : null;

    if (factory !== null) {
      const compRef = this.vcRef.createComponent(factory);

      (<any>compRef.instance).data = this.notification;
    }
  }
}
