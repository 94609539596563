import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.RedactionMode'
})
export class ConversationRedactionMode {
    @GenericLookup({ code: 'Conversation.RedactionMode.ReplaceWithCharacter' })
    static replaceWithCharacter: number;

    @GenericLookup({ code: 'Conversation.RedactionMode.ReplaceWithEntityType' })
    static replaceWithEntityType: number;
}
