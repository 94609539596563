import { LocalizationService } from '@abp/ng.core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'reasonTooltipList' })
export class ReasonTextPipe implements PipeTransform {
  constructor(private localizationService: LocalizationService) {}

  transform(items: any[]): string {
    if (!items) {
      return '';
    }
    let list: string =
      items.length > 0
        ? this.localizationService.instant('QualityManagement::Reasons') + ': '
        : this.localizationService.instant('QualityManagement::NoReasonSelected');

    for (let i = 0; i < items.length; i++) {
      let reason = items[i].reason ? items[i].reason : items[i];
      if (i == 0) {
        list += reason.text;
      } else {
        list += ', ' + reason.text;
      }
    }

    return list;
  }
}
