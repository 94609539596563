<ng-template #popContent>
  <div class="lp-timeline m-1 pointer">
    <div
      class="lp-timeline-item align-items-start"
      (click)="onMarkClick($event, marker)"
      *ngFor="let marker of markerGroup.markers let i = index"
    >
      <div class="lp-timeline-label">
        {{ getMarkerTimeLine(marker) }}
      </div>
      <div class="lp-timeline-badge">
        <i [ngClass]="getCategoryIconClass(marker)" style="font-size: 12px; color:{{ marker.color }}"></i>
      </div>
      <div [ngClass]="  i + 1 !=  markerGroup.markers.length ? 'lp-timeline-content lp-timeline-border' : 'lp-timeline-content ml-8'  ">
        <span> {{ marker.text }}</span
        >
      </div>
    </div>
  </div></ng-template
>
<div
  [ngbPopover]="popContent"
  [openDelay]="500"
  [closeDelay]="1000"
  #popover="ngbPopover"
  (shown)="onPopoverShown(popover)"
  [popoverClass]="getPopoverClass()"
  [autoClose]="'outside'"
  triggers="mouseenter"
  class="marker {{ cls }}"
  style="box-shadow: 1px 2px 2px 1px rgb(0 0 0 / 20%); left:{{ markerGroup.position }}%;"
>
  <div class="marker-text" *ngIf="!hasCommentandOthers(markerGroup)" (click)="onClick($event, markerGroup)">
    <i [ngClass]="getCategoryIconClass(markerGroup)" style="color:{{ markerGroup.color }}"></i>
    {{ getMarkerName() }}
  </div>
  <div class="marker-text" *ngIf="hasCommentandOthers(markerGroup)" (click)="onClick($event, markerGroup)">
    <i [ngClass]="markerIcon" style="color:{{ markerColor }}"></i>
    {{ markerName }}
    <i ngClass="far fa-comment" style="color:#94acce"></i>
    {{ markerNameForComment }}
  </div>
</div>
