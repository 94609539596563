export class FilterChange {
  static readonly type = '[StatisticComparison] FilterChange';
  constructor(public filters: any, public debounceTime: number = 3000) {}
}

export class ViewModeChange {
  static readonly type = '[StatisticComparison] ViewModeChange';
  constructor(public cloudMode: boolean = true) {}
}

export class CategoryModeChange {
  static readonly type = '[StatisticComparison] CategoryModeChange';
  constructor(public aiGeneratedMode: boolean = true) {}
}
