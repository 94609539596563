import { LocalizationService } from '@abp/ng.core';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormButtonConfiguration } from '../../form-button-configuration.model';

@Component({
  selector: 'ca-compact-form-footer',
  templateUrl: './compact-form-footer.component.html',
  styleUrls: ['./compact-form-footer.component.scss'],
})
export class CompactFormFooterComponent implements OnInit, AfterViewInit {
  @Input()
  usePadding = true;

  @Input()
  processing = false;

  @Input()
  processingMessage = this.localizationService.instant('AbpUi::ProcessingWithThreeDot');

  @Input()
  set buttons(value: FormButtonConfiguration) {
    this.buttonConfig = Object.assign(this.buttonConfig, this.buttonDefaults, value);
  }

  @Output()
  cancelButtonClick = new EventEmitter();

  @Output()
  deleteButtonClick = new EventEmitter();

  @Output()
  previousButtonClick = new EventEmitter();

  @Output()
  nextButtonClick = new EventEmitter();

  buttonConfig: FormButtonConfiguration;

  private buttonDefaults: FormButtonConfiguration = {
    save: {
      name: this.localizationService.instant('AbpUi::Save'),
      hideIcon: false,
      visible: true,
      tooltip: null,
      disabled: false,
    },
    delete: {
      name: this.localizationService.instant('AbpUi::Delete'),
      visible: true,
      tooltip: null,
      disabled: false,
    },
    cancel: {
      name: this.localizationService.instant('AbpUi::Cancel'),
      visible: true,
      tooltip: null,
      disabled: false,
    },
    previous: {
      name: this.localizationService.instant('::Previous'),
      hideIcon: false,
      visible: false,
      tooltip: null,
      disabled: false,
    },
    next: {
      name: this.localizationService.instant('::Next'),
      hideIcon: false,
      visible: false,
      tooltip: null,
      disabled: false,
    },
  };

  constructor(private localizationService: LocalizationService) {
    this.buttonConfig = Object.assign({}, this.buttonDefaults);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  onClickCancelButton(eventArgs: MouseEvent): void {
    this.cancelButtonClick.emit();
  }

  onClickDeleteButton(eventArgs: MouseEvent): void {
    this.deleteButtonClick.emit();
  }

  onClickPreviousButton(eventArgs: MouseEvent): void {
    this.previousButtonClick.emit();
  }

  onClickNextButton(eventArgs: MouseEvent): void {
    this.nextButtonClick.emit();
  }
}
