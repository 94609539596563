<ca-clickable-link
  [innerHtml]="
    'QualityManagement::ConversationEvaluatedToastrAndNotificationMessage'
      | abpLocalization : payloadData?.conversationId
  "
  [targetLink]="['/quality/evaluation-result']"
  [params]="{
    conversationId: payloadData?.conversationId,
    isAutomatic: 'true',
    referrer: 'evaluation-result'
  }">
</ca-clickable-link>
