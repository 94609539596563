export class FilterPanelValueChange {
  static readonly type = '[FilterPanel] FilterPanelValueChange';

  constructor(public key: string, public values: any) {}
}

export class ChildPanelVisibilityChange {
  static readonly type = '[FilterPanel] ChildPanelVisibilityChange';

  constructor(public key: string, public hiddenPanels: string[]) {}
}

export class ChildPanelActivityChange {
  static readonly type = '[FilterPanel] ChildPanelActivityChange';

  constructor(public key: string, public activePanels: string[]) {}
}

export class PanelSizeChange {
  static readonly type = '[FilterPanel] PanelSizeChange';

  constructor(public key: string, public collapsed: boolean) {}
}
