<div class="category-container">
  <div
    (click)="onFilterActionGroupClick(actionGroupType.id)"
    class="action-group-class"
    *ngFor="let actionGroupType of actionGroupTypes">
    <div
      [ngClass]="getToggleClass(actionGroupType.id)"
      [title]="'GenericLookup::' + actionGroupType.code | abpLocalization">
      <i [ngClass]="getIconClass(actionGroupType.id)"></i>
      {{ 'GenericLookup::' + actionGroupType.code | abpLocalization }}
    </div>
  </div>
</div>
<div *ngIf="(data == null || data.length === 0) && !isSelectedSystem" class="row m-0 p-3">
  {{ 'ActionLog::ConversationNoActionLog' | abpLocalization }}
</div>
<table
  mat-table
  *ngIf="data != null && data.length > 0 && !isSelectedSystem"
  [dataSource]="dataSource">
  <ng-container *ngFor="let gridColumn of gridColumns; let i = index">
    <ng-container
      *ngIf="gridColumn.columnName !== 'actionType'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.minWidth || gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td
        mat-cell
        *matCellDef="let actionLog"
        [innerHtml]="actionLog | objectparser : gridColumn"></td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'actionType'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let actionLog">
        <ng-container *ngIf="actionLog.actionTypeId">
          <ca-action-log-row [actionLog]="actionLog" [activeTab]="activeTab"></ca-action-log-row>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>
  <!-- Info column -->
  <ng-container matColumnDef="info">
    <td
      mat-footer-cell
      *matFooterCellDef
      style="text-align: left"
      [attr.colspan]="displayedColumns.length">
      <span>
        <mat-paginator
          style="display: flex; justify-content: left"
          (page)="onPageChange($event)"
          [pageIndex]="currentPage"
          [length]="totalCount"
          [pageSize]="pageSize"
          class="paginator"
          hidePageSize
          showFirstLastButtons></mat-paginator>
      </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    class="action-log-row-{{ myRowData.id }}"
    *matRowDef="let myRowData; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="['info']; sticky: true"></tr>
</table>

<section class="flow-container" *ngIf="isSelectedSystem">
  <div [className]="'flow-card  position-relative ' + getCss(statesDto?.analysisState)">
    <span class="tooltip-date">{{ transformDate(statesDto?.analysisStateTime) }}</span>
    <b>{{ 'Conversation::StateMachine:Title:Analysis' | abpLocalization }}:</b>
    {{ getStateText(statesDto?.analysisState) }}
  </div>

  <svg viewbox="0 0 10 500">
    <line x1="5" x2="5" y1="0" y2="100" />
  </svg>

  <div [className]="'flow-card  position-relative  ' + getCss(statesDto?.waveformState)">
    <span class="tooltip-date">{{ transformDate(statesDto?.waveformStateTime) }}</span>
    <b>{{ 'Conversation::StateMachine:Title:Waveform' | abpLocalization }}:</b>
    {{ getStateText(statesDto?.waveformState) }}
  </div>

  <svg viewbox="0 0 10 500">
    <line x1="5" x2="5" y1="0" y2="100" />
  </svg>

  <div [className]="'flow-card  position-relative  ' + getCss(statesDto?.recordingState)">
    <span class="tooltip-date">{{ transformDate(statesDto?.recordingStateTime) }}</span>
    <b>{{ 'Conversation::StateMachine:Title:ProcessRecording' | abpLocalization }}:</b>
    {{ getStateText(statesDto?.recordingState) }}
  </div>

  <svg viewbox="0 0 10 500">
    <line x1="5" x2="5" y1="0" y2="100" />
  </svg>

  <div [className]="'flow-card  position-relative  ' + getCss(statesDto?.aiTopicState)">
    <span class="tooltip-date">{{ transformDate(statesDto?.aiTopicStateTime) }}</span>
    <b>{{ 'Conversation::StateMachine:Title:AITopic' | abpLocalization }}:</b>
    {{ getStateText(statesDto?.aiTopicState) }}
  </div>

  <svg viewbox="0 0 10 500">
    <line x1="5" x2="5" y1="0" y2="100" />
  </svg>

  <div [className]="'flow-card  position-relative  ' + getCss(statesDto?.indexingState)">
    <span class="tooltip-date">{{ transformDate(statesDto?.indexingStateTime) }}</span>
    <b>{{ 'Conversation::StateMachine:Title:Indexing' | abpLocalization }}:</b>
    {{ getStateText(statesDto?.indexingState) }}
  </div>

  <svg viewbox="0 0 10 500">
    <line x1="5" x2="5" y1="0" y2="100" />
  </svg>

  <div [className]="'flow-card  position-relative  ' + getCss(statesDto?.automaticEvaluationState)">
    <span class="tooltip-date">{{ transformDate(statesDto?.automaticEvaluationStateTime) }}</span>
    <b>{{ 'Conversation::StateMachine:Title:AutomaticEvaluation' | abpLocalization }}:</b>
    {{ getStateText(statesDto?.automaticEvaluationState) }}
  </div>
</section>
