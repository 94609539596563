<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="sourceName">
    <label for="sourceName">{{ 'Settings::TeamsIntegrationSourceName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sourceName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="tenantId">
    <label for="tenantId">{{ 'Settings::TeamsIntegrationTenantId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="tenantId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::TeamsIntegrationClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="clientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">
      {{ 'Settings::TeamsIntegrationClientSecret' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientSecret"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="groupId">
    <label for="groupId">{{ 'Settings::TeamsIntegrationGroupId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="groupId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="publicKey">
    <label for="publicKey">{{ 'Settings::TeamsIntegrationPublicKey' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="publicKey"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="privateKey">
    <label for="privateKey">{{ 'Settings::TeamsIntegrationPrivateKey' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="privateKey"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="encryptionCertificateId">
    <label for="encryptionCertificateId">
      {{ 'Settings::TeamsIntegrationEncryptionCertificateId' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="encryptionCertificateId"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
