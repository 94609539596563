import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { Operators } from 'src/core/models/request/operator.enum';
import { FormSectionSelectionConfigurationModel } from '../models/form-section-selection-configuration-model';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { FormSectionDto } from 'src/core/models/quality-management/form-section.dto';
@Component({
  selector: 'ca-form-section-selection-panel',
  templateUrl: './form-section-selection-panel.component.html',
  styleUrls: ['./form-section-selection-panel.component.scss'],
})
export class FormSectionSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit
{
  actualConfig: FormSectionSelectionConfigurationModel;
  _value: FormSectionDto[];
  filters: FilterItemDto[] = [];
  sectionSelectorOptions: any;

  @ViewChild(SelectorComponent, { read: SelectorComponent })
  sectionSelector: SelectorComponent;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as FormSectionSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }
  
  autoSelectFirstItem(): void {}
  
  reload(): void {
    this.innerLoad();
  }
  reset(): void {}

  constructor(private operators: Operators, protected fb: FormBuilder) {
    super(fb);

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  ngOnInit(): void {

    this.sectionSelectorOptions = {
      pageSize: 10,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      multiple: false,
      url: 'api/app/quality/form-sections',
      currentPage: 1,
      showSelections: false,
      showClearSelections: false,
      showClearSelectionLink: true,
      selectorPosition: 'Top',
      filters: this.filters,
    };

  }

  init() {
    super.init();

    if (this.actualConfig.autoLoad) {
      this.innerLoad();
    }
  }

  private innerLoad() {
    const filters: FilterItemDto[] = [];

    if (this.extraData.formId) {
      filters.push({
        field: 'formId',
        operator: this.operators.Equals,
        value: this.extraData.formId,
      });
    }

    if (this.extraData.versionId) {
      filters.push({
        field: 'versionId',
        operator: this.operators.Equals,
        value: this.extraData.versionId,
      });
  
      this.sectionSelector.setFilters(filters);
      this.sectionSelector.refresh();
    }
  }
}
