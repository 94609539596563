import { CallAnalysisDto } from './call-analysis.dto';
import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../shared/dto-has-api.model';

@Api('api/app/call')
export class CallDto extends DtoHasApi {
  id: number;
  callerNumber;
  calledNumber: string;
  voiceFilePath: string;
  uniqueId: string;
  transferOrder: number;
  holdCount: number;
  totalHoldDuration: number;
  maxHoldDuration: number;
  conversationId: number;
  userId: string;
  userGroupId: number;
  directionId: number;
  releasingPartyId: number;
  directionName: string;
  directionCode: string;
  releasingPartyName: string;
  callAnalysis: CallAnalysisDto;
  isDiarize: boolean;
  agentChannelId: number;
}
