<div
  ngbTooltip="{{
    !hasEvaluableForms ? ('QualityManagement::ConversationNoSuitableForms' | abpLocalization) : ''
  }}"
  [disableTooltip]="hasEvaluableForms"
  container="body"
>
  <button
    class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
    (click)="openEvaluationFormsListModal(flagListContent)"
    [disabled]="!hasEvaluableForms || loading || buttonDisabled"
  >
    <ng-container *ngIf="!loading">
      <i class="far fa-chalkboard-teacher"></i>
      <span>
        {{ 'Conversation::Evaluate' | abpLocalization }}
      </span>
    </ng-container>
    <ng-container *ngIf="loading">
      <i class="fas fa-spinner fa-spin"></i
      ><span>{{ '::Loading' | abpLocalization }}...</span></ng-container
    >
  </button>
</div>
<ng-template #flagListContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'QualityManagement::FormSelection' | abpLocalization }}</h4>
  </div>
  <div class="modal-body">
    <div class="table-body">
      <table mat-table [dataSource]="dataSource">
        <ng-container *ngFor="let gridColumn of gridColumns">
          <ng-container
            *ngIf="gridColumn.columnName === 'radio'"
            matColumnDef="{{ gridColumn.columnName }}"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              [style.width]="gridColumn.width"
              [style.minWidth]="gridColumn.width"
              [style.maxWidth]="gridColumn.width"
            >
              {{ gridColumn.header }}
            </th>
            <td mat-cell *matCellDef="let evaluationForm">
              <div class="radio-group">
                <input
                  type="radio"
                  name="form-selector"
                  [value]="evaluationForm"
                  [(ngModel)]="selectedForm"
                />
              </div>
            </td>
          </ng-container>
          <ng-container
            *ngIf="gridColumn.columnName === 'name'"
            matColumnDef="{{ gridColumn.columnName }}"
          >
            <th
              mat-header-cell
              *matHeaderCellDef
              [style.width]="gridColumn.width"
              [style.minWidth]="gridColumn.width"
              [style.maxWidth]="gridColumn.width"
            >
              {{ gridColumn.header }}
            </th>
            <td
              mat-cell
              *matCellDef="let evaluationForm"
              [innerHtml]="evaluationForm | objectparser: gridColumn"
            ></td>
          </ng-container>
        </ng-container>
        <ng-container>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClick(row)"
            [ngbTooltip]="
              'QualityManagement::NotAnalysedConversationNotEvaluableWithHybridFormMessage'
                | abpLocalization
            "
            [disableTooltip]="isFormEvaluable(row)"
            [attr.data-isEvaluable]="!isFormEvaluable(row)"
            triggers="mouseenter:mouseleave"
            container="body"
          ></tr>
        </ng-container>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn ca-btn ca-btn-neutral toolbar-button"
      (click)="closeModal(true)"
    >
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
    <button
      type="button"
      [disabled]="processing || selectedForm == null"
      class="btn ca-btn ca-btn-primary"
      (click)="evaluate(selectedForm)"
    >
      <ng-container *ngIf="!processing"
        ><i class="far fa-chalkboard-teacher"></i
        ><span>{{ 'Conversation::Evaluate' | abpLocalization }}</span></ng-container
      >
      <ng-container *ngIf="processing"
        ><i class="fas fa-spinner fa-spin"></i>
        <span>{{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span></ng-container
      >
    </button>
  </div>
</ng-template>
