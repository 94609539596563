import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { Component, HostBinding, OnInit } from '@angular/core';
import { StateVersionService } from 'src/core/services/state/state-version.service';

@Component({
  selector: 'ca-reset-user-settings',
  templateUrl: './reset-user-settings.component.html',
  styleUrls: ['./reset-user-settings.component.scss'],
})
export class ResetUserSettingsComponent implements OnInit {
  @HostBinding('style.width')
  componentWidth = '100%';

  constructor(
    private confirmationService: ConfirmationService,
    private stateVersionService: StateVersionService
  ) {}

  ngOnInit(): void {}

  resetUserStateSettings() {
    this.confirmationService
      .warn('::ResetUserSettingsTitle', '', {
        yesText: '::Reset',
      })
      .subscribe((status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          this.stateVersionService.resetAllStates().subscribe(result => {
            window.location.reload();
          });
        }
      });
  }
}
