export class ValidationHelper {
  public static getIndicesOf(searchStr, str) {
    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
      return [];
    }
    var startIndex = 0,
      index,
      indices = [];
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  }

  public static getSpecialCharacterRegex(): RegExp {
    return /[`~!@#$%^()_|+=?;:",.<>\{\}\[\]\\\/]/gi;
  }
}
