<ng-template #itemTemplate let-item="item">
  <div class="selection-panel-name">
    <div class="line-ellipsis">{{ item.name }}</div>
  </div>
</ng-template>

<ng-template #selectionTemplate let-item="item">
  <div class="filter-name">{{ item.name }}</div>
</ng-template>

<ng-container [formGroup]="editorForm">
  <ca-selector
    formControlName="editor"
    [url]="sectionSelectorOptions.url"
    [filters]="sectionSelectorOptions.filters"
    [itemTemplate]="itemTemplate"
    [selectionTemplate]="selectionTemplate"
    [queryOperator]="sectionSelectorOptions.queryOperator"
    [queryField]="sectionSelectorOptions.queryField"
    [multiple]="false"
    [emptyText]="sectionSelectorOptions.emptyText"
    [showSelections]="sectionSelectorOptions.showSelections"
    [showClearSelections]="sectionSelectorOptions.showClearSelections"
    [showClearSelectionLink]="sectionSelectorOptions.showClearSelectionLink"
    [selectorPosition]="sectionSelectorOptions.selectorPosition"
    [styles]="{ width: '100%', 'border-width': '0px' }"
    [pageSize]="sectionSelectorOptions.pageSize"
    [currentPage]="sectionSelectorOptions.currentPage"></ca-selector>
</ng-container>
