import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MatTableColumnDefinitionModel } from '../models/mat-table/mat-table-column-definition.model';
import { CADatePipe } from './ca-date.pipe';
import { CATimePipe } from './ca-time.pipe';

@Pipe({ name: 'objectparser' })
export class ObjectParserPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private caDatePipe: CADatePipe,
    private caTimePipe: CATimePipe
  ) { }

  transform(obj: any, options: MatTableColumnDefinitionModel): any {
    let iterator = obj;
    const segments = options.binding.split('.');

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      iterator = iterator[segment];

      if (iterator === undefined || iterator === null) {
        return options.nullText === null ? null : options.nullText;
      }
    }

    if (options.valueParser !== undefined) {
      iterator = options.valueParser(iterator, obj);
    }

    if (options.pipes !== undefined) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < options.pipes.length; i++) {
        const pipe = options.pipes[i];
        const args = Array.from(pipe.args);

        args.splice(0, 0, iterator);

        if (pipe.pipe === this.datePipe.constructor) {
          iterator = this.datePipe.transform.apply(this.datePipe, args);
        } else if (pipe.pipe === this.decimalPipe.constructor) {
          iterator = this.decimalPipe.transform.apply(this.decimalPipe, args);
        } else if (pipe.pipe === this.caDatePipe.constructor) {
          iterator = this.caDatePipe.transform.apply(this.caDatePipe, args);
        } else if (pipe.pipe === this.caTimePipe.constructor) {
          iterator = this.caTimePipe.transform.apply(this.caTimePipe, args);
        }
      }
    }

    return iterator;
  }
}
