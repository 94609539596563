import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'ActionLog.ActionGroupType',
})
export class ActionLogActionGroupType {
    @GenericLookup({
        code: 'ActionLog.ActionGroupType.System',
    })
    static system: number;

    @GenericLookup({
        code: 'ActionLog.ActionGroupType.Operations',
    })
    static operations: number;
}
