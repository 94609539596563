import { LocalizationService, PermissionService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PlayerStatus } from 'src/ca-shared/player/models/player-status.enum';
import { PlayerComponent } from 'src/ca-shared/player/player.module';
import { EvaluationReferrer } from 'src/core/constants/evaluation-referrer.constant';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { SimpleFormDto } from 'src/core/models/quality-management/simple-form.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { FormService } from 'src/core/services/quality-management/form.service';

export class EvaluationFormUsage {
  formId: number;
  usage: number;
}

@Component({
  selector: 'ca-evaluation-selector',
  templateUrl: './evaluation-selector.component.html',
  styleUrls: ['./evaluation-selector.component.scss'],
})
export class EvaluationSelectorComponent implements OnInit {
  processing: boolean;
  listModal: NgbModalRef;

  dataSource = new MatTableDataSource<SimpleFormDto>();

  displayedColumns: string[] = [];
  sorters: SorterItemDto[] = [];
  filters: FilterItemDto[] = [];

  mostUsedCount: number = 3;
  hasEvaluableForms: boolean = true;
  loading: boolean = false;
  canEvaluateTheirOwnCalls: boolean = false;

  selectedForm: SimpleFormDto;

  @Input()
  conversationId: number;

  @Input()
  isAnalysed: boolean;

  @Input()
  buttonDisabled: boolean;

  @Input()
  player: PlayerComponent;

  private _activeTab: string;

  @Input()
  set activeTab(activeTab: string) {
    this._activeTab = activeTab;
  }

  private _conversationType: number;

  @Input()
  set conversationType(value: number) {
    if (value > 0) {
      this._conversationType = value;

      this.filters.push({
        field: 'active',
        operator: this.operators.Equals,
        value: 'true',
      });

      this.filters.push({
        field: 'includeManual',
        operator: this.operators.Equals,
        value: 'true',
      });

      this.filters.push({
        field: 'conversationType',
        operator: this.operators.Equals,
        value: this._conversationType,
      });

      this.sorters.push({
        field: 'Name',
        direction: 'ASC',
      });

      this.formService
        .getEvaluableFormCount<SimpleFormDto>({
          filters: this.filters.concat([
            {
              field: 'onlyAgentCanAcess',
              operator: this.operators.Equals,
              value: 'true',
            },
            {
              field: 'excludeEvaluatedByMe',
              operator: this.operators.Equals,
              value: this.conversationId,
            },
            {
              field: 'excludeFormsWithNonMatchingQueries',
              operator: this.operators.Equals,
              value: this.conversationId,
            },
          ]),
          sorters: this.sorters,
          maxResultCount: 99999,
          skipCount: 0,
        })
        .subscribe(response => {
          if (response <= 0) {
            this.hasEvaluableForms = false;
          }
        });
    }
  }

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'radio',
      header: '',
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: '55px',
      tooltip: '',
    },
    {
      columnName: 'name',
      header: '',
      binding: 'name',
      valueParser: undefined,
      pipes: undefined,
      width: '100%',
      tooltip: '',
    },
  ];

  private isPlayerPlaying: boolean;
  private playerCurrentTime: number;

  constructor(
    private formService: FormService,
    private modalService: NgbModal,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private operators: Operators,
    private router: Router,
    private permissionService: PermissionService
  ) {
    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }

    this.canEvaluateTheirOwnCalls = this.permissionService.getGrantedPolicy('QualityManagement.CanEvaluateTheirOwnCalls');
  }

  ngOnInit(): void { }

  onRowClick(form: SimpleFormDto) {
    if (this.isFormEvaluable(form)) {
      this.selectedForm = form;
    } else {
      return false;
    }
  }

  evaluate(form: SimpleFormDto) {
    this.closeModal();
    this.saveEvaluationFormUsage(form);

    const queryParams: any = {
      referrer: EvaluationReferrer.conversation,
      referrerTab: this._activeTab,
    };

    if (this.isPlayerPlaying && this.playerCurrentTime >= 0) {
      queryParams.t = this.playerCurrentTime;
    }

    this.router.navigate(
      [
        'quality',
        'evaluate',
        'conversation',
        this.conversationId,
        'with-form-version',
        form.id,
        form.latestFormVersionId,
      ],
      {
        queryParams: queryParams,
      }
    );
  }

  openEvaluationFormsListModal(content) {
    if (this.player) {
      this.isPlayerPlaying = this.player.status === PlayerStatus.Playing;
      this.playerCurrentTime = this.player.currentTime;

      if (this.isPlayerPlaying) {
        this.player.pause();
      }
    }

    this.loading = true;
    this.formService
      .getEvaluableForms<SimpleFormDto>({
        filters: this.filters.concat([
          {
            field: 'onlyAgentCanAcess',
            operator: this.operators.Equals,
            value: 'true',
          },
          {
            field: 'excludeEvaluatedByMe',
            operator: this.operators.Equals,
            value: this.conversationId,
          },
          {
            field: 'excludeFormsWithNonMatchingQueries',
            operator: this.operators.Equals,
            value: this.conversationId,
          },
        ]),
        sorters: this.sorters,
        maxResultCount: 99999,
        skipCount: 0,
      })
      .subscribe(response => {
        if (response.items.length > 1) {
          var forms = [...this.orderByEvaluationFormUsage(response.items)];
          this.selectedForm = this.getLastUsedEvaluationForm(forms);
          if (forms.some(x => x.id === this.selectedForm.id)) {
            this.moveElement(
              forms,
              forms.findIndex(x => x.id === this.selectedForm.id),
              0
            );
          }
          this.dataSource.connect().next(forms);
          this.listModal = this.modalService.open(content, {
            size: 'lg',
            scrollable: true,
            backdrop: 'static',
            keyboard: false,
          });
        } else {
          if (response.items.length === 1) {
            this.evaluate(response.items[0]);
          } else {
            this.hasEvaluableForms = false;
          }
        }
        this.loading = false;
      });

    this.formService
      .getEvaluableForms<SimpleFormDto>({
        filters: this.filters.concat({
          field: 'onlyAgentCanAcess',
          operator: this.operators.Equals,
          value: 'false',
        }),
        sorters: [],
        maxResultCount: 99999,
        skipCount: 0,
      })
      .subscribe(response => {
        this.removeNonExistingEvaluationFormUsage(response.items);
      });
  }

  closeModal(resumePlaying = false) {
    if (this.listModal) {
      this.listModal.close();

      if (resumePlaying && this.isPlayerPlaying && this.playerCurrentTime >= 0) {
        this.player.play(this.playerCurrentTime);
      }
    }
  }

  orderByEvaluationFormUsage(forms: SimpleFormDto[]): SimpleFormDto[] {
    if (localStorage.getItem('EvaluationFormUsage')) {
      let evaluationFormUsages: EvaluationFormUsage[] = [];

      evaluationFormUsages = JSON.parse(localStorage.getItem('EvaluationFormUsage'));
      evaluationFormUsages.sort((x, y) => (x.usage < y.usage ? 1 : x.usage > y.usage ? -1 : 0));

      let mostUsedIndex = 0;

      for (let formUsage of evaluationFormUsages) {
        if (mostUsedIndex < this.mostUsedCount) {
          if (forms.some(x => x.id === formUsage.formId)) {
            this.moveElement(
              forms,
              forms.findIndex(x => x.id === formUsage.formId),
              mostUsedIndex
            );
            mostUsedIndex++;
          }
        } else {
          break;
        }
      }
    }

    return forms;
  }

  moveElement(array: any[], initialIndex: number, finalIndex: number): any[] {
    return array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
  }

  saveEvaluationFormUsage(form: SimpleFormDto) {
    let evaluationFormUsages: EvaluationFormUsage[] = [];

    evaluationFormUsages = JSON.parse(
      localStorage.getItem('EvaluationFormUsage') ?? JSON.stringify(evaluationFormUsages)
    );

    if (evaluationFormUsages.some(x => x.formId === form.id)) {
      let usage = evaluationFormUsages.filter(x => x.formId === form.id)[0].usage;
      evaluationFormUsages.filter(x => x.formId === form.id)[0].usage = ++usage;
    } else {
      evaluationFormUsages.push({ formId: form.id, usage: 1 });
    }

    localStorage.setItem('EvaluationFormUsage', JSON.stringify(evaluationFormUsages));
    localStorage.setItem('LastUsedEvaluationForm', JSON.stringify(form));
  }

  getLastUsedEvaluationForm(forms: SimpleFormDto[]): SimpleFormDto {
    let lastUsedEvaluationForm: SimpleFormDto = JSON.parse(
      localStorage.getItem('LastUsedEvaluationForm')
    );

    if (lastUsedEvaluationForm) {
      lastUsedEvaluationForm = this.isAnalysed
        ? forms.find(f => f.id === lastUsedEvaluationForm.id)
        : forms.find(f => f.id === lastUsedEvaluationForm.id && !f.hasAutomaticQuestion);
    }

    return lastUsedEvaluationForm
      ? lastUsedEvaluationForm
      : this.isAnalysed
        ? forms[0]
        : forms.find(f => !f.hasAutomaticQuestion);
  }

  removeNonExistingEvaluationFormUsage(forms: SimpleFormDto[]) {
    let evaluationFormUsages: EvaluationFormUsage[] = [];
    let tempEvaluationFormUsages: EvaluationFormUsage[] = [];

    evaluationFormUsages = JSON.parse(
      localStorage.getItem('EvaluationFormUsage') ?? JSON.stringify(evaluationFormUsages)
    );

    evaluationFormUsages.forEach(x => {
      if (forms.some(y => x.formId === y.id)) {
        tempEvaluationFormUsages.push(x);
      }
    });

    localStorage.setItem('EvaluationFormUsage', JSON.stringify(tempEvaluationFormUsages));
  }

  isFormEvaluable(evaluationForm: SimpleFormDto) {
    if (this.isAnalysed) {
      return true;
    } else {
      return !evaluationForm.hasAutomaticQuestion;
    }
  }
}
