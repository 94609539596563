<div class="form-group">
  <label for="title" class="h2">{{ 'Settings::AutoDeletionHeader' | abpLocalization }}</label>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group mt-3">
    <input type="checkbox" id="autoDeletionEnabled" formControlName="autoDeletionEnabled" />
    <label class="ps-1" for="autoDeletionEnabled">
      {{ 'Settings::AutoDeletionEnabled' | abpLocalization }}
    </label>
  </div>
  <div class="form-group mt-3">
    <label for="autoDeletionOption">
      {{ 'Settings::AutomaticallyDeleteConversations' | abpLocalization }}</label
    >
    <ca-select
      formControlName="autoDeletionOption"
      [disabled]="autoDeletionOptionDisabled ? true : false"
      ><ca-option *ngFor="let option of autoDeletionOptions" [value]="option.code">
        {{ 'GenericLookup::' + option.code | abpLocalization }}
      </ca-option></ca-select
    >
  </div>
  <div class="form-group mt-3">
    <label for="deleteType"> {{ 'Settings::DeletionScope' | abpLocalization }}</label>
    <ca-select
      formControlName="deletionScopeType"
      [disabled]="deletionScopeTypeDisabled ? true : false"
      ><ca-option *ngFor="let option of deletionScopes" [value]="option.code">
        {{ 'GenericLookup::' + option.code | abpLocalization }}
      </ca-option></ca-select
    >
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
