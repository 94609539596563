<ng-template #attachedDataItemTemplate let-item="item">
  {{ item.name }}
</ng-template>
<ng-template #attachedDataSelectionTemplate let-selection="selection">
  {{ selection[0].name }}
</ng-template>

<input
  #fileInput
  id="fileSelector"
  type="file"
  class="d-none"
  (click)="$event.target.value = ''"
  (change)="onFileChange($event.target.files)"
  accept="{{ acceptFileExtensions.join(',') }}" />

<div class="container">
  <div class="row mt-2">
    <div class="col-12">
      <span
        innerHtml="{{
          'Conversation::UploadTaggingStepInfo' | abpLocalization : strMaxCsvFileSize
        }}"></span>
    </div>
  </div>
  <div class="row mt-4">
    <label for="dateSelector" class="col-form-label col-4 form-label"
      >{{ '::DateRange' | abpLocalization }}
    </label>
    <div class="col-8">
      <ca-date-selection-panel
        #dateSelector
        [config]="dateSelectionConfiguration"></ca-date-selection-panel>
    </div>
  </div>
  <div class="row mt-2">
    <label class="col-form-label col-4 form-label"
      >{{ 'Conversation::TaggingCriteria' | abpLocalization }}
    </label>
    <div class="col-8 d-flex flex-row justify-content-start align-items-center">
      <input
        id="attachedDataRadio"
        type="radio"
        value="AttachedDataRadioValue"
        class="me-1 ms-1"
        name="taggingCriteriaType"
        [checked]="isAttachedData"
        (click)="onTaggingCriteriaTypeChange($event)" />
      <label for="attachedDataRadio" class="radio-label me-1 ms-1">
        {{ '::AttachedData' | abpLocalization }}
      </label>
      <input
        id="calledOrCallerNumberRadio"
        type="radio"
        value="CalledOrCallerNumber"
        name="taggingCriteriaType"
        class="me-1 ms-4"
        [checked]="!isAttachedData"
        (click)="onTaggingCriteriaTypeChange($event)" />
      <label for="calledOrCallerNumberRadio" class="radio-label me-1 ms-1">
        {{ 'Conversation::CalledOrCallerNumber' | abpLocalization }}
      </label>
    </div>
  </div>
  <div class="row mt-2" [hidden]="!isAttachedData">
    <label for="attachedDataSelector" class="col-form-label col-4 form-label"
      >{{ '::AttachedData' | abpLocalization }}
    </label>
    <div class="col-8" [formGroup]="attachedDataForm">
      <ca-attached-data-selector
        [bringToFront]="true"
        formControlName="attachedData"
        placeholder="{{ '::PleaseSelect' | abpLocalization }}..."></ca-attached-data-selector>
    </div>
  </div>
  <div class="row mt-2">
    <label class="col-form-label col-4 form-label">{{ '::File' | abpLocalization }}</label>
    <div class="col-8">
      <button class="btn ca-btn ca-btn-neutral" type="button" (click)="fileInput.click()">
        <i class="far fa-upload"></i>
        <span>{{ '::SelectFile' | abpLocalization }}...</span>
      </button>
      <span class="ms-2" *ngIf="selectedCsvFile?.size > 0">
        {{ selectedCsvFile?.name }}, {{ (selectedCsvFile.size / 1024).toFixed(2) }} KB
      </span>
    </div>
  </div>
</div>
