import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CategoryService } from '../../services/category.service';
import { CategoryDto } from 'src/core/models/query/category.dto';
import { Subject, debounceTime, distinctUntilChanged, take } from 'rxjs';
import { BaseDropdownSelectorComponent } from 'src/ui/base-selector/base-selector.module';
import { CategorySelectorModel } from '../../models/category-selector.model';

@Component({
  selector: 'ca-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CategorySelectorComponent),
    },
  ],
})
export class CategorySelectorComponent
  extends BaseDropdownSelectorComponent<CategorySelectorModel>
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  @Input()
  set conversationType(val: number | null) {
    this._conversationType = val;

    this.conversationType$.next(val);
  }

  @Input()
  disableFn: (category: CategoryDto) => boolean = item => false;

  @Input()
  headerInfo: string;

  @Input()
  headerInfoStyle: unknown;

  get conversationType(): number | null {
    return this._conversationType;
  }

  private conversationType$: Subject<number> = new Subject();
  private _conversationType: number | null = null;

  constructor(
    private service: CategoryService,
    protected elementRef: ElementRef,
    protected ngZone: NgZone
  ) {
    super(elementRef, ngZone);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.attachCategoryInput();
    this.attachConversationTypeId();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  compareFn(a: CategoryDto, b: CategoryDto): boolean {
    return a.id === b.id;
  }

  protected load(): void {
    this.loading = true;

    this.service
      .getCategories(
        this.conversationType,
        this.quickSearchTerm,
        this.filters,
        this.pageIndex,
        this.itemsPerPage
      )
      .pipe(take(1))
      .subscribe(response => {
        this.totalCount = response.totalCount;
        this.items = response.items.map(x => {
          return { ...x, disabled: this.disableFn(x) } as CategorySelectorModel;
        });

        this.loading = false;
      });
  }

  private attachCategoryInput(): void {
    this.quickSearchTermInput$.pipe(debounceTime(250), distinctUntilChanged()).subscribe(val => {
      this.pageIndex = 0;
      this.quickSearchTerm = val;

      this.load();
    });
  }

  private attachConversationTypeId(): void {
    this.conversationType$.pipe(debounceTime(250), distinctUntilChanged()).subscribe(val => {
      this.pageIndex = 0;
      this.load();
    });
  }
}
