import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, first, mergeMap } from 'rxjs/operators';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'ca-conversation-side-selector',
  templateUrl: './conversation-side-selector.component.html',
  styleUrls: ['./conversation-side-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConversationSideSelectorComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ConversationSideSelectorComponent implements OnInit, ControlValueAccessor {
  @Input()
  allSide: boolean = false;

  @Input()
  anySide: boolean = true;

  @Input()
  isFilterPanel: boolean = false;

  conversationSides$: Observable<GenericLookupDto[]>;
  innerValue: number = 0;
  disabled: boolean = false;
  innerSelectedConversationSide: string;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private store: Store, private localizationService: LocalizationService) {}

  get selectedConversationSide(): string {
    return this.innerSelectedConversationSide;
  }

  get value(): number {
    return this.innerValue;
  }

  set value(newValue) {
    this.innerValue = newValue;
    this.conversationSides$.subscribe((sides: GenericLookupDto[]) => {
      this.innerSelectedConversationSide = sides.find(x => x.id == this.innerValue).name;
    });
  }

  getLookup(lookupCode: string): Observable<GenericLookupDto> {
    return this.conversationSides$.pipe(
      first(),
      mergeMap(x => x.filter(y => y.code == lookupCode))
    );
  }

  getTooltipText() {
    const text = this.localizationService.instant(
      'GenericLookup::' + this.innerSelectedConversationSide + '.Label'
    );

    return text;
  }

  writeValue(value: number): void {
    if (value) {
      this.innerValue = value;

      this.conversationSides$.subscribe((sides: GenericLookupDto[]) => {
        let side = sides.find(x => x.id == this.innerValue);
        if (side) {
          this.innerSelectedConversationSide = side.name;
        }
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    let temp$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationSide)));

    if (!this.anySide) {
      temp$ = temp$.pipe(map(data => data.filter(s => s.code != 'Conversation.Side.Any')));
    }

    if (this.allSide) {
      this.conversationSides$ = temp$.pipe(
        map(data => data.sort(a => (a.code == 'Conversation.Side.All' ? -1 : 1))),
        map(data => data.sort(a => (a.code == 'Conversation.Side.Any' ? -1 : 1)))
      );
    } else {
      this.conversationSides$ = temp$.pipe(
        map(data => data.filter(s => s.code != 'Conversation.Side.All'))
      );
    }
  }

  onSelectionChange(newValue) {
    this.onChange(this.value);
    this.onTouched();
  }
}
