import { Component, OnInit } from '@angular/core';
import {
  NgWizardConfig,
  NgWizardService,
  StepChangedArgs,
  StepValidationArgs,
  STEP_STATE,
  THEME,
} from 'ng-wizard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StepDefinition } from 'src/core/models/call/step-definition.model';
import { ConversationBatchTaggingCommunicationService } from './conversation-batch-tagging-communication.service';
import { isObservable } from 'rxjs';
import { ToasterService } from '@abp/ng.theme.shared';
import { LocalizationService, PermissionService } from '@abp/ng.core';
import { ConversationBatchTaggingStepOne } from './steps/step-one/conversation-batch-tagging-step-one.component';
import { ConversationBatchTaggingStepTwo } from './steps/step-two/conversation-batch-tagging-step-two.component';
import { ConversationBatchTaggingDto } from 'src/core/models/conversation/tag/conversation-batch-tagging-filter.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';

@Component({
  selector: 'ca-conversation-batch-tagging',
  templateUrl: './conversation-batch-tagging.component.html',
  styleUrls: ['./conversation-batch-tagging.component.scss'],
})
export class ConversationBatchTaggingComponent implements OnInit {
  stepOneCmp: ConversationBatchTaggingStepOne;
  stepTwoCmp: ConversationBatchTaggingStepTwo;

  selectedStepIndex: number;
  preButtonHidden: boolean = true;
  showNextButton: boolean = false;
  showImportButton: boolean = false;
  isStepTwo: boolean = false;
  stepOneData: any;

  canBatchTaggingConversation: boolean = false;

  progressValue: number = 0;

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden,
  };

  stepDefinitions: any[] = [
    {
      title: this.localizationService.instant('Conversation::UploadList'),
      description: '',
      component: ConversationBatchTaggingStepOne,
      canEnter: this.validateStep.bind(this, 'entry'),
      canExit: this.validateStep.bind(this, 'exit'),
    },
    {
      title: this.localizationService.instant('Conversation::SelectTag'),
      description: '',
      state: STEP_STATE.normal,
      component: ConversationBatchTaggingStepTwo,
      canEnter: this.validateStep.bind(this, 'entry'),
      canExit: this.validateStep.bind(this, 'exit'),
    },
  ];

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.dots,
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
    anchorSettings: {
      anchorClickable: true,
      markDoneStep: false,
      enableAllAnchors: true,
    },
  };

  get closeButtonDisabled(): boolean {
    return this.progressValue < 100;
  }

  constructor(
    private ngWizardService: NgWizardService,
    private modalService: NgbModal,
    private conversationService: ConversationService,
    private communicationService: ConversationBatchTaggingCommunicationService,
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private permissionService: PermissionService
  ) {
    this.canBatchTaggingConversation = this.permissionService.getGrantedPolicy(
      'Conversation.ConversationBatchTagging'
    );
  }

  ngOnInit() {}

  stepChanged(args: StepChangedArgs) {
    this.selectedStepIndex = args.step.index;
    this.showNextButton =
      this.selectedStepIndex === 0 || this.selectedStepIndex === this.stepDefinitions.length - 2;

    this.preButtonHidden = this.selectedStepIndex <= 0 || this.selectedStepIndex % 2 == 0;
    this.isStepTwo = this.selectedStepIndex === this.stepDefinitions.length - 1;

    if (this.selectedStepIndex === 1) {
      if (args.previousStep && args.previousStep.componentRef) {
        this.stepOneData = args.previousStep.componentRef.instance.getData();
      }
    }
  }

  onChangeStepState() {
    return this.stepStates.normal;
  }

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }

  openBatchTaggingModal(content) {
    this.modalService.open(content, { size: 'lg', scrollable: true });
  }

  onCloseModal() {
    this.refreshCommunucationService();
    this.modalService.dismissAll();
  }

  onCancelModal() {
    this.refreshCommunucationService();
    this.modalService.dismissAll();
  }

  isFilesSelected() {
    return this.communicationService.isFilesSelected;
  }

  isValid() {
    return this.communicationService.isValid;
  }

  isTagsSelected() {
    return this.communicationService.isTagsSelected;
  }

  changeProgressValue(eventArgs: { progressValue: number }) {
    this.progressValue = eventArgs.progressValue;
  }

  refreshCommunucationService() {
    this.communicationService.isFilesSelected = false;
    this.communicationService.isTagsSelected = false;
    this.communicationService.isValid = false;
    this.communicationService.tags = [];
  }

  onTagging() {
    if (this.stepOneData) {
      let formData = new FormData();
      formData.append('file', this.stepOneData.file, this.stepOneData.file.name);
      formData.append('startTime', JSON.stringify(this.stepOneData.startTime));
      formData.append('endTime', JSON.stringify(this.stepOneData.endTime));
      formData.append('taggingCriteria', JSON.stringify(this.stepOneData.taggingCriteria));
      formData.append('tags', JSON.stringify(this.communicationService.tags));
      this.conversationService.taggingConversations(formData).subscribe(result => {
        this.toastr.success(
          this.localizationService.instant('Conversation::BatchTaggingRequestHasCreated')
        );
        this.onCloseModal();
      });
    }
  }

  private validateStep(type: string, args: StepValidationArgs) {
    let step = type == 'entry' ? args.toStep : args.fromStep;
    let stepSpecificValidateMethod;

    if (step && step.componentRef) {
      if (!this.stepOneCmp) {
        this.stepOneCmp = step.componentRef.instance;
      }
      stepSpecificValidateMethod =
        type == 'entry'
          ? step.componentRef.instance.validateEntryToStep
          : step.componentRef.instance.validateExitFromStep;
    }

    if (stepSpecificValidateMethod) {
      if (typeof stepSpecificValidateMethod === typeof true) {
        return <boolean>stepSpecificValidateMethod;
      } else if (stepSpecificValidateMethod instanceof Function) {
        stepSpecificValidateMethod = stepSpecificValidateMethod.bind(step.componentRef.instance);
        let result = stepSpecificValidateMethod();

        var isObservableObject = isObservable(result);
        if (isObservableObject as boolean) {
          return result;
        } else if (typeof result === typeof true) {
          return <boolean>result;
        }
      }
    }

    return true;
  }
}
