import { Injectable } from '@angular/core';
import { ColumnConfiguratorModuleStateModel } from './column-configurator-module.state-model';
import { Action, State, StateContext } from '@ngxs/store';
import { GridConfigChange } from './column-configurator-module.actions';
import { GenericState } from 'src/core/states/generic/generic.state';

const name = 'ColumnConfiguratorModuleState';

@State<ColumnConfiguratorModuleStateModel>({
  name: 'ColumnConfiguratorModuleState',
  defaults: ColumnConfiguratorModuleStateModel.getDefaults(),
})
@Injectable()
export class ColumnConfiguratorModuleState extends GenericState<ColumnConfiguratorModuleState> {
  @Action(GridConfigChange)
  changeGridConfig(
    ctx: StateContext<ColumnConfiguratorModuleStateModel>,
    action: GridConfigChange
  ) {
    const currentState = ctx.getState();
    const idx = currentState.grids.findIndex(x => x.id === action.gridConfig.id);

    if (idx > -1) {
      currentState.grids[idx] = action.gridConfig;
    } else {
      currentState.grids.push(action.gridConfig);
    }

    currentState.grids = Array.from(currentState.grids);

    this.userSettingsService.patchState(ctx, currentState, name);
  }
}
