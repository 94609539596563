import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserPhotoComponent } from './user-photo.component';

export { UserPhotoComponent } from './user-photo.component';

@NgModule({
  declarations: [UserPhotoComponent],
  imports: [CommonModule, SharedModule],
  exports: [UserPhotoComponent],
})
export class UserPhotoModule {}
