import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ListeningStatusChange } from 'src/core/actions/conversation/conversation-module.actions';
import { MeetingTranscriptDto } from 'src/core/models/meeting/meeting-transcript.dto';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationListeningStatus } from 'src/core/models/generic-lookup-type/conversation/conversation-listening-status.glt';
import { CrudService } from 'src/core/services/crud/crud.service';
import { Store } from '@ngxs/store';
import { PlayerComponent } from 'src/ca-shared/player/player.module';
import { PlayerStatus } from 'src/ca-shared/player/models/player-status.enum';
import { ConversationMediaType } from 'src/core/models/generic-lookup-type/conversation/conversation-media-type.glt';
import { VideoPlayerService } from 'src/core/services/video-player/video-player.service';
import { LocalizationService } from '@abp/ng.core';
import { VideoCallDto } from 'src/core/models/video-call/video-call.dto';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { ConversationCommentComponentPanel } from '../../conversation-comment-panel/conversation-comment-panel.component';
import { FormatHelper } from 'src/core/helpers/format.helper';
import { VideoCallAnalysisStatus } from 'src/core/models/generic-lookup-type/video-call/video-call-analysis-status.glt';

@Component({
  selector: 'ca-video-call-detail-tab-panel',
  templateUrl: './video-call-detail-tab-panel.component.html',
  styleUrls: ['./video-call-detail-tab-panel.component.scss'],
})
export class VideoCallDetailTabPanelComponent implements OnInit, OnDestroy {
  @ViewChild('player', {
    read: PlayerComponent,
    static: true,
  })
  player: PlayerComponent;

  @ViewChild('transcriptContainer', {
    read: ViewContainerRef,
  })
  transcriptContainer: ViewContainerRef;

  @ViewChild('commentPanel', {
    read: ConversationCommentComponentPanel,
    static: false,
  })
  commentPanel: ConversationCommentComponentPanel;

  transcripts: MeetingTranscriptDto[] = [];
  activeTab: number = 1;
  lastPosition = -1;
  highlightedEls: Element[] = [];
  qualityManagementFeatureEnabled: boolean;
  analyticsFeatureEnabled = false;
  sentimentFetureEnabled: boolean;
  commentCount: number = 0;
  videoCallAnalysisType = VideoCallAnalysisStatus;

  @Input()
  set conversation(value) {
    this._conversation = value;
    if (this._conversation) {
      this.getTranscript();
    }

    this.loadPlayer();
  }

  get conversation(): ConversationDto {
    return this._conversation;
  }

  @Output()
  addCommentRequested: EventEmitter<{
    comment: any;
    startTime: any;
    endTime: any;
  }> = new EventEmitter();

  private _conversation: ConversationDto;
  private _loadPlayerFn;

  constructor(
    private crudService: CrudService,
    private store: Store,
    private renderer: Renderer2,
    private featureService: FeatureService,
    private videoPlayerService: VideoPlayerService,
    private localizationService: LocalizationService,
    private formatHelper: FormatHelper
  ) {
    this.analyticsFeatureEnabled = this.featureService.isEnabled(FeatureConstants.Analytics);
    this.sentimentFetureEnabled = this.featureService.isEnabled(FeatureConstants.SentimentAnalysis);
    this.qualityManagementFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.QualityManagement
    );

    this._loadPlayerFn = this.loadPlayer.bind(this);
  }

  getTranscript() {
    this.crudService
      .getById<MeetingTranscriptDto[]>(VideoCallDto, this.conversation.videoCall.id, 'transcript')
      .subscribe(response => {
        this.transcripts = response;
      });
  }

  ngOnInit(): void { }

  setListeningStatus(eventArgs) {
    const action = new ListeningStatusChange(
      ConversationListeningStatus.listenedBeforeByYou,
      this.conversation.id
    );
    this.store.dispatch(action);
  }

  loadPlayer() {
    if (this.player.status === PlayerStatus.NotInitialized) {
      setTimeout(this._loadPlayerFn, 100);

      return;
    }

    this.setPlayer();
  }

  onAudioProcess(args: { currentTime: number; currentTimeString: string }) {
    const position: number = Math.floor(args.currentTime * 10);
    if (position === this.lastPosition) {
      return;
    }

    this.lastPosition = position;

    if (this.transcriptContainer != null) {
      const targetEl: HTMLElement = this.transcriptContainer.element.nativeElement;
      const targetDivArr: HTMLCollection = targetEl.getElementsByClassName(
        position.toLocaleString()
      );

      if (targetDivArr.length > 0) {
        for (let j = 0; j < this.highlightedEls.length; j++) {
          this.renderer.setStyle(this.highlightedEls[j], 'background-color', 'transparent');
        }

        this.highlightedEls = [];

        for (let i = 0; i < targetDivArr.length; i++) {
          targetDivArr[i].scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
          this.renderer.setStyle(targetDivArr[i], 'background-color', 'yellow');

          this.highlightedEls.push(targetDivArr[i]);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.videoPlayerService.close();
  }

  onAddCommentRequest(eventArgs) {
    this.addCommentRequested.emit(eventArgs);
  }

  onGoToComment(eventArgs) {
    this.activeTab = eventArgs.activeTab;
    const startTime = this.formatHelper.convertStringToTimerSecond(eventArgs.startTime);
    let endTime = 0;

    this.player.play(startTime);

    if (eventArgs.endTime) {
      endTime = this.formatHelper.convertStringToTimerSecond(eventArgs.endTime);
      this.player.addRegion(startTime * 1000, endTime * 1000);
      if (this.player.regions().list['region_1'] != null) {
        this.player.regions().list['region_1'].play();
      }
    }
  }

  private setPlayer() {
    if (this.conversation) {
      this.player.contentSource = `api/app/conversation/${this._conversation.id}/media/${ConversationMediaType.videoRecording}`;

      this.player.peakSource = 'api/app/conversation/' + this.conversation.id + '/waveform';
      this.player.downloadUrl = 'api/app/conversation/' + this.conversation.id + '/download';
      this.player.fileName = this.conversation.id.toString();
      this.player.conversationId = this.conversation.id;

      this.openVideoPlayer();
      this.player.isVideo = true;
      this.player.load(true, false);
    } else {
      this.player.reset();
    }

    this.player.positionChange.subscribe(this.onAudioProcess.bind(this));
  }

  private openVideoPlayer(): void {
    this.videoPlayerService.show(
      `${this.localizationService.instant('Conversation::Conversation')} #${this.conversation.id}`
    );
  }

  onCommentCountChanged(eventArgs) {
    this.commentCount = eventArgs.comments.length;
  }
}
