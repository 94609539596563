import { EntityDto } from '../shared/entity.dto';

export class GenAIYesNoWithRangeQueryItemDto extends EntityDto {
  operator: number;
  categoryId: number;
  categoryItemId: number;
  field: number;
  internalId: number;
  parentInternalId: number;
  queryId: number;
  sideId: number;
  sideCode: string;
  multiple: boolean;
  genAIPromptId: number;
  genAIPrompt: string;
  response: boolean;
  rangeUnitId: number;
  rangeUnitCode: number;
  startTime: number;
  endTime: number;
}
