export enum ExcelType {
  Conversation = 0,
  Evaluation = 1,
  AgentCategory = 2,
  NonFcr = 3,
  AgentObjection = 4,
  AgentPerformance = 5,
  EvaluatorPerformance = 6,
  Category = 7,
  AnalysisAgentPerformance = 8,
}
