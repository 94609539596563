import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConversationAutoDeletionOption } from 'src/core/models/generic-lookup-type/conversation/conversation-auto-deletion-option.glt';
import { DeletionScopeType } from 'src/core/models/generic-lookup-type/conversation/deletion-scope-type.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';

@Component({
  selector: 'ca-auto-deletion-settings',
  templateUrl: './auto-deletion-settings.component.html',
  styleUrls: ['./auto-deletion-settings.component.scss'],
})
export class AutoDeletionSettingsComponent implements OnInit {
  form: FormGroup;
  deletionScopes: GenericLookupDto[];
  autoDeletionOptions: GenericLookupDto[];

  get deletionScopeTypeDisabled(): boolean {
    return !this.form.controls['autoDeletionEnabled']?.value;
  }

  get autoDeletionOptionDisabled(): boolean {
    return !this.form.controls['autoDeletionEnabled']?.value;
  }

  private readonly prefix = 'Conversation';
  private readonly autoDeletionEnabled = `${this.prefix}.AutoDeletionEnabled`;
  private readonly deletionScopeType = `${this.prefix}.DeletionScopeType`;
  private readonly autoDeletionOption = `${this.prefix}.AutoDeletionOption`;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.form = this.fb.group({
      autoDeletionEnabled: [
        JSON.parse(this.config.getSetting(this.autoDeletionEnabled).toLowerCase()),
      ],
      autoDeletionOption: [this.config.getSetting(this.autoDeletionOption)],
      deletionScopeType: [this.config.getSetting(this.deletionScopeType)],
    });

    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationAutoDeletionOption)))
      .subscribe(result => {
        this.autoDeletionOptions = result;
      });

    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(DeletionScopeType)))
      .subscribe(result => {
        this.deletionScopes = result;
      });
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.autoDeletionEnabled,
      settingValue: this.form.get('autoDeletionEnabled').value.toString(),
    });

    settings.push({
      settingName: this.deletionScopeType,
      settingValue: this.form.get('deletionScopeType').value.toString(),
    });

    settings.push({
      settingName: this.autoDeletionOption,
      settingValue: this.form.get('autoDeletionOption').value.toString(),
    });

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
