import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { Sections } from 'src/core/models/administration/section.enum';
import { LayoutStateModel } from 'src/core/models/layout/layout.state-model';
import { SplitConfig } from 'src/core/models/split/split-config.model';
import { CALayoutState } from 'src/core/states/layout/ca-layout.state';
import { LayoutService } from 'src/core/services/layout/layout.service';
import { NotificationService } from 'src/core/services/notification/notification.service';

@Component({
  selector: 'ca-notification-main',
  templateUrl: './notification-main.component.html',
  styleUrls: ['./notification-main.component.scss'],
})
export class NotificationMainComponent implements OnInit {
  splitConfig = SplitConfig.DEFAULT;
  section = Sections.systemNotifications;

  @Select(CALayoutState.layout(Sections.systemNotifications))
  layout$: Observable<LayoutStateModel>;

  constructor(
    private layoutService: LayoutService,
    private notificationService: NotificationService
  ) {
    this.notificationService.requestCountSummary();
  }

  ngOnInit(): void {}

  onDragEnd(eventArgs) {
    this.layoutService.onDragEnd(Sections.systemNotifications, eventArgs);
  }
}
