import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.ListType',
})
export class ConversationListType {
  @GenericLookup({
    code: 'Conversation.ListType.Mixed',
  })
  static mixed: number;

  @GenericLookup({
    code: 'Conversation.ListType.Call',
  })
  static call: number;

  @GenericLookup({
    code: 'Conversation.ListType.Chat',
  })
  static chat: number;

  @GenericLookup({
    code: 'Conversation.ListType.Meeting',
  })
  static meeting: number;

  @GenericLookup({
    code: 'Conversation.ListType.VideoCall',
  })
  static videoCall: number;

  @GenericLookup({
    code: 'Conversation.ListType.Email',
  })
  static email: number;
}
