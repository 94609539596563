import { ConfigStateService, RoutesService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SimpleFeatureDto } from 'src/core/models/feature/simple-feature.dto';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private apiBase = environment.apis.default.url;
  constructor(
    private config: ConfigStateService,
    private routesService: RoutesService,
    private http: HttpClient
  ) {}

  checkRoutesForFeatures(): void {
    const flatItems = this.routesService.flat;

    for (let i = 0; i < flatItems.length; i++) {
      const item = flatItems[i] as any;

      if (Array.isArray(item.forFeatures)) {
        const isEnabled = this.isEnabled(item.forFeatures);

        if (!isEnabled) {
          this.routesService.remove([item.name]);
        }
      }
    }
  }

  isEnabled(features: string | string[]): boolean {
    let result = true;

    if (!Array.isArray(features)) {
      features = [features];
    }

    if (!Array.isArray(features) || features.length <= 0) {
      result = false;
    } else {
      for (let i = 0; i < features.length; i++) {
        let featureEnabled = false;

        try {
          featureEnabled = JSON.parse(this.config.getFeature(features[i]));
        } catch (e) {
          featureEnabled = false;
        }

        result = result && featureEnabled;
      }
    }

    return result;
  }

  isEnabledForTenant(tenantId: string, featureName: string): Observable<boolean> {
    const apiUrl = `${this.apiBase}/api/app/feature/` + tenantId + `/` + featureName;
    return this.http.get(apiUrl) as Observable<boolean>;
  }

  featureForAllTenants(featureName: string): Observable<SimpleFeatureDto[]> {
    const apiUrl = `${this.apiBase}/api/app/feature/all-tenants-feature/` + featureName;
    return this.http.get(apiUrl) as Observable<SimpleFeatureDto[]>;
  }
}
