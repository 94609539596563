import { PermissionService, RoutesService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScopeService {
  constructor(private permissionService: PermissionService, private routesService: RoutesService) {}

  checkRoutesForScopes(): void {
    const flatItems = this.routesService.flat;

    for (let i = 0; i < flatItems.length; i++) {
      const item = flatItems[i] as any;

      if (Array.isArray(item.forRoles)) {
        const isEnabled = this.getAccessScope(item.forRoles);

        if (!isEnabled) {
          this.routesService.remove([item.name]);
        }
      }
    }
  }

  accessScope(role: string): boolean {
    return this.permissionService.getGrantedPolicy(role);
  }

  public get adminAccessScope(): boolean {
    return this.permissionService.getGrantedPolicy('Global.UserAccessScope.AllUsers');
  }

  public get departmentManagerAccessScope(): boolean {
    return this.permissionService.getGrantedPolicy('Global.UserAccessScope.DeparmentUsers');
  }

  public get assignerAccessScope(): boolean {
    return this.permissionService.getGrantedPolicy('Global.UserAccessScope.AssignerUsers');
  }

  public get evaluatorAccessScope(): boolean {
    return this.permissionService.getGrantedPolicy('Global.UserAccessScope.EvaluatorUsers');
  }

  public get agentAccessScope(): boolean {
    return this.permissionService.getGrantedPolicy('Global.UserAccessScope.AgentUsers');
  }

  getAccessScope(role: string): boolean {
    return this.permissionService.getGrantedPolicy(role);
  }

  hasOnlyAgentScope(): boolean {
    return (
      this.agentAccessScope &&
      !(
        this.adminAccessScope ||
        this.departmentManagerAccessScope ||
        this.assignerAccessScope ||
        this.evaluatorAccessScope
      )
    );
  }
}
