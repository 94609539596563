import { Component, OnInit } from '@angular/core';
import { VideoPlayerService } from 'src/core/services/video-player/video-player.service';

@Component({
  selector: 'ca-video-player-icon',
  templateUrl: './video-player-icon.component.html',
  styleUrls: ['./video-player-icon.component.scss'],
})
export class VideoPlayerIconComponent implements OnInit {
  hidden = false;

  constructor(private videoPlayerService: VideoPlayerService) {}

  ngOnInit(): void {
    this.videoPlayerService.minimized$.subscribe(minimized => {
      this.hidden = !minimized;
    });
  }

  bringVideoPlayerToFront(): void {
    this.videoPlayerService.restore();
  }
}
