<div class="main">
  <div
    class="warnings"
    [style.padding]="analysisData?.statusId != callAnalysisType.analyzed ? '15px' : ''">
    <div
      class="m-2"
      *ngIf="analysisData?.statusId == callAnalysisType.notAnalyzed && !recordingFileName">
      {{ 'Conversation::HasNoRecordingFile' | abpLocalization }}
    </div>

    <div
      class="m-2"
      *ngIf="analysisData?.statusId == callAnalysisType.notAnalyzed && recordingFileName">
      {{ 'Conversation::CallNotAnalyzed' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.analyzing">
      {{ 'Conversation::CallIsAnalyzing' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.failed">
      <strong>{{ 'Conversation::CallAnalysisFaild' | abpLocalization }}</strong>
      {{ analysisData?.error }}
    </div>

    <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.noSpeech">
      {{ 'Conversation::CallAnalysisNoSpeech' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.fileNotFound">
      {{ 'Conversation::CallAnalysisFileNotFound' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.notSupportedLanguageCode">
      {{ 'Conversation::CallAnalysisNotSupportedLanguageCode' | abpLocalization }}
    </div>

    <div
      class="m-2"
      *ngIf="analysisData?.statusId == callAnalysisType.languageDetectionServiceDisabled">
      {{ 'Conversation::CallAnalysisLanguageDetectionServiceDisabled' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.notSupportedAudioFormat">
      {{ 'Conversation::CallAnalysisNotSupportedAudioFormat' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisData?.statusId == callAnalysisType.speechRecognitionError">
      {{ 'Conversation::CallAnalysisSpeechRecognitionError' | abpLocalization }}
    </div>
  </div>
  <div
    class="p-1 header"
    style="
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-right: 16px !important;
    ">
    <button
      type="button"
      *ngIf="isTranscriptExportVisible"
      (click)="exportTranscript()"
      class="btn ca-btn ca-btn-neutral mr-2 ml-2">
      <i class="far fa-file-excel"></i
      ><span>{{ 'Conversation::ExportTranscript' | abpLocalization }}</span>
    </button>
    <button
      *ngIf="isTranscriptEditorVisible"
      type="button"
      (click)="onEdit()"
      class="btn ca-btn ca-btn-neutral mr-2 ml-2">
      <i class="far fa-pen"></i><span>{{ 'Conversation::EditTranscript' | abpLocalization }}</span>
    </button>
  </div>
  <div class="center">
    <table
      *ngIf="
        dataTableTranscripts?.length > 0 && analysisData?.statusId == callAnalysisType.analyzed
      "
      mat-table
      [dataSource]="dataTableTranscripts"
      style="width: 100%"
      aria-describedby="TranscriptTable">
      <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

      <!-- StartTime Column -->
      <ng-container matColumnDef="startTime">
        <th scope="col" style="width: 100px; min-width: 100px" mat-header-cell *matHeaderCellDef>
          {{ '::Time' | abpLocalization }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ hideAgentTranscript && element.channel == 1 ? '' : element.time }}
        </td>
      </ng-container>

      <!-- Channel Column -->
      <ng-container matColumnDef="channel">
        <th scope="col" style="width: 64px; min-width: 64px" mat-header-cell *matHeaderCellDef>
          {{ '::Channel' | abpLocalization }}
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <span
            class="fa fa-headphones fa-lg"
            *ngIf="element.channel == 1"
            style="color: rgb(255, 157, 9)"
            title="{{ 'Conversation::Agent' | abpLocalization }}"></span>
          <span
            class="fa fa-user fa-lg"
            *ngIf="element.channel != 1"
            style="color: #6fa8dc"
            title="{{ 'Conversation::Customer' | abpLocalization }}"></span>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th scope="col" style="width: 64px; min-width: 64px" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; let i = index" style="text-align: center">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            *ngIf="
              element.channel === 1 &&
              !hideAgentTranscript &&
              enableDictation &&
              !recording &&
              speechRecognitionResults[i] &&
              recordingRowIndex === i &&
              (!speechRecognitionResults[i].success ||
                speechRecognitionResults[i].distanceScore < pointsToSucceed)
            "
            [disabled]="recording && recordingRowIndex !== i"
            (click)="onClickRecordButton($event, element, i)">
            <i
              class="bi-mic-fill"
              *ngIf="!recording || (recording && recordingRowIndex !== i && enableDictation)"></i>
          </button>
          <i
            class="bi-record-circle blink-me"
            *ngIf="recording && enableDictation && recordingRowIndex === i"
            style="color: red"></i>
        </td>
      </ng-container>

      <!-- Chat Column -->
      <ng-container matColumnDef="chat">
        <th scope="col" style="width: 100%; min-width: 100%" mat-header-cell *matHeaderCellDef>
          {{ '::Chat' | abpLocalization }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <ng-container *ngIf="!editMode">
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
              ">
              <ng-container
                *ngIf="
                  (element.channel === 1 && practiceMode) ||
                  (element.channel === 2 && !practiceMode) ||
                  !enableDictation
                ">
                <div
                  [ngStyle]="{
                    'margin-top': 'auto',
                    'font-style': element.channel === 1 ? 'italic' : 'normal'
                  }">
                  <span
                    #wordContainer
                    [attr.data-time]="(word.startTime / 1000).toFixed(2)"
                    [attr.data-end-time]="(word.endTime / 1000).toFixed(2)"
                    [attr.data-channel]="element.channel"
                    [attr.data-index]="i"
                    *ngFor="let word of element.words"
                    class="word-container">
                    {{ word.word }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="!practiceMode && element.channel == 1 && enableDictation">
                ***
              </ng-container>
              <ng-container *ngIf="practiceMode && enableDictation && element.channel == 2">
                ***
              </ng-container>
              <div style="margin-top: auto">
                <span
                  *ngIf="speechRecognitionResults[i]"
                  style="margin-left: 8px; margin-top: auto; font-size: 14px">
                  <i
                    class="far fa-check-circle"
                    #p1="ngbPopover"
                    (mouseover)="p1.open({ result: speechRecognitionResults[i] })"
                    (mouseleave)="p1.close()"
                    container="body"
                    [ngbPopover]="popContent"
                    [popoverTitle]="popTitle"
                    popoverClass="speech-recognition-result-popover"
                    *ngIf="
                      speechRecognitionResults[i].success &&
                      speechRecognitionResults[i].distanceScore >= pointsToSucceed
                    "
                    style="color: green; vertical-align: middle"></i>
                  <i
                    class="far fa-times-circle"
                    #p2="ngbPopover"
                    (mouseover)="p2.open({ result: speechRecognitionResults[i] })"
                    (mouseleave)="p2.close()"
                    container="body"
                    [ngbPopover]="popContent"
                    [popoverTitle]="popTitle"
                    popoverClass="speech-recognition-result-popover"
                    *ngIf="
                      !speechRecognitionResults[i].success ||
                      speechRecognitionResults[i].distanceScore < pointsToSucceed
                    "
                    style="color: red; vertical-align: middle"></i>
                </span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="editMode">
            <ca-conversation-transcript-editor-input
              (changed)="onTranscriptRowChanged($event, element.channel)"
              [rowInfo]="element"></ca-conversation-transcript-editor-input>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="transcriptDisplayedColumns; sticky: true"></tr>
      <tr
        mat-row
        (dblclick)="onRowDoubleClick($event, row)"
        *matRowDef="let row; columns: transcriptDisplayedColumns"></tr>
    </table>
  </div>
  <div class="footer" *ngIf="editMode">
    <div *ngIf="editMode" class="col p-0">
      <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
        <div class="ca-toolbar-east-section">
          <div class="toolbar-item">
            <button type="button" class="btn ca-btn ca-btn-neutral" (click)="cancelEdit()">
              {{ 'AbpUi::Cancel' | abpLocalization }}
            </button>
          </div>
          <div class="toolbar-item last">
            <button
              class="btn ca-btn ca-btn-primary"
              type="button"
              [disabled]="processing"
              (click)="saveTranscript()">
              <ng-container *ngIf="!processing"
                ><i class="far fa-save"></i><span>{{ 'AbpUi::Save' | abpLocalization }}</span>
              </ng-container>
              <ng-container *ngIf="processing"
                ><i class="fas fa-spinner fa-spin"></i>
                <span>{{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span></ng-container
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #speechRecognitionResultModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      <i
        *ngIf="recognitionStatus !== speechRecognitionStatus.Finished"
        class="fas fa-spinner fa-spin"></i>
      <i
        *ngIf="
          recognitionStatus === speechRecognitionStatus.Finished &&
          this.speechRecognitionResult.success
        "
        class="fas fa-check"
        style="color: green"></i>
      <i
        *ngIf="
          recognitionStatus === speechRecognitionStatus.Finished &&
          !this.speechRecognitionResult.success
        "
        class="fas fa-times"
        style="color: red"></i>
      <span style="margin-left: 8px">{{
        'SpeechRecognition::SpeechRecognitionResult' | abpLocalization
      }}</span>
    </h4>
  </div>
  <div class="modal-body p-2">
    <div class="form-group">
      <label>{{ 'SpeechRecognition::ReferenceText' | abpLocalization }} :</label>
      <textarea
        style="resize: none"
        rows="5"
        class="form-control form-control-sm"
        readonly
        [value]="joinWordsOfRecognizingRowData()"></textarea>
    </div>
    <div class="form-group">
      <label>{{ 'SpeechRecognition::SpeechRecognitionResult' | abpLocalization }} :</label>
      <textarea
        class="form-control form-control-sm"
        rows="5"
        style="resize: none"
        readonly
        [value]="speechRecognitionResult.resultText"></textarea>
    </div>
    <div class="form-group">
      <label>{{ 'SpeechRecognition::Score' | abpLocalization }} :</label>
      <input
        type="text"
        class="form-control form-control-sm"
        readonly
        [value]="
          speechRecognitionResult.distanceScore !== null
            ? speechRecognitionResult.distanceScore + ' / 100'
            : '-'
        " />
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning" (click)="closeSpeechRecognitionResultModal()">
      {{ '::Close' | abpLocalization }}
    </button>
  </div>
</ng-template>

<ng-template #popContent let-result="result">
  <div style="text-align: center; width: 100%">
    <ng-container *ngIf="result.distanceScore >= pointsToSucceed">
      <div
        style="
          border-radius: 50%;
          width: 32px;
          height: 32px;
          color: white;
          font-weight: bold;
          background-color: green;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          margin: auto;
        ">
        {{ result.distanceScore }}
      </div>
      <div *ngIf="practiceMode" style="font-weight: bold">
        {{ 'SpeechRecognition::Perfect' | abpLocalization }}!
      </div>
    </ng-container>
    <ng-container *ngIf="result.distanceScore < pointsToSucceed">
      <div
        style="
          border-radius: 50%;
          font-weight: bold;
          width: 32px;
          height: 32px;
          color: white;
          background-color: red;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          margin: auto;
        ">
        {{ result.distanceScore || 0 }}
      </div>
      <div *ngIf="practiceMode" style="font-weight: bold">
        {{ 'SpeechRecognition::TryAgain' | abpLocalization }}
      </div>
    </ng-container>
    <hr style="margin-top: 12px; margin-bottom: 12px" />
    <div style="font-weight: bold; color: black; font-size: 12pt">
      <div><i class="fas fa-microphone"></i></div>
      <q>
        {{ result.resultText }}
      </q>
      <ng-container *ngIf="!practiceMode">
        <hr style="margin-top: 12px; margin-bottom: 12px" />
        <div><i class="fas fa-file-alt"></i></div>
        <q style="color: green">
          {{ result.referenceText }}
        </q>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #popTitle let-result="result"
  ><b>{{ 'SpeechRecognition::SpeechRecognitionResult' | abpLocalization }}</b></ng-template
>
