import { FormBuilder } from '@angular/forms';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { QuerySelectionConfigurationModel } from './../models/query-selection-configuration.model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { LocalizationService } from '@abp/ng.core';
import { Operators } from 'src/core/models/request/operator.enum';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColorUtils } from 'src/core/helpers/color-utils';
import { QueryItemsCategoriesTupleDto } from 'src/core/models/query/query-items-categories-tuple.dto';
import { QueryDto } from 'src/core/models/query/query.dto';
import { combineLatest, Subscription } from 'rxjs';
import { CrudService } from 'src/core/services/crud/crud.service';
import { QueryItemDto } from 'src/core/models/query/query-item.dto';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { QueryService } from '../../../../../core/services/query/query.service';

@Component({
  selector: 'ca-query-selection-panel',
  templateUrl: './query-selection-panel.component.html',
  styleUrls: ['./query-selection-panel.component.scss'],
})
export class QuerySelectionPanelComponent extends FilterPanelEditorComponent implements OnInit {
  actualConfig: QuerySelectionConfigurationModel;
  _value: QueryDto[];

  queryDropdownOptions: any;
  isQueryPreviewLoading: boolean;
  previewQueryId: number;
  queryPreviewDataCache = new Map<number, QueryItemsCategoriesTupleDto>();
  queryPreviewDataSubscription: Subscription;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as QuerySelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }
  get value(): any {
    return this._value;
  }

  @ViewChild(SelectorComponent, { read: SelectorComponent }) querySelector: SelectorComponent;

  private innerLoad() {
    if (this.extraData.conversationType && this.extraData.conversationType.length > 0) {
      const filters: FilterItemDto[] = [
        {
          field: 'conversationType',
          operator: this.operators.In,
          value: this.extraData.conversationType,
        },
      ];

      this.querySelector.setFilters(filters);
      this.querySelector.refresh();
    } else {
      this.querySelector.setFilters([]);
      this.querySelector.refresh();
    }
  }

  init(): void {
    super.init();

    if (this.actualConfig.autoLoad) {
      this.innerLoad();
    }
  }
 
  autoSelectFirstItem(): void {}

  reload(): void {
    this.innerLoad();
  }

  reset(): void {
    this.querySelector.currentPage = 1;
  }

  toggleQueryPreviewPopover(popover: NgbPopover, query: QueryDto, event: any): boolean {
    this.previewQueryId = query.id;

    if (popover.isOpen()) {
      popover.close();
      this.previewQueryId = 0;
    } else {
      const data = this.queryPreviewDataCache.get(query.id);
      if (data == null) {
        this.isQueryPreviewLoading = true;
        const categoriesRequest = this.queryService.getQueryCategories();
        const queryItemsRequest = this.service.getById<QueryItemDto[]>(QueryDto, query.id, 'items');

        this.queryPreviewDataSubscription = combineLatest([
          categoriesRequest,
          queryItemsRequest,
        ]).subscribe(([categories, queryItems]) => {
          const previewData: QueryItemsCategoriesTupleDto = {
            queryBuilderCategoryData: categories.items,
            queryItems: queryItems,
          };

          this.queryPreviewDataCache.set(query.id, previewData);
          this.isQueryPreviewLoading = false;
          popover.open({ query: query, queryItems: queryItems, categories: categories.items });
        });
      } else {
        popover.open({
          query: query,
          queryItems: data.queryItems,
          categories: data.queryBuilderCategoryData,
        });
      }
    }

    event.stopPropagation();
    return false;
  }

  constructor(
    private operators: Operators,
    private localizationService: LocalizationService,
    public colorUtils: ColorUtils,
    private service: CrudService,
    private queryService: QueryService,
    protected fb: FormBuilder
  ) {
    super(fb);

    this.queryDropdownOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      emptyText: this.localizationService.instant('::SelectQuery'),
      multiple: true,
      url: 'api/app/query',
      currentPage: 1,
      showSelections: false,
      showClearSelections: false,
      showClearSelectionLink: true,
      selectorPosition: 'Top',
    };

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  ngOnInit(): void {}
}
