<ng-template #supervisorItemTemplate let-item="item">
  {{ item.fullName ? item.fullName : '' }}
</ng-template>
<ng-template #supervisorSelectionTemplate let-selection="selection">
  {{
    selection.length === 1
      ? getSelectionName(selection[0])
      : ('User::SelectSupervisor' | abpLocalization)
  }}
</ng-template>

<form [formGroup]="userForm" style="height: 100%" (ngSubmit)="onSubmitForm()" validateOnSubmit>
  <input #hiddenSubmitButton type="submit" class="hidden" />
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="activeTabId"
    (navChange)="onTabChanged($event)"
    [destroyOnHide]="false"
    class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>{{ 'User::UserInfo' | abpLocalization }}</a>
      <ng-template ngbNavContent>
        <div class="col p-4">
          <div class="row form-row">
            <div class="col">
              <span class="indicates-required-field-info">{{
                '::IndicatesRequiredField' | abpLocalization
              }}</span>
            </div>
          </div>
          <div class="row form-row">
            <div class="col-4 p-2">
              <div class="row">
                <label for="name" class="col-sm-4 col-form-label indicates-required-field">{{
                  'User::Name' | abpLocalization
                }}</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    id="name"
                    class="form-control form-control-sm"
                    formControlName="name" />
                </div>
              </div>
            </div>
            <div class="col-4 p-2">
              <div class="row">
                <label for="middleName" class="col-sm-4 col-form-label">
                  {{ 'User::MiddleName' | abpLocalization }}
                </label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="middleName"
                    formControlName="middleName" />
                </div>
              </div>
            </div>
            <div class="col-4 p-2">
              <div class="row">
                <label for="surname" class="col-sm-4 col-form-label indicates-required-field">
                  {{ 'User::Surname' | abpLocalization }}
                </label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="surname"
                    formControlName="surname" />
                </div>
              </div>
            </div>
          </div>
          <div class="row form-row">
            <div class="col-4 p-2">
              <div class="row">
                <label class="col-sm-4 col-form-label indicates-required-field" for="userName">{{
                  'User::Username' | abpLocalization
                }}</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="userName"
                    formControlName="userName" />
                </div>
              </div>
            </div>
            <div class="col-4 p-2">
              <div class="row">
                <label class="col-sm-4 col-form-label indicates-required-field" for="email">{{
                  'User::E-mail' | abpLocalization
                }}</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="email"
                    formControlName="email" />
                </div>
              </div>
            </div>
            <div class="col-4 p-2">
              <div class="row">
                <label
                  class="col-sm-4 col-form-label indicates-required-field"
                  for="departmentSelector"
                  >{{ '::Department' | abpLocalization }}</label
                >
                <div class="col-sm-8">
                  <ca-dropdown-tree
                    id="departmentSelector"
                    [hideToolbar]="true"
                    #departmentDropdownTree
                    [nodeTemplate]="nodeTemplate"
                    [selectionTemplate]="selectionTemplate"
                    [emptyText]="'::SelectDepartment' | abpLocalization"
                    [idProperty]="'id'"
                    [parentIdProperty]="'parentId'"
                    formControlName="department"
                    [styles]="{ width: '100%' }"
                    [width]="'100%'"></ca-dropdown-tree>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-row">
            <div class="col-4 p-2">
              <div class="row">
                <label
                  [ngClass]="
                    requiredPhoneNumber
                      ? 'col-sm-4 col-form-label indicates-required-field'
                      : 'col-sm-4 col-form-label'
                  "
                  for="phoneNumber"
                  >{{ 'User::FirstDeviceId' | abpLocalization }}</label
                >
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="phoneNumber"
                    formControlName="phoneNumber" />
                </div>
              </div>
            </div>
            <div class="col-4 p-2">
              <div class="row">
                <label class="col-sm-4 col-form-label" for="secondPhoneNumber">{{
                  'User::SecondDeviceId' | abpLocalization
                }}</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="secondPhoneNumber"
                    formControlName="secondPhoneNumber" />
                </div>
              </div>
            </div>
            <div class="col-4 p-2">
              <div class="row">
                <label class="col-sm-4 col-form-label" for="pbxAgentId">{{
                  'User::PbxAgentId' | abpLocalization
                }}</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="pbxAgentId"
                    formControlName="pbxAgentId" />
                </div>
              </div>
            </div>
          </div>
          <div class="row form-row">
            <div class="col-4 p-2">
              <div class="row">
                <label class="col-sm-4 col-form-label" for="roleSelectorComponent">{{
                  'User::Role' | abpLocalization
                }}</label>
                <div class="col-sm-8">
                  <ca-dropdown-selector
                    id="roleSelectorComponent"
                    #roleSelectorComponent
                    [url]="roleDropdownOptions.url"
                    [filterProperty]="roleDropdownOptions.filterProperty"
                    [sorterProperty]="roleDropdownOptions.sorterProperty"
                    [pageSize]="roleDropdownOptions.pageSize"
                    [filters]="roleDropdownOptions.filters"
                    [sorters]="roleDropdownOptions.sorters"
                    [itemTemplate]="roleItemTemplate"
                    [selectorPosition]="'Top'"
                    [listSelectionTemplate]="roleListSelectionTemplate"
                    [selectionTemplate]="roleSelectionTemplate"
                    [multiple]="roleDropdownOptions.multiple"
                    [useSimpleFilter]="roleDropdownOptions.useSimpleFilter"
                    [emptyText]="roleDropdownOptions.emptyText"
                    formControlName="role"
                    [styles]="{ width: '100%' }"
                    [listStyles]="{ width: '400px', height: '320px' }"
                    (changed)="onRoleSelectionChanged()"></ca-dropdown-selector>
                </div>
              </div>
            </div>
            <div class="col-4 p-2">
              <div class="row">
                <label class="col-sm-4 col-form-label" for="userSelector">{{
                  'User::Supervisor' | abpLocalization
                }}</label>
                <div class="col-sm-8">
                  <ca-dropdown-selector
                    id="userSelector"
                    #userSelector
                    [url]="userDropdownOptions.url"
                    [filters]="userFilters"
                    [currentPage]="1"
                    [pageSize]="userDropdownOptions.pageSize"
                    [itemTemplate]="supervisorItemTemplate"
                    [selectionTemplate]="supervisorSelectionTemplate"
                    [queryOperator]="userDropdownOptions.queryOperator"
                    [queryField]="userDropdownOptions.queryField"
                    [emptyText]="'User::SelectSupervisor' | abpLocalization"
                    [multiple]="userDropdownOptions.multiple"
                    [idProperty]="'id'"
                    formControlName="supervisor"
                    [styles]="{ width: '100%', 'border-width': '0px' }">
                  </ca-dropdown-selector>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-row">
            <div class="col-4 p-2">
              <div class="row" *ngIf="showPassword">
                <label class="col-sm-4 col-form-label indicates-required-field" for="password">{{
                  'User::Password' | abpLocalization
                }}</label>
                <div class="col-sm-8">
                  <input
                    type="password"
                    class="form-control form-control-sm"
                    id="password"
                    formControlName="password" />
                </div>
              </div>
            </div>
            <div class="col-4 p-2">
              <div class="row" *ngIf="showPassword">
                <label
                  class="col-sm-4 col-form-label indicates-required-field"
                  for="passwordRepeat"
                  >{{ 'User::PasswordRepeat' | abpLocalization }}</label
                >
                <div class="col-sm-8">
                  <input
                    type="password"
                    class="form-control form-control-sm"
                    id="passwordRepeat"
                    formControlName="passwordRepeat" />
                </div>
              </div>
            </div>
          </div>
          <div class="col mt-2">
            <div class="form-group">
              <input
                type="checkbox"
                id="isActive"
                formControlName="isActive"
                (change)="onIsActiveChange($event.currentTarget.checked)" />
              <label class="ps-1" for="isActive">
                {{ 'User::Activate' | abpLocalization }}
              </label>
            </div>
            <div class="form-group">
              <input
                type="checkbox"
                id="ldapUser"
                formControlName="ldapUser"
                (change)="onIsLdapChange($event.currentTarget.checked, user)" />
              <label class="ps-1" for="ldapUser">
                {{ 'User::LdapUser' | abpLocalization }}
              </label>
            </div>
            <div class="form-group">
              <input
                type="checkbox"
                id="lockoutEnabled"
                formControlName="lockoutEnabled"
                [disabled]="!user.extraProperties.IsActive" />
              <label class="ps-1" for="lockoutEnabled">
                {{ 'User::LockAccount' | abpLocalization }}
              </label>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="'permissionsTab'"
      placement="bottom"
      container="body"
      [ngbTooltip]="
        user.id === '' || permissionsDisabled
          ? ('User::RoleDataDirtyInformation' | abpLocalization)
          : ''
      "
      [disabled]="user.id === '' || permissionsDisabled">
      <a ngbNavLink
        >{{ 'AbpIdentity::Permissions' | abpLocalization }}
        <i *ngIf="user.id === '' || permissionsDisabled" class="fas fa-info-circle"></i
      ></a>
      <ng-template ngbNavContent>
        <ca-user-permission-selector #permissionSelector></ca-user-permission-selector>
      </ng-template>
    </li>
    <li
      [ngbNavItem]="'interactionsTab'"
      placement="bottom"
      container="body"
      *ngIf="userGroupFeatureEnabled"
      [ngbTooltip]="
        user.id === '' || !userGroupFeatureEnabled
          ? ('User::RoleDataDirtyTabInformation' | abpLocalization)
          : ''
      "
      [disabled]="user.id === '' || !userGroupFeatureEnabled">
      <a ngbNavLink
        >{{ 'User::Interactions' | abpLocalization }}
        <i *ngIf="user.id === '' || !userGroupFeatureEnabled" class="fas fa-info-circle"></i
      ></a>
      <ng-template ngbNavContent>
        <ca-user-interaction-list #interactionList></ca-user-interaction-list>
      </ng-template>
    </li>
  </ul>

  <div
    [ngbNavOutlet]="nav"
    class="p-0"
    style="height: calc(100% - 61px); overflow-x: hidden; overflow-y: auto"></div>
</form>

<ng-template #selectionTemplate let-selection="selection">
  {{ selection[0].name }}
</ng-template>

<ng-template #roleSelectionTemplate let-selection="selection">
  <span *ngIf="selection.length > 0">
    {{ selection.length }} {{ 'User::RoleSelected' | abpLocalization }}</span
  >
</ng-template>

<ng-template #roleItemTemplate let-item="item">
  {{ item.name }}
</ng-template>

<ng-template #roleListSelectionTemplate let-selection="selection">
  <div [ngbTooltip]="selection.name" container="body" style="display: inline">
    {{ selection.name.length < 16 ? selection.name : (selection.name | slice : 0 : 12) + '...' }}
  </div>
</ng-template>

<ng-template #nodeTemplate let-payload="payload">
  <div>{{ payload.name }}</div>
</ng-template>
