import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-automatic-summarization-error-notification',
  templateUrl: './automatic-summarization-error-notification.component.html',
  styleUrls: ['./automatic-summarization-error-notification.component.scss'],
})
@NotificationType(
  'AutomaticSummarizationErrorNotification',
  AutomaticSummarizationErrorNotificationComponent
)
export class AutomaticSummarizationErrorNotificationComponent implements OnInit {
  data: NotificationDto;

  constructor(private router: Router, private notificationService: NotificationService) {}

  ngOnInit(): void {}

  onClickNotificationRow(eventArgs: MouseEvent): void {
    if (!this.data.markedAsRead) {
      this.notificationService.markAsRead(this.data.id).pipe(take(1)).subscribe();
    }

    this.router.navigate(['setting-management'], {
      state: {
        settings: true,
      },
      queryParams: {
        tabId: 'Settings::OpenAiSettings-tab',
      },
    });
  }

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector
  ) {
    const router = injector.get(Router);
    const message = `${localizationService.instant(
      'Conversation::AutomaticSummarization:NotificationMessage:0'
    )} ${localizationService.instant(
      'Conversation::AutomaticSummarization:NotificationMessage:1'
    )} ${localizationService.instant(
      'Conversation::AutomaticSummarization:NotificationMessage:2'
    )}`;

    const toastrId = toastr.error(message);
    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      notificationService.markAsRead(notificationId).pipe(take(1)).subscribe();
      router.navigate(['setting-management'], {
        state: {
          settings: true,
        },
        queryParams: {
          tabId: 'Settings::OpenAiSettings-tab',
        },
      });
    });
  }
}
