<div>
  <h2>{{ 'Settings::Indexing' | abpLocalization }}</h2>
</div>
<br />
<hr />
<div></div>
<form [formGroup]="indexingSettingsForm">
  <div class="mt-3">
    <label for="queryCleanupStartTime">
      {{ 'Settings::Indexing.QueryCleanupStartTime' | abpLocalization }}
      <i
        class="ms-2 fas fa-info-circle"
        ngbTooltip="{{ 'Settings::Indexing.QueryCleanupStartTimeDescription' | abpLocalization }}"
        container="body"
      ></i>
    </label>
    <input
      type="time"
      class="form-control form-control-sm"
      id="queryCleanupStartTime"
      formControlName="queryCleanupStartTime"
    />
  </div>
  <button type="button" (click)="onSubmitIndexingSettings()" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
