import { LocalizationService } from '@abp/ng.core';
import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { CAUserDto } from 'src/core/models/administration/user/CAUser.dto';
import { AgentListFilterModeOption } from 'src/core/models/generic-lookup-type/identity/agent-list-filter-mode-option.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserAutoCompleteSelectorInputModel } from './models/user-auto-complete-selector-input.model';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { AutoCompleteSelectorComponent } from '../selector/components/auto-complete-selector/auto-complete-selector.component';
import { UserRoleSelectionEnum } from '../filter-panel/children/user-selection/models/user-role-selection.enum';
import { EvaluatorFilterModeOption } from 'src/core/models/generic-lookup-type/identity/evaluator-filter-mode-option.glt';
import { UserFilterModeOption } from 'src/core/models/generic-lookup-type/identity/user-filter-mode-option.glt';

@Component({
  selector: 'ca-user-auto-complete-selector',
  templateUrl: './user-auto-complete-selector.component.html',
  styleUrls: ['./user-auto-complete-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UserAutoCompleteSelectorComponent),
    },
  ],
})
export class UserAutoCompleteSelectorComponent implements OnInit, ControlValueAccessor {
  _userRoleSelection: UserRoleSelectionEnum;
  // tslint:disable-next-line: variable-name
  private _disabledItemMessage: string;
  // tslint:disable-next-line: variable-name
  private _disabledItemList: CAUserDto[] = [];

  @ViewChild('autoCompleteSelector', { static: true, read: AutoCompleteSelectorComponent })
  autoCompleteSelector: AutoCompleteSelectorComponent;

  @Input()
  hideFilterMode: boolean = false;

  @Input()
  showEmail: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  nullElement: any = null;

  @Input()
  nullElementTerm: string = '';

  @Input()
  appendToBody: boolean = true;

  @Input()
  filterByStatus: boolean = false;

  @Input()
  set disabledItemList(disabledItemList: CAUserDto[]) {
    this._disabledItemList = disabledItemList;
  }

  get disabledItemList() {
    return this._disabledItemList;
  }

  @Input()
  set disabledItemMessage(message: string) {
    this._disabledItemMessage = message;
  }

  get disabledItemMessage() {
    return this._disabledItemMessage;
  }

  @Input()
  set filters(value: FilterItemDto[]) {
    this._filters = value;
  }

  get filters(): FilterItemDto[] {
    return this._filters;
  }

  @Input()
  disableFn: (user: any) => boolean = item => false;

  queryOperator: number;
  autoCompleteSelectorOptions: any;
  filterModes: GenericLookupDto[];
  _filters: FilterItemDto[] = [];
  noUserSelected: string;
  userPhotoComponentStyles: any = {
    width: '25px',
    height: '25px',
    'border-radius': '50%',
  };
  private _value: UserAutoCompleteSelectorInputModel;

  statusItems = [
    { id: 1, label: this.localizationService.instant('::Active'), class: 'active-filter-icon' },
    { id: 2, label: this.localizationService.instant('::Passive'), class: 'passive-filter-icon' },
    { id: 3, label: this.localizationService.instant('::All'), class: 'all-filter-icon' },
  ];
  currentStatus = this.localizationService.instant('::Active');
  disableTooltip = false;

  set value(val: UserAutoCompleteSelectorInputModel) {
    this._value = val;
  }

  get value(): UserAutoCompleteSelectorInputModel {
    return this._value;
  }

  @Input()
  set userRoleSelection(value: UserRoleSelectionEnum) {
    this._userRoleSelection = value ?? UserRoleSelectionEnum.Agent;
  }

  constructor(
    private operators: Operators,
    private localizationService: LocalizationService,
    private store: Store
  ) { }

  ngOnInit(): void {
    var emptyText = this.localizationService.instant('::SelectAgents');
    this.noUserSelected = this.localizationService.instant('::NoAgentSelected');

    if (this._userRoleSelection === UserRoleSelectionEnum.Agent) {
      this.filters.push({
        field: 'onlyAgents',
        operator: this.operators.Equals,
        value: true,
      });

      this.store
        .select(GenericLookupTypeState.getGenericLookups)
        .pipe(map(filterFn => filterFn(AgentListFilterModeOption)))
        .subscribe(result => {
          this.filterModes = result;
        });
    } else if (this._userRoleSelection === UserRoleSelectionEnum.Evaluator) {
      this.filters.push({
        field: 'onlyEvaluators',
        operator: this.operators.Equals,
        value: true,
      });
      emptyText = this.localizationService.instant('::SelectEvaluators');
      this.noUserSelected = this.localizationService.instant('::NoEvaluatorSelected');

      this.store
        .select(GenericLookupTypeState.getGenericLookups)
        .pipe(map(filterFn => filterFn(EvaluatorFilterModeOption)))
        .subscribe(result => {
          this.filterModes = result;
        });
    } else if (this._userRoleSelection === UserRoleSelectionEnum.User) {
      emptyText = this.localizationService.instant('::SelectUsers');
      this.noUserSelected = this.localizationService.instant('::NoUserSelected');

      this.store
        .select(GenericLookupTypeState.getGenericLookups)
        .pipe(map(filterFn => filterFn(UserFilterModeOption)))
        .subscribe(result => {
          this.filterModes = result;
        });
    }

    this.autoCompleteSelectorOptions = {
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchFilter',
      emptyText: emptyText,
      multiple: true,
      url: this.getApiUrl(CAUserDto),
      selectionText: this.localizationService.instant('::Selections'),
    };
  }

  protected getApiUrl(type = DtoHasApi): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0 ? apiBase + '/' + type.apiUrl : type.apiUrl;
  }

  writeValue(obj: UserAutoCompleteSelectorInputModel): void {
    obj = obj
      ? obj
      : {
        selectedUsers: [],
        filterModeId: AgentListFilterModeOption.SelectedAgents,
      };
    this.value = obj;
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }
  onChange: Function = (_: UserAutoCompleteSelectorInputModel) => { };
  onTouched: Function = (_: UserAutoCompleteSelectorInputModel) => { };

  onFilterModeChange() {
    if (this.value.filterModeId == AgentListFilterModeOption.AllAgentsExceptSelectedAgent) {
      if (this.value.selectedUsers != null) {
        this.value.selectedUsers = this.value.selectedUsers.concat(this.disabledItemList);
      }
    } else if (this.value.filterModeId == AgentListFilterModeOption.SelectedAgents) {
      this.disabledItemList.forEach(disabledElement => {
        const index = this.value.selectedUsers.indexOf(disabledElement);
        if (index > -1) {
          this.value.selectedUsers.splice(index);
        }
      });
    }
    this.applyChanges();
  }

  onSelectionChange() {
    this.applyChanges();
  }

  changeStatusFilter(eventArgs) {
    this.currentStatus = eventArgs.label;
    let isEdited = false;
    let currFilters = [...this.filters];
    currFilters = currFilters as FilterItemDto[];
    const activeFilterCount = currFilters.filter(x => x.field == 'isActive').length;
    const passiveFilterCount = currFilters.filter(x => x.field == 'isPassive').length;
    currFilters = currFilters.filter(x => x.field != 'isActive');
    currFilters = currFilters.filter(x => x.field != 'isPassive');
    if (eventArgs.id == this.statusItems[0].id && activeFilterCount == 0) {
      currFilters.push({
        field: 'isActive',
        operator: this.operators.Equals,
        value: true,
      });
      isEdited = true;
    } else if (eventArgs.id == this.statusItems[1].id && passiveFilterCount == 0) {
      currFilters.push({
        field: 'isPassive',
        operator: this.operators.Equals,
        value: true,
      });
      isEdited = true;
    } else if (
      eventArgs.id == this.statusItems[2].id &&
      (activeFilterCount > 0 || passiveFilterCount > 0)
    ) {
      isEdited = true;
    }

    if (isEdited) {
      this.filters = [...currFilters];
      this.applyChanges();
    }
  }

  applyChanges() {
    this.onChange(ObjectHelper.deepCopy(this.value));
  }

  public setFilters(filters: FilterItemDto[]) {
    this.filters = [...this.filters, ...filters];
    this.autoCompleteSelector.clear();
  }
}
