import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-idle-users-count-notification',
  templateUrl: './idle-users-count-notification.component.html',
  styleUrls: ['./idle-users-count-notification.component.scss'],
})
@NotificationType(
  'IdleUsersCountNotification',
 IdleUsersCountNotificationComponent
)
export class IdleUsersCountNotificationComponent implements OnInit {
  data: NotificationDto;

  get idleUserCount(): string {
    return this.data ? JSON.parse(this.data.payload).idleUserCount : '';
  }
  
  constructor(private notificationService: NotificationService, private router: Router) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const parsedPayload = JSON.parse(payload);
    let message = localizationService.instant(
      'User::IdleUsersCountMessage',
      parsedPayload.idleUserCount
    );
    var toastrId = toastr.success(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap
      .pipe(take(1))
      .subscribe(() => this.toasterClickedHandler(notificationService, notificationId, parsedPayload, router));
  }

  static toasterClickedHandler(
    notificationService: NotificationService,
    notificationId: string,
    payload: any,
    router: Router
  ) {
    notificationService.markAsRead(notificationId).subscribe();
    this.navigateToUser(payload, router);
  }

  static navigateToUser(payloadData: any, router: Router) {
    const queryParams: any = {
      lastLoginDate: 1,
    };

    router.navigate(['identity', 'users'], {
      queryParams: queryParams,
    });
  }

  onClick() {
    if (!this.data.markedAsRead) {
      this.notificationService.markAsRead(this.data.id).subscribe();
    }
  }
}
