import { DtoHasApi } from './dto-has-api.model';
import { Api } from 'src/core/decorators/dto/api.decorator';

@Api('api/identity/roles')
export class RoleDto extends DtoHasApi {
    name: string;
    isDefault: boolean;
    isStatic: boolean;
    isPublic: boolean;
    id: string;
}
