import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../shared/dto-has-api.model';
import { IQueryBuilderCategoryItem } from './query-builder-category-item.interface';

@Api('api/app/query-builder-category')
export class QueryBuilderCategoryDto extends DtoHasApi {
  id: number;
  title: string;
  cssClass: string;
  active: boolean;
  visible: boolean;
  items: IQueryBuilderCategoryItem[];
}
