import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { DashboardModuleStateModel } from 'src/core/models/dashboard/dashboard-module.state-model';

import { GenericState } from '../generic/generic.state';
import {
  AgentPerformanceDetailSorterChange,
  AgentPerformanceSorterChange,
  WidgetFilterChange,
  WidgetsChange,
} from 'src/core/actions/quality/quality-dashboard-module.actions';
const name = 'QualityDashboardModuleState';
@State<DashboardModuleStateModel>({
  name: name,
  defaults: {
    widgets: {},
  } as DashboardModuleStateModel,
})
@Injectable()
export class QualityDashboardModuleState extends GenericState<QualityDashboardModuleState> {
  constructor(userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;

    this.userSettingsService.register(this.name, this);
  }

  @Action(WidgetsChange)
  changeWidgets(ctx: StateContext<DashboardModuleStateModel>, action: WidgetsChange) {
    const currentState = ctx.getState();

    if (!Array.isArray(currentState.widgets)) {
      currentState.widgets = [];
    }
    action.widgets.forEach(widget => {
      let stateWidget = currentState.widgets.find(x => x.component == widget.component);
      if (!stateWidget) {
        currentState.widgets.push(widget);
      } else {
        stateWidget.widgetConfigs = widget.widgetConfigs;
      }
    });
    currentState.widgets.forEach(widget => {
      let stateWidget = action.widgets.find(x => x.component == widget.component);
      if (!stateWidget && !widget.default) {
        const index = currentState.widgets.map(x => x.component).indexOf(widget.component);
        currentState.widgets.splice(index, 1);
      }
    });
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Action(WidgetFilterChange)
  changeWidgetFilter(ctx: StateContext<DashboardModuleStateModel>, action: WidgetFilterChange) {
    let currentState = ctx.getState();
    if (!Array.isArray(currentState.widgets)) {
      currentState.widgets = [];
    }

    let currentWidgetState = currentState.widgets.find(x => x.component == action.component);
    if (!currentWidgetState) {
      currentState.widgets.push({
        component: action.component,
        default: true,
        widgetFilters: action.filters,
      });
    } else {
      currentWidgetState.widgetFilters = action.filters;
    }
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Selector()
  static agentPerformanceGetSortField(state: DashboardModuleStateModel): string {
    var a = state.agentPerformanceSort == null ? 'AgentName' : state.agentPerformanceSort.active;
    return a;
  }

  @Selector()
  static agentPerformanceGetSortDirection(state: DashboardModuleStateModel): 'asc' | 'desc' | '' {
    return state.agentPerformanceSort == null ? 'asc' : state.agentPerformanceSort.direction;
  }

  @Action(AgentPerformanceSorterChange)
  agentPerformanceChangeSorter(
    ctx: StateContext<DashboardModuleStateModel>,
    action: AgentPerformanceSorterChange
  ) {
    const currentState = ctx.getState();

    currentState.agentPerformanceSort = action.sort;
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  @Selector()
  static agentPerformanceDetailGetSortField(state: DashboardModuleStateModel): string {
    var a =
      state.agentPerformanceDetailSort == null ? 'Month' : state.agentPerformanceDetailSort.active;
    return a;
  }

  @Selector()
  static agentPerformanceDetailGetSortDirection(
    state: DashboardModuleStateModel
  ): 'asc' | 'desc' | '' {
    return state.agentPerformanceDetailSort == null
      ? 'desc'
      : state.agentPerformanceDetailSort.direction;
  }

  @Action(AgentPerformanceDetailSorterChange)
  agentPerformanceDetailChangeSorter(
    ctx: StateContext<DashboardModuleStateModel>,
    action: AgentPerformanceDetailSorterChange
  ) {
    const currentState = ctx.getState();

    currentState.agentPerformanceDetailSort = action.sort;
    this.userSettingsService.patchState(ctx, currentState, name);
  }
}
