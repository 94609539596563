import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConversationMainComponent } from './conversation-main/conversation-main.component';
import { ApplicationLayoutComponent } from '@volo/abp.ng.theme.lepton';
import { ConversationDetailMainComponent } from './conversation-detail/conversation-detail-main/conversation-detail-main.component';
import { ConversationReportDownloadComponent } from './conversation-report-download/conversation-report-download.component';

const routes: Routes = [
  {
    path: '',
    component: ApplicationLayoutComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ConversationMainComponent,
            data: {
              storageKey: 'conversation',
              reuseRoutesFrom: ['conversation/:id'],
            },
          },
          {
            path: 'result/:resultId',
            component: ConversationMainComponent,
          },
          {
            path: ':id',
            component: ConversationDetailMainComponent,
          },
          {
            path: 'report/:id',
            component: ConversationReportDownloadComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConversationRoutingModule {}
