import { FormGroupStatus } from 'src/core/constants/form/form-group-status.constant';
import { ValidationMessageService } from 'src/core/services/validation/validation-message.service';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { GenericLookupSelectionType } from './../models/generic-lookup-selection-type.enum';
import { Component, Input, OnInit } from '@angular/core';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { GenericLookupSelectionConfigurationModel } from '../models/generic-lookup-selection-configuration.model';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { map } from 'rxjs/operators';
import { OrderHelper } from 'src/core/helpers/order.helper';

@Component({
  selector: 'ca-generic-lookup-selection-panel',
  templateUrl: './generic-lookup-selection-panel.component.html',
  styleUrls: ['./generic-lookup-selection-panel.component.scss'],
})
export class GenericLookupSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit
{
  genericLookups$: Observable<GenericLookupDto[]>;
  genericLookups: GenericLookupDto[];
  selectionType = GenericLookupSelectionType;
  errorMessage: string[] = [];
  randomKey: string;

  _value: any;

  @Input()
  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as GenericLookupSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  get value(): any {
    if (
      this._value &&
      this._value.length &&
      this.actualConfig &&
      this.actualConfig.valuesToExclude
    ) {
      this._value = this._value.filter(v => !this.actualConfig.valuesToExclude.includes(v));
    }
    return this._value;
  }

  set value(value: any) {
    if (value && value.length && this.actualConfig && this.actualConfig.valuesToExclude) {
      value = value.filter(v => !this.actualConfig.valuesToExclude.includes(v));
    }
    this._value = value;

    this.editorForm.patchValue({
      editor: this.value,
    });
  }

  get labelClass() {
    let cls = '';
    if (this.actualConfig.conversationInline === true) {
      cls = 'col-5 custom-padding';
    } else if (this.actualConfig.evaluationInline === true) {
      cls = 'col-6 custom-padding';
    }
    return cls;
  }

  actualConfig: GenericLookupSelectionConfigurationModel;

  init(): void {
    super.init();

    this.genericLookups$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(this.actualConfig.genericLookupType)));

    if (
      this.actualConfig.type == this.selectionType.Combobox &&
      this.actualConfig.valuesToExclude != null
    ) {
      this.genericLookups$ = this.genericLookups$.pipe(
        map(items => items.filter(item => !this.actualConfig.valuesToExclude.includes(item.id)))
      );
    }

    if (this.actualConfig.orderValues != null && this.actualConfig.orderValues.length > 0) {
      this.genericLookups$ = this.genericLookups$.pipe(
        map(list => this.orderHelper.getOrderedGenericLookupList(list, this.actualConfig.orderValues))
        );
    }
  }

  isVisible(genericLookup: GenericLookupDto) {
    return (
      (this.actualConfig.valuesToExclude &&
        this.actualConfig.valuesToExclude.indexOf(genericLookup.id) < 0) ||
      this.actualConfig.valuesToExclude == null
    );
  }

  reload(): void {}

  reset(): void {}

  autoSelectFirstItem(): void {}

  onCheckChanged(eventArgs) {
    if (
      this.actualConfig.type == this.selectionType.Combobox &&
      this.actualConfig.multiple === true
    ) {
      this.value = [];
      if (eventArgs.length > 0) {
        this.value = eventArgs;
      }
    } else if (this.actualConfig.type == this.selectionType.Radio) {
      const val = Number(eventArgs.currentTarget.value);

      this.value = [val];
    } else {
      const val = Number(eventArgs.currentTarget.value);
      const checked = eventArgs.currentTarget.checked;
      const idx = this.value.indexOf(val);

      if (checked) {
        if (idx < 0) {
          this.value.push(val);
        }
      } else {
        if (idx > -1) {
          this.value.splice(idx, 1);
        }
      }
    }

    this.editorForm.patchValue({
      editor: this.value,
    });

    this.onChange();
  }

  onSelectChange(eventArgs) {
    const val = Number(eventArgs);

    if (this.value !== val) {
      this.value = val;

      this.onChange();
    }
  }

  constructor(
    private store: Store,
    protected fb: FormBuilder,
    private orderHelper: OrderHelper,
    private validationMessageService: ValidationMessageService
  ) {
    super(fb);

    this.editorForm.statusChanges.subscribe(x => {
      if (x === FormGroupStatus.INVALID) {
        for (const error in this.editorForm.controls.editor.errors) {
          if (this.editorForm.controls.editor.errors.hasOwnProperty(error)) {
            const errorMessage = this.validationMessageService[error];
            this.errorMessage.push(errorMessage);
          }
        }
      } else {
        this.errorMessage = [];
      }
    });

    this.randomKey = new Date().valueOf().toString();
  }

  ngOnInit(): void {}
}
