import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ConversationMarkStateModel } from 'src/core/models/conversation/conversation-mark.state-model';
import { DataChange } from 'src/core/actions/conversation/conversation-mark.actions';
import { MarksStateModel } from 'src/core/models/conversation/marks-data.state-model';
import { ConversationMarkType } from 'src/core/models/generic-lookup-type/conversation/conversation-mark-type.glt';
import { ConversationMarkChannel } from 'src/core/models/generic-lookup-type/conversation/conversation-mark-channel.glt';
import { Injectable } from '@angular/core';

@State<ConversationMarkStateModel>({
  name: 'ConversationMarkState',
  defaults: {
    marks: [],
  } as ConversationMarkStateModel,
})
@Injectable()
export class ConversationMarkState {
  @Selector()
  static getData(state: ConversationMarkStateModel): MarksStateModel[] {
    if (state.marks.length === 0) {
      state.marks = this.getDefault();
    }
    return state.marks;
  }

  private static getDefault(): MarksStateModel[] {
    return [
      {
        name: 'callOverlapDuration',
        checked: false,
        color: 'rgba(128, 193, 141, 0.6)',
        code: 'ConversationMark.Type.Overlap',
        channelId: [
          ConversationMarkChannel.undefined,
          ConversationMarkChannel.agent,
          ConversationMarkChannel.customer,
        ],
      },
      {
        name: 'callMaxOverlapDuration',
        checked: false,
        color: 'rgba(255,208,128, 0.6)',
        code: 'ConversationMark.Type.MaxOverlap',
        channelId: ConversationMarkChannel.undefined,
      },
      {
        name: 'callMaximumSilenceDuration',
        checked: false,
        color: 'rgba(128, 130, 254, 0.6)',
        code: 'ConversationMark.Type.MaxSilence',
        channelId: ConversationMarkChannel.undefined,
      },
      {
        name: 'agentBlockRatio',
        checked: false,
        color: 'rgba(104, 183, 220, 0.6)',
        code: 'ConversationMark.Type.Block',
        channelId: ConversationMarkChannel.agent,
      },
      {
        name: 'customerBlockRatio',
        checked: false,
        color: 'rgba(255, 128, 128, 0.6)',
        code: 'ConversationMark.Type.Block',
        channelId: ConversationMarkChannel.customer,
      },
      {
        name: 'agentTensionRatio',
        checked: false,
        color: 'rgba(189, 0, 3, 1)',
        code: 'ConversationMark.Type.Emotion',
        channelId: ConversationMarkChannel.agent,
      },
      {
        name: 'customerTensionRatio',
        checked: false,
        color: 'rgba(255, 71, 73, 1)',
        code: 'ConversationMark.Type.Emotion',
        channelId: ConversationMarkChannel.customer,
      },
      {
        name: 'callHoldCount',
        checked: false,
        color: 'rgba(210, 208, 208, 1)',
        code: 'ConversationMark.Type.Hold',
        channelId: ConversationMarkChannel.undefined,
      },
      {
        name: 'callIvrHoldCount',
        checked: false,
        color: 'rgba(186, 185, 185, 1)',
        code: 'ConversationMark.Type.IVRHold',
        channelId: ConversationMarkChannel.undefined,
      },
    ];
  }

  @Action(DataChange)
  changeData(ctx: StateContext<ConversationMarkStateModel>, action: DataChange) {
    const currentState = ctx.getState();
    currentState.marks = Array.from(action.payload.marks);

    ctx.patchState(currentState);
  }
}
