import { FormControl, ValidatorFn } from '@angular/forms';
import { ValidationHelper } from 'src/core/helpers/validation-helper';

export class SimpleTermValidator {
  static Validator: ValidatorFn = (control: FormControl) => {
    if (!control.value) {
      return null;
    }

    let text: string = control.value.toString();

    if (text.includes('"')) {
      return { queryTermQuotesNotRequired: true };
    }

    var regex = ValidationHelper.getSpecialCharacterRegex();
    if (regex.test(text)) {
      return { queryTermInvalidPattern: true };
    }

    let indices = ValidationHelper.getIndicesOf('*', text);

    if (indices.length > 1) {
      return { queryTermMultipleAsteriks: true };
    }

    if (indices.length === 1 && indices[0] != text.length - 1) {
      return { queryTermWrongAsteriksPosition: true };
    }

    return null;
  };
}
