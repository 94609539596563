import { LocalizationService } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { EvaluationReferrer } from 'src/core/constants/evaluation-referrer.constant';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { FormatHelper } from 'src/core/helpers/format.helper';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { FormVersionDto } from 'src/core/models/quality-management/form-version.dto';
import { EvaluationDto } from 'src/core/models/quality/evaluation/evaluation.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { ScopeService } from 'src/core/services/administration/scope.service';
import { FeatureService } from 'src/core/services/feature/feature.service';

@Component({
  selector: 'ca-conversation-evaluation-detail-panel',
  templateUrl: './conversation-evaluation-detail-panel.component.html',
  styleUrls: ['./conversation-evaluation-detail-panel.component.scss'],
})
export class ConversationEvaluationDetailPanel implements OnInit {
  dataSource = new MatTableDataSource<EvaluationDto>();
  displayedColumns: string[] = [];
  hasOnlyAgentScope: boolean;
  evaluationReferrer = EvaluationReferrer;
  evaluationDurationFeatureEnabled = false;
  private _activeTab: number;

  @Input()
  evaluations: EvaluationDto[];

  @Input()
  set activeTab(activeTab: number) {
    this._activeTab = activeTab;
  }

  get activeTab(): number {
    return this._activeTab;
  }

  @Output()
  loaded = new EventEmitter();

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'creationTime',
      header: this.localizationService.instant('QualityManagement::EvaluationTime'),
      binding: 'creationTime',
      valueParser: val => {
        return new Date(val);
      },
      pipes: [
        {
          pipe: CADatePipe,
          args: [DateDisplayType.DateTimeWithoutSeconds, true],
        },
      ],
      width: '24%',
      tooltip: undefined,
    },
    {
      columnName: 'formVersion',
      header: this.localizationService.instant('QualityManagement::Form'),
      binding: 'evaluationMaster.formVersion',
      valueParser: (val: FormVersionDto) => {
        let formVersionStr = val.formName;
        formVersionStr += ' - V' + val.version;
        return formVersionStr;
      },
      pipes: undefined,
      width: '26%',
      tooltip: undefined,
    },
    {
      columnName: 'totalPoint',
      header: this.localizationService.instant('QualityManagement::Score'),
      binding: 'totalPoint',
      valueParser: (val, evaluation) => {
        val = val.toFixed(1);

        let scoreStr = val;
        scoreStr += ' / ' + evaluation.evaluationMaster.maxPoint;
        return scoreStr;
      },
      pipes: undefined,
      width: '30%',
      tooltip: undefined,
    },
  ];

  constructor(
    private localizationService: LocalizationService,
    private scopeService: ScopeService,
    public formatHelper: FormatHelper,
    private featureService: FeatureService
  ) {
    this.evaluationDurationFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.EvaluationDurationStopWatch
    );
    this.hasOnlyAgentScope = this.scopeService.hasOnlyAgentScope();

    if (!this.hasOnlyAgentScope) {
      this.gridColumns.splice(1, 0, {
        columnName: 'evaluator',
        header: this.localizationService.instant('QualityManagement::Evaluator'),
        binding: 'evaluator.fullName',
        valueParser: undefined,
        pipes: undefined,
        width: '20%',
        tooltip: undefined,
      });
    }

    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }
  }

  ngOnInit(): void {
    this.dataSource.connect().next(this.evaluations);
    this.loaded.emit();
  }
}
