import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ToasterService } from '@abp/ng.theme.shared';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';

@Component({
  selector: 'ca-email-summary',
  templateUrl: './email-summary.component.html',
  styleUrls: ['./email-summary.component.scss']
})
export class EmailSummaryComponent implements OnInit {
  @Output()
  categorySelectionChanged: EventEmitter<{
    selectedCategories: number[];
  }> = new EventEmitter();

  @Input()
  set conversation(value: ConversationDto) {
    this._conversation = value;
  }

  @Input()
  set categories(categories: ConversationCategoryDto[]) {
    this._categories = categories;
  }

  get categories(): ConversationCategoryDto[] {
    return this._categories;
  }

  get conversationTime(): string {
    if (this._conversation?.startTime) {
      let date = new Date(this._conversation?.startTime);
      return this.caDatePipe.transform(date, DateDisplayType.DateTime, true);
    } else {
      return '';
    }
  }

  get conversation(): ConversationDto {
    if (this._conversation) {
      return this._conversation;
    } else return new ConversationDto();
  }

  userPhotoComponentStyles: any = {
    width: '64px',
    height: '64px',
    border: '2px solid rgba(0, 0, 0, .12)',
    'border-radius': '100%',
  };

  private _conversation: ConversationDto;
  private _categories: ConversationCategoryDto[];
  constructor(
    private toastr: ToasterService,
    private caDatePipe: CADatePipe,
  ) { }

  ngOnInit(): void { }

  copyToClipboard() {
    this.toastr.success('::CopiedToClipboard', '', {});
  }

  onCategorySelectionChanged(event) {
    this.categorySelectionChanged.emit(event);
  }

  getEmailText(text): string {
    let splits = text ? text?.split(';') : [];
    return splits.join('\n');
  }
}
