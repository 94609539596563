import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { TopicDto } from 'src/core/models/conversation/ai-topic/topic.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { TopicSelectionConfigurationModel } from '../models/topic-selection-configuration.model';

@Component({
  selector: 'ca-topic-selection-panel',
  templateUrl: './topic-selection-panel.component.html',
  styleUrls: ['./topic-selection-panel.component.scss'],
})
export class TopicSelectionPanelComponent extends FilterPanelEditorComponent implements OnInit {
  actualConfig: TopicSelectionConfigurationModel;

  _value: TopicDto[];
  topicSelectorOptions: any;

  @ViewChild(SelectorComponent, { read: SelectorComponent })
  topicSelector: SelectorComponent;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as TopicSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  constructor(
    private localizationService: LocalizationService,
    private operators: Operators,
    protected fb: FormBuilder
  ) {
    super(fb);

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  ngOnInit(): void {
    this.topicSelectorOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      emptyText: this.localizationService.instant('::SelectTopic'),
      multiple: this.actualConfig.multiple,
      url: 'api/app/topic',
      currentPage: 1,
      showSelections: false,
      showClearSelections: false,
      showClearSelectionLink: true,
      selectorPosition: 'Top',
    };
  }

  reload(): void {}

  reset(): void {}
    
  autoSelectFirstItem(): void {}

  init() {
    super.init();

    if (this.actualConfig.autoLoad) {
      this.topicSelector.refresh();
    }
  }
}
