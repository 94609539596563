<div class="main">
  <div
    class="warnings"
    [style.padding]="analysisStatusId != meetingAnalysisType.analyzed ? '15px' : ''">
    <div class="m-2" *ngIf="analysisStatusId == meetingAnalysisType.notAnalyzed">
      {{ 'Conversation::CallNotAnalyzed' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisStatusId == meetingAnalysisType.analyzing">
      {{ 'Conversation::CallIsAnalyzing' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisStatusId == meetingAnalysisType.failed">
      {{ 'Conversation::CallAnalysisFaild' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisStatusId == meetingAnalysisType.noSpeech">
      {{ 'Conversation::CallAnalysisNoSpeech' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisStatusId == meetingAnalysisType.fileNotFound">
      {{ 'Conversation::CallAnalysisFileNotFound' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisStatusId == meetingAnalysisType.notSupportedLanguageCode">
      {{ 'Conversation::CallAnalysisNotSupportedLanguageCode' | abpLocalization }}
    </div>

    <div
      class="m-2"
      *ngIf="analysisStatusId == meetingAnalysisType.languageDetectionServiceDisabled">
      {{ 'Conversation::CallAnalysisLanguageDetectionServiceDisabled' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisStatusId == meetingAnalysisType.notSupportedAudioFormat">
      {{ 'Conversation::CallAnalysisNotSupportedAudioFormat' | abpLocalization }}
    </div>

    <div class="m-2" *ngIf="analysisStatusId == meetingAnalysisType.speechRecognitionError">
      {{ 'Conversation::CallAnalysisSpeechRecognitionError' | abpLocalization }}
    </div>
  </div>
  <div class="center">
    <table
      *ngIf="dataTableTranscripts?.length > 0"
      mat-table
      [dataSource]="dataTableTranscripts"
      style="width: 100%"
      aria-describedby="TranscriptTable">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- StartTime Column -->
      <ng-container matColumnDef="startTime">
        <th scope="col" style="width: 100px; min-width: 100px" mat-header-cell *matHeaderCellDef>
          {{ '::Time' | abpLocalization }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.time }}
        </td>
      </ng-container>

      <!-- Channel Column -->
      <ng-container matColumnDef="channel">
        <th scope="col" style="width: 150px; min-width: 150px" mat-header-cell *matHeaderCellDef>
          {{ '::Channel' | abpLocalization }}
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: left">
          <span style="color:{{ getChannelColor(element.channel) }}"> {{ element.channel }} </span>
        </td>
      </ng-container>

      <!-- Chat Column -->
      <ng-container matColumnDef="chat">
        <th scope="col" style="width: 100%; min-width: 100%" mat-header-cell *matHeaderCellDef>
          {{ '::Chat' | abpLocalization }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <ng-container>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
              ">
              <ng-container>
                <div
                  [ngStyle]="{
                    'margin-top': 'auto',
                    'font-style': element.channel === 1 ? 'italic' : 'normal'
                  }">
                  <span
                    #wordContainer
                    [attr.data-time]="(word.startTime / 1000).toFixed(2)"
                    [attr.data-end-time]="(word.endTime / 1000).toFixed(2)"
                    [attr.data-channel]="element.channel"
                    [attr.data-index]="i"
                    *ngFor="let word of element.words"
                    class="word-container">
                    {{ word.word }}
                  </span>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="transcriptDisplayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: transcriptDisplayedColumns"></tr>
    </table>
  </div>
</div>
