import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.CustomerSentiment',
})
export class ConversationCustomerSentiment {
  @GenericLookup({
    code: 'Conversation.CustomerSentiment.VeryNegative',
  })
  static veryNegative: number;

  @GenericLookup({
    code: 'Conversation.CustomerSentiment.Negative',
  })
  static negative: number;

  @GenericLookup({
    code: 'Conversation.CustomerSentiment.Neutral',
  })
  static neutral: number;

  @GenericLookup({
    code: 'Conversation.CustomerSentiment.Positive',
  })
  static positive: number;

  @GenericLookup({
    code: 'Conversation.CustomerSentiment.VeryPositive',
  })
  static veryPositive: number;

  @GenericLookup({
    code: 'Conversation.CustomerSentiment.Unknown',
  })
  static unknown: number;
}
