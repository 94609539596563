import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CAUserDto } from 'src/core/models/administration/user/CAUser.dto';
import { Observable } from 'rxjs';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { ProfilePictureResultDto } from 'src/core/models/shared/profile-picture-result.dto';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  @Output()
  currentUserPhotoChanged = new EventEmitter();
  private cache: any = {};
  constructor(private http: HttpClient) {}

  getProfilePictureFromAbp(userId: string): Observable<ProfilePictureResultDto> {
    const url = `${environment.apis.AbpAccountPublic.url}/api/account/profile-picture/${userId}`;

    return this.http.get(url, {
      headers: { 'hide-errors': 'true' },
    }) as Observable<ProfilePictureResultDto>;
  }

  uploadProfile(file: FormData, userId: string) {
    const url = this.getApiUrl(CAUserDto) + '/' + userId + '/photo';

    return this.http.post<any>(url, file, {
      reportProgress: true,
      observe: 'events',
    });
  }

  downloadProfile(userId: string): Observable<File> {
    const url = this.getApiUrl(CAUserDto) + '/' + userId + '/photo';
    var req = this.http.get<File>(url, {
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
    if (!this.cache[userId]) {
      this.cache[userId] = req.pipe(shareReplay(1));
    }

    return this.cache[userId];
  }

  downloadProfileThumbnail(userId: string): Observable<File> {
    const url = this.getApiUrl(CAUserDto) + '/' + userId + '/photo-thumbnail';
    var req = this.http.get<File>(url, {
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
    var key = userId + '-thumbnail';
    if (!this.cache[key]) {
      this.cache[key] = req.pipe(shareReplay(1));
    }

    return this.cache[key];
  }

  getProfileImageUrl(userId: string): string {
    return this.getApiUrl(CAUserDto) + '/' + userId + '/photo';
  }

  removeProfile(userId: string) {
    const url = this.getApiUrl(CAUserDto) + '/' + userId + '/photo';

    return this.http.delete<any>(url);
  }

  protected getApiUrl(type = DtoHasApi): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0 ? apiBase + '/' + type.apiUrl : type.apiUrl;
  }
}
