import { Component, OnInit, Input } from '@angular/core';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { CallDirection } from 'src/core/models/generic-lookup-type/call/call-direction.glt';
import { CallReleasingParty } from 'src/core/models/generic-lookup-type/call/call-releasing-party.glt';
import { ConversationListeningStatus } from 'src/core/models/generic-lookup-type/conversation/conversation-listening-status.glt';

@Component({
    selector: 'ca-conversation-icon-container',
    templateUrl: './conversation-icon-container.component.html',
    styleUrls: ['./conversation-icon-container.component.scss'],
})
export class ConversationIconContainerComponent implements OnInit {
    @Input()
    conversation: ConversationDto;

    @Input()
    showConversationType = true;

    @Input()
    inDetail = false;

    @Input()
    sentimentFeatureEnabled = false;

    conversationType = ConversationType;
    callDirection = CallDirection;
    callReleasingParty = CallReleasingParty;
    callListeningStatus = ConversationListeningStatus;

    constructor() { }

    ngOnInit() { }
}
