import { SplitAreaConfig } from './split-area-config.model';

export class LayoutConfig {
  static DEFAULT: LayoutConfig = {
    west: {
      size: 400,
      maxSize: 400,
      minSize: 400,
      visible: false,
    },
    east: {
      size: 700,
      visible: false,
    },
    center: {
      size: '*',
      visible: true,
    },
  };

  west: SplitAreaConfig;
  east: SplitAreaConfig;
  center: SplitAreaConfig;
}
