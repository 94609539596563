import { EntityDto } from '../shared/entity.dto';

export class OrderedGroupQueryItemDto extends EntityDto {
  categoryId: number;
  categoryItemId: number;
  internalId: number;
  parentInternalId: number;
  queryId: number;
  multiple: boolean;
}
