import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { AttachedDataFilterDto } from 'src/core/models/administration/attached-data/attached-data-filter.dto';
import { AttachedDataLookupDto } from 'src/core/models/administration/attached-data/attached-data-lookup.dto';
import { AttachedDataDto } from 'src/core/models/administration/attached-data/attached-data.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { AttachedDataService } from 'src/core/services/administration/attached-data.service';
import { CrudService } from 'src/core/services/crud/crud.service';
import { AttachedDataSelectionConfigurationModel } from '../models/attached-data-selection-configuration.model';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';

@Component({
  selector: 'ca-attached-data-selection-panel',
  templateUrl: './attached-data-selection-panel.component.html',
  styleUrls: ['./attached-data-selection-panel.component.scss'],
})
export class AttachedDataSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit
{
  actualConfig: AttachedDataSelectionConfigurationModel;

  _value: any;
  attachedDataList: AttachedDataDto[];
  selectedAttachedDataId: any = -1;
  selectedAttachedDataLookupList: AttachedDataLookupDto[] = [];

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as AttachedDataSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value?.id,
      attachedDataValue: value?.value,
    });
  }

  get value(): any {
    if (this._value?.editor > 0) {
      if (this.attachedDataList) {
        const attachedData = this.attachedDataList.find(x => x.id == this._value.editor);

        if (attachedData == null) {
          return null;
        }

        return {
          id: attachedData.id,
          value: this._value.attachedDataValue,
          name: attachedData.name,
        } as AttachedDataFilterDto;
      } else {
        return {
          id: this._value.editor,
          value: this._value.attachedDataValue,
          name: '',
        } as AttachedDataFilterDto;
      }
    }

    if (!this.selectedAttachedDataId) {
      this.selectedAttachedDataId = '-1';
    }

    return null;
  }

  get isSelectedAttachedDataMultipleChoice(): boolean {
    const result = this.selectedAttachedData != null && this.selectedAttachedData.isMultipleChoice;
    return result;
  }

  get selectedAttachedData(): AttachedDataDto {
    if (
      this.attachedDataList &&
      this.selectedAttachedDataId != null &&
      this.selectedAttachedDataId > 0
    ) {
      return this.attachedDataList.find(a => a.id == this.selectedAttachedDataId);
    } else {
      return null;
    }
  }

  get hasLabel(): boolean {
    return this.actualConfig.label && this.actualConfig.label.length > 0;
  }

  get attachedDataSelectionClass(): string {
    if (this.hasLabel) {
      return 'col no-padding-left';
    }
    return 'col';
  }

  autoSelectFirstItem(): void {}

  attachedDataSelectionChanged(event): void {
    this.editorForm.get('attachedDataValue').reset();
    if (event > 0) {
      this.selectedAttachedDataId = event;
      this.arrangeAttachedDataInput();
    }
  }

  arrangeAttachedDataInput(): void {
    if (this.isSelectedAttachedDataMultipleChoice) {
      this.attachedDataService
        .getAttachedDataLookups(this.selectedAttachedDataId)
        .subscribe(response => {
          this.selectedAttachedDataLookupList = response;
          if (this.value?.value == null && this.selectedAttachedDataLookupList.length > 0) {
            this.editorForm
              .get('attachedDataValue')
              .setValue(this.selectedAttachedDataLookupList[0].value);
          }
        });
    } else {
      this.selectedAttachedDataLookupList = [];
    }
  }

  private loadData() {
    var filters: FilterItemDto[] = [
      {
        field: 'visible',
        operator: this.operators.Equals,
        value: true,
      },
    ];

    if (this.actualConfig.onlyNonFcrGroupByAttachedData === true) {
      filters.push({
        field: 'onlyNonFcrGroupByAttachedData',
        operator: this.operators.Equals,
        value: true,
      });
    }

    this.service
      .get<AttachedDataDto>(AttachedDataDto, {
        filters: filters,
        sorters: [],
        maxResultCount: 1000,
        skipCount: 0,
      })
      .subscribe(data => {
        this.attachedDataList = data.items;

        if (this.value?.id > 0) {
          this.selectedAttachedDataId = this.value.id;
          this.arrangeAttachedDataInput();
        }
      });
  }

  constructor(
    protected fb: FormBuilder,
    private attachedDataService: AttachedDataService,
    private service: CrudService,
    private operators: Operators
  ) {
    super(fb);

    this.editorForm.addControl('attachedDataValue', this.fb.control(''));

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val;
      this.onChange();
    });
  }

  ngOnInit(): void {}

  reload(): void {
    this.loadData();
  }

  reset(): void {}

  init() {
    super.init();

    if (this.actualConfig.autoLoad) {
      this.loadData();
    }
  }
}
