import { ConfigStateService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImpersonationService } from '@volo/abp.commercial.ng.ui/config';
import { RoleConstants } from 'src/core/constants/role.constant';
import { UserDto } from 'src/core/models/shared/user.dto';

@Component({
  selector: 'ca-user-detail-toolbar',
  templateUrl: './user-detail-toolbar.component.html',
  styleUrls: ['./user-detail-toolbar.component.scss'],
})
export class UserDetailToolbarComponent implements OnInit {
  get impersonateVisibility(): boolean {
    var currentUser = this.configStateService.getDeep('currentUser');

    if (
      this.user.id != '' &&
      currentUser.impersonatorUserId == null &&
      currentUser.id != this.user.id
    ) {
      if (currentUser.roles.includes(RoleConstants.admin)) {
        return true;
      }
      let roles = this.user.roleNames;
      return !roles?.includes(RoleConstants.admin);
    } else {
      return false;
    }
  }
  @Input()
  user: UserDto;

  @Input()
  processing = false;

  @Output()
  deleteRequest = new EventEmitter();

  @Output()
  saveRequest: EventEmitter<{
    closeAfterSave: boolean;
  }> = new EventEmitter();

  constructor(
    private impersonationService: ImpersonationService,
    private configStateService: ConfigStateService,
    private toastrService: ToasterService
  ) {}

  ngOnInit(): void {}

  onClickSaveAndClose(eventArgs: MouseEvent): void {
    this.saveRequest.emit({ closeAfterSave: true });
  }

  onClickSave(eventArgs: MouseEvent): void {
    this.saveRequest.emit({ closeAfterSave: false });
  }

  onClickDelete(eventArgs: MouseEvent): void {
    this.deleteRequest.emit();
  }

  onClickLoginWithUser(eventArgs: MouseEvent) {
    this.impersonateUser();
  }

  private impersonateUser() {
    if (this.user.id != null) {
      this.impersonationService.impersonateUser(this.user.id).subscribe(
        x => {},
        response => {
          if (response.error) {
            this.toastrService.warn(response.error.error);
          } else {
            console.error(response);
          }
        }
      );
    }
  }
}
