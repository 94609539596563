import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl implements OnDestroy {
  constructor(
    private localizationService: LocalizationService,
    private config: ConfigStateService
  ) {
    super();
    this.getAndInitTranslations();
    this.localizationService.languageChange$.subscribe(res => {
      this.getAndInitTranslations();
    });
  }

  ngOnDestroy(): void {}

  getAndInitTranslations() {
    this.nextPageLabel = this.localizationService.instant('::NextPage');
    this.previousPageLabel = this.localizationService.instant('::PreviousPage');
    this.firstPageLabel = this.localizationService.instant('::FirstPage');
    this.lastPageLabel = this.localizationService.instant('::LastPage');

    this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  };
}
