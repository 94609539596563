<ng-template #rt let-item="result" let-t="term">
  <div *ngIf="item.index === -1" class="typeahead-container">
    <h5 class="typeahead-header">{{ 'Query::Templates' | abpLocalization }}</h5>
  </div>
  <div *ngIf="item.index >= 0">
    <ngb-highlight [result]="item[title]" [term]="t"></ngb-highlight>
  </div>
</ng-template>

<div>
  <input
    #inputField
    type="text"
    placeholder="{{ placeholder }}"
    class="{{ class }}"
    (change)="onInputChange()"
    (input)="onInputChange()"
    (focus)="onInputFocus()"
    (blur)="onInputBlur()"
    (selectItem)="selectItem($event)"
    [resultTemplate]="rt"
    [inputFormatter]="formatter"
    [popupClass]="'template-selection-popup'"
    [ngbTypeahead]="search" />
</div>
