import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { DepartmentSelectionConfigurationModel } from '../models/department-selection-configuration.model';
import { DropdownTreeNodeModel } from 'src/ca-shared/dropdown-tree/dropdown-tree.module';
import { DepartmentSelectorInputModel } from 'src/ca-shared/department-selector/models/department-selector-input.model';

@Component({
  selector: 'ca-department-selection-panel',
  templateUrl: './department-selection-panel.component.html',
  styleUrls: ['./department-selection-panel.component.scss'],
})
export class DepartmentSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit {
  actualConfig: DepartmentSelectionConfigurationModel;
  _value: DepartmentSelectorInputModel;
  departmentDropdownOptions: any;
  departmentTreeData: DropdownTreeNodeModel[] = [];

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as DepartmentSelectionConfigurationModel;
  }

  get config(): DepartmentSelectionConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.setValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  constructor(protected fb: FormBuilder) {
    super(fb);

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  ngOnInit(): void { }

  init() {
    super.init();
  }

  reload(): void { }

  reset(): void { }

  autoSelectFirstItem(): void { }
}
