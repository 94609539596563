import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { UserFeedbackDto } from 'src/core/models/user-feedback/user-feedback.dto';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';

@Injectable({
  providedIn: 'root',
})
export class UserFeedbackService {
  constructor(private http: HttpClient) {}

  create(feedback: UserFeedbackDto): Observable<UserFeedbackDto> {
    const url = this.getApiUrl(UserFeedbackDto) + '/create';
    return this.http.post(url, feedback) as Observable<UserFeedbackDto>;
  }

  protected getApiUrl(type = DtoHasApi): string {
    const apiBase = environment.apis.default.url;
    return apiBase + '/' + type.apiUrl;
}
}
