import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'ActionLog.TargetType',
})
export class ActionLogTargetType {
  @GenericLookup({
    code: 'ActionLog.TargetType.Conversation',
  })
  static conversation: number;
}
