<ng-template #popContent>
  <form
    *ngIf="!feedbackSent"
    class="feedback-popover-container form-group"
    (ngSubmit)="sendFeedback()"
    [formGroup]="feedbackForm"
  >
    <h2>{{ '::UserFeedback:Title' | abpLocalization }}</h2>
    <span>{{ '::UserFeedback:PersonalInfoWarning' | abpLocalization }}</span>
    <div class="form-group">
      <textarea class="mt-2 feedback-textarea form-control" formControlName="feedbackComment">
      </textarea>
    </div>
    <button type="submit" class="btn ca-btn ca-btn-neutral mt-2 feedback-send-button">
      {{ '::Send' | abpLocalization }}
    </button>
  </form>
  <div *ngIf="feedbackSent" class="feedback-sent-container">
    <span>{{ '::UserFeedback:ThankYou' | abpLocalization }}</span>
  </div>
</ng-template>

<div *ngIf="feedbackFeatureEnabled">
  <button
    [ngbPopover]="popContent"
    popoverClass="feedback-popover"
    #feedbackPopover="ngbPopover"
    class="feedback-button"
    placement="auto"
    container="body"
    [autoClose]="'outside'"
  >
    <i class="far fa-comment" style="color: #ff6600"></i>
    {{ 'CA::UserFeedbackEmail:Feedback' | abpLocalization }}
  </button>
</div>
