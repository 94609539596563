<ng-container [formGroup]="editorForm">
  <div class="row display-inline">
    <div class="col-5">
      <label *ngIf="actualConfig.label != null && actualConfig.label != ' '" class="label-padding">
        {{ actualConfig.label | abpLocalization }}
      </label>
    </div>
    <div class="col no-padding-left">
      <ca-select formControlName="editor" (change)="onSelectChange($event)"
        ><ca-option
          *ngIf="
            actualConfig.showAllOption == null ||
            (actualConfig.showAllOption !== null && actualConfig.showAllOption)
          "
          [value]="'-1'">
          {{ '::All' | abpLocalization }}
        </ca-option>
        <ca-option [value]="'true'">
          {{ actualConfig.trueOptionText | abpLocalization }}
        </ca-option>
        <ca-option [value]="'false'">
          {{ actualConfig.falseOptionText | abpLocalization }}
        </ca-option></ca-select
      >
    </div>
  </div>
</ng-container>
