import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateFormatConstants {
  static readonly dateFormats: string[] = [
    'dd/mm/yyyy',
    'mm/dd/yyyy',
    'yyyy/mm/dd',
    'yyyy/dd/mm',
    'dd.mm.yyyy',
    'mm.dd.yyyy',
    'yyyy.mm.dd',
    'yyyy.dd.mm',
  ];
}
