import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CorporateIdentitySettingsOutputDto } from 'src/core/models/configuration-setting/corporate-identity-settings-output.dto';

@Injectable({
  providedIn: 'root',
})
export class CorporateIdentitySettingsService {
  private apiBase = environment.apis.default.url;

  constructor(private http: HttpClient) {}

  save(formData: FormData) {
    return this.http.post(
      `${this.apiBase}/api/app/configuration-settings/corporate-identity`,
      formData
    );
  }

  get(): Observable<CorporateIdentitySettingsOutputDto> {
    return this.http.get(
      `${this.apiBase}/api/app/configuration-settings/corporate-identity`
    ) as Observable<CorporateIdentitySettingsOutputDto>;
  }
}
