import { Component, OnInit, Input, ElementRef, AfterContentInit } from '@angular/core';

@Component({
  selector: 'ca-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class OptionComponent implements OnInit, AfterContentInit {
  @Input()
  value: any;

  @Input()
  label: string;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    if (this.element.nativeElement.innerHTML) {
      this.label = this.element.nativeElement.innerText;
    }
  }
}
