<ng-template
  #previewQueryPopContent
  let-queryItems="queryItems"
  let-categories="categories"
  let-query="query">
  <div *ngIf="!query.lastQueryVersionIndexed">
    {{ 'Query::IndexingContinuesMessage' | abpLocalization }}
  </div>
  <ca-query-detail-builder
    #builder
    [isReadonly]="true"
    (abpInit)="builder.loadQueryItems(queryItems, categories, query)"></ca-query-detail-builder>
</ng-template>

<ng-template #previewQueryPopTitle let-query="query">
  <div
    *ngIf="query.isCategory"
    class="badge category-badge ps-1"
    [ngStyle]="colorUtils.getColors(query.categoryColor)"
    [title]="query.categoryName">
    {{ query.categoryName }}
  </div>
  <div *ngIf="!query.isCategory">
    {{ query.name }}
  </div>
</ng-template>

<ng-template #loaded>
  <mat-icon>info_outline</mat-icon>
</ng-template>

<ng-template #itemTemplate let-item="item">
  <div class="query-selection-panel-query-name">
    <div class="line-ellipsis">{{ item.name }}</div>
    <div class="query-selection-panel-info-button">
      <button
        type="button"
        mat-icon-button
        [ngStyle]="{
          color: !item.lastQueryVersionIndexed ? 'rgb(255, 157, 9)' : 'rgb(0, 0, 0)'
        }"
        style="vertical-align: top"
        #popover="ngbPopover"
        [ngbPopover]="previewQueryPopContent"
        [popoverTitle]="previewQueryPopTitle"
        popoverClass="query-preview-popover"
        placement="right auto"
        triggers="manual"
        container="body"
        [autoClose]="'outside'"
        (click)="toggleQueryPreviewPopover(popover, item, $event)">
        <div *ngIf="isQueryPreviewLoading && item.id === previewQueryId; else loaded">
          <mat-progress-spinner
            class="black-spinner"
            style="margin: 0 auto; padding: 0px"
            mode="indeterminate"
            diameter="16"></mat-progress-spinner>
        </div>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #selectionTemplate let-selection="selection">
  {{ selection.name.length < 16 ? selection.name : (selection.name | slice : 0 : 12) + '...' }}
</ng-template>

<ng-container [formGroup]="editorForm">
  <ca-selector
    #querySelector
    [url]="queryDropdownOptions.url"
    [pageSize]="queryDropdownOptions.pageSize"
    [itemTemplate]="itemTemplate"
    [selectionTemplate]="selectionTemplate"
    [queryOperator]="queryDropdownOptions.queryOperator"
    [queryField]="queryDropdownOptions.queryField"
    [emptyText]="queryDropdownOptions.emptyText"
    [showSelections]="queryDropdownOptions.showSelections"
    [showClearSelections]="queryDropdownOptions.showClearSelections"
    [showClearSelectionLink]="queryDropdownOptions.showClearSelectionLink"
    [selectorPosition]="queryDropdownOptions.selectorPosition"
    [styles]="{ width: '100%', 'border-width': '0px' }"
    formControlName="editor"></ca-selector>
</ng-container>
