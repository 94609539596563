import { Injectable } from '@angular/core';
import { NgWizardConfig, StepValidationArgs, THEME } from 'ng-wizard';
import { isObservable, Observable, of } from 'rxjs';
import { StepDefinition } from 'src/core/models/call/step-definition.model';
import { CallImportStepOne } from './steps/call-steps/step-one/call-import-step-one.component';
import { CallImportStepThree } from './steps/call-steps/step-three/call-import-step-three.component';
import { CallImportStepTwo } from './steps/call-steps/step-two/call-import-step-two.component';
import { ChatImportStepOne } from './steps/chat-steps/step-one/chat-import-step-one.component';
import { ChatImportStepThree } from './steps/chat-steps/step-three/chat-import-step-three.component';
import { ChatImportStepTwo } from './steps/chat-steps/step-two/chat-import-step-two.component';
import { ConversationImportStepZero } from './steps/step-zero/conversation-import-step-zero.component';

@Injectable()
export class ConversationImportWizardService {
  constructor() {}

  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.dots,
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
    anchorSettings: {
      anchorClickable: true,
      markDoneStep: false,
      enableAllAnchors: true,
    },
  };

  exitButtonDisabled: boolean = true;

  stepDefinitions: StepDefinition[] = [
    {
      title: 'Conversation::SelectConversationType',
      description: '',
      component: ConversationImportStepZero,
    },
    {
      title: 'Conversation::UploadConversationFiles',
      description: '',
      component: CallImportStepOne,
    },
    {
      title: 'Conversation::UploadCsvFileStep',
      description: '',
      component: CallImportStepTwo,
    },
    {
      title: '::Status',
      description: '',
      component: CallImportStepThree,
    },
    {
      title: 'Conversation::UploadConversationFiles',
      description: '',
      component: ChatImportStepOne,
    },
    {
      title: 'Conversation::UploadCsvFileStep',
      description: '',
      component: ChatImportStepTwo,
    },
    {
      title: '::Status',
      description: '',
      component: ChatImportStepThree,
    },
  ];

  validateStepZero(
    stepZeroCmp: ConversationImportStepZero,
    stepOneCmp: CallImportStepOne | ChatImportStepOne,
    stepTwoCmp: CallImportStepTwo | ChatImportStepTwo,
    type: string,
    args: StepValidationArgs
  ): Observable<boolean> {
    let step = type == 'entry' ? args.toStep : args.fromStep;
    let stepSpecificValidateMethod;

    if (step) {
      stepSpecificValidateMethod =
        type == 'entry' ? stepZeroCmp.validateEntryToStep() : stepZeroCmp.validateExitFromStep();
      if ((args.toStep.index === 1 || args.toStep.index === 4) && args.fromStep.index === 0) {
        stepOneCmp.selectedConversationFiles = stepZeroCmp.selectedConversationFiles;
        stepOneCmp.selectedFileSizes = 0;
        stepTwoCmp.selectedConversationFiles = stepZeroCmp.selectedConversationFiles;
        stepTwoCmp.selectedCsvFile = stepZeroCmp.selectedCsvFile;
        stepTwoCmp.systemLangCode = stepZeroCmp.systemLangCode;
      }
    }

    return of(args.toStep.index === 0 || args.toStep.index === 1 || args.toStep.index === 4);
  }

  validateStepOne(
    stepOneCmp: CallImportStepOne | ChatImportStepOne,
    stepTwoCmp: CallImportStepTwo | ChatImportStepTwo,
    type: string,
    args: StepValidationArgs
  ): Observable<boolean> {
    let step = type == 'entry' ? args.toStep : args.fromStep;
    let stepSpecificValidateMethod;

    if (step) {
      stepSpecificValidateMethod =
        type == 'entry' ? stepOneCmp.validateEntryToStep() : stepOneCmp.validateExitFromStep();
      if (
        (args.toStep.index === 2 && args.fromStep.index === 1) ||
        (args.toStep.index === 5 && args.fromStep.index === 4)
      ) {
        stepTwoCmp.selectedConversationFiles = stepOneCmp.selectedConversationFiles;
      }
    }

    if (stepSpecificValidateMethod) {
      if (typeof stepSpecificValidateMethod === typeof true) {
        return of(<boolean>stepSpecificValidateMethod);
      } else if (stepSpecificValidateMethod instanceof Function) {
        stepSpecificValidateMethod = stepSpecificValidateMethod.bind(stepOneCmp);
        let result = stepSpecificValidateMethod();
        var isObservableObject = isObservable(result);
        if (isObservableObject as boolean) {
          return result;
        } else if (typeof result === typeof true) {
          return of(<boolean>result);
        }
      }
    }

    return of(type != 'entry' && args.toStep.index != 0 ? false : true);
  }

  validateStepTwo(
    stepOneCmp: CallImportStepOne | ChatImportStepOne,
    stepTwoCmp: CallImportStepTwo | ChatImportStepTwo,
    stepThreeCmp: CallImportStepThree | ChatImportStepThree,
    type: string,
    args: StepValidationArgs
  ): Observable<boolean> {
    let step = type == 'entry' ? args.toStep : args.fromStep;
    let stepSpecificValidateMethod;

    if (step) {
      stepSpecificValidateMethod =
        type == 'entry' ? stepTwoCmp.validateEntryToStep() : stepTwoCmp.validateExitFromStep();
      if (
        (args.toStep.index === 3 && args.fromStep.index === 2) ||
        (args.toStep.index === 6 && args.fromStep.index === 5)
      ) {
        stepThreeCmp.selectedCsvFile = stepTwoCmp.selectedCsvFile;
        stepThreeCmp.selectedConversationFiles = stepTwoCmp.selectedConversationFiles;
        stepThreeCmp.systemLangCode = stepTwoCmp.systemLangCode;
        this.exitButtonDisabled = stepThreeCmp.progressValue < 100;
      }
    }

    if (stepSpecificValidateMethod) {
      if (typeof stepSpecificValidateMethod === typeof true) {
        return of(<boolean>stepSpecificValidateMethod);
      } else if (stepSpecificValidateMethod instanceof Function) {
        stepSpecificValidateMethod = stepSpecificValidateMethod.bind(stepOneCmp);
        let result = stepSpecificValidateMethod();
        let isObservableObject = isObservable(result);
        if (isObservableObject as boolean) {
          if (args.toStep.index === 3 || args.toStep.index === 6) {
            result.subscribe(x => {
              this.exitButtonDisabled = !x;
            });
          }
          return result;
        } else if (typeof result === typeof true) {
          return of(<boolean>result);
        }
      }
    }

    return of(true);
  }

  validateStepThree(
    stepTwoCmp: CallImportStepTwo | ChatImportStepTwo,
    stepThreeCmp: CallImportStepThree | ChatImportStepThree,
    type: string,
    args: StepValidationArgs
  ): Observable<boolean> {
    let step = type == 'entry' ? args.toStep : args.fromStep;
    let stepSpecificValidateMethod;

    if (step) {
      stepSpecificValidateMethod =
        type == 'entry' ? stepThreeCmp.validateEntryToStep() : stepThreeCmp.validateExitFromStep();
    }

    if (stepSpecificValidateMethod) {
      if (typeof stepSpecificValidateMethod === typeof true) {
        return of(<boolean>stepSpecificValidateMethod);
      } else if (stepSpecificValidateMethod instanceof Function) {
        stepSpecificValidateMethod = stepSpecificValidateMethod.bind(stepThreeCmp);
        let result = stepSpecificValidateMethod();

        var isObservableObject = isObservable(result);
        if (isObservableObject as boolean) {
          return result;
        } else if (typeof result === typeof true) {
          return of(<boolean>result);
        }
      }
    }

    return of(type != 'entry' ? false : args.fromStep.index === 2 || args.fromStep.index === 5);
  }
}
