import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../shared/dto-has-api.model';

@Api('api/app/meeting')
export class MeetingDto extends DtoHasApi {
  id: number;
  conversationId: number;
  recordingFileName: string;
  participantAudioFileCount: number | null;
}
