<div class="comment-container">
  <div class="comment-info">
    <div class="row m-0 p-3" *ngIf="comments.length === 0">
      {{ 'Conversation::NoCommentsYet' | abpLocalization }}
    </div>
    <div class="row m-0 mt-3" *ngFor="let comment of comments">
      <div class="col">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div>
              <small class="fw-bold">
                {{ comment.userName }}
              </small>
            </div>
            <div *ngIf="comment?.startTime != null" class="ps-2 pe-2 d-flex">
              <div class="vr"></div>
            </div>
            <ca-clickable-link
              *ngIf="comment?.startTime != null"
              [innerHtml]="
                '<small>@' +
                comment.startTime +
                (comment.endTime != null ? '-' + comment.endTime : '') +
                '</small>'
              "
              (linkClicked)="onLinkClicked(comment.startTime, comment.endTime)">
            </ca-clickable-link>
          </div>
          <div></div>
          <div
            class="c-default"
            container="body"
            [ngbTooltip]="comment.commentDate | caDatePipe : dateDisplayType : true">
            <small class="fw-bold">
              {{ comment.commentDate | timeSince }}
            </small>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row">
          <div [ngClass]="'col-' + getCommentColumnWidth()">
            <div class="mt-2 text-break">
              {{ comment.comment }}
            </div>
          </div>
          <div [ngClass]="'col-' + getCommentDeleteColumnWidth()" *ngIf="canDeleteComment(comment)">
            <button class="btn btn-sm float-end" (click)="confirmDeletion(comment, $event)">
              <ca-delete-icon></ca-delete-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="comment-actions">
    <div class="row m-0 mt-2 mb-2">
      <div class="col-md-11">
        <textarea #edtComment class="form-control" value=""></textarea>
      </div>
      <div class="col-md-1">
        <button
          (click)="addComment($event, edtComment)"
          [disabled]="edtComment.textLength == 0"
          class="btn ca-btn ca-btn-neutral">
          {{ '::Comment' | abpLocalization }}
        </button>
      </div>
    </div>
  </div>
</div>
