import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'ConclusionSentiment.Status',
})
export class ConclusionSentimentStatus {
  @GenericLookup({
    code: 'ConclusionSentiment.Status.Agreed',
  })
  static agreed: number;

  @GenericLookup({
    code: 'ConclusionSentiment.Status.Disagreed',
  })
  static disagreed: number;

  @GenericLookup({
    code: 'ConclusionSentiment.Status.Unknown',
  })
  static unknown: number;
}