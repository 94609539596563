import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AttachedDataValueDto } from 'src/core/models/administration/attached-data/attached-data-value.dto';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';

@Component({
  selector: 'ca-attached-data-panel',
  templateUrl: './attached-data-panel.component.html',
  styleUrls: ['./attached-data-panel.component.scss'],
})
export class AttachedDataPanelComponent implements OnInit {
  @Input()
  conversationId: number;

  attachedDataDisplayedColumns: string[] = ['name', 'value'];
  data: AttachedDataValueDto[] = [];

  constructor(private conversationService: ConversationService) {}

  ngOnInit(): void {
    this.conversationService
      .getAttachedDataByConversationId(this.conversationId)
      .subscribe((response) => {
        this.data = response;
      });
  }
}
