import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Category.Match.Type',
})
export class CategoryMatchType {
  @GenericLookup({
    code: 'Category.Match.Type.Matching',
  })
  static matching: number;

  @GenericLookup({
    code: 'Category.Match.Type.NotMatching',
  })
  static notMatching: number;
}
