import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { GenericState } from '../generic/generic.state';
import { SupervisorDashboardStateModel } from 'src/core/models/real-time/supervisor-dashboard.state-model';
import { FilterChange } from 'src/core/actions/real-time/supervisor-dashboard.action';
const name = 'SupervisorDashboardState';
@State<SupervisorDashboardStateModel>({
  name: name,
  defaults: {
    filters: [],
  } as SupervisorDashboardStateModel,
})
@Injectable()
export class SupervisorDashboardState extends GenericState<SupervisorDashboardState> {
  @Action(FilterChange)
  changeFilter(ctx: StateContext<SupervisorDashboardStateModel>, action: FilterChange) {
    const currentState = ctx.getState();
    currentState.filters = action.filters;
    this.userSettingsService.patchState(ctx, currentState, name);
  }

  constructor(userSettingsService: UserStateSettingsService) {
    super(userSettingsService);
    this.name = name;

    this.userSettingsService.register(this.name, this);
  }
}
