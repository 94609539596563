import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ColumnSelectionItem } from '../../models/column-selection-item.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ca-column-selector',
  templateUrl: './column-selector.component.html',
  styleUrls: ['./column-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ColumnSelectorComponent),
    },
  ],
})
export class ColumnSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  readonly: boolean = false;

  @Input()
  items: ColumnSelectionItem[] = [];

  @ViewChild('ngSelect', { read: NgSelectComponent, static: false })
  ngSelect: NgSelectComponent;

  value: unknown;
  changes: MutationObserver;
  classList: string = '';

  constructor(private elementRef: ElementRef) {
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        this.classList = this.elementRef.nativeElement.classList;
      });
    });

    this.changes.observe(this.elementRef.nativeElement, {
      attributeFilter: ['class'],
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  compareFn(a: ColumnSelectionItem, b: ColumnSelectionItem): boolean {
    return a.item.id === b.item.id;
  }

  onOpenColumnSelector(eventArgs: any): void {
    setTimeout(() => {
      const dropdownId = this.ngSelect.dropdownId;
      const dropdownEl = document.getElementById(dropdownId);

      dropdownEl.classList.add('ca-selector-dropdown-panel');

      dropdownEl.classList.add('bring-to-front');
    }, 50);
  }

  onChange(args: any) {}

  onTouched() {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }
}
