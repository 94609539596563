export class ConversationTranscriptItemDto {
  channel: number;
  startTime: number | null;
  endTime: number | null;
  time: Date | null;
  term: string;
  expansionProperties: object;
  index: number | null;
  agentIndex: number | null;
  customerIndex: number | null;

  get participantName(): string {
    return this.expansionProperties['ParticipantName'];
  }

  set participantName(participantName: string) {
    this.expansionProperties['ParticipantName'] = participantName;
  }

  get rowIdentifier(): string {
    return this.expansionProperties['RowIdentifier'];
  }

  set rowIdentifier(rowIdentifier: string) {
    this.expansionProperties['RowIdentifier'] = rowIdentifier;
  }
}
