import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';

@Component({
  selector: 'ca-dummy-notification',
  templateUrl: './dummy-notification.component.html',
  styleUrls: ['./dummy-notification.component.scss'],
})
@NotificationType('DummyNotification', DummyNotificationComponent)
export class DummyNotificationComponent implements OnInit {
  data: NotificationDto;

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService
  ) {
    toastr.success(payload, 'Dummy Notification Received');
  }

  constructor() {}

  ngOnInit(): void {}

  onClick(eventArgs: MouseEvent): void {
    alert(this.data.id);
  }
}
