import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'ConversationMark.Channel'
})
export class ConversationMarkChannel {
    @GenericLookup({
        code: 'ConversationMark.Channel.Undefined'
    })
    static undefined: number;

    @GenericLookup({
        code: 'ConversationMark.Channel.Agent'
    })
    static agent: number;

    @GenericLookup({
        code: 'ConversationMark.Channel.Customer'
    })
    static customer: number;
}
