import { Component, OnInit } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StringQueryItemDto } from 'src/core/models/query/string-query-item.dto';

@Component({
  selector: 'ca-query-tree-node-string',
  templateUrl: './query-tree-node-string.component.html',
  styleUrls: ['./query-tree-node-string.component.scss'],
})
export class QueryTreeNodeStringComponent extends QueryEditorBase implements OnInit {
  payload: StringQueryItemDto;
  queryForm: FormGroup = null;
  formOperator: number;
  formTerm: string;

  constructor(private fb: FormBuilder) {
    super();
    this.queryForm = fb.group(
      {
        term: [
          null,
          {
            validators: [Validators.required],
          },
        ],
        operator: [
          null,
          {
            validators: [Validators.required],
          },
        ],
      },
      { updateOn: 'blur' }
    );
    this.queryForm.valueChanges.subscribe(value => {
      this.node.isDirty = this.queryForm.dirty;
      this.setPayloadValues();
    });

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });
  }

  setPayloadValues() {
    if (this.queryForm.valid) {
      this.payload.value = this.queryForm.get('term').value;
      this.payload.operator = this.queryForm.get('operator').value;
    }
  }
  ngOnInit() {
    this.payload = this.node.categoryItem.payload as StringQueryItemDto;
    this.queryForm.get('term').patchValue(this.payload.value);
    this.queryForm.get('operator').patchValue(this.payload.operator);
    this.node.validationStatus = this.queryForm.valid;
  }
}
