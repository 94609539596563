<ng-template #batchTaggingContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'Conversation::BatchTagging' | abpLocalization }}
    </h4>
  </div>
  <div class="modal-body">
    <div class="container p-2">
      <ng-wizard [config]="config" (stepChanged)="stepChanged($event)">
        <ng-wizard-step
          *ngFor="let stepDefinition of stepDefinitions"
          [title]="stepDefinition.title"
          [description]="stepDefinition.description"
          [state]="onChangeStepState(stepDefinition)"
          [component]="stepDefinition.component"
          [canEnter]="stepDefinition.canEnter"
          [canExit]="stepDefinition.canExit">
        </ng-wizard-step>
      </ng-wizard>
    </div>
  </div>
  <div class="modal-footer">
    <div class="btn-toolbar ng-wizard-toolbar ng-wizard-toolbar-top">
      <button
        type="button"
        [disabled]="!closeButtonDisabled && isStepTwo"
        (click)="closeButtonDisabled && isStepTwo ? onCancelModal() : onCloseModal()"
        container="body"
        style="margin-right: 6px !important"
        class="btn ca-btn ca-btn-neutral"
        nzType="primary">
        {{ 'AbpUi::Cancel' | abpLocalization }}
      </button>
      <span class="toolbar-item">
        <mat-divider [vertical]="true" class="menu-divider"></mat-divider>
      </span>
      <button
        type="button"
        [hidden]="preButtonHidden"
        (click)="showPreviousStep($event)"
        class="btn ca-btn ca-btn-neutral"
        nzType="primary"
        style="margin-left: 6px">
        <i class="fas fa-angle-left"></i
        ><span class="me-1 ms-1"> {{ '::Previous' | abpLocalization }}</span>
      </button>
      <span
        [hidden]="!showNextButton"
        container="body"
        [disableTooltip]="this.selectedStepIndex === 1">
        <button
          type="button"
          (click)="showNextStep($event)"
          [disabled]="!isFilesSelected() || !isValid()"
          class="btn ca-btn ca-btn-primary reverse"
          nzType="primary"
          style="float: left; margin-left: 6px">
          <span class="me-1 ms-1"> {{ '::Next' | abpLocalization }}</span
          ><i class="fas fa-angle-right"></i>
        </button>
      </span>
      <button
        type="button"
        (click)="onTagging()"
        [disabled]="!isTagsSelected()"
        [hidden]="!isStepTwo"
        class="btn ca-btn ca-btn-primary"
        nzType="default"
        style="margin-left: 4px">
        <span>{{ 'Conversation::Tag' | abpLocalization }}</span>
      </button>
    </div>
  </div>
</ng-template>

<button
  class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
  (click)="openBatchTaggingModal(batchTaggingContent)">
  <i class="far fa-tag"></i>
  <span class="me-1">{{ 'Conversation::BatchTagging' | abpLocalization }}</span>
</button>
