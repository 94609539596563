import { EntityDto } from '../shared/entity.dto';

export class SimpleTermWithOccurenceQueryItemDto extends EntityDto {
  operator: number;
  categoryId: number;
  categoryItemId: number;
  internalId: number;
  parentInternalId: number;
  queryId: number;
  sideId: number;
  sideCode: string;
  term: string;
  occurence: number;
  multiple: boolean;
}
