import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.SentimentTrendStatus',
})
export class ConversationSentimentTrendStatus {
  @GenericLookup({
    code: 'Conversation.SentimentTrendStatus.Unknown',
  })
  static unknown: number;

  @GenericLookup({
    code: 'Conversation.SentimentTrendStatus.Improving',
  })
  static improving: number;

  @GenericLookup({
    code: 'Conversation.SentimentTrendStatus.Stable',
  })
  static stable: number;

  @GenericLookup({
    code: 'Conversation.SentimentTrendStatus.Worsening',
  })
  static worsening: number;
}
