import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { takeUntil } from 'rxjs';
import { ColumnConfiguratorService } from '../../services/column-configurator.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColumnSelectionItem } from '../../models/column-selection-item.model';
import { GridConfig } from '../../models/grid-config.model';
import { ColumnSelectionValidator } from '../../validators/column-selection.validator';
import { CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'ca-column-configurator',
  templateUrl: './column-configurator.component.html',
  styleUrls: ['./column-configurator.component.scss'],
})
@AutoUnsubscribe()
export class ColumnConfiguratorComponent extends HasSubscription implements OnInit, OnDestroy {
  @ViewChild('modal')
  modal: TemplateRef<any>;

  modalRef: NgbModalRef;
  selectionItems: ColumnSelectionItem[] = [];
  gridConfig: GridConfig;
  form: FormGroup;
  gridId: string;
  sortPredicateFn: unknown;

  private showFn: unknown;

  get columnsFormArray(): FormArray {
    return this.form.get('columns') as FormArray;
  }

  get allColumnsSelected(): boolean {
    const selection = this.columnsFormArray.getRawValue() as ColumnSelectionItem[];

    return selection.length === this.selectionItems.length + this.lockedCount;
  }

  get lockedCount(): number {
    return this.gridConfig.columns.filter(x => x.locked).length;
  }

  constructor(
    private modalService: NgbModal,
    private service: ColumnConfiguratorService,
    private fb: FormBuilder
  ) {
    super();

    this.showFn = this.show.bind(this);
    this.sortPredicateFn = this.sortPredicate.bind(this);

    this.service.configuratorRequested
      .pipe(takeUntil(this.autoUnsubscribeNotifier))
      .subscribe(this.showFn);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  show(eventArgs: { gridId: string }): void {
    this.gridId = eventArgs.gridId;
    // a copy of original grid config is used to prevent harming original config before save.
    this.gridConfig = Object.assign({}, this.service.getGridConfig(this.gridId));

    this.buildForm();
    this.setSelectionItems();

    this.modalRef = this.modalService.open(this.modal);
  }

  sortPredicate(index: number, item: CdkDrag<number>): boolean {
    return index >= this.lockedCount;
  }

  onClickAddColumn(eventArgs: MouseEvent): void {
    const selectionItem: ColumnSelectionItem = {
      disabled: false,
      item: {
        id: null,
        header: null,
        locked: false,
        visible: true,
        width: null,
      },
    };

    const columnFormControl = new FormControl(null, [ColumnSelectionValidator.validColumnRequired]);

    const columnsFormArray = this.form.get('columns') as FormArray;

    columnsFormArray.push(columnFormControl, { emitEvent: false });
  }

  onClickRemoveColumn(eventArgs: MouseEvent, index: number): void {
    const columnsFormArray = this.form.get('columns') as FormArray;

    columnsFormArray.removeAt(index);
  }

  onSubmitForm(eventArgs: SubmitEvent): void {
    eventArgs.preventDefault();
    eventArgs.stopPropagation();
  }

  onClickReset(eventArgs: MouseEvent): void {
    this.service.resetConfig(this.gridId);
    this.modalRef.dismiss();
  }

  onClickSave(eventArgs: MouseEvent): void {
    if (!this.form.valid) {
      return;
    }

    const visibleColumnIds = this.columnsFormArray.getRawValue().map(x => x.item.id) as string[];

    this.service.saveVisibleColumns(this.gridId, visibleColumnIds);

    this.modalRef.dismiss();
  }

  onDropRow(event: CdkDragDrop<string[]>): void {
    const formControl = this.columnsFormArray.at(event.previousIndex);

    this.columnsFormArray.removeAt(event.previousIndex);
    this.columnsFormArray.insert(event.currentIndex, formControl);
  }

  private buildForm(): void {
    const arr = [];

    this.gridConfig.columns
      .filter(col => col.visible)
      .forEach(col => {
        const selectionItem: ColumnSelectionItem = {
          disabled: false,
          item: col,
        };
        const columnFormControl = new FormControl(selectionItem);

        arr.push(columnFormControl);
      });

    this.form = this.fb.group({
      columns: this.fb.array(arr),
    });

    this.form.valueChanges.subscribe(formValue => {
      this.setSelectionItems();
    });
  }

  private setSelectionItems(): void {
    const selection = this.columnsFormArray.getRawValue() as ColumnSelectionItem[];

    this.selectionItems = this.gridConfig.columns
      .filter(x => !x.locked)
      .map(x => {
        return {
          disabled: selection.findIndex(s => s?.item.id === x.id) > -1,
          item: x,
        };
      });
  }
}
