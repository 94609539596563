<div>
  <h2>{{ 'Settings::ExternalLogin' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="externalLoginSettingsForm" (ngSubmit)="saveExternalLoginSettings()">
  <div class="form-group mt-3">
    <input type="checkbox" id="samlLoginEnabled" formControlName="samlLoginEnabled" />
    <label class="pl-1" for="samlLoginEnabled">
      {{ 'Settings::SamlLoginEnabled' | abpLocalization }}
    </label>
  </div>
  <div class="form-group mt-3">
    <label class="pl-1" for="samlLoginEndPoint">
      {{ 'Settings::SamlLoginEndPoint' | abpLocalization }}
    </label>
    <input
      type="text"
      id="samlLoginEndPoint"
      class="form-control form-control-sm"
      formControlName="samlLoginEndPoint"
    />
  </div>
  <div class="form-group mt-3">
    <label class="pl-1" for="samlDefaultRedirectEndPoint">
      {{ 'Settings::SamlDefaultRedirectEndPoint' | abpLocalization }}
    </label>
    <input
      type="text"
      id="samlDefaultRedirectEndPoint"
      class="form-control form-control-sm"
      formControlName="samlDefaultRedirectEndPoint"
    />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
