import { ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CAConfigStateService {
  constructor(private configStateService: ConfigStateService) {}

  // TODO: Find all references and subscribe
  refreshAppState() {
    return this.configStateService.refreshAppState();
  }
}
