import { ServerSideEnum } from '../enum/server-side-enum.model';
import { Injector, Injectable } from '@angular/core';
import { MapToServerSideEnum } from 'src/core/decorators/enum/mapto-server-side-enum.decorator';

@Injectable({
  providedIn: 'root',
})
@MapToServerSideEnum('Operators')
export class Operators extends ServerSideEnum {
  private notations: string[] = ['=', '!=', '*', '=*', 'in', '>', '>=', '<', '<='];

  Equals: number;
  NotEquals: number;
  Contains: number;
  StartsWith: number;
  In: number;
  GreaterThan: number;
  GreaterThanOrEquals: number;
  LessThan: number;
  LessThanOrEquals: number;
  NotContains: number;

  constructor(injector: Injector) {
    super(injector);
  }

  getNotation(operator: number) {
    return this.notations[operator];
  }
}
