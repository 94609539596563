import { ConversationDto } from 'src/core/models/conversation/conversation.dto';

export class NonFcrMasterConversationIdChange {
  static readonly type = '[NonFcr] NonFcrMasterConversationIdChange';
  constructor(public nonFcrMasterConversationId: number) {}
}

export class ConversationsChange {
  static readonly type = '[NonFcr] ConversationsChange';
  constructor(
    public masterConversationId,
    public conversations: ConversationDto[],
    public conversationsTotalCount: number
  ) {}
}

export class LastConversationIdChange {
  static readonly type = '[NonFcr] LastConversationIdChange';
  constructor(public lastConversationId: number) {}
}

export class ScrollPositionChange {
  static readonly type = '[NonFcr] ScrollPositionChange';
  constructor(public scrollPosition: number) {}
}

export class StateClearRequest {
  static readonly type = '[NonFcr] StateClearRequest';
  constructor(public masterConversationId: number | null) {}
}
