import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanDeactivateGuard } from 'src/core/guards/can-deactivate.guard';
import { EnumResolver } from 'src/core/resolvers/enum.resolver';
import { GenericLookupTypeResolver } from 'src/core/resolvers/generic-lookup-type.resolver';
import { UserDetailMainComponent } from './user-detail/user-detail-main/user-detail-main.component';

const routes: Routes = [
  {
    path: 'identity/users',
    canActivate: [AuthGuard, PermissionGuard],
    resolve: {
      appConfig: GenericLookupTypeResolver,
      enum: EnumResolver,
    },
    children: [
      {
        path: ':id',
        component: UserDetailMainComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
