<ng-template #nodeTemplate let-payload="payload">
  <div>{{ payload.name }}</div>
</ng-template>

<ng-template #selectionTemplate let-selection="selection">
  {{ selection[0].name }}
</ng-template>

<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ '::Department' | abpLocalization }}</label>
      </div>

      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ payload.operator | operator }}</label>
      </div>
      <div class="col-auto" [attr.title]="departmentNameList">
        <span *ngIf="loading"> {{ 'CA::Loading' | abpLocalization }}... </span>
        <span *ngIf="!loading">
          {{
            departments.length === 1
              ? departments[0].name
              : ('CA::SelectedDepartmentsWithCount' | abpLocalization : departments.length)
          }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #editableContent>
  <div [class]="cssClass">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ '::Department' | abpLocalization }}</label>
      </div>

      <div class="col-auto">
        <select
          class="form-select form-select-sm"
          [(ngModel)]="payload.operator"
          (ngModelChange)="operatorChanged()">
          <option *ngFor="let operator of node.categoryItem.operators" [value]="operator">
            {{ operator | operator }}
          </option>
        </select>
      </div>
      <div class="col-auto" [formGroup]="departmentForm">
        <ca-department-selector-v2
          id="departmentSelector"
          style="display: block; width: 350px"
          formControlName="department"
          [multiple]="true"
          [closeOnSelect]="false"
          placeholder="{{
            'CA::PleaseSelectWithThreeDots' | abpLocalization
          }}"></ca-department-selector-v2>
      </div>

      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>
