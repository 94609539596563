<ng-select
  #ngSelect
  appendTo="body"
  [ngClass]="classList"
  [addTag]="!isAttachedDataMultipleChoice"
  (change)="onChange($event)"
  (open)="onOpen($event)"
  (clear)="onClear($event)"
  [placeholder]="placeholder"
  [items]="items"
  [multiple]="multiple"
  [closeOnSelect]="closeOnSelect"
  [searchable]="!isAttachedDataMultipleChoice"
  [loading]="loading"
  [(ngModel)]="value"
  [disabled]="isDisabled"
  [isOpen]="isAttachedDataMultipleChoice ? undefined : false"
  loadingText="{{ 'CA::Loading' | abpLocalization }}..."
  bindLabel="value">
  >
  <ng-template
    *ngIf="isAttachedDataMultipleChoice"
    ng-multi-label-tmp
    let-items="items"
    let-clear="clear">
    <div class="ng-value" *ngIf="items.length === 1">
      <span class="ng-value-label">{{ items[0].value }}</span>
      <span class="ng-value-icon right" (click)="clear(items[0])" aria-hidden="true">×</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 1" [ngbTooltip]="tipContent" container="body">
      <span class="ng-value-label"
        >{{ '::XItemsSelected' | abpLocalization : items.length }}...</span
      >
    </div>
  </ng-template>
</ng-select>

<ng-template #tipContent>
  <div *ngFor="let item of value">{{ item.value }}</div>
</ng-template>
