export class ConversationDetailTabs {
  static general = 'general';
  static transcript = 'transcript';
  static analysis = 'analysis';
  static attachedData = 'attached-data';
  static comment = 'comment';
  static transfer = 'transfer';
  static evaluation = 'evaluation';
  static history = 'history';
  static keyPhrases = 'key-phrases';
}
