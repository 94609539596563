import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'QualityManagement.EvaluationType',
})
export class EvaluationType {
  @GenericLookup({
    code: 'QualityManagement.EvaluationType.Original',
  })
  static original: number;

  @GenericLookup({
    code: 'QualityManagement.EvaluationType.ReEvaluation',
  })
  static reEvaluation: number;

  @GenericLookup({
    code: 'QualityManagement.EvaluationType.Calibration',
  })
  static calibration: number;

  @GenericLookup({
    code: 'QualityManagement.EvaluationType.ResumeDraft',
  })
  static resumeDraft: number;
}
