<div
  (mouseenter)="onMouseEnter(marker)"
  (click)="onClick(marker)"
  class="marker"
  [title]="marker.text"
  [style.left]="marker.position + '%'"
  [style.backgroundColor]="marker.color"
  [style.color]="colorUtils.getColors(marker.color).color"
  [style.width]="marker.width + 'px'"
  title="{{ marker.text }}"
></div>
