<div>
  <h2>{{ 'Settings::CategorizationSettings' | abpLocalization }}</h2>
</div>
<br />
<hr />
<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>
      <span style="margin-left: 4px">
        {{ 'Settings::UserDefinedCategoriesSettings' | abpLocalization }}
      </span>
    </a>
    <ng-template ngbNavContent>
      <ca-user-defined-category-settings> </ca-user-defined-category-settings>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>
      <span style="margin-left: 4px">
        {{ 'Settings::AIGeneratedCategoriesSettings' | abpLocalization }}
      </span>
    </a>
    <ng-template ngbNavContent>
      <ca-ai-topic-category-settings> </ca-ai-topic-category-settings>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" style="height: calc(100% - 130px)"></div>
