<ca-clickable-link
  [innerHtml]="
    'QualityManagement::EvaluationResultAutomaticNotificationMessage'
      | abpLocalization
        : payloadData?.count
        : getDateRangeText(payloadData?.date, payloadData?.endDate, payloadData?.notificationType)
  "
  [targetLink]="['/quality/evaluation-result']"
  [params]="{
    departmentManager: payloadData?.departmentManager,
    supervisor: payloadData?.supervisor,
    supervisorId: payloadData?.supervisorId,
    departmentId: payloadData?.departmentId,
    date: payloadData?.date,
    endDate: payloadData?.endDate,
    isAutomatic: 'true',
    referrer: 'evaluation-result'
  }"></ca-clickable-link>
