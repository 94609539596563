import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DesktopNotificationService {
  static showDesktopNotification(
    text: string,
    options?: NotificationOptions,
    onclick?: () => void
  ): boolean {
    var notification;
    if (!('Notification' in window)) {
      console.warn('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      notification = this.getNotification(text, options, onclick);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          notification = this.getNotification(text, options, onclick);
        }
      });
    }
    return notification != null;
  }

  private static getNotification(
    text: string,
    options?: NotificationOptions,
    onclick?: () => void
  ) {
    var notification = options ? new Notification(text, options) : new Notification(text);
    if (onclick) {
      notification.addEventListener('click', onclick);
    } else {
      notification.addEventListener('click', () => {
        window.location.href = '/real-time/notification';
      });
    }
    return notification;
  }
}
