import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'ca-dropdown-selector-wrapper',
  templateUrl: './dropdown-selector-wrapper.component.html',
  styleUrls: ['./dropdown-selector-wrapper.component.scss'],
})
export class DropdownSelectorWrapperComponent implements OnInit {
  private _disabled = false;

  @Input()
  buttonCls: string;

  @Input()
  buttonString: string;

  @Input()
  autoClose: any;

  @Input()
  selections: any[] = [];

  @Input()
  selectionTemplate: TemplateRef<any>;

  @Input()
  selectionExceptTemplate: TemplateRef<any>;

  @Input()
  except: boolean;

  @Input()
  exceptType: string;

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get selectedMessage(): string {
    const items =
      this.selections.length.toString() + ' ' + this.localizationService.instant(this.exceptType);
    return this.except
      ? this.localizationService.instant('::AllExcept', items)
      : this.localizationService.instant('::AllSelectedGeneric', items);
  }

  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {}
}
