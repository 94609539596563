import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { PlayerComponent } from '../player/player.component';
import { PlayerStatus } from '../models/player-status.enum';
import { Store } from '@ngxs/store';
import { ConversationModuleStateModel } from 'src/core/models/conversation/conversation-module.state-model';
import {
  BeforeVolumeChange,
  SpeedChange,
  VolumeChange,
} from 'src/core/actions/conversation/conversation-module.actions';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { PermissionService } from '@abp/ng.core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { FormatHelper } from 'src/core/helpers/format.helper';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';
import { ColorUtils } from 'src/core/helpers/color-utils';
import { CallTranscriptService } from 'src/core/services/conversation/call-transcript.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ConversationTranscriptDto } from 'src/core/models/conversation/transcript/conversation-transcript.dto';
import { ConversationDetailService } from 'src/core/services/conversation/conversation-detail.service';

@Component({
  selector: 'ca-player-controller',
  templateUrl: './player-controller.component.html',
  styleUrls: ['./player-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerControllerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  player: PlayerComponent;

  @Input()
  collapsible: boolean;

  @Input()
  hideDownloadButton = false;

  @Input()
  smallVolumeSlider = false;

  @Input()
  hideCommentButton = true;

  @Input()
  showSentimentButtons = true;

  @Input()
  showCategoryButton = false;

  @Input()
  chatMode = false;

  @Input()
  set categories(value: ConversationCategoryDto[]) {
    this._categories = value;

    // if there are already selected categories, it means comments changed
    if (this.selectedCategories && this.selectedCategories.length > 0) {
      // if comments are visible, redraw comment marks
      if (this.commentsVisible) {
        this.reactivateCommentSlider();
      }
    } else {
      this.selectedCategories = [];

      if (this.player) {
        this.player.categoryMarks = [];
      }

      this.conversationDetailService.updateCategory({
        categories: this._categories,
        categoryMarks: [],
      });
      this.activateDefaultSliders();
    }
    this.cdr.detectChanges();
  }

  get categories(): ConversationCategoryDto[] {
    return this._categories;
  }

  @Input()
  transcript: ConversationTranscriptDto;

  @Input()
  searchedCategoryIds: number[] = [];

  @Output()
  addCommentRequested: EventEmitter<{
    comment: any;
    startTime: any;
    endTime: any;
  }> = new EventEmitter();

  @ViewChild('volumeSlider')
  volumeSlider: ElementRef;

  @ViewChildren('categoryMark') categorySliders: QueryList<MatSlideToggle>;

  speedOptions = [0.5, 1, 1.5, 2];
  speed: number;
  canDownloadCall: boolean = false;
  waveFormEnabled = true;
  selectedCategories: number[] = [];
  commentsVisible: boolean = false;
  searchResultsVisible: boolean = false;

  private _volume = 100;
  private _durationString = '00:00';
  private _currentTimeString = '00:00';
  private _playing = false;
  private _playerStatus: PlayerStatus;
  private _volumeBeforeChange: number;
  private _disableButtons: boolean;
  private _categories: ConversationCategoryDto[] = [];

  commentPopover: NgbPopover;
  startTime: string = '00:00';
  endTime: string;

  public get durationString(): string {
    return this._durationString;
  }

  public get currentTimeString(): string {
    return this._currentTimeString;
  }

  public get playing(): boolean {
    return this._playing;
  }

  public get volume(): number {
    return this._volume;
  }

  get playerExpanded(): boolean {
    return this.player.expanded;
  }

  get hasCommentCategory(): boolean {
    return this.categories.filter(x => x.isCommentMarker).length > 0;
  }

  get hasSearchResultCategory(): boolean {
    return this.categories.filter(x => x.isQuickSearchMarker).length > 0;
  }

  set playerExpanded(value: boolean) {
    this.player.expanded = value;
  }

  get isVolumeDown(): boolean {
    return this._volume > 0 && this._volume < 50;
  }

  get isVolumeUp(): boolean {
    return this._volume > 50;
  }

  get isMuted(): boolean {
    return this._volume === 0;
  }

  set disableButtons(val: boolean) {
    this._disableButtons = val;

    this.cdr.detectChanges();
  }

  get buttonsDisabled(): boolean {
    const disabledStatus = [
      PlayerStatus.NotInitialized,
      PlayerStatus.Initialized,
      PlayerStatus.LoadingContent,
      PlayerStatus.LoadingPeaks,
      PlayerStatus.DrawingWaveform,
    ];

    return disabledStatus.indexOf(this._playerStatus) > -1 || this._disableButtons;
  }

  get volumeIcon(): string {
    if (this.isVolumeDown) {
      return 'fas fa-volume-down';
    } else if (this.isVolumeUp) {
      return 'fas fa-volume-up';
    } else if (this.isMuted) {
      return 'fas fa-volume-mute';
    }
  }

  get isCategoryResultsInputChecked(): boolean {
    const intersection = this.selectedCategories.filter(value =>
      this.searchedCategoryIds.includes(value)
    );

    return intersection.length > 0 && intersection.length === this.searchedCategoryIds.length;
  }

  reactivateCommentSlider(): void {
    setTimeout(() => {
      if (this.hasCommentCategory) {
        this.onCommentVisibilityChange({ checked: true });
      }
    }, 100);
  }

  activateDefaultSliders(): void {
    setTimeout(() => {
      this.onCategoryResultVisibilityChange({ checked: true });

      if (this.hasSearchResultCategory) {
        this.onSearchResultVisibilityChange({ checked: true });
      }

      if (this.hasCommentCategory) {
        this.onCommentVisibilityChange({ checked: true });
      }
    }, 100);
  }

  onSelectAllCategoriesChange(eventArgs: Event): void {
    const chk = eventArgs.currentTarget as HTMLInputElement;
    const checked = chk.checked;

    this.selectedCategories.splice(0, this.selectedCategories.length);

    this.commentsVisible = chk.checked;
    this.searchResultsVisible = chk.checked;

    const filterFn = this.getFilterFunction();

    if (checked) {
      this.categories.forEach(c => {
        if (c.id > 0 || (c.id < 0 && c.topicTermItems?.length > 0)) {
          this.selectedCategories.push(c.id);
        }
      });

      this.categorySliders.forEach(s => {
        const color = s._elementRef.nativeElement.getAttribute('data-category-color');

        s.checked = true;
        this.setCategorySliderColor(s._inputElement.nativeElement, true, color);
      });
    } else {
      this.categorySliders.forEach(s => {
        s.checked = false;
        this.setCategorySliderColor(s._inputElement.nativeElement, false, '');
      });
    }

    const marks = this.transcriptService.getConversationTranscriptAnalysis(
      this.categories,
      this.transcript,
      filterFn
    );
    this.player.categoryMarks = marks;
    this.conversationDetailService.updateCategory({
      categories: this.categories,
      categoryMarks: marks,
    });

    this.cdr.detectChanges();
  }

  onCategoryVisibilityChange(eventArgs: any, categoryId: number, categoryColor: string): void {
    this.toggleCategorySlider(
      eventArgs.checked,
      categoryId,
      categoryColor,
      true,
      eventArgs.source._inputElement.nativeElement
    );
  }

  onCommentVisibilityChange(eventArgs: any): void {
    this.commentsVisible = eventArgs.checked;

    const filterFn = this.getFilterFunction();

    const marks = this.transcriptService.getConversationTranscriptAnalysis(
      this.categories,
      this.transcript,
      filterFn
    );
    this.player.categoryMarks = marks;

    this.arrangeSelectAllCheck();

    this.cdr.detectChanges();
  }

  onSearchResultVisibilityChange(eventArgs: any): void {
    this.searchResultsVisible = eventArgs.checked;

    const filterFn = this.getFilterFunction();

    const marks = this.transcriptService.getConversationTranscriptAnalysis(
      this.categories,
      this.transcript,
      filterFn
    );
    this.player.categoryMarks = marks;
    this.conversationDetailService.updateCategory({
      categories: this.categories,
      categoryMarks: marks,
    });

    this.arrangeSelectAllCheck();

    this.cdr.detectChanges();
  }

  onCategoryResultVisibilityChange(eventArgs: any): void {
    this.categorySliders?.forEach(s => {
      const categoryId = parseInt(s._elementRef.nativeElement.getAttribute('data-category-id'));
      const categoryColor = s._elementRef.nativeElement.getAttribute('data-category-color');

      if (this.searchedCategoryIds.includes(categoryId)) {
        s.checked = eventArgs.checked;

        this.toggleCategorySlider(
          eventArgs.checked,
          categoryId,
          categoryColor,
          true,
          s._inputElement.nativeElement
        );
      }
    });
  }

  onPlayButtonClick(eventArgs: MouseEvent) {
    this.player.play(null, null, true);
  }

  onPauseButtonClick(eventArgs: MouseEvent) {
    this.player.pause(true);
  }

  onStopButtonClick(eventArgs: MouseEvent) {
    this.player.stop(true);
  }

  onExpandButtonClick(eventArgs: MouseEvent) {
    this.playerExpanded = true;
  }

  onCollapseButtonClick(eventArgs: MouseEvent) {
    this.playerExpanded = false;
  }

  onMuteButtonClick(eventArgs: MouseEvent) {
    this._volumeBeforeChange = this.volume;
    const beforeVolumeAction = new BeforeVolumeChange(this._volumeBeforeChange);
    this.store.dispatch(beforeVolumeAction);
    this.player.mute();
    const volume = 0;
    this.player.changeVolume(volume);

    this.adjustSliderStyle(0);
  }

  onUnmuteButtonClick(eventArgs: MouseEvent) {
    this.player.unmute();
    this.player.changeVolume(this._volumeBeforeChange);

    this.adjustSliderStyle(this._volumeBeforeChange);
  }

  onDownloadButtonClick(eventArgs: MouseEvent) {
    this.player.download();
  }

  onShowSentimentButtonClick(eventArgs: MouseEvent) {
    this.waveFormEnabled = false;
    this.player.onShowSentiment();
  }

  onShowWaveFormButtonClick(eventArgs: MouseEvent) {
    this.waveFormEnabled = true;
    this.player.onShowWaveForm();
  }

  onCommentButtonClick(popover: NgbPopover) {
    this.startTime = this.currentTimeString;
    this.endTime = null;

    const regions = this.player.regions();

    if (regions?.list['region_1'] != null) {
      this.startTime = this.formatHelper.convertMillisecondToTimeString(
        regions.list['region_1'].start * 1000
      );
      this.endTime = this.formatHelper.convertMillisecondToTimeString(
        regions.list['region_1'].end * 1000
      );
    }

    this.player.pause();
    this.commentPopover = popover;

    popover.open();
  }

  onPopoverShown(): void {
    setTimeout(() => {
      const textarea = document.getElementById('player-comment') as HTMLTextAreaElement;

      if (textarea) {
        textarea.focus();
      }
    }, 100);
  }

  onSaveComment() {
    const comment = (document.getElementById('player-comment') as HTMLTextAreaElement).value;

    this.addCommentRequested.emit({
      comment: comment,
      startTime: this.startTime,
      endTime: this.endTime,
    });

    this.commentPopover.close();
  }

  onVolumeChange(eventArgs: Event) {
    const input = eventArgs.target as HTMLInputElement;
    const volume = Number(input.value);
    this._volumeBeforeChange = this.volume;

    const beforeVolumeAction = new BeforeVolumeChange(this._volumeBeforeChange);
    this.store.dispatch(beforeVolumeAction);

    if (volume == 0) {
      this._volume = volume;
      this.player.mute();
    } else {
      this.player.unmute();
      this.player.changeVolume(volume);
    }
  }

  onPlayerReady(args: { duration: number; durationString: string }): void {
    this._durationString = args.durationString;

    this.cdr.detectChanges();
  }

  onPlayerPositionChange(args: { currentTime: number; currentTimeString }): void {
    this._currentTimeString = args.currentTimeString;

    this.cdr.detectChanges();
  }

  onPlayerStartPlaying() {
    this._playing = true;

    this.cdr.detectChanges();
  }

  onPlayerPausePlaying() {
    this._playing = false;

    this.cdr.detectChanges();
  }

  onPlayerStopPlaying() {
    this._playing = false;

    this.cdr.detectChanges();
  }

  onPlayerFinishPlaying() {
    this._playing = false;

    this.cdr.detectChanges();
  }

  onPlayerVolumeChange(args: { volume: number }) {
    this._volume = args.volume;

    const action = new VolumeChange(this.volume);
    this.store.dispatch(action);

    this.adjustSliderStyle();
  }

  onPlayerStatusChange(args: { status: PlayerStatus }) {
    this._playerStatus = args.status;

    this.cdr.detectChanges();
  }

  onPlayerSpeedChange(speedType: number) {
    this.speed = speedType;
    this.player.changeSpeed(this.speed);
    const action = new SpeedChange(this.speed);
    this.store.dispatch(action);
  }

  restorePlayerController() {
    this._durationString = '00:00';
    this._currentTimeString = '00:00';
    this._playing = false;
    this.cdr.detectChanges();
  }

  adjustSliderStyle(volume?: number): void {
    if (this.chatMode) {
      return;
    }

    const input = this.volumeSlider.nativeElement as HTMLInputElement;
    const min = 0;
    const max = 100;
    const val = volume ?? Number(input.value);

    input.style.backgroundSize = ((val - min) * 100) / (max - min) + '% 100%';
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store,
    private permissionService: PermissionService,
    private formatHelper: FormatHelper,
    private colorUtils: ColorUtils,
    private transcriptService: CallTranscriptService,
    private conversationDetailService: ConversationDetailService
  ) {
    const state = this.store.selectSnapshot<ConversationModuleStateModel>(ConversationModuleState);
    this.speed = state.player.speed;
    this._volume = state.player.volume;
    this._volumeBeforeChange = state.player.beforeVolume;
    this.canDownloadCall = this.permissionService.getGrantedPolicy('Conversation.CanDownloadCall');
  }

  ngOnInit() {
    this.player.ready.subscribe(this.onPlayerReady.bind(this));

    this.player.positionChange.subscribe(this.onPlayerPositionChange.bind(this));

    this.player.startPlaying.subscribe(this.onPlayerStartPlaying.bind(this));

    this.player.pausePlaying.subscribe(this.onPlayerPausePlaying.bind(this));

    this.player.finishPlaying.subscribe(this.onPlayerFinishPlaying.bind(this));

    this.player.volumeChange.subscribe(this.onPlayerVolumeChange.bind(this));

    this.player.statusChange.subscribe(this.onPlayerStatusChange.bind(this));
  }

  ngAfterViewInit() {
    this.player.changeSpeed(this.speed);
    this.player.changeVolume(this.volume);
  }

  ngOnDestroy() {
    // No need to unsubscribe from events. Angular takes care of them.
    // this.player.ready.unsubscribe();
    // this.player.positionChange.unsubscribe();
    // this.player.startPlaying.unsubscribe();
    // this.player.pausePlaying.unsubscribe();
    // this.player.finishPlaying.unsubscribe();
    // this.player.volumeChange.unsubscribe();
    // this.player.statusChange.unsubscribe();
  }

  private setCategorySliderColor(thumbEl: any, checked: boolean, categoryColor: string): void {
    const rgb = this.colorUtils.hex2rgb(categoryColor);

    const thumbBarColor = `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.5)`;

    thumbEl.parentElement.children[1].children[0].style.backgroundColor = checked
      ? categoryColor
      : '';

    thumbEl.parentElement.style.backgroundColor = checked ? thumbBarColor : '';
  }

  private getFilterFunction(): Function {
    return x =>
      this.selectedCategories.indexOf(x.id) !== -1 ||
      (this.commentsVisible && x.isCommentMarker) ||
      (this.searchResultsVisible && x.isQuickSearchMarker);
  }

  private toggleCategorySlider(
    checked: boolean,
    categoryId: number,
    categoryColor: string,
    arrangeSelectAllCheck: boolean,
    thumbEl: any
  ): void {
    const filterFn = this.getFilterFunction();

    this.setCategorySliderColor(thumbEl, checked, categoryColor);

    if (checked) {
      if (this.selectedCategories.filter(x => x == categoryId).length > 0) {
        return;
      }
      this.selectedCategories.push(categoryId);
    } else {
      const idx = this.selectedCategories.indexOf(categoryId);

      this.selectedCategories.splice(idx, 1);
    }

    const marks = this.transcriptService.getConversationTranscriptAnalysis(
      this.categories,
      this.transcript,
      filterFn
    );
    this.player.categoryMarks = marks;
    this.conversationDetailService.updateCategory({
      categories: this.categories,
      categoryMarks: marks,
    });

    if (arrangeSelectAllCheck) {
      this.arrangeSelectAllCheck();
    }

    this.cdr.detectChanges();
  }

  private arrangeSelectAllCheck(): void {
    const chkSelectAll = document.getElementById('flexCheckIndeterminate') as HTMLInputElement;
    const actualCategoriesLength = this.categories.filter(
      x => x.id > 0 || (x.id < 0 && x.topicTermItems?.length > 0)
    ).length;

    if (actualCategoriesLength === this.selectedCategories.length) {
      this.commentsVisible = this.hasCommentCategory ? this.commentsVisible : true;
      this.searchResultsVisible = this.hasSearchResultCategory ? this.searchResultsVisible : true;
    }

    if (this.selectedCategories.length <= 0) {
      this.commentsVisible = this.hasCommentCategory ? this.commentsVisible : false;
      this.searchResultsVisible = this.hasSearchResultCategory ? this.searchResultsVisible : false;
    }

    if (
      actualCategoriesLength === this.selectedCategories.length &&
      this.commentsVisible &&
      this.searchResultsVisible
    ) {
      chkSelectAll.indeterminate = false;
      chkSelectAll.checked = true;
    } else if (
      this.selectedCategories.length <= 0 &&
      !this.commentsVisible &&
      !this.searchResultsVisible
    ) {
      chkSelectAll.indeterminate = false;
      chkSelectAll.checked = false;
    } else {
      chkSelectAll.indeterminate = true;
    }
  }
}
