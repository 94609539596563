import { Injectable } from '@angular/core';
import { PermissionGroupDto } from 'src/core/models/administration/permission/permission-group.dto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionSaveInput } from 'src/core/models/administration/permission/permission.dto';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private http: HttpClient) {}

  get(providerKey: string, providerName: string): Observable<PermissionGroupDto[]> {
    const url = this.getApiUrl();
    let params = new HttpParams();

    params = params.append('providerKey', providerKey);
    params = params.append('providerName', providerName);

    return this.http.get(url, { params }).pipe(
      map((response: { groups: PermissionGroupDto[] }) => {
        return response.groups;
      })
    ) as Observable<PermissionGroupDto[]>;
  }

  save(
    providerKey: string,
    providerName: string,
    permissions: PermissionSaveInput[]
  ): Observable<any> {
    const url = this.getApiUrl();
    let params = new HttpParams();

    params = params.append('providerKey', providerKey);
    params = params.append('providerName', providerName);

    const body = {
      permissions,
    };

    return this.http.put(url, body, { params });
  }

  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase + '/' + PermissionGroupDto.apiUrl;
  }
}
