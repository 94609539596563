import { EventEmitter, Injectable, Injector, Output } from '@angular/core';
import {
  AllMarkedAsRead,
  NotificationInitialized,
  NotificationReceived,
  NotificationStatusChanged,
} from 'src/core/actions/notification/notification.actions';
import { NotificationHubRequestType } from 'src/core/models/enum/notification-hub-request-type.enum';
import { NotificationHubRequestDto } from 'src/core/models/notification/notification-hub-request.dto';
import { NotificationHubResponseDto } from 'src/core/models/notification/notification-hub-response.dto';
import { HubServiceConfigModel } from 'src/core/models/signalr/hub-service-config.model';
import { environment } from 'src/environments/environment';
import { HubService } from '../signalr/hub.service';
import { Store } from '@ngxs/store';
import { NotificationCountSummaryDto } from 'src/core/models/notification/notification-count-summary.dto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListRequestDto } from 'src/core/models/request/list-request.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { ToasterService } from '@abp/ng.theme.shared';
import { LocalizationService } from '@abp/ng.core';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
import { NotificationHelper } from 'src/core/models/notification/types/notification.helper';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private hubUrl = environment.apis.default.url + 'api/hubs/notification';
  private retrieveMethod = 'SystemNotificationReceived';
  private publishMethod = 'Request';
  private hubConfig: HubServiceConfigModel;
  private hubState: string;
  private readonly connectedState = 'Connected';

  @Output()
  notificationReceived: EventEmitter<{
    notification: any;
  }> = new EventEmitter();

  @Output()
  notificationReadStatusChanged: EventEmitter<{
    notificationId: string;
    readStatus: boolean;
  }> = new EventEmitter();

  constructor(
    private hubService: HubService,
    private store: Store,
    private http: HttpClient,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private toasterHelperService: CAToasterHelperService,
    private injector: Injector,
    private caDatePipe: CADatePipe,
    private router: Router
  ) {
    this.initHub();

    this.requestCountSummary();
  }

  showNotification(
    notificationId: string,
    type: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const cmp = NotificationHelper.notificationList.get(type);
    const toastrFn = cmp?.prototype?.constructor?.showNotification;

    if (toastrFn && typeof toastrFn === 'function') {
      cmp.prototype.constructor.showNotification(
        notificationId,
        payload,
        toastr,
        localizationService,
        toasterHelperService,
        this,
        injector,
        caDatePipe,
        router
      );
    }
  }

  getList(
    status: string,
    request: ListRequestDto | null
  ): Observable<ListResponseDto<NotificationDto>> {
    let params = request === null ? null : new HttpParams();

    if (request !== null) {
      params = params.append('filters', JSON.stringify(request.filters));
      params = params.append('sorters', JSON.stringify(request.sorters));
      params = params.append('skipCount', JSON.stringify(request.skipCount));
      params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));
    }

    return this.http.get(`api/app/notification?status=${status}`, {
      params,
    }) as Observable<ListResponseDto<NotificationDto>>;
  }

  requestCountSummary(): void {
    if (this.hubState !== this.connectedState) {
      setTimeout(this.requestCountSummary.bind(this), 250);

      return;
    }

    this.hubService.publishMessage({
      requestType: NotificationHubRequestType.CountSummary,
      json: '',
    } as NotificationHubRequestDto);
  }

  getReadStatus(id: string): Observable<boolean> {
    return this.http.get(`api/app/notification/${id}/read-status`) as Observable<boolean>;
  }

  markAsRead(id: string): Observable<object> {
    this.notificationReadStatusChanged.emit({
      notificationId: id,
      readStatus: true,
    });

    return this.http.put(`api/app/notification/${id}/mark-as-read`, null).pipe(
      map(
        result => {
          const action = new NotificationStatusChanged(true);
          this.store.dispatch(action);

          return result;
        },
        err => {
          return err;
        }
      )
    );
  }

  markAllAsRead(): Observable<object> {
    return this.http.post(`api/app/notification/mark-all-as-read`, null).pipe(
      map(
        result => {
          const action = new AllMarkedAsRead();

          this.store.dispatch(action);

          return result;
        },
        err => {
          return err;
        }
      )
    );
  }

  markAsUnread(id: string): Observable<object> {
    this.notificationReadStatusChanged.emit({
      notificationId: id,
      readStatus: false,
    });

    return this.http.put(`api/app/notification/${id}/mark-as-unread`, null).pipe(
      map(
        result => {
          const action = new NotificationStatusChanged(false);

          this.store.dispatch(action);

          return result;
        },
        err => {
          return err;
        }
      )
    );
  }

  sendTestNotification(recipient: string, message: string): void {
    this.http
      .post('api/app/playground/notification', {
        recipient: recipient,
        message: message,
      })
      .subscribe(result => {
        this.toastr.success('Notification::NotificationSent');
      });
  }

  private initHub(): void {
    this.hubConfig = {
      url: this.hubUrl,
      retrieveMethod: this.retrieveMethod,
      publishMethod: this.publishMethod,
    };

    this.hubService.messageRetrieved.subscribe((response: any) => {
      const action = new NotificationReceived();

      this.store.dispatch(action);

      this.showNotification(
        response.notificationId,
        response.type,
        response.payload,
        this.toastr,
        this.localizationService,
        this.toasterHelperService,
        this.injector,
        this.caDatePipe,
        this.router
      );

      this.notificationReceived.emit({ notification: response });
    });

    this.hubService.messageInvoked.subscribe((response: NotificationHubResponseDto) => {
      if (response.success) {
        switch (response.requestType) {
          case NotificationHubRequestType.CountSummary:
            this.initializeCountSummary(JSON.parse(response.json) as NotificationCountSummaryDto);
        }
      }
    });

    this.hubService.getConnectionState().subscribe(value => {
      this.hubState = value;
    });

    this.hubService.config = this.hubConfig;
  }

  private initializeCountSummary(countSummary: NotificationCountSummaryDto): void {
    const action = new NotificationInitialized(countSummary);

    this.store.dispatch(action);
  }
}
