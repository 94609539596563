import { EntityDto } from '../shared/entity.dto';

export class OrderedGroupWithRangeQueryItemDto extends EntityDto {
  categoryId: number;
  categoryItemId: number;
  internalId: number;
  parentInternalId: number;
  startTime: number;
  endTime: number;
  queryId: number;
  rangeUnitId: number;
  rangeUnitCode: number;
  multiple: boolean;
}
