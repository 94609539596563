<ng-template #popContent>
  <ng-container>
    <div class="d-flex flex-column align-items-end">
      <textarea
        [attr.id]="'player-comment'"
        class="form-control question-comment-textarea"></textarea>
      <button type="button" class="btn ca-btn ca-btn-neutral mt-2 d-flex" (click)="onSaveComment()">
        <i class="far fa-save"></i><span>{{ '::Comment' | abpLocalization }}</span>
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #popTitle
  ><b
    >{{ '::Comment' | abpLocalization }} {{ startTime }}
    {{ endTime != null ? '-' + endTime : '' }}</b
  >
</ng-template>

<div *ngIf="!chatMode" class="player-controller-container">
  <div class="player-controller-label">
    <span class="mt-1">{{ currentTimeString }} / {{ durationString }}</span>
  </div>
  <div class="player-controller-item" *ngIf="!playing">
    <button
      type="button"
      class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button"
      [disabled]="buttonsDisabled"
      (click)="onPlayButtonClick($event)">
      <i class="fas fa-play"></i>
    </button>
  </div>
  <div class="player-controller-item" *ngIf="playing">
    <button
      type="button"
      [disabled]="buttonsDisabled"
      class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button"
      (click)="onPauseButtonClick($event)">
      <i class="fas fa-pause"></i>
    </button>
  </div>
  <div class="player-controller-item">
    <button
      type="button"
      [disabled]="buttonsDisabled || !playing"
      class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button"
      (click)="onStopButtonClick($event)">
      <i class="fas fa-stop"></i>
    </button>
  </div>
  <div class="player-controller-item">
    <div class="toolbar-item first h-100">
      <div ngbDropdown class="d-inline-block h-100">
        <button
          class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button"
          id="playerSpeedDropdown"
          ngbDropdownToggle>
          {{ speed }}x
        </button>
        <div ngbDropdownMenu aria-labelledby="playerSpeedDropdown">
          <button
            *ngFor="let speedOption of speedOptions"
            (click)="onPlayerSpeedChange(speedOption)"
            ngbDropdownItem>
            {{ speedOption }}x
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="player-controller-item" *ngIf="canDownloadCall && !hideDownloadButton">
    <button
      type="button"
      [disabled]="buttonsDisabled"
      class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button"
      (click)="onDownloadButtonClick($event)">
      <i class="fas fa-download"></i>
    </button>
  </div>
  <div class="player-controller-item" *ngIf="!hideCommentButton">
    <button
      type="button"
      class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button"
      (click)="onCommentButtonClick(commentPopover)"
      #commentPopover="ngbPopover"
      [ngbPopover]="popContent"
      [popoverTitle]="popTitle"
      [disablePopover]="buttonsDisabled"
      container="body"
      [autoClose]="'outside'"
      triggers="manual"
      (shown)="onPopoverShown()">
      <i class="fas fa-comment-plus"></i>
    </button>
  </div>
  <div class="player-controller-item" *ngIf="!playerExpanded && collapsible">
    <button
      type="button"
      class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button"
      (click)="onExpandButtonClick($event)">
      <i class="fas fa-expand"></i>
    </button>
  </div>
  <div class="player-controller-item" *ngIf="playerExpanded && collapsible">
    <button
      type="button"
      class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button"
      (click)="onCollapseButtonClick($event)">
      <i class="fas fa-compress"></i>
    </button>
  </div>
  <div class="player-controller-item">
    <div
      class="d-inline-block volume-button-wrapper h-100"
      ngbDropdown
      #volumeDropdown="ngbDropdown"
      container="body"
      placement="top-start"
      (mouseenter)="volumeDropdown.open()"
      (mouseleave)="volumeDropdown.close()">
      <button
        type="button"
        class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button"
        (click)="isMuted ? onUnmuteButtonClick($event) : onMuteButtonClick($event)"
        ngbDropdownAnchor>
        <i [ngClass]="volumeIcon"></i>
      </button>
      <div
        ngbDropdownMenu
        (mouseenter)="volumeDropdown.open()"
        (mouseleave)="volumeDropdown.close()"
        class="volume-slider-dropdown">
        <div class="volume-slider-wrapper">
          <input
            #volumeSlider
            type="range"
            class="vertical-volume-slider"
            [value]="volume"
            (change)="onVolumeChange($event)"
            (input)="adjustSliderStyle()" />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showCategoryButton" class="player-controller-item">
    <div
      ngbDropdown
      dropdownClass="categoryDropdownContainer"
      class="d-inline-block h-100"
      [ngbTooltip]="('Conversation::NoCommentsOrMatchingCategories' | abpLocalization) + '.'"
      [disableTooltip]="
        !(categories.length <= 0 && !hasSearchResultCategory && !hasCommentCategory)
      ">
      <button
        class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button w-100"
        [disabled]="categories.length <= 0 && !hasSearchResultCategory && !hasCommentCategory"
        id="categoryDropdown"
        ngbDropdownToggle>
        <i class="ca category small-font"></i
        ><span class="player-controller-label small-font me-1">{{
          'Category::Categories' | abpLocalization
        }}</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="categoryDropdown">
        <div class="dropdown-container">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckIndeterminate"
              (change)="onSelectAllCategoriesChange($event)" />
            <label class="form-check-label" for="flexCheckIndeterminate">
              {{ '::ShowHideAll' | abpLocalization }}
            </label>
          </div>
          <hr />
          <div
            class="static-badge-container"
            *ngIf="hasCommentCategory || hasSearchResultCategory || searchedCategoryIds.length > 0">
            <mat-slide-toggle
              *ngIf="hasCommentCategory"
              class="category-mark"
              [disableRipple]="true"
              [checked]="commentsVisible"
              (change)="onCommentVisibilityChange($event)"
              >{{ '::Comments' | abpLocalization }}</mat-slide-toggle
            >
            <mat-slide-toggle
              *ngIf="hasSearchResultCategory"
              class="category-mark"
              [disableRipple]="true"
              [checked]="searchResultsVisible"
              (change)="onSearchResultVisibilityChange($event)"
              >{{ 'Conversation::SearchResults' | abpLocalization }}</mat-slide-toggle
            >
            <mat-slide-toggle
              *ngIf="searchedCategoryIds.length > 0"
              class="category-mark"
              [disableRipple]="true"
              (change)="onCategoryResultVisibilityChange($event)"
              [checked]="isCategoryResultsInputChecked"
              >{{ 'Conversation::QueryResults' | abpLocalization }}</mat-slide-toggle
            >
            <div></div>
          </div>
          <hr
            *ngIf="
              hasCommentCategory || hasSearchResultCategory || searchedCategoryIds.length > 0
            " />
          <div class="dynamic-badge-container">
            <ng-container *ngFor="let category of categories">
              <ng-container
                *ngIf="
                  !category.isCommentMarker &&
                  !category.isQuickSearchMarker &&
                  category.rootQueryId === null
                ">
                <mat-slide-toggle
                  #categoryMark
                  class="category-mark"
                  [disableRipple]="true"
                  [attr.data-category-color]="category.categoryColor"
                  [attr.data-category-id]="category.id"
                  [ngbTooltip]="category.categoryName"
                  (change)="onCategoryVisibilityChange($event, category.id, category.categoryColor)"
                  openDelay="750"
                  >{{ category.categoryName }}</mat-slide-toggle
                >
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showSentimentButtons">
    <mat-button-toggle-group class="player-toggle-group" [value]="waveFormEnabled">
      <mat-button-toggle [value]="false" (click)="onShowSentimentButtonClick($event)"
        ><i class="fa-solid fa-face-smile"></i
      ></mat-button-toggle>
      <mat-button-toggle [value]="true" (click)="onShowWaveFormButtonClick($event)"
        ><i class="fa-solid fa-waveform-lines"></i
      ></mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>

<div *ngIf="chatMode" class="player-controller-container">
  <div class="player-controller-item first" *ngIf="!hideCommentButton">
    <button
      type="button"
      class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button"
      (click)="onCommentButtonClick(commentPopover)"
      #commentPopover="ngbPopover"
      [ngbPopover]="popContent"
      [popoverTitle]="popTitle"
      [disablePopover]="buttonsDisabled"
      container="body"
      [autoClose]="'outside'"
      triggers="manual"
      (shown)="onPopoverShown()">
      <i class="fas fa-comment-plus"></i>
    </button>
  </div>
  <div *ngIf="showCategoryButton" class="player-controller-item">
    <div
      ngbDropdown
      dropdownClass="categoryDropdownContainer"
      class="d-inline-block h-100"
      [ngbTooltip]="('Conversation::NoCommentsOrMatchingCategories' | abpLocalization) + '.'"
      [disableTooltip]="
        !(categories.length <= 0 && !hasSearchResultCategory && !hasCommentCategory)
      ">
      <button
        class="btn btn-sm ca-btn ca-btn-outline ca-btn-neutral-toolbar toolbar-button player-button w-100"
        [disabled]="categories.length <= 0 && !hasSearchResultCategory && !hasCommentCategory"
        id="categoryDropdown"
        ngbDropdownToggle>
        <i class="ca category small-font"></i
        ><span class="player-controller-label small-font me-1">{{
          'Category::Categories' | abpLocalization
        }}</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="categoryDropdown">
        <div class="dropdown-container">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckIndeterminate"
              (change)="onSelectAllCategoriesChange($event)" />
            <label class="form-check-label" for="flexCheckIndeterminate">
              {{ '::ShowHideAll' | abpLocalization }}
            </label>
          </div>
          <hr />
          <div
            class="static-badge-container"
            *ngIf="hasCommentCategory || hasSearchResultCategory || searchedCategoryIds.length > 0">
            <mat-slide-toggle
              *ngIf="hasCommentCategory"
              class="category-mark"
              [disableRipple]="true"
              [checked]="commentsVisible"
              (change)="onCommentVisibilityChange($event)">
              {{ '::Comments' | abpLocalization }}</mat-slide-toggle
            >
            <mat-slide-toggle
              *ngIf="hasSearchResultCategory"
              class="category-mark"
              [disableRipple]="true"
              [checked]="searchResultsVisible"
              (change)="onSearchResultVisibilityChange($event)"
              >{{ 'Conversation::SearchResults' | abpLocalization }}</mat-slide-toggle
            >
            <mat-slide-toggle
              *ngIf="searchedCategoryIds.length > 0"
              class="category-mark"
              [disableRipple]="true"
              (change)="onCategoryResultVisibilityChange($event)"
              [checked]="isCategoryResultsInputChecked">
              {{ 'Conversation::QueryResults' | abpLocalization }}
            </mat-slide-toggle>
            <div></div>
          </div>
          <hr
            *ngIf="
              hasCommentCategory || hasSearchResultCategory || searchedCategoryIds.length > 0
            " />
          <div class="dynamic-badge-container">
            <ng-container *ngFor="let category of categories">
              <ng-container *ngIf="!category.isCommentMarker && !category.isQuickSearchMarker">
                <mat-slide-toggle
                  #categoryMark
                  class="category-mark"
                  [disableRipple]="true"
                  [attr.data-category-color]="category.categoryColor"
                  [attr.data-category-id]="category.id"
                  [ngbTooltip]="category.categoryName"
                  (change)="onCategoryVisibilityChange($event, category.id, category.categoryColor)"
                  openDelay="750"
                  >{{ category.categoryName }}</mat-slide-toggle
                >
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showSentimentButtons">
    <mat-button-toggle-group class="player-toggle-group" [value]="waveFormEnabled">
      <mat-button-toggle [value]="false" (click)="onShowSentimentButtonClick($event)"
        ><i class="fa-solid fa-face-smile"></i
      ></mat-button-toggle>
      <mat-button-toggle [value]="true" (click)="onShowWaveFormButtonClick($event)"
        ><i class="fa-solid fa-waveform-lines"></i
      ></mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
