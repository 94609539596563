import { Directive, ElementRef, OnInit, HostListener } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[showButtonsOnHover]',
})
export class ShowButtonsOnHoverDirective implements OnInit {
    private elements: HTMLElement[] = [];

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        this.elements = this.elementRef.nativeElement.querySelectorAll(
            '.show-on-hover'
        );
    }

    @HostListener('mouseenter') mouseover() {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.elements.length; i++) {
            const button = this.elements[i];

            if (!button.classList.contains('show-on-hover-disabled')) {
                button.style.display = 'initial';
            }
        }
    }

    @HostListener('mouseleave') mouseleave() {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.elements.length; i++) {
            const button = this.elements[i];

            if (!button.classList.contains('show-on-hover-disabled')) {
                button.style.display = 'none';
            }
        }
    }
}
