import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { Select, Store } from '@ngxs/store';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { map } from 'rxjs/operators';
import { ConversationChatListViewOption } from 'src/core/models/generic-lookup-type/conversation/conversation-chat-list-view-option.glt';
import { ConversationService } from 'src/core/services/conversation/conversation.service';

@Component({
  selector: 'ca-conversation-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
})
export class ChatListComponent implements OnInit {
  @Select(ConversationModuleState.getActiveList)
  activeList$: Observable<number>;

  @Select(ConversationModuleState.getActiveView)
  activeView$: Observable<number>;

  viewOptions$: Observable<GenericLookupDto[]>;
  viewOptions: GenericLookupDto[] = [];

  @Output()
  conversationIdChange: EventEmitter<{
    id: number;
  }> = new EventEmitter();

  @Output()
  dataLoaded = new EventEmitter();

  standardListVisible = true;

  constructor(private store: Store, private conversationService: ConversationService) {
    this.viewOptions$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationChatListViewOption)));

    this.viewOptions$.subscribe(genericLookups => {
      this.viewOptions = genericLookups;
    });

    this.activeView$.subscribe(activeView => {
      this.standardListVisible = activeView === ConversationChatListViewOption.standard;
    });
  }

  ngOnInit() {}

  onConversationIdChanged(conversationId) {
    this.conversationIdChange.emit(conversationId);
  }

  onDataLoaded() {
    this.dataLoaded.emit();
  }
}
