<div>
  <h2>{{ 'Settings::ZendeskApplication' | abpLocalization }}</h2>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::ZendeskApplicationClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="clientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">
      {{ 'Settings::ZendeskApplicationClientSecret' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientSecret"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
