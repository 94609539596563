import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { DepartmentService } from '../../services/department.service';
import { DepartmentDto } from 'src/core/models/administration/department/department.dto';
import { DepartmentTreeNode } from '../../model/department-tree-node.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { BaseDropdownSelectorComponent } from 'src/ui/base-selector/base-selector.module';
import { CAConstants } from 'src/core/constants/ca-constant';

@Component({
  selector: 'ca-department-selector-v2',
  templateUrl: './department-selector.component.html',
  styleUrls: ['./department-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DepartmentSelectorComponent),
    },
  ],
})
@AutoUnsubscribe()
export class DepartmentSelectorComponent
  extends BaseDropdownSelectorComponent<DepartmentDto>
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  private _disabledItemIdList: number[] = [];

  @Input()
  hideHeader: boolean = false;

  @Input()
  multipleSingleRemove: boolean = false;

  @Input()
  set disabledItemIdList(items: number[]) {
    this._disabledItemIdList = items;
    setTimeout(() => {
      this.refreshDisabledData();
    }, 500);
  }

  get disabledItemIdList(): number[] {
    return this._disabledItemIdList;
  }

  singleValue: DepartmentTreeNode = null;
  multipleValue: DepartmentTreeNode[] = [];
  departmentStatusFilter: 'all' | 'active' | 'passive' = 'active';

  private cache: DepartmentDto[] = [];
  private treeData: DepartmentDto[] = [];
  private expandedNodes: number[] = [];
  private previouslyLoadedNodes: number[] = [];

  constructor(
    private service: DepartmentService,
    protected elementRef: ElementRef,
    protected ngZone: NgZone
  ) {
    super(elementRef, ngZone);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.quickSearchTermInput$
      .pipe(takeUntil(this.autoUnsubscribeNotifier), debounceTime(250), distinctUntilChanged())
      .subscribe(val => {
        this.quickSearchTerm = val;

        if (
          0 < this.quickSearchTerm.length &&
          this.quickSearchTerm.length < CAConstants.SEARCH_INPUT_MIN_LENGTH
        ) {
          return;
        }

        this.load();
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  writeValue(val: DepartmentDto | DepartmentDto[] | null): void {
    if (this.multiple) {
      val = (val as DepartmentDto[]) ?? [];

      val.forEach(v => {
        this.multipleValue.push({
          department: v,
          expanded: false,
          level: 0,
          disabled: this.disabledItemIdList.indexOf(v.id) === -1 ? false : true,
        });
      });
    } else if (!Array.isArray(val) && val !== null) {
      this.singleValue = {
        department: val,
        expanded: false,
        level: 0,
        disabled: this.disabledItemIdList.indexOf(val.id) === -1 ? false : true,
      };
    }

    this.setValue();
  }

  onChangeBase(val: DepartmentTreeNode | DepartmentTreeNode[]): void {
    if (this.multiple) {
      // onChange will be triggered by onAdd
      return;
    }

    this.onChange(this.singleValue?.department);
  }

  onExpandDepartmentClick(
    eventArgs: MouseEvent,
    node: DepartmentTreeNode,
    nodeIndex: number
  ): void {
    eventArgs.preventDefault();
    eventArgs.stopPropagation();

    this.expandNode(node, nodeIndex);
  }

  onCollapseDepartmentClick(
    eventArgs: MouseEvent,
    node: DepartmentTreeNode,
    nodeIndex: number
  ): void {
    eventArgs.preventDefault();
    eventArgs.stopPropagation();

    this.collapseNode(node, nodeIndex);
  }

  compareFn(a: DepartmentTreeNode, b: DepartmentTreeNode): boolean {
    return a.department.id === b.department.id;
  }

  onAdd(eventArgs: DepartmentTreeNode): void {
    this.loading = true;

    this.service
      .getChildDepartments(eventArgs.department.id, this.departmentStatusFilter, true)
      .subscribe(response => {
        response.items.forEach(i => {
          const alreadySelected = this.multipleValue.findIndex(x => x.department.id === i.id) > -1;

          if (!alreadySelected) {
            const newNode: DepartmentTreeNode = {
              department: i,
              level: 0,
              expanded: false,
              disabled: this.disabledItemIdList.indexOf(i.id) === -1 ? false : true,
            };

            this.multipleValue.push(newNode);
          }
        });

        this.setValue();
        this.onChange(this.multipleValue.map(x => x.department));

        this.loading = false;
      });
  }

  onClear(eventArgs: unknown): void {
    this.initialLoad = true;
    this.quickSearchTerm = '';

    this.load();

    if (this.multiple) {
      this.onChange([]);
    }
  }

  onStatusFilterChanged(eventArgs: Event): void {
    const radio = eventArgs.currentTarget as HTMLInputElement;

    if (radio.checked && radio.value) {
      this.departmentStatusFilter = radio.value as 'all' | 'active' | 'passive';
      this.load();
    }
  }

  isDepartmentSelected(id: number): boolean {
    return this.multipleValue.findIndex(x => x.department.id === id) > -1;
  }

  onDepartmentCheckClick(eventArgs: Event, node: DepartmentTreeNode): void {
    const checkbox = eventArgs.currentTarget as HTMLInputElement;

    if (!checkbox.disabled) {
      checkbox.checked = !checkbox.checked;
    }
  }

  protected load(): void {
    const hasQuickSearchTerm = this.quickSearchTerm.trim().length > 0;
    let selectedIds = [];

    if (this.initialLoad && this.multiple) {
      selectedIds = this.multipleValue.map(x => x.department.id);
    } else if (this.initialLoad && !this.multiple && this.singleValue !== null) {
      selectedIds = [this.singleValue.department.id];
    }

    let fn = hasQuickSearchTerm
      ? this.service.getFilteredDepartments(this.quickSearchTerm, this.departmentStatusFilter)
      : this.service.getRootDepartments(this.departmentStatusFilter, selectedIds);

    this.loading = true;
    this.expandedNodes.splice(0, this.expandedNodes.length);
    this.previouslyLoadedNodes.splice(0, this.previouslyLoadedNodes.length);

    fn.subscribe(response => {
      this.cache = Array.from(response.items);
      this.treeData = Array.from(response.items);
      this.buildTree(hasQuickSearchTerm || this.initialLoad);

      this.initialLoad = false;
      this.loading = false;
    });
  }

  private expandPath(ids: number[], callback: Function | null): void {
    if (ids.length > 0) {
      this.expandNodeById(ids[0], () => {
        ids.splice(0, 1);
        this.expandPath(ids, callback);
      });
    } else if (callback !== null) {
      callback.apply(this);
    }
  }

  private expandNodeById(id: number, callback: Function | null = null): void {
    this.expandedNodes.push(id);

    if (this.previouslyLoadedNodes.includes(id)) {
      const dataToAdd = this.cache.filter(x => x.parentId === id);

      this.treeData.push(...dataToAdd);

      this.buildTree();

      if (callback !== null) {
        callback.apply(this);
      }

      return;
    }

    this.loading = true;

    this.service.getChildDepartments(id, this.departmentStatusFilter, false).subscribe(response => {
      this.previouslyLoadedNodes.push(id);

      this.treeData.push(...response.items);
      this.cache.push(...response.items);

      this.buildTree();

      this.loading = false;

      if (callback !== null) {
        callback.apply(this);
      }
    });
  }

  private expandNode(node: DepartmentTreeNode, nodeIndex: number): void {
    this.expandNodeById(node.department.id);
  }

  private collapseNode(node: DepartmentTreeNode, nodeIndex: number): void {
    this.expandedNodes = this.expandedNodes.filter(x => x !== node.department.id);

    this.removeNodes(node.department.id);
    this.buildTree();
  }

  private buildTree(expandParentNodes: boolean = false): void {
    let items = [];

    if (expandParentNodes) {
      this.treeData.forEach(i => {
        const childIdx = this.treeData.findIndex(x => x.parentId === i.id);

        if (childIdx > -1) {
          this.expandedNodes.push(i.id);
        }
      });
    }

    this.treeData
      .filter(i => i.parentId == null)
      .forEach(i => {
        const parentIds: number[] = [i.id];

        items.push(<DepartmentTreeNode>{
          expanded: this.expandedNodes.includes(i.id),
          level: 0,
          department: i,
          parentIds: [],
          disabled: this.disabledItemIdList.indexOf(i.id) === -1 ? false : true,
        });

        const childNodes = this.buildNodes(i.id, 1, parentIds);

        items.push(...childNodes);
      });

    this.ngSelect.itemsList.setItems(items);

    this.setValue();
  }

  private buildNodes(parentId: number | null, level: number | null, parentIds: number[]): any[] {
    const children = this.treeData.filter(i => i.parentId === parentId);
    const result = [];

    if (children.length === 0) {
      return result;
    }

    const newParentIds = Array.from(parentIds);

    children.forEach(i => {
      result.push(<DepartmentTreeNode>{
        expanded: this.expandedNodes.includes(i.id),
        level: level,
        department: i,
        parentIds: newParentIds,
        disabled: this.disabledItemIdList.indexOf(i.id) === -1 ? false : true,
      });

      parentIds.push(i.id);

      const childNodes = this.buildNodes(i.id, level + 1, parentIds);

      result.push(...childNodes);
    });

    return result;
  }

  private removeNodes(parentId: number): void {
    const newItems = this.ngSelect.itemsList.items
      .map(x => x.value)
      .filter(x => !x.parentIds.includes(parentId))
      .map(x => x.department);

    this.ngSelect.itemsList.items
      .map(x => x.value)
      .filter(x => x.parentIds.includes(parentId))
      .map(x => x.department)
      .forEach(x => {
        this.expandedNodes = this.expandedNodes.filter(n => n != x.id);
      });

    this.treeData = newItems;
  }

  private setValue(): void {
    if (this.multiple) {
      this.multipleValue = new Array(...this.multipleValue);
    } else if (this.singleValue !== null) {
      this.singleValue = Object.assign({}, this.singleValue);
    }
  }

  removeFromSelectedDepartment(item) {
    this.multipleValue = this.multipleValue.filter(f => f.department.id != item.department.id);
    this.onChange(this.multipleValue.map(x => x.department));
  }

  refreshDisabledData() {
    if (!this.ngSelect || this.ngSelect.itemsList?.items.length <= 0) {
      return;
    }
    for (let i = 0; i < this.ngSelect.itemsList.items.length; i++) {
      const element = this.ngSelect.itemsList.items[i];

      element.disabled = this.disabledItemIdList.includes(element.value.department.id);
    }
  }
}
