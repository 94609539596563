import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

import 'src/core/services/auth/oauth-service.extensions';
import { CAConfigStateService } from 'src/core/services/config/ca-config-state.service';

@Component({
  selector: 'ca-external-main',
  templateUrl: './external-main.component.html',
  styleUrls: ['./external-main.component.scss'],
})
export class ExternalMainComponent implements OnInit {
  get hasLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private oAuthService: OAuthService,
    private configStateService: CAConfigStateService
  ) {
    const refreshToken = this.route.snapshot.queryParams.refreshToken;
    const redirectTo = this.route.snapshot.queryParams.redirectTo;
    const fragments = redirectTo ? redirectTo.split('/') : [];

    document.body.classList.add('external-view');

    if (this.hasLoggedIn) {
      this.router.navigate(fragments);

      return;
    }

    if (refreshToken) {
      this.oAuthService
        .fetchTokenUsingGrant('refresh_token', {
          refresh_token: refreshToken,
        })
        .then(result => {
          this.configStateService.refreshAppState().subscribe(res => {
            this.router.navigate(fragments);
          });
        });

      return;
    }
  }

  ngOnInit(): void {}
}
