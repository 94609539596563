import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimelineService {
  private subject = new Subject<any>();

  closePopover(popoverTime: number) {
    this.subject.next({ popoverTime: popoverTime });
  }

  onPopoverClose(): Observable<any> {
    return this.subject.asObservable();
  }
}
