// your-custom-toaster.service.ts
import { Injectable } from '@angular/core';
import { LocalizationService, LocalizationParam } from '@abp/ng.core';
import { Toaster } from '@abp/ng.theme.shared';
import { ToastrService } from 'ngx-toastr';
import { Injector } from '@angular/core';

@Injectable()
export class CAToasterService implements Toaster.Service {
  toastr: ToastrService;

  constructor(private injector: Injector, private localizationService: LocalizationService) {}

  getToastr(): ToastrService {
    if (!this.toastr) {
      this.toastr = this.injector.get(ToastrService);
    }
    return this.toastr;
  }

  error(
    message: LocalizationParam,
    title?: LocalizationParam,
    options?: Partial<Toaster.ToastOptions>
  ) {
    return this.show(message, title, 'error', options);
  }

  clear(): void {
    this.getToastr().clear();
  }

  info(
    message: LocalizationParam,
    title: LocalizationParam | undefined,
    options: Partial<Toaster.ToastOptions> | undefined
  ): Toaster.ToasterId {
    return this.show(message, title, 'info', options);
  }

  remove(id: number): void {
    this.getToastr().remove(id);
  }

  show(
    message: LocalizationParam,
    title: LocalizationParam,
    severity: Toaster.Severity,
    options: Partial<any>
  ): Toaster.ToasterId {
    const translatedMessage = this.localizationService.instant(message);
    const translatedTitle = this.localizationService.instant(title);

    let timeOut = 3000;

    const easeTime = 500;
    const milliSecondPerCharacter = 80;
    const defaultErrorTimeOut = 4000;
    const maxErrorTimeOut = 7000;

    if (severity === 'error') {
      timeOut = Math.min(
        Math.max(translatedMessage.length * milliSecondPerCharacter, defaultErrorTimeOut),
        maxErrorTimeOut
      );
    }

    var toasterOptions = {
      positionClass: 'toast-bottom-right',
      timeOut: timeOut,
      easeTime: easeTime,
      closeButton: true,
      enableHtml: true,
      tapToDismiss: options?.tapToDismiss,
      ...(options?.sticky && {
        extendedTimeOut: 0,
        timeOut: 0,
      }),
    };
    if (options?.customToastrClass != null) {
      toasterOptions['toastClass'] = 'ngx-toastr ' + options?.customToastrClass;
    }
    const activeToast = this.getToastr().show(
      translatedMessage,
      translatedTitle,
      toasterOptions,
      `toast-${severity}`
    );
    activeToast.portal.location.nativeElement.setAttribute('toaster-type', message);
    return activeToast.toastId;
  }

  success(
    message: LocalizationParam,
    title: LocalizationParam | undefined,
    options: Partial<Toaster.ToastOptions> | undefined
  ): Toaster.ToasterId {
    return this.show(message, title, 'success', options);
  }

  warn(
    message: LocalizationParam,
    title: LocalizationParam | undefined,
    options: Partial<Toaster.ToastOptions> | undefined
  ): Toaster.ToasterId {
    return this.show(message, title, 'warning', options);
  }
}
