<ng-select
  #ngSelect
  appendTo="body"
  class="selector-with-pagination"
  [ngClass]="classList"
  (change)="onChange($event)"
  (open)="onOpen($event)"
  (clear)="onClear($event)"
  [placeholder]="placeholder"
  [items]="items"
  [multiple]="multiple"
  [closeOnSelect]="closeOnSelect"
  [searchable]="true"
  [loading]="loading"
  [(ngModel)]="value"
  [minTermLength]="0"
  [disabled]="isDisabled"
  [compareWith]="compareFn"
  [typeahead]="quickSearchTermInput$"
  loadingText="{{ 'CA::Loading' | abpLocalization }}..."
  bindLabel="name">
  <ng-template *ngIf="headerInfo" ng-header-tmp>
    <div [ngStyle]="headerInfoStyle" [innerHtml]="headerInfo"></div>
  </ng-template>
  <ng-template ng-footer-tmp>
    <div class="w-100 d-flex d-flex flex-row justify-content-start align-items-center">
      <mat-paginator
        class="d-flex flex-row justify-content-row"
        style="flex: 0 0 350px"
        [length]="totalCount"
        [pageSize]="itemsPerPage"
        [pageIndex]="pageIndex"
        [hidePageSize]="true"
        [showFirstLastButtons]="true"
        (page)="onPageChange($event)"></mat-paginator>
    </div>
  </ng-template>
</ng-select>
