import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { ActionLogDto } from 'src/core/models/action-log/action-log.dto';
import { ActionLogHelper } from 'src/core/services/helper/action-log.helper';

@Component({
  selector: 'ca-action-log-row',
  templateUrl: './action-log-row.component.html',
  styleUrls: ['./action-log-row.component.scss'],
})
export class ActionLogRowComponent implements OnInit {
  @Input()
  actionLog: ActionLogDto;

  @Input()
  activeTab: number;

  constructor(private resolver: ComponentFactoryResolver, private vcRef: ViewContainerRef) {}

  ngOnInit(): void {
    const cmp = ActionLogHelper.actionTypeMappings.get(this.actionLog.actionTypeId);

    const factory = cmp ? this.resolver.resolveComponentFactory(cmp?.prototype.constructor) : null;

    if (factory !== null) {
      const compRef = this.vcRef.createComponent(factory);

      (<any>compRef.instance).data = this.actionLog;
      (<any>compRef.instance).activeTab = this.activeTab;
    }
  }
}
