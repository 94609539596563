<div class="row m-0 p-3" *ngIf="relatedConversations == null || relatedConversations.length === 0">
  {{ 'Conversation::ConversationNotContainRelatedConversations' | abpLocalization }}
</div>
<table
  mat-table
  *ngIf="relatedConversations != null && relatedConversations.length > 0"
  [dataSource]="relatedConversations"
  style="width: 100%"
  class="zebra">
  <ng-container *ngFor="let gridColumn of gridColumns">
    <ng-container
      *ngIf="gridColumn.columnName !== 'id' && gridColumn.columnName !== 'userName'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let element" [innerHtml]="element | objectparser : gridColumn"></td>
    </ng-container>
    <ng-container *ngIf="gridColumn.columnName === 'id'" matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width"
        scope="col">
        {{ gridColumn.header }}
      </th>
      <td mat-cell *matCellDef="let conversation">
        <ca-clickable-link
          [innerHtml]="conversation | objectparser : gridColumn"
          [targetLink]="['/conversation', conversation.id]">
        </ca-clickable-link>
      </td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'userName'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.width"
        [style.maxWidth]="gridColumn.width"
        [ngClass]="gridColumn.headerClass">
        {{ gridColumn.header }}
      </th>
      <td mat-cell [ngClass]="gridColumn.cellClass" *matCellDef="let conversation">
        <ca-user-identity
          [id]="conversation.userId"
          [name]="conversation.userName"
          [surname]="conversation.userSurname"></ca-user-identity>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (dblclick)="onRelatedConversationRowClick(row.id)"></tr>

  <!-- Group header -->
  <ng-container matColumnDef="groupHeader">
    <td colspan="999" mat-cell *matCellDef="let groupBy">
      <span
        class="badge category-badge"
        [title]="groupBy.initial"
        [ngStyle]="colorUtils.getColors(groupBy.color)"
        >{{ groupBy.initial }}</span
      >
    </td>
  </ng-container>
</table>
