import { downloadBlob, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConversationImportFileModel } from 'src/core/models/conversation/conversation-import-file-model';
import { ChatService } from 'src/core/services/conversation/chat.service';
import { ConversationImportCommunicationService } from '../../../conversation-import-communication.service';

@Component({
  selector: 'ca-chat-import-step-one',
  templateUrl: './chat-import-step-one.component.html',
  styleUrls: ['./chat-import-step-one.component.scss'],
})
export class ChatImportStepOne implements OnInit {

  @ViewChild('fileInput')
  fileInputVariable: ElementRef;

  public selectedConversationFiles: ConversationImportFileModel[] = [];
  selectedFileSizes: number = 0;
  acceptFileExtensions: string[] = [".csv"];
  fileList: FileList;
  infoLabel: string = "";
  maxChatFileSize: number = 200 * 1024 * 1024;

  constructor(
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private communicationService: ConversationImportCommunicationService,
    private chatService: ChatService,
  ) {
    this.infoLabel = this.localizationService.instant('Conversation::UploadChatStepInfo');
    this.infoLabel = this.infoLabel.replace('{0}', "<b>" + this.localizationService.instant('Conversation::UploadChatMaxSize') + "</b>");
  }

  ngOnInit(): void {
    this.communicationService.isFilesSelected = false;
    this.communicationService.isFilesValid = false;
  }

  ngOnDestroy(): void {
    this.communicationService.isFilesSelected = false;
    this.communicationService.isFilesValid = false;
  }

  onFileChange(files: FileList) {
    if (files.length > 1000) {
      this.toastr.error(this.localizationService.instant('Conversation::ConversationImportFileLimit'));
      return;
    }
    this.selectedConversationFiles = [];
    this.selectedFileSizes = 0;
    this.fileList = files;
    this.communicationService.isFilesSelected = files.length > 0;

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      const splitName = file.name.split('.');
      let isValid = true;
      let invalidMessage = "";
      if (this.acceptFileExtensions.indexOf('.' + splitName[splitName.length - 1]) <= -1) {
        isValid = false;
        invalidMessage = this.localizationService.instant('::FileTypeNotAllowed');
      }
      if (file.size > this.maxChatFileSize) {
        isValid = false;
        invalidMessage = this.localizationService.instant('Conversation::FileSizeTooLarge');
      }
      this.selectedFileSizes += this.getSize(file.size);
      this.selectedConversationFiles.push(new ConversationImportFileModel(i, file, isValid, invalidMessage));
    }

    this.communicationService.isFilesValid = this.isChatFilesValid();
  }

  validateEntryToStep() {
    return true;
  }

  validateExitFromStep() {
    if (this.selectedFileSizes > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  removeFile(data) {
    this.selectedConversationFiles = this.selectedConversationFiles.filter(x => x.id != data.id);
    this.selectedFileSizes -= this.getSize(data.file.size);
    this.communicationService.isFilesSelected = this.selectedConversationFiles.length > 0;
    this.communicationService.isFilesValid = this.isChatFilesValid();
    this.fileInputVariable.nativeElement.value = '';
  }

  getSize(num) {
    return num / 1024 / 1024
  }

  isChatFilesValid() {
    let invalidFileCount = this.selectedConversationFiles.filter(f => !f.isValid).length;
    if (invalidFileCount == 0) {
      return true;
    }
    return false;
  }

  downloadSampleChatCsv() {
    this.chatService.downloadSampleChatCsv().subscribe(result => {
      if (result.size > 0) {
        downloadBlob(result, 'sampleChatFile.csv');
      }
    });
  }
}