import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CompactFormComponent } from './compact-form/compact-form/compact-form.component';
import { CompactFormFooterComponent } from './compact-form/compact-form-footer/compact-form-footer.component';
import { CompactFormHeaderComponent } from './compact-form/compact-form-header/compact-form-header.component';
import { FormLabelComponent } from './form-label/form-label.component';
import { CompactFormItemComponent } from './compact-form/compact-form-item/compact-form-item.component';
import { CompactFormCheckboxItemComponent } from './compact-form/compact-form-checkbox-item/compact-form-checkbox-item.component';
import { CompactFormBodyComponent } from './compact-form/compact-form-body/compact-form-body.component';
import { FormRowDirective } from './form-row.directive';
import { MatDividerModule } from '@angular/material/divider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CompactFormSpacerComponent } from './compact-form/compact-form-spacer/compact-form-spacer.component';
import { FormInputComponent } from './form-input/form-input.component';

@NgModule({
  declarations: [
    CompactFormComponent,
    CompactFormHeaderComponent,
    CompactFormBodyComponent,
    CompactFormFooterComponent,
    FormLabelComponent,
    CompactFormItemComponent,
    CompactFormCheckboxItemComponent,
    FormRowDirective,
    CompactFormSpacerComponent,
    FormInputComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    NgbModule,
  ],
  exports: [
    CompactFormComponent,
    CompactFormHeaderComponent,
    CompactFormBodyComponent,
    CompactFormFooterComponent,
    FormLabelComponent,
    CompactFormItemComponent,
    CompactFormCheckboxItemComponent,
    FormRowDirective,
    CompactFormSpacerComponent,
    FormInputComponent,
  ],
})
export class CAFormsModule {}
