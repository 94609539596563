import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';

export class CARouteReuseStrategy implements RouteReuseStrategy {
  private storedRoutes = new Map<string, DetachedRouteHandle>();
  private routeLeftFrom: string;

  private getFullPath(routes: ActivatedRouteSnapshot[]): string {
    const paths: string[] = [];

    routes.forEach((route) => {
      if (route.routeConfig && route.routeConfig.path.length > 0) {
        paths.push(route.routeConfig.path);
      }
    });

    return paths.join('/');
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // console.debug('CustomReuseStrategy:shouldDetach', route);
    this.routeLeftFrom = this.getFullPath(route.pathFromRoot);
    return route.data.shouldReuseRoute || false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    let key = route.url.join('/') || route.parent.url.join('/');

    if (key === '') {
      key = route.data.storageKey + '-empty';
    }

    this.storedRoutes.set(key, handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    let key = route.url.join('/') || route.parent.url.join('/');

    if (key === '') {
      key = route.data.storageKey + '-empty';
    }

    const wasRoutePreviouslyDetached = !!this.storedRoutes.get(key);
    if (wasRoutePreviouslyDetached && Array.isArray(route.data.reuseRoutesFrom)) {
      const reuseRouteFromVerified = route.data.reuseRoutesFrom.indexOf(this.routeLeftFrom) > -1;

      if (reuseRouteFromVerified) {
        return true;
      }
    }
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    let key = route.url.join('/') || route.parent.url.join('/');

    if (key === '') {
      key = route.data.storageKey + '-empty';
    }

    return route.data.shouldReuseRoute ? this.storedRoutes.get(key) : null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
