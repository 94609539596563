export class SectionChange {
    static readonly type = '[AdministrationModule] SectionChange';

    constructor(public section: string) {}
}

export class TitleChange {
    static readonly type = '[AdministrationModule] TitleChange';

    constructor(public title: string) {}
}
