export class SplitConfig {
  static DEFAULT: SplitConfig = {
    direction: 'horizontal',
    unit: 'pixel',
    useTransition: false,
    gutterSize: 3,
  };

  static PercentConfig: SplitConfig = {
    direction: 'horizontal',
    unit: 'percent',
    useTransition: false,
    gutterSize: 3,
  };

  unit: string;
  direction: string;
  useTransition: boolean;
  gutterSize: number;
}
