import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.MixedLanguageType',
})
export class MixedLanguageType {
  @GenericLookup({
    code: 'Conversation.MixedLanguageType.SixLanguagesMulti',
  })
  static sixLanguagesMulti: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.FourLanguagesMulti',
  })
  static fourLanguagesMulti: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.DutchFrench',
  })
  static dutchFrench: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.EnglishArabic',
  })
  static englishArabic: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.MixedModel4',
  })
  static mixedModel4: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.MixedModel5',
  })
  static mixedModel5: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.MixedModel6',
  })
  static mixedModel6: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.MENA',
  })
  static MENA: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.NorthAmerica',
  })
  static northAmerica: number;
}
