<div
  [style.minWidth]="fillParent ? '100%' : width + 'px'"
  [style.minHeight]="playerHeight + 'px'"
  style="position: relative"
  #mainContainer>
  <ca-player-timeline-category-layer
    [ngClass]="categoryLayerClass"
    (categoryMarkerClick)="onCatergoryMarkerClick($event)"
    *ngIf="!hideLabels"
    [styles]="!hideChannelIcons ? 'margin-left: 32px;' : ''"
    #primaryCategoryLayer></ca-player-timeline-category-layer>
  <ca-player-timeline-sentiment-layer
    (click)="onSentimentLayerClick($event)"
    [ngClass]="sentimentClass"
    [style]="'top: 32px; ' + (!hideChannelIcons ? 'left: 32px' : '')"
    #primarySentimentLayer></ca-player-timeline-sentiment-layer>
  <ca-player-timeline-sentiment-layer
    (click)="onSentimentLayerClick($event)"
    [ngClass]="sentimentClass"
    [style]="'top: 67px;' + (!hideChannelIcons ? 'left: 32px' : '')"
    #secondarySentimentLayer></ca-player-timeline-sentiment-layer>
  <div id="wrapper" style="display: flex; height: 70px">
    <div
      class="icon-row"
      style="display: inline-block; vertical-align: top; left: 0; top: 0; width: 1.5%"
      [hidden]="blocked"
      *ngIf="!hideChannelIcons">
      <div
        [hidden]="hideAgent"
        class="{{ isAgentChannelRight ? 'wave-icon-container' : 'wave-icon-container' }}">
        <div class="wave-icon">
          <i
            class=" fas fa-{{
              isAgentChannelRight ? 'headphones text-brand' : 'user text-secondary'
            }}"></i>
        </div>
      </div>
      <div class="{{ !isAgentChannelRight ? 'wave-icon-container' : 'wave-icon-container ' }}">
        <div class="wave-icon">
          <i
            class="fas fa-{{
              !isAgentChannelRight ? 'headphones text-brand' : 'user text-secondary'
            }}"></i>
        </div>
      </div>
    </div>
    <div class="mask-container" *ngIf="blocked">
      <div
        class="alert alert-warning"
        *ngIf="status === playerStatus.Initialized && expanded"
        style="width: 100%; margin: auto">
        <i class="fas fa-exclamation-circle"></i>
        <span class="ms-1">{{ 'Conversation::NoRecordToPlay' | abpLocalization }}</span>
      </div>
      <button
        *ngIf="status === playerStatus.ContentSet && expanded"
        class="btn btn-primary btn-xl btn-circle"
        (click)="onPlayButtonClick($event)">
        <i class="fas fa-play play-icon"></i>
      </button>
      <div
        *ngIf="status === playerStatus.LoadingContent"
        class="progress"
        style="width: 100%; margin: auto">
        <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 100%">
          {{ 'Conversation::LoadingRecord' | abpLocalization }}...
        </div>
      </div>
      <div
        *ngIf="status === playerStatus.DrawingWaveform"
        class="progress"
        style="width: 100%; margin: auto">
        <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 100%">
          {{ 'Conversation::DrawingWaveform' | abpLocalization }}
        </div>
      </div>
      <div
        *ngIf="status === playerStatus.Error"
        class="progress"
        style="
          width: 100%;
          height: auto;
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
        ">
        <div class="alert alert-danger" role="alert" style="margin-bottom: 0px">
          <span
            >{{ errorMessage }} {{ 'CA::ClickToRetryPartI' | abpLocalization }}
            <a style="cursor: pointer" (click)="onTryAgainLinkClicked($event)">{{
              'CA::ClickToRetryPartII' | abpLocalization
            }}</a>
            {{ 'CA::ClickToRetryPartIII' | abpLocalization }}</span
          >
        </div>
      </div>
    </div>
    <div
      #miniPlayerWrapper
      class="mini-player-wrapper"
      [hidden]="expanded"
      (click)="onMiniPlayerClick($event)">
      <mat-progress-bar
        [value]="miniPlayerValue"
        class="mini-player-bar"
        (animationEnd)="onMiniPlayerAnimationEnd($event)">
      </mat-progress-bar>
    </div>
    <div
      #expandedPlayerWrapper
      [hidden]="!expanded"
      [ngClass]="'expanded-player-wrapper ' + (hideChannelIcons ? '' : 'with-channel-icons')"></div>
  </div>
  <ca-player-timeline-category-layer
    [ngClass]="categoryLayerClass"
    (categoryMarkerClick)="onCatergoryMarkerClick($event)"
    [styles]="!hideChannelIcons ? 'margin-left: 32px;' : ''"
    *ngIf="!hideLabels"
    #secondaryCategoryLayer>
  </ca-player-timeline-category-layer>
  <ca-player-timeline-conversationmark-layer
    #conversationMarkLayer
    [ngClass]="conversationMarkClass"
    [markerInfo]="markInfo"
    [styles]="!hideChannelIcons ? 'margin-left: 32px;' : ''"
    (markerClick)="onConversationMarkClick($event)"
    [hidden]="getLayerHiddenStatus(conversationMarks)"
    *ngIf="!hideLabels">
  </ca-player-timeline-conversationmark-layer>
</div>
