export class ObjectHelper {
  static deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj)) as T;
  }

  static isEqual(firstObject: any, secondObject): boolean {
    if (
      JSON.stringify(this.deepSort(firstObject)) === JSON.stringify(this.deepSort(secondObject))
    ) {
      return true;
    } else {
      return false;
    }
  }

  static deepSort(unsortedObj: any) {
    if (unsortedObj != null) {
      let sorted = Object.keys(unsortedObj)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unsortedObj[key];
          if (typeof obj[key] === 'object') {
            obj[key] = this.deepSort(obj[key]);
          } else if (Array.isArray(obj[key])) {
            obj[key].forEach(element => {
              element = this.deepSort(element);
            });
          }
          return obj;
        }, {});
      return sorted;
    }
  }

  static isObjectKeysEqual(object1, object2) {
    const keys1 = Object.keys(object1).sort();
    const keys2 = Object.keys(object2).sort();
    return this.isEqual(keys1, keys2);
  }
}
