import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { BooleanSelectionConfigurationModel } from '../models/boolean-selection-configuration.model';

@Component({
  selector: 'ca-boolean-selection-panel',
  templateUrl: './boolean-selection-panel.component.html',
  styleUrls: ['./boolean-selection-panel.component.scss'],
})
export class BooleanSelectionPanelComponent extends FilterPanelEditorComponent implements OnInit {
  actualConfig: BooleanSelectionConfigurationModel;

  _value: any;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as BooleanSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  onSelectChange(eventArgs) {
    const val = eventArgs;

    this.value = val;

    this.onChange();
  }

  constructor(protected fb: FormBuilder) {
    super(fb);

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  init() {
    super.init();
  }
 
  autoSelectFirstItem(): void {}

  ngOnInit(): void {}

  reload(): void {}

  reset(): void {}
}
