<ca-clickable-link
  (linkClicked)="onClick()"
  [innerHtml]="
    'User::IdleUsersCountMessage'
      | abpLocalization : idleUserCount
  "
  [targetLink]="['/identity/users']"
  [params]="{
    lastLoginDate: 1
  }">
</ca-clickable-link>
