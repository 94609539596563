import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../shared/dto-has-api.model';

@Api('api/app/conversation/{id}/comment')
export class ConversationCommentInputDto extends DtoHasApi {
  comment: string;
  conversationId: number;
  startTime?: string;
  endTime?: string;
}
