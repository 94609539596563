<form #deleteConversationForm="ngForm" id="form" [formGroup]="form" (ngSubmit)="(true)">
  <div class="form-panel-wrapper">
    <div class="form-group">
      <label for="dateSelector" class="col-form-label col-sm-3 form-label"
        >{{ '::DateRange' | abpLocalization }}
      </label>
      <div class="col-sm-6">
        <ca-date-selection-panel
          #dateSelector
          [config]="dateSelectionConfiguration"></ca-date-selection-panel>
      </div>
    </div>
    <div class="form-group pt-2">
      <label for="filterType" class="col-form-label col-sm-6 form-label">
        {{ 'QualityManagement::ConversationSelection' | abpLocalization }}
      </label>
      <div class="form-group" style="display: flex">
        <div class="col-sm-6">
          <ca-select
            formControlName="filterType"
            (change)="onFilterTypeChange($event)"
            [appendTo]="'ngb-modal-window'"
            ><ca-option [value]="-1">{{ '::All' | abpLocalization }}</ca-option>
            <ca-option *ngFor="let filterType of filterTypes" [value]="filterType">
              {{ 'Conversation::' + filterType | abpLocalization }}
            </ca-option></ca-select
          >
        </div>
        <div class="col-sm-6" [hidden]="form.controls.filterType.value !== 'UniqueCustomerKey'">
          <input
            type="text"
            class="form-control form-control-sm"
            id="uniqueCustomerKey"
            placeholder="{{ 'Conversation::UniqueCustomerKey' | abpLocalization }}"
            formControlName="uniqueCustomerKey" />
        </div>
        <div class="col-sm-6" [hidden]="form.controls.filterType.value !== 'CalledOrCallerNumber'">
          <input
            type="text"
            class="form-control form-control-sm"
            id="calledOrCallerNumber"
            placeholder="{{ 'Conversation::CalledOrCallerNumber' | abpLocalization }}"
            formControlName="calledOrCallerNumber" />
        </div>
      </div>
    </div>
  </div>
</form>
