import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, merge, Observable, of, OperatorFunction, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { PhraseSuggestionRequestDto } from 'src/core/models/phrase-suggestion/phrase-suggestion-request.dto';
import { PhraseSuggestionResultDto } from 'src/core/models/phrase-suggestion/phrase-suggestion-result.dto';
import { PhraseSuggestionService } from 'src/core/services/phrase-suggestion/phrase-suggestion.service';
import { PhraseSuggestionSelectorItemModel } from '../../models/phrase-suggestion-selector-item.model';

@Component({
  selector: 'ca-phrase-suggestion-dropdown-selector',
  templateUrl: './phrase-suggestion-dropdown-selector.component.html',
  styleUrls: ['./phrase-suggestion-dropdown-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhraseSuggestionDropdownSelectorComponent),
    },
  ],
})
export class PhraseSuggestionDropdownSelectorComponent implements OnInit, ControlValueAccessor {
  @ViewChild('inputField', { static: true })
  inputField: ElementRef;

  @Input()
  inputPhrases$: BehaviorSubject<string[]>;

  @Input()
  placeholder: string;

  @Input()
  class: string;

  @Input()
  set languageCode(value: string) {
    this._languageCode = value;
  }

  get languageCode(): string {
    return this._languageCode;
  }

  @Output()
  phraseRequest = new EventEmitter();

  private _languageCode: string;

  private get text(): string {
    return this.inputField.nativeElement.value;
  }

  private set text(value: string) {
    this.inputField.nativeElement.value = value;
  }

  private get isNextPhraseSuggestionValid(): boolean {
    return (
      this.text.length > 0 &&
      this.text[this.text.length - 1] == ' ' &&
      this.text.split(' ').length > 0
    );
  }

  phrase$ = new Subject<string>();
  loading: boolean = false;

  formatter = (p: { phrase: string }) =>
    this.isNextPhraseSuggestionValid ? this.text + p.phrase : p.phrase;

  search: OperatorFunction<string, readonly PhraseSuggestionSelectorItemModel[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputPhrase$ = this.phrase$;

    return merge(debouncedText$, inputPhrase$).pipe(
      tap(() => (this.loading = true)),
      switchMap(term =>
        term.length > 0
          ? this.isNextPhraseSuggestionValid
            ? this.getNextPhraseSuggestionObservable(term)
            : of([])
          : this.getPhraseSuggestionObservable()
      ),
      tap(() => (this.loading = false))
    );
  };

  constructor(private phraseSuggestionService: PhraseSuggestionService) {}

  ngOnInit(): void {}

  loadData(inputPhrases: string[]): Observable<PhraseSuggestionResultDto[]> {
    var phrases = {
      phrases: inputPhrases,
      count: 5,
      languageCode: this.languageCode,
    } as PhraseSuggestionRequestDto;
    return this.phraseSuggestionService.getPhraseSuggestions(phrases);
  }

  loadNextPhraseData(inputPhrases: string[]): Observable<PhraseSuggestionResultDto[]> {
    var phrases = {
      phrases: inputPhrases,
      count: 5,
      languageCode: this.languageCode,
    } as PhraseSuggestionRequestDto;
    return this.phraseSuggestionService.getNextPhraseSuggestions(phrases);
  }

  onInputChange() {
    this.onChange(this.text);
    if (this.isNextPhraseSuggestionValid) {
      this.phrase$.next(this.text);
    }
  }

  onInputFocus() {
    this.phraseRequest.emit();
    if (this.text.length <= 0) {
      this.phrase$.next(this.text);
    }
  }

  onInputBlur() {
    this.onChange(this.text);
    this.onTouched();
    this.phraseRequest.emit();
    this.loading = false;
  }

  writeValue(obj: any): void {
    this.text = obj.toString();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(value: any) {}

  onTouched() {}

  private getInputPhrases(): string[] {
    return this.inputPhrases$.getValue();
  }

  private getPhraseSuggestionObservable(): Observable<{ phrase: string; index: number }[]> {
    return this.loadData(this.getInputPhrases()).pipe(
      map(response => {
        return response.length > 0
          ? [
              { phrase: '', index: -2 },
              ...response.map((p, index) => {
                return {
                  phrase: p.phrase,
                  index: index,
                };
              }),
            ]
          : [{ phrase: '', index: -1 }];
      }),
      tap(() => (this.loading = false)),
      catchError(() => {
        this.loading = false;
        return of([]);
      })
    );
  }

  private getNextPhraseSuggestionObservable(
    phrase: string
  ): Observable<{ phrase: string; index: number }[]> {
    var word = [];

    var words = phrase.split(' ').filter(w => w && w != '');
    if (words.length > 0) {
      word = [words[words.length - 1].trim()];
    }

    return word.length > 0
      ? this.loadNextPhraseData(word).pipe(
          map(response => {
            return response.length > 0
              ? [
                  { phrase: '', index: -2 },
                  ...response.map((p, index) => {
                    return {
                      phrase: p.phrase,
                      index: index,
                    };
                  }),
                ]
              : [{ phrase: '', index: -1 }];
          }),
          tap(() => (this.loading = false)),
          catchError(() => {
            this.loading = false;
            return of([]);
          })
        )
      : of([]);
  }
}
