export class ConversationImportFileModel {
  id: number
  file: File;
  isValid: boolean;
  invalidMessage: string;

  constructor(id: number, file: File, isValid: boolean, invalidMessage: string) {
    this.id = id;
    this.file = file;
    this.isValid = isValid;
    this.invalidMessage = invalidMessage;
  }
}