import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { GlobalSettingsService } from '../services/settings/global-settings.service';

@Pipe({ name: 'caDatePipe' })
export class CADatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private globalSettingsService: GlobalSettingsService) {}

  transform(
    value: any,
    dateType: DateDisplayType = DateDisplayType.DateTime,
    normalizeTimeZone: boolean = false
  ): any {
    if (value !== null) {
      const date = new Date(value);

      var timeZone = normalizeTimeZone ? this.globalSettingsService.systemTimeZone : '';

      const dateString = this.datePipe.transform(
        date,
        this.globalSettingsService.systemDateFormat,
        timeZone
      );
      const timeString = this.datePipe.transform(date, 'HH:mm:ss', timeZone);
      const timeStringWithoutSeconds = this.datePipe.transform(date, 'HH:mm', timeZone);
      const monthString  = this.datePipe.transform(date, 'MMM', timeZone);

      if (dateType === DateDisplayType.DateTime) {
        return dateString + ' ' + timeString;
      } else if (dateType === DateDisplayType.DateTimeWithoutSeconds) {
        return dateString + ' ' + timeStringWithoutSeconds;
      } else if (dateType === DateDisplayType.Time) {
        return timeString;
      } else if (dateType === DateDisplayType.TimeWithoutSeconds) {
        return timeStringWithoutSeconds;
      } else if (dateType === DateDisplayType.Date) {
        return dateString;
      } else if (dateType === DateDisplayType.Month) {
        return monthString ;
      }
    } else {
      return '';
    }
  }
}
