import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.MixedList.ViewOption'
})
export class ConversationMixedListViewOption {
    @GenericLookup({
        code: 'Conversation.MixedList.ViewOption.Standard'
    })
    static standard: number;

    @GenericLookup({
        code: 'Conversation.MixedList.ViewOption.Card'
    })
    static card: number;
}
