import { LayoutConfig } from 'src/core/models/split/layout-config.model';

export class LayoutChange {
    static readonly type = '[Application] LayoutChange';

    constructor(public name: string, public layout: LayoutConfig) {}
}

export class LayoutReset {
    static readonly type = '[Application] LayoutReset';

    constructor(public name: string) {}
}
