import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'AITopic.ClassificationService',
})
export class AITopicClassificationServiceOption {
    @GenericLookup({
        code: 'AITopic.ClassificationService.Sestek',
    })
    static sestek: number;

    @GenericLookup({
        code: 'AITopic.ClassificationService.OpenAI',
    })
    static openAI: number;
}