<div class="row">
  <div class="col-md-12 float-left">
    {{ '::CurrentCAVersion' | abpLocalization }} : {{ versionInfo.version }} ({{
      versionInfo.updateDate
    }})
  </div>
  <div class="col-md-12 float-left">
    {{ '::CurrentUIVersion' | abpLocalization }} : {{ versionInfo.uiVersion }} ({{
      versionInfo.uiUpdateDate
    }})
  </div>
</div>
