import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { ConversationSideSelectionConfigurationModel } from '../models/conversation-side-selection-configuration.model';

@Component({
  selector: 'ca-conversation-side-selection-panel',
  templateUrl: './conversation-side-selection-panel.component.html',
  styleUrls: ['./conversation-side-selection-panel.component.scss'],
})
export class ConversationSideSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit
{
  reload(): void {}
  reset(): void {
    this.value = ConversationSide.any;
  }
  actualConfig: ConversationSideSelectionConfigurationModel;
  _value: ConversationSide = ConversationSide.any;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as ConversationSideSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  constructor(protected fb: FormBuilder) {
    super(fb);

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }
  init() {
    super.init();
  }
  ngOnInit(): void {}
  
  autoSelectFirstItem(): void {}
}
