import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AttachedDataLookupDto } from 'src/core/models/administration/attached-data/attached-data-lookup.dto';
import { BaseDropdownSelectorComponent } from 'src/ui/base-selector/base-selector.module';
import { AttachedDataService } from '../../services/attached-data.service';
import { take } from 'rxjs';

@Component({
  selector: 'ca-attached-data-value-input',
  templateUrl: './attached-data-value-input.component.html',
  styleUrls: ['./attached-data-value-input.component.scss'],
  host: {
    class: 'ca-selector',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AttachedDataValueInputComponent),
    },
  ],
})
export class AttachedDataValueInputComponent
  extends BaseDropdownSelectorComponent<AttachedDataLookupDto>
  implements OnInit, OnChanges, AfterViewInit, OnDestroy, ControlValueAccessor
{
  @Input()
  attachedDataId: number;

  @Input()
  isAttachedDataMultipleChoice: boolean;

  @Input()
  autoHeight: boolean = true;

  constructor(
    protected elementRef: ElementRef,
    protected ngZone: NgZone,
    private service: AttachedDataService
  ) {
    super(elementRef, ngZone);

    this.setAutoHeight();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const newValue = this.multiple ? [] : null;
    this.isDisabled = this.attachedDataId == null;
    if (changes.attachedDataId?.previousValue) {
      this.writeValue(newValue);
      this.onChange(newValue);
    }

    this.load();
    this.setAutoHeight();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected load(): void {
    if (!this.isAttachedDataMultipleChoice || !this.attachedDataId) {
      return;
    }

    this.loading = true;

    this.service
      .getAttachedDataLookups(this.attachedDataId)
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.items = response;
        },
        error: () => {},
        complete: () => {
          this.loading = false;
        },
      });
  }

  private setAutoHeight(): void {
    if (this.autoHeight && !this.isAttachedDataMultipleChoice) {
      const idx = this.classListArray.indexOf('auto-height');

      if (idx < 0) {
        this.classListArray.push('auto-height');
      }
    } else {
      this.classListArray = this.classListArray.filter(x => x !== 'auto-height');
    }
  }
}
