import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';

@Component({
  selector: 'ca-external-login-settings',
  templateUrl: './external-login-settings.component.html',
  styleUrls: ['./external-login-settings.component.scss'],
})
export class ExternalLoginSettingsComponent implements OnInit {
  externalLoginSettingsForm: FormGroup;
  externalLoginSettingDtos: ConfigurationSettingsDto[] = [];

  private readonly settingKeySamlLoginEndPoint = 'ExternalLogin.SamlLoginEndPoint';
  private readonly settingKeySamlLoginEnabled = 'ExternalLogin.SamlLoginEnabled';
  private readonly settingKeySamlDefaultRedirectEndPoint =
    'ExternalLogin.SamlDefaultRedirectEndPoint';

  constructor(
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private fb: FormBuilder,
    private localizationService: LocalizationService
  ) {
    this.externalLoginSettingsForm = fb.group({
      samlLoginEndPoint: [this.config.getSetting(this.settingKeySamlLoginEndPoint)],
      samlLoginEnabled: [
        this.config.getSetting(this.settingKeySamlLoginEnabled) === 'true' ? true : false,
      ],
      samlDefaultRedirectEndPoint: [
        this.config.getSetting(this.settingKeySamlDefaultRedirectEndPoint),
      ],
    });
  }

  ngOnInit(): void {}

  saveExternalLoginSettings() {
    this.externalLoginSettingDtos.push({
      settingName: this.settingKeySamlLoginEndPoint,
      settingValue: this.externalLoginSettingsForm.get('samlLoginEndPoint').value.toString(),
    });

    this.externalLoginSettingDtos.push({
      settingName: this.settingKeySamlLoginEnabled,
      settingValue: this.externalLoginSettingsForm.get('samlLoginEnabled').value.toString(),
    });

    this.externalLoginSettingDtos.push({
      settingName: this.settingKeySamlDefaultRedirectEndPoint,
      settingValue: this.externalLoginSettingsForm
        .get('samlDefaultRedirectEndPoint')
        .value.toString(),
    });

    this.settingService.saveSetting(this.externalLoginSettingDtos).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
