import { WindowPosition } from 'src/core/models/window/window-position.model';
import { WindowSize } from 'src/core/models/window/window-size.model';
export class VideoPlayerRequested {
  static readonly type = '[VideoPlayer] VideoPlayerRequested';

  constructor(public title: string = null) {}
}

export class WindowStatusChanged {
  static readonly type = '[VideoPlayer] WindowStatusChanged';

  constructor() {}
}

export class WindowSizeChanged {
  static readonly type = '[VideoPlayer] WindowSizeChanged';

  constructor(public newSize: WindowSize) {}
}

export class WindowPositionChanged {
  static readonly type = '[VideoPlayer] WindowPositionChanged';

  constructor(public newPosition: WindowPosition) {}
}

export class InitializationRequested {
  static readonly type = '[VideoPlayer] InitializationRequested';

  constructor() {}
}
