<div
  class="d-flex"
  [ngbTooltip]="sentimentcontent"
  placement="auto"
  container="body"
  tooltipClass="sentiment-tooltip">
  <div class="truncated">
    <i
      style=" font-size: 18px; color:{{ sentimentColor }}"
      [ngClass]="getCustomerSentimentIconClass()"></i>
  </div>
  <div [hidden]="score == null" class="ms-2">
    {{ getCustomerSentimentLabel() }}
  </div>
</div>

<ng-template #sentimentcontent let-params="params">
  <div style="overflow: visible" class="line-container">
    <div class="line"></div>
    <div
      class="number-circle"
      style="font-weight: 700; left:{{ findThumbPosition() }}; border-color:{{ sentimentColor }}">
      {{ getScoreText() }}
    </div>
    <div class="labels">
      <div>
        <span style="font-weight: 700"> {{ '::Min' | abpLocalization }}:</span> -1
      </div>
      <div>
        <span style="font-weight: 700"> {{ '::Max' | abpLocalization }}:</span> 1
      </div>
    </div>
  </div>
</ng-template>
