import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ExcelWorkBook } from '../models/excel-work-book.model';

@Injectable({
  providedIn: 'root',
})
export class ClientExcelService {
  constructor() {}

  generateAndDownload(config: ExcelWorkBook) {
    const workbook = new Workbook();

    config.worksheets.forEach(worksheet => {
      const worksheetObj = workbook.addWorksheet(worksheet.title, {
        properties: worksheet.properties,
      });

      worksheet.rows.forEach(row => {
        const cellValues = row.cells.map(({ value }) => value);
        const addedExcelRow = worksheetObj.addRow(cellValues);

        if (row.mergeOptions) {
          const splittedOptions = row.mergeOptions.split(':');
          worksheetObj.mergeCells(
            splittedOptions[0] + addedExcelRow.number,
            splittedOptions[1] + addedExcelRow.number
          );
        }

        for (let i = 0; i < row.cells.length; i++) {
          const cell = row.cells[i];
          if (cell.properties != null) {
            let addedCell = addedExcelRow.getCell(i + 1);
            addedCell.style = cell.properties;
            if (cell.properties.width) {
              worksheetObj.getColumn(i + 1).width = cell.properties.width;
            }
          }
        }

        if (row.fill) {
          addedExcelRow.fill = row.fill;
        }
      });
    });

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, config.fileName);
    });
  }
}
