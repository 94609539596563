import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ca-category-settings',
  templateUrl: './category-settings.component.html',
  styleUrls: ['./category-settings.component.scss']
})
export class CategorySettingsComponent implements OnInit {
  activeTab = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
