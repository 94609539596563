<ng-select
  bindLabel="text"
  placeholder="{{ 'Conversation::SelectReason' | abpLocalization }}"
  [items]="reasonList"
  [multiple]="true"
  [disabled]="isDisabled"
  [closeOnSelect]="false"
  [clearSearchOnAdd]="true"
  (add)="addReason($event)"
  (remove)="removeReason($event)"
  (search)="search($event)"
  (clear)="clearModel()"
  (blur)="canAddNewReason ? onCloseDropDown() : ''"
  [(ngModel)]="selectedItem"
  [appendTo]="'body'">
  <ng-template ng-option-tmp let-item="item" let-item$="item$">
    <div
      #dropdownItem
      class="option-ellipsis"
      [ngbTooltip]="
        item.id !== 0
          ? item.text
          : item.text + ('QualityManagement::Reason.CreateNewReason' | abpLocalization)
      "
      [disableTooltip]="!checkOverflow(dropdownItem)">
      <input *ngIf="item.id !== 0" [checked]="item$.selected" type="checkbox" />
      <span>
        {{ item.text }}
        <span *ngIf="item.id === 0">
          {{ 'QualityManagement::Reason.CreateNewReason' | abpLocalization }}
        </span>
      </span>
    </div>
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items">
    <div class="ng-summary-list summary-list">
      <div class="ng-value">
        <span
          *ngIf="items.length > 1"
          [ngbTooltip]="items | reasonTooltipList"
          placement="left"
          container="body"
          class="ng-value-label">
          {{ items.length + ' ' + ('QualityManagement::Selected' | abpLocalization) }}
        </span>
        <span
          #labelItem
          *ngIf="items.length === 1"
          [ngbTooltip]="items[0].text"
          placement="left"
          container="body"
          class="ng-value-label label-ellipsis">
          {{ items[0].text }}
        </span>
      </div>
    </div>
  </ng-template>
</ng-select>
