<div class="w-40 m-auto">
  <div class="card mt-5">
    <div class="card-body">
      <div class="d-flex flex-row justify-content-center">
        <div class="d-flex flex-column">
          <div class="fa-3x d-flex justify-content-center">
            <i *ngIf="!isFailure && !isCompleted" class="fas fa-spinner fa-spin"></i>
            <i *ngIf="!isFailure && isCompleted" class="fas fa-check"></i>
            <i *ngIf="isFailure" class="fas fa-exclamation-triangle"></i>
          </div>
          <div class="mt-3">{{ infoLabel }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
