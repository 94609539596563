import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-playground-notification',
  templateUrl: './playground-notification.component.html',
  styleUrls: ['./playground-notification.component.scss'],
})
@NotificationType('PlaygroundNotification', PlaygroundNotificationComponent)
export class PlaygroundNotificationComponent implements OnInit {
  data: NotificationDto;

  get payload(): any {
    return JSON.parse(this.data.payload);
  }

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector
  ) {
    const parsedPayload = JSON.parse(payload);
    var toastrId = toastr.info(
      parsedPayload.message,
      parsedPayload.sender + ' has sent you a notification'
    );
    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap
      .pipe(take(1))
      .subscribe(() => this.toasterClickedHandler(notificationService, notificationId));
  }

  static toasterClickedHandler(notificationService: NotificationService, notificationId: string) {
    notificationService.markAsRead(notificationId).subscribe();
  }

  constructor() {}

  ngOnInit(): void {}
}
