<form [formGroup]="userDefinedCategorySettingsForm" (ngSubmit)="onSubmitCategorySettings()">
  <div class="row form-row">
    <h4>
      {{ 'Settings::IntervalCategorySettings' | abpLocalization }}
    </h4>
  </div>
  <div class="form-group pb-2">
    <input
      type="checkbox"
      id="seperateValueForChannelsEnabled"
      formControlName="seperateValueForChannelsEnabled"
      (change)="onSeperateValueChange($event)" />
    <label class="ps-1 fw-bold" for="seperateValueForChannelsEnabled">
      {{ 'Settings::SeperateValueForChannelsEnabled' | abpLocalization }}
    </label>
  </div>
  <div class="row form-row pb-2">
    <div class="row-container">
      <h5 class="mb-0" [hidden]="sameValueForChannels">
        {{ 'Settings::ForAgent' | abpLocalization }}
      </h5>
    </div>
    <div class="row-container">
      <div class="column-container col-3 first">
        <label for="agentBeginningWordCount" class="col-form-label pt-0 indicates-required-field">
          {{ ('Settings::OpeningInterval' | abpLocalization) + ' ' }}
        </label>
        <span class="row-container">
          <label class="col-form-label pt-0 pe-1 pb-0">
            {{ 'Settings::First' | abpLocalization }}
          </label>
          <input
            type="number"
            class="form-control form-control-sm"
            id="agentBeginningWordCount"
            formControlName="agentBeginningWordCount" />
          <label class="col-form-label pt-0 ps-1 pb-0">
            {{ 'Settings::Words' | abpLocalization }}
          </label>
        </span>
      </div>
      <div class="column-container col-3 second">
        <label for="agentEndingWordCount" class="col-form-label indicates-required-field">
          {{ ('Settings::ClosingInterval' | abpLocalization) + ' ' }}
        </label>
        <span class="row-container">
          <label class="col-form-label pt-0 pe-1 pb-0">
            {{ 'Settings::Last' | abpLocalization }}
          </label>
          <input
            type="number"
            class="form-control form-control-sm"
            id="agentEndingWordCount"
            formControlName="agentEndingWordCount" />
          <label class="col-form-label pt-0 ps-1 pb-0">
            {{ 'Settings::Words' | abpLocalization }}
          </label>
        </span>
      </div>
    </div>
    <div class="row-container" [hidden]="!sameValueForChannels">
      <small class="form-text text-muted">
        {{ 'Settings::SeperateValueForChannelsEnabledExplanation' | abpLocalization }}
      </small>
    </div>
  </div>
  <div class="row form-row" [hidden]="sameValueForChannels">
    <div class="row-container">
      <h5 class="mb-0">
        {{ 'Settings::ForCustomer' | abpLocalization }}
      </h5>
    </div>
    <div class="row-container">
      <div class="column-container col-3 first">
        <label
          for="customerBeginningWordCount"
          class="col-form-label pt-0 indicates-required-field">
          {{ ('Settings::OpeningInterval' | abpLocalization) + ' ' }}
        </label>
        <span class="row-container">
          <label class="col-form-label pt-0 pe-1 pb-0">
            {{ 'Settings::First' | abpLocalization }}
          </label>
          <input
            type="number"
            class="form-control form-control-sm"
            id="customerBeginningWordCount"
            formControlName="customerBeginningWordCount" />
          <label class="col-form-label pt-0 ps-1 pb-0">
            {{ 'Settings::Words' | abpLocalization }}
          </label>
        </span>
      </div>
      <div class="column-container col-3 second">
        <label for="customerEndingWordCount" class="col-form-label indicates-required-field">
          {{ ('Settings::ClosingInterval' | abpLocalization) + ' ' }}
        </label>
        <span class="row-container">
          <label class="col-form-label pt-0 pe-1 pb-0">
            {{ 'Settings::Last' | abpLocalization }}
          </label>
          <input
            type="number"
            class="form-control form-control-sm"
            id="customerEndingWordCount"
            formControlName="customerEndingWordCount" />
          <label class="col-form-label pt-0 ps-1 pb-0">
            {{ 'Settings::Words' | abpLocalization }}
          </label>
        </span>
      </div>
    </div>
  </div>
  <div class="form-group pb-2 pt-4">
    <input
      type="checkbox"
      id="identifyDepartmentExceptions"
      formControlName="enableIdentifyDepartmentException" />
    <label class="ps-1 fw-bold" for="identifyDepartmentExceptions">
      {{ 'Settings::EnableIdentifyDepartmentException' | abpLocalization }}
    </label>
  </div>
  <div
    formArrayName="exceptions"
    [hidden]="!userDefinedCategorySettingsForm.get('enableIdentifyDepartmentException').value"
    class="ps-3">
    <div
      *ngFor="
        let exception of userDefinedCategorySettingsForm?.controls['exceptions']?.controls;
        let i = index
      "
      [formGroupName]="i"
      class="pt-2 pb-2">
      <div class="form-group pb-2">
        <label class="fw-bold" [for]="'exception' + i">
          {{ ('Settings::Exception' | abpLocalization) + ' ' + (i + 1) }}
        </label>
        <button
          class="ca-btn ca-btn-link ms-4 p-0"
          *ngIf="userDefinedCategorySettingsForm.get('exceptions')?.value.length > 1"
          (click)="onClickDeleteException(i)"
          type="button">
          {{ '::Delete' | abpLocalization }}
        </button>
      </div>
      <div class="form-group pb-2">
        <ca-department-selector-v2
          #depSel
          formControlName="departments"
          [multipleSingleRemove]="true"
          [hideHeader]="true"
          [multiple]="true"
          [closeOnSelect]="false"
          [disabledItemIdList]="departmentIdInUseList(exception, $event)"
          placeholder="{{
            'CA::PleaseSelectWithThreeDots' | abpLocalization
          }}"></ca-department-selector-v2>
      </div>
      <div class="form-group pb-2">
        <input
          type="checkbox"
          [id]="'seperateValueForChannelsEnabled' + i"
          formControlName="seperateValueForChannelsEnabled"
          (change)="onSeperateValueForExceptionsChange($event, exception)" />
        <label class="ps-1 fw-bold" [for]="'seperateValueForChannelsEnabled' + i">
          {{ 'Settings::SeperateValueForChannelsEnabled' | abpLocalization }}
        </label>
      </div>
      <div class="row form-row pb-2">
        <div class="row-container">
          <h5 class="mb-0" [hidden]="!exception.get('seperateValueForChannelsEnabled').value">
            {{ 'Settings::ForAgent' | abpLocalization }}
          </h5>
        </div>
        <div class="row-container">
          <div class="column-container col-3 first">
            <label
              [for]="'agentBeginningWordCount' + i"
              class="col-form-label pt-0 indicates-required-field">
              {{ ('Settings::OpeningInterval' | abpLocalization) + ' ' }}
            </label>
            <span class="row-container">
              <label class="col-form-label pt-0 pe-1 pb-0">
                {{ 'Settings::First' | abpLocalization }}
              </label>
              <input
                type="number"
                class="form-control form-control-sm"
                [id]="'agentBeginningWordCount' + i"
                formControlName="agentBeginningWordCount" />
              <label class="col-form-label pt-0 ps-1 pb-0">
                {{ 'Settings::Words' | abpLocalization }}
              </label>
            </span>
          </div>
          <div class="column-container col-3 second">
            <label
              [for]="'agentEndingWordCount' + i"
              class="col-form-label indicates-required-field">
              {{ ('Settings::ClosingInterval' | abpLocalization) + ' ' }}
            </label>
            <span class="row-container">
              <label class="col-form-label pt-0 pe-1 pb-0">
                {{ 'Settings::Last' | abpLocalization }}
              </label>
              <input
                type="number"
                class="form-control form-control-sm"
                [id]="'agentEndingWordCount' + i"
                formControlName="agentEndingWordCount" />
              <label class="col-form-label pt-0 ps-1 pb-0">
                {{ 'Settings::Words' | abpLocalization }}
              </label>
            </span>
          </div>
        </div>
        <div
          class="row-container"
          [hidden]="exception.get('seperateValueForChannelsEnabled').value">
          <small class="form-text text-muted">
            {{ 'Settings::SeperateValueForChannelsEnabledExplanation' | abpLocalization }}
          </small>
        </div>
      </div>
      <div class="row form-row" [hidden]="!exception.get('seperateValueForChannelsEnabled').value">
        <div class="row-container">
          <h5 class="mb-0">
            {{ 'Settings::ForCustomer' | abpLocalization }}
          </h5>
        </div>
        <div class="row-container">
          <div class="column-container col-3 first">
            <label
              [for]="'customerBeginningWordCount' + i"
              class="col-form-label pt-0 indicates-required-field">
              {{ ('Settings::OpeningInterval' | abpLocalization) + ' ' }}
            </label>
            <span class="row-container">
              <label class="col-form-label pt-0 pe-1 pb-0">
                {{ 'Settings::First' | abpLocalization }}
              </label>
              <input
                type="number"
                class="form-control form-control-sm"
                [id]="'customerBeginningWordCount' + i"
                formControlName="customerBeginningWordCount" />
              <label class="col-form-label pt-0 ps-1 pb-0">
                {{ 'Settings::Words' | abpLocalization }}
              </label>
            </span>
          </div>
          <div class="column-container col-3 second">
            <label
              [for]="'customerEndingWordCount' + i"
              class="col-form-label indicates-required-field">
              {{ ('Settings::ClosingInterval' | abpLocalization) + ' ' }}
            </label>
            <span class="row-container">
              <label class="col-form-label pt-0 pe-1 pb-0">
                {{ 'Settings::Last' | abpLocalization }}
              </label>
              <input
                type="number"
                class="form-control form-control-sm"
                [id]="'customerEndingWordCount' + i"
                formControlName="customerEndingWordCount" />
              <label class="col-form-label pt-0 ps-1 pb-0">
                {{ 'Settings::Words' | abpLocalization }}
              </label>
            </span>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="w-100 btn ca-btn ca-btn-neutral opaque-on-hover"
      (click)="addException(null)"
      [hidden]="!userDefinedCategorySettingsForm.get('enableIdentifyDepartmentException').value">
      <i class="fa-duotone fa-section"></i>
      <span>{{ 'Settings::AddException' | abpLocalization }}</span>
    </button>
  </div>

  <button type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
