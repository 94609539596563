import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NotificationCountSummaryDto } from 'src/core/models/notification/notification-count-summary.dto';
import { NotificationState } from 'src/core/states/notification/notification.state';

@Component({
  selector: 'ca-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.scss'],
})
export class NotificationIconComponent implements OnInit {
  unreadNotificationCount = 0;
  recentNotificationCount = 0;
  totalNotificationCount = 0;

  @Select(NotificationState.getCountSummary)
  notificationCountSummary$: Observable<NotificationCountSummaryDto>;

  constructor() {}

  ngOnInit(): void {
    this.notificationCountSummary$.subscribe(c => {
      this.unreadNotificationCount = c.unread;
      this.recentNotificationCount = c.recent;
      this.totalNotificationCount = c.total;
    });
  }
}
