import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';

@Component({
  selector: 'ca-topic-training-started-notification',
  templateUrl: './topic-training-started-notification.component.html',
  styleUrls: ['./topic-training-started-notification.component.scss'],
})
@NotificationType(
  'TopicTrainingStartedNotification',
  TopicTrainingStartedNotificationComponent
)
export class TopicTrainingStartedNotificationComponent implements OnInit {
  data: NotificationDto;

  get dataCount(): string {
    return this.data ? JSON.parse(this.data.payload).dataCount : '';
  }

  get trainOption(): string {
    return this.data ? JSON.parse(this.data.payload).trainOption : '';
  }
  
  constructor() {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
  ) {
    const parsedPayload = JSON.parse(payload);
    let message = localizationService.instant(
      'AITopic::TopicTrainWithDialoguesStartedMessage',
      parsedPayload.dataCount
    );
    if (parsedPayload.trainOption == '1') {
      message = localizationService.instant(
        'AITopic::TopicTrainWithBothStartedMessage',
        parsedPayload.dataCount
      );
    } else if (parsedPayload.trainOption == '3') {
      message = localizationService.instant(
        'AITopic::TopicTrainWithCategoriesStartedMessage'
      );
    }
    toastr.success(message);
  }
}
