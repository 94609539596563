<div class="form-footer-content" [class]="usePadding ? 'ps-3 pe-3' : ''">
  <div class="left-aligned-container"></div>
  <div class="right-aligned-container">
    <div class="toolbar-item" *ngIf="processing">
      <i class="far fa-spinner fa-spin"></i>
      <span>{{ processingMessage }}</span>
    </div>
    <!-- DELETE BUTTON -->
    <div
      class="toolbar-item"
      *ngIf="buttonConfig.delete.visible"
      [ngbTooltip]="buttonConfig.delete.tooltip"
      [disableTooltip]="
        !(buttonConfig.delete.tooltip && buttonConfig.delete.tooltip.trim().length > 0)
      "
      container="body">
      <button
        type="button"
        class="btn ca-btn ca-btn-neutral"
        [disabled]="processing || buttonConfig.delete.disabled"
        (click)="onClickDeleteButton($event)">
        <span>{{ buttonConfig.delete.name }}</span>
      </button>
    </div>
    <!-- DELETE BUTTON -->
    <!-- DIVIDER -->
    <div class="toolbar-item" *ngIf="buttonConfig.delete.visible">
      <mat-divider [vertical]="true" class="menu-divider"></mat-divider>
    </div>
    <!-- DIVIDER -->
    <!-- CANCEL BUTTON -->
    <div
      class="toolbar-item"
      *ngIf="buttonConfig.cancel.visible"
      [ngbTooltip]="buttonConfig.cancel.tooltip"
      [disableTooltip]="
        !(buttonConfig.cancel.tooltip && buttonConfig.cancel.tooltip.trim().length > 0)
      "
      container="body">
      <button
        type="button"
        class="btn ca-btn ca-btn-neutral"
        [disabled]="processing || buttonConfig.cancel.disabled"
        (click)="onClickCancelButton($event)">
        <span>{{ buttonConfig.cancel.name }}</span>
      </button>
    </div>
    <!-- CANCEL BUTTON -->
    <!-- PREVIOUS BUTTON -->
    <div
      class="toolbar-item"
      *ngIf="buttonConfig.previous.visible"
      [ngbTooltip]="buttonConfig.previous.tooltip"
      [disableTooltip]="
        !(buttonConfig.previous.tooltip && buttonConfig.previous.tooltip.trim().length > 0)
      "
      container="body">
      <button
        type="button"
        class="btn ca-btn ca-btn-neutral"
        [disabled]="processing || buttonConfig.previous.disabled"
        (click)="onClickPreviousButton($event)">
        <i *ngIf="!buttonConfig.previous.hideIcon" class="fas fa-angle-left"></i>
        <span>{{ buttonConfig.previous.name }}</span>
      </button>
    </div>
    <!-- PREVIOUS BUTTON -->
    <!-- NEXT BUTTON -->
    <div
      class="toolbar-item"
      *ngIf="buttonConfig.next.visible"
      [ngbTooltip]="buttonConfig.next.tooltip"
      [disableTooltip]="!(buttonConfig.next.tooltip && buttonConfig.next.tooltip.trim().length > 0)"
      container="body">
      <button
        type="button"
        class="btn ca-btn ca-btn-neutral"
        [disabled]="processing || buttonConfig.next.disabled"
        (click)="onClickNextButton($event)">
        <i *ngIf="!buttonConfig.next.hideIcon" class="fas fa-angle-right"></i>
        <span>{{ buttonConfig.next.name }}</span>
      </button>
    </div>
    <!-- NEXT BUTTON -->
    <!-- SAVE BUTTON -->
    <div
      class="toolbar-item"
      *ngIf="buttonConfig.save.visible"
      [ngbTooltip]="buttonConfig.save.tooltip"
      [disableTooltip]="!(buttonConfig.save.tooltip && buttonConfig.save.tooltip.trim().length > 0)"
      container="body">
      <button
        class="btn ca-btn ca-btn-primary"
        type="submit"
        [disabled]="processing || buttonConfig.save.disabled">
        <i *ngIf="!buttonConfig.save.hideIcon" class="far fa-save"></i>
        <span>{{ buttonConfig.save.name }}</span>
      </button>
    </div>
    <!-- SAVE BUTTON -->
  </div>
</div>
