<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <h4>
    {{ 'AITopic::GeneralSettings' | abpLocalization }}
    <i
      class="ms-2 fas fa-info-circle"
      ngbTooltip="{{ 'AITopic::ConversationCountPopover' | abpLocalization }}"
      container="body"></i>
  </h4>
  <div class="form-group" formGroupName="apiAddress">
    <label for="apiAddress">
      {{ 'AITopic::ApiAddress' | abpLocalization }}
    </label>
    <i
      class="ms-2 fas fa-info-circle"
      ngbTooltip="{{ 'AITopic::ApiAddressPopover' | abpLocalization }}"
      container="body"></i>
    <input
      type="text"
      class="form-control form-control-sm"
      id="apiAddress"
      formControlName="value" />
  </div>
  <div class="form-group mt-3" formGroupName="identityBaseUrl">
    <label for="identityBaseUrl">
      {{ 'AITopic::IdentityBaseUrl' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="identityBaseUrl"
      formControlName="value" />
  </div>
  <div class="form-group mt-3" style="display: flex">
    <input
      id="serviceAddressRadio"
      type="radio"
      value="serviceAddress"
      (change)="onRadioChange($event.currentTarget.value)"
      [checked]="publishedAddressRadio == 'serviceAddress'"
      class="radio-input" />
    <label for="serviceAddressRadio" class="radio-label">
      {{ 'AITopic::UseServiceAddress' | abpLocalization }}
    </label>
    <br />
    <input
      id="clusterAddressRadio"
      type="radio"
      value="clusterAddress"
      (change)="onRadioChange($event.currentTarget.value)"
      [checked]="publishedAddressRadio == 'clusterAddress'"
      class="radio-input"
      style="margin-left: 10px" />
    <label for="clusterAddressRadio" class="radio-label">
      {{ 'AITopic::UseClusteringAddress' | abpLocalization }}
    </label>
  </div>
  <div
    class="form-group"
    formGroupName="clusteringAddress"
    [hidden]="publishedAddressRadio != 'clusterAddress'">
    <input
      type="text"
      class="form-control form-control-sm"
      id="clusteringAddress"
      formControlName="value" />
  </div>
  <div class="form-group mt-3" formGroupName="language">
    <label for="language">{{ 'AITopic::Language' | abpLocalization }}</label>
    <!-- combobox denenecek-->
    <input type="text" class="form-control form-control-sm" id="language" formControlName="value" />
  </div>
  <div
    class="form-group mt-3"
    formGroupName="classificationServiceType"
    *ngIf="isGenerativeAIEnabled">
    <label for="classificationServiceType">
      {{ 'AITopic::ClassificationService' | abpLocalization }}</label
    >
    <ca-select id="classificationServiceType" formControlName="value" [appendTo]="''"
      ><ca-option *ngFor="let type of classificationServiceOptions" [value]="type.code">
        {{ 'GenericLookup::' + type.code | abpLocalization }}
      </ca-option></ca-select
    >
  </div>
  <div class="form-group mt-3" formGroupName="conversationCount">
    <label for="conversationCount">{{ 'AITopic::ConversationCount' | abpLocalization }} </label>
    <input
      type="number"
      class="form-control form-control-sm"
      id="conversationCount"
      [min]="minConversationCount"
      [max]="maxConversationCount"
      formControlName="value" />
  </div>
  <div class="form-group mt-3">
    <input type="checkbox" id="categoriesAutomatically" formControlName="categoriesAutomatically" />
    <label class="ps-1" for="categoriesAutomatically">
      {{ 'AITopic::CategoriesAutomatically' | abpLocalization }}
    </label>
  </div>
  <div
    class="form-group mt-3"
    formGroupName="clusterCount"
    *ngIf="form.get('categoriesAutomatically').value == false">
    <label for="clusterCount">{{ 'AITopic::ClusterCount' | abpLocalization }}</label>
    <i
      class="ms-2 fas fa-info-circle"
      ngbTooltip="{{ 'AITopic::NumberOfAITopicInfo' | abpLocalization }}"
      container="body"></i>
    <input
      type="number"
      class="form-control form-control-sm"
      id="clusterCount"
      formControlName="value" />
  </div>
  <div *ngIf="isSuperAdminUser">
    <br />
    <hr />
    <h4>{{ 'AITopic::InferenceSettings' | abpLocalization }}</h4>

    <div class="form-group mt-3" formGroupName="serviceStartDateTypeId">
      <label for="serviceStartDateTypeId">{{ 'AITopic::StartDate' | abpLocalization }}</label>
      <i
        class="ms-2 fas fa-info-circle"
        ngbTooltip="{{ 'AITopic::StartDateInfo' | abpLocalization : startDateInfo }}"
        container="body"></i>
      <ca-select id="serviceStartDateTypeId" formControlName="value"
        ><ca-option *ngFor="let type of inferenceSettingOptions" [value]="type.code">
          {{ 'GenericLookup::' + type.code | abpLocalization }}
        </ca-option></ca-select
      >
    </div>
  </div>
  <div>
    <button type="button" type="submit" class="btn btn-primary mt-3" [disabled]="!form.valid">
      <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
    </button>
  </div>

  <div>
    <button type="button" class="btn btn-primary mt-3 mb-3" (click)="resetSetting()">
      <i class="me-1 fa fa-undo"></i>{{ 'AITopic::ResetDefaultValue' | abpLocalization }}
    </button>
  </div>
</form>
