import { EntityDto } from '../shared/entity.dto';

export class ConversationNerWithRangeQueryItemDto extends EntityDto {
  operator: number;
  categoryId: number;
  categoryItemId: number;
  field: number;
  internalId: number;
  parentInternalId: number;
  queryId: number;
  sideId: number;
  sideCode: string;
  nerId: number;
  term: string;
  rangeUnitId: number;
  rangeUnitCode: number;
  startTime: number;
  endTime: number;
  multiple: boolean;
}
