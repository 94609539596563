import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { DataChange } from 'src/core/actions/generic-lookup-type/generic-lookup-type.actions';
import { GenericLookupTypeDto } from 'src/core/models/generic-lookup-type/generic-lookup-type.dto';
import { GenericLookupTypeStateModel } from 'src/core/models/generic-lookup-type/generic-lookup-type.state-model';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';

@State<GenericLookupTypeStateModel>({
  name: 'GenericLookupTypeState',
  defaults: {
    loaded: false,
    data: [],
  } as GenericLookupTypeStateModel,
})
@Injectable()
export class GenericLookupTypeState {
  @Selector()
  static getGenericLookupTypes(state: GenericLookupTypeStateModel): GenericLookupTypeDto[] {
    return state.data;
  }

  @Selector()
  static getGenericLookups(state: GenericLookupTypeStateModel) {
    return (genericLookupType: any): GenericLookupDto[] => {
      if (!genericLookupType) {
        return [];
      }

      let result: GenericLookupDto[];
      const code = genericLookupType.prototype.code;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < state.data.length; i++) {
        if (state.data[i].code === code) {
          result = state.data[i].genericLookups;
        }
      }

      return result;
    };
  }

  constructor() {}

  @Action(DataChange)
  changeData(ctx: StateContext<GenericLookupTypeStateModel>, action: DataChange) {
    const currentState = ctx.getState();
    currentState.data = action.payload;
    currentState.loaded = true;

    ctx.patchState(currentState);
  }
}
