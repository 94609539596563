<div *ngIf="!withoutDiv && !showTooltipIfTruncated" [ngClass]="cls ? cls : 'truncate'">
  <ng-container *ngTemplateOutlet="link"> </ng-container>
</div>
<span *ngIf="withoutDiv && !showTooltipIfTruncated" [ngClass]="cls ? cls : 'truncate'">
  <ng-container *ngTemplateOutlet="link"> </ng-container>
</span>

<div
  *ngIf="!withoutDiv && showTooltipIfTruncated"
  [ngClass]="cls ? cls : 'truncate'"
  [ngbTooltip]="innerHtml"
  showTooltipIfTruncated>
  <ng-container *ngTemplateOutlet="link"> </ng-container>
</div>
<span
  *ngIf="withoutDiv && showTooltipIfTruncated"
  [ngClass]="cls ? cls : 'truncate'"
  [ngbTooltip]="innerHtml"
  showTooltipIfTruncated>
  <ng-container *ngTemplateOutlet="link"> </ng-container>
</span>

<ng-template #link>
  <i *ngIf="icon != ''" style="margin-right: 6px" [ngClass]="icon"></i>

  <a
    [ngClass]="disabled ? 'disabled' : ''"
    [routerLink]="targetLink"
    [state]="state"
    [queryParams]="params"
    [innerHtml]="innerHtml"
    (click)="onLinkClick()"
    [target]="target"></a>
</ng-template>
