import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { LastLoginDateSelectionConfigurationModel } from '../models/last-login-date-selection-panel.model';
import { DateSelectionModel } from '../models/date-selection.model';
import { LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'ca-last-login-date-selection-panel',
  templateUrl: './last-login-date-selection-panel.component.html',
  styleUrls: ['./last-login-date-selection-panel.component.scss'],
})
export class LastLoginDateSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit
{
  actualConfig: LastLoginDateSelectionConfigurationModel;
  _value: any;
  monthList: DateSelectionModel[] = [
    {
      id: 1,
      name: this.localizationService.instant('User::OneMonthAgo'),
    },
    {
      id: 3,
      name: this.localizationService.instant('User::ThreeMonthsAgo'),
    },
    {
      id: 6,
      name: this.localizationService.instant('User::SixMonthsAgo'),
    },
    {
      id: 12,
      name: this.localizationService.instant('User::TwelveMonthsAgo'),
    },
    {
      id: 24,
      name: this.localizationService.instant('User::TwentyFourMonthsAgo'),
    },
  ];
  selectedValue: any = -1;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as LastLoginDateSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value?.id,
    });
  }

  get value(): any {
    if (this._value?.editor > 0) {
      return {
        id: this._value.editor,
        name: this.monthList.find(x => x.id == this._value.editor).name,
      } as DateSelectionModel;
    }

    if (!this.selectedValue) {
      this.selectedValue = '-1';
    }

    return null;
  }

  constructor(protected fb: FormBuilder, private localizationService: LocalizationService) {
    super(fb);

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val;
      this.onChange();
    });
  }

  ngOnInit(): void {}
  reload(): void {}
  reset(): void {}
  autoSelectFirstItem(): void {}

  init() {
    super.init();
  }
}
