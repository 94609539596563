import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-gen-ai-project-deletion-completed-notification',
  templateUrl: './gen-ai-project-deletion-completed-notification.component.html',
  styleUrls: ['./gen-ai-project-deletion-completed-notification.component.scss'],
})
@NotificationType(
  'GenAIProjectDeletionCompletedNotification',
  GenAiProjectDeletionCompletedNotificationComponent
)
export class GenAiProjectDeletionCompletedNotificationComponent implements OnInit {
  data: NotificationDto;

  get payloadData(): any {
    return JSON.parse(this.data?.payload);
  }

  constructor() {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'Analysis::GenAI.Project.DeletionCompletedNotificationMessage',
      parsedPayload.name
    );

    var toastrId = toastr.success(message);

    var toastrInstance = toasterHelperService.getToastById(toastrId);
    toastrInstance.onTap.pipe(take(1)).subscribe(() => {
      this.toasterClickedHandler(notificationService, notificationId);
    });
  }

  static toasterClickedHandler(notificationService: NotificationService, notificationId: string) {
    notificationService.markAsRead(notificationId).subscribe();
  }
}
