import {
  AuthService,
  ConfigStateService,
  LocalizationService,
  SessionStateService,
} from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DateFormatConstants } from 'src/core/constants/dateformat.constant';
import { TimeZoneConstants } from 'src/core/constants/timezone.constant';
import { CallLanguageCodeDto } from 'src/core/models/call/call-language-code.dto';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { InitialSettingsDto } from 'src/core/models/configuration-setting/initial-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { finalize } from 'rxjs/operators';
import { TimeZoneHelper } from 'src/core/services/helper/timezone-helper';

@Component({
  selector: 'ca-initial-setup',
  templateUrl: './initial-setup.component.html',
  styleUrls: ['./initial-setup.component.scss'],
})
export class InitialSetupComponent implements OnInit {
  settingsForm: FormGroup;
  initialSettingDtos: ConfigurationSettingsDto[] = [];
  languagesDataSource: CallLanguageCodeDto[];
  otherLanguagesDataSource: CallLanguageCodeDto[];
  systemStartDate: any;
  timeZones: any;
  dateFormats: string[];
  otherLanguages: string[] = [];
  isPrimaryLanguageSelected = false;
  processing = false;

  currentUser$ = this.configStateService.getOne$('currentUser');

  selectedTenant$ = this.sessionState.getTenant$();

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private configStateService: ConfigStateService,
    private sessionState: SessionStateService,
    private oAuthService: OAuthService,
    private authService: AuthService,
    private settingService: ConfigurationSettingsService,
    private timeZoneHelper: TimeZoneHelper
  ) {
    const isInitialSetupCompleted = this.configStateService.getSetting(
      'Global.IsInitialTenantSetupCompleted'
    );

    if (!this.oAuthService.hasValidAccessToken() || isInitialSetupCompleted === 'True') {
      this.router.navigate(['/']);
    }

    this.systemStartDate = new Date(new Date().getFullYear(), 0, 1);

    this.settingsForm = fb.group({
      systemLangCode: [null, Validators.required],
      otherLanguages: [null],
      systemStartDate: [null, Validators.required],
      systemTimeZone: [null, Validators.required],
      systemDateFormat: [null, Validators.required],
    });

    this.settingsForm.get('otherLanguages').disable();
    this.settingsForm.get('systemLangCode').valueChanges.subscribe(selectedValue => {
      if (selectedValue) {
        this.settingsForm.get('otherLanguages').enable();

        this.resetOtherLanguagesDataSource();
        this.removeItemFromOtherLanguagesDataSource(selectedValue);
      }
    });

    this.settingService.getLanguageCodeList().subscribe(res => {
      this.languagesDataSource = res.filter(x => !x.code.includes(';'));
      this.languagesDataSource.sort((x, y) => (x.name > y.name ? 1 : -1));
      this.resetOtherLanguagesDataSource();
    });

    this.dateFormats = DateFormatConstants.dateFormats;
    this.timeZones = TimeZoneConstants.timeZones;
  }

  removeItemFromOtherLanguagesDataSource(languageCode: string) {
    const indexToRemoveFromDataSource = this.otherLanguagesDataSource.findIndex(
      d => d.code === languageCode
    );
    if (indexToRemoveFromDataSource > -1) {
      this.otherLanguagesDataSource.splice(indexToRemoveFromDataSource, 1);
      this.otherLanguagesDataSource = [...this.otherLanguagesDataSource];
      this.removeFromSelectedOtherLanguages(languageCode);
    }
  }

  removeFromSelectedOtherLanguages(languageCode: string) {
    let selectedItems = [];
    selectedItems = this.settingsForm.get('otherLanguages').value;

    if (selectedItems) {
      const indexToRemoveFromSelected = selectedItems.findIndex(i => i === languageCode);
      if (indexToRemoveFromSelected > -1) {
        selectedItems.splice(indexToRemoveFromSelected, 1);
        selectedItems = [...selectedItems];
        this.settingsForm.get('otherLanguages').setValue(selectedItems);
      }
    }
  }

  resetOtherLanguagesDataSource() {
    this.otherLanguagesDataSource = ObjectHelper.deepCopy(this.languagesDataSource);
  }

  ngOnInit(): void { }

  navigateToLogin() {
    this.authService.navigateToLogin();
  }

  logout() {
    this.authService.logout().subscribe();
  }

  save() {
    if (this.processing || this.settingsForm.invalid) {
      return;
    }

    this.processing = true;
    this.initialSettingDtos = new Array();
    var startDate = new Date(this.systemStartDate);
    var timeZoneDifferenceMinutes = this.timeZoneHelper.GetTimeZoneOffsetInMinutes(this.settingsForm.get('systemTimeZone').value.toString());
    var ticks = (startDate.setMinutes(-1 * timeZoneDifferenceMinutes)* 10000 +621355968000000000).toString();    

    const systemLangCode = this.settingsForm.get('systemLangCode').value.toString();
    const englishLanguageCode = this.languagesDataSource.find(l => l.name == "English")?.code;

    this.initialSettingDtos.push(
      {
        settingName: 'Global.SystemLanguageCode',
        settingValue: systemLangCode,
      },
      {
        settingName: 'Global.SystemStartDate',
        settingValue: ticks,
      },
      {
        settingName: 'Global.SystemDateFormat',
        settingValue: this.settingsForm.get('systemDateFormat').value.toString().replace(/m/g, 'M'),
      },
      {
        settingName: 'Global.SystemTimeZone',
        settingValue: this.settingsForm.get('systemTimeZone').value.toString(),
      }
    );

    if (this.settingsForm.get('otherLanguages').value) {
      this.otherLanguages = this.settingsForm.get('otherLanguages').value;
      this.initialSettingDtos.push(
        {
          settingName: 'Global.OtherLanguageCode',
          settingValue: this.otherLanguages.join(';'),
        },
        {
          settingName: 'Global.QueryLanguageCodes',
          settingValue: this.otherLanguages.join(','),
        }
      );
    }

    if (systemLangCode == englishLanguageCode) {
      const defaultValue = '700';
      this.initialSettingDtos.push(
        {
          settingName: 'UserDefinedCategory.AgentBeginningWordCount',
          settingValue: defaultValue,
        },
        {
          settingName: 'UserDefinedCategory.AgentEndingWordCount',
          settingValue: defaultValue,
        },
        {
          settingName: 'UserDefinedCategory.CustomerBeginningWordCount',
          settingValue: defaultValue,
        },
        {
          settingName: 'UserDefinedCategory.CustomerEndingWordCount',
          settingValue: defaultValue,
        }
      );
    }

    var initialSettings: InitialSettingsDto = {
      configurationsSettingsDtos: this.initialSettingDtos,
      otherLanguageCodes: this.otherLanguages,
    };

    this.settingService
      .saveInitialSettings(initialSettings)
      .pipe(
        finalize(() => {
          this.processing = false;
        })
      )
      .subscribe(res => {
        this.toastr.success(
          this.localizationService.instant('Settings::InitialSetup.SetupCompleted')
        );
        this.router.navigate(['/']);
      });
  }
}
