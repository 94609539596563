import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimeZoneConstants {
  static readonly timeZones: { [name: string]: string } = {
    '-1200': 'UTC-12:00',
    '-1100': 'UTC-11:00',
    '-1000': 'UTC-10:00',
    '-0930': 'UTC-09:30',
    '-0900': 'UTC-09:00',
    '-0800': 'UTC-08:00',
    '-0700': 'UTC-07:00',
    '-0600': 'UTC-06:00',
    '-0500': 'UTC-05:00',
    '-0400': 'UTC-04:00',
    '-0330': 'UTC-03:30',
    '-0300': 'UTC-03:00',
    '-0200': 'UTC-02:00',
    '-0100': 'UTC-01:00',
    '+0000': 'UTC+00:00',
    '+0100': 'UTC+01:00',
    '+0200': 'UTC+02:00',
    '+0300': 'UTC+03:00',
    '+0330': 'UTC+03:30',
    '+0400': 'UTC+04:00',
    '+0430': 'UTC+04:30',
    '+0500': 'UTC+05:00',
    '+0530': 'UTC+05:30',
    '+0545': 'UTC+05:45',
    '+0600': 'UTC+06:00',
    '+0630': 'UTC+06:30',
    '+0700': 'UTC+07:00',
    '+0800': 'UTC+08:00',
    '+0845': 'UTC+08:45',
    '+0900': 'UTC+09:00',
    '+0930': 'UTC+09:30',
    '+1000': 'UTC+10:00',
    '+1030': 'UTC+10:30',
    '+1100': 'UTC+11:00',
    '+1200': 'UTC+12:00',
    '+1245': 'UTC+12:45',
    '+1300': 'UTC+13:00',
    '+1345': 'UTC+13:45',
    '+1400': 'UTC+14:00',
  };
}
