import { CallDto } from '../call/call.dto';
import { ChatDto } from '../chat/chat.dto';
import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from '../shared/dto-has-api.model';
import { NotSuitableForEvaluationReasonDto } from '../quality-management/not-suitable-for-evaluation-reason-dto';
import { MeetingDto } from '../meeting/meeting.dto';
import { ConversationTagDto } from './tag/conversation-tag.dto';
import { VideoCallDto } from '../video-call/video-call.dto';
import { ConversationMarkDto } from '../mark/conversation-mark.dto';
import { EmailDto } from '../email/email.dto';
import { ConversationSentimentMarkDto } from '../mark/conversation-sentiment-mark.dto';
import { ConversationNERTemplateMarkDto } from '../mark/conversation-ner-template-mark.dto';

@Api('api/app/conversation')
export class ConversationDto extends DtoHasApi {
  id: number;
  typeId: number;
  typeName: string;
  startTime: Date;
  endTime: Date;
  duration: number;
  conversationSourceId: number;
  conversationSourceName: string;
  channelId: number;
  channelName: string;
  departmentName: string;
  userId: string;
  userName: string;
  userSurname: string;
  externalId: string;
  relatedGroupId: string;
  ratingForNPS: number;
  conclusionSentimentStatusId: number;
  averageCustomerSentimentScore: number | null;
  averageAgentSentimentScore: number | null;
  automaticEvaluationScore: number | null;
  numberOfComments: number;
  predictedCSATScore: number;
  transferCount: number;
  mainCategoryName: string;

  call: CallDto;
  chat: ChatDto;
  meeting: MeetingDto;
  videoCall: VideoCallDto;
  email: EmailDto;
  conversationTags: ConversationTagDto[] = [];
  marks: ConversationMarkDto[] = [];
  sentimentMarks: ConversationSentimentMarkDto[] = [];
  nerTemplateMarks: ConversationNERTemplateMarkDto[] = [];
  isSuitableForEvaluation: boolean;
  notSuitableForEvaluationReasons: NotSuitableForEvaluationReasonDto[] = [];

  isPriorForEvaluation: boolean;
  uniqueCustomerKey: string;

  hasScreenRecording: boolean;
  listeningStatusId: number;
}
