import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';

@Api('api/app/action-log')
export class ActionLogDto extends DtoHasApi {
  id: string;
  targetId: number;
  targetTypeId: number;
  actionTypeId: number;
  payload: string;
  creationTime: Date;
}
