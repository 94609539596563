import { State, Action, StateContext, createSelector } from '@ngxs/store';
import { LayoutChange, LayoutReset } from 'src/core/actions/layout/layout-actions';
import { LayoutConfig } from 'src/core/models/split/layout-config.model';
import { LayoutStateModel } from 'src/core/models/layout/layout.state-model';
import { Injectable } from '@angular/core';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
import { GenericState } from '../generic/generic.state';
const name = 'CALayoutState';
@State<LayoutStateModel[]>({
  name: name,
  defaults: [],
})
@Injectable()
export class CALayoutState extends GenericState<CALayoutState> {
  static layout(name: string) {
    return createSelector([CALayoutState], (state: LayoutStateModel[]) => {
      const idx = state.findIndex(l => l.name === name);

      return idx > -1 ? state[idx] : { name, config: ObjectHelper.deepCopy(LayoutConfig.DEFAULT) };
    });
  }

  constructor(userStateSettingsService: UserStateSettingsService) {
    super(userStateSettingsService);
    this.name = name;
  }

  @Action(LayoutChange)
  changeLayout(ctx: StateContext<LayoutStateModel[]>, action: LayoutChange) {
    const currentState = ctx.getState();
    const idx = currentState.findIndex(l => l.name === action.name);
    let viewLayout: LayoutStateModel;

    if (idx > -1) {
      viewLayout = currentState[idx];
    } else {
      viewLayout = {
        name: action.name,
        config: LayoutConfig.DEFAULT,
      };

      currentState.push(viewLayout);
    }

    viewLayout.config = action.layout;
    viewLayout.config.center = LayoutConfig.DEFAULT.center;

    const newState = Array.from(currentState);
    this.userSettingsService.setState(ctx, newState, name);
  }

  @Action(LayoutReset)
  resetLayout(ctx: StateContext<LayoutStateModel[]>, action: LayoutReset) {
    const currentState = ctx.getState();

    const idx = currentState.findIndex(l => l.name === action.name);
    let viewLayout: LayoutStateModel;

    if (idx > -1) {
      viewLayout = currentState[idx];
    } else {
      viewLayout = {
        name: action.name,
        config: null,
      };

      currentState.push(viewLayout);
    }

    viewLayout.config = LayoutConfig.DEFAULT;

    this.userSettingsService.setState(ctx, currentState, name);
  }
}
