import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Call.Direction'
})
export class CallDirection {
    @GenericLookup({
        code: 'Call.Direction.Inbound'
    })
    static Inbound: number;

    @GenericLookup({
        code: 'Call.Direction.InboundTransfer'
    })
    static inboundTransfer: number;

    @GenericLookup({
        code: 'Call.Direction.Outbound'
    })
    static outbound: number;

    @GenericLookup({
        code: 'Call.Direction.OutboundTransfer'
    })
    static outboundTransfer: number;

    @GenericLookup({
        code: 'Call.Direction.Internal'
    })
    static internal: number;

    @GenericLookup({
        code: 'Call.Direction.Unknown'
    })
    static unknown: number;
}
