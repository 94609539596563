import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.Side',
})
export class ConversationSide {
  @GenericLookup({
    code: 'Conversation.Side.Any',
  })
  static any: number;

  @GenericLookup({
    code: 'Conversation.Side.Agent',
  })
  static agent: number;

  @GenericLookup({
    code: 'Conversation.Side.Customer',
  })
  static customer: number;

  @GenericLookup({
    code: 'Conversation.Side.All',
  })
  static all: number;
}
