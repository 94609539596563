<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{
          'Query::QueryGroup' | abpLocalization
        }}</label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{
          'Query::QueryGroup' | abpLocalization
        }}</label>
      </div>
      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"
      ></ca-query-builder-node-remove-button>
      <div class="col-auto">
        <button
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          [matMenuTriggerFor]="root"
          (menuOpened)="onMenuOpened($event)"
          (menuClosed)="onMenuClosed($event)"
          ngbTooltip="{{ '::AddNewNode' | abpLocalization }}"
          placement="bottom"
        >
          <i class="fad fa-layer-plus"></i>
        </button>

        <mat-menu #root="matMenu">
          <ng-container *ngFor="let category of categories">
            <button
              mat-menu-item
              [matMenuTriggerFor]="sub_menu"
              class="tree-node-menu-button mt-1 mb-1"
            >
              <div [ngClass]="'query left-bordered ps-2 ' + category.cssClass">
                {{ 'Conversation::' + category.title | abpLocalization }}
              </div>
            </button>
            <mat-menu #sub_menu="matMenu">
              <ng-container *ngFor="let categoryItem of category.items">
                <button
                  class="tree-node-menu-button mt-1 mb-1"
                  *ngIf="isCategoryItemVisible(categoryItem)"
                  [disabled]="isCategoryItemDisabled(categoryItem)"
                  mat-menu-item
                  (click)="onAddNodeClick(category, categoryItem)"
                >
                  <div [ngClass]="'query left-bordered ps-2 ' + category.cssClass">
                    {{ 'Conversation::' + categoryItem.title | abpLocalization }}
                  </div>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </mat-menu>
      </div>
    </div>
  </div>
</ng-template>
