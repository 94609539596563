import { Component, Input } from '@angular/core';
import { ApplicationInfo, EnvironmentService } from '@abp/ng.core';

@Component({
  selector: 'ca-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentYear = new Date().getFullYear();

  constructor(private environment: EnvironmentService) {}

  get appInfo(): ApplicationInfo {
    return this.environment.getEnvironment().application;
  }
}
