<ng-template #rt let-item="result" let-t="term">
  <div *ngIf="item.index === -2" class="typeahead-container" (click)="$event.stopPropagation()">
    <h5 class="typeahead-header">{{ 'Query::SuggestedWords' | abpLocalization }}</h5>
  </div>
  <div *ngIf="item.index === -1" class="typeahead-container">
    <h5 class="typeahead-header">{{ 'Query::NoSuggestionsFound' | abpLocalization }}</h5>
  </div>
  <div *ngIf="item.index >= 0">
    <ngb-highlight [result]="item.phrase" [term]="t"></ngb-highlight>
  </div>
</ng-template>

<div>
  <input
    #inputField
    type="text"
    placeholder="{{ placeholder }}"
    class="{{ class }}"
    (change)="onInputChange()"
    (focus)="onInputFocus()"
    (blur)="onInputBlur()"
    [resultTemplate]="rt"
    [inputFormatter]="formatter"
    [popupClass]="'suggestion-popup'"
    [ngbTypeahead]="search" />
  <i *ngIf="loading" class="fad fa-spinner-third fa-spin loading-spinner"></i>
</div>
