import { Component, OnInit } from '@angular/core';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Operators } from 'src/core/models/request/operator.enum';
import { QueryBuilderCategoryDto } from 'src/core/models/query/query-builder-category.dto';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { AdvancedOrderedSimpleTermQueryItemDto } from 'src/core/models/query/advanced-ordered-simple-term-query-item.dto';
import { IntegerValidator } from 'src/core/validators/shared/integer.validator';
import { RegexTermValidator } from 'src/core/validators/query/regex-term.validator';

@Component({
  selector: 'ca-query-tree-node-advanced-ordered-simple-term',
  templateUrl: './query-tree-node-advanced-ordered-simple-term.component.html',
  styleUrls: ['./query-tree-node-advanced-ordered-simple-term.component.scss'],
})
export class QueryTreeNodeAdvancedOrderedSimpleTermComponent
  extends QueryEditorBase
  implements OnInit
{
  payload: AdvancedOrderedSimpleTermQueryItemDto;
  queryForm: FormGroup = null;
  categories: QueryBuilderCategoryDto[];

  private _minDistance: number = 1;
  private _maxDistance: number = 999;

  get minDistance(): number {
    return this._minDistance;
  }

  get maxDistance(): number {
    return this._maxDistance;
  }

  constructor(
    private fb: FormBuilder,
    public queryFieldDataType: QueryFieldDataType,
    public operators: Operators
  ) {
    super();

    this.queryForm = fb.group(
      {
        not: [null],
        term: [
          null,
          {
            validators: [Validators.required, RegexTermValidator.Validator],
          },
        ],
        maximumDistanceToNextItem: [
          null,
          {
            validators: [
              Validators.required,
              IntegerValidator.minMax(this.minDistance, this.maxDistance),
            ],
          },
        ],
        conversationSide: [null],
      },
      { updateOn: 'blur' }
    );

    this.queryForm.statusChanges.subscribe(status => {
      this.node.validationStatus = status === 'VALID';
    });

    this.queryForm.valueChanges.subscribe(() => {
      this.node.isDirty = this.queryForm.dirty;
    });
  }

  get isLastEditor(): boolean {
    if (this.node.parentNode.children) {
      var advancedOrderedSimpleTerms = this.node.parentNode.children.filter(
        c =>
          c.categoryItem.dataType === this.queryFieldDataType.AdvancedOrderedNearTerm ||
          c.categoryItem.dataType === this.queryFieldDataType.AdvancedOrderedSimpleTerm
      );

      const indexOfCurrent = advancedOrderedSimpleTerms.findIndex(
        o => o.categoryItem.payload.internalId === this.payload.internalId
      );
      return indexOfCurrent === advancedOrderedSimpleTerms.length - 1;
    }

    return false;
  }

  ngOnInit() {
    this.categories = this.queryBuilder.visibleCategories;
    this.payload = this.node.categoryItem.payload as AdvancedOrderedSimpleTermQueryItemDto;
  }
}
