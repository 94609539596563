<div
  class="form-group"
  [ngClass]="customClass"
  [formGroup]="editorForm"
  [ngbTooltip]="actualConfig.placeholder | abpLocalization"
  [disableTooltip]="(actualConfig.placeholder | abpLocalization).length < 50"
  container="body"
>
  <div [ngClass]="labelClass">
    <label
      *ngIf="actualConfig.label && actualConfig.label.length > 0"
      [for]="'text-editor-' + this.actualConfig.key"
      >{{ actualConfig.label | abpLocalization }}</label
    >
  </div>
  <div [ngClass]="textClass">
    <div class="input-wrapper" *ngIf="hasIcon">
      <i [ngClass]="actualConfig.iconCls"></i>
      <input
        type="text"
        [placeholder]="actualConfig.placeholder | abpLocalization"
        class="form-control form-control-sm editor"
        formControlName="editor"
      />
    </div>
    <input
      *ngIf="!hasIcon"
      type="text"
      [placeholder]="actualConfig.placeholder | abpLocalization"
      class="form-control form-control-sm"
      formControlName="editor"
    />
  </div>
</div>
