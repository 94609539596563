import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
export * from './services/client-excel.service';
export * from './models/excel-work-book.model';
export * from './models/excel-row.model';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ClientExcelModule {}
