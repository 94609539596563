<ng-template #modal let-modal>
  <form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ 'CA::ColumnConfigurator:ColumnOptions' | abpLocalization }}
      </h4>
    </div>
    <div
      cdkDropList
      style="min-height: 400px; max-height: 90vh; overflow-y: auto"
      class="modal-body m-2 pt-0"
      [cdkDropListSortPredicate]="sortPredicateFn"
      (cdkDropListDropped)="onDropRow($event)">
      <ng-container formArrayName="columns">
        <ng-container
          *ngFor="let columnFormControl of columnsFormArray.controls; let columnIdx = index">
          <div
            cdkDrag
            cdkDragBoundary=".modal-body"
            cdkDragLockAxis="y"
            [cdkDragDisabled]="columnFormControl.value?.item.locked"
            class="draggable-row d-flex flex-row justify-content stretch align-items-center mb-2">
            <div
              cdkDragHandle
              [ngClass]="columnFormControl.value?.item.locked ? '' : 'drag-handle'"
              class="text-center flex-grow-0 flex-shrink-0 pe-2">
              <i
                [style.margin]="'auto'"
                [style.opacity]="columnFormControl.value?.item.locked ? '0.3' : '1'"
                class="fa-solid fa-grip-dots-vertical"></i>
            </div>
            <div class="flex-grow-1 flex-shrink-1">
              <ca-column-selector
                [formControlName]="columnIdx"
                [items]="selectionItems"
                [readonly]="columnFormControl.value?.item.locked">
              </ca-column-selector>
            </div>
            <div class="text-center flex-grow-0 flex-shrink-0 ps-2">
              <i *ngIf="columnFormControl.value?.item.locked" class="fa-duotone fa-lock"></i>
              <i
                *ngIf="!columnFormControl.value?.item.locked"
                class="fa-solid fa-xmark cursor-pointer"
                (click)="onClickRemoveColumn($event, columnIdx)"></i>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="modal-footer justify-content-stretch">
      <div
        class="d-flex flex-row justify-content-start flex-grow-1"
        [disableTooltip]="!allColumnsSelected"
        ngbTooltip="{{ 'CA::ColumnConfigurator:AllColumnsSelected' | abpLocalization }}"
        container="body">
        <button
          [disabled]="allColumnsSelected"
          class="btn ca-btn ca-btn-neutral-toolbar d-flex align-items-center"
          type="button"
          (click)="onClickAddColumn($event)">
          <i class="far fa-plus-circle"></i
          ><span>{{ 'CA::ColumnConfigurator:AddColumn' | abpLocalization }}</span>
        </button>
      </div>
      <div class="d-flex flex-row justify-content-end flex-grow-1" style="gap: 8px">
        <button
          class="btn ca-btn ca-btn-neutral-toolbar d-flex align-items-center"
          type="button"
          (click)="onClickReset($event)">
          <i class="fa-regular fa-right-left"></i><span>{{ '::Reset' | abpLocalization }}</span>
        </button>
        <button
          class="btn ca-btn ca-btn-neutral-toolbar d-flex align-items-center"
          type="button"
          (click)="modal.dismiss()">
          <i class="far fa-rotate-left"></i
          ><span>{{ 'AbpUi::Cancel' | abpLocalization }}</span></button
        ><button
          class="btn ca-btn ca-btn-primary-toolbar d-flex align-items-center"
          type="submit"
          (click)="onClickSave($event)">
          <i class="far fa-floppy-disk"></i><span>{{ '::Save' | abpLocalization }}</span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
