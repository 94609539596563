import { Directive, ElementRef, OnInit } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[ngbTooltip][showTooltipIfTruncated]',
})
export class ShowTooltipIfTruncatedDirective implements OnInit {
  timer: any;
  count: number = 0;
  maxCount: number = 10;
  constructor(private ngbTooltip: NgbTooltip, private elementRef: ElementRef<HTMLElement>) { }

  public ngOnInit(): void {
    const fn = this.arrangeTooltip.bind(this);
    this.timer = setInterval(fn, 250);
  }

  private arrangeTooltip() {
    if (++this.count >= this.maxCount) {
      clearInterval(this.timer);
    }
    const element = this.elementRef.nativeElement;
    var scrollWidth = element.scrollWidth;
    var clientWidth = element.clientWidth;
    if (scrollWidth == 0 && clientWidth == 0) {
      return;
    }
    if (element?.style?.display && element?.style?.display.includes('inline')) {
      const offsetParent = element.parentElement;
      scrollWidth = offsetParent.scrollWidth;
      clientWidth = offsetParent.clientWidth;
    }
    this.ngbTooltip.disableTooltip = scrollWidth <= clientWidth;
    clearInterval(this.timer);
  }
}
