<span>
  {{ 'ActionLog::ActionType:ConversationAutomaticEvaluationComplete' | abpLocalization }}
  {{ 'ActionLog::FormName' | abpLocalization }}:
  <ca-clickable-link
    [innerHtml]="payloadData?.formName"
    [targetLink]="[
      '/quality/form',
      'v2',
      payloadData?.formId,
      'version',
      payloadData?.formVersionId
    ]"
    [withoutDiv]="true"></ca-clickable-link>
  ({{ 'ActionLog::EvaluationId' | abpLocalization }}:
  <ca-clickable-link
    [innerHtml]="payloadData?.evaluationId"
    [targetLink]="['/quality/evaluation', payloadData?.evaluationMasterId]"
    [params]="{
      evaluationResultId: payloadData?.evaluationId,
      referrerTab: activeTab,
      referrer: 'conversation'
    }"
    [withoutDiv]="true"></ca-clickable-link>
  )
</span>
