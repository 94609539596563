<div>
  <h2>{{ 'Settings::Analysis' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form [formGroup]="nonFcrSettingsForm" (ngSubmit)="onSubmitNonFcrSettings($event)">
  <ng-template #nonFcrPopContent>
    <p class="text-start h4">
      {{ 'Settings::UsageValueRecommendation' | abpLocalization }}
    </p>
    <div class="text-start">{{ 'Settings::NonFcrConversationInterval' | abpLocalization }}: 48</div>
    <div class="text-start">{{ 'Settings::NonFcrMinConversationCount' | abpLocalization }}: 3</div>
    <div class="text-start">{{ 'Settings::NonFcrMaxConversationCount' | abpLocalization }}: 50</div>
  </ng-template>
  <h4>
    {{ 'Settings::NonFcrSettings' | abpLocalization }}
    <i class="ms-2 fas fa-info-circle" [ngbTooltip]="nonFcrPopContent" container="body"></i>
  </h4>
  <div class="form-group mt-3">
    <input type="checkbox" id="nonFcrEnabled" formControlName="nonFcrEnabled" />
    <label class="ps-1" for="nonFcrEnabled">
      {{ 'Settings::NonFcrEnabled' | abpLocalization }}
    </label>
  </div>
  <div *ngIf="nonFcrSettingsForm.get('nonFcrEnabled').value">
    <div class="form-group mt-3">
      <label for="nonFcrCallInterval">
        {{ 'Settings::NonFcrConversationInterval' | abpLocalization }}
      </label>
      <input
        type="number"
        [min]="0"
        class="form-control form-control-sm"
        id="nonFcrCallInterval"
        formControlName="nonFcrCallInterval" />
    </div>
    <div class="form-group mt-3">
      <label for="nonFcrMinCallCount">
        {{ 'Settings::NonFcrMinConversationCount' | abpLocalization }}
      </label>
      <input
        type="number"
        min="0"
        class="form-control form-control-sm"
        id="nonFcrMinCallCount"
        formControlName="nonFcrMinCallCount" />
    </div>
    <div class="form-group mt-3">
      <label for="nonFcrMaxCallCount">
        {{ 'Settings::NonFcrMaxConversationCount' | abpLocalization }}
      </label>
      <input
        type="number"
        min="0"
        class="form-control form-control-sm"
        id="nonFcrMaxCallCount"
        formControlName="nonFcrMaxCallCount" />
    </div>
    <div class="form-group mt-3">
      <label for="nonFcrCallGroup">
        {{ 'Settings::NonFcrCallAccordingTo' | abpLocalization }}
      </label>
      <ca-select id="nonFcrCallGroup" formControlName="nonFcrCallGroup">
        <ca-option *ngFor="let group of nonFcrCallGroups" [value]="group.code">
          {{ 'GenericLookup::' + group.code | abpLocalization }}
        </ca-option>
      </ca-select>
    </div>
    <div class="form-group selector-group mt-3">
      <input
        type="checkbox"
        id="filterByCategoriesEnabled"
        formControlName="filterByCategoriesEnabled"
        (change)="onFilterByCategoriesChange($event)" />
      <label class="ps-1 pe-2" for="filterByCategoriesEnabled">
        {{ 'Settings::FilterByCategories' | abpLocalization }}
      </label>
      <ca-dropdown-selector
        #filterByCategoriesSelector
        [labelCls]="'toolbar-button-label'"
        id="filterByCategories"
        [url]="categoryDropdownOptions.url"
        [filters]="filterByCategorySelectorFilters"
        [sorters]="categorySelectorSorters"
        [pageSize]="categoryDropdownOptions.pageSize"
        [itemTemplate]="itemTemplate"
        [selectionTemplate]="selectionTemplate"
        [listSelectionTemplate]="listSelectionTemplate"
        [queryOperator]="categoryDropdownOptions.queryOperator"
        [queryField]="categoryDropdownOptions.queryField"
        [emptyText]="categoryDropdownOptions.emptyText"
        (openChanged)="onOpenChanged($event)"
        [multiple]="categoryDropdownOptions.multiple"
        [selectorPosition]="'Top'"
        [styles]="{ width: '100%' }"
        [listStyles]="{ width: '400px', height: '350px' }"
        [disabled]="disableFilterByCategoriesSelection"
        formControlName="filterByCategories"></ca-dropdown-selector>
    </div>
    <div *ngIf="analyticsFeatureEnabled">
      <h4 class="mt-3">
        {{ 'Settings::AdvancedNonFcrSettings' | abpLocalization }}
      </h4>
      <div class="form-group selector-group mt-3">
        <input
          type="checkbox"
          id="groupByCategoriesEnabled"
          formControlName="groupByCategoriesEnabled"
          (change)="onGroupByCategoriesChange($event)" />
        <label class="ps-1 pe-2" for="groupByCategoriesEnabled">
          {{ 'Settings::GroupByCategories' | abpLocalization }}
        </label>
        <ca-dropdown-selector
          #groupByCategoriesSelector
          [labelCls]="'toolbar-button-label'"
          id="groupByCategories"
          [url]="categoryDropdownOptions.url"
          [filters]="groupByCategorySelectorFilters"
          [sorters]="categorySelectorSorters"
          [pageSize]="categoryDropdownOptions.pageSize"
          [itemTemplate]="itemTemplate"
          [selectionTemplate]="selectionTemplate"
          [listSelectionTemplate]="listSelectionTemplate"
          [queryOperator]="categoryDropdownOptions.queryOperator"
          [queryField]="categoryDropdownOptions.queryField"
          [emptyText]="categoryDropdownOptions.emptyText"
          (openChanged)="onOpenChanged($event)"
          [multiple]="categoryDropdownOptions.multiple"
          [selectorPosition]="'Top'"
          [styles]="{ width: '100%' }"
          [listStyles]="{ width: '400px', height: '350px' }"
          [disabled]="disableGroupByCategoriesSelection"
          formControlName="groupByCategories"></ca-dropdown-selector>
      </div>
      <div class="form-group selector-group mt-3">
        <input
          type="checkbox"
          id="groupByAttachedDataEnabled"
          formControlName="groupByAttachedDataEnabled"
          (change)="onGroupByAttachedDataChange($event)" />
        <label class="ps-1 pe-2" for="groupByAttachedDataEnabled">
          {{ 'Settings::GroupByAttachedData' | abpLocalization }}
        </label>
        <ca-dropdown-selector
          id="attachedDataSelector"
          #attachedDataSelector
          [url]="attachedDataSelectorOptions.url"
          [filters]="attachedDataSelectorFilters"
          [pageSize]="attachedDataSelectorOptions.pageSize"
          [itemTemplate]="attachedDataItemTemplate"
          [queryOperator]="attachedDataSelectorOptions.queryOperator"
          [queryField]="attachedDataSelectorOptions.queryField"
          [emptyText]="attachedDataSelectorOptions.emptyText"
          [multiple]="attachedDataSelectorOptions.multiple"
          [selectorPosition]="attachedDataSelectorOptions.selectorPosition"
          [selectionTemplate]="attachedDataSelectionTemplate"
          [listSelectionTemplate]="attachedDataListSelectionTemplate"
          [styles]="{ width: '100%', 'border-width': '0px' }"
          [listStyles]="{ height: '350px' }"
          [labelCls]="'toolbar-button-label'"
          [disabled]="disableAttachedDataSelection"
          formControlName="groupByAttachedData"></ca-dropdown-selector>
      </div>
    </div>
  </div>
  <div class="btn-group mt-4">
    <button type="submit" class="btn btn-primary" data-restart="false">
      <i class="me-1 fas fa-save"></i>
      <span>
        {{ 'AbpUi::Save' | abpLocalization }}
      </span>
    </button>
    <div
      *ngIf="nonFcrSettingsForm.get('nonFcrEnabled').value"
      class="btn-group"
      ngbDropdown
      role="group">
      <button type="button" class="btn btn-primary" ngbDropdownToggle></button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button ngbDropdownItem type="submit" data-restart="true">
          <span>{{ 'Settings::NonFcrSaveAndRestart' | abpLocalization }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
<ng-template #selectionTemplate let-selection="selection">
  <div [ngbTooltip]="selection.name" container="body" style="display: inline">
    {{
      selection.length === 1
        ? selection[0].name
        : ('::XItemsSelected' | abpLocalization : selection.length)
    }}
  </div>
</ng-template>

<ng-template #listSelectionTemplate let-selection="selection">
  <div [ngbTooltip]="selection.name" container="body" style="display: inline">
    {{ selection.name.length < 16 ? selection.name : (selection.name | slice : 0 : 12) + '...' }}
  </div>
</ng-template>

<ng-template
  #previewQueryPopContent
  let-queryItems="queryItems"
  let-categories="categories"
  let-query="query">
  <div *ngIf="!query.lastQueryVersionIndexed">
    {{ 'Query::IndexingContinuesMessage' | abpLocalization }}
  </div>
  <ca-query-detail-builder
    #builder
    [isReadonly]="true"
    (abpInit)="builder.loadQueryItems(queryItems, categories, query)"></ca-query-detail-builder>
</ng-template>

<ng-template #previewQueryPopTitle let-query="query">
  <div>
    {{ query.name }}
  </div>
</ng-template>

<ng-template #previewTopicPopContent let-phrases="phrases">
  <div>{{ 'Category::CategoryPreviewInfo' | abpLocalization }}:</div>
  <br />
  <div>{{ phrases.join(', ') }}</div>
</ng-template>

<ng-template #loaded>
  <mat-icon>info_outline</mat-icon>
</ng-template>

<ng-template #itemTemplate let-item="item">
  <div class="query-selection-panel-query-name">
    <div>{{ item.name }}</div>
    <div *ngIf="item.categoryType === categoryType.Query" class="query-selection-panel-info-button">
      <button
        type="button"
        mat-icon-button
        [ngStyle]="{
          color: !item.query.lastQueryVersionIndexed ? 'rgb(255, 157, 9)' : 'rgb(0, 0, 0)'
        }"
        style="vertical-align: top"
        #popover="ngbPopover"
        [ngbPopover]="previewQueryPopContent"
        [popoverTitle]="previewQueryPopTitle"
        popoverClass="query-preview-popover"
        placement="right auto"
        triggers="manual"
        container="body"
        [autoClose]="'outside'"
        (click)="toggleCategoryPreviewPopover(popover, item, $event)">
        <div *ngIf="isQueryPreviewLoading && item.queryId === previewQueryId; else loaded">
          <mat-progress-spinner
            class="black-spinner"
            style="margin: 0 auto; padding: 0px"
            mode="indeterminate"
            diameter="20"></mat-progress-spinner>
        </div>
      </button>
    </div>
    <div *ngIf="item.categoryType === categoryType.Topic" class="query-selection-panel-info-button">
      <button
        type="button"
        mat-icon-button
        style="vertical-align: top"
        #popover="ngbPopover"
        [ngbPopover]="previewTopicPopContent"
        [popoverTitle]="item.name"
        popoverClass="query-preview-popover"
        placement="right auto"
        triggers="manual"
        container="body"
        [autoClose]="'outside'"
        (click)="toggleTopicPreviewPopover(popover, item, $event)">
        <div *ngIf="isTopicPreviewLoading && item.topicId === previewTopicId; else loaded">
          <mat-progress-spinner
            class="black-spinner"
            style="margin: 0 auto; padding: 0px"
            mode="indeterminate"
            diameter="20"></mat-progress-spinner>
        </div>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #infoTemplate let-item="item">
  <div class="query-selection-panel-query-name">
    <div>{{ item.name }}</div>
    <div class="query-selection-panel-info-button">
      <button
        type="button"
        mat-icon-button
        style="vertical-align: bottom"
        #popover="ngbPopover"
        [ngbPopover]="previewQueryPopContent"
        [popoverTitle]="previewQueryPopTitle"
        popoverClass="query-preview-popover"
        placement="right auto"
        triggers="manual"
        container="body"
        [autoClose]="'outside'"
        (click)="toggleQueryPreviewPopover(popover, item, $event)">
        <div *ngIf="isQueryPreviewLoading && item.id === previewQueryId; else loaded">
          <mat-progress-spinner
            class="black-spinner"
            style="margin: 0 auto; padding: 0px"
            mode="indeterminate"
            diameter="20"></mat-progress-spinner>
        </div>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #attachedDataItemTemplate let-item="item">
  {{ item.name }}
</ng-template>

<ng-template #attachedDataListSelectionTemplate let-selection="selection">
  {{ selection.name }}
</ng-template>

<ng-template #attachedDataSelectionTemplate let-selection="selection">
  <div [ngbTooltip]="selection.name" container="body" style="display: inline">
    {{
      selection.length === 1
        ? selection[0].name
        : ('::XItemsSelected' | abpLocalization : selection.length)
    }}
  </div>
</ng-template>
