<form [formGroup]="toolbarForm">
  <div class="row entry-row submenu-row">
    <div class="col-auto">
      <h1 class="content-header-title">
        {{ '::Users' | abpLocalization }}
      </h1>
    </div>
    <div class="col-md-auto pl-md-0">
      <abp-breadcrumb></abp-breadcrumb>
    </div>
    <div class="col">
      <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
        <div class="ca-toolbar-west-section">
          <div class="toolbar-item first">
            <button
              class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
              type="button"
              (click)="addNew($event)">
              <i class="far fa-plus-circle"></i>
              <span>{{ '::AddNew' | abpLocalization }}</span>
            </button>
          </div>
        </div>
        <div class="ca-toolbar-east-section">
          <div class="toolbar-item last">
            <mat-slide-toggle
              labelPosition="before"
              (change)="onIncludePassiveUsersChange()"
              formControlName="includePassiveUsers">
              {{ '::IncludePassives' | abpLocalization }}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
