import { Store } from '@ngxs/store';
import { EnumState } from 'src/core/states/enum/enum.state';
import { Injector } from '@angular/core';

export class ServerSideEnum {
  constructor(injector: Injector) {
    const store = injector.get(Store);
    const name = (this.constructor as any).enumName;
    const state = store.selectSnapshot<any>(EnumState);
    const data = state[name];

    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }
  }
}
