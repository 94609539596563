import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Call.Analysis.Status'
})
export class CallAnalysisStatus {
    @GenericLookup({
        code: 'Call.Analysis.Status.NotAnalyzed'
    })
    static notAnalyzed: number;

    @GenericLookup({
        code: 'Call.Analysis.Status.Analyzing'
    })
    static analyzing: number;

    @GenericLookup({
        code: 'Call.Analysis.Status.Analyzed'
    })
    static analyzed: number;

    @GenericLookup({
        code: 'Call.Analysis.Status.Failed'
    })
    static failed: number;

    @GenericLookup({
        code: 'Call.Analysis.Status.NoSpeech'
    })
    static noSpeech: number;

    @GenericLookup({
        code: 'Call.Analysis.Status.FileNotFound'
    })
    static fileNotFound: number;

    @GenericLookup({
        code: 'Call.Analysis.Status.NotSupportedLanguageCode'
    })
    static notSupportedLanguageCode: number;

    @GenericLookup({
        code: 'Call.Analysis.Status.LanguageDetectionServiceDisabled'
    })
    static languageDetectionServiceDisabled: number;

    @GenericLookup({
        code: 'Call.Analysis.Status.NotSupportedAudioFormat'
    })
    static notSupportedAudioFormat: number;

    @GenericLookup({
        code: 'Call.Analysis.Status.SpeechRecognitionError'
    })
    static speechRecognitionError: number;
}
