import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConversationImportStatus } from 'src/core/models/conversation/conversation-import-status.dto';
import { environment } from 'src/environments/environment';
import { CallTranscriptDto } from 'src/core/models/call/call-transcript.dto';
@Injectable({
  providedIn: 'root',
})
export class CallService {
  constructor(private http: HttpClient) {}

  private apiBase = environment.apis.default.url;

  import(files: FormData): Observable<ConversationImportStatus> {
    return this.http.post(
      `${this.apiBase}/api/app/call/import`,
      files
    ) as Observable<ConversationImportStatus>;
  }

  csvImport(files: FormData): Observable<boolean> {
    return this.http.post(`${this.apiBase}/api/app/call/import-csv`, files) as Observable<boolean>;
  }

  downloadSampleCsv(): Observable<File> {
    return this.http.get<File>(`${this.apiBase}/api/app/call/sample-csv`, {
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
  }

  downloadAudio(downloadUrl: string): Observable<File> {
    return this.http.get<File>(`${this.apiBase}` + downloadUrl, {
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
  }

  updateTranscript(id: number, transcripts: CallTranscriptDto[]) {
    return this.http.put(`${this.apiBase}/api/app/call/${id}/transcript`, transcripts);
  }

  updateCategoryTranscript(id: number, transcripts: CallTranscriptDto[]) {
    return this.http.put(
      `${this.apiBase}/api/app/call/${id}/update-edited-category-transcript`,
      transcripts
    );
  }

  deleteCategoryTranscript(id: number, transcripts: CallTranscriptDto[]) {
    return this.http.put(
      `${this.apiBase}/api/app/call/${id}/update-deleted-category-transcript`,
      transcripts
    );
  }
}
