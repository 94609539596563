import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { take } from 'rxjs';
import { IntegerValidator } from 'src/core/validators/shared/integer.validator';

@Component({
  selector: 'ca-automatic-summarization-settings',
  templateUrl: './automatic-summarization-settings.component.html',
  styleUrls: ['./automatic-summarization-settings.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AutomaticSummarizationSettingsComponent implements OnInit {
  get form(): FormGroup {
    return this.formGroupDirective.form;
  }

  constructor(private fb: FormBuilder, private formGroupDirective: FormGroupDirective) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(take(1)).subscribe(value => {
      this.setValidators(value);
    });
  }

  setValidators(formValues): void {
    const filter = formValues.automaticSummarizationFilterConversations.toLowerCase() === 'true';

    if (!formValues.automaticSummarizationEnabled) {
      this.form.get('automaticSummarizationCategories').clearValidators();
      this.form.get('automaticSummarizationDailyConversationLimit').clearValidators();
    } else if (formValues.automaticSummarizationEnabled && filter) {
      this.form.get('automaticSummarizationCategories').addValidators([Validators.required]);
      this.form
        .get('automaticSummarizationDailyConversationLimit')
        .addValidators([IntegerValidator.minMax(0, Number.MAX_SAFE_INTEGER)]);
    } else if (formValues.automaticSummarizationEnabled) {
      this.form
        .get('automaticSummarizationDailyConversationLimit')
        .addValidators([IntegerValidator.minMax(0, Number.MAX_SAFE_INTEGER)]);
      this.form.get('automaticSummarizationCategories').clearValidators();
    }

    this.form.updateValueAndValidity();

    this.form.valueChanges.pipe(take(1)).subscribe(value => {
      this.setValidators(value);
    });
  }
}
