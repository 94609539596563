import { Injectable } from '@angular/core';
import { TagDto } from 'src/core/models/conversation/tag/tag.dto';

@Injectable()
export class ConversationBatchTaggingCommunicationService {
  isFilesSelected: boolean;
  isValid: boolean;
  isTagsSelected: boolean;
  tags: TagDto[];
}
