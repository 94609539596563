import { LocalizationService } from '@abp/ng.core';
import { take } from 'rxjs/operators';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';
import { CAToasterService } from 'src/core/services/toaster/ca-toaster.service';
import { DesktopNotificationService } from 'src/core/services/desktop-notification/desktop-notification.service';

@Component({
  selector: 'ca-real-time-event-detected',
  templateUrl: './real-time-event-detected-notification.component.html',
  styleUrls: ['./real-time-event-detected-notification.component.scss'],
})
@NotificationType('RealTimeEventDetectedNotification', RealTimeEventDetectedNotificationComponent)
export class RealTimeEventDetectedNotificationComponent implements OnInit {
  constructor(private localizationService: LocalizationService) {}
  router: Router;
  ngOnInit(): void {}
  data: NotificationDto;

  get scenarioName(): string {
    return this.data ? JSON.parse(this.data.payload).scenarioName : '';
  }

  get scenarioId(): string {
    return this.data ? JSON.parse(this.data.payload).scenarioId : '';
  }

  get agentName(): string {
    return this.data ? JSON.parse(this.data.payload).agentName : '';
  }
  get text(): string {
    return this.localizationService.instant(
      'RealTime::RealTimeEventDetectedNotificationMessage',
      this.agentName === null ? '' : this.agentName,

      this.scenarioName,
      this.scenarioId
    );
  }

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: CAToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe,
    router: Router
  ) {
    if (router.url !== '/real-time/notification') {
      const parsedPayload = JSON.parse(payload);
      const message = localizationService.instant(
        'RealTime::RealTimeEventDetectedNotificationMessage',
        parsedPayload.agentName === null ? '' : parsedPayload.agentName,
        parsedPayload.scenarioName,
        parsedPayload.scenarioId
      );

      const favIcon: HTMLLinkElement = document.querySelector('#appFavicon');
      const options =
        favIcon && favIcon.href
          ? {
              icon: favIcon.href,
              badge: favIcon.href,
            }
          : null;
      var success = DesktopNotificationService.showDesktopNotification(message, options);

      if (!success) {
        let toastrId = toastr.show(message, '', 'info', { customToastrClass: 'toast-realtime' });
        let toastrInstance = toasterHelperService.getToastById(toastrId);
        toastrInstance.onTap.pipe(take(1)).subscribe(() => {
          this.toasterClickedHandler(notificationService, notificationId, router);
        });
      }
    }
  }

  static toasterClickedHandler(
    notificationService: NotificationService,
    notificationId: string,
    router: Router
  ) {
    router.navigate(['/real-time/notification']);
  }
}
