<div>
  <h2>{{ 'Settings::RealTime' | abpLocalization }}</h2>
</div>
<br />
<hr />

<form
  *ngIf="realTimeFeatureEnabled"
  [formGroup]="realTimeSettingsForm"
  (ngSubmit)="onSubmitAnalysisSettings()">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
    <li *ngIf="virtualAgentIntegrationEnabled" [ngbNavItem]="1">
      <a ngbNavLink>
        <span style="margin-left: 4px">
          {{ 'Settings::RealTimeVirtualAgentIntegration' | abpLocalization }}
        </span>
      </a>
      <ng-template ngbNavContent>
        <ca-real-time-virtual-agent-settings></ca-real-time-virtual-agent-settings>
      </ng-template>
    </li>
    <li *ngIf="generativeQnAFeatureEnabled" [ngbNavItem]="2">
      <a ngbNavLink>
        <span style="margin-left: 4px">
          {{ 'Settings::RealTimeGenerativeQnA' | abpLocalization }}
        </span>
      </a>
      <ng-template ngbNavContent>
        <ca-generative-qa-settings></ca-generative-qa-settings>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>
        <span style="margin-left: 4px">
          {{ 'Settings::RealTimeAnalysis' | abpLocalization }}
        </span>
      </a>
      <ng-template ngbNavContent>
        <ca-real-time-analysis-settings></ca-real-time-analysis-settings>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" style="height: calc(100% - 130px)"></div>
</form>
