import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';

@Component({
  selector: 'ca-query-index-completed-notification',
  templateUrl: './query-index-completed-notification.component.html',
  styleUrls: ['./query-index-completed-notification.component.scss'],
})
@NotificationType('QueryIndexCompletedNotification', QueryIndexCompletedNotificationComponent)
export class QueryIndexCompletedNotificationComponent implements OnInit {
  data: NotificationDto;

  get queryName(): string {
    return this.data ? JSON.parse(this.data.payload).queryName : '';
  }

  constructor() {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'Query::IndexingCompletedNotification',
      parsedPayload.queryName
    );

    toastr.success(message);
  }
}
