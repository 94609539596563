import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { NotificationService } from 'src/core/services/notification/notification.service';
import { CAToasterHelperService } from 'src/core/services/toaster/ca-toaster-helper.service';

@Component({
  selector: 'ca-assignment-due-date-approaching-notification',
  templateUrl: './assignment-due-date-approaching-notification.component.html',
  styleUrls: ['./assignment-due-date-approaching-notification.component.scss'],
})
@NotificationType(
  'AssignmentDueDateApproachingNotification',
  AssignmentDueDateApproachingNotificationComponent
)
export class AssignmentDueDateApproachingNotificationComponent implements OnInit {
  data: NotificationDto;

  get assignmentName(): string {
    return this.data ? JSON.parse(this.data.payload).assignmentName : '';
  }

  get dueDate(): string {
    const a = JSON.parse(this.data.payload).dueDate;
    return this.data ? this.caDatePipe.transform(a, DateDisplayType.Date) : '';
  }

  constructor(private caDatePipe: CADatePipe) {}

  ngOnInit(): void {}

  static showNotification(
    notificationId: string,
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
    toasterHelperService: CAToasterHelperService,
    notificationService: NotificationService,
    injector: Injector,
    caDatePipe: CADatePipe
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'QualityManagement::AssignmentDueDateApproachingNotification',
      parsedPayload.assignmentName,
      caDatePipe
        ? caDatePipe.transform(parsedPayload.dueDate, DateDisplayType.Date)
        : parsedPayload.dueDate
    );

    toastr.warn(message);
  }
}
