import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '@abp/ng.core';

@Pipe({ name: 'caTimePipe' })
export class CATimePipe implements PipeTransform {
    constructor(private localizationService: LocalizationService) { }

    transform(
        seconds: number,
        showShortcuts: boolean = false
    ): any {
        if (seconds != null) {
            const mins = Math.floor(seconds / 60).toString().padStart(2, '0');
            const secs = Math.floor(seconds % 60).toString().padStart(2, '0');
            if (showShortcuts) {
                return this.localizationService.instant('::TimeShortcut', mins, secs);
            }
            else {
                return mins + ':' + secs;
            }
        }
        else '';
    }
}
