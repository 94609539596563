import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.EvaluationMark',
})
export class ConversationEvaluationMark {
  @GenericLookup({
    code: 'Conversation.EvaluationMark.NotSuitableForEvaluation',
  })
  static notSuitableForEvaluation: number;

  @GenericLookup({
    code: 'Conversation.EvaluationMark.PriorForEvaluation',
  })
  static priorForEvaluation: number;
}
