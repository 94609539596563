import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Identity.UserList.SortOption'
})
export class UserListSortOption {
    @GenericLookup({
        code: 'Identity.UserList.SortOption.UsernameAscending'
    })
    static usernameAscending: number;
}
