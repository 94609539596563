import { EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { ConversationCommentComponentPanel } from '../../conversation-comment-panel/conversation-comment-panel.component';

@Component({
  selector: 'ca-email-detail-tab-panel',
  templateUrl: './email-detail-tab-panel.component.html',
  styleUrls: ['./email-detail-tab-panel.component.scss']
})
export class EmailDetailTabPanelComponent implements OnInit {
  private _conversation: ConversationDto;

  startTime: string;
  commentCount: number = 0;
  analyticsFeatureEnabled: boolean;

  activeTab: number = 1;

  @Input()
  set conversation(value) {
    this._conversation = value;
  }

  get conversation(): ConversationDto {
    return this._conversation;
  }

  @ViewChild('commentPanel', {
    read: ConversationCommentComponentPanel,
    static: false,
  })
  commentPanel: ConversationCommentComponentPanel;

  @Output()
  addCommentRequested: EventEmitter<{
    comment: any;
    startTime: any;
    endTime: any;
  }> = new EventEmitter();

  constructor(
    private featureService: FeatureService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.analyticsFeatureEnabled = this.featureService.isEnabled(FeatureConstants.Analytics);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const activeTab = this.activatedRoute.snapshot.queryParams.activeTab;
      if (activeTab) {
        this.activeTab = Number(activeTab);
      }
    });
  }

  onAddCommentRequest(eventArgs) {
    this.addCommentRequested.emit(eventArgs);
  }

  onGoToComment(eventArgs) {
    this.startTime = null;

    setTimeout(() => {
      this.activeTab = eventArgs.activeTab;
      this.startTime = eventArgs.startTime;
    }, 200);
  }

  onCommentCountChanged(eventArgs) {
    this.commentCount = eventArgs.comments.length;
  }
}
