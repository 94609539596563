import { DtoHasApi } from './dto-has-api.model';
import { Api } from 'src/core/decorators/dto/api.decorator';

@Api('api/identity/users')
export class UserDto extends DtoHasApi {
  constructor(data?: UserDto) {
    super();
    if (data != null) {
      this.id = data.id;
      this.tenantId = data.tenantId;
      this.userName = data.userName;
      this.password = data.password;
      this.name = data.name;
      this.surname = data.surname;
      this.email = data.email;
      this.emailConfirmed = data.emailConfirmed;
      this.phoneNumber = data.phoneNumber;
      this.phoneNumberConfirmed = data.phoneNumberConfirmed;
      this.isLockedOut = data.isLockedOut;
      this.lockoutEnabled = data.lockoutEnabled;
      this.roleNames = data.roleNames;
      this.extraProperties = data.extraProperties;
      this.concurrencyStamp = data.concurrencyStamp;
      this.twoFactorEnabled = data.twoFactorEnabled;
      this.extraProperties.ExternalId = data.extraProperties?.ExternalId;
    }
  }

  id: string = '';
  twoFactorEnabled: boolean = false;
  concurrencyStamp: string = '';
  tenantId?: string = null;
  userName: string = '';
  password: string = null;
  name: string = '';
  surname: string = '';
  email: string = '';
  emailConfirmed: boolean;
  phoneNumber: string = '';
  phoneNumberConfirmed: boolean;
  isLockedOut: boolean;
  lockoutEnabled: boolean;
  roleNames: string[] = [];
  extraProperties: any = {
    LdapUser: false,
    IsActive: true,
    DepartmentId: 0,
    PbxAgentId: '',
    SupervisorId: '',
    SecondPhoneNumber: '',
    IsInteractive: false,
    ExternalId: '',
  };

  get middleName(): string {
    return this.extraProperties.MiddleName;
  }

  set middleName(value: string) {
    this.extraProperties.MiddleName = value;
  }

  get fullName(): string {
    return this.surname && this.surname.length > 0
      ? this.middleName && this.middleName.length > 0
        ? this.name + ' ' + this.middleName + ' ' + this.surname
        : this.name + ' ' + this.surname
      : this.middleName && this.middleName.length > 0
      ? this.name + ' ' + this.middleName
      : this.name;
  }
}
