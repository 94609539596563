import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.Summary.Status',
})
export class ConversationSummaryStatus {
  @GenericLookup({
    code: 'Conversation.Summary.Status.Generating',
  })
  static generating: number;

  @GenericLookup({
    code: 'Conversation.Summary.Status.Generated',
  })
  static generated: number;

  @GenericLookup({
    code: 'Conversation.Summary.Status.Failed',
  })
  static failed: number;

  @GenericLookup({
    code: 'Conversation.Summary.Status.SkippedDueToSuspension',
  })
  static skippedDueToSuspension: number;

  @GenericLookup({
    code: 'Conversation.Summary.Status.SkippedDueToDailyLimit',
  })
  static skippedDueToDailyLimit: number;

  @GenericLookup({
    code: 'Conversation.Summary.Status.SkippedDueToCategoryFilter',
  })
  static skippedDueToCategoryFilter: number;
}
