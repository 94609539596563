import { Injectable, Injector } from '@angular/core';
import { LocalizationService } from '@abp/ng.core';
import { ServerSideEnum } from './server-side-enum.model';
import { MapToServerSideEnum } from 'src/core/decorators/enum/mapto-server-side-enum.decorator';

@Injectable({
  providedIn: 'root',
})
@MapToServerSideEnum('LogicalOperators')
export class LogicalOperators extends ServerSideEnum {
  private notations: string[];
  And: number;
  Or: number;

  constructor(injector: Injector, private localizationService: LocalizationService) {
    super(injector);
  }

  getNotation(operator: number) {
    this.notations = [
      '',
      this.localizationService.instant('Query::AND'),
      this.localizationService.instant('Query::OR'),
    ];
    return this.notations[operator];
  }
}
