import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { map, take, takeUntil } from 'rxjs/operators';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConversationRedactionMode } from 'src/core/models/generic-lookup-type/conversation/redaction-mode.glt';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { QueryService } from 'src/core/services/query/query.service';
import { PhraseTypeSettingsService } from 'src/core/services/settings/phrase-type-settings.service';
import { PhraseTypeDto } from 'src/core/models/conversation/phrase-type/phrase-type.dto';

@Component({
  selector: 'ca-redaction-settings',
  templateUrl: './redaction-settings.component.html',
  styleUrls: ['./redaction-settings.component.scss'],
})
export class RedactionSettingsComponent extends HasSubscription implements OnInit {
  settingsForm: FormGroup;
  redactionSettingDtos: ConfigurationSettingsDto[] = [];
  redactionModes: GenericLookupDto[];
  defaultRedactionModeCode: string;
  phraseTypeList: PhraseTypeDto[] = [];
  selectedPhraseTypes: number[] = [];
  groupByList: any = [];

  private readonly settingKeyDynamicDataMaskingEnabled = 'Global.DynamicDataMaskingEnabled';
  private readonly settingKeyRedactionMode = 'Redaction.RedactionMode';
  private readonly settingKeyRedactionTemplates = 'PhraseType.RedactionTemplates';

  get disableSaveButton(): boolean {
    if (this.settingsForm.get('dynamicDataMaskingEnabled').value) {
      return this.selectedPhraseTypes.length <= 0;
    }
    return false;
  }

  constructor(
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private store: Store,
    private config: ConfigStateService,
    private fb: FormBuilder,
    private globalSettingsService: GlobalSettingsService,
    private queryService: QueryService,
    private phraseTypeSettingsService: PhraseTypeSettingsService,
  ) {
    super();
    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(takeUntil(this.autoUnsubscribeNotifier))
      .pipe(map(filterFn => filterFn(ConversationRedactionMode)))
      .subscribe(result => {
        this.redactionModes = result;
        this.defaultRedactionModeCode = this.redactionModes.find(
          f => f.id === ConversationRedactionMode.replaceWithCharacter
        ).code;
      });
    let redactionModeCode =
      this.config.getSetting(this.settingKeyRedactionMode) ?? this.defaultRedactionModeCode;
    let dynamicDataMaskingEnabled = this.globalSettingsService.dynamicDataMaskingEnabled;

    this.settingsForm = fb.group({
      dynamicDataMaskingEnabled: [dynamicDataMaskingEnabled],
      redactionMode: [redactionModeCode],
    });

    if (dynamicDataMaskingEnabled) {
      this.setRedactionEntities();
    }
  }

  ngOnInit(): void { }

  getSelectedCountText(): string {
    let selectionText = this.localizationService.instant(
      'Settings::NamedEntityRecognitionEntities'
    );
    selectionText = selectionText.replace('{0}', this.selectedPhraseTypes?.length.toString());
    selectionText = selectionText.replace('{1}', this.phraseTypeList.length.toString());
    return selectionText;
  }

  isSelected(entity: PhraseTypeDto) {
    return this.selectedPhraseTypes.includes(entity.id);
  }

  setRedactionEntities() {

    this.phraseTypeSettingsService
      .getRedactionTemplates()
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.phraseTypeList = response.phraseTypes;
          const removedPhraseTypeNames = response.removedPhraseTypeNames;
          this.phraseTypeList = this.phraseTypeList.map((p) =>
            p.isUsedInRecognition ? { ...p, disabled: true } : p);
          const usedPhraseTypeIds: number[] = this.config.getSetting(this.settingKeyRedactionTemplates) ? JSON.parse(this.config.getSetting(this.settingKeyRedactionTemplates)) : [];
          this.selectedPhraseTypes = usedPhraseTypeIds ?? [];

          if (removedPhraseTypeNames.length > 0) {
            this.toastr.warn(
              this.localizationService.instant(
                'Settings::RemovedPhraseTypes',
                removedPhraseTypeNames.join(',')
              )
            );
          }
          if (this.phraseTypeList.length == 0) {
            this.toastr.info(this.localizationService.instant('Settings::NoRedactionEntity'));
          }

          this.groupByList = this.groupByCategory(this.phraseTypeList);
        },
        error: () => { },
      });
  }

  onEntityChange(event, phraseType: PhraseTypeDto) {
    if (event.target.checked) {
      this.selectedPhraseTypes.push(phraseType.id);
    } else {
      this.selectedPhraseTypes = this.selectedPhraseTypes.filter(
        id => id != phraseType.id
      );
    }
  }

  groupByCategory(phraseTypeList: any[]): any {
    return phraseTypeList.reduce((grouped, phraseType) => {
      const category = phraseType.category || 'Others';
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(phraseType);
      return grouped;
    }, {});
  }

  onDynamicDataMaskingEnabledChanged(eventArgs) {
    if (eventArgs.target.checked) {
      this.queryService.getExistCallerOrCalledNumber().subscribe(response => {
        if (response === true) {
          this.settingsForm.get('dynamicDataMaskingEnabled').setValue(false);
          this.settingsForm.get('redactionMode').setValue(this.defaultRedactionModeCode);
          this.settingsForm.get('redactionMode').clearValidators();
          return;
        }

        this.setRedactionEntities();
        this.settingsForm.get('redactionMode').setValidators(Validators.required);
        this.settingsForm.get('redactionMode').setValue(this.defaultRedactionModeCode);
        this.settingsForm.get('redactionMode').updateValueAndValidity();
      });
    } else {
      this.settingsForm.get('redactionMode').setValue(this.defaultRedactionModeCode);
      this.settingsForm.get('redactionMode').clearValidators();
    }
  }

  onSubmitSettings() {
    if (this.settingsForm.invalid) {
      return;
    }
    this.saveSettings();
  }

  saveSettings() {
    if (this.settingsForm.invalid) {
      return;
    }

    this.addRedactionSettings();

    this.settingService
      .saveSetting(this.redactionSettingDtos)
      .pipe(take(1))
      .subscribe(res => {
        this.toastr.success(
          this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
        );
      });
  }

  private addRedactionSettings() {
    this.redactionSettingDtos = new Array();

    this.redactionSettingDtos.push({
      settingName: this.settingKeyDynamicDataMaskingEnabled,
      settingValue: this.settingsForm.get('dynamicDataMaskingEnabled').value.toString(),
    });

    if (this.settingsForm.get('dynamicDataMaskingEnabled').value) {
      this.redactionSettingDtos.push({
        settingName: this.settingKeyRedactionMode,
        settingValue: this.settingsForm.get('redactionMode').value.toString(),
      });

      this.redactionSettingDtos.push({
        settingName: this.settingKeyRedactionTemplates,
        settingValue: JSON.stringify(this.selectedPhraseTypes ?? []),
      });
    }
    else {
      this.redactionSettingDtos.push({
        settingName: this.settingKeyRedactionTemplates,
        settingValue: JSON.stringify([]),
      });
    }
  }
}
