import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConversationTypeIconComponent } from './conversation-type-icon/conversation-type-icon.component';
import { CallDirectionIconComponent } from './call-direction-icon/call-direction-icon.component';
import { CallReleasingPartyIconComponent } from './call-releasing-party-icon/call-releasing-party-icon.component';
import { CallListeningStatusIconComponent } from './call-listening-status-icon/call-listening-status-icon.component';
import { ChatReleasingPartyIconComponent } from './chat-releasing-party-icon/chat-releasing-party-icon.component';
import { ScreenRecordIconComponent } from './screen-record-icon/screen-record-icon.component';
import { CustomerSentimentIconComponent } from './customer-sentiment-icon/customer-sentiment-icon.component';

export * from './conversation-type-icon/conversation-type-icon.component';

export * from './call-direction-icon/call-direction-icon.component';
export * from './call-releasing-party-icon/call-releasing-party-icon.component';
export * from './call-listening-status-icon/call-listening-status-icon.component';

export * from './chat-releasing-party-icon/chat-releasing-party-icon.component';

@NgModule({
  declarations: [
    ConversationTypeIconComponent,
    CallDirectionIconComponent,
    CallReleasingPartyIconComponent,
    CallListeningStatusIconComponent,
    ChatReleasingPartyIconComponent,
    ScreenRecordIconComponent,
    CustomerSentimentIconComponent,
  ],
  imports: [CommonModule, SharedModule, NgbTooltipModule],
  exports: [
    ConversationTypeIconComponent,
    CallDirectionIconComponent,
    CallReleasingPartyIconComponent,
    CallListeningStatusIconComponent,
    ChatReleasingPartyIconComponent,
    ScreenRecordIconComponent,
    CustomerSentimentIconComponent,
  ],
})
export class ConversationIconsModule { }
