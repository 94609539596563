import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { TopicClusterKeywordDto } from './topic-cluster-keyword.dto';
import { TopicUtteranceDto } from './topic-utterance.dto';

@Api('api/app/topic')
export class TopicDto extends DtoHasApi {
  id: number;
  name: string;
  clusterId: number;
  creationTime: Date;
  isActive: boolean;
  updateHasDifference: boolean;
  topicClusterKeywords: TopicClusterKeywordDto[];
  topicUtterances: TopicUtteranceDto[];
}
