import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpStatusCodes } from '../models/http/http-status-codes.model';
import { ValidationErrorMessageModel } from '../models/validation/validation-error-message.model';
import { ToasterService } from '@abp/ng.theme.shared';
import { ErrorService } from '../services/error/error.service';
import { LocalizationService } from '@abp/ng.core';
import { ToastrConstants } from '../constants/toastr.constant';

@Injectable()
export class HttpErrorResponseInterceptor implements HttpInterceptor {
  urlsToNotUse: Array<string>;
  constructor(
    private toastr: ToasterService,
    private errorService: ErrorService,
    private localizationService: LocalizationService
  ) {
    this.urlsToNotUse = ['connect/token'];
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('hide-errors')) {
      let headers = req.headers.delete('hide-errors');

      req = req.clone({ headers: headers });

      return next.handle(req);
    } else if (this.isValidRequestForInterceptor(req.url)) {
      return next.handle(req).pipe(
        catchError((response: HttpErrorResponse) => {
          let errors: ValidationErrorMessageModel[] = [];
          let toastrType: string;

          try {
            errors = response.error.error.validationErrors;
          } catch (e) {
            errors = [];
          }
          try {
            toastrType = response.error.error?.data?.toastrType;
          } catch (e) {
            toastrType = null;
          }
          if (response.status === HttpStatusCodes.badRequest && errors.length > 0) {
            const message = this.createValidationMessage(errors);

            this.showToastr(message, 'Validation Error', toastrType);
          } else if (response.status === HttpStatusCodes.forbidden && response.error != null) {
            if (response.error.error?.data?.toastr === false) {
              return throwError(response);
            }
            this.showToastr(response.error.error.message, null, toastrType);
          } else if (
            response.status === HttpStatusCodes.unprocessableEntity &&
            response.error != null
          ) {
            if (response.error.error?.data?.toastr === false) {
              return throwError(response);
            }
            this.showToastr(response.error.error.message, null, toastrType);
          } else if (response.status === HttpStatusCodes.notFound && response.error != null) {
            if (req.headers.has('redirect-not-found-page')) {
              this.errorService.showError(404);
            } else {
              if (response.error.error?.data?.toastr === false) {
                return throwError(response);
              }
              this.showToastr(response.error.error.message, null, toastrType);
            }
          } else if (response.status === HttpStatusCodes.unauthorized && response.error != null) {
            this.errorService.showError(response.status);
          } else if (response.status === HttpStatusCodes.tooManyRequest && response.error != null) {
            this.showToastr(
              this.localizationService.instant('::TooManyRequestErrorMessage'),
              this.localizationService.instant('::TooManyRequestError'),
              toastrType
            );
          } else {
            this.showToastr(
              'An unexpected error has occured. Please try again.',
              'Server Error',
              toastrType
            );
          }
          return throwError(response);
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private showToastr(message: string, title: string, type: string) {
    if (type == ToastrConstants.warning) {
      this.toastr.warn(message, title ?? this.localizationService.instant('::Warning'));
    } else if (type == ToastrConstants.info) {
      this.toastr.info(message, title ?? this.localizationService.instant('::Information'));
    } else if (type == ToastrConstants.success) {
      this.toastr.success(message, title ?? this.localizationService.instant('::Success'));
    } else if (type != ToastrConstants.hidden) {
      this.toastr.error(message, title ?? this.localizationService.instant('::Error'));
    }
  }

  private createValidationMessage(errors: ValidationErrorMessageModel[]): string {
    let result = '';

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < errors.length; i++) {
      result += errors[i].message + '<br/>';
    }

    return result;
  }
  private isValidRequestForInterceptor(requestUrl: string): boolean {
    for (const address of this.urlsToNotUse) {
      const position = requestUrl.indexOf(address);
      if (position > -1) {
        return false;
      }
    }
    return true;
  }
}
