import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { ConversationListeningStatus } from 'src/core/models/generic-lookup-type/conversation/conversation-listening-status.glt';

@Component({
  selector: 'ca-call-listening-status-icon',
  templateUrl: './call-listening-status-icon.component.html',
  styleUrls: ['./call-listening-status-icon.component.scss', '../conversation-icons.scss'],
})
export class CallListeningStatusIconComponent implements OnInit {
  @Input()
  listeningStatusId: number | null;

  get iconCls(): string {
    let result = '';

    switch (this.listeningStatusId) {
      case ConversationListeningStatus.notListenedBefore:
        result = 'fas fa-deaf';
        break;
      case ConversationListeningStatus.listenedBeforeByYou:
        result = 'fas fa-check';
        break;
      case ConversationListeningStatus.listenedBefore:
        result = 'fas fa-head-side-headphones';
        break;
      default:
        result = 'fas fa-question';
    }

    return result;
  }

  get tooltip(): string {
    let result = '';

    switch (this.listeningStatusId) {
      case ConversationListeningStatus.notListenedBefore:
        result = `${this.localizationService.instant(
          'Conversation::ListeningStatus'
        )}: ${this.localizationService.instant(
          'GenericLookup::Conversation.Listening.Status.ConversationNotListenedBefore'
        )}`;
        break;
      case ConversationListeningStatus.listenedBeforeByYou:
        result = `${this.localizationService.instant(
          'Conversation::ListeningStatus'
        )}: ${this.localizationService.instant(
          'GenericLookup::Conversation.Listening.Status.ConversationListenedBeforeByYou'
        )}`;
        break;
      case ConversationListeningStatus.listenedBefore:
        result = `${this.localizationService.instant(
          'Conversation::ListeningStatus'
        )}: ${this.localizationService.instant(
          'GenericLookup::Conversation.Listening.Status.ConversationListenedBefore'
        )}`;
        break;
      default:
        result = `${this.localizationService.instant(
          'Conversation::ListeningStatus'
        )}: ${this.localizationService.instant('Query::Unknown')}`;
    }

    return result;
  }

  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void { }
}
