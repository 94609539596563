import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'ActionLog.ActionType',
})
export class ActionLogActionType {
  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.Create',
  })
  static conversationCreate: number;

  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.AnalysisStart',
  })
  static conversationAnalysisStart: number;

  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.AnalysisComplete',
  })
  static conversationAnalysisComplete: number;

  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.IndexStart',
  })
  static conversationIndexStart: number;

  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.IndexComplete',
  })
  static conversationIndexComplete: number;

  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.AutomaticEvaluationStart',
  })
  static conversationAutomaticEvaluationStart: number;

  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.AutomaticEvaluationComplete',
  })
  static conversationAutomaticEvaluationComplete: number;

  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.TextCorrectionComplete',
  })
  static conversationTextCorrectionComplete: number;
  
  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.TranscriptEdited',
  })
  static conversationTranscriptEdited: number;
  
  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.Viewed',
  })
  static conversationViewed: number;
  
  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.Downloaded',
  })
  static conversationDownloaded: number;
  
  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.TagAdded',
  })
  static conversationTagAdded: number;
  
  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.TagRemoved',
  })
  static conversationTagRemoved: number;
  
  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.RedactedDataViewed',
  })
  static conversationRedactedDataViewed: number;
  
  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.PriorForEvaluationAdded',
  })
  static conversationPriorForEvaluationAdded: number;
  
  @GenericLookup({
    code: 'ActionLog.ActionType.Conversation.NotSuitableForEvaluationAdded',
  })
  static conversationNotSuitableForEvaluationAdded: number;
}
