import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.Media.Type',
})
export class ConversationMediaType {
  @GenericLookup({
    code: 'Conversation.Media.Type.VoiceRecording',
  })
  static voiceRecording: number;

  @GenericLookup({
    code: 'Conversation.Media.Type.ScreenRecording',
  })
  static screenRecording: number;

  @GenericLookup({
    code: 'Conversation.Media.Type.VideoRecording',
  })
  static videoRecording: number;

  @GenericLookup({
    code: 'Conversation.Media.Type.ChatRecording',
  })
  static chatRecording: number;
}
