<div class="row entry-row submenu-row">
  <div class="col-auto">
    <h1 class="content-header-title">
      {{ 'Conversation::Conversations' | abpLocalization }}
    </h1>
  </div>
  <div class="col-md-auto pl-md-0">
    <ol class="breadcrumb">
      <li class="breadcrumb-item pointer">
        <a [routerLink]="" (click)="goBack()">
          <i class="fas fa-arrow-circle-left"></i>
          {{ backActionText }}
        </a>
      </li>
      <li class="breadcrumb-item active">
        {{ 'Conversation::Conversation' | abpLocalization }} #{{ id }}
      </li>
    </ol>
  </div>
  <div class="col">
    <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
      <div class="ca-toolbar-west-section">
        <div class="toolbar-item first">
          <button
            class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
            (click)="openDeleteConversationModal()"
            *ngIf="canDeleteConversation">
            <i class="far fa-trash-alt"></i>
            <span>{{ 'AbpUi::Delete' | abpLocalization }}</span>
          </button>
        </div>
        <div class="toolbar-item">
          <ca-evaluation-selector
            *ngIf="canEvaluateConversation"
            class="toolbar-item"
            [buttonDisabled]="!conversation?.isSuitableForEvaluation"
            [conversationId]="id"
            [isAnalysed]="isAnalysed"
            [player]="getPlayer()"
            [activeTab]="getActiveTab()"
            [conversationType]="conversation?.typeId">
          </ca-evaluation-selector>
        </div>
        <div class="toolbar-item">
          <ca-conversation-mark-as [conversation]="conversation"></ca-conversation-mark-as>
        </div>
      </div>
      <div class="ca-toolbar-east-section">
        <div class="toolbar-item" *ngIf="enableQuickNavigation">
          <button
            class="btn ca-btn ca-btn-neutral-toolbar toolbar-button previous-button"
            (click)="onClickPreviousConversation()">
            <i class="far fa-angle-left"></i>
            <span>{{ '::Previous' | abpLocalization }}</span>
          </button>
        </div>
        <div
          class="toolbar-item last"
          *ngIf="enableQuickNavigation"
          (click)="onClickNextConversation()">
          <button class="btn ca-btn ca-btn-neutral-toolbar toolbar-button reverse next-button">
            <span>{{ '::Next' | abpLocalization }}</span>
            <i class="far fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row split-panel-container full-height main-container">
  <ca-call-detail-tab-panel
    #callDetailTabPanel
    *ngIf="isCall"
    class="detail-tab-panel"
    [categories]="categories"
    [conversation]="conversation"
    (addCommentRequested)="onAddCommentRequest($event)"></ca-call-detail-tab-panel>

  <ca-chat-detail-tab-panel
    #chatDetailTabPanel
    *ngIf="isChat"
    class="detail-tab-panel"
    [conversation]="conversation"
    [categories]="categories"
    (addCommentRequested)="onAddCommentRequest($event)"></ca-chat-detail-tab-panel>

  <ca-meeting-detail-tab-panel
    #meetingDetailTabPanel
    *ngIf="isMeeting"
    class="detail-tab-panel"
    [conversation]="conversation"></ca-meeting-detail-tab-panel>

  <ca-video-call-detail-tab-panel
    #videoCallDetailTabPanel
    *ngIf="isVideoCall"
    class="detail-tab-panel"
    [conversation]="conversation"
    (addCommentRequested)="onAddCommentRequest($event)"></ca-video-call-detail-tab-panel>

  <ca-email-detail-tab-panel
    #emailDetailTabPanel
    *ngIf="isEmail"
    class="detail-tab-panel"
    [conversation]="conversation"
    (addCommentRequested)="onAddCommentRequest($event)"></ca-email-detail-tab-panel>
</div>
<ng-template #conversationDeleteModal let-modal>
  <div class="modal-header conversation-delete-header">
    <div class="confirmation-icon">
      <i class="fa icon fa-exclamation-triangle"></i>
    </div>
    <h1 class="modal-title pt-3">
      {{ 'Conversation::DeleteConversationWarningHeader' | abpLocalization }}
    </h1>
  </div>
  <div class="modal-body">
    <div [innerHTML]="deleteConversationInformationText"></div>
    <input class="confirmation-input mt-2" type="text" [(ngModel)]="deleteConfirmationText" />
  </div>
  <div class="modal-footer">
    <button
      type="button"
      [disabled]="processing"
      (click)="cancelDeleteConversation()"
      class="btn ca-btn ca-btn-neutral toolbar-button">
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
    <button
      type="button"
      (click)="deleteConversation()"
      [disabled]="processing || conversationDeleteDisabled"
      class="btn ca-btn ca-btn-primary">
      <ng-container *ngIf="!processing"
        ><i class="far fa-trash-alt"></i
        ><span>{{ 'AbpUi::Delete' | abpLocalization }}</span></ng-container
      >
      <ng-container *ngIf="processing"
        ><i class="fas fa-spinner fa-spin"></i>
        <span>{{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span></ng-container
      >
    </button>
  </div>
</ng-template>

<ng-template #selectionTemplate let-selection="selection">
  {{ selection.name.length < 16 ? selection.name : (selection.name | slice : 0 : 12) + '...' }}
</ng-template>
