import { FormControl } from '@angular/forms';

export class StringValidator {
  static whitespace(fc: FormControl) {
    if (!fc.value) {
      return null;
    }

    if (fc.value.startsWith(' ') || fc.value.endsWith(' ')) {
      return { whiteSpaceNotAllowed: true };
    }

    return null;
  }

  static whitespaceHtml(fc: FormControl) {
    if (!fc.value) {
      return null;
    }

    if (fc.value.startsWith('&#160;') || fc.value.endsWith('&#160;')) {
      return { whiteSpaceNotAllowed: true };
    }

    return null;
  }

  static allowOnlyLettersOrDigits(fc: FormControl) {
    if (!fc.value) {
      return null;
    }

    var regex = /^[a-zA-ZığüşöçĞÜŞÖÇİ0-9]*$/;

    if (!regex.test(fc.value)) {
      return { alphaNumericStringInvalidPattern: true };
    }
  }

  static allowOnlyLettersOrDigitsOrDots(fc: FormControl) {
    if (!fc.value) {
      return null;
    }

    var regex = /^[a-zA-ZığüşöçĞÜŞÖÇİ.0-9]*$/;

    if (!regex.test(fc.value)) {
      return { alphaNumericAndDotsStringInvalidPattern: true };
    }
  }

  static json(fc: FormControl) {
    if (!fc.value) {
      return null;
    }

    try {
      JSON.parse(fc.value);
    }
    catch (error) {
      return { notAValidJson: true };
    }

    return null;
  }
}
