import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { FormBuilder } from '@angular/forms';
import { UserListSortOption } from 'src/core/models/generic-lookup-type/identity/user-list-sort-option.glt';
import { Operators } from 'src/core/models/request/operator.enum';

@Component({
  selector: 'ca-user-list-toolbar',
  templateUrl: './user-list-toolbar.component.html',
  styleUrls: ['./user-list-toolbar.component.scss'],
})
export class UserListToolbarComponent implements OnInit {
  @Output()
  addNewClick = new EventEmitter();

  @Output()
  isActiveFilterChangeRequest: EventEmitter<{
    includePassiveUsers: boolean;
  }> = new EventEmitter();

  toolbarForm = null;

  doSearch() {
    this.onIncludePassiveUsersChange();
  }

  addNew(eventArgs) {
    this.addNewClick.emit();
  }

  constructor(private fb: FormBuilder, private store: Store, private operators: Operators) {
    this.toolbarForm = this.fb.group({
      includePassiveUsers: [false],
      sort: [UserListSortOption.usernameAscending],
    });
  }

  onIncludePassiveUsersChange() {
    const val: boolean = this.toolbarForm.get('includePassiveUsers').value;
    this.isActiveFilterChangeRequest.emit({
      includePassiveUsers: val,
    });
  }

  ngAfterViewInit() {}
  ngOnInit() {}
}
