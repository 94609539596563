<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div class="row m-0 align-items-center full-height">
      <div
        *ngIf="payload.sideId !== conversationSideAny; else emptySpace"
        class="col-auto"
        ngbTooltip="{{ '::Channel' | abpLocalization }}"
        container="body"
        placement="bottom">
        <label>{{ 'GenericLookup::' + currentConversationSide | abpLocalization }}</label>
        <label>:</label>
      </div>
      <ng-template #emptySpace class="col-auto">
        <label>&nbsp;</label>
      </ng-template>
      <div
        class="col-auto"
        ngbTooltip="{{ '::Term' | abpLocalization }}"
        container="body"
        placement="left auto">
        <label>"</label>
        <label>{{ payload.term }}</label>
        <label *ngIf="payload.operator === operators.Contains">
          {{ payload.operator | operator }}
        </label>
        <label>"</label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div
        class="col-auto"
        ngbTooltip="{{ '::Term' | abpLocalization }}"
        container="body"
        placement="bottom">
        <input
          *ngIf="!queryBuilder.isPhraseSuggestionSupported"
          type="text"
          class="form-control form-control-sm"
          formControlName="term" />
        <ca-phrase-suggestion-dropdown-selector
          *ngIf="queryBuilder.isPhraseSuggestionSupported"
          formControlName="term"
          [languageCode]="queryBuilder.languageCode"
          [class]="'form-control form-control-sm'"
          [inputPhrases$]="inputPhrases$"
          (phraseRequest)="updateInputPhrases()"></ca-phrase-suggestion-dropdown-selector>
      </div>
      <div class="col-auto">
        <ca-conversation-side-selector
          formControlName="conversationSide"
          [(ngModel)]="payload.sideId"></ca-conversation-side-selector>
      </div>
      <div class="col-auto" *ngIf="isLastEditor">
        <button
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          ngbTooltip="{{ '::AddNewNode' | abpLocalization }}"
          container="body"
          placement="bottom"
          (click)="onAddClick($event)">
          <i class="far fa-plus-circle"></i>
        </button>
      </div>
      <ca-query-builder-node-remove-button
        *ngIf="!isFirstTwoEditor"
        (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>
