import { Injectable } from '@angular/core';
import { QueryFieldDataType } from '../models/query/query-field-data-type.enum';

@Injectable({
  providedIn: 'root',
})
export class CAConstants {
  static readonly SEARCH_INPUT_MIN_LENGTH: number = 2;
  static readonly SEARCH_INPUT_DELAY: number = 500;
}
