import { ConfigStateService } from '@abp/ng.core';
import { RoleConstants } from '../constants/role.constant';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class RoleHelper {
  constructor(private config: ConfigStateService) {}

  isSuperAdminUser(): boolean {
    let roles = this.config.getDeep('currentUser.roles');
    return roles.includes(RoleConstants.admin);
  }
}
