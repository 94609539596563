import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { UserGroupDepartmentDto } from './user-group-department.dto';
import { UserGroupInteractionDto } from './user-group-interactions.dto';
import { UserGroupUserDto } from './user-group-user.dto';

@Api('api/app/user-group')
export class UserGroupDto extends DtoHasApi {
  id: number;
  name: string;
  isActive: boolean;
  lastModificationTime: Date;
  userFilterModeId: number;
  departmentFilterModeId: number;
  userGroupUsers: UserGroupUserDto[];
  userGroupDepartments: UserGroupDepartmentDto[];
  userGroupInteractions: UserGroupInteractionDto[];
}
