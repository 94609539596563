<ng-template #queryItemTemplate let-item="item">
  {{ item.name }}
</ng-template>
<ng-template #querySelectionTemplate let-selection="selection">
  {{ selection[0].name }}
</ng-template>

<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ '::Query' | abpLocalization }}</label>
      </div>

      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ payload.operator | operator }}</label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ currentQueryName }}</label>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #editableContent>
  <div [class]="cssClass">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ '::Query' | abpLocalization }}</label>
      </div>
      <div class="col-auto">
        <select class="form-select form-select-sm" [(ngModel)]="payload.operator">
          <option *ngFor="let operator of node.categoryItem.operators" [value]="operator">
            {{ operator | operator }}
          </option>
        </select>
      </div>

      <div class="col-auto" [formGroup]="queryForm">
        <ca-user-defined-category-selector
          id="querySelector"
          formControlName="query"
          style="width: 350px"
          class="d-block"
          [disableFn]="disableFn"
          [headerInfo]="headerInfo"
          [headerInfoStyle]="headerInfoStyle"></ca-user-defined-category-selector>
        <!--
        <ca-dropdown-selector
          id="querySelector"
          #querySelector
          [buttonCls]="dropdownButtonCls"
          [url]="queryDropdownOptions.url"
          [filters]="filters"
          [pageSize]="queryDropdownOptions.pageSize"
          [(ngModel)]="query"
          [itemTemplate]="queryItemTemplate"
          [selectionTemplate]="querySelectionTemplate"
          [queryOperator]="queryDropdownOptions.queryOperator"
          [queryField]="queryDropdownOptions.queryField"
          [emptyText]="queryDropdownOptions.emptyText"
          [multiple]="queryDropdownOptions.multiple"
          [idProperty]="'id'"
          [disabledProperty]="queryDropdownOptions.disabledProperty"
          [disabledValue]="queryDropdownOptions.disabledValue"
          [information]="queryDropdownOptions.information"
          formControlName="query"
          (changed)="onChange()"
          [styles]="{ width: '100%', 'border-width': '0px' }"
          ngbTooltip="{{ '::RequiredField' | abpLocalization }}"
          container="body"
          [disableTooltip]="node.validationStatus"
          skipValidation
        >
        </ca-dropdown-selector>
        -->
      </div>

      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>
