import { EventEmitter, Output, Input } from '@angular/core';
import {
  HubConnectionBuilder,
  HubConnectionState,
  HubConnection,
  LogLevel,
  HttpTransportType,
} from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HubServiceConfigModel } from 'src/core/models/signalr/hub-service-config.model';
import { AuthorizationService } from '../auth/authorization.service';
@Injectable({
  providedIn: 'root',
})
@Injectable()
export class HubService {
  private _hubConnection: HubConnection;
  private _config: HubServiceConfigModel;
  private hubConnectionState: BehaviorSubject<HubConnectionState>;

  @Input()
  set config(config: HubServiceConfigModel) {
    this._config = config;
    this.authorizationServive.getAccessKey().subscribe(key => {
      this.initializeHub();
    });
  }

  @Output()
  messageRetrieved: EventEmitter<{
    data: any;
  }> = new EventEmitter();
  @Output()
  messageInvoked: EventEmitter<{
    data: any;
  }> = new EventEmitter();

  constructor(private authorizationServive: AuthorizationService) {
    this.hubConnectionState = new BehaviorSubject<HubConnectionState>(
      HubConnectionState.Disconnected
    );
  }

  initializeHub() {
    this.stopConnection();
    this.buildConnection();
    this.startConnection();
    this._hubConnection.onclose(connection => {
      this.setConnectionState(this._hubConnection.state);
    });
    this._hubConnection.onreconnecting(connection => {
      this.setConnectionState(this._hubConnection.state);
    });
    this._hubConnection.onreconnected(connection => {
      this.setConnectionState(this._hubConnection.state);
    });
  }

  getConnectionState(): Observable<HubConnectionState> {
    if (this.hubConnectionState != null) {
      return this.hubConnectionState;
    }
  }

  setConnectionState(newValue): void {
    this.hubConnectionState.next(newValue);
  }

  private buildConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withUrl(this._config.url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([0, 2000, 10000, 30000, 60000])
      .build();
  }

  private startConnection() {
    this._hubConnection
      .start()
      .then(() => {
        this.setConnectionState(this._hubConnection.state);
        this.registerMessageRetrievedEvent();
      })
      .catch(err => console.error('SignalR startup error:' + err));
  }

  private registerMessageRetrievedEvent() {
    if (this._config.retrieveMethod) {
      this._hubConnection.on(this._config.retrieveMethod, (data: any) => {
        this.messageRetrieved.emit(data);
      });
    }
  }

  publishMessage(data: any) {
    if (this._hubConnection.state == HubConnectionState.Connected) {
      this._hubConnection.invoke(this._config.publishMethod, data).then(t => {
        this.messageInvoked.emit(t);
      });
    }
  }

  reconnect() {
    this.startConnection();
  }

  stopConnection() {
    if (this._hubConnection) {
      this._hubConnection.stop();
    }
  }

  getConnection(): HubConnection {
    return this._hubConnection;
  }
}
