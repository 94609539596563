<div class="icon-container">
  <ca-conversation-type-icon [typeId]="conversation.typeId"></ca-conversation-type-icon>
  <ng-container *ngIf="conversation.typeId === conversationType.call">
    <ca-call-direction-icon [directionId]="conversation.call.directionId"></ca-call-direction-icon>
    <ca-call-releasing-party-icon
      [releasingPartyId]="conversation.call.releasingPartyId"></ca-call-releasing-party-icon>
    <ca-call-listening-status-icon
      *ngIf="!inDetail"
      [listeningStatusId]="conversation.listeningStatusId"></ca-call-listening-status-icon>
  </ng-container>
  <ng-container *ngIf="conversation.typeId === conversationType.chat">
    <ca-chat-releasing-party-icon
      [releasingPartyId]="conversation.chat.releasingPartyId"></ca-chat-releasing-party-icon>
  </ng-container>
  <ca-customer-sentiment-icon
    *ngIf="conversation.typeId !== conversationType.email && sentimentFeatureEnabled && conversation.averageCustomerSentimentScore !== null"
    [score]="conversation.averageCustomerSentimentScore"></ca-customer-sentiment-icon>
  <ca-screen-record-icon
    *ngIf="!inDetail"
    [hasScreenRecording]="conversation.hasScreenRecording"></ca-screen-record-icon>
</div>
