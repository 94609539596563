import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class IntegerValidator {

  static minMax(min: number | null, max: number | null): ValidatorFn {
    return (fc: FormControl): ValidationErrors | null => {
      const value = fc.value;
      if (Math.round(Number(value)) !== value) {
        fc.setValue(Math.round(Number(value)));
      }
      if (value < min) {
        fc.setValue(min);
      } else if (max && value > max) {
        fc.setValue(max);
      }
      return null;
    };
  }
}
