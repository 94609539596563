import { Injectable } from '@angular/core';
import { AsyncValidator, ValidationErrors, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserGroupService } from 'src/core/services/user-group/user-group.service';

@Injectable({ providedIn: 'root' })
export class UserGroupNameValidator implements AsyncValidator {
  id: number | null;

  constructor(private service: UserGroupService) {}

  validate(
    formControl: FormControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const id = this.id == null ? null : this.id;
    const value = formControl.value;

    return this.service.getByName(value).pipe(
      map(
        response => {
          return response !== null && response.id !== +id ? { userGroupNameInUse: true } : null;
        },
        catchError(() => {
          return null;
        })
      )
    );
  }
}
