<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label>{{ '(' + ('Query::OrderedQueryShortText' | abpLocalization) + ')' }}</label>
      </div>
      <!-- <div class="col-auto">
        <label>{{ '::TimeRange' | abpLocalization }}:&nbsp; </label>
      </div>
      <div
        class="col-auto"
        ngbTooltip=" {{
          currentQueryIntervalType === QueryIntervalType.All
            ? ('Query::IntervalQueryAll' | abpLocalization : RANGE_SECONDS)
            : currentQueryIntervalType === QueryIntervalType.Opening
            ? getOpeningIntervalTooltip()
            : getClosingIntervalTooltip()
        }}">
        <label *ngIf="currentQueryIntervalType === QueryIntervalType.All">{{
          '::All' | abpLocalization
        }}</label>
        <label *ngIf="currentQueryIntervalType === QueryIntervalType.Opening">{{
          'Conversation::QueryBuilder:OpeningInterval' | abpLocalization
        }}</label>
        <label *ngIf="currentQueryIntervalType === QueryIntervalType.Closing">{{
          'Conversation::QueryBuilder:ClosingInterval' | abpLocalization
        }}</label>
      </div> -->
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div class="form-check-input-wrapper" [class]="cssClass" [formGroup]="queryForm">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label>{{ 'Query::OrderedQueryShortText' | abpLocalization }}</label>
      </div>
      <!-- <div class="col-auto radio-container" container="body" placement="bottom">
        <div
          ngbTooltip="{{ 'Query::IntervalQueryAll' | abpLocalization }}"
          container="body"
          placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioAll_' + payload.internalId"
            #radioOpening
            value="{{ QueryIntervalType.All }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioAll_' + payload.internalId" class="radio-label">{{
            '::All' | abpLocalization
          }}</label>
        </div>
        <div
          style="margin-left: 12px"
          ngbTooltip="{{ getOpeningIntervalTooltip() }}"
          container="body"
          placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioOpening_' + payload.internalId"
            #radioOpening
            value="{{ QueryIntervalType.Opening }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioOpening_' + payload.internalId" class="radio-label">{{
            'Conversation::QueryBuilder:OpeningInterval' | abpLocalization
          }}</label>
        </div>
        <div
          style="margin-left: 12px"
          ngbTooltip="{{ getClosingIntervalTooltip() }}"
          container="body"
          placement="top auto">
          <input
            type="radio"
            class="radio-input"
            [attr.id]="'radioClosing_' + payload.internalId"
            #radioClosing
            value="{{ QueryIntervalType.Closing }}"
            formControlName="intervalType" />
          <label [attr.for]="'radioClosing_' + payload.internalId" class="radio-label">{{
            'Conversation::QueryBuilder:ClosingInterval' | abpLocalization
          }}</label>
        </div>
      </div> -->
      <div style="gap: 8px" class="col-auto d-flex flex-row justify-content-center">
        <button
          class="btn btn-sm ca-btn ca-btn-neutral-command"
          (click)="onClickDuplicate($event)"
          ngbTooltip="{{ '::Duplicate' | abpLocalization }} (Shift + Enter)"
          container="body"
          placement="bottom">
          <i class="far fa-copy"></i>
        </button>
        <ca-query-builder-node-remove-button
          (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
      </div>
    </div>
  </div>
</ng-template>
