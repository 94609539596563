import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Call.AgentChannel'
})
export class CallAgentChannel {
    @GenericLookup({
        code: 'Call.AgentChannel.Left'
    })
    static left: number;

    @GenericLookup({
        code: 'Call.AgentChannel.Right'
    })
    static right: number;
}
