import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { CallReleasingParty } from 'src/core/models/generic-lookup-type/call/call-releasing-party.glt';

@Component({
  selector: 'ca-chat-releasing-party-icon',
  templateUrl: './chat-releasing-party-icon.component.html',
  styleUrls: ['./chat-releasing-party-icon.component.scss', '../conversation-icons.scss'],
})
export class ChatReleasingPartyIconComponent implements OnInit {
  @Input()
  releasingPartyId: number | null;

  get iconCls(): string {
    let result = '';

    switch (this.releasingPartyId) {
      case CallReleasingParty.agent:
        result = 'fas fa-headset';
        break;
      case CallReleasingParty.customer:
        result = 'fas fa-user';
        break;
      case CallReleasingParty.unknown:
        result = 'fas fa-question';
        break;
      case CallReleasingParty.transfer:
        result = 'fas fa-random';
        break;
      default:
        result = 'fas fa-question';
    }

    return result;
  }

  get tooltip(): string {
    let result = '';

    switch (this.releasingPartyId) {
      case CallReleasingParty.agent:
        result = `${this.localizationService.instant(
          'Conversation::ReleasingParty'
        )}: ${this.localizationService.instant('GenericLookup::Call.ReleasingParty.Agent')}`;
        break;
      case CallReleasingParty.customer:
        result = `${this.localizationService.instant(
          'Conversation::ReleasingParty'
        )}: ${this.localizationService.instant('GenericLookup::Call.ReleasingParty.Customer')}`;
        break;
      case CallReleasingParty.unknown:
        result = `${this.localizationService.instant(
          'Conversation::ReleasingParty'
        )}: ${this.localizationService.instant('GenericLookup::Call.ReleasingParty.Unknown')}`;
        break;
      case CallReleasingParty.transfer:
        result = `${this.localizationService.instant(
          'Conversation::ReleasingParty'
        )}: ${this.localizationService.instant('GenericLookup::Call.ReleasingParty.Transfer')}`;
        break;
      case CallReleasingParty.customData:
        result = `${this.localizationService.instant(
          'Conversation::ReleasingParty'
        )}: ${this.localizationService.instant('GenericLookup::Call.ReleasingParty.CustomData')}`;
        break;
      default:
        result = `${this.localizationService.instant(
          'Conversation::ReleasingParty'
        )}: ${this.localizationService.instant('Query::Unknown')}`;
    }

    return result;
  }

  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void { }
}
