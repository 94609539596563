<div class="main-container">
  <div class="row pb-2">
    <div class="col-3">
      <ca-user-photo
        [useCurrentUser]="false"
        [userId]="conversation?.userId"
        [alt]="'user'"
        [classes]="['user-avatar']"
        [styles]="userPhotoComponentStyles">
      </ca-user-photo>
    </div>
    <div class="col-8 summary-label-container">
      <div class="summary-label user-full-name-label">{{ conversation?.userName }}</div>
      <div class="summary-label department-label">{{ conversation?.departmentName }}</div>
      <div class="summary-label conversation-id-label">{{ conversation?.id }}</div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="d-flex flex-row">
    <span class="col-4"></span>
    <ca-conversation-icon-container
      class="container-class col-8"
      [conversation]="conversation"
      [inDetail]="true"></ca-conversation-icon-container>
  </div>
  <mat-divider></mat-divider>
  <div class="row p-2 text-start">
    <span class="col-4 bold mb-1">{{ '::Subject' | abpLocalization }}</span>
    <span
      class="col-8 truncated"
      [ngbTooltip]="getEmailText(conversation?.email?.subject)"
      showTooltipIfTruncated
      container="body">
      {{ conversation?.email?.subject ? conversation?.email?.subject : '-' }}
    </span>

    <span class="col-4 bold mb-1">{{ '::Time' | abpLocalization }}</span>
    <span class="col-8 truncated">{{ conversationTime }}</span>

    <span class="col-4 bold mb-1">{{ '::Source' | abpLocalization }}</span>
    <span class="col-8 truncated">{{ conversation?.conversationSourceName }}</span>

    <span class="col-4 bold mb-1">
      {{ 'Conversation::ExternalId' | abpLocalization }}
    </span>
    <span class="col-8 extId-container">
      <span class="item">{{ conversation?.externalId }}</span>
      <span [hidden]="!conversation?.externalId">
        <i
          class="fas fa-copy text-primary clipboard-link"
          [cdkCopyToClipboard]="conversation?.externalId"
          (click)="copyToClipboard()"
          title="{{ 'Conversation::CopyExternalIdtoClipboard' | abpLocalization }}"></i>
      </span>
    </span>

    <span class="col-4 bold mb-1">{{ '::From' | abpLocalization }}</span>
    <span
      class="col-8 truncated"
      [ngbTooltip]="getEmailText(conversation?.email?.from)"
      showTooltipIfTruncated
      container="body">
      {{ conversation?.email?.from ? conversation?.email?.from : '-' }}
    </span>

    <span class="col-4 bold mb-1">{{ '::To' | abpLocalization }}</span>
    <span
      class="col-8 truncated"
      [ngbTooltip]="getEmailText(conversation?.email?.to)"
      showTooltipIfTruncated
      container="body">
      {{ conversation?.email?.to ? conversation?.email?.to : '-' }}
    </span>

    <span class="col-4 bold mb-1">{{ '::Cc' | abpLocalization }}</span>
    <span
      class="col-8 truncated"
      [ngbTooltip]="getEmailText(conversation?.email?.cc)"
      showTooltipIfTruncated
      container="body">
      {{ conversation?.email?.cc ? conversation?.email?.cc : '-' }}</span
    >
  </div>
</div>
