import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationTagDto } from 'src/core/models/conversation/tag/conversation-tag.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { ConversationMarkAsCommunicationService } from '../conversation-mark-as-communication.service';

@Component({
  selector: 'ca-conversation-mark-as-detail',
  templateUrl: './conversation-mark-as-detail.component.html',
  styleUrls: ['./conversation-mark-as-detail.component.scss'],
})
export class ConversationMarkAsDetailComponent implements OnInit {
  @Input()
  conversation: ConversationDto;

  @Output()
  evaluableMarkChanged = new EventEmitter();

  get displayedTagCount(): number {
    var count = 3;
    if (this.conversation) {
      if (this.conversation.isPriorForEvaluation) {
        count--;
      }

      if (!this.conversation.isSuitableForEvaluation) {
        count--;
      }
    }
    return count;
  }

  constructor(
    private conversationService: ConversationService,
    private conversationMarkAsCommunicationService: ConversationMarkAsCommunicationService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit(): void {}

  onDeleteClick(selectedTag: ConversationTagDto) {
    this.conversationMarkAsCommunicationService.removeTag(this.conversation, selectedTag);
  }

  onDeleteEvaluableMarkClick() {
    this.conversationService.clearMark(this.conversation.id).subscribe(() => {
      this.conversation.isPriorForEvaluation = false;
      this.conversation.isSuitableForEvaluation = true;
      this.conversation.notSuitableForEvaluationReasons = [];
      this.evaluableMarkChanged.emit();
      this.toastr.success(this.localizationService.instant('::SuccessfullySaved'));
    });
  }

  displayedTags(): ConversationTagDto[] {
    return this.conversation && this.conversation.conversationTags
      ? this.conversation.conversationTags.slice(0, this.displayedTagCount)
      : [];
  }

  remainingTags(): ConversationTagDto[] {
    return this.conversation && this.conversation.conversationTags
      ? this.conversation.conversationTags.slice(
          this.displayedTagCount,
          this.conversation.conversationTags.length
        )
      : [];
  }
}
