import { Component, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SplitConfig } from 'src/core/models/split/split-config.model';
import { UserListComponent } from '../user-list/user-list.component';
import { LayoutService } from 'src/core/services/layout/layout.service';
import { CALayoutState } from 'src/core/states/layout/ca-layout.state';
import { Sections } from 'src/core/models/administration/section.enum';
import { LayoutStateModel } from 'src/core/models/layout/layout.state-model';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPanelConfigurationModel } from 'src/ca-shared/filter-panel/filter-panel.module';
import { LayoutConfig } from 'src/core/models/split/layout-config.model';
import { LayoutChange } from 'src/core/actions/layout/layout-actions';
import { UserListFilterPanelComponent } from '../user-list-filter-panel/user-list-filter-panel.component';

@Component({
  selector: 'ca-user-main',
  templateUrl: './user-main.component.html',
  styleUrls: ['./user-main.component.scss'],
})
export class UserMainComponent implements OnInit {
  @ViewChild('list', { static: true, read: UserListComponent })
  list: UserListComponent;
  
  @ViewChild('filterPanel', { static: true, read: UserListFilterPanelComponent })
  filterPanel: UserListFilterPanelComponent;

  private layout: LayoutStateModel;
  private wait: boolean = false;

  @Select(CALayoutState.layout(Sections.users))
  layout$: Observable<LayoutStateModel>;

  splitConfig = SplitConfig.DEFAULT;

  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;

  includePassiveUsers: boolean = false;
  lastLoginDateValue:number = null;

  filters: any;

  onOpenDetails(eventArgs: { id: number }) {
    this.router.navigate(['identity/users', eventArgs.id]);
  }

  onIsActiveFilterChangeRequest(eventArgs: { includePassiveUsers: boolean }) {
    this.includePassiveUsers = eventArgs.includePassiveUsers;
    this.onFilterValueChanges(this.filters);
  }

  onAddNewClick(eventArgs) {
    this.router.navigate(['identity/users', 'new']);
  }

  onDetailPanelClose(eventArgs) {
    this.list.clearSelection();

    this.layoutService.changeVisibilityForDetailPanel(Sections.users, false);
  }

  onDragEnd(eventArgs) {
    this.layoutService.onDragEnd(Sections.users, eventArgs);
  }

  onFilterPanelCollapsed(eventArgs: { width: number }) {
    this.layout.config.west.size = eventArgs.width;

    const action = new LayoutChange(Sections.users, this.layout.config);
    this.store.dispatch(action);
  }

  onFilterPanelExpanded(eventArgs) {
    this.layout.config.west.size = LayoutConfig.DEFAULT.west.size;

    const action = new LayoutChange(Sections.users, this.layout.config);
    this.store.dispatch(action);
  }

  onFilterValueChanges(eventArgs: { config: FilterPanelConfigurationModel; values: any }): void {
    this.filters = eventArgs;
    eventArgs.values.includePassives = this.includePassiveUsers;

    if (this.wait) return;

    this.list.loadFromFilterPanel(this.filters);
  }

  constructor(private layoutService: LayoutService,
     private router: Router,
     private route: ActivatedRoute,
     private store: Store) {
    this.layout$.subscribe(x => {
      this.layout = x;
    });
    const lastLoginDateParam = this.route.snapshot.queryParams.lastLoginDate;
    this.lastLoginDateValue = lastLoginDateParam !== undefined ? Number(lastLoginDateParam) : -1;
    this.wait = this.lastLoginDateValue > 0 ? true : false;

    this.layoutService.changeVisibilityForFilterPanel(Sections.users, true);
  }

  ngOnInit() {}
  

  ngAfterViewInit(): void {
    if (this.lastLoginDateValue > 0 && this.filterPanel) {
      setTimeout(() => {
        if (this.lastLoginDateValue > 0 && this.filterPanel) {
          this.filterPanel.clearFilter();
          if (!this.filters.values.lastLoginDate) {
            this.filters.values.lastLoginDate = { id: null };
        }
          this.filters.values.lastLoginDate.id = this.lastLoginDateValue;
          this.filterPanel.setFilterValues(this.filters);
          this.list.loadFromFilterPanel(this.filters);
          this.wait = false;
        }
      }, 500);
    }
  }
}
