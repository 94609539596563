import { Api } from 'src/core/decorators/dto/api.decorator';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';

@Api('api/app/department')
export class DepartmentDto extends DtoHasApi {
  id: number;
  name: string;
  parentId: number | null;
  hasUser: boolean;
  isActive: boolean;
  isIntegrated: boolean;
  hasChildren: boolean;
}
