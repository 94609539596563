<div class="content-container">
  <div class="summary-container">
    <ca-chat-summary
      (categorySelectionChanged)="onCategorySelectionChanged($event)"
      [categories]="categories"
      [conversation]="conversation"></ca-chat-summary>
  </div>
  <div class="center-container">
    <div class="tab-panel-container">
      <ul [destroyOnHide]="false" ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink
            ><i class="fas fa-file-alt"></i
            ><span style="margin-left: 4px">{{
              'Conversation::Transcript' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <p>
              <ca-chat-transcript-panel
                [showEditor]="true"
                (excelExportRequested)="onExcelExportRequest($event)"
                [transcripts]="transcripts"
                (addCommentRequested)="onAddCommentRequest($event)"
                [startTime]="startTime"
                (transcriptSaved)="onTranscriptSaved($event)"
                (transcriptSaveFailed)="onTranscriptSaveFailed()"
                [conversationId]="conversation?.id"></ca-chat-transcript-panel>
            </p>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink
            ><i class="fas fa-paperclip"></i
            ><span style="margin-left: 4px">{{
              'Conversation::QueryBuilder:AttachedData' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-attached-data-panel [conversationId]="conversation?.id"></ca-attached-data-panel>
          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink
            ><i class="fas fa-comments"></i
            ><span style="margin-left: 4px">{{ '::Comment' | abpLocalization }}</span>
            <span class="badge bg-secondary ms-1 mb-1">{{ commentCount }}</span>
          </a>
          <ng-template ngbNavContent>
            <ca-conversation-comment-panel
              #commentPanel
              [conversationId]="conversation?.id"
              (goToCommentRequest)="onGoToComment($event)"
              (commentCountChanged)="onCommentCountChanged($event)"></ca-conversation-comment-panel>
          </ng-template>
        </li>
        <li [ngbNavItem]="5">
          <a ngbNavLink
            ><i class="fas fa-tag"></i
            ><span style="margin-left: 4px">{{
              'Conversation::CategoriesAndTopics' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-chat-category-detail-panel
              [categories]="categories"
              [categoryMarks]="categoryMarks"></ca-chat-category-detail-panel>
          </ng-template>
        </li>
        <li [ngbNavItem]="6">
          <a ngbNavLink
            ><i class="fas fa-random"></i
            ><span style="margin-left: 4px">{{
              'Conversation::Transfer' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-conversation-transfer-tab-panel
              [conversationId]="conversation?.id"
              [relatedGroupId]="conversation?.relatedGroupId"></ca-conversation-transfer-tab-panel>
          </ng-template>
        </li>
        <li *ngIf="qualityManagementFeatureEnabled" [ngbNavItem]="7">
          <a ngbNavLink
            ><i class="fas fa-file-check"></i
            ><span style="margin-left: 4px">{{
              'Conversation::Evaluation' | abpLocalization
            }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-conversation-evaluation-list-panel
              *ngIf="activeTab == 7"
              [conversationId]="conversation?.id"
              [activeTab]="activeTab"></ca-conversation-evaluation-list-panel>
          </ng-template>
        </li>
        <li [ngbNavItem]="8">
          <a ngbNavLink
            ><i class="fas fa-history"></i
            ><span style="margin-left: 4px">{{ 'ActionLog::History' | abpLocalization }}</span></a
          >
          <ng-template ngbNavContent>
            <ca-conversation-history-panel
              *ngIf="activeTab == 8"
              [conversationId]="conversation?.id"
              [activeTab]="activeTab"></ca-conversation-history-panel>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2" style="height: calc(100% - 61px)"></div>
    </div>
    <div class="player-controller-container">
      <ca-player-controller
        [player]="player"
        [collapsible]="false"
        [hideCommentButton]="false"
        [chatMode]="true"
        [showSentimentButtons]="
          !conversation?.call?.isDiarize && analyticsFeatureEnabled && sentimentFetureEnabled
        "
        (addCommentRequested)="onAddCommentRequest($event)"
        #playerController></ca-player-controller>
    </div>
  </div>
</div>
<div class="player-container">
  <ca-player
    #player
    style="width: 100%"
    [fillParent]="true"
    [expandedPlayerHeight]="70"
    [minimizedPlayerHeight]="18"
    [expanded]="true"
    [loadAtOnce]="false"
    [categoryMarks]="categoryMarks"
    [showSentiments]="analyticsFeatureEnabled"
    [conversationMarks]="conversation?.marks"
    [splitChannels]="!conversation?.call?.isDiarize"
    [hideChannelIcons]="conversation?.call?.isDiarize ? true : false"
    [agentChannel]="conversation?.call?.agentChannelId"
    [chatMode]="true"></ca-player>
</div>
