<ng-template #userItemTemplate let-item="item">
  <ca-clickable-link
    class="mt-auto mb-auto truncate-text"
    [innerHtml]="item.username"
    [targetLink]="[item.id]"
    [showTooltipIfTruncated]="true">
  </ca-clickable-link>
</ng-template>

<table mat-table [dataSource]="dataSource" class="fixed-table table-100">
  <ng-container *ngFor="let gridColumn of gridColumns">
    <ng-container
      *ngIf="gridColumn.columnName !== 'username' && gridColumn.columnName !== 'icons'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.minWidth || gridColumn.width"
        [style.maxWidth]="gridColumn.maxWidth || gridColumn.width"
        container="body"
        [ngbTooltip]="gridColumn.tooltip">
        {{ gridColumn.header }}
      </th>
      <td
        mat-cell
        *matCellDef="let user"
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.minWidth || gridColumn.width"
        [style.maxWidth]="gridColumn.maxWidth || gridColumn.width"
        [innerHtml]="user | objectparser : gridColumn"
        container="body"
        [ngbTooltip]="user | objectparser : gridColumn"
        showTooltipIfTruncated></td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'icons'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.minWidth || gridColumn.width"
        [style.maxWidth]="gridColumn.maxWidth || gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td
        mat-cell
        *matCellDef="let user"
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.minWidth || gridColumn.width"
        [style.maxWidth]="gridColumn.maxWidth || gridColumn.width"></td>
    </ng-container>
    <ng-container
      *ngIf="gridColumn.columnName === 'username'"
      matColumnDef="{{ gridColumn.columnName }}">
      <th
        mat-header-cell
        *matHeaderCellDef
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.minWidth || gridColumn.width"
        [style.maxWidth]="gridColumn.maxWidth || gridColumn.width">
        {{ gridColumn.header }}
      </th>
      <td
        mat-cell
        *matCellDef="let user"
        [style.width]="gridColumn.width"
        [style.minWidth]="gridColumn.minWidth || gridColumn.width"
        [style.maxWidth]="gridColumn.maxWidth || gridColumn.width"
        container="body">
        <ca-user-identity
          [id]="user.id"
          [name]="user.name"
          [surname]="user.surname"
          [item]="user"
          [itemTemplate]="userItemTemplate"></ca-user-identity>
      </td>
    </ng-container>
  </ng-container>
  <!-- Info column -->
  <ng-container matColumnDef="info">
    <td
      mat-footer-cell
      *matFooterCellDef
      style="text-align: left"
      [attr.colspan]="displayedColumns.length">
      <span>
        <mat-paginator
          style="display: flex; justify-content: left"
          (page)="pageChanged($event)"
          [pageIndex]="currentPage"
          [length]="totalCount"
          [pageSize]="pageSize"
          class="paginator"
          hidePageSize
          showFirstLastButtons></mat-paginator>
      </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    class="user-row-{{ myRowData.id }}"
    (dblclick)="onRowDoubleClick($event, myRowData.id)"
    *matRowDef="let myRowData; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="['info']; sticky: true"></tr>
</table>
