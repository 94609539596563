<ng-template #popContent let-element="element" let-index="index">
  <ng-container>
    <div class="d-flex flex-column align-items-end">
      <textarea
        [attr.id]="'comment-text-' + index"
        class="form-control question-comment-textarea"></textarea>
      <button
        type="button"
        class="btn ca-btn ca-btn-neutral mt-2 d-flex"
        (click)="onSaveComment(element, index)">
        <i class="far fa-save"></i><span>{{ '::Comment' | abpLocalization }}</span>
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #popTitle let-element="element"
  ><b>{{ '::Comment' | abpLocalization }} {{ element.time }}</b>
</ng-template>

<div class="main">
  <div
    class="p-1 header"
    style="
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-right: 16px !important;
    ">
    <button
      *ngIf="isTranscriptExportVisible"
      type="button"
      (click)="exportTranscript()"
      class="btn ca-btn ca-btn-neutral mr-2 ml-2">
      <i class="far fa-file-excel"></i
      ><span>{{ 'Conversation::ExportTranscript' | abpLocalization }}</span>
    </button>
    <button
      *ngIf="isTranscriptEditorVisible"
      type="button"
      (click)="onEdit()"
      class="btn ca-btn ca-btn-neutral mr-2 ml-2">
      <i class="far fa-pen"></i><span>{{ 'Conversation::EditTranscript' | abpLocalization }}</span>
    </button>
  </div>
  <div class="center">
    <table
      *ngIf="dataTableTranscripts?.length > 0"
      mat-table
      [dataSource]="dataTableTranscripts"
      style="width: 100%"
      aria-describedby="TranscriptTable">
      <!-- Comment Column -->
      <ng-container matColumnDef="comment">
        <th scope="col" style="width: 50px; min-width: 50px" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; let i = index">
          <i
            *ngIf="mouseOverIndex == i"
            #commentPopover="ngbPopover"
            class="far fa-comment-plus"
            [ngbPopover]="popContent"
            [popoverTitle]="popTitle"
            container="body"
            [autoClose]="'outside'"
            triggers="manual"
            (click)="toggleQueryPreviewPopover(commentPopover, element, i)"
            (shown)="onPopoverShown(i)"></i>
        </td>
      </ng-container>

      <!-- StartTime Column -->
      <ng-container matColumnDef="startTime">
        <th scope="col" style="width: 100px; min-width: 100px" mat-header-cell *matHeaderCellDef>
          {{ '::Time' | abpLocalization }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.time }}
        </td>
      </ng-container>

      <!-- Channel Column -->
      <ng-container matColumnDef="channel">
        <th scope="col" style="width: 64px; min-width: 64px" mat-header-cell *matHeaderCellDef>
          {{ '::Channel' | abpLocalization }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            class="fa fa-headphones fa-lg"
            *ngIf="element.channel == 1"
            style="color: rgb(255, 157, 9)"
            title="{{ 'Conversation::Agent' | abpLocalization }}"></span>
          <span
            class="fa fa-user fa-lg"
            *ngIf="element.channel != 1"
            style="color: rgb(111, 168, 220)"
            title="{{ 'Conversation::Customer' | abpLocalization }}"></span>
        </td>
      </ng-container>

      <!-- Chat Column -->
      <ng-container matColumnDef="chat">
        <th scope="col" style="width: 100%; min-width: 100%" mat-header-cell *matHeaderCellDef>
          {{ '::Chat' | abpLocalization }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <ng-container *ngIf="!editMode">
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
              ">
              <ng-container *ngIf="element.channel === 1 || element.channel === 2">
                <div
                  [ngStyle]="{
                    'margin-top': 'auto',
                    'font-style': element.channel === 1 ? 'italic' : 'normal'
                  }">
                  <span
                    #wordContainer
                    [attr.data-time]="(word.startTime / 1000).toFixed(2)"
                    [attr.data-channel]="element.channel"
                    [attr.data-index]="i"
                    *ngFor="let word of element.words"
                    class="word-container">
                    {{ word.word }}
                  </span>
                </div>
              </ng-container>
              <!-- <ng-container *ngIf="element.channel == 1"> *** </ng-container>
              <ng-container *ngIf="element.channel == 2"> *** </ng-container> -->
            </div>
          </ng-container>
          <ng-container *ngIf="editMode">
            <ca-conversation-chat-transcript-editor-input
              (changed)="onTranscriptRowChanged($event, element.channel)"
              [rowInfo]="element"></ca-conversation-chat-transcript-editor-input>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="transcriptDisplayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: transcriptDisplayedColumns; let e = index"
        (mouseover)="onMouseOver(e)"
        (mouseleave)="onMouseOver(-1)"
        [attr.id]="'text-' + row.time"></tr>
    </table>
  </div>
  <div class="footer" *ngIf="editMode">
    <div *ngIf="editMode" class="col p-0">
      <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
        <div class="ca-toolbar-east-section">
          <div class="toolbar-item">
            <button type="button" class="btn ca-btn ca-btn-neutral" (click)="cancelEdit()">
              {{ 'AbpUi::Cancel' | abpLocalization }}
            </button>
          </div>
          <div class="toolbar-item last">
            <button
              class="btn ca-btn ca-btn-primary"
              type="button"
              [disabled]="processing"
              (click)="saveTranscript()">
              <ng-container *ngIf="!processing"
                ><i class="far fa-save"></i><span>{{ 'AbpUi::Save' | abpLocalization }}</span>
              </ng-container>
              <ng-container *ngIf="processing"
                ><i class="fas fa-spinner fa-spin"></i>
                <span>{{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span></ng-container
              >
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
