import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NumberHelper {
  isInt(n: number): boolean {
    return Number(n) === n && n % 1 === 0;
  }

  isFloat(n: number): boolean {
    return Number(n) === n && n % 1 !== 0;
  }

  roundWithFraction(value: number, fractionDigits: number): number {
    const constant = Math.pow(10, fractionDigits);

    return Math.round((value + Number.EPSILON) * constant) / constant;
  }

  isNumberConvergingZero(n: number): boolean {
    return n ? (n > 0 && n <= 0.005) || (n >= -0.005 && n < 0) : false;
  }
}
