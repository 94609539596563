import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ca-delete-icon',
  templateUrl: './delete-icon.component.html',
  styleUrls: ['./delete-icon.component.scss'],
})
export class DeleteIconComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
