import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import {
    LayoutChange,
    LayoutReset
} from 'src/core/actions/conversation/conversation-module.actions';

@Injectable({
    providedIn: 'root'
})
export class ConversationLayoutService {
    constructor(private store: Store) {}

    changeVisibilityForFilterPanel(visible: boolean) {
        const latest = this.store.selectSnapshot(
            ConversationModuleState.getCurrentLayout
        );

        if (latest.west.visible && visible) {
            return;
        } else if (!latest.west.visible && !visible) {
            return;
        }

        latest.west.visible = !latest.west.visible;

        const action = new LayoutChange(latest);
        this.store.dispatch(action);
    }

    changeVisibilityForDetailPanel(visible: boolean) {
        const latest = this.store.selectSnapshot(
            ConversationModuleState.getCurrentLayout
        );

        if (latest.east.visible && visible) {
            return;
        } else if (!latest.east.visible && !visible) {
            return;
        }

        latest.east.visible = !latest.east.visible;

        const action = new LayoutChange(latest);
        this.store.dispatch(action);
    }

    onDragEnd(e: { gutterNum: number; sizes: Array<number> }) {
        const latest = this.store.selectSnapshot(
            ConversationModuleState.getCurrentLayout
        );

        if (latest.west.visible) {
            latest.west.size = e.sizes[0];
            latest.center.size = e.sizes[1];
            latest.east.size = e.sizes[2];
        } else {
            latest.center.size = e.sizes[0];
            latest.east.size = e.sizes[1];
        }

        const action = new LayoutChange(latest);
        this.store.dispatch(action);
    }

    resetLayout() {
        const action = new LayoutReset();
        this.store.dispatch(action);
    }
}
