import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { Observable } from 'rxjs';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { Select } from '@ngxs/store';

@Component({
  selector: 'ca-conversation-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent implements OnInit {
  @Select(ConversationModuleState.getActiveList)
  activeList$: Observable<number>;

  @Select(ConversationModuleState.getActiveView)
  activeView$: Observable<number>;

  viewOptions$: Observable<GenericLookupDto[]>;
  viewOptions: GenericLookupDto[] = [];

  @Output()
  conversationIdChange: EventEmitter<{
    id: number;
  }> = new EventEmitter();

  @Output()
  dataLoaded = new EventEmitter();

  standardListVisible = true;
  constructor() { }

  ngOnInit() { }

  onConversationIdChanged(conversationId) {
    this.conversationIdChange.emit(conversationId);
  }

  onDataLoaded() {
    this.dataLoaded.emit();
  }

}
