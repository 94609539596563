import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConversationIconsModule } from '../conversation-icons/conversation-icons.module';
import { ConversationIconContainerComponent } from './conversation-icon-container.component';

@NgModule({
    declarations: [ConversationIconContainerComponent,],
    imports: [CommonModule, SharedModule, NgbTooltipModule, ConversationIconsModule],
    exports: [ConversationIconContainerComponent,],
})
export class ConversationIconContainerModule { }
