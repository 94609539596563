<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">
          {{ 'Conversation::QueryBuilder:AgentSentiment' | abpLocalization }}
        </label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ payload.operator | operator }}</label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">
          {{ 'GenericLookup::' + currentStatus | abpLocalization }}
        </label>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">
          {{ 'Conversation::QueryBuilder:AgentSentiment' | abpLocalization }}
          <sup
            ngbTooltip="{{ 'Category::SentimentSupportInfo' | abpLocalization }}"
            container="body"
            *ngIf="existVeryPositiveNegative()">
            <i class="fa-regular fa-circle-info"></i>
          </sup>
        </label>
      </div>
      <div class="col-auto">
        <select class="form-select form-select-sm" [(ngModel)]="payload.operator">
          <option *ngFor="let operator of node.categoryItem.operators" [value]="operator">
            {{ operator | operator }}
          </option>
        </select>
      </div>
      <div class="col-auto">
        <mat-select
          class="form-control form-control-sm"
          [(value)]="payload.value"
          (selectionChange)="valueChanged()"
          panelClass="mat-select-position"
          disableOptionCentering>
          <mat-option *ngFor="let status of agentSentimentStatuses$ | async" [value]="status.id">
            <div class="mat-option-container">
              <div
                class="icon-container"
                title="{{ 'GenericLookup::' + status.code | abpLocalization }}">
                <span [class]="getOptionIcon(status.code)" style="margin-right: 5px"></span>
                <span>{{ 'GenericLookup::' + status.code | abpLocalization }}</span>
              </div>
            </div>
          </mat-option>
          <mat-select-trigger>
            <div class="mat-option-container">
              <div
                class="icon-container"
                title="{{ 'GenericLookup::' + payload.genericLookupCode | abpLocalization }}">
                <span
                  [class]="getOptionIcon(payload.genericLookupCode)"
                  style="margin-right: 5px"></span>
                <span>{{ 'GenericLookup::' + payload.genericLookupCode | abpLocalization }}</span>
              </div>
            </div>
          </mat-select-trigger>
        </mat-select>
      </div>
      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>
