import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ActiveTabChange,
  CommentAdded,
  CommentDeleted,
  CommentsLoaded,
  ConversationChange,
  EvaluationPanelStatusChange,
  EvaluationParametersChange,
  ExcelDetailChange,
  RedactedTranscriptChange,
  SkipDeactivationCheckChange,
  SummaryChange,
  TabStatusChange,
} from 'src/core/actions/conversation/conversation-detail.actions';
import { ConversationCommentDto } from 'src/core/models/comment/comment.dto';
import { ConversationDetailTabStatus } from 'src/core/models/conversation/conversation-detail-tab-status.enum';
import {
  ConversationDetailStateModel,
  ConversationDetailStateModelDefaults,
  EvaluationParameters,
} from 'src/core/models/conversation/conversation-detail.state-model';
import { ConversationExcelDetailDto } from 'src/core/models/conversation/conversation-excel-detail.dto';
import { ConversationSummaryDto } from 'src/core/models/conversation/conversation-summary.dto';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { EvaluationPanelStatus } from 'src/core/models/conversation/evaluation-panel-status.enum';
import { ConversationTranscriptDto } from 'src/core/models/conversation/transcript/conversation-transcript.dto';

@State<ConversationDetailStateModel>({
  name: 'ConversationDetailState',
  defaults: Object.assign({}, ConversationDetailStateModelDefaults.value),
})
export class ConversationDetailState {
  @Selector()
  static getConversation(state: ConversationDetailStateModel): ConversationDto {
    return state.conversation;
  }

  @Selector()
  static getSummary(state: ConversationDetailStateModel): ConversationSummaryDto {
    return state.summary;
  }

  @Selector()
  static getTranscript(state: ConversationDetailStateModel): ConversationTranscriptDto {
    return (
      state.transcript ?? {
        conversationId: 0,
        conversationTypeId: 0,
        items: [],
      }
    );
  }

  @Selector()
  static getNumberOfComments(state: ConversationDetailStateModel): number {
    return state.numberOfComments;
  }

  @Selector()
  static getComments(state: ConversationDetailStateModel): ConversationCommentDto[] {
    return state.comments;
  }

  @Selector()
  static getActiveTab(state: ConversationDetailStateModel): string {
    return state.activeTab;
  }

  @Selector()
  static getEvaluationPanelStatus(state: ConversationDetailStateModel): EvaluationPanelStatus {
    return state.evaluationPanelStatus;
  }

  @Selector()
  static getEvaluationParameters(state: ConversationDetailStateModel): EvaluationParameters {
    return state.evaluationParameters;
  }

  @Selector()
  static getTabStatus(
    state: ConversationDetailStateModel
  ): (tabId: string) => ConversationDetailTabStatus {
    return (tabId: string): ConversationDetailTabStatus => {
      return state.tabStatus.get(tabId);
    };
  }


  @Selector()
  static getExcelDetail(state: ConversationDetailStateModel): ConversationExcelDetailDto {
    return state.excelDetail;
  }

  @Action(ConversationChange)
  changeConversation(ctx: StateContext<ConversationDetailStateModel>, action: ConversationChange) {
    let currentState = ctx.getState();

    const activeTab = currentState.activeTab;
    const defaults = Object.assign({}, ConversationDetailStateModelDefaults.value);

    // Preserve active tab if requested.
    if (action.preserveTab) {
      defaults.activeTab = activeTab;
    }

    currentState = defaults;

    currentState.conversationId = action.id;
    currentState.conversation = Object.assign({}, action.conversation);
    currentState.transcript = Object.assign({}, action.transcript);
    currentState.numberOfComments = action.conversation.numberOfComments;
    currentState.summary = action.summary;
    currentState.comments = Array.from(action.comments);

    ctx.patchState(currentState);
  }

  @Action(ActiveTabChange)
  changeActiveTab(ctx: StateContext<ConversationDetailStateModel>, action: ActiveTabChange) {
    const currentState = ctx.getState();

    currentState.activeTab = action.activeTab;

    ctx.patchState(currentState);
  }

  @Action(EvaluationPanelStatusChange)
  changeEvaluationPanelStatus(
    ctx: StateContext<ConversationDetailStateModel>,
    action: EvaluationPanelStatusChange
  ) {
    const currentState = ctx.getState();

    currentState.evaluationPanelStatus = action.newStatus;

    ctx.patchState(currentState);
  }

  @Action(EvaluationParametersChange)
  changeEvaluationParameters(
    ctx: StateContext<ConversationDetailStateModel>,
    action: EvaluationParametersChange
  ) {
    const currentState = ctx.getState();

    currentState.evaluationParameters = {
      formId: action.formId,
      formVersionId: action.formVersionId,
      evaluationResultId: action.evaluationResultId,
      evaluationMasterId: action.evaluationMasterId,
      assignmentId: action.assignmentId,
      evaluationType: action.evaluationType,
      isEvaluating: action.isEvaluating
    };

    ctx.patchState(currentState);
  }

  @Action(TabStatusChange)
  changeTabStatus(ctx: StateContext<ConversationDetailStateModel>, action: TabStatusChange) {
    const currentState = ctx.getState();

    currentState.tabStatus.set(action.tabId, action.newStatus);

    ctx.patchState(currentState);
  }

  @Action(CommentAdded)
  increaseNumberOfComments(ctx: StateContext<ConversationDetailStateModel>, action: CommentAdded) {
    const currentState = ctx.getState();

    currentState.numberOfComments++;

    ctx.patchState(currentState);
  }

  @Action(CommentDeleted)
  decreaseNumberOfComments(
    ctx: StateContext<ConversationDetailStateModel>,
    action: CommentDeleted
  ) {
    const currentState = ctx.getState();

    currentState.numberOfComments--;

    ctx.patchState(currentState);
  }

  @Action(CommentsLoaded)
  setComments(ctx: StateContext<ConversationDetailStateModel>, action: CommentsLoaded) {
    const currentState = ctx.getState();

    currentState.comments = Array.from(action.comments);

    ctx.patchState(currentState);
  }

  @Action(SkipDeactivationCheckChange)
  changeSkipDeactivationCheck(
    ctx: StateContext<ConversationDetailStateModel>,
    action: SkipDeactivationCheckChange
  ) {
    const currentState = ctx.getState();

    currentState.skipDeactivationCheck = action.value;

    ctx.patchState(currentState);
  }

  @Action(SummaryChange)
  changeSummary(ctx: StateContext<ConversationDetailStateModel>, action: SummaryChange) {
    const currentState = ctx.getState();

    currentState.summary = {
      error: action.newSummary.error,
      summary: action.newSummary.summary,
      topic: action.newSummary.topic,
      statusId: action.newSummary.statusId,
    };

    ctx.patchState(currentState);
  }

  @Action(ExcelDetailChange)
  changeEvaluationExcelDetail(
    ctx: StateContext<ConversationDetailStateModel>,
    action: ExcelDetailChange
  ) {
    const currentState = ctx.getState();
    currentState.excelDetail = action.excelDetail;

    ctx.patchState(currentState);
  }

  @Action(RedactedTranscriptChange)
  changeRedactedTranscript(
    ctx: StateContext<ConversationDetailStateModel>,
    action: RedactedTranscriptChange
  ) {
    const currentState = ctx.getState();
    currentState.transcript = Object.assign({}, action.redactedTranscript);

    ctx.patchState(currentState);
  }
}
