import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { TextEditorConfigurationModel } from '../../filter-panel.module';
import { FilterPanelEditorConfigurationModel } from '../../models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from '../../models/filter-panel-editor.component';
import { QuickSearchWithSideSelectionValue } from './model/quick-search-with-side-selection-value.model';

@Component({
  selector: 'ca-quick-search-with-side-selection-panel',
  templateUrl: './quick-search-with-side-selection-panel.component.html',
  styleUrls: ['./quick-search-with-side-selection-panel.component.scss'],
})
export class QuickSearchWithSideSelectionPanelComponent
  extends FilterPanelEditorComponent
  implements OnInit
{
  private _value: QuickSearchWithSideSelectionValue;
  actualConfig: TextEditorConfigurationModel;
  set value(value: QuickSearchWithSideSelectionValue) {
    this._value = value;

    this.editorForm.patchValue({
      sideId: value.sideId,
      quickSearchText: value.quickSearchText,
    });
  }

  get value(): QuickSearchWithSideSelectionValue {
    return {
      sideId: this.editorForm.controls.sideId.value,
      quickSearchText: this.editorForm.controls.quickSearchText.value,
    };
  }

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as TextEditorConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  get hasIcon(): boolean {
    return this.actualConfig.iconCls != null;
  }

  get labelClass() {
    let cls = '';
    if (this.actualConfig.callInline === true) {
      cls = 'col-5 custom-padding';
    } else if (this.actualConfig.chatInline === true) {
      cls = 'col-4 custom-padding';
    }
    return cls;
  }

  get customClass() {
    let cls = '';
    if (this.actualConfig.clearBottomMargin === true) {
      cls += 'mb-0';
    }
    if (this.actualConfig.callInline === true || this.actualConfig.chatInline === true) {
      cls += ' display-inline';
    }
    return cls;
  }

  get hideExternalIdInformation() {
    if (!this.actualConfig || this.actualConfig.hideExternalIdInformation == undefined) {
      return false;
    }

    return this.actualConfig.hideExternalIdInformation;
  }

  constructor(protected fb: FormBuilder, private localizationService: LocalizationService) {
    super(fb);
    this.editorForm.addControl('sideId', new FormControl());
    this.editorForm.addControl('quickSearchText', new FormControl());

    this.editorForm.valueChanges.pipe(debounceTime(100)).subscribe(val => {
      this._value.sideId = val.sideId;
      this._value.quickSearchText = val.quickSearchText;
      this.onChange();
    });
  }

  changeText(text: string) {
    const quickSearchText = this.editorForm.get('quickSearchText');
    switch (text) {
      case 'AND': {
        quickSearchText.patchValue(
          this.localizationService.instant('::QuickSearch:OperatorAndExample')
        );
        break;
      }
      case 'OR': {
        quickSearchText.patchValue(
          this.localizationService.instant('::QuickSearch:OperatorOrExample')
        );
        break;
      }
      default: {
        quickSearchText.patchValue(this.localizationService.instant('::FilterByExtIdLabel'));
        break;
      }
    }

    if (this.hasIcon) {
      document.getElementById('quickSearchTextWithIcon').focus();
    } else {
      document.getElementById('quickSearchTextWithoutIcon').focus();
    }
  }

  ngOnInit(): void {}

  autoSelectFirstItem(): void {}

  init(): void {
    super.init();
  }

  reload(): void {}

  reset(): void {}

  onValueChange(eventArgs) {
    this.onChange();
  }
}
