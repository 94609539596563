<div class="row m-0" *blockUI="'permissions-block'" style="min-height: 300px">
  <div class="col p-2" *ngIf="userInteractedUsers !== null && userInteractedUsers.length > 0">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let userInteractedUser of userInteractedUsers">
        <mat-expansion-panel-header>
          <mat-panel-title style="color: #2196f3">
            {{ userInteractedUser.departmentName }}
          </mat-panel-title>
          <mat-panel-description
            style="display: flex; flex-direction: row; justify-content: flex-end"
          >
            <b>{{ userInteractedUser.users?.length }}</b
            >&nbsp;of
            {{ userInteractedUser.departmentUserCount }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-selection-list #selectionList [disableRipple]="true">
          <ng-container *ngFor="let user of userInteractedUser.users">
            <div>{{ user.fullName }}</div>
          </ng-container>
        </mat-selection-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div
    class="col p-2"
    *ngIf="
      !this.blockUI.isActive && (userInteractedUsers === null || userInteractedUsers.length === 0)
    "
  >
    {{ 'User::UserNoInteractions' | abpLocalization }}
  </div>
</div>
