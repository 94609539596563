<ng-select
  #select
  #t="ngbTooltip"
  [ngbTooltip]="tooltip"
  [class]="class"
  [style]="style"
  [clearable]="multiple"
  [searchable]="false"
  [disabled]="disabled"
  [(ngModel)]="value"
  (change)="onChanged($event); onBlurRequest(select)"
  (click)="onBlurRequest(select); t?.close()"
  [placeholder]="placeholder"
  [ngClass]="classList"
  [appendTo]="appendTo"
  [multiple]="multiple"
  [closeOnSelect]="!multiple"
  placement="auto"
  container="body"
  bindLabel="code"
  bindValue="id">
  <ng-option *ngFor="let item of items" [value]="item.data">
    <div
      [ngClass]="'truncate-text'"
      [ngbTooltip]="item.label"
      showTooltipIfTruncated
      container="body">
      {{ item.label }}
    </div>
  </ng-option>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="ng-value" *ngIf="items.length === 1">
      <span class="ng-value-label">{{ getItemLabel(items[0]) }}</span>
    </div>
    <div class="ng-value" *ngIf="items.length > 1">
      <span class="ng-value-label">
        {{ 'CA::XItemsSelected' | abpLocalization : items.length }}...
      </span>
    </div>
  </ng-template>
</ng-select>
