import { Injectable } from '@angular/core';
import { AsyncValidator, ValidationErrors, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserService } from 'src/core/services/administration/user.service';

@Injectable({ providedIn: 'root' })
export class UsernameValidator implements AsyncValidator {
  userId: string | null;

  constructor(private userService: UserService) {}

  patternValidator(control: FormControl) {
    const username = control.value;
    var regex = /[`~!#$%^&()|+=?;:",<>\{\}\[\]\\\/]/gi;

    if (regex.test(username)) {
      return { userNameInvalidPattern: true };
    }

    return null;
  }

  validate(
    formControl: FormControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    const username = formControl.value;
    const id = this.userId == null ? '' : this.userId;

    return this.userService.getByUsername(username).pipe(
      map(
        response => {
          return response !== null && response.id !== id ? { usernameInUse: true } : null;
        },
        catchError(() => {
          return null;
        })
      )
    );
  }
}
