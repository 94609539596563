import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { InitializeUserState } from 'src/core/actions/generic/user-state.actions';
import { UserStateSettingsService } from 'src/core/services/state/user-state-settings.service';
@State<any>({
  name: 'GenericState',
  defaults: null,
})
@Injectable()
export class GenericState<T> {
  name: string;

  constructor(protected userSettingsService: UserStateSettingsService) {}

  @Action(InitializeUserState)
  initializeData(ctx: StateContext<T>, action: any) {
    if (Array.isArray(action.payload)) {
      let state = action.payload.find(x => x.key === this.name);
      if (state) {
        let stateValue = JSON.parse(state.value);
        ctx.setState(stateValue);
      } else {
        // Use defaults if no state is preserved on db. (To prevent using data on local storage)
        const stateCls = this.userSettingsService.stateList[this.name];

        if (stateCls) {
          const resetAction = new StateReset(stateCls.constructor);

          ctx.dispatch(resetAction);
        }
      }
    }
  }
}
