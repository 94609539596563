<ng-container *ngIf="isReadonly; then readonlyContent; else editableContent"></ng-container>

<ng-template #readonlyContent>
  <div [class]="cssClass">
    <div class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{
          'Conversation::QueryBuilder:CustomerGender' | abpLocalization
        }}</label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{ payload.operator | operator }}</label>
      </div>
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{
          'Query::' + currentGender | abpLocalization
        }}</label>
      </div>
      <div class="col-auto show-on-hover">
        <button
          mat-mini-fab
          color="warn"
          (click)="removeNode($event)"
          ngbTooltip="{{ '::RemoveNode' | abpLocalization }}"
          container="body"
          placement="bottom"
        >
          <ca-delete-icon></ca-delete-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editableContent>
  <div [class]="cssClass">
    <div showButtonsOnHover class="row m-0 align-items-center full-height">
      <div class="col-auto">
        <label style="margin-bottom: 0px !important">{{
          'Conversation::QueryBuilder:CustomerGender' | abpLocalization
        }}</label>
      </div>
      <div class="col-auto">
        <select
          class="form-select form-select-sm"
          [(ngModel)]="payload.operator"
          (ngModelChange)="operatorChanged()"
        >
          <option *ngFor="let operator of node.categoryItem.operators" [value]="operator">
            {{ operator | operator }}
          </option>
        </select>
      </div>
      <div class="col-auto">
        <select
          class="form-select form-select-sm"
          [(ngModel)]="payload.value"
          (ngModelChange)="valueChanged()"
        >
          <option *ngFor="let gender of genders$ | async" [value]="gender.id">
            {{ 'Query::' + gender.name | abpLocalization }}
          </option>
        </select>
      </div>
      <ca-query-builder-node-remove-button
        (buttonClick)="removeNode($event)"
      ></ca-query-builder-node-remove-button>
    </div>
  </div>
</ng-template>
