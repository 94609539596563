<div class="main-center w-45">
  <div class="card">
    <div class="card-header">
      <h2 class="d-inline-block mt-1">
        {{ 'Settings::InitialSetup.InitialSettings' | abpLocalization }}
      </h2>
      <div class="navbar float-end">
        <abp-languages></abp-languages>
        <ng-container *ngIf="currentUser$ | async as user">
          <ng-template #loginBtn>
            <a role="button" class="btn" (click)="navigateToLogin()">
              <i class="fas fa-sign-in-alt me-1"></i>
              {{ 'AbpAccount::Login' | abpLocalization }}</a
            >
          </ng-template>
          <div *ngIf="user.isAuthenticated; else loginBtn" class="dropdown btn-group" ngbDropdown>
            <a ngbDropdownToggle class="btn pointer d-flex">
              <abp-current-user-image classes="user-avatar"></abp-current-user-image>
              <span class="ms-1">
                <small *ngIf="(selectedTenant$ | async)?.name as tenantName"
                  ><i>{{ tenantName }}</i
                  >\</small
                >
                <span>{{ user.userName }}</span>
              </span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
              <div class="p-2 row">
                <div class="pr-0 col col-auto">
                  <abp-current-user-image classes="user-avatar-big"></abp-current-user-image>
                </div>
                <div class="ps-2 col">
                  <span>{{ 'AbpAccount::Welcome' | abpLocalization }}</span
                  ><br />
                  <small *ngIf="(selectedTenant$ | async)?.name as tenantName"
                    ><i>{{ tenantName }}</i
                    >\</small
                  >
                  <strong>{{
                    ((user.name || '') + ' ' + (user.surName || '')).trim() || user.userName
                  }}</strong>
                </div>
              </div>
              <div class="dropdown-divider"></div>

              <a class="dropdown-item pointer" id="logout" (click)="logout()">{{
                'AbpUi::Logout' | abpLocalization
              }}</a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="card-body">
      <div class="container">
        <form [formGroup]="settingsForm" (ngSubmit)="save()" #formDirective="ngForm">
          <div class="form-group mt-3 mb-3">
            <label class="font-italic">
              {{ 'Settings::InitialSetup.InitialSettingsWarning' | abpLocalization }}
            </label>
          </div>
          <div class="form-group">
            <div class="form-group mt-3">
              <label for="systemLanguageCode">
                {{ 'Settings::InitialSetup.PrimaryConversationLanguage' | abpLocalization }}</label
              >
              <select class="form-select form-select-sm" formControlName="systemLangCode">
                <option *ngFor="let language of languagesDataSource" [(ngValue)]="language.code">
                  {{ 'Settings::Call.Language.' + language.name | abpLocalization }}
                </option>
              </select>
              <small>{{ 'Settings::InitialSetup.PrimaryLanguageDescription' | abpLocalization }}</small>
            </div>
            <div class="form-group mt-3">
              <label for="systemLanguageCode">{{
                'Settings::InitialSetup.OtherLanguagesLabel' | abpLocalization
              }}</label>
              <ng-select
                formControlName="otherLanguages"
                id="otherLanguages"
                [items]="otherLanguagesDataSource"
                bindLabel="name"
                bindValue="code"
                [multiple]="true"
                [closeOnSelect]="false">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input
                    id="item-{{ index }}"
                    type="checkbox"
                    [ngModel]="item$.selected"
                    [ngModelOptions]="{ standalone: true }" />
                  {{ 'Settings::Call.Language.' + item.name | abpLocalization }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  <span
                    class="ng-value-icon left"
                    aria-hidden="true"
                    (click)="removeFromSelectedOtherLanguages(item.code)"
                    >×</span
                  >
                  <span class="ng-value-label">{{
                    'Settings::Call.Language.' + item.name | abpLocalization
                  }}</span>
                </ng-template>
              </ng-select>
              <small>{{ 'Settings::InitialSetup.OtherLanguagesDescription' | abpLocalization }}</small>
            </div>
            <div class="form-group mt-3">
              <label for="systemDate">
                {{ 'Settings::Global.SystemStartDate' | abpLocalization }}</label
              >
              <input
                class="form-control form-control-sm"
                type="date"
                formControlName="systemStartDate"
                [ngModel]="systemStartDate | date : 'yyyy-MM-dd'"
                (ngModelChange)="systemStartDate = $event" />
            </div>
            <div class="form-group mt-3">
              <label for="systemDateFormat">
                {{ 'Settings::Global.SystemDateFormat' | abpLocalization }}</label
              >
              <select class="form-select form-select-sm" formControlName="systemDateFormat">
                <option *ngFor="let dateFormat of dateFormats" [ngValue]="dateFormat">
                  {{ dateFormat }}
                </option>
              </select>
            </div>
            <div class="form-group mt-3">
              <label for="systemTimeZone">
                {{ 'Settings::Global.SystemTimeZone' | abpLocalization }}</label
              >
              <select class="form-select form-select-sm" formControlName="systemTimeZone">
                <option
                  *ngFor="let timeZone of timeZones | keyvalue : originalOrder"
                  [(ngValue)]="timeZone.key">
                  {{ timeZone.value }}
                </option>
              </select>
            </div>
          </div>

          <button
            class="btn btn-primary mt-3 mb-3"
            type="submit"
            [disabled]="!settingsForm.valid || processing">
            <ng-container *ngIf="!processing">
              <i class="me-1 fa fa-flag-checkered"> </i>
              <span>{{ 'Settings::InitialSetup.CompleteButton' | abpLocalization }}</span>
            </ng-container>
            <ng-container *ngIf="processing">
              <i class="me-1 far fa-spinner fa-spin"> </i>
              <span>{{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span>
            </ng-container>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
