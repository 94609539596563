import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { NumberEditorConfigurationModel } from './models/number-editor-configuration.model';

@Component({
  selector: 'ca-number-editor',
  templateUrl: './number-editor.component.html',
  styleUrls: ['./number-editor.component.scss'],
})
export class NumberEditorComponent extends FilterPanelEditorComponent implements OnInit {
  private _value: string;

  actualConfig: NumberEditorConfigurationModel;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as NumberEditorConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  get hasIcon(): boolean {
    return this.actualConfig.iconCls != null;
  }
  
  get showValidationMessage(): boolean {
    return this.actualConfig.showValidationMessage != null && this.actualConfig.showValidationMessage;
  }

  get labelClass() {
    let cls = '';
    if (this.actualConfig.callInline === true) {
      cls = 'col-5 custom-padding';
    } else if (this.actualConfig.chatInline === true) {
      cls = 'col-4 custom-padding';
    }
    return cls;
  }

  get customClass() {
    let cls = '';
    if (this.actualConfig.clearBottomMargin === true) {
      cls += 'mb-0';
    }
    if (this.actualConfig.callInline === true || this.actualConfig.chatInline === true) {
      cls += ' display-inline';
    }
    return cls;
  }

  init(): void {
    super.init();
  }
  
  autoSelectFirstItem(): void {}
  
  reload(): void {}

  reset(): void {}

  onValueChange(eventArgs) {
    this.onChange();
  }

  set value(value: any) {
    this._value = value;

    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this.editorForm.controls.editor.value;
  }

  onChangeValue() {
    if (this.actualConfig.minValue != null && this.value < this.actualConfig.minValue) {
      this.value = this.actualConfig.minValue;
    }

    if (this.actualConfig.maxValue != null && this.value > this.actualConfig.maxValue) {
      this.value = this.actualConfig.maxValue;
    }
  }

  constructor(protected fb: FormBuilder) {
    super(fb);
  }

  ngOnInit(): void {}
}
