import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormButtonConfiguration } from 'src/ca-shared/form/form-button-configuration.model';
import { LocalizationService, PermissionService } from '@abp/ng.core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';
import { ConversationExcelDetailDto } from 'src/core/models/conversation/conversation-excel-detail.dto';
import { Store } from '@ngxs/store';
import { ConversationDetailState } from 'src/core/states/conversation/conversation-detail.state';
import { ExcelDetailChange } from 'src/core/actions/conversation/conversation-detail.actions';
import { DatePipe } from '@angular/common';
import { CrudService } from 'src/core/services/crud/crud.service';
import { CategoryDto } from 'src/core/models/query/category.dto';
import { CategoryType } from 'src/core/models/enum/category-type.enum';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';

@Component({
  selector: 'ca-conversation-export',
  templateUrl: './conversation-export.component.html',
  styleUrls: ['./conversation-export.component.scss'],
})
export class ConversationExportComponent implements OnInit {
  @ViewChild('conversationResultExportModal') conversationResultExportModal: any;

  @Input()
  isDisabled: boolean = false;

  canExportConversation: boolean = false;
  form: FormGroup;
  documentName: string = '';
  userDefinedCategories: CategoryDto[] = [];
  AIGeneratedCategories: CategoryDto[] = [];

  get formButtonConfiguration(): FormButtonConfiguration {
    const result: FormButtonConfiguration = {
      save: {
        name: this.localizationService.instant('::Export'),
        hideIcon: true,
        visible: true,
        tooltip: null,
        disabled: false,
      },
      delete: {
        visible: false,
        tooltip: null,
        disabled: false,
      },
    };

    return result;
  }

  get userDefinedCategoryCount(): string {
    return this.userDefinedCategories.length.toString();
  }

  get aiGeneratedCategoryCount(): string {
    return this.AIGeneratedCategories.length.toString();
  }

  constructor(
    private permissionService: PermissionService,
    private conversationService: ConversationService,
    private toastr: ToasterService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private localizationService: LocalizationService,
    private store: Store,
    private datePipe: DatePipe,
    private service: CrudService,
    private operators: Operators
  ) {
    this.canExportConversation = this.permissionService.getGrantedPolicy(
      'Conversation.ConversationExport'
    );

    let filters: FilterItemDto[] = [
      {
        field: 'isActive',
        operator: this.operators.Equals,
        value: true,
      },
      {
        field: 'isCategory',
        operator: this.operators.Equals,
        value: true,
      }
    ];

    this.service
      .get<CategoryDto>(CategoryDto, {
        maxResultCount: 9999,
        skipCount: 0,
        filters: filters,
        sorters: [],
      })
      .subscribe(response => {
        let categories = response.items;
        this.userDefinedCategories = [
          ...categories.filter(x => x.categoryType == CategoryType.Query),
        ];
        this.AIGeneratedCategories = [
          ...categories.filter(x => x.categoryType == CategoryType.Topic),
        ];
      });

    this.form = this.fb.group({
      documentName: [
        null,
        {
          validators: [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(250),
            StringValidator.whitespace,
          ],
        },
      ],
      includeUserDefinedCategories: [false],
      includeAIGeneratedCategories: [false],
    });

  }

  ngOnInit(): void { }

  onOpenModal() {
    this.modalService.open(this.conversationResultExportModal, {
      size: 'lg',
      scrollable: false,
      backdrop: 'static',
      keyboard: false,
    });

    const today = new Date();
    const dateStr = this.datePipe.transform(today, 'ddMMyyyy');
    let fileName = this.localizationService.instant('Conversation::ExcelExport.FileName');
    this.documentName = fileName + '-' + dateStr;

    this.getState();
  }

  onModalClosed() {
    this.modalService.dismissAll();
  }

  onExportExcelClick() {
    if (this.form.valid) {
      let excelDetail = this.form.value;
      let excelDetailDto: ConversationExcelDetailDto = {
        documentName: excelDetail.documentName,
        includeUserDefinedCategories: excelDetail.includeUserDefinedCategories,
        includeAIGeneratedCategories: excelDetail.includeAIGeneratedCategories,
      };

      this.setState();
      this.modalService.dismissAll();

      this.conversationService.exportList(excelDetailDto).subscribe(() => {
        this.toastr.success('Conversation::ExportListRequestSuccessful');
      });
    }
  }

  getState() {
    const excelDetail = this.store.selectSnapshot(ConversationDetailState.getExcelDetail);
    if (excelDetail) {
      this.form
        .get('documentName')
        .setValue(excelDetail?.documentName ?? this.documentName);
      this.form
        .get('includeUserDefinedCategories')
        .setValue(excelDetail?.includeUserDefinedCategories);
      this.form
        .get('includeAIGeneratedCategories')
        .setValue(excelDetail?.includeAIGeneratedCategories);
    }
  }

  setState() {
    let excelDetail = this.form.value;
    let excelDetailDto: ConversationExcelDetailDto = {
      documentName: excelDetail.documentName === this.documentName ? null : excelDetail.documentName,
      includeUserDefinedCategories: excelDetail?.includeUserDefinedCategories ?? false,
      includeAIGeneratedCategories: excelDetail?.includeAIGeneratedCategories ?? false,
    };
    const excelDetailChangedAction = new ExcelDetailChange(excelDetailDto);
    this.store.dispatch(excelDetailChangedAction);
  }
}
