import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Identity.Evaluator.FilterModeOption',
})
export class EvaluatorFilterModeOption {
  @GenericLookup({
    code: 'Identity.Evaluator.FilterModeOption.SelectedEvaluators',
  })
  static SelectedEvaluators: number;

  @GenericLookup({
    code: 'Identity.Evaluator.FilterModeOption.AllEvaluatorsExceptSelectedEvaluator',
  })
  static AllEvaluatorsExceptSelectedEvaluator: number;
}
