<div class="auto-selector-main">
  <label
    *ngIf="selectionText?.length > 0"
    class="selection-label pe-3 text-primary"
    for="input-wrapper">
    {{ selectionText }} :</label
  >
  <div class="input-wrapper" id="input-wrapper">
    <ng-select
      #selector
      class="auto-complete"
      [items]="items$ | async"
      [multiple]="true"
      class="auto-complete"
      [closeOnSelect]="false"
      [searchable]="true"
      [searchWhileComposing]="false"
      bindLabel="name"
      [loading]="itemsLoading"
      [minTermLength]="minTermLength"
      [clearable]="false"
      [placeholder]="emptyText"
      [(ngModel)]="dummyModel"
      loadingText="{{ '::Loading' | abpLocalization }}"
      notFoundText="{{ '::NoItemsFound' | abpLocalization }}"
      typeToSearchText="{{ '::TypeToSearch' | abpLocalization }}"
      [typeahead]="itemInput$"
      appendTo="{{ appendToBody ? 'body' : '' }}"
      (search)="onSearch($event)"
      (close)="onClose()"
      (change)="onItemSelect($event)"
      (keyup.enter)="onEnterKeyUp($event)"
      [disabled]="disabled">
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div
          style="display: flex; align-items: center"
          [ngbTooltip]="
            isDisabled(item[idProperty]) || disableFn(item)
              ? disabledItemMessage
              : item.fullName
              ? item.fullName
              : item.name
          "
          container="body">
          <input
            type="checkbox"
            [checked]="isSelected(item[idProperty])"
            [disabled]="disabled || isDisabled(item[idProperty]) || disableFn(item)" />
          <div [ngClass]="filterByStatus ? 'truncate-selection' : 'ps-2 truncate-selection'">
            <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }"> </ng-container>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>

<div class="selections mt-3 ms-2" [ngStyle]="selectionStyle" *ngIf="!hideSelections">
  <div *ngIf="selectedRecords?.length == 0">{{ noSelectionText }}</div>
  <ul class="card-text auto-complete-selector-card-text">
    <li *ngFor="let item of selectedRecords" class="pb-1">
      <div style="display: flex; align-items: center; align-content: flex-start">
        <div class="warning-icon">
          <i
            *ngIf="!filterByStatus && (item.isActive != null ? !item.isActive : false)"
            class="fas fa-info-circle fa-sm"
            style="color: red; margin-top: 5px"
            [ngbTooltip]="'CA::UserNotActive' | abpLocalization"
            container="body"
            placement="right"></i>
        </div>
        <button
          class="dropdown-selector-remove-selection-icon"
          [disabled]="disabled || isDisabled(item[idProperty]) || disableFn(item)"
          (click)="onClickRemoveItem(item)">
          <i class="fas fa-times remove-icon"></i>
        </button>
        <i
          *ngIf="filterByStatus"
          [ngClass]="
            item.isActive ? 'fas fa-circle active-item-icon' : 'fas fa-circle passive-item-icon'
          "
          container="body"
          placement="right"></i>
        <div
          class="ps-2 truncate-selected"
          [ngbTooltip]="item.fullName ? item.fullName : item.name"
          container="body">
          <ng-container *ngTemplateOutlet="selectionTemplate; context: { item: item }">
          </ng-container>
        </div>
        <div
          class="ps-4 truncate-selected"
          *ngIf="showEmail"
          [ngbTooltip]="item.email"
          container="body">
          <ng-container *ngTemplateOutlet="selectionEmailTemplate; context: { item: item }">
          </ng-container>
        </div>
      </div>
    </li>
  </ul>
</div>
