import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
    code: 'Conversation.ChatList.ViewOption'
})
export class ConversationChatListViewOption {
    @GenericLookup({
        code: 'Conversation.ChatList.ViewOption.Standard'
    })
    static standard: number;
}
