import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import {
  BeforeVolumeChange,
  CompactPlayerPositionChange,
  SpeedChange,
  VolumeChange,
} from 'src/core/actions/conversation/conversation-module.actions';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';
import { ConversationModuleStateModel } from 'src/core/models/conversation/conversation-module.state-model';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationMediaType } from 'src/core/models/generic-lookup-type/conversation/conversation-media-type.glt';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { CATimePipe } from 'src/core/pipes/ca-time.pipe';
import { VideoPlayerService } from 'src/core/services/video-player/video-player.service';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';

@Component({
  selector: 'ca-compact-player',
  templateUrl: './compact-player.component.html',
  styleUrls: ['./compact-player.component.scss'],
})
@AutoUnsubscribe()
export class CompactPlayerComponent
  extends HasSubscription
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('volumeSlider')
  volumeSlider: ElementRef;

  @BlockUI('compactPlayerBlockUI') blockUI: NgBlockUI;

  @Input()
  showInfo: boolean = true;

  conversation: ConversationDto;
  speedOptions = [0.5, 1, 1.5, 2];
  timeElapsed: BehaviorSubject<string> = new BehaviorSubject('00:00');
  playerStatus: BehaviorSubject<string> = new BehaviorSubject('paused');

  get currentTimeReverse(): number {
    if (!this.isVideo) {
      return this.media.duration ? this.media.duration - this.media.currentTime : 0;
    } else {
      return this._videoElement.duration
        ? this._videoElement.duration - this._videoElement.currentTime
        : 0;
    }
  }

  get currentTime(): number {
    if (!this.isVideo) {
      return this.media.currentTime;
    } else {
      return this._videoElement.currentTime;
    }
  }

  get playing(): boolean {
    return this._playing;
  }

  get loading(): boolean {
    return this._loading;
  }

  get speed(): number {
    return this._speed;
  }

  get duration(): number {
    if (!this.isVideo) {
      return this.media.duration;
    } else {
      return this._videoElement.duration;
    }
  }

  public get volume(): number {
    return this._volume;
  }

  get isVolumeDown(): boolean {
    return this._volume > 0 && this._volume < 0.5;
  }

  get isVolumeUp(): boolean {
    return this._volume >= 0.5;
  }

  get isMuted(): boolean {
    return this._volume === 0;
  }

  get isVideo(): boolean {
    return this._isVideo;
  }

  get videoShown(): boolean {
    return this._videoShown;
  }

  get volumeIcon(): string {
    if (this.isVolumeDown) {
      return 'fas fa-volume-down';
    } else if (this.isVolumeUp) {
      return 'fas fa-volume-up';
    } else if (this.isMuted) {
      return 'fas fa-volume-mute';
    }
  }

  private media = new Audio();
  private _videoElement: HTMLVideoElement = null;
  private _speed: number = 1;
  private _playing: boolean = false;
  private _loading: boolean = false;
  private _volume: number = 1;
  private _volumeBeforeChange: number = 1;
  private _isVideo: boolean = false;
  private _videoShown: boolean = false;

  constructor(
    private caTimePipe: CATimePipe,
    private store: Store,
    private router: Router,
    private videoPlayerService: VideoPlayerService,
    private localizationService: LocalizationService,
    private toastr: ToasterService
  ) {
    super();

    const state = this.store.selectSnapshot<ConversationModuleStateModel>(ConversationModuleState);
    this._videoElement = this.videoPlayerService.videoElement;
    this._speed = state.player.speed;
    this._volume = state.player.volume / 100;
    this._volumeBeforeChange = state.player.beforeVolume / 100;
    this.attachListeners();
    this.router.events.pipe(takeUntil(this.autoUnsubscribeNotifier)).subscribe(val => {
      if (val instanceof NavigationStart) {
        this.saveCompactPlayerPosition();
      }
    });
  }

  private attachListeners(): void {
    this.removeAllListeners();

    if (!this.isVideo) {
      this.media.addEventListener('timeupdate', this.calculateTime, false);
      this.media.addEventListener('playing', this.setPlayerStatus, false);
      this.media.addEventListener('pause', this.setPlayerStatus, false);
      this.media.addEventListener('waiting', this.setPlayerStatus, false);
      this.media.addEventListener('ended', this.setPlayerStatus, false);
      this.media.addEventListener('volumechange', this.onPlayerVolumeChange.bind(this), false);
      this.media.addEventListener('error', this.onError.bind(this), false);
    } else {
      this._videoElement.addEventListener('timeupdate', this.calculateTime, false);
      this._videoElement.addEventListener('playing', this.setPlayerStatus, false);
      this._videoElement.addEventListener('pause', this.setPlayerStatus, false);
      this._videoElement.addEventListener('waiting', this.setPlayerStatus, false);
      this._videoElement.addEventListener('ended', this.setPlayerStatus, false);
      this._videoElement.addEventListener(
        'volumechange',
        this.onPlayerVolumeChange.bind(this),
        false
      );
      this._videoElement.addEventListener('error', this.onError.bind(this), false);
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.media.playbackRate = this.speed;
    this.media.volume = this.volume;
    this._videoElement.playbackRate = this.speed;
    this._videoElement.volume = this.volume;
    this.adjustSliderStyle();
    this.reset();
  }

  ngOnDestroy(): void {
    this.reset(false);
    if (this.isVideo) {
      this.removeVideoPlayer();
    }
    this.removeAllListeners();
    this.media = null;
  }

  setAndPlayAudio(conversation: ConversationDto): void {
    this.reset(false);
    let src = '';

    if (conversation) {
      this.conversation = conversation;
      this._isVideo =
        this.conversation.typeId === ConversationType.meeting ||
        this.conversation.typeId === ConversationType.videoCall
          ? true
          : false;

      if (
        this.conversation.typeId === ConversationType.meeting ||
        this.conversation.typeId === ConversationType.videoCall
      ) {
        src = `api/app/conversation/${this.conversation.id}/media/${ConversationMediaType.videoRecording}`;
      } else {
        src = this.conversation.hasScreenRecording
          ? `api/app/conversation/${this.conversation.id}/media/${ConversationMediaType.screenRecording}`
          : `api/app/conversation/${this.conversation.id}/media/${ConversationMediaType.voiceRecording}`;
      }
    }

    if (src) {
      if (this.isVideo) {
        this.setVideo(src);
      } else {
        this.setAudio(src);
      }
      this.onChangeSpeed(this.speed);
      this.onPlayAudio();
      if (this.blockUI.isActive) {
        this.blockUI.stop();
      }
    } else {
      this.reset();
    }

    this.attachListeners();
  }

  setAudio(src: string): void {
    this.media.src = src;
  }

  setVideo(src: string) {
    this._videoElement.setAttribute('src', src);

    this.showVideoPlayer();

    this.videoPlayerService.minimized$
      .pipe(takeUntil(this.autoUnsubscribeNotifier))
      .subscribe(value => (this._videoShown = !value));
  }

  reset(forceBlock = true) {
    this.onPauseAudio();
    this.media.currentTime = 0;
    if (this.isVideo) {
      this.removeVideoPlayer();
    }
    this.conversation = null;
    if (forceBlock && !this.blockUI.isActive) {
      this.blockUI.start();
    }
  }

  onPlayAudio(): void {
    if (!this.isVideo) {
      this.media.play();
    } else {
      this._videoElement.play();
    }
  }

  onPauseAudio(): void {
    if (!this.isVideo) {
      this.media.pause();
    } else {
      this._videoElement.pause();
    }
  }

  onSeekAudio(eventArgs): void {
    let duration = 0;

    if (!this.isVideo) {
      duration = this.media.duration;
    } else {
      duration = this._videoElement.duration;
    }

    const barWidth = eventArgs.currentTarget.offsetWidth;
    const clickedPosition = eventArgs.offsetX;
    const unitDuration = duration / barWidth;
    const target = unitDuration * clickedPosition;

    if (!this.isVideo) {
      this.media.currentTime = target;
    } else {
      this._videoElement.currentTime = target;
    }
  }

  onChangeSpeed(speed: number) {
    this._speed = speed;

    this.media.playbackRate = this.speed;
    this._videoElement.playbackRate = this.speed;

    const action = new SpeedChange(this.speed);
    this.store.dispatch(action);
  }

  onVolumeChange(eventArgs: Event) {
    const input = eventArgs.target as HTMLInputElement;
    const volume = Number(input.value) / 100;
    this._volumeBeforeChange = this.volume;

    const beforeVolumeAction = new BeforeVolumeChange(this._volumeBeforeChange * 100);
    this.store.dispatch(beforeVolumeAction);

    this.media.volume = volume;
    this._videoElement.volume = volume;

    if (volume == 0) {
      this.media.muted = true;
      this._videoElement.muted = true;
    } else {
      this.media.muted = false;
      this._videoElement.muted = false;
    }
  }

  onMuteButtonClick() {
    if (!this.media.muted || !this._videoElement.muted) {
      this._volumeBeforeChange = this.volume;

      const beforeVolumeAction = new BeforeVolumeChange(this._volumeBeforeChange * 100);
      this.store.dispatch(beforeVolumeAction);

      const volume = 0;

      this.media.muted = true;
      this.media.volume = volume;
      this._videoElement.muted = true;

      this.adjustSliderStyle(0);
    }
  }

  onUnmuteButtonClick() {
    if (this.media.muted || this._videoElement.muted) {
      this.media.muted = false;
      this.media.volume = this._volumeBeforeChange;
      this._videoElement.muted = false;
      this._videoElement.volume = this._volumeBeforeChange;

      this.adjustSliderStyle(this._volumeBeforeChange);
    }
  }

  adjustSliderStyle(volume?: number): void {
    const input = this.volumeSlider.nativeElement as HTMLInputElement;
    const min = 0;
    const max = 1;
    const val = volume ?? Number(input.value);

    input.style.backgroundSize = (val - min) / (max - min) + '% 100%';
  }

  onPlayerVolumeChange(eventArgs) {
    if (!this.isVideo) {
      this._volume = this.media.volume;
    } else {
      if (this._videoElement.muted) {
        this._volumeBeforeChange = this.volume;

        const beforeVolumeAction = new BeforeVolumeChange(this._volumeBeforeChange * 100);
        this.store.dispatch(beforeVolumeAction);

        this._volume = 0;
      } else {
        this._volume = this._videoElement.volume;
      }
      this.media.volume = this.volume;
    }

    const action = new VolumeChange(this.volume * 100);
    this.store.dispatch(action);

    this.adjustSliderStyle(this.volume * 100);
  }

  onRestoreVideoPlayer() {
    this.videoPlayerService.restore();
  }

  onCloseVideoPlayer() {
    this.videoPlayerService.minimize();
  }

  private showVideoPlayer() {
    this.videoPlayerService.show(
      `${this.localizationService.instant('Conversation::Conversation')} #${this.conversation.id}`
    );
  }

  private calculateTime = evt => {
    this.setTimeElapsed(this.currentTimeReverse);
  };

  private setPlayerStatus = evt => {
    switch (evt.type) {
      case 'playing':
        this.playerStatus.next('playing');
        this._playing = true;
        this._loading = false;
        break;
      case 'pause':
        this.playerStatus.next('paused');
        this._playing = false;
        this._loading = false;
        break;
      case 'waiting':
        this.playerStatus.next('loading');
        this._playing = false;
        this._loading = true;
        break;
      case 'ended':
        this.playerStatus.next('ended');
        this._playing = false;
        this._loading = false;
        break;
      default:
        this.playerStatus.next('paused');
        this._playing = false;
        this._loading = false;
        break;
    }
  };

  private onError(eventArgs) {
    this.toastr.error(eventArgs.target.error.message, this.localizationService.instant('::Error'));
    this._playing = false;
    this._loading = false;
  }

  private setTimeElapsed(seconds: number): void {
    this.timeElapsed.next(this.caTimePipe.transform(seconds));
  }

  private saveCompactPlayerPosition() {
    if (this.conversation) {
      const CompactPlayerPositionAction = new CompactPlayerPositionChange(
        this.conversation.id,
        !this.isVideo ? this.media.currentTime : this._videoElement.currentTime
      );
      this.store.dispatch(CompactPlayerPositionAction);
    }
  }

  private removeVideoPlayer() {
    this.removeAllListeners();
    this.videoPlayerService.close();
    this._isVideo = false;
  }

  private removeAllListeners() {
    this.media.removeAllListeners();
    this._videoElement.removeAllListeners();
  }
}
