import { InjectionToken, inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

export const CA_NAVIGATE_TO_SECURITY_LOGS = new InjectionToken<() => void>(
  'CA_NAVIGATE_TO_SECURITY_LOGS',
  {
    providedIn: 'root',
    factory: () => {
      const oAuthService = inject(OAuthService);

      return () => {
        const issuer = oAuthService.tokenEndpoint.replace('/connect/token', '');

        window.open(`${issuer}/Account/SecurityLogs?returnUrl=${window.location.href}`, '_self');
      };
    },
  }
);
