import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { LocalizationService } from '@abp/ng.core';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { ColorUtils } from 'src/core/helpers/color-utils';
import { Router } from '@angular/router';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';

@Component({
  selector: 'ca-conversation-transfer-tab-panel',
  templateUrl: './conversation-transfer-tab-panel.component.html',
  styleUrls: ['./conversation-transfer-tab-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConversationTransferTabPanelComponent implements OnInit {
  private _conversationId: number;
  private _relatedGroupId: string;

  @Input()
  set conversationId(conversationId: number) {
    if (conversationId) {
      this._conversationId = conversationId;
      this.getRelatedConversationsById();
    }
  }

  get conversationId(): number {
    return this._conversationId;
  }

  @Input()
  set relatedGroupId(relatedGroupId: string) {
    if (relatedGroupId) {
      this._relatedGroupId = relatedGroupId;
      this.getRelatedConversationsById();
    }
  }

  get relatedGroupId(): string {
    return this._relatedGroupId;
  }

  displayedColumns: string[] = [];
  relatedConversations: ConversationDto[];

  gridColumns: MatTableColumnDefinitionModel[] = [
    {
      columnName: 'id',
      header: this.localizationService.instant('::Id'),
      binding: 'id',
      valueParser: undefined,
      pipes: undefined,
      width: '7%',
      tooltip: 'id',
    },
    {
      columnName: 'startTime',
      header: this.localizationService.instant('::Time'),
      binding: 'startTime',
      valueParser: undefined,
      pipes: [
        {
          pipe: CADatePipe,
          args: [DateDisplayType.DateTimeWithoutSeconds, true],
        },
      ],
      width: '15%',
      tooltip: undefined,
    },
    {
      columnName: 'userName',
      header: this.localizationService.instant('Conversation::Agent'),
      binding: 'userName',
      valueParser: undefined,
      pipes: undefined,
      width: '20%',
      tooltip: undefined,
    },
    {
      columnName: 'duration',
      header: this.localizationService.instant('::Duration'),
      binding: 'duration',
      valueParser: undefined,
      pipes: undefined,
      width: '20%',
      tooltip: undefined,
    },
    {
      columnName: 'typeName',
      header: this.localizationService.instant('::ConversationType'),
      binding: 'typeName',
      valueParser: val => {
        return this.localizationService.instant('GenericLookup::' + val);
      },
      pipes: undefined,
      width: '100%',
      tooltip: undefined,
    },
  ];

  constructor(
    private conversationService: ConversationService,
    private localizationService: LocalizationService,
    public colorUtils: ColorUtils,
    private operators: Operators,
    private router: Router
  ) {
    for (const gridColumn of this.gridColumns) {
      this.displayedColumns.push(gridColumn.columnName);
    }
  }

  ngOnInit(): void {
    if (this.relatedGroupId) {
      this.getRelatedConversationsById();
    }
  }

  onRelatedConversationRowClick(conversationId: number) {
    this.router.navigate(['/conversation', conversationId]);
  }

  private getRelatedConversationsById() {
    if (this.conversationId && this.relatedGroupId) {
      const filters: FilterItemDto[] = [];
      filters.push({
        field: 'relatedGroupId',
        operator: this.operators.Equals,
        value: this.relatedGroupId,
      });
      filters.push({
        field: 'id',
        operator: this.operators.NotEquals,
        value: this.conversationId,
      });
      this.conversationService
        .get({
          filters: filters,
          sorters: [],
          skipCount: 0,
          maxResultCount: 9999,
        })
        .subscribe(response => {
          this.relatedConversations = response.items;
        });
    } else {
      this.relatedConversations = [];
    }
  }
}
