import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { ConversationImportFileModel } from 'src/core/models/conversation/conversation-import-file-model';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { ConversationImportCommunicationService } from '../../conversation-import-communication.service';

@Component({
  selector: 'ca-conversation-import-step-zero',
  templateUrl: './conversation-import-step-zero.component.html',
  styleUrls: ['./conversation-import-step-zero.component.scss']
})
export class ConversationImportStepZero implements OnInit {

  public selectedConversationFiles: ConversationImportFileModel[] = [];
  public selectedCsvFile: File;
  public systemLangCode: string;

  isCallImport: boolean = true;

  constructor(
    private communicationService: ConversationImportCommunicationService,
    private globalSettingsService: GlobalSettingsService,) {
    this.systemLangCode = this.globalSettingsService.systemLanguageCode;
  }

  ngOnInit(): void {
    this.communicationService.isCallImport = true;
  }

  validateEntryToStep() {
    return true;
  }

  validateExitFromStep() {
    return of(true);
  }

  onImportTypeChange() {
    this.isCallImport = !this.isCallImport;
    this.communicationService.isCallImport = this.isCallImport;
  }
}