import { LocalizationService } from '@abp/ng.core';
import { Component, Input, OnInit } from '@angular/core';
import { SentimentService } from 'src/core/services/conversation/sentiment-service';

@Component({
  selector: 'ca-conversation-sentiment-label',
  templateUrl: './conversation-sentiment-label.component.html',
  styleUrls: ['./conversation-sentiment-label.component.scss'],
})
export class ConversationSentimentLabelComponent implements OnInit {
  @Input()
  score: number;
  get sentimentColor(): string {
    return this.sentimentService.getSentimentScoreColor(this.score);
  }

  constructor(
    private localizationService: LocalizationService,
    private sentimentService: SentimentService
  ) { }

  ngOnInit(): void { }

  getCustomerSentimentIconClass(): string {
    if (this.score >= 0.6) {
      return 'far fa-smile-beam';
    } else if (this.score >= 0.2) {
      return 'far fa-smile';
    } else if (this.score > -0.1) {
      return 'far fa-meh';
    } else if (this.score >= -0.4) {
      return 'far fa-frown';
    } else if (this.score < -0.4) {
      return 'fa-regular fa-face-disappointed';
    }
  }

  getCustomerSentimentLabel(): string {
    if (this.score >= 0.6) {
      return this.localizationService.instant('Conversation::Analysis:VeryPositive');
    } else if (this.score >= 0.2) {
      return this.localizationService.instant('Conversation::Analysis:Positive');
    } else if (this.score > -0.1) {
      return this.localizationService.instant('Conversation::Analysis:Neutral');
    } else if (this.score >= -0.4) {
      return this.localizationService.instant('Conversation::Analysis:Negative');
    } else if (this.score < -0.4) {
      return this.localizationService.instant('Conversation::Analysis:VeryNegative');
    }
  }

  getScoreText(): string {
    let point = this.score ?? 0;
    if (point < 0.01 && point > 0) point = 0.01;
    if (point > -0.01 && point < 0) point = -0.01;

    return point.toFixed(2);
  }

  findThumbPosition() {
    const min = -1;
    const max = 1;
    const range = Math.abs(max - min);
    const difference = Math.abs(this.score - min);
    return (difference / range) * 100 + '%';
  }
}
