import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

export { BaseDropdownSelectorComponent } from './components/base-dropdown-selector/base-dropdown-selector.component';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgSelectModule],
  exports: [],
})
export class BaseSelectorModule {}
