import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, first, mergeMap } from 'rxjs/operators'; import { ConversationMarkChannel } from 'src/core/models/generic-lookup-type/conversation/conversation-mark-channel.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { NegativeContentQueryItemDto } from 'src/core/models/query/negative-content-query-item.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { Operators } from 'src/core/models/request/operator.enum';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';

@Component({
  selector: 'ca-query-tree-node-negative-content',
  templateUrl: './query-tree-node-negative-content.component.html',
  styleUrls: ['./query-tree-node-negative-content.component.scss']
})
export class QueryTreeNodeNegativeContentComponent extends QueryEditorBase implements OnInit {
  payload: NegativeContentQueryItemDto;
  conversationMarkChannels$: Observable<GenericLookupDto[]>;
  conversationMarkChannels = ConversationMarkChannel;
  currentConversationMarkChannel: string;

  operatorList = [
    {
      notation: this.operators.Contains,
      label: "Conversation::Exists",
      icon: 'fa-regular fa-face-swear',
    },
    {
      notation: this.operators.NotContains,
      label: "Conversation::DoesNotExist",
      icon: null,
    },
  ];

  constructor(private store: Store, private genericLookupService: GenericLookupTypeService, private operators: Operators) {
    super();

    this.conversationMarkChannels$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationMarkChannel)));
  }

  getLookup(lookupCode: string): Observable<GenericLookupDto> {
    return this.conversationMarkChannels$.pipe(
      first(),
      mergeMap(x => x.filter(y => y.code == lookupCode))
    );
  }

  getOperatorLabel(operator) {
    return this.operatorList.find(x => x.notation == operator).label;
  }

  getOperatorIcon(operator) {
    return this.operatorList.find(x => x.notation == operator).icon;
  }

  valueChanged() {
    this.node.isDirty = true;
    this.payload.sideCode = this.genericLookupService.findGenericLookupWithId(
      this.payload.sideId
    )?.code;
  }

  ngOnInit(): void {
    this.payload = this.node.categoryItem.payload as NegativeContentQueryItemDto;
    this.conversationMarkChannels$.subscribe((sides: GenericLookupDto[]) => {
      let conversationMarkChannel = sides.find(x => x.id == this.payload.sideId);
      this.currentConversationMarkChannel = conversationMarkChannel.code;
      this.payload.sideCode = conversationMarkChannel.code;
    });

    this.node.validationStatus = true;
  }
}
