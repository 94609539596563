import { LocalizationService } from '@abp/ng.core';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { AutomaticAssignmentDto } from 'src/core/models/quality-management/automatic-assignment.dto';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { AutomaticAssignmentSelectionConfigurationModel } from '../models/automatic-assignment-selection-configuration.model';

@Component({
  selector: 'ca-automatic-assignment-selection-panel',
  templateUrl: './automatic-assignment-selection-panel.component.html',
  styleUrls: ['./automatic-assignment-selection-panel.component.scss'],
})
export class AutomaticAssignmentSelectionPanelComponent extends FilterPanelEditorComponent {
  actualConfig: AutomaticAssignmentSelectionConfigurationModel;

  _value: AutomaticAssignmentDto[];
  automaticAssignmentSelectorOptions: any;

  @ViewChild(SelectorComponent, { read: SelectorComponent })
  automaticAssignmentSelector: SelectorComponent;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as AutomaticAssignmentSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  constructor(
    private localizationService: LocalizationService,
    private operators: Operators,
    protected fb: FormBuilder
  ) {
    super(fb);

    let filters: FilterItemDto[] = [];
    let sorters: SorterItemDto[] = [];

    filters.push({
      field: 'includePassiveAssignments',
      operator: this.operators.Equals,
      value: false,
    });

    sorters.push({
      field: 'Name',
      direction: 'ASC',
    });

    this.automaticAssignmentSelectorOptions = {
      pageSize: 5,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      emptyText: this.localizationService.instant('QualityManagement::SelectAutomaticAssignments'),
      multiple: true,
      url: 'api/app/automatic-assignment',
      currentPage: 1,
      showSelections: false,
      showClearSelections: false,
      showClearSelectionLink: true,
      selectorPosition: 'Top',
      filters: filters,
      sorters: sorters,
    };

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }
    
  autoSelectFirstItem(): void {}

  reload(): void { }

  reset(): void { }

  init() {
    super.init();
    if (this.actualConfig.autoLoad) {
      this.automaticAssignmentSelector.refresh();
    }
  }
}
