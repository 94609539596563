import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'QualityManagement.ReasonType',
})
export class ReasonType {
  @GenericLookup({
    code: 'QualityManagement.ReasonType.ChoiceReason',
  })
  static choiceReason: number;

  @GenericLookup({
    code: 'QualityManagement.ReasonType.NotSuitableForEvaluationReason',
  })
  static notSuitableForEvaluationReason: number;

  @GenericLookup({
    code: 'QualityManagement.ReasonType.ZeroPointReason',
  })
  static zeroPointReason: number;
}
