<ng-template #itemTemplate let-item="item">
  <div class="tag-selection-panel-tag-name">
    <div class="line-ellipsis">{{ item.name }}</div>
  </div>
</ng-template>

<ng-template #selectionTemplate let-item="item">
  <div class="filter-name">{{ item.name }}</div>
</ng-template>

<div class="p-4" style="height: 300px">  
<ng-container [formGroup]="tagForm">
  <h3 class="content-header-title content-title">
    {{ 'Conversation::Tag.Plural' | abpLocalization }}
  </h3>
  <ca-selector
    #tagSelector
    formControlName="tag"
    [url]="tagSelectorOptions.url"
    [itemTemplate]="itemTemplate"
    [filters]="tagSelectorFilters"
    [selectionTemplate]="selectionTemplate"
    [queryOperator]="tagSelectorOptions.queryOperator"
    [queryField]="tagSelectorOptions.queryField"
    [multiple]="'true'"
    [emptyText]="tagSelectorOptions.emptyText"
    [showSelections]="tagSelectorOptions.showSelections"
    [showClearSelections]="tagSelectorOptions.showClearSelections"
    [showClearSelectionLink]="tagSelectorOptions.showClearSelectionLink"
    [selectorPosition]="tagSelectorOptions.selectorPosition"
    [styles]="{ width: '100%', 'border-width': '0px' }"
    [pageSize]="tagSelectorOptions.pageSize"
    [currentPage]="tagSelectorOptions.currentPage"
  ></ca-selector>
</ng-container>
</div>
