import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'ca-user-identity',
  templateUrl: './user-identity.component.html',
  styleUrls: ['./user-identity.component.scss'],
})
export class UserIdentityComponent implements OnInit {
  @Input()
  id: string;

  @Input()
  name: string;

  @Input()
  surname: string;

  @Input()
  divClass: string = '';

  @Input()
  item: any;

  @Input()
  itemTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
