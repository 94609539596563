import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { QueryDto } from 'src/core/models/query/query.dto';
import { ConversationDto } from 'src/core/models/conversation/conversation.dto';
import { ListRequestDto } from 'src/core/models/request/list-request.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { QueryMatchingConversationsRequestDto } from 'src/core/models/query/query-matching-conversations-request.dto';
import { SaveQueryInputDto } from 'src/core/models/query/save-query-input.dto';
import { QueryTreeNodeModel } from 'src/core/models/query/query-tree-node.model';
import { QueryItemDto } from 'src/core/models/query/query-item.dto';
import { ToasterService } from '@abp/ng.theme.shared';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { CrudService } from '../crud/crud.service';
import { SimpleQueryDto } from 'src/core/models/query/simple-query.dto';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { QueryBuilderCategoryDto } from 'src/core/models/query/query-builder-category.dto';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { QueryTrendResponseDto } from 'src/core/models/query/query-trend-response.dto';
import { LanguageCodeDto } from 'src/core/models/language-code/language-code.dto';
import { MatchingTestResponseDto } from 'src/core/models/query/matching-test-response.dto';
import { GenAIQueryItemTestResponseDto } from 'src/core/models/query/gen-ai-query-item-test-response.dto';
import { GenAIQueryItemTestRequestDto } from 'src/core/models/query/gen-ai-query-item-test-request.dto';
import { GenAIPromptTemplateListDto } from 'src/core/models/query/gen-ai-prompt-template-list.dto';
import { ConversationCategoryDto } from 'src/core/models/category/conversation-category.dto';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  private categoryCache$: Observable<any>;
  private genAIPromptTemplateCache: Observable<any>[] = [];
  private lastUsedInternalId: number = 0;
  private supportedPhraseSuggestionLanguages: string[] = ['tr-TR', 'en-US'];

  constructor(
    private http: HttpClient,
    private localizationService: LocalizationService,
    private toastr: ToasterService,
    private config: ConfigStateService,
    public queryFieldDataType: QueryFieldDataType,
    private operators: Operators,
    private service: CrudService
  ) {}

  resetInternalIdCounter(): void {
    this.lastUsedInternalId = 0;
  }

  adjustInternalIdCounter(ref: number): void {
    this.lastUsedInternalId = ref > this.lastUsedInternalId ? ref : this.lastUsedInternalId;
  }

  getInternalId(): number {
    this.lastUsedInternalId++;

    return this.lastUsedInternalId;
  }

  getByName(name: string): Observable<QueryDto> {
    const url = this.getApiUrl() + '/by-name?name=' + encodeURIComponent(name);

    return this.http.get(url) as Observable<QueryDto>;
  }

  getByCategoryName(name: string): Observable<QueryDto> {
    const url = this.getApiUrl() + '/by-category-name?name=' + encodeURIComponent(name);

    return this.http.get(url) as Observable<QueryDto>;
  }

  getGenAIQueryItemTestResponse(
    input: GenAIQueryItemTestRequestDto
  ): Observable<GenAIQueryItemTestResponseDto[]> {
    let apiUrl = this.getApiUrl() + '/test-gen-ai-query-item/';
    return this.http.post(apiUrl, input) as Observable<GenAIQueryItemTestResponseDto[]>;
  }

  getImportName(name: string): Observable<string> {
    const url = this.getApiUrl() + '/import-name?name=' + encodeURIComponent(name);

    return this.http.get(url, { responseType: 'text' as 'json' }) as Observable<string>;
  }

  createTempQuery(query: SaveQueryInputDto): Observable<ListResponseDto<ConversationDto>> {
    let apiUrl = this.getApiUrl() + '/temp-query/';
    return this.http.post(apiUrl, query) as Observable<ListResponseDto<ConversationDto>>;
  }

  getTempQuery(tempQueryId: string): Observable<ConversationCategoryDto> {
    if (tempQueryId != undefined) {
      let apiUrl = this.getApiUrl() + '/temp-query/' + tempQueryId;
      return this.http.get(apiUrl) as Observable<ConversationCategoryDto>;
    } else {
      return of(null);
    }
  }

  exportQuery(query: SaveQueryInputDto): Observable<File> {
    let apiUrl = this.getApiUrl() + '/export/';
    return this.http.post<File>(apiUrl, query, {
      responseType: 'blob' as 'json',
    }) as unknown as Observable<File>;
  }

  duplicate(id: number): Observable<QueryDto> {
    const url = this.getApiUrl() + '/duplicate/' + id;
    return this.http.get(url) as Observable<QueryDto>;
  }

  reindex(id: number): Observable<any> {
    const url = this.getApiUrl() + '/reindex/' + id;
    return this.http.get(url) as Observable<any>;
  }

  getQueryConversations(
    tempId: string,
    request: ListRequestDto | null,
    matchingConversationDate: number
  ): Observable<MatchingTestResponseDto<ConversationDto>> {
    const apiUrl =
      environment.apis.default.url + '/' + ConversationDto.apiUrl + '/with-temp-query/' + tempId;

    let input = new QueryMatchingConversationsRequestDto();
    input.skipCount = request.skipCount;
    input.maxResultCount = request.maxResultCount;

    let params = new HttpParams();

    params = params.append('skipCount', JSON.stringify(request.skipCount));
    params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));
    if (typeof matchingConversationDate === 'string') {
      matchingConversationDate = parseInt(matchingConversationDate);
    }
    params = params.append('matchingConversationDate', JSON.stringify(matchingConversationDate));

    return this.http.get(apiUrl, {
      params,
    }) as Observable<MatchingTestResponseDto<ConversationDto>>;
  }

  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase + '/' + QueryDto.apiUrl;
  }

  getQueryBuilderTerms(treeData: QueryTreeNodeModel[]): string[] {
    let terms: string[] = [];

    treeData.forEach(t => {
      if (
        (t.categoryItem.dataType === this.queryFieldDataType.OrderedGroup ||
          t.categoryItem.dataType === this.queryFieldDataType.OrderedGroupWithRange) &&
        t.children &&
        t.children.length > 0
      ) {
        terms.push(...this.getQueryBuilderTerms(t.children));
      } else {
        let properties = Object.getOwnPropertyNames(t.categoryItem.payload);
        var termProperties = properties.filter(p => p.toLocaleLowerCase().includes('term'));
        termProperties.forEach(p => {
          var termPhrase: string = t.categoryItem.payload[p].toString();
          terms.push(...termPhrase.trim().split(' '));
        });
      }
    });

    return terms;
  }

  getQueryBuilderData(
    treeData: QueryTreeNodeModel[],
    ignoreValidation: boolean = false
  ): QueryItemDto[] {
    let builderDataToSave: QueryItemDto[] = [];
    let valid = true;
    let index = 0;

    treeData.forEach(t => {
      let childrenValidation = true;
      if (index === 0) {
        // skip first node which is a fake operator node
        index++;
        return;
      }
      this.setNodeValue(t, builderDataToSave);
      valid = valid && t.validationStatus;

      if (
        (t.categoryItem.dataType == this.queryFieldDataType.QueryGroup &&
          t.children?.length <= 0) ||
        (t.categoryItem.dataType == this.queryFieldDataType.AdvancedOrderedGroup &&
          t.children?.length <= 2)
      ) {
        childrenValidation = false;
        valid = false;
      }

      if (t.children && t.children.filter(x => x.validationStatus == false).length > 0) {
        childrenValidation = false;
        valid = false;
      }

      const categoryItemTitle = this.localizationService.instant(
        'Conversation::' + t.categoryItem.title
      );

      if (!ignoreValidation && (!t.validationStatus || !childrenValidation)) {
        this.toastr.error(
          this.localizationService.instant(
            'Validation::Query:FormComponentNotValid',
            categoryItemTitle
          )
        );
      }
      index++;
    });

    if (!ignoreValidation && !valid) {
      return null;
    }

    return builderDataToSave;
  }

  setNodeValue(node: QueryTreeNodeModel, data: QueryItemDto[]) {
    let index = 0;

    const queryItem: QueryItemDto = {
      internalId: node.categoryItem.payload.internalId,
      dataType: node.categoryItem.dataType,
      serializedPayload: JSON.stringify(node.categoryItem.payload),
    };

    data.push(queryItem);

    if (Array.isArray(node.categoryItem.multipleValuePayloads)) {
      node.categoryItem.multipleValuePayloads.forEach(x => {
        const queryItem: QueryItemDto = {
          internalId: x.internalId,
          dataType: node.categoryItem.dataType,
          serializedPayload: JSON.stringify(x),
        };

        data.push(queryItem);
      });
    }

    if (node.children != null && node.children.length > 0) {
      node.children.forEach(t => {
        if (index === 0) {
          // skip first node which is a fake operator node
          index++;
          return;
        }
        this.setNodeValue(t, data);
        index++;
      });
    }
  }

  getQueryTrends(filters: FilterItemDto[]): Observable<QueryTrendResponseDto> {
    const apiUrl = environment.apis.default.url + '/' + QueryDto.apiUrl + '/trend';
    let params = new HttpParams();
    params = params.append('filters', JSON.stringify(filters));

    return this.http.get(apiUrl, {
      params,
    }) as Observable<QueryTrendResponseDto>;
  }

  getMergedQueries(queryId: number) {
    var filtersAllQueries: FilterItemDto[] = [];

    filtersAllQueries.push({
      field: 'includePassives',
      operator: this.operators.Equals,
      value: true,
    });

    filtersAllQueries.push({
      field: 'isAllData',
      operator: this.operators.Equals,
      value: true,
    });

    filtersAllQueries.push({
      field: 'hasMergeQueryItem',
      operator: this.operators.Contains,
      value: queryId,
    });

    return this.service.get<SimpleQueryDto>(SimpleQueryDto, {
      filters: filtersAllQueries,
      sorters: [],
      maxResultCount: 99999,
      skipCount: 0,
    });
  }

  isTextSearchQueryItem(dataType: number): boolean {
    return (
      dataType === this.queryFieldDataType.OrderedGroup ||
      dataType === this.queryFieldDataType.OrderedGroupWithRange ||
      dataType === this.queryFieldDataType.SimpleTerm ||
      dataType === this.queryFieldDataType.SimpleTermWithNot ||
      dataType === this.queryFieldDataType.SimpleTermWithOccurence ||
      dataType === this.queryFieldDataType.SimpleTermWithRange ||
      dataType === this.queryFieldDataType.SimpleTermWithRangeAndOccurence ||
      dataType === this.queryFieldDataType.NearQuery
    );
  }

  isGenAIQueryItem(dataType: number): boolean {
    return (
      dataType === this.queryFieldDataType.GenAIYesNo ||
      dataType === this.queryFieldDataType.GenAIYesNoWithRange
    );
  }

  isPhraseSuggestionSupportedForLanguage(languageCode: string) {
    return this.supportedPhraseSuggestionLanguages.includes(languageCode);
  }

  getGenAIPromptTemplates(): Observable<ListResponseDto<QueryBuilderCategoryDto>> {
    let req = this.service.get<GenAIPromptTemplateListDto>(GenAIPromptTemplateListDto, null);

    var lang = this.localizationService.currentLang;

    if (!this.genAIPromptTemplateCache[lang]) {
      this.genAIPromptTemplateCache[lang] = req.pipe(shareReplay(1));
    }

    return this.genAIPromptTemplateCache[lang];
  }

  public getQueryCategories(): Observable<ListResponseDto<QueryBuilderCategoryDto>> {
    let req = this.service.get<QueryBuilderCategoryDto>(QueryBuilderCategoryDto, null);
    if (!this.categoryCache$) {
      this.categoryCache$ = req.pipe(shareReplay(1));
    }

    return this.categoryCache$;
  }

  public getQueryLanguageDefinitions() {
    const apiUrl = environment.apis.default.url + '/' + QueryDto.apiUrl + '/language-definitions';
    let params = new HttpParams();

    return this.http.get(apiUrl, {
      params,
    }) as Observable<LanguageCodeDto[]>;
  }

  updateLastUsedDate(request: any) {
    let queryIds = request?.filters?.find(x => x.field == 'query')?.value ?? [];
    const url = this.getApiUrl() + '/update-last-used-date';
    return this.http.put(url, queryIds);
  }

  getExistCallerOrCalledNumber(): Observable<boolean> {
    const url = this.getApiUrl() + '/exist-caller-called-number-for-redaction';
    return this.http.get(url) as Observable<boolean>;
  }
}
