import { Sort } from '@angular/material/sort';
import { EvaluationResultExcelDetailDto } from 'src/core/models/quality/evaluation/evaluation-result-excel-detail.dto';

export class SorterChange {
  static readonly type = '[EvaluationResult] SorterChange';

  constructor(public sort: Sort) { }
}

export class PageChange {
  static readonly type = '[EvaluationResult] PageChange';

  constructor(public currentPage: number) { }
}

export class EvaluationScrollChange {
  static readonly type = '[EvaluationResult] EvaluationScrollChange';

  constructor(public lastScrollPosition: number = 0) { }
}

export class EvaluationExcelDetailChange {
  static readonly type = '[EvaluationResult] EvaluationExcelDetailChange';

  constructor(public excelDetail: EvaluationResultExcelDetailDto) { }
}