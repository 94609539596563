import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private http: HttpClient) {}
  getAccessKey(): Observable<any> {
    const url = 'api/app/authorization/access-key';

    return this.http.get<string>(url, { headers: { 'hide-errors': 'true' } });
  }

  refreshAccessKey() {
    this.getAccessKey().subscribe();
  }
}
