import { Component, OnInit } from '@angular/core';
import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { CorporateIdentitySettingsService } from 'src/core/services/settings/corporate-identity-settings.service';

@Component({
  selector: 'ca-corporate-identity-settings',
  templateUrl: './corporate-identity-settings.component.html',
  styleUrls: ['./corporate-identity-settings.component.scss'],
})
export class CorporateIdentitySettingsComponent implements OnInit {
  logoAllowedExtensions: string[] = ['.png', '.jpg', '.jpeg', '.svg'];
  logoFileList: FileList;
  logoMaxFileSize: number = 1 * 1024 * 1024;
  isLogoFileSelectionValid = true;
  logoFileSelectionInvalidMessage = '';

  faviconAllowedExtensions: string[] = ['.ico'];
  faviconFileList: FileList;
  faviconMaxFileSize: number = 100 * 1024;
  isFaviconFileSelectionValid = true;
  faviconFileSelectionInvalidMessage = '';

  settingsForm: FormGroup;
  corporateIdentitySettingDtos: ConfigurationSettingsDto[] = [];

  formData = new FormData();

  constructor(
    private corporateIdentitySettingsService: CorporateIdentitySettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private fb: FormBuilder,
    private domSanitizer: DomSanitizer
  ) {
    this.formData = new FormData();

    this.settingsForm = this.fb.group({
      title: [null, Validators.required],
      logoBase64: null,
      faviconBase64: null,
    });
  }

  ngOnInit(): void {
    this.corporateIdentitySettingsService.get().subscribe(result => {
      this.settingsForm.get('title').setValue(result.title);

      if (result.logoFileBytes) {
        const logoFileBase64Uri = `data:${result.logoFileContentType};base64,${result.logoFileBytes}`;
        this.settingsForm.get('logoBase64').setValue(logoFileBase64Uri);
      }

      if (result.faviconFileBytes) {
        const faviconFileBase64Uri = `data:${result.faviconFileContentType};base64,${result.faviconFileBytes}`;
        this.settingsForm.get('faviconBase64').setValue(faviconFileBase64Uri);
      }
    });
  }

  get logoImageSource(): SafeUrl | string {
    if (this.settingsForm.get('logoBase64').value) {
      return this.domSanitizer.bypassSecurityTrustUrl(
        this.settingsForm.get('logoBase64').value.toString()
      );
    } else {
      return null;
    }
  }

  get faviconImageSource(): SafeUrl {
    if (this.settingsForm.get('faviconBase64').value) {
      // to be able to show the ico as an image
      return this.domSanitizer.bypassSecurityTrustUrl(
        this.settingsForm.get('faviconBase64').value.toString()
      );
    } else {
      return null;
    }
  }

  private onFileChange(
    files: FileList,
    allowedExtensions: string[],
    formControl: AbstractControl,
    maxFileSize: number,
    isLogo: boolean
  ) {
    const file = files[0];
    let isFileSelectionValid = true;
    let fileSelectionInvalidMessage = '';

    if (!file) {
      isFileSelectionValid = false;
      fileSelectionInvalidMessage = this.localizationService.instant(
        'Settings::NoValidFileSelected'
      );
      return { isFileSelectionValid, fileSelectionInvalidMessage };
    }

    if (files.length > 1) {
      isFileSelectionValid = false;
      fileSelectionInvalidMessage = this.localizationService.instant('Settings::SelectSingleFile');
      return { isFileSelectionValid, fileSelectionInvalidMessage };
    }

    const splitName = file.name.split('.');
    const extension = splitName[splitName.length - 1];
    const mime = file.type;

    if (allowedExtensions.indexOf('.' + extension) <= -1) {
      isFileSelectionValid = false;
      fileSelectionInvalidMessage = this.localizationService.instant('::FileTypeNotAllowed');
      return { isFileSelectionValid, fileSelectionInvalidMessage };
    }

    if (file.size > maxFileSize) {
      isFileSelectionValid = false;
      fileSelectionInvalidMessage = this.localizationService.instant(
        'Conversation::FileSizeTooLarge'
      );
      return { isFileSelectionValid, fileSelectionInvalidMessage };
    }

    const self = this;
    const reader = new FileReader();

    reader.onload = function () {
      const fileBase64Binary = btoa(reader.result as string);
      const fileBase64 = self.getBase64Uri(fileBase64Binary, mime);
      formControl.setValue(fileBase64);
    };
    reader.readAsBinaryString(file);

    if (isLogo) {
      this.formData.set('LogoFile', file, file.name);
    } else {
      this.formData.set('FaviconFile', file, file.name);
    }

    return { isFileSelectionValid, fileSelectionInvalidMessage };
  }

  onLogoFileChange(files: FileList) {
    const fileSelectionResult = this.onFileChange(
      files,
      this.logoAllowedExtensions,
      this.settingsForm.get('logoBase64'),
      this.logoMaxFileSize,
      true
    );

    this.isLogoFileSelectionValid = fileSelectionResult.isFileSelectionValid;
    this.logoFileSelectionInvalidMessage = fileSelectionResult.fileSelectionInvalidMessage;
  }

  onFaviconFileChange(files: FileList) {
    const fileSelectionResult = this.onFileChange(
      files,
      this.faviconAllowedExtensions,
      this.settingsForm.get('faviconBase64'),
      this.faviconMaxFileSize,
      false
    );

    this.isFaviconFileSelectionValid = fileSelectionResult.isFileSelectionValid;
    this.faviconFileSelectionInvalidMessage = fileSelectionResult.fileSelectionInvalidMessage;
  }

  getBase64Uri(base64Binary: string, mime: string): string {
    const base64Uri = `data:${mime};base64,${base64Binary}`;
    return base64Uri;
  }

  saveSettings() {
    this.formData.set('Title', this.settingsForm.get('title').value.toString());

    this.corporateIdentitySettingsService.save(this.formData).subscribe(result => {
      this.formData = new FormData();
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
