<ng-template #idleTimeoutModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title d-flex justify-content-center">
      <span *ngIf="!isLoggedOut"> {{ '::SessionTimeout' | abpLocalization }}</span>
      <span *ngIf="isLoggedOut"> {{ '::SessionExpired' | abpLocalization }}</span>
    </h4>
  </div>
  <div class="modal-body pt-1">
    <span *ngIf="!isLoggedOut">{{ sessionExirationInfoText }}</span>
    <span *ngIf="isLoggedOut">{{ '::SessionExpiredMessage' | abpLocalization }} </span>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="!isLoggedOut"
      class="btn btn-sm ca-btn ca-btn-neutral"
      (click)="onLogoutClick()"
      style="flex: 1"
    >
      {{ 'CA::LogOutNow' | abpLocalization }}
    </button>
    <div style="flex: 0 0 16px"></div>
    <button
      *ngIf="!isLoggedOut"
      class="btn btn-sm ca-btn ca-btn-primary"
      (click)="onStayLoginClick()"
      style="flex: 1"
    >
      <span>{{ 'CA::StayLoggedIn' | abpLocalization }}</span>
    </button>

    <button
      *ngIf="isLoggedOut"
      class="btn btn-sm ca-btn ca-btn-primary"
      (click)="onNavigateToLogin()"
      style="flex: 0.5"
    >
      <span>{{ 'CA::Login' | abpLocalization }}</span>
    </button>
  </div> </ng-template
><iframe [hidden]="true" #logoutFrame></iframe>
