import { FormGroup, ValidatorFn } from '@angular/forms';
import { QueryRangeUnit } from '../../models/generic-lookup-type/query/query-range-unit.glt';

export class QueryRangeValidator {

  static TimeRangeValidator: ValidatorFn = (fg: FormGroup) => {
    const start = fg.get('startTime').value;
    const end = fg.get('endTime').value;
    const rangeUnit = fg.get('rangeUnit').value;

    if (start === null || start < 0) {
      fg.get('startTime').setErrors({ queryTimeRangeNotValid: true });
    }
    if (end === null || end < 1) {
      fg.get('endTime').setErrors({ queryTimeRangeNotValid: true });
    }
    if (start === null || start < 0 || end === null || end < 1) {
      return { queryTimeRangeNotValid: true };
    } else {
      if (Math.round(Number(start)) !== start) {
        fg.get('startTime').setValue(Math.round(Number(start)));
      } else if (Math.round(Number(end)) !== end) {
        fg.get('endTime').setValue(Math.round(Number(end)));
      } else if (start >= end) {
        fg.get('startTime').setErrors({ queryTimeRangeNotValid: true });
        fg.get('endTime').setErrors({ queryTimeRangeNotValid: true });
        return { queryTimeRangeNotValid: true };
      } else if (rangeUnit != null && parseInt(rangeUnit, 10) === QueryRangeUnit.percentage) {
        if (end > 100) {
          fg.get('endTime').setErrors({ queryTimeRangeNotValid: true });
          fg.get('startTime').setErrors(null);
          return { queryTimeRangeNotValid: true };
        }
      }
    }

    fg.get('startTime').setErrors(null);
    fg.get('endTime').setErrors(null);
    return null;
  };

}
