<ng-template #blockTemplate>
  <div class="d-flex justify-content-center align-items-center block-ui-template"></div>
</ng-template>

<div *blockUI="'compactPlayerBlockUI'; template: blockTemplate">
  <div class="compact-player-container flex-container">
    <div class="flex-container w-25" style="min-width: 250px" *ngIf="showInfo">
      <div class="divider"></div>
      <div class="divider"></div>
      <div
        class="flex-container compact-player-label-container h-100 align-items-center"
        style="min-width: 90px">
        <span class="d-inline-block compact-player-label">
          {{ 'QualityManagement::ConversationId' | abpLocalization }}:</span
        >
      </div>
      <div class="divider"></div>
      <div
        class="flex-container compact-player-label-container h-100 align-items-center"
        style="width: 30%">
        <span class="d-inline-block compact-player-label">
          {{ conversation == null ? '' : conversation.id }}</span
        >
      </div>
      <div class="divider"></div>
      <div class="divider"></div>
      <div class="flex-container h-100 align-items-center" style="width: 5px">|</div>
      <div class="divider"></div>
      <div class="divider"></div>
      <div
        class="flex-container compact-player-label-container h-100 align-items-center"
        style="min-width: 40px">
        <span class="d-inline-block compact-player-label"
          >{{ 'QualityManagement::Agent' | abpLocalization }}:</span
        >
      </div>
      <div class="divider"></div>
      <div
        class="flex-container compact-player-label-container h-100 align-items-center"
        style="width: 70%">
        <span class="d-inline-block compact-player-label">
          {{ conversation == null ? '' : conversation.userName }}</span
        >
      </div>
      <div class="divider"></div>
      <div class="divider"></div>
    </div>
    <div class="flex-container w-75">
      <div class="divider"></div>
      <div class="flex-container h-100 align-items-center" style="width: 20px">
        <div ngbDropdown class="d-inline-block dropdown-button" placement="top">
          <button
            class="mb-1 ca-btn-outline ca-btn-neutral-toolbar speed-button compact-player-label"
            id="playerSpeedDropdown"
            ngbDropdownToggle>
            {{ speed }}x
          </button>
          <div ngbDropdownMenu aria-labelledby="playerSpeedDropdown">
            <button
              *ngFor="let speedOption of speedOptions"
              (click)="onChangeSpeed(speedOption)"
              ngbDropdownItem>
              {{ speedOption }}x
            </button>
          </div>
        </div>
      </div>
      <div class="divider" *ngIf="!loading"></div>
      <div
        class="flex-container h-100 align-items-center"
        style="width: 20px"
        *ngIf="!playing && !loading">
        <button
          type="button"
          class="btn btn-sm ca-btn ca-btn-outline toolbar-button player-button compact-player-label"
          (click)="onPlayAudio()">
          <i class="fas fa-play fa-sm"></i>
        </button>
      </div>
      <div class="flex-container h-100 align-items-center" style="width: 20px" *ngIf="playing">
        <button
          type="button"
          class="btn btn-sm ca-btn ca-btn-outline toolbar-button player-button"
          (click)="onPauseAudio()">
          <i class="fas fa-pause fa-sm"></i>
        </button>
      </div>
      <div class="half-divider" *ngIf="loading"></div>
      <div class="flex-container h-100 align-items-center" style="width: 20px" *ngIf="loading">
        <button type="button" class="btn btn-sm ca-btn ca-btn-outline toolbar-button player-button">
          <i class="fa-duotone fa-spinner-third fa-spin fa-sm"></i>
        </button>
      </div>
      <div class="half-divider" *ngIf="loading"></div>
      <div class="divider"></div>
      <div class="flex-container h-100 align-items-center" style="width: 40px">
        {{ timeElapsed | async }}
      </div>
      <div class="divider"></div>
      <div class="flex-container h-100 align-items-center" style="width: 100%">
        <ngb-progressbar
          class="progress-bar-container w-100"
          min="0"
          [max]="duration"
          [value]="currentTime"
          (click)="onSeekAudio($event)"
          container="body">
        </ngb-progressbar>
      </div>
      <div class="divider"></div>
      <div class="flex-container h-100 align-items-center" style="width: 32px">
        <div
          class="d-inline-block dropdown-button"
          ngbDropdown
          #volumeDropdown="ngbDropdown"
          container="body"
          (mouseenter)="volumeDropdown.open()"
          (mouseleave)="volumeDropdown.close()">
          <button
            type="button"
            class="btn btn-sm ca-btn ca-btn-outline toolbar-button player-button"
            (click)="isMuted ? onUnmuteButtonClick() : onMuteButtonClick()"
            ngbDropdownAnchor>
            <i [ngClass]="volumeIcon"></i>
          </button>
          <div
            ngbDropdownMenu
            (mouseenter)="volumeDropdown.open()"
            (mouseleave)="volumeDropdown.close()"
            class="volume-slider-dropdown">
            <div class="volume-slider-wrapper">
              <input
                #volumeSlider
                type="range"
                class="vertical-volume-slider"
                [value]="volume * 100"
                (change)="onVolumeChange($event)"
                (input)="adjustSliderStyle()" />
            </div>
          </div>
        </div>
      </div>
      <div class="divider" *ngIf="isVideo"></div>
      <div
        class="flex-container h-100 align-items-center"
        style="width: 32px"
        *ngIf="isVideo && !videoShown">
        <button
          type="button"
          class="btn btn-sm ca-btn ca-btn-outline ca-btn-player-controller toolbar-button player-button"
          (click)="onRestoreVideoPlayer()">
          <span class="original">
            <i class="ca video-minimized"></i>
          </span>
          <span class="hover">
            <i class="ca video-minimized"></i>
          </span>
        </button>
      </div>
      <div
        class="flex-container h-100 align-items-center"
        style="width: 32px"
        *ngIf="isVideo && videoShown">
        <button
          type="button"
          class="btn btn-sm ca-btn ca-btn-outline ca-btn-player-controller toolbar-button player-button"
          (click)="onCloseVideoPlayer()">
          <span class="original">
            <i class="ca video-opened"></i>
          </span>
          <span class="hover">
            <i class="ca video-minimized"></i>
          </span>
        </button>
      </div>
      <div class="divider"></div>
    </div>
  </div>
</div>
