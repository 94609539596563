import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericLookupDropdownSelectorComponent } from './components/generic-lookup-dropdown-selector/generic-lookup-dropdown-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

export { GenericLookupDropdownSelectorComponent } from './components/generic-lookup-dropdown-selector/generic-lookup-dropdown-selector.component';

@NgModule({
  declarations: [GenericLookupDropdownSelectorComponent],
  imports: [SharedModule, ReactiveFormsModule, FormsModule, CommonModule, NgSelectModule],
  exports: [GenericLookupDropdownSelectorComponent],
})
export class GenericLookupSelectorModule {}
